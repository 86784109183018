import React, { useEffect, useState } from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import SubLots from '../../../utils/CommonFunctionality/SellerDashboard/SubLots'
import { useTranslation } from 'react-i18next'
const MyLots = (props) => {
    const [artTheme, setArtTheme] = useState(null)
    const { t } = useTranslation()
    const isArtTheme = document.querySelector('body').classList.contains('artTheme')
    useEffect(() => {
        if (isArtTheme) {
            setArtTheme(isArtTheme)
        } else setArtTheme('')
    }, [isArtTheme])
    return (
        <Layout props={props}>
            <DashboardLayout title={t('my_lots')}>
                <SubLots
                    multipleChangeStatus={false}
                    searchBar={1}
                    status={1}
                    resultsPerPage={1}
                    changeStatus={1}
                    withoutAuction={1}
                    copyItem={1}
                    date_input_change={1}
                    slidertheme={'ArtAuction'}
                    storeTheme={artTheme ? 'ArtAuction' : ''}
                />
            </DashboardLayout>
        </Layout>
    )
}

export default MyLots
