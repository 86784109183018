import * as Yup from 'yup'
const validation1 = Yup.object({
    category_id: Yup.string().required('Required!'),
    subcategory: Yup.array().when('category_id', {
        is: (val) => val == global?.storeConfigration?.similar_category?.value,
        then: Yup.array(),
        otherwise: Yup.array().min(1, 'Required!').required('Required!'),
    }),
    customfield1: Yup.string().when('category_id', {
        is: (val) => val == global?.storeConfigration?.similar_category?.value,
        then: Yup.string(),
        otherwise: Yup.string().required('Required!'),
    }),
    customfield2: Yup.string().when('category_id', {
        is: (val) => val == global?.storeConfigration?.similar_category?.value,
        then: Yup.string(),
        otherwise: Yup.string().required('Required!'),
    }),
    customfield3: Yup.string().when('category_id', {
        is: (val) => val == global?.storeConfigration?.similar_category?.value,
        then: Yup.string(),
        otherwise: Yup.string().required('Required!'),
    }),
    customfield4: Yup.string().when('category_id', {
        is: (val) => val == global?.storeConfigration?.similar_category?.value,
        then: Yup.string(),
        otherwise: Yup.string().required('Required!'),
    }),
    customfield6: Yup.string().when('category_id', {
        is: (val) => val == global?.storeConfigration?.similar_category?.value,
        then: Yup.string(),
        otherwise: Yup.string().required('Required!'),
    }),
    radcondition: Yup.string().when('category_id', {
        is: (val) => val == global?.storeConfigration?.similar_category?.value,
        then: Yup.string(),
        otherwise: Yup.string().required('Required!'),
    }),
    protitle: Yup.string().required('Required!'),
    description: Yup.string().when('category_id', {
        is: (val) => val == global?.storeConfigration?.similar_category?.value,
        then: Yup.string(),
        otherwise: Yup.string().required('Required!'),
    }),
})
const validation2 = Yup.object({
    inventory_date: Yup.date().typeError('000').nullable('Incorrect Start Date'),
    game_theme: Yup.string().when('category_id', {
        is: (val) => val == global?.storeConfigration?.similar_category?.value,
        then: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
        otherwise: Yup.string()
            .required('Required!')
            .matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    }),
    serial: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    display_type: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    board_type: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    player_tracking: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    validator_model: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    validator_manufacturer: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    ticket_printer_model: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    ticket_printer_manufacturer: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    denomination: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    cabinet_color: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    door_color: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    bet_configuration: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    theoritical_hold: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    sas_version: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    software_number: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    panel_present: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    motherboard_present: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    other_prom: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
    model_number: Yup.string().matches(/^[^']*$/, 'Single Quotes Not Allowed!'),
})
const validation4 = Yup.object({
    // mprice: Yup.number()
    //     .typeError('Number Only!')
    //     .positive('Should be greater than 1')
    //     .required('Required!'),
    bprice: Yup.number()
        .typeError('Number Only!')
        .positive('Should be greater than 1')
        // .lessThan(Yup.ref('mprice'), 'Should be lesser than the Retail price')
        .required('Required!'),
    qty: Yup.number()
        .typeError('Number Only!')
        .positive('Should be greater than 1')
        .required('Invalid Qty!'),
    customfield5: Yup.string().required('Required!'),
    startdate: Yup.string().required('Incorrect Start Date').nullable(),
    // enddate: Yup.string().required('Incorrect End Date').nullable(),
})

const validation5 = Yup.object({
    address: Yup.string().when('temp_hasshipping', {
        is: '2' || 2,
        then: Yup.string().required('Required!'),
    }),
    location: Yup.string().when('temp_hasshipping', {
        is: '2' || 2,
        then: Yup.string().required('Required!'),
    }),
    state: Yup.string().when('temp_hasshipping', {
        is: '2' || 2,
        then: Yup.string().required('Required!'),
    }),
    city: Yup.string().when('temp_hasshipping', {
        is: '2' || 2,
        then: Yup.string().required('Required!'),
    }),
    zipcode: Yup.string().when('temp_hasshipping', {
        is: '2' || 2,
        then: Yup.string().when('location', {
            is: '38',
            then: Yup.string()
                .matches(
                    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
                    'Invalid Zipcode',
                )
                .required('Required'),
            otherwise: Yup.string()
                .matches(/\b\d{5}\b/g, 'Invalid Zipcode!')
                .required('Required'),
        }),
    }),
    shippingcost: Yup.number()
        .typeError('Number Only!')
        .when('temp_hasshipping', {
            is: '1',
            then: Yup.number()
                .typeError('Number Only!')
                .required('Required!')
                .positive('Should be greater than 1'),
        })
        .when('temp_hasshipping', {
            is: 1,
            then: Yup.number()
                .typeError('Number Only!')
                .required('Required!')
                .positive('Should be greater than 1'),
        }),
})
const allValidations = {
    validation1,
    validation2,
    validation4,
    validation5,
}

export const getValidationSchema = (index) => allValidations[`validation${index}`]
