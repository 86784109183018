import React from 'react'
import { FacebookShareButton, FacebookIcon } from 'react-share'
import { FacebookProvider, ShareButton } from 'react-facebook'
const Facebook = ({ path, title, selectedProduct }) => {
    const handleShare = () => {
        window.FB.ui(
            {
                method: 'share_open_graph',
                display: 'popup',
                action_type: 'og.likes',
                action_properties: JSON.stringify({
                    object: {
                        'og:url': window.location.href.includes(global?.storeDetails?.id)
                            ? `${window.location.origin}/${global?.storeDetails?.id}${path}`
                            : `${window.location.origin}${path}`,
                        'og:title': title,
                        'og:description': title,
                        'og:image':
                            `${
                                selectedProduct.content_head1 === '0'
                                    ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                                    : process.env.REACT_APP_PRODUCT_IMAGE_URL
                            }` + selectedProduct.avatar,
                        'og:image:width': '1200',
                        'og:image:height': '630',
                    },
                }),
            },
            (response) => {
                // Handle the response (optional)
                console.log('response  fb api', response)
            },
        )
    }

    return (
        <>
            {global.storeConfigration?.facebook_product_share_image?.value == 1 ? (
                <FacebookShareButton
                    url={`${window.location.origin}/product?id=${selectedProduct.id}`}
                    quote={title}
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
            ) : (
                <FacebookShareButton
                    url={
                        window.location.href.includes(global?.storeDetails?.id)
                            ? `${window.location.origin}/${global?.storeDetails?.id}${path}`
                            : `${window.location.origin}${path}`
                    }
                    quote={title}
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
            )}
        </>
    )
}

export default Facebook
