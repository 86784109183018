import React, { useContext, useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import './subscription.css'
import { NavLink } from 'react-router-dom'
import authContext from '../../../Product/context/auth/authContext'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import alertContext from '../../../Product/context/alert/alertContext'
const BuyerAssurance = (props) => {
    const { user, loadUser } = useContext(authContext)
    const { cancelAutoPay, responseStatus } = useContext(pluginContext)
    const { setAlert } = useContext(alertContext)
    const [loader, setLoader] = useState(false)
    const cancelSubscription = () => {
        setLoader(true)
        cancelAutoPay({ user_id: user?.id, site_id: global?.storeDetails?.site_id })
    }
    useEffect(() => {
        if (responseStatus?.from === 'cancelAutoPay') {
            setLoader(false)
            if (responseStatus?.status === 'success') {
                setAlert('Subscription Cancelled Successfully!', 'success')
                loadUser()
            }
        }
    }, [responseStatus])
    return (
        <Container className="db-bids-wrapper mt-4">
            <h2 className="mp-head">Join your subscription plan</h2>
            <div className="subCard my-5">
                <div className="liquidationSubCard">
                    <div className="card grid-card">
                        <div className="grid-img-wrap">
                            <img src="/assets/images/subCard.png"></img>
                        </div>
                        <div className="card-body p-4">
                            <h3 className="premium">
                                {user?.buyer_subscribed == 1
                                    ? user?.buyer_subscribe_plan
                                    : global?.pluginConfiguration?.buyers_assurance?.end_point_url}
                            </h3>
                            {user?.buyer_subscribed == 1 ? null : (
                                <h2 className="text-center my-4">
                                    <b>
                                        ${' '}
                                        {global?.pluginConfiguration?.buyers_assurance?.client_key}
                                    </b>
                                    /mo
                                </h2>
                            )}
                            {user?.buyer_subscribed == 1 ? (
                                <>
                                    <p>
                                        Subscribed on{' '}
                                        {moment(user?.buyer_subscribed_at).format('LL')}
                                    </p>
                                    <p>
                                        {user?.buyer_subscribed_auto_pay == 1
                                            ? 'Upcoming Subscription '
                                            : 'Expire '}
                                        on{' '}
                                        {moment(
                                            new Date(user?.buyer_subscribed_at).setDate(
                                                new Date(user?.buyer_subscribed_at).getDate() + 30,
                                            ),
                                        ).format('LL')}{' '}
                                        {user?.buyer_subscribe_auto_pay == 1 ? (
                                            <b>
                                                ($
                                                {
                                                    global?.pluginConfiguration?.buyers_assurance
                                                        ?.client_key
                                                }
                                                )
                                            </b>
                                        ) : null}
                                    </p>
                                </>
                            ) : (
                                ReactHtmlParser(
                                    global?.pluginConfiguration?.buyers_assurance?.service_key,
                                )
                            )}
                            {user?.buyer_subscribed == 1 && user?.buyer_subscribed_auto_pay == 1 ? (
                                <PrimaryButton
                                    type="button"
                                    label={loader ? 'Loading...' : 'Cancel Upcoming Subscription'}
                                    onClick={cancelSubscription}
                                    disabled={loader}
                                />
                            ) : null}
                            {user?.buyer_subscribed == 0 ? (
                                <div className="d-flex justify-content-between align-items-center mx-4">
                                    <button type="button" className="yesButton">
                                        <NavLink activeClassName="active" to="/dashboard/billing">
                                            Yes
                                        </NavLink>
                                    </button>
                                    <p className="mb-0 fonting">or</p>
                                    <p className="mb-0 fonting later">Later</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default BuyerAssurance
