import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import MyBidsPanel from '../../../utils/CommonFunctionality/MyBidsPanel'
import { useTranslation } from 'react-i18next'

const MyBids = (props) => {
    const { t } = useTranslation()
    return (
        <Layout props={props}>
            <DashboardLayout title={t('my_bid')} className="sdsd">
                <MyBidsPanel
                    currency="cad"
                    sliderTheme="multiseller"
                    noTimer={true}
                    storeTheme="ArtAuction"
                    noView={true}
                    //noProxy={true}
                    checkout_but_pos={'top'}
                />
            </DashboardLayout>
        </Layout>
    )
}

export default MyBids
