import React, { useContext, useEffect } from 'react'
import Layout from '../Layout'
import './Static.css'
import UserContext from '../../../Product/context/user/userContext'
const Earn = (props) => {
    const userContext = useContext(UserContext)
    const { static_page, getStaticPage } = userContext

    useEffect(() => {
        getStaticPage(
            {
                page_id: 'earn_page',
            },
            1,
        )
    }, [])

    return (
        <Layout>
            {static_page?.content ? (
                <div
                    className="container py-5"
                    dangerouslySetInnerHTML={{
                        __html: static_page.content,
                    }}
                ></div>
            ) : (
                <div className="container py-5">
                    <b>AFFILIATION</b>
                    <br />
                    <br />
                    <p>
                        <b>Register as an Affiliate</b> for Artauction.io and <b>EARN</b> monthly on
                        Subscriptions you pull in. Promote on Social Platforms, web advertising,
                        sponsorship, publications, periodicals, websites and blog posts and
                        <b>EARN 10%</b> of all new subscriptions to the site.
                    </p>
                    <p>
                        <b>SIGN UP:</b> Email
                        <a href="mailto:Auctioneer@artauction.io">Auctioneer@artauction.io</a> and
                        get a PERSONALIZED Coupon Code to promote. Your <b>coupon code</b> will
                        allow us to track new users. You will receive 10% of the subscription upon
                        the second month of service.
                    </p>
                    <p>
                        There is not limit to how little or how much you can EARN as an Affiliate of
                        ARTAUCTION.IO
                    </p>
                    <p>EARN: $100 a month for 10 new users</p>
                    <p>EARN: $1000 a month for 100 new users</p>
                    <p>EARN: $10,000 a month for 1000 new users</p>
                    <br />
                    <br />
                    <br />
                    <b>NON-PROFIT 501c3 AFFILIATION</b>
                    <br />
                    <br />
                    <p>
                        <b>Register as an affiliate</b> for Artauction.io and Promote the service to
                        Artists. We are willing to swap Logos and place them on our website to
                        promote and sponsor your Non-profit organization.
                    </p>
                    <p>
                        <b>SIGN UP:</b>Email
                        <a href="mailto:Auctioneer@artauciton.io">Auctioneer@artauciton.io</a> in
                        subject please write <b>NP</b> (non-profit) We will supply you with a
                        <b>Non-profit Custom Coupon Code</b>
                    </p>
                    <p>
                        <b>EARN 10% donation</b> from users.
                    </p>
                    <p>
                        Any new user that registers through your Custom Non-profit Coupon Code will
                        be tracked for the purpose of donations to your 501c3 organization.
                    </p>
                    <blockquote className="blockquote">
                        <p className="mb-0">We look forward to working with you!</p>
                        <footer className="blockquote-footer">
                            <cite title="Source Title">The Auctioneer ARTAUCTION.IO</cite>
                        </footer>
                    </blockquote>
                </div>
            )}
        </Layout>
    )
}

export default Earn
