import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import './index.css'

const Layout = ({ props, children, ...rest }) => {
    return (
        <div className="app-wrapper">
            {/* <h1>Hi {currentUser.name}!</h1> */}
            <Header />
            <div className="main-container bubble-container">
                <div className="bubbles">
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                </div>
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout
