import React from 'react'
import './orders.css'
import Layout from '../Layout'
import CurrentOrders from './Orders'
import DashboardLayout from '../DashboardLayout'

const Orders = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="Current Orders">
                <div className="db-bids-wrapper">
                    <CurrentOrders page="paid" />
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default Orders
