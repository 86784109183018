import { AccountBalance, AccountBalanceWalletOutlined } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../assets/loader'
import { currencyFormat } from '../../../Product/common/components'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import authContext from '../../../Product/context/auth/authContext'
import pluginContext from '../../../Product/context/plugin/pluginContext'

const ReferralEarned = () => {
    const { t } = useTranslation()
    const { getReferralList, all_referrals, total_earned } = useContext(pluginContext)
    const { user } = useContext(authContext)

    const [loading, setLoading] = useState(true)
    const [referralList, setReferralList] = useState([])
    const [search, setSearch] = useState({
        limit: 12,
        page: 1,
        user_id: user?.id,
        site_id: global?.storeDetails?.site_id,
    })

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }
    useEffect(() => {
        if (user) {
            setLoading(true)
            getReferralList(search)
        }
    }, [search])
    useEffect(() => {
        setLoading(false)
        setReferralList(all_referrals)
    }, [all_referrals])

    useEffect(() => {
        if (user && !search.user_id) {
            setSearch({ ...search, user_id: user?.id })
        }
    }, [user])
    return (
        <div className="text-center">
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : referralList.length ? (
                <div>
                    <p className="d-inline float-left">
                        <AccountBalance /> {currencyFormat(total_earned)}
                    </p>
                    <p className="d-inline float-right">
                        <AccountBalanceWalletOutlined /> {currencyFormat(user?.wallet_amount)}
                    </p>

                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Amount Earned</th>
                                </tr>
                            </thead>
                            <tbody>
                                {referralList.map((val, key) => (
                                    <tr key={key}>
                                        <td>{val.id}</td>
                                        <td>{moment(val.date_added).format('MM-DD-YYYY')}</td>
                                        <td className="text-center">
                                            {currencyFormat(
                                                val.from_id == user?.id
                                                    ? val.from_amount
                                                    : val.to_amount,
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex mt-3 pagination-wrapper">
                            <div className="ml-auto page-showing">
                                {t('showing')} {search.limit * (search.page - 1) + 1} -{' '}
                                {search.limit * search.page > referralList.length
                                    ? referralList.length
                                    : search.limit * search.page}{' '}
                                of {referralList.length}
                            </div>
                            <Pagination
                                count={Math.ceil(referralList.length / search.limit)}
                                page={search.page}
                                onChange={onHandlePage}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <NoRecordsFound />
            )}
        </div>
    )
}

export default ReferralEarned
