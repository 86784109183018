import React from 'react'
import '../Login/Login.css'

import Layout from '../Layout'
import ForgotPasswordFunction from '../../../utils/CommonFunctionality/ForgotPasswordFunction'
import SEO from '../../../utils/CommonFunctionality/SEO'

const ForgotPassword = () => {
    return (
        <Layout>
            <SEO title={`${global?.storeDetails?.name} | Forgot password`} />

            <div className="clearfix row vcaLogin">
                <div className="col-12">
                    <div className="loginFormBox">
                        <ForgotPasswordFunction />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ForgotPassword
