import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AlertContext from '../../Product/context/alert/alertContext'
import PluginContext from '../../Product/context/plugin/pluginContext'
import CheckBox from '../../Product/components/atoms/CheckBox'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    mapData,
    handleRedirectInternal,
    currencyFormat,
    dateFormatFront,
} from '../../Product/common/components'
import AuthContext from '../../Product/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
const PreferenceForm = (props) => {
    const authContext = useContext(AuthContext)
    const [Loading, setLoading] = useState(true)
    const { user, isAuthenticated } = authContext

    const {
        get_booked_tickets,
        responseStatus: book_ticket_status,
        clearResponse,
    } = useContext(PluginContext)
    const { setAlert } = useContext(AlertContext)
    const [ticket_details, setBookedDetails] = useState([])
    const [total_result, setTotalResult] = useState(0)
    const [page, setPage] = useState(1)
    const history = useHistory()
    useEffect(() => {
        if (user) {
            get_booked_tickets({ user_id: user.id, perpage: 10, page: 1 })
        }
    }, [user])

    useEffect(() => {
        if (book_ticket_status) {
            if (book_ticket_status.from === 'booked_ticket_status') {
                setBookedDetails(
                    book_ticket_status.message?.seatList ? book_ticket_status.message.seatList : [],
                )
                setTotalResult(
                    book_ticket_status.message?.total_results
                        ? book_ticket_status.message.total_results
                        : 0,
                )
                setLoading(false)
            }
            //clearResponse()
        }
    }, [book_ticket_status])

    const onHandlePage = (event, value) => {
        setPage(value)
        get_booked_tickets({ user_id: user.id, perpage: 10, page: value })
    }
    return (
        <div className="preferences-form-wrapper">
            {Loading ? (
                ''
            ) : (
                <>
                    {ticket_details.length > 0 ? (
                        <div className="tcktWrpr">
                            {ticket_details.map((data, index) => (
                                <div
                                    className="tcktVw cursorDecoy"
                                    onClick={() =>
                                        handleRedirectInternal(
                                            history,
                                            `search/product-buyer-auction/${data.auction_id}`,
                                        )
                                    }
                                >
                                    <div className="tcktDtls">
                                        <div className="topVw">
                                            <h2>{data.event_title}</h2>
                                            <span>
                                                {data.seat_obj
                                                    ? JSON.parse(data.seat_obj).length
                                                    : 0}{' '}
                                                {data.seat_obj
                                                    ? JSON.parse(data.seat_obj).length > 1
                                                        ? 'Tickets'
                                                        : 'Ticket'
                                                    : ''}
                                            </span>
                                        </div>
                                        <div className="tcktInfo">
                                            <div className="stTmInfo">
                                                <div className="stInfo">
                                                    <label>Seat No</label>
                                                    <p>{data.seat_number}</p>
                                                </div>
                                                <div className="stInfo">
                                                    <label>Date & Time</label>
                                                    <p>{dateFormatFront(data.date_added)}</p>
                                                </div>
                                                <div className="stInfo">
                                                    <label>Location</label>
                                                    <p>
                                                        {data.stage_name}, <br />
                                                        <small>{data.city}</small>
                                                    </p>
                                                </div>
                                                <div className="stInfo">
                                                    <label>Price</label>
                                                    <p>{currencyFormat(data.amount)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tcktStatus">
                                        <div className="qrContianer">
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL}images/qr/${data.qr_code}`}
                                            />
                                        </div>
                                        <p>BOOKED</p>
                                    </div>
                                </div>
                            ))}

                            {/* <table className="table text-left">
                                <thead>
                                    <tr>
                                        <th scope="col">S no</th>
                                        <th scope="col">Ticket Number</th>
                                        <th scope="col">Total Tickets</th>
                                        <th scope="col">Total Amount</th>
                                        <th scope="col">Booked Date</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ticket_details.map((data, index) => (
                                        <>
                                            <tr key={data.id}>
                                                <td>{index + 1}</td>
                                                <td>{data.seat_number}</td>
                                                <td>
                                                    {data.seat_obj
                                                        ? JSON.parse(data.seat_obj).length
                                                        : 0}
                                                </td>
                                                <td>{currencyFormat(data.amount)}</td>
                                                <td>{dateFormatFront(data.date_added)}</td>
                                                <td>
                                                    <Link
                                                        to={`/search/product-buyer-auction/${data.auction_id}`}
                                                    >
                                                        View Event
                                                    </Link>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table> */}
                        </div>
                    ) : (
                        <div className="np-pro-box">{'No tickets found'}</div>
                    )}
                </>
            )}
            {ticket_details.length > 0 ? (
                <div>
                    <Pagination
                        count={Math.ceil(total_result / 10)}
                        page={page}
                        onChange={onHandlePage}
                        siblingCount={3}
                        showFirstButton
                        showLastButton
                        boundaryCount={2}
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default PreferenceForm
