import React from 'react'
import { PinterestShareButton, PinterestIcon } from 'react-share'
const Pinterest = ({ path, title, media }) => {
    return (
        <PinterestShareButton
            url={
                window.location.href.includes(global?.storeDetails?.id)
                    ? `${window.location.origin}/${global?.storeDetails?.id}${path}`
                    : `${window.location.origin}${path}`
            }
            title={title}
            media={media}
        >
            <PinterestIcon size={32} round />
        </PinterestShareButton>
    )
}

export default Pinterest
