import React from 'react'
import List from '../../../utils/CommonFunctionality/Bio'
import Layout from '../Layout'
const ArtistList = () => {
    return (
        <Layout>
            <List />
        </Layout>
    )
}

export default ArtistList
