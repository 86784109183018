import React from 'react'
import { TwitterShareButton, TwitterIcon } from 'react-share'
const Twitter = ({ path, title }) => {
    return (
        <TwitterShareButton
            url={
                window.location.href.includes(global?.storeDetails?.id)
                    ? `${window.location.origin}/${global?.storeDetails?.id}${path}`
                    : `${window.location.origin}${path}`
            }
            title={title}
        >
            <TwitterIcon size={32} round />
        </TwitterShareButton>
    )
}

export default Twitter
