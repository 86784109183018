import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SlidePanel from '../../../Component/SlidePanel'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import { messageHandler } from '../../../Product/common/socketHandler'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import { socket, socketAuction } from '../../../Product/common/socket'
import Slider from 'react-slick'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import { useHistory } from 'react-router-dom'
import HeaderSearchComponent from '../Header/HeaderSearch'
import { horseAges, horseGait, horseGender, horseTypes } from '../../../utils'
import axios from 'axios'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const history = useHistory()

    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { search_allproducts, getAllSearchProducts } = productContext
    const [auctionView, setAuctionView] = useState('List')

    const { isAuthenticated, user } = authContext
    const { setAlert } = alertContext

    const classes = useStyles()

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalItems, setTotalItem] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    const listContainer = useRef(null)
    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    // useEffect(() => {
    //     handleRedirectInternal(history, '')
    // }, [])

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])
    const cancelToken = axios.CancelToken.source()
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const params = new URLSearchParams(window.location.search.substring(1))
    const formik = useFormik({
        initialValues: {
            sh_limit: 12,
            page: 1,
            orderby: '6',
            age: [],
            gait: [],
            gender: [],
            location: [],
            state: [],
            city: [],
            category: catg ? [catg] : [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: '',
            search_title_only_auction_io: params.get('search_title_only_auction_io')
                ? params.get('search_title_only_auction_io')
                : '',
            from: '',
            closed_auction_list: params.get('closed_auction_list')
                ? params.get('closed_auction_list')
                : 0,
            view_type: params.get('view_type') ? params.get('view_type') : 0,
            // customfield8: [],
            contenthead2: [],
            contenthead3: [],
        },
    })
    const handleParamsChange = (e) => {
        const { name, value } = e.target
        formik.setFieldValue(name, value)
        if (params.get(name) || typeof params.get(name) !== undefined) {
            params.delete(name)
        }
        params.append(name, value)
        history.push(`/?${params.toString()}`)
    }
    const filterValues = [
        {
            title: 'Status',
            type: 'radio',
            name: 'closed_auction_list',
            int: 1,
            class: 'col-12 col-md-4 customRadio',
            item: [
                { id: 0, description: 'Live Items' },
                { id: 1, description: 'Closed Items' },
            ],
            formik: formik,
            onChange: handleParamsChange,
        },
        {
            title: 'View',
            type: 'radio',
            name: 'view_type',
            int: 1,
            class: 'col-12 col-md-6 customRadio',
            item: [
                { id: 0, description: 'All' },
                { id: 1, description: 'Auction' },
                { id: 2, description: 'Buy Now' },
            ],
            formik: formik,
            onChange: handleParamsChange,
        },

        // {
        //     label: 'State',
        //     type: 'multiselect',
        //     name: 'state',
        //     class: 'col-sm-2 col-xs-6 col-12',
        //     options: [
        //         {
        //             show: 'Oceanwise',
        //             value: 1,
        //         },
        //         {
        //             show: 'MSC',
        //             value: 2,
        //         },
        //     ],
        //     formik: formik,
        // },
        {
            content: <h6 className="hfTitle">Add filters</h6>,
            type: 'misc',
            class: 'col-12',
        },
        // {
        //     label: 'Type of horse',
        //     type: 'multiselect',
        //     name: 'category',
        //     class: 'col-sm-2 col-xs-6 col-12',
        //     options: horseTypes,
        //     formik: formik,
        // },
        {
            label: 'Age',
            type: 'multiselect',
            name: 'contenthead2',
            class: 'col-sm-2 col-xs-6 col-12',
            options: horseAges,
            formik: formik,
        },
        // {
        //     label: 'Gait',
        //     type: 'multiselect',
        //     name: 'contenthead2',
        //     class: 'col-sm-2 col-xs-6 col-12',
        //     options: horseGait,
        //     formik: formik,
        // },
        {
            label: 'Gender',
            type: 'multiselect',
            name: 'contenthead3',
            class: 'col-sm-2 col-xs-6 col-12',
            options: horseGender,
            formik: formik,
        },
    ]
    useEffect(() => {
        setLoading(false)

        // if (search_allproducts.from === 'searchPage') {
        setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
        setTotalItem(search_allproducts.total_pagecnt)
        // }
    }, [search_allproducts, formik.values])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        formik.setFieldValue('page', value)
    }
    const [cancelRequest, setCancelRequest] = useState(undefined)
    useEffect(() => {
        setLoading(true)
        if (formik.values.view_type == 1) {
            formik.setFieldValue('from', 'auction_only')
        } else if (formik.values.view_type == 2) {
            formik.setFieldValue('from', 'buynow')
        } else formik.setFieldValue('from', '')
        if (localStorage.getItem('page')) {
            formik.setFieldValue('page', parseInt(localStorage.getItem('page')))
            localStorage.removeItem('page')
        }
        if (cancelRequest) {
            cancelRequest.cancel()
        }

        setCancelRequest(cancelToken)
        getAllSearchProducts(formik.values, '', cancelToken)
    }, [isAuthenticated, formik.values])

    const handleSearch = (val) => {
        formik.setFieldValue('search_title_only_auction_io', val)
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        localStorage.setItem('search', auctionView)
        localStorage.setItem('page', formik.values.page)
        handleRedirectInternal(history, `productView/${id}`)
    }

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]

    const settings = {
        dots: true,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }

    useEffect(() => {
        if (localStorage.getItem('search')) {
            setAuctionView(localStorage.getItem('search'))
            localStorage.removeItem('search')
            window.scrollTo({
                top: listContainer.current.offsetTop,
                left: 0,
                behavior: 'smooth',
            })
        }
    }, [])

    return (
        <Layout props={props}>
            {global.multipleBanners.length ? (
                <div className="siteBanner multiple">
                    {
                        <Slider {...settings}>
                            {global.multipleBanners.map((data, index) => (
                                <>
                                    <img
                                        src={process.env.REACT_APP_AUCTION_IMAGE_URL + '/' + data}
                                    />
                                    {/* <div className="bannerContent">
                                        <h2>{global?.storeDetails?.description}</h2>
                                        <PrimaryButton
                                            label="Explore Now"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `${isAuthenticated ? 'search' : 'sign-up'}`,
                                                )
                                            }
                                        />
                                    </div> */}
                                </>
                            ))}
                        </Slider>
                    }
                </div>
            ) : (
                <div className="siteBanner">
                    <LazyLoadImage
                        src={global?.storeDetails?.bannerValue}
                        alt={`${global?.storeDetails?.name} banner`}
                        effect="blur"
                        height="100%"
                        width="100%"
                    />
                    {/* <div className="bannerContent">
                        <h2>{global?.storeDetails?.description}</h2>
                        <PrimaryButton
                            label="Explore Now"
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    `${isAuthenticated ? 'search' : 'sign-up'}`,
                                )
                            }
                        />
                    </div> */}
                </div>
            )}

            <div className="customContainer mt-5 position-relative">
                <div className="homeFilter">
                    <h3>Browse listings</h3>
                    <HeaderSearchComponent
                        handleParamsChange={handleParamsChange}
                        searchValues={formik.values}
                    />
                    <FilterPanel formik={formik} filterValues={filterValues} custom={true} />
                    <Button className="col-2" onClick={() => formik.resetForm()}>
                        Reset
                    </Button>
                </div>
            </div>

            <div className="customContainer mt-5 position-relative" ref={listContainer}>
                <div className="d-flex justify-content-between align-items-center flex-wrap my-5">
                    <h2 className="auctionListTitle"></h2>

                    <div className="auctionsMiscFilter">
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => setAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => setAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={formik.values.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                formik.setFieldValue('sh_limit', event.target.value)
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        <CustomSelect
                            label="Sort by"
                            value={formik.values.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                formik.setFieldValue('orderby', event.target.value)
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    </div>
                    {/* <Button
                        className="toggleRespDrawer w-100"
                        variant="outlined"
                        onClick={toggleDrawer('bottom', true)}
                    >
                        <span className="material-icons">tune</span>
                        Filters
                    </Button> */}
                </div>
                <div className="searchContainer">
                    <div className="searchRt">
                        {isLoading ? (
                            <div>
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                            </div>
                        ) : viewProduct.length === 0 ? (
                            <NoRecordsFound />
                        ) : (
                            <div
                                className={`lotResults ${auctionView} liquidationBuyCard ${
                                    !isAuthenticated ? 'unauth' : 'auth'
                                }`}
                            >
                                {viewProduct.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <>
                                            {
                                                <GridProduct
                                                    data={data}
                                                    className={data.auction ? 'auction' : 'buynow'}
                                                    key={index}
                                                    type={data.buynow ? 'buynow' : 'auction'}
                                                    selectedProduct={selectedProduct}
                                                    link={`/productView/${data.id}`}
                                                    handleClick={handleClick}
                                                    timerTheme=""
                                                    cardTheme="liveStock"
                                                    bidBtnLabel="Place Bid"
                                                    //productViewLink={`/productView/${data.id}`}
                                                    viewProduct={viewProduct}
                                                    setViewProduct={setViewProduct}
                                                />
                                            }
                                        </>
                                    ) : (
                                        <MultisellerListCard
                                            lotData={data}
                                            className={data.auction ? 'auction' : 'buynow'}
                                            key={index}
                                            selectedProduct={selectedProduct}
                                            allsearchitems={viewProduct}
                                            type="lot"
                                            handleClick={handleClick}
                                            details={`/gallery/product-view/${data.id}`}
                                            sliderTheme="multiseller"
                                            //standalonePage={true}
                                            cardTheme="liveStock"
                                            bidBtnLabel={
                                                <>
                                                    <span className="material-icons-outlined">
                                                        gavel
                                                    </span>
                                                    Bid
                                                </>
                                            }
                                            //productViewLink={`/productView/${data.id}`}
                                            viewProduct={viewProduct}
                                            setViewProduct={setViewProduct}
                                        />
                                    ),
                                )}
                            </div>
                        )}
                        <Pagination
                            count={Math.ceil(totalItems / formik.values.sh_limit)}
                            page={formik.values.page}
                            onChange={onHandlePage}
                        />
                    </div>
                </div>
            </div>

            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel formik={formik} filterValues={filterValues} />
                </div>
            </Drawer>
            {/* <MultisellerSlider
                selectedLot={selectedProduct}
                handleClose={handleClose}
                type={props.type ? props.type : ''}
            /> */}
        </Layout>
    )
}

export default AuctionSearchComponent
