import React, { useState, useEffect, useContext } from 'react'
import Layout from '../Layout'
import Loaders from '../../../Product/components/molecules/Loaders'
import PaymentContext from '../../../Product/context/payment/payContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import Popup from '../../../Product/components/organisms/Popup'
const Paypalsuccess = (props) => {
    //const [loaders, setLoaders] = useState(true)
    const [open, setOpen] = useState(true)
    // const token = new URLSearchParams(window.location.search.substring(1)).get('token')
    // const PayerID = new URLSearchParams(window.location.search.substring(1)).get('PayerID')
    // const {
    //     successAfterPaypaypalupdate,
    //     clearResponse,
    //     responseStatus: responseStatusPayment,
    // } = useContext(PaymentContext)
    // const alertContext = useContext(AlertContext)
    // const { setAlert } = alertContext

    // useEffect(() => {
    //     if (token) {
    //         var payment_det = JSON.parse(localStorage.getItem('paymentdetails'))
    //         payment_det.payment_details = { token: token, PayerID: PayerID }
    //         successAfterPaypaypalupdate(payment_det)
    //     }
    // }, [token])

    // useEffect(() => {
    //     if (responseStatusPayment) {
    //         if (responseStatusPayment.from === 'successAfterPaypalupdate') {
    //             if (responseStatusPayment.status === 'success') {
    //                 console.log(
    //                     'llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll',
    //                     responseStatusPayment,
    //                 )
    //                 setLoaders(false)
    //                 setOpen(true)
    //             } else {
    //                 setAlert(responseStatusPayment.message, 'error')
    //                 // window.location.href = window.location.href.includes(global?.storeDetails?.id)
    //                 //     ? `/${global?.storeDetails?.id}/dashboard/mybids?s=3`
    //                 //     : ''
    //             }
    //         }
    //     }
    //     clearResponse()
    // }, [responseStatusPayment])

    const handleClose = () => {
        setOpen(false)
        // clearResponse()
        window.location.href = window.location.href.includes(global?.storeDetails?.id)
            ? `/${global?.storeDetails?.id}/dashboard/mybids?s=1`
            : ''
    }

    return (
        <Layout props={props}>
            {/* <Loaders isLoading={loaders}></Loaders> */}
            {/* {loaders ? (
                <h6 style={{ fontsize: '32px', textAlign: 'center' }}>
                    Paypal Proccesing payment.......
                </h6>
            ) : (
                ''
            )} */}
            <Popup
                open={open}
                size="md"
                className="save-search-modal"
                handleClose={handleClose}
                modaltitle="PAYMENT FAILED"
            >
                <div className="order-success-wrap orPlcdSs text-center">
                    {/* <img src="/image/check_circle.svg" alt="" /> */}
                    <p className="os-text">Payment Process Failed!</p>
                </div>
            </Popup>
        </Layout>
    )
}

export default Paypalsuccess
