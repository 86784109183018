export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const AUCTION_DETAILS = 'AUCTION_DETAILS'
export const AUCTION_MSGS = 'AUCTION_MSGS'
export const GET_ALL_SEARCH_AUCTION = 'GET_ALL_SEARCH_AUCTION'
export const HOMEPAGE_CALENDAR_AUCTION = 'HOMEPAGE_CALENDAR_AUCTION'
export const HOMEPAGE_FEATURED_AUCTION = 'HOMEPAGE_FEATURED_AUCTION'
export const HOMEPAGE_OTHER_AUCTION = 'HOMEPAGE_OTHER_AUCTION'
export const HOMEPAGE_LIVE_AUCTION = 'HOMEPAGE_LIVE_AUCTION'
export const HOMEPAGE_TIMED_AUCTION = 'HOMEPAGE_TIMED_AUCTION'
export const AUCTION_SELLER_DETAILS = 'AUCTION_SELLER_DETAILS'
export const HOMEPAGE_TOP_LOTS = 'HOMEPAGE_TOP_LOTS'
export const AUCTION_POLICIES = 'AUCTION_POLICIES'
