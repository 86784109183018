import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, description, appleTouchIcon, favIcon, keywords }) => {
    return (
        <>
            <Helmet>
                <link rel="icon" id="favicon" href={favIcon} />
                <meta name="description" content={description} />
                <link rel="apple-touch-icon" href={appleTouchIcon} />
                {(keywords != '' || keywords != undefined) && (
                    <meta name="keywords" content={keywords}></meta>
                )}
                <title>{title}</title>
            </Helmet>
        </>
    )
}

export default SEO
