import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Layout from '../Layout'
import ReferralFunction from '../../../utils/CommonFunctionality/Referral'
import DashboardHeader from '../DashboardHeader'

const Referral = (props) => {
    return (
        <Layout props={props}>
            <DashboardHeader />
            <Container className="db-bids-wrapper mt-4">
                <h2 className="mp-head">REFERRAL</h2>
                <ReferralFunction />
            </Container>
        </Layout>
    )
}

export default Referral
