export const config = (content = null) => {
    let header = {}
    const currentUserString = localStorage.getItem('currentUser')
    if (
        currentUserString === null ||
        currentUserString === undefined ||
        currentUserString === 'null'
    ) {
        if (content !== null) {
            return false
        }
    } else {
        let currentUser = JSON.parse(currentUserString)
        if (content) {
            if (content === 'userData') {
                return {
                    first_name: currentUser['first_name'],
                    last_name: currentUser['last_name'],
                    email: currentUser['email'],
                    phone: currentUser['phone'],
                    country: currentUser['country'],
                    state: currentUser['state'],
                    city: currentUser['city'],
                    zip: currentUser['zip'],
                    address1: currentUser['address1'],
                    address2: currentUser['address2'],
                    id: currentUser['id'],
                    nmfs_number: currentUser['nmfs_number'],
                    nmfs_number: currentUser['nmfs_number'],
                    card_paymentid: currentUser['card_paymentid'],
                    is_imported_user: currentUser['li_id'],
                }
            }
            return currentUser[content]
        }
        if (currentUser) {
            header['authorization'] = 'Bearer ' + currentUser.user_token || ''
        } else {
            header['authorization'] = ''
        }
    }
    if (window.location.origin.includes('localhost')) {
        header['domain'] = 'auction_preview.ecommerce.auction'
    }
    return { headers: header }
}
