import React from 'react'
import Layout from '../Layout'
import EditBuynow from './EditBuyNow'

const EditBuyNow = (props) => {
    const id = props.match.params.id
    // console.log('id', id)
    return (
        <>
            <Layout props={props}>
                <div className="postProductContainer">
                    {/*eslint-disable*/}
                    <EditBuynow auction_id={id} bottomErrorField={1} />
                </div>
            </Layout>
        </>
    )
}

export default EditBuyNow
