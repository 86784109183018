import React, { useContext, useEffect, useState } from 'react'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import ProductContext from '../../../Product/context/product/productContext'
import { useFormik } from 'formik'
import { mapData } from '../../../Product/common/components'
import * as Yup from 'yup'
const BuynowToAuction = ({ product_id, title }) => {
    const [open, setOpen] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const { buynow_to_auction, responseStatus } = useContext(ProductContext)

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'success') {
                if (responseStatus.from === 'buynow_to_auction') {
                    setOpen(false)
                }
            } else {
                if (responseStatus.from === 'buynow_to_auction') {
                    setBtnLoading(false)
                }
            }
        }
    }, [responseStatus])

    const validationArray = Yup.object({
        product_id: Yup.number(),
        sprice: Yup.number()
            .required('Start Price Required!')
            .positive('Should be greater than 1')
            .min(1, 'Should be greater than 1'),
        rprice: Yup.number().when('enable_rprice', {
            is: true,
            then: Yup.number()
                .positive('Should be greater than 1')
                .min(Yup.ref('sprice'), 'Should be equal or greater than the starting price')
                .required('Reserve Price Required!'),
        }),
    })
    const formik = useFormik({
        initialValues: {
            id: product_id,
            sprice: '',
            rprice: '',
            enable_rprice: 0,
            startdate: new Date(),
            enddate: new Date(
                new Date().setDate(
                    new Date().getDate() +
                        parseInt(global.storeConfigration?.buynow_to_auction_days?.value),
                ),
            ),
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            setBtnLoading(true)
            buynow_to_auction(values)
        },
    })
    const fields = [
        {
            label: 'Enable Reserve',
            type: 'checkbox',
            class: `col-md-4 col-sm-6 col-12`,
            name: 'enable_rprice',
            formik: formik,
        },
        {
            label: 'Auction start price',
            placeholder: 'Enter starting price',
            type: 'number',
            class: `col-md-4 col-sm-6 col-12`,
            name: 'sprice',
            formik: formik,
        },
        {
            label: 'Auction reserve price',
            placeholder: 'Enter reserve price',
            type: 'number',
            class: `col-md-4 col-sm-6 col-12 `,
            name: 'rprice',
            disabled: !formik.values.enable_rprice,
            formik: formik,
        },
        {
            label: 'Start date *',
            placeholder: 'Enter Start date',
            type: 'datetime',
            disablePast: true,
            class: `col-sm-6 col-12`,
            name: 'startdate',
            formik: formik,
        },
        {
            label: 'End Date *',
            placeholder: 'Enter End Date',
            disablePast: true,
            type: 'datetime',
            class: `col-sm-6 col-12`,
            name: 'enddate',
            formik: formik,
        },
    ]
    return (
        <div>
            <Button className="ml-2" variant="outlined" onClick={() => setOpen(true)}>
                Move To Auction
            </Button>
            <CustomDialog title={'Move Buynow To Auction'} open={open} function={setOpen}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                Are You Sure ? Do You Want To Move This Product <b>({title})</b>{' '}
                                From Buynow To Auction ?
                            </p>
                        </div>
                        <div className="col-md-12">
                            <div className="row">{mapData(fields)}</div>
                        </div>
                        <div className="col-md-6">
                            <Button onClick={() => setOpen(false)} disabled={btnLoading}>
                                {'Cancel'}
                            </Button>
                        </div>
                        <div className="col-md-6">
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                type="button"
                                disabled={btnLoading}
                                label={btnLoading ? 'Loading...' : 'Confirm'}
                            />
                        </div>
                    </div>
                </div>
            </CustomDialog>
        </div>
    )
}

export default BuynowToAuction
