import React from 'react'
import Layout from '../Layout'
import PhotoUploaderProcedure from '../../../utils/CommonFunctionality/PhotoUploader/requirementImageUpload'
import DashboardLayout from '../DashboardLayout'

const PhotoUploadProcedure = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="Photo Uploader">
                <PhotoUploaderProcedure />
            </DashboardLayout>
        </Layout>
    )
}

export default PhotoUploadProcedure
