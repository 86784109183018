import { useEffect } from 'react'
import React from 'react'
import Layout from '../Layout'

export default function termsOfService(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout props={props}>
            <div className="px-5 py-4 staticPgWrapper customContainer">
                {/* <h2>About Us</h2> */}
                <span
                    dangerouslySetInnerHTML={{
                        __html: global.storeDetails.terms_condition,
                    }}
                ></span>
            </div>
        </Layout>
    )
}
