import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Copyrightinfo, Logo, FooterLogo } from '../../../utils'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AuthContext from '../../../Product/context/auth/authContext'
import './Footer.css'
import Footernav from '../../../utils/FooterNavigation/footernav'
import { useTranslation } from 'react-i18next'
function Footer() {
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram
    const { t } = useTranslation()

    const { user } = useContext(AuthContext)

    return (
        <footer className="footer-main-standard noPrint">
            <div className="customContainer">
                <div className="footerInner">
                    <ul>
                        <li>
                            <FooterLogo className="footerLogo" />
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <h6>Company</h6>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/contact_us">
                                {t('contact_us')}
                            </NavLink>
                        </li>
                        {global.storeDetails?.privacy && (
                            <li>
                                <NavLink activeClassName="active" to="/aboutus">
                                    {t('about_us')}
                                </NavLink>
                            </li>
                        )}
                        {global.storeDetails?.terms && (
                            <li>
                                <NavLink activeClassName="active" to="/faq">
                                    {t('faq')}
                                </NavLink>
                            </li>
                        )}
                        {global.storeDetails?.terms_condition && (
                            <li>
                                <NavLink activeClassName="active" to="/termsOfService">
                                    {t('terms_conditions')}
                                </NavLink>
                            </li>
                        )}

                        {global.storeDetails?.privacy_policy && (
                            <li>
                                <NavLink activeClassName="active" to="/privacy_policy">
                                    {t('Privacy_Policy')}
                                </NavLink>
                            </li>
                        )}
                        <Footernav />
                    </ul>
                    <ul>
                        <li>
                            <h6>My Account</h6>
                        </li>
                        <li>
                            <Link to="/dashboard/profile">Profile</Link>
                        </li>
                        <li>
                            <Link
                                to={user?.role == 1 ? '/dashboard/overview' : '/dashboard/mybids'}
                            >
                                Dashboard
                            </Link>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <h6>Connect</h6>
                        </li>
                        <li className="footSocialLinks">
                            {facebookLink && (
                                <a
                                    href={facebookLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Facebook"
                                >
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            )}
                            {instagramLink && (
                                <a
                                    href={instagramLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Instagram"
                                >
                                    <i className="fab fa-instagram"></i>
                                </a>
                            )}
                            {googleLink && (
                                <a
                                    href={googleLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Linkedin"
                                >
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                            )}
                            {twitterLink && (
                                <a
                                    href={twitterLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Twitter"
                                >
                                    <i className="fab fa-twitter"></i>
                                </a>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
            <Copyrightinfo />
        </footer>
    )
}

export default Footer
