/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import {
    Button,
    Popover,
    ListItem,
    Divider,
    SwipeableDrawer,
    MenuItem,
    Menu,
    IconButton,
    Collapse,
    Typography,
    SvgIcon,
} from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { useMediaQuery } from 'react-responsive'
import { FooterLogo, Logo } from '../../../utils/index'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import BecomeSeller from '../../../utils/CommonFunctionality/BecomeSeller'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import ProductContext from '../../../Product/context/product/productContext'
import Timer from '../../../Product/common/timer'
import url from 'socket.io-client-latest/lib/url'
import LoginComponent from '../Login'
import LoginFunction from '../../../utils/CommonFunctionality/LoginFunction'
import QuickSignupFunction from '../../../utils/CommonFunctionality/QuickSignup'
import ForgotPasswordFunction from '../../../utils/CommonFunctionality/ForgotPasswordFunction'
import PaymentPendingHeader from '../../../utils/CommonFunctionality/PaymentPendingHeader'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'
import GuideChimp from 'guidechimp'
import 'guidechimp/dist/guidechimp.min.css'
import CurrencyConverter from '../../../utils/CommonFunctionality/CurrencyConverter'
import LanguageManer from '../../../utils/CommonFunctionality/LanguageManager'
import { useTranslation } from 'react-i18next'
import CartContext from '../../../Product/context/cart/cartContext'
const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram
    const { t } = useTranslation()
    const location = useLocation()

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [auctionMenu, setAuctionMenu] = useState(null)
    const [open, setOpen] = useState(false)

    const { user, isAuthenticated, logout, responseStatus, userCount } = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const cartContext = useContext(CartContext)
    const {
        buyer_cartitems,
        getUserCart,
        responseStatus: cartresponse,
        clearResponse,
    } = cartContext
    const { search_allauctions, getAllSearchAuctions, search_allauctionproducts, getLotList } =
        productContext

    const [viewAuction, setViewAuction] = useState([])

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: '',
        type: '',
    })

    const { setAlert } = useContext(AlertContext)
    const isMobile = useMediaQuery({
        query: '(max-width: 700px)',
    })
    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
        left: false,
    })

    const [listingAnchor, setListingAnchor] = useState(null)
    const listingOpen = Boolean(listingAnchor)

    const [userType, setUserType] = useState('')
    const [logPopup, setLogPopup] = useState(false)
    const [regPopup, setRegPopup] = useState(false)
    const [forgotOpen, setForgotPopup] = useState(false)
    const [useropen, setUserOpen] = useState(false)

    const [sellerDrop, setSellerDrop] = useState({
        seller: false,
        buyerAccount: false,
        sellerAccount: false,
    })

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'activeHeader')
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'activeHeader') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    useEffect(() => {
        if (cartresponse) {
            if (cartresponse.from === 'addToCart') {
                if (cartresponse.status === 'success') {
                    getUserCart()
                    clearResponse()
                }
            }
            if (cartresponse.from === 'removeFromCart') {
                if (cartresponse.status === 'success') {
                    getUserCart()
                    clearResponse()
                }
            }
        }
    }, [cartresponse])

    useEffect(() => {
        if (user?.card_profileid == '' && userCount.itemsoldCount > 0) {
            setAlert('Kindly add account id to continue!', 'warning')
            handleRedirectInternal(history, 'dashboard/profile')
        }
    }, [user])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleMenuAuction = (event) => {
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        setAuctionMenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setAuctionMenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const handleListingClick = (event) => {
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        setListingAnchor(event.currentTarget)
    }

    const handleListingClose = () => {
        setListingAnchor(null)
    }
    const toggleOpen = () => {
        setOpen(!open)
    }
    const toggleLogOpen = () => {
        setLogPopup(!logPopup)
        if (window.location.hash.includes('#login')) {
            handleRedirectInternal(history, '')
        }
    }

    const toggleForgotOpen = () => {
        setForgotPopup(!forgotOpen)
        if (window.location.hash.includes('#forgot_password')) {
            handleRedirectInternal(history, '')
        }
    }

    const toggleRegOpen = () => {
        setRegPopup(!regPopup)
    }

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories.filter((main) => {
                return true
            }),
        },
    ]

    const logoutUser = () => {
        logout()
        history.push('/login')
        window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleClick = (catId) => {
        handleRedirectInternal(history, `search/product-buyer-auction/${catId}`)
    }
    const handleSellClick = (path) => {
        if (isAuthenticated) {
            // if (global.pluginConfiguration?.reseller_subscription?.enable) {
            //     if (!user?.subscribed) {
            //         setAlert('Kindly subscribe to proceed further!', 'warning')
            //         return handleRedirectInternal(history, 'dashboard/subscription')
            //     }
            // }
            if (user && user.role == 1) {
                return handleRedirectInternal(history, path)
            } else {
                return handleRedirectInternal(history, 'becomeSeller')
            }
        } else {
            return handleRedirectInternal(history, 'becomeSeller')
        }
    }

    let newUrl = new URLSearchParams(location.search)

    useEffect(() => {
        setUserType('buyer')
    }, [])

    const handleTypeChange = (type) => {
        setUserType(type)
        setOpen(false)
        setLogPopup(true)
    }

    const setSignUp = () => {
        setRegPopup(true)
        setLogPopup(false)
    }

    const setLoginPopup = () => {
        setRegPopup(false)
        setLogPopup(true)
    }

    useEffect(() => {
        if (window.location.hash.includes('#login')) {
            setLogPopup(true)
        } else if (window.location.hash.includes('#forgot_password')) {
            setForgotPopup(true)
        } else if (window.location.hash.includes('#register')) {
            setUserType('seller')
            setRegPopup(true)
            setLogPopup(false)
        }
    }, [window.location.pathname])

    useEffect(() => {
        if (!localStorage.getItem('newUser')) {
            setOpen(true)
            localStorage.setItem('newUser', true)
        }
    }, [])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    setLogPopup(false)
                }
            }
        }
    }, [responseStatus])

    const userGuide = [
        {
            element: '#step1',
            title: 'Welcome!',
            description:
                'To post a product click this menu button and then click on the next arrow',
            // custombuttons: [
            //     {
            //         title: 'See more',
            //         class: 'tour-button',
            //         onClick: function () {
            //             alert('Step button click')
            //         },
            //     },
            // ],
        },
        {
            element: '#step2',
            title: 'Click Post Single Lot',
            description: 'Click this post single lot option to open post listing page',
        },
    ]

    const guidechimp = new GuideChimp(userGuide)

    // guidechimp.on('onNext', toggleDrawer('left', true))

    useEffect(() => {
        if (isAuthenticated) {
            if (localStorage.getItem('guide') != 'true') {
                if (user?.role == 1) {
                    guidechimp.start()
                    localStorage.setItem('guide', 'true')
                }
            }
        }
    }, [isAuthenticated, user])

    const handleSellerDrop = (name) => (event) => {
        setSellerDrop({ ...sellerDrop, [name]: event.currentTarget })
    }

    const handleCloseSellerDrop = () => {
        setSellerDrop({
            seller: null,
            buyerAccount: null,
            sellerAccount: null,
        })
    }

    const handleSubmenu = (path) => {
        setSellerDrop({
            seller: null,
            buyerAccount: null,
            sellerAccount: null,
        })
        setState({ ...state, left: false })

        handleRedirectInternal(history, path)
    }

    // const id = open ? 'simple-popover' : undefined
    // userMenu handler
    const userHandleClick = (event) => {
        setUserOpen(event.currentTarget)
    }
    console.log(sellerDrop.seller, 'sellerrr')

    const userHandleClose = () => {
        setUserOpen(null)
    }

    const getUsername = () => {
        if (user?.first_name) {
            if (user.first_name.length <= 8) {
                return user.first_name
            } else return user.first_name.slice(0, 8) + '...'
        }
    }

    return (
        <>
            {(global?.pluginConfiguration?.currency_converter?.enable == 1 ||
                global?.pluginConfiguration?.language_manager?.enable == 1) && (
                <div className="topAdtnFtrHeader">
                    <div className="customContainer">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <NavLink className="cntUs" to="/contact_us">
                                    {t('contact_us')}
                                </NavLink>
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                {global?.pluginConfiguration?.currency_converter?.enable == 1 ? (
                                    <CurrencyConverter />
                                ) : null}
                                {global?.pluginConfiguration?.language_manager?.enable == 1 ? (
                                    <LanguageManer />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <header className="mainHeader noPrint">
                <div className="customContainer d-flex align-items-center h-100">
                    <div className="col-md-4 pl-0 col-4">
                        <IconButton
                            aria-label="delete"
                            id="step1"
                            className="hdrMenuIcon mr-3"
                            onClick={toggleDrawer('left', true)}
                        >
                            <svg width="43" height="43" viewBox="0 0 43 43">
                                <path
                                    d="M6.427,18.073H26.071V16.74H6.427v1.334Zm0-8.79v1.334H26.071V9.283H6.427Z"
                                    transform="translate(5.573 7.917)"
                                    fill="#0a0a0a"
                                />
                                <g fill="none" stroke="#0a0a0a" stroke-width="1">
                                    <circle cx="21.5" cy="21.5" r="21.5" stroke="none" />
                                    <circle cx="21.5" cy="21.5" r="21" fill="none" />
                                </g>
                            </svg>
                        </IconButton>
                        <span className="headermenuTag">{t('MENU')}</span>
                        {/* <Button
                            id="step1"
                            className="hdrMenuIcon"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={toggleDrawer('left', true)}
                        >
                            <span className="material-icons">menu</span>
                        </Button> */}
                    </div>
                    <div className="headLt d-flex justify-content-center align-items-center col-md-4 col-4">
                        <Logo className="headerLogo" />
                    </div>
                    <div className="rtPd col-md-4 text-right d-flex align-items-center justify-content-end col-4 pr-0">
                        {isMobile ? (
                            <>
                                <div className="add-button-sell pr-0">
                                    <NavLink
                                        activeClassName="active"
                                        to={
                                            user && user.role == 1
                                                ? '/post-project?type=auction'
                                                : user && user.role != 1
                                                ? '/becomeSeller?u=1'
                                                : '/register'
                                        }
                                    >
                                        <PrimaryButton btnSize="small" label={t('sell_now')} />
                                    </NavLink>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="add-button-sell pr-0 d-flex align-items-center justify-content-end">
                                    <NavLink
                                        activeClassName="active"
                                        to={
                                            user && user.role == 1
                                                ? '/post-project?type=auction'
                                                : user && user.role != 1
                                                ? '/becomeSeller?u=1'
                                                : '/register'
                                        }
                                    >
                                        <PrimaryButton label={t('sell_now')} />
                                    </NavLink>
                                    {!isAuthenticated ? (
                                        <>
                                            <NavLink
                                                button
                                                to="#"
                                                onClick={() => setLogPopup(true)}
                                                className="borRtt"
                                            >
                                                {t('login')}
                                            </NavLink>
                                            <NavLink
                                                button
                                                to="#"
                                                onClick={() => setRegPopup(true)}
                                            >
                                                {t('signup')}
                                            </NavLink>
                                        </>
                                    ) : null}
                                </div>

                                {isAuthenticated && (
                                    <div className="d-flex align-items-center justify-content-end text-capitalize pl-3">
                                        <Button
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={userHandleClick}
                                        >
                                            <svg
                                                width="15.567"
                                                height="16.638"
                                                viewBox="0 0 15.567 16.638"
                                                className="mr-2"
                                            >
                                                <g transform="translate(0.7 0.7)">
                                                    <path
                                                        d="M20.167,27.813V26.042A3.542,3.542,0,0,0,16.626,22.5H9.542A3.542,3.542,0,0,0,6,26.042v1.771"
                                                        transform="translate(-6 -11.874)"
                                                        fill="none"
                                                        stroke="#000"
                                                        stroke-linejoin="round"
                                                        stroke-width="1.4"
                                                    />
                                                    <path
                                                        d="M19.084,8.042A3.542,3.542,0,1,1,15.542,4.5a3.542,3.542,0,0,1,3.542,3.542Z"
                                                        transform="translate(-8.458 -4.5)"
                                                        fill="none"
                                                        stroke="#000"
                                                        stroke-linejoin="round"
                                                        stroke-width="1.4"
                                                    />
                                                </g>
                                            </svg>
                                            Hi, {getUsername()}
                                            <span className="material-icons ml-2">
                                                keyboard_arrow_down
                                            </span>
                                        </Button>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={useropen}
                                            keepMounted
                                            open={Boolean(useropen)}
                                            style={{ top: '50px' }}
                                            onClose={userHandleClose}
                                            className="sortMenu"
                                        >
                                            <MenuItem
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        'dashboard/profile',
                                                    )
                                                }
                                            >
                                                My Account
                                            </MenuItem>
                                            <MenuItem onClick={logoutUser}>Logout</MenuItem>
                                        </Menu>
                                        <li className="this_cart">
                                            <CartIcon className="carting"></CartIcon>
                                        </li>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </header>

            <PaymentPendingHeader />
            {user?.card_profileid == '' && userCount.itemsoldCount > 0 ? (
                <div className="nocardBanner">
                    <p
                        className="m-0"
                        onClick={() => handleRedirectInternal(history, 'dashboard/profile')}
                    >
                        You have sold products, Please finalize your seller account setup to receive
                        payouts!
                    </p>
                </div>
            ) : (
                ''
            )}
            <React.Fragment>
                <SwipeableDrawer
                    className="mnSideMenu"
                    anchor={'left'}
                    open={state['left']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                >
                    <div className="catgHead sds">
                        {/* <Logo className="headerLogo" /> */}
                        <IconButton className="mr-4" onClick={toggleDrawer('left', false)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="43"
                                height="43"
                                viewBox="0 0 43 43"
                            >
                                <g transform="translate(-60 -71)">
                                    <path
                                        d="M6.427,9.283v1.334H26.071V9.283H6.427Z"
                                        transform="translate(77.046 73.975) rotate(45)"
                                        fill="#0a0a0a"
                                    />
                                    <g
                                        transform="translate(60 71)"
                                        fill="none"
                                        stroke="#0a0a0a"
                                        stroke-width="1"
                                    >
                                        <circle cx="21.5" cy="21.5" r="21.5" stroke="none" />
                                        <circle cx="21.5" cy="21.5" r="21" fill="none" />
                                    </g>
                                    <path
                                        d="M0,8.791V7.457H19.644V8.79H0Z"
                                        transform="translate(94.19 91.299) rotate(135)"
                                        fill="#0a0a0a"
                                    />
                                </g>
                            </svg>
                        </IconButton>
                        <span>{t('MENU')}</span>
                    </div>
                    <Divider />
                    <div className="navList">
                        <ul className="navRespLinks">
                            {isAuthenticated && user && isMobile && (
                                <ListItem button className="text-center prsnNmTag">
                                    Hi, {user?.first_name}
                                </ListItem>
                            )}
                            <ListItem button onClick={toggleDrawer('left', false)}>
                                <NavLink activeClassName="active" to="/" exact>
                                    <span className="material-icons">
                                        <svg
                                            width="33.229"
                                            height="25.485"
                                            viewBox="0 0 33.229 25.485"
                                        >
                                            <g transform="translate(-261 -242.015)">
                                                <path
                                                    d="M28.866,19.038V30.283h-7.48v-7.5h-7.48v7.5H6.427V19.038L17.646,10.6Z"
                                                    transform="translate(259.968 236.717)"
                                                    fill="none"
                                                    stroke="gray"
                                                    stroke-width="1"
                                                />
                                                <path
                                                    d="M16.309.145,0,12.638,16.309.145,16.12,0l.189.145L16.5,0l-.189.145L32.621,12.638Z"
                                                    transform="translate(261.304 242.5)"
                                                    fill="none"
                                                    stroke="gray"
                                                    stroke-width="1"
                                                />
                                            </g>
                                        </svg>
                                    </span>
                                    <Typography>{t('home')}</Typography>
                                </NavLink>
                            </ListItem>
                            {global.storeConfigration?.fishing_theme?.value != 1 ? (
                                <ListItem
                                    button
                                    onClick={handleSellerDrop('seller')}
                                    // aria-describedby={id}
                                >
                                    <a
                                        className={
                                            window.location.pathname.includes('/seller')
                                                ? 'active'
                                                : ''
                                        }
                                    >
                                        <span className="material-icons">
                                            <svg
                                                width="29.01"
                                                height="23.918"
                                                viewBox="0 0 29.01 23.918"
                                            >
                                                <g transform="translate(-1 -4)">
                                                    <path
                                                        d="M24.009,30.941V28.127A5.627,5.627,0,0,0,18.381,22.5H7.127A5.627,5.627,0,0,0,1.5,28.127v2.814"
                                                        transform="translate(0 -3.523)"
                                                        fill="none"
                                                        stroke="gray"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1"
                                                    />
                                                    <path
                                                        d="M18.754,10.127A5.627,5.627,0,1,1,13.127,4.5a5.627,5.627,0,0,1,5.627,5.627Z"
                                                        transform="translate(-1.213)"
                                                        fill="none"
                                                        stroke="gray"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1"
                                                    />
                                                    <path
                                                        d="M34.22,30.953V28.139A5.627,5.627,0,0,0,30,22.7"
                                                        transform="translate(-4.71 -3.535)"
                                                        fill="none"
                                                        stroke="gray"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1"
                                                    />
                                                    <path
                                                        d="M24,4.695a5.6,5.6,0,0,1,4.087,5.451A5.6,5.6,0,0,1,24,15.6"
                                                        transform="translate(-3.576 -0.012)"
                                                        fill="none"
                                                        stroke="gray"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                        <Typography>{t('sellers')}</Typography>
                                        <SvgIcon
                                            className="navEndIcon"
                                            width="9.485"
                                            height="16.99"
                                            viewBox="0 0 9.485 16.99"
                                        >
                                            <path
                                                d="M0,8,8,0l8,8"
                                                transform="translate(8.495 0.495) rotate(90)"
                                                fill="none"
                                                stroke="#808080"
                                                stroke-width="1.4"
                                            />
                                        </SvgIcon>
                                    </a>
                                </ListItem>
                            ) : null}
                            <Popover
                                // id={id}
                                open={sellerDrop.seller}
                                anchorEl={sellerDrop.seller}
                                onClose={handleCloseSellerDrop}
                                className="DrawerMenuList"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <ListItem button onClick={() => handleSubmenu('seller/artist')}>
                                    <Typography>{t('artist')}</Typography>
                                </ListItem>
                                <ListItem button onClick={() => handleSubmenu('seller/gallery')}>
                                    <Typography>{t('gallery')}</Typography>
                                </ListItem>
                                <ListItem button onClick={() => handleSubmenu('seller/reseller')}>
                                    <Typography>{t('reseller')}</Typography>
                                </ListItem>
                                {/* <NavLink activeClassName="active" to="/seller/artist">
                                </NavLink>
                                <NavLink activeClassName="active" to="/seller/gallery">
                                </NavLink>
                                <NavLink activeClassName="active" to="/seller/reseller">
                                </NavLink> */}
                            </Popover>
                            {/* <Collapse
                                className="colpsLnk"
                                in={sellerDrop}
                                timeout="auto"
                                onClose={handleCloseSellerDrop}
                                unmountOnExit
                            >
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <NavLink activeClassName="active" to="/seller/artist">
                                        <Typography>{t('artist')}</Typography>
                                    </NavLink>
                                </ListItem>
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <NavLink activeClassName="active" to="/seller/gallery">
                                        <Typography>{t('gallery')}</Typography>
                                    </NavLink>
                                </ListItem>
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <NavLink activeClassName="active" to="/seller/reseller">
                                        <Typography>{t('reseller')}</Typography>
                                    </NavLink>
                                </ListItem>
                            </Collapse> */}
                            {!isAuthenticated ? (
                                <>
                                    {/* <ListItem button onClick={() => setLogPopup(true)}>
                                        <a>
                                            <span class="material-icons">login</span>
                                            <Typography>{t('login')}</Typography>
                                        </a>
                                    </ListItem>
                                    <ListItem button onClick={() => setRegPopup(true)}>
                                        <a>
                                            <span class="material-icons">how_to_reg</span>
                                            <Typography>{t('register')}</Typography>
                                        </a>
                                    </ListItem> */}
                                </>
                            ) : (
                                <>
                                    {user && user.role == 1 ? (
                                        <ListItem button onClick={toggleDrawer('left', false)}>
                                            <NavLink
                                                activeClassName="active"
                                                to="/dashboard/overview"
                                            >
                                                <span className="material-icons">
                                                    <svg
                                                        width="23.75"
                                                        height="23.75"
                                                        viewBox="0 0 23.75 23.75"
                                                    >
                                                        <path
                                                            d="M4.5,17.139H14.611V4.5H4.5Zm0,10.111H14.611V19.667H4.5Zm12.639,0H27.25V14.611H17.139Zm0-22.75v7.583H27.25V4.5Z"
                                                            transform="translate(-4 -4)"
                                                            fill="none"
                                                            stroke="gray"
                                                            stroke-width="1"
                                                        />
                                                    </svg>
                                                </span>
                                                <Typography>{t('My Dashboard')}</Typography>
                                            </NavLink>
                                        </ListItem>
                                    ) : null}
                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink activeClassName="active" to="/dashboard/profile">
                                            <span className="material-icons">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="25.209"
                                                    height="23.419"
                                                    viewBox="0 0 25.209 23.419"
                                                >
                                                    <path
                                                        id="person_FILL0_wght200_GRAD0_opsz48"
                                                        d="M212.6,330.772a5.069,5.069,0,1,1,3.711-1.483A5.035,5.035,0,0,1,212.6,330.772ZM200,343.8v-2.6a3.531,3.531,0,0,1,.682-2.13,4.617,4.617,0,0,1,1.79-1.465,30.789,30.789,0,0,1,5.178-1.783,21.033,21.033,0,0,1,4.953-.606,20.813,20.813,0,0,1,4.949.611,30.339,30.339,0,0,1,5.154,1.794,4.475,4.475,0,0,1,1.824,1.452,3.53,3.53,0,0,1,.678,2.126v2.6Zm1.385-1.385h22.439V341.2a2.216,2.216,0,0,0-.462-1.321,3.572,3.572,0,0,0-1.266-1.054A20.88,20.88,0,0,0,212.6,336.6a22.094,22.094,0,0,0-4.765.518,20.553,20.553,0,0,0-4.737,1.705,3.523,3.523,0,0,0-1.26,1.054,2.232,2.232,0,0,0-.457,1.321ZM212.6,329.387a3.744,3.744,0,1,0-2.713-1.1A3.685,3.685,0,0,0,212.6,329.387ZM212.6,325.578ZM212.6,342.418Z"
                                                        transform="translate(-200 -320.384)"
                                                        fill="gray"
                                                    />
                                                </svg>
                                            </span>
                                            <Typography>{t('My Profile')}</Typography>
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink activeClassName="active" to="/cart">
                                            <span class="material-icons thiscart">
                                                add_shopping_cart
                                            </span>
                                            <Typography>{t('Cart')}</Typography>
                                        </NavLink>
                                    </ListItem>
                                    {/* <hr className="sdBrDvdr" /> */}
                                    {/* <h4 className="sdDvdrHdr">{t('buyer')}</h4> */}
                                    {global.storeConfigration?.fishing_theme?.value != 1 ? (
                                        <ListItem
                                            button
                                            onClick={handleSellerDrop('buyerAccount')}
                                            // aria-describedby={id}
                                        >
                                            <a
                                                className={
                                                    window.location.pathname.includes('/mybids')
                                                        ? 'active'
                                                        : ''
                                                }
                                            >
                                                <span className="material-icons">
                                                    <svg
                                                        width="23.386"
                                                        height="25.873"
                                                        viewBox="0 0 23.386 25.873"
                                                    >
                                                        <g transform="translate(-4 -2.5)">
                                                            <path
                                                                d="M8.231,3,4.5,7.975V25.386a2.487,2.487,0,0,0,2.487,2.487H24.4a2.487,2.487,0,0,0,2.487-2.487V7.975L23.155,3Z"
                                                                transform="translate(0 0)"
                                                                fill="none"
                                                                stroke="gray"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1"
                                                            />
                                                            <path
                                                                d="M4.5,9H26.886"
                                                                transform="translate(0 -0.087)"
                                                                fill="none"
                                                                stroke="gray"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1"
                                                            />
                                                            <path
                                                                d="M24.492,15c0,3.059-2.8,5.54-6.246,5.54S12,18.059,12,15"
                                                                transform="translate(-2.553 -2.333)"
                                                                fill="none"
                                                                stroke="gray"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="1"
                                                            />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <Typography>{t('My Buyer Account')}</Typography>
                                                <SvgIcon
                                                    className="navEndIcon"
                                                    width="9.485"
                                                    height="16.99"
                                                    viewBox="0 0 9.485 16.99"
                                                >
                                                    <path
                                                        d="M0,8,8,0l8,8"
                                                        transform="translate(8.495 0.495) rotate(90)"
                                                        fill="none"
                                                        stroke="#808080"
                                                        stroke-width="1.4"
                                                    />
                                                </SvgIcon>
                                            </a>
                                        </ListItem>
                                    ) : null}
                                    <Popover
                                        // id={id}
                                        open={sellerDrop.buyerAccount}
                                        anchorEl={sellerDrop.buyerAccount}
                                        onClose={handleCloseSellerDrop}
                                        className="DrawerMenuList"
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <ListItem button onClick={toggleDrawer('left', false)}>
                                            <NavLink
                                                activeClassName="active"
                                                to="/dashboard/mybids"
                                            >
                                                <Typography>{t('My Bids')}</Typography>
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button onClick={toggleDrawer('left', false)}>
                                            <NavLink
                                                activeClassName="active"
                                                to="/dashboard/favorites"
                                            >
                                                <Typography>{t('My Favorites')}</Typography>
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button onClick={toggleDrawer('left', false)}>
                                            <NavLink
                                                activeClassName="active"
                                                to="/dashboard/savedSearch"
                                            >
                                                <Typography>{t('Saved Searches')}</Typography>
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button onClick={toggleDrawer('left', false)}>
                                            <NavLink activeClassName="active" to="/dashboard/cards">
                                                <Typography>{t('Saved Cards')}</Typography>
                                            </NavLink>
                                        </ListItem>
                                        <ListItem button onClick={toggleDrawer('left', false)}>
                                            <NavLink
                                                activeClassName="active"
                                                to="/dashboard/preference"
                                            >
                                                <Typography>{t('Preferences')}</Typography>
                                            </NavLink>
                                        </ListItem>
                                    </Popover>
                                    {/* <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink activeClassName="active" to="/dashboard/mybids">
                                            <span className="material-icons">gavel</span>
                                            <Typography>{t('my_bid')}</Typography>
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink activeClassName="active" to="/dashboard/favorites">
                                            <span className="material-icons">favorite</span>
                                            <Typography>{t('my_fav')}</Typography>
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink
                                            activeClassName="active"
                                            to="/dashboard/savedSearch"
                                        >
                                            <span className="material-icons">bookmark</span>
                                            <Typography>{t('saved_search')}</Typography>
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink activeClassName="active" to="/dashboard/cards">
                                            <span className="material-icons">credit_card</span>
                                            <Typography>{t('saved_cards')}</Typography>
                                        </NavLink>
                                    </ListItem>{' '}
                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink
                                            activeClassName="active"
                                            to="/dashboard/preference"
                                        >
                                            <span className="material-icons">notifications</span>
                                            <Typography>{t('preference')}</Typography>
                                        </NavLink>
                                    </ListItem> */}
                                    {user && user.role != 1 && (
                                        <>
                                            {/* <hr className="sdBrDvdr mb-0" /> */}
                                            <ListItem button onClick={toggleDrawer('left', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/becomeSeller?u=1"
                                                >
                                                    <span className="material-icons">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="23.509"
                                                            height="27.459"
                                                            viewBox="0 0 23.509 27.459"
                                                        >
                                                            <g transform="translate(1302.011 -210.541)">
                                                                <g transform="translate(-1303.011 206.541)">
                                                                    <path
                                                                        d="M24.009,30.941V28.127A5.627,5.627,0,0,0,18.381,22.5H7.127A5.627,5.627,0,0,0,1.5,28.127v2.814"
                                                                        transform="translate(0 -3.523)"
                                                                        fill="none"
                                                                        stroke="gray"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-width="1"
                                                                    />
                                                                    <path
                                                                        d="M18.754,10.127A5.627,5.627,0,1,1,13.127,4.5a5.627,5.627,0,0,1,5.627,5.627Z"
                                                                        transform="translate(-1.213)"
                                                                        fill="none"
                                                                        stroke="gray"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-width="1"
                                                                    />
                                                                </g>
                                                                <g transform="translate(-1299.257 224.5)">
                                                                    <path
                                                                        d="M6,3,4.5,5v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V5L12,3Z"
                                                                        transform="translate(0 0)"
                                                                        fill="none"
                                                                        stroke="gray"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-width="1"
                                                                    />
                                                                    <path
                                                                        d="M4.5,9h9"
                                                                        transform="translate(0 -3.623)"
                                                                        fill="none"
                                                                        stroke="gray"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-width="1"
                                                                    />
                                                                    <path
                                                                        d="M17.022,15a2.382,2.382,0,0,1-2.511,2.227A2.382,2.382,0,0,1,12,15"
                                                                        transform="translate(-5.511 -8.114)"
                                                                        fill="none"
                                                                        stroke="gray"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-width="1"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>

                                                    <Typography>{t('become_a_seller')}</Typography>
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}
                                    {user && user.role == 1 && (
                                        <>
                                            {global.storeConfigration?.fishing_theme?.value != 1 ? (
                                                <ListItem
                                                    button
                                                    onClick={handleSellerDrop('sellerAccount')}
                                                    // aria-describedby={id}
                                                >
                                                    <a
                                                        className={
                                                            window.location.pathname.includes(
                                                                '/buyer',
                                                            )
                                                                ? 'active'
                                                                : ''
                                                        }
                                                    >
                                                        <span className="material-icons">
                                                            <svg
                                                                width="27.517"
                                                                height="22.213"
                                                                viewBox="0 0 27.517 22.213"
                                                            >
                                                                <path
                                                                    d="M20.631,9.361,11.852.582A1.989,1.989,0,0,0,10.446,0H1.989A1.989,1.989,0,0,0,0,1.989v8.457a1.989,1.989,0,0,0,.582,1.406l8.779,8.779a1.989,1.989,0,0,0,2.813,0l8.457-8.457a1.989,1.989,0,0,0,0-2.813ZM4.64,6.629A1.989,1.989,0,1,1,6.629,4.64,1.989,1.989,0,0,1,4.64,6.629Zm21.294,5.545-8.457,8.457a1.989,1.989,0,0,1-2.813,0l-.015-.015L21.861,13.4a3.729,3.729,0,0,0,0-5.273L13.731,0h2.019a1.989,1.989,0,0,1,1.406.582l8.779,8.779A1.989,1.989,0,0,1,25.934,12.174Z"
                                                                    transform="translate(0.5 0.5)"
                                                                    fill="none"
                                                                    stroke="gray"
                                                                    stroke-width="1"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <Typography>
                                                            {t('My Seller Account')}
                                                        </Typography>
                                                        <SvgIcon
                                                            className="navEndIcon"
                                                            width="9.485"
                                                            height="16.99"
                                                            viewBox="0 0 9.485 16.99"
                                                        >
                                                            <path
                                                                d="M0,8,8,0l8,8"
                                                                transform="translate(8.495 0.495) rotate(90)"
                                                                fill="none"
                                                                stroke="#808080"
                                                                stroke-width="1.4"
                                                            />
                                                        </SvgIcon>
                                                    </a>
                                                </ListItem>
                                            ) : null}
                                            <Popover
                                                // id={id}
                                                open={sellerDrop.sellerAccount}
                                                anchorEl={sellerDrop.sellerAccount}
                                                onClose={handleCloseSellerDrop}
                                                className="DrawerMenuList"
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <ListItem
                                                    button
                                                    onClick={toggleDrawer('left', false)}
                                                >
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/post-project?type=auction"
                                                        className={
                                                            window.location.pathname.includes(
                                                                '/post-project',
                                                            ) && newUrl.get('type') == 'auction'
                                                                ? 'active'
                                                                : 'inactive'
                                                        }
                                                        onClick={() =>
                                                            handleSellClick(
                                                                'post-project?type=auction',
                                                            )
                                                        }
                                                    >
                                                        <Typography>
                                                            {t('post_single_lot')}
                                                        </Typography>
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem
                                                    button
                                                    onClick={toggleDrawer('left', false)}
                                                >
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/mylots"
                                                    >
                                                        <Typography>{t('my_lots')}</Typography>
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem
                                                    button
                                                    onClick={toggleDrawer('left', false)}
                                                >
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/Invoices"
                                                    >
                                                        <Typography>{t('Invoice')}</Typography>
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem
                                                    button
                                                    onClick={toggleDrawer('left', false)}
                                                >
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/Payoutseller"
                                                    >
                                                        <Typography>{t('Payout')}</Typography>
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem
                                                    button
                                                    onClick={toggleDrawer('left', false)}
                                                >
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/reports"
                                                    >
                                                        <Typography>{t('reports')}</Typography>
                                                    </NavLink>
                                                </ListItem>

                                                {global.pluginConfiguration?.reseller_subscription
                                                    ?.enable == 1 ? (
                                                    <ListItem button>
                                                        <NavLink
                                                            to="/dashboard/subscription"
                                                            activeClassName="active"
                                                        >
                                                            <Typography>
                                                                {t('subscription')}
                                                            </Typography>
                                                        </NavLink>
                                                    </ListItem>
                                                ) : (
                                                    ''
                                                )}
                                                {global.storeConfigration?.remove_message_module
                                                    ?.value != 1 ? (
                                                    <ListItem button>
                                                        <NavLink
                                                            to="/dashboard/message"
                                                            activeClassName="active"
                                                        >
                                                            <span className="material-icons">
                                                                question_answer
                                                            </span>
                                                            <Typography>{t('messages')}</Typography>
                                                        </NavLink>
                                                    </ListItem>
                                                ) : null}
                                            </Popover>
                                            {/* <hr className="sdBrDvdr" />
                                            <h4 className="sdDvdrHdr">{t('seller')}</h4> */}
                                            {/* <ListItem
                                                button
                                                id="step2"
                                                onClick={toggleDrawer('left', false)}
                                            >
                                                <a
                                                    className={
                                                        window.location.pathname.includes(
                                                            '/post-project',
                                                        ) && newUrl.get('type') == 'auction'
                                                            ? 'active'
                                                            : 'inactive'
                                                    }
                                                    onClick={() =>
                                                        handleSellClick('post-project?type=auction')
                                                    }
                                                >
                                                    <span class="material-icons">inventory</span>
                                                    <Typography>{t('post_single_lot')}</Typography>
                                                </a>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/sellerdashboard"
                                                >
                                                    <span className="material-icons">store</span>
                                                    My Auctions
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button onClick={toggleDrawer('left', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mylots"
                                                >
                                                    <span className="material-icons">store</span>
                                                    <Typography>{t('my_lots')}</Typography>
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button onClick={toggleDrawer('left', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/invoices"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    <Typography>{t('Invoice')}</Typography>
                                                </NavLink>
                                            </ListItem>{' '}
                                            <ListItem button>
                                                <NavLink
                                                    to="/Payoutseller"
                                                    activeClassName="active"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    <Typography>{t('payout')}</Typography>
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button onClick={toggleDrawer('left', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/reports"
                                                >
                                                    <span className="material-icons">
                                                        description
                                                    </span>
                                                    <Typography>{t('reports')}</Typography>
                                                </NavLink>
                                            </ListItem>{' '}
                                            {global.pluginConfiguration?.reseller_subscription
                                                ?.enable == 1 ? (
                                                <ListItem button>
                                                    <NavLink
                                                        to="/dashboard/subscription"
                                                        activeClassName="active"
                                                    >
                                                        <span className="material-icons">
                                                            loyalty
                                                        </span>
                                                        <Typography>{t('subscription')}</Typography>
                                                    </NavLink>
                                                </ListItem>
                                            ) : (
                                                ''
                                            )}
                                            {global.storeConfigration?.remove_message_module
                                                ?.value != 1 ? (
                                                <ListItem button>
                                                    <NavLink
                                                        to="/dashboard/message"
                                                        activeClassName="active"
                                                    >
                                                        <span className="material-icons">
                                                            question_answer
                                                        </span>
                                                        <Typography>{t('messages')}</Typography>
                                                    </NavLink>
                                                </ListItem>
                                            ) : null} */}
                                        </>
                                    )}
                                    {/* <hr className="sdBrDvdr" /> */}
                                    {/* <ListItem button onClick={toggleDrawer('left', false)}>
                                        <NavLink activeClassName="active" to="/dashboard/profile">
                                            <span className="material-icons">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="23.75"
                                                    height="23.75"
                                                    viewBox="0 0 23.75 23.75"
                                                >
                                                    <path
                                                        d="M4.5,17.139H14.611V4.5H4.5Zm0,10.111H14.611V19.667H4.5Zm12.639,0H27.25V14.611H17.139Zm0-22.75v7.583H27.25V4.5Z"
                                                        transform="translate(-4 -4)"
                                                        fill="none"
                                                        stroke="gray"
                                                        stroke-width="1"
                                                    />
                                                </svg>
                                            </span>
                                            <Typography>My Dashboard</Typography>
                                        </NavLink>
                                    </ListItem> */}
                                </>
                            )}

                            <ListItem button onClick={toggleDrawer('left', false)}>
                                <NavLink activeClassName="active" to="/how_it_works">
                                    <span className="material-icons">
                                        <svg
                                            width="27.97"
                                            height="20.828"
                                            viewBox="0 0 27.97 20.828"
                                        >
                                            <g transform="translate(-67.755 -467.906)">
                                                <path
                                                    d="M101.864,91.043a16.273,16.273,0,0,0-1.912.112.482.482,0,0,0-.426.479v1.528H96.734a.482.482,0,0,0-.482.482v17.746a.482.482,0,0,0,.7.428,21.826,21.826,0,0,1,13.208-1.714h.013a1.69,1.69,0,0,0,.121,0h.013a21.826,21.826,0,0,1,13.208,1.714h0a.482.482,0,0,0,.7-.428V93.643a.482.482,0,0,0-.482-.482h-2.793V91.633a.482.482,0,0,0-.426-.479,16.226,16.226,0,0,0-2.181-.109,14.141,14.141,0,0,0-8.1,2.63,14.144,14.144,0,0,0-8.1-2.63c-.09,0-.18,0-.27,0Zm.051.964.2,0a13.146,13.146,0,0,1,7.639,2.513v14.17a14.5,14.5,0,0,0-9.264-2.239V92.071c.479-.043.954-.066,1.425-.065Zm16.643,0c.47,0,.946.022,1.425.065v14.382a14.5,14.5,0,0,0-9.264,2.239V94.522a13.146,13.146,0,0,1,7.639-2.513l.2,0ZM97.216,94.125h2.31v12.858h0a.482.482,0,0,0,.538.479,13.818,13.818,0,0,1,8.17,1.446,22.274,22.274,0,0,0-11.018,1.71Zm23.731,0h2.311v16.492a22.276,22.276,0,0,0-11.019-1.71,13.818,13.818,0,0,1,8.17-1.446h0a.482.482,0,0,0,.538-.479Z"
                                                    transform="translate(-28.497 376.863)"
                                                    fill="gray"
                                                    fill-rule="evenodd"
                                                />
                                                <g transform="translate(10)">
                                                    <path
                                                        d="M326.759,148.981a.305.305,0,0,1-.044.164.131.131,0,0,1-.106.068H323.9c-.083,0-.15-.1-.15-.231s.067-.231.15-.231h2.708a.131.131,0,0,1,.106.068A.305.305,0,0,1,326.759,148.981Z"
                                                        transform="translate(-249.927 324.105)"
                                                        fill="gray"
                                                    />
                                                    <path
                                                        d="M303.518,210.231a.231.231,0,0,1-.231.231h-5.555a.231.231,0,0,1,0-.463h5.555a.231.231,0,0,1,.231.231Z"
                                                        transform="translate(-223.677 264.475)"
                                                        fill="gray"
                                                    />
                                                    <path
                                                        d="M303.518,262.731a.231.231,0,0,1-.231.231h-5.555a.231.231,0,0,1,0-.463h5.555a.231.231,0,0,1,.231.231Z"
                                                        transform="translate(-223.677 213.363)"
                                                        fill="gray"
                                                    />
                                                    <path
                                                        d="M303.518,262.731a.231.231,0,0,1-.231.231h-5.555a.231.231,0,0,1,0-.463h5.555a.231.231,0,0,1,.231.231Z"
                                                        transform="translate(-223.677 214.752)"
                                                        fill="gray"
                                                    />
                                                    <path
                                                        d="M303.518,262.731a.231.231,0,0,1-.231.231h-5.555a.231.231,0,0,1,0-.463h5.555a.231.231,0,0,1,.231.231Z"
                                                        transform="translate(-223.677 216.141)"
                                                        fill="gray"
                                                    />
                                                    <path
                                                        d="M303.518,262.731a.231.231,0,0,1-.231.231h-5.555a.231.231,0,0,1,0-.463h5.555a.231.231,0,0,1,.231.231Z"
                                                        transform="translate(-223.677 217.529)"
                                                        fill="gray"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <Typography>{t('how_it_wors')}</Typography>
                                </NavLink>
                            </ListItem>
                            {global.storeDetails?.terms && (
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <NavLink activeClassName="active" to="/faq">
                                        <span className="material-icons">quiz</span>
                                        <Typography>{t('faq')}</Typography>
                                    </NavLink>
                                </ListItem>
                            )}
                            {global.storeDetails?.terms_condition && (
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <NavLink activeClassName="active" to="/termsOfService">
                                        <span className="material-icons">
                                            <svg
                                                width="26.143"
                                                height="27.786"
                                                viewBox="0 0 26.143 27.786"
                                            >
                                                <g transform="translate(-124.707 -43.75)">
                                                    <path
                                                        d="M149.335,43.75H133.168a1.52,1.52,0,0,0-1.516,1.516V45.7l-5.83,1.556a1.487,1.487,0,0,0-.919.7,1.466,1.466,0,0,0-.142,1.127l5.886,21.36a1.516,1.516,0,0,0,1.844,1.046l5.5-1.465h11.346A1.52,1.52,0,0,0,150.85,68.5V45.266a1.52,1.52,0,0,0-1.516-1.516Zm-17.1,26.761a.5.5,0,0,1-.611-.339l-5.891-21.36a.477.477,0,0,1,.046-.354.486.486,0,0,1,.3-.233l5.572-1.485V68.5a1.52,1.52,0,0,0,1.516,1.516h.9ZM149.84,68.5a.505.505,0,0,1-.505.505H133.168a.505.505,0,0,1-.505-.505V45.266a.505.505,0,0,1,.505-.505h16.166a.505.505,0,0,1,.505.505Z"
                                                        fill="gray"
                                                    />
                                                    <path
                                                        d="M330.318,149.255a.667.667,0,0,1-.1.357.286.286,0,0,1-.232.148h-5.911c-.181,0-.328-.226-.328-.505s.147-.505.328-.505h5.911a.286.286,0,0,1,.232.148A.667.667,0,0,1,330.318,149.255Z"
                                                        transform="translate(-189.066 -98.938)"
                                                        fill="gray"
                                                    />
                                                    <path
                                                        d="M310.635,210.505a.505.505,0,0,1-.505.505H298.005a.505.505,0,1,1,0-1.01H310.13a.505.505,0,0,1,.505.505Z"
                                                        transform="translate(-162.816 -156.651)"
                                                        fill="gray"
                                                    />
                                                    <path
                                                        d="M310.635,263.005a.505.505,0,0,1-.505.505H298.005a.505.505,0,1,1,0-1.01H310.13a.505.505,0,0,1,.505.505Z"
                                                        transform="translate(-162.816 -206.12)"
                                                        fill="gray"
                                                    />
                                                    <path
                                                        d="M310.635,315.505a.505.505,0,0,1-.505.505H298.005a.505.505,0,1,1,0-1.01H310.13a.505.505,0,0,1,.505.505Z"
                                                        transform="translate(-162.816 -255.589)"
                                                        fill="gray"
                                                    />
                                                    <path
                                                        d="M387.012,374.814a1.01,1.01,0,0,1-.5.626.005.005,0,0,1-.005.005,1.113,1.113,0,0,1-.844.091,2.3,2.3,0,0,1-.687-.379c-.066-.051-.136-.1-.207-.141a.518.518,0,0,0-.248-.1,1.444,1.444,0,0,0-.156.222l-.04.066a1.231,1.231,0,0,1-.637.561.766.766,0,0,1-.652-.091.794.794,0,0,1-.364-.581.865.865,0,0,1,.02-.328l-.02.025h0a1.921,1.921,0,0,1-.652.672.951.951,0,0,1-.8.04.921.921,0,0,1-.515-.581,1.34,1.34,0,0,1,.227-1.1l.091-.126a.988.988,0,0,0,.172-.3.416.416,0,0,0-.1-.323.423.423,0,0,0-.3-.126,1.263,1.263,0,0,0-1.157.834,2.375,2.375,0,0,0,.061,1.955.505.505,0,1,1-.889.475,3.354,3.354,0,0,1-.091-2.844,2.265,2.265,0,0,1,2.167-1.43,1.459,1.459,0,0,1,.955.45,1.421,1.421,0,0,1,.339,1.228,1.509,1.509,0,0,1-.1.278,1.214,1.214,0,0,1,.692-.44.893.893,0,0,1,.758.227.961.961,0,0,1,.293.505.731.731,0,0,1,.1-.081,1.186,1.186,0,0,1,1.384.046c.091.055.177.121.263.182a1.518,1.518,0,0,0,.379.227.124.124,0,0,0,.076,0,.537.537,0,0,1,.227-.3.526.526,0,0,1,.409-.061.5.5,0,0,1,.354.611Z"
                                                        transform="translate(-239.087 -309.245)"
                                                        fill="gray"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                        <Typography>{t('terms_conditions')}</Typography>
                                    </NavLink>
                                </ListItem>
                            )}
                            {global.storeDetails?.privacy_policy && (
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <NavLink activeClassName="active" to="/privacy_policy">
                                        <span className="material-icons">
                                            <svg
                                                width="20.617"
                                                height="25.299"
                                                viewBox="0 0 20.617 25.299"
                                            >
                                                <g transform="translate(-66.489 -495.139)">
                                                    <path
                                                        d="M15.809,27.3s9.809-4.86,9.809-12.15v-8.5L15.809,3,6,6.645v8.5C6,22.44,15.809,27.3,15.809,27.3Z"
                                                        transform="translate(60.989 492.639)"
                                                        fill="none"
                                                        stroke="gray"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1"
                                                    />
                                                    <path
                                                        d="M12.764,3,11.737,4.028M7.826,7.938a2.826,2.826,0,1,1-4,0,2.826,2.826,0,0,1,4,0Zm0,0L9.938,5.826m0,0L11.48,7.368l1.8-1.8L11.737,4.028m-1.8,1.8,1.8-1.8"
                                                        transform="translate(68.659 499.907)"
                                                        fill="none"
                                                        stroke="gray"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="1"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                        <Typography>{t('Privacy_Policy')}</Typography>
                                    </NavLink>
                                </ListItem>
                            )}

                            {global.storeDetails?.privacy && (
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <NavLink activeClassName="active" to="/aboutus">
                                        <span class="material-icons">
                                            <svg
                                                width="25.882"
                                                height="23.958"
                                                viewBox="0 0 25.882 23.958"
                                            >
                                                <g transform="translate(-68.981 -523.452)">
                                                    <text
                                                        fill="gray"
                                                        font-size="13"
                                                        font-family="Roboto-Regular, Roboto"
                                                    >
                                                        <tspan x="0" y="0">
                                                            i
                                                        </tspan>
                                                    </text>
                                                    <path
                                                        d="M15.822,4.5c6.869,0,12.435,4.5,12.435,10.044,0,3.88-2.726,7.246-6.714,8.92a7.813,7.813,0,0,0,2,3.617.237.237,0,0,1-.215.377,9.619,9.619,0,0,1-3.049-.759,9.434,9.434,0,0,1-2.768-2.206,15.916,15.916,0,0,1-1.7.1c-6.869,0-12.435-4.5-12.435-10.044S8.953,4.5,15.822,4.5Z"
                                                        transform="translate(66.106 519.452)"
                                                        fill="none"
                                                        stroke="gray"
                                                        stroke-width="1"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                        <Typography>{t('about_us')}</Typography>
                                    </NavLink>
                                </ListItem>
                            )}

                            <ListItem button onClick={toggleDrawer('left', false)}>
                                <NavLink activeClassName="active" exact to="/contact_us">
                                    <span className="material-icons">
                                        <svg
                                            width="25.015"
                                            height="18.188"
                                            viewBox="0 0 25.015 18.188"
                                        >
                                            <path
                                                d="M24.613,6H5.4A2.4,2.4,0,0,0,3.012,8.4L3,20.786a2.409,2.409,0,0,0,2.4,2.4H24.613a2.409,2.409,0,0,0,2.4-2.4V8.4A2.409,2.409,0,0,0,24.613,6Zm0,2.4-9.606,8.028L5.4,8.4h0l9.606,8.028L24.613,8.4Z"
                                                transform="translate(-2.5 -5.5)"
                                                fill="none"
                                                stroke="gray"
                                                stroke-width="1"
                                            />
                                        </svg>
                                    </span>
                                    <Typography>{t('contact_us')}</Typography>
                                </NavLink>
                            </ListItem>

                            {isAuthenticated ? (
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <a onClick={logoutUser}>
                                        <span className="material-icons">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="23.725"
                                                height="24.062"
                                                viewBox="0 0 23.725 24.062"
                                            >
                                                <path
                                                    id="power_settings_new_FILL0_wght200_GRAD0_opsz48"
                                                    d="M171.292,261.836V248.154h1.141v13.682Zm.57,10.38a11.4,11.4,0,0,1-4.615-.942,12.3,12.3,0,0,1-3.774-2.545,11.763,11.763,0,0,1-2.545-3.774,11.583,11.583,0,0,1-.928-4.6,11.222,11.222,0,0,1,1.146-5.015,12.376,12.376,0,0,1,3.188-4.051l.787.787a10.62,10.62,0,0,0-2.93,3.657,10.723,10.723,0,1,0,20.393,4.62,10.641,10.641,0,0,0-1.037-4.643,10.074,10.074,0,0,0-2.9-3.634l.8-.787a11.778,11.778,0,0,1,3.352,13.666,11.8,11.8,0,0,1-2.541,3.774,12.248,12.248,0,0,1-3.77,2.545A11.422,11.422,0,0,1,171.863,272.216Z"
                                                    transform="translate(-160 -248.154)"
                                                    fill="gray"
                                                />
                                            </svg>
                                        </span>
                                        <Typography>{t('logout')}</Typography>
                                    </a>
                                </ListItem>
                            ) : (
                                <>
                                    {' '}
                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <a onClick={() => setLoginPopup()}>
                                            <span className="material-icons">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20.897"
                                                    height="22.75"
                                                    viewBox="0 0 20.897 22.75"
                                                >
                                                    <path
                                                        d="M177.978,278.75v-1.029h8.758a.886.886,0,0,0,.824-.824V257.853a.886.886,0,0,0-.824-.824h-8.758V256h8.758a1.846,1.846,0,0,1,1.853,1.853V276.9a1.846,1.846,0,0,1-1.853,1.853Zm-.705-7.039-.744-.718,3.1-3.1H167.692V266.86h11.9l-3.1-3.1.769-.744,4.37,4.37Z"
                                                        transform="translate(-167.692 -256)"
                                                        fill="gray"
                                                    />
                                                </svg>
                                            </span>
                                            <Typography> {t('login')}</Typography>
                                        </a>
                                    </ListItem>
                                    <ListItem button onClick={toggleDrawer('left', false)}>
                                        <a onClick={() => setSignUp()}>
                                            <span className="material-icons">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20.799"
                                                    height="22.643"
                                                    viewBox="0 0 20.799 22.643"
                                                >
                                                    <path
                                                        d="M169.536,278.643a1.838,1.838,0,0,1-1.844-1.844V257.844A1.838,1.838,0,0,1,169.536,256h8.717v1.025h-8.717a.881.881,0,0,0-.82.82V276.8a.882.882,0,0,0,.82.82h8.717v1.025Zm14.646-7.005-.766-.715,3.089-3.089H174.646v-1.025H186.49L183.4,263.72l.74-.74,4.349,4.362Z"
                                                        transform="translate(-167.692 -256)"
                                                        fill="gray"
                                                    />
                                                </svg>
                                            </span>
                                            <Typography> {t('signup')}</Typography>
                                        </a>
                                    </ListItem>
                                </>
                            )}

                            <Headernavmenu type="mobile" />

                            {/* {isAuthenticated && (
                                <ListItem button onClick={toggleDrawer('left', false)}>
                                    <a className="cursorDecoy" onClick={logoutUser}>
                                        <span className="material-icons">power_settings_new</span>
                                        <Typography>{t('logout')}</Typography>
                                    </a>
                                </ListItem>
                            )} */}
                        </ul>
                    </div>
                </SwipeableDrawer>
            </React.Fragment>

            {/* Login Signup Popups */}

            <CustomDialog
                title={
                    <IconButton className="popClrBtn" onClick={toggleOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                }
                open={open}
                function={toggleOpen}
                // maxWidth={'md'}
                className="lgnWrpr"
            >
                <div className="hrToWrpr">
                    <div className="text-center">
                        <FooterLogo className="popupLgo" />
                    </div>
                    <h2 className="text-center popHr">I'm here to</h2>
                    <div className="radCntnr d-flex align-items-center">
                        <div className="popRadio">
                            <input
                                type="radio"
                                name="hereTo"
                                id="buy"
                                onChange={() => handleTypeChange('buyer')}
                            />
                            <label for="buy">
                                <img src="/assets/svg/buyIcon.svg" />
                                <span>Buy</span>
                            </label>
                        </div>
                        <div className="popRadio">
                            <input
                                type="radio"
                                name="hereTo"
                                id="sell"
                                onChange={() => handleTypeChange('seller')}
                            />
                            <label for="sell">
                                <img src="/assets/svg/sellIcon.svg" />
                                <span>Sell</span>
                            </label>
                        </div>
                    </div>
                </div>
            </CustomDialog>
            <CustomDialog
                title={
                    <IconButton className="popClrBtn" onClick={toggleLogOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                }
                open={logPopup}
                function={toggleLogOpen}
                // maxWidth={'md'}
                className="lgnWrpr"
            >
                <div className="hrToWrpr">
                    <div className="text-center">
                        <FooterLogo className="popupLgo" />
                    </div>
                    <h2 className="text-center popHr">
                        <span>{t('log')}</span>
                        {t('in')}
                    </h2>
                    <p className="popTgLn text-center">{t('welcome') + ' ' + t('back')}</p>
                    {/* <div className="lgnWthSclMda">
                        <p className="text-center">LOGIN WITH</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <img src="/assets/svg/facebook.svg" />
                            <img src="/assets/svg/google.svg" />
                            <img src="/assets/svg/apple_login.svg" />
                        </div>
                    </div> */}
                    {/* <p className="text-center orTxt">OR</p> */}
                    <div className="lgnCntnr mt-4">
                        <LoginFunction autoComplete={1} />
                    </div>
                    <div className="newAcc mt-3 text-center">
                        <p>
                            {t('need_account')}?{' '}
                            <a className="cursorDecoy" onClick={() => setSignUp()}>
                                {t('signup')}
                            </a>
                        </p>
                    </div>
                </div>
            </CustomDialog>
            <CustomDialog
                title={
                    <IconButton className="popClrBtn" onClick={toggleForgotOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                }
                open={forgotOpen}
                function={toggleForgotOpen}
                // maxWidth={'md'}
                className="lgnWrpr"
            >
                <div className="hrToWrpr">
                    <div className="text-center">
                        <FooterLogo className="popupLgo" />
                    </div>
                    <h2 className="text-center popHr">
                        <span>{t('reset')}</span> &nbsp;{t('password')}
                    </h2>
                    <div className="lgnCntnr mt-4">
                        <ForgotPasswordFunction />
                    </div>
                </div>
            </CustomDialog>
            <CustomDialog
                title={
                    <IconButton className="popClrBtn" onClick={toggleRegOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                }
                open={regPopup}
                function={toggleRegOpen}
                className="lgnWrpr"
            >
                <div className="hrToWrpr">
                    <div className="text-center">
                        <FooterLogo className="popupLgo" />
                    </div>
                    <h2 className="text-center popHr">
                        <span>{t('sign')}</span>
                        {t('up')}
                    </h2>
                    <p className="popTgLn text-center">{t('welcome')}!</p>
                    <div className="imHrTo">
                        <p>{t('i_here_to')}</p>
                        <div className="brSlrWrp">
                            <div className="bsrIpt">
                                <input
                                    type="radio"
                                    name="regType"
                                    id="buyReg"
                                    onChange={() => setUserType('buyer')}
                                    checked={userType === 'buyer' ? true : false}
                                />
                                <label for="buyReg">{t('buy')}</label>
                            </div>
                            <div className="bsrIpt">
                                <input
                                    type="radio"
                                    name="regType"
                                    id="selReg"
                                    onChange={() => setUserType('seller')}
                                    checked={userType === 'seller' ? true : false}
                                />
                                <label for="selReg">{t('sell')}</label>
                            </div>
                        </div>
                    </div>
                    {/*<div className="lgnWthSclMda">
                        <p className="text-center">REGISTER WITH</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <img src="/assets/svg/facebook.svg" />
                            <img src="/assets/svg/google.svg" />
                            <img src="/assets/svg/apple_login.svg" />
                        </div> 
                    </div>*/}
                    {/* <p className="text-center orTxt">OR</p> */}
                    <div className="lgnCntnr mt-4">
                        <QuickSignupFunction need_seller_verify={1} userType={userType} />
                    </div>
                    <div className="newAcc mt-3 text-center">
                        <p>
                            {t('have_account')}?{' '}
                            <a className="cursorDecoy" onClick={() => setLoginPopup()}>
                                {t('login')}
                            </a>
                        </p>
                    </div>
                </div>
            </CustomDialog>
        </>
    )
}

export default React.memo(Header)
