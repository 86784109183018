import React from 'react'
import Layout from '../Layout'
import DashboardLayout from '../DashboardLayout'
import PreferenceForm from '../../../utils/CommonFunctionality/PreferenceFunction'

const Preference = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="Notification Preference">
                <PreferenceForm props={props} notifictionpara={true} />
            </DashboardLayout>
        </Layout>
    )
}

export default Preference
