import React from 'react'
import { Helmet } from 'react-helmet'

const SEOTAG = ({ page, title }) => {
    return (
        <>
            <Helmet>
                <title>{title ? title : `${page} - ${global?.storeDetails?.name}`}</title>
                <meta property="og:title" content={`${page} - ${global?.storeDetails?.name}`} />
                <meta
                    name="description"
                    content={`${global?.storeConfigration?.meta_description_all_pages?.value}`}
                />

                <meta
                    property="og:description"
                    content={`${global?.storeConfigration?.meta_description_all_pages?.value}`}
                />

                <meta
                    name="keywords"
                    content={`${global?.storeConfigration?.meta_keywords_all_pages?.value}`}
                />

                <meta property="og:image" content={global?.storeDetails?.logoValue} />

                <meta property="og:image:alt" content={`${global?.storeDetails?.name} Logo`} />
            </Helmet>
        </>
    )
}

export default SEOTAG
