import React, { useContext, useEffect, useState } from 'react'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import ProductContext from '../../../Product/context/product/productContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { mapData } from '../../../Product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const EditReservePrice = ({ product_id, title, oldReserveprice }) => {
    const [open, setOpen] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [rprice, setRprice] = useState(oldReserveprice)

    const { editReserve, responseStatus } = useContext(ProductContext)
    const { setAlert } = useContext(AlertContext)

    const validation = Yup.object({
        rprice: Yup.number()
            .positive('Should be greater than 1')
            .required('Reserve Price Required!'),
    })
    const formik = useFormik({
        initialValues: {
            rprice: oldReserveprice,
        },
        validationSchema: validation,
        onSubmit: (values) => {
            if (!values.rprice) {
                return setAlert('Invalid Reserve Price!', 'error')
            }
            setBtnLoading(true)
            editReserve({ product_id, rprice: values.rprice })
        },
    })
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'success') {
                if (responseStatus.from === 'editReserve') {
                    setOpen(false)
                    formik.handleReset()
                }
            }
        }
    }, [responseStatus])
    const fields = [
        {
            label: 'Reserve price',
            placeholder: 'Enter reserve price',
            type: 'number',
            class: `col-12`,
            name: 'rprice',
            value: formik.values.rprice,
            formik,
        },
    ]

    return (
        <div>
            <Button className="ml-2" variant="outlined" onClick={() => setOpen(true)}>
                Edit Reserve Price
            </Button>
            <CustomDialog title={'Edit Reserve Price'} open={open} function={setOpen}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h5>Product Name: {title}</h5>
                            {mapData(fields)}
                        </div>
                        <div className="col-md-6">
                            <Button onClick={() => setOpen(false)} disabled={btnLoading}>
                                {'Cancel'}
                            </Button>
                        </div>
                        <div className="col-md-6">
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                type="button"
                                disabled={btnLoading}
                                label={btnLoading ? 'Loading...' : 'Confirm'}
                            />
                        </div>
                    </div>
                </div>
            </CustomDialog>
        </div>
    )
}

export default EditReservePrice
