import React, { useEffect, useState, useContext } from 'react'
import { Button, Popover, ListItem, Divider, SwipeableDrawer } from '@material-ui/core'
import './footernav.css'
import { useTranslation } from 'react-i18next'
const Footernavmenu = (props) => {
    const [anchormenu, setanchormenu] = useState(null)
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }
    const { t } = useTranslation()
    const handleClose = () => {
        setanchormenu(null)
    }
    return (
        <>
            {global.storeDetails?.footer_menu ? (
                <>
                    {global.storeDetails?.footer_menu.length > 0
                        ? global.storeDetails.footer_menu.map((val) =>
                              val.subLinks.length > 0 ? (
                                  <li>
                                      <div className="nav-menu-from-store">
                                          <a
                                              //   target={'_blank'}
                                              //   rel="noreferrer"
                                              // activeClassName="active"
                                              href={val.path}
                                          >
                                              {t(val.label)}
                                          </a>

                                          <Button
                                              aria-controls="simple-menu"
                                              aria-haspopup="true"
                                              onClick={handleMenu}
                                          >
                                              {!Boolean(anchormenu) ? (
                                                  <span className="material-icons-outlined">
                                                      expand_more
                                                  </span>
                                              ) : (
                                                  <span className="material-icons-outlined">
                                                      expand_less
                                                  </span>
                                              )}
                                          </Button>
                                      </div>
                                      <Popover
                                          id={'simple-menu'}
                                          open={Boolean(anchormenu)}
                                          anchorEl={anchormenu}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'right',
                                          }}
                                          transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right',
                                          }}
                                      >
                                          <ul className="headerDropMenu">
                                              {val.subLinks.map((data) => (
                                                  <a
                                                      rel="noreferrer"
                                                      //target={'_blank'}
                                                      // activeClassName="active"
                                                      href={data.path}
                                                  >
                                                      <ListItem>{data.label}</ListItem>
                                                  </a>
                                              ))}
                                          </ul>
                                      </Popover>
                                  </li>
                              ) : (
                                  <li>
                                      <a
                                          //   target={'_blank'}
                                          //   rel="noreferrer"
                                          // activeClassName="active"
                                          href={val.path}
                                      >
                                          {t(val.label)}
                                      </a>
                                  </li>
                              ),
                          )
                        : ''}
                </>
            ) : (
                ''
            )}
        </>
    )
}
export default React.memo(Footernavmenu)
