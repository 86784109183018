import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './Auctions.css'
import { Button } from '@material-ui/core'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import Loader from '../../../assets/loader'
import Loaders from '../../../Product/components/molecules/Loaders'

const Auctions = (props) => {
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allauctionproducts, getLotList } =
        productContext
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [auctionView, setAuctionView] = useState('Grid')
    const [subLotData, setSubLotData] = useState([])
    const [auction_type, setAuction_type] = useState('')

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        is_auction: '',
        searchbar: '',
        auc_type: '',
        type: '',
    })

    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        orderby: '2',
        lotof: '',
        limit: 50,
        market_status: 'open',
        auction_io: 1,
    })

    useEffect(() => {
        setLoading(true)
        var data = auctionSearch

        if (props?.match?.params?.type) {
            var auction_type_loc = props.match.params.type
            setAuction_type(auction_type_loc)
            // //console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnn', props.match.params.type)
            if (parseInt(auction_type_loc) == 0) {
                data.auc_type = '2'
            } else if (parseInt(auction_type_loc) == 1) {
                data.auc_type = '1'
            } else {
                data.auc_type = '3'
                data.type = 'closed'
            }
        }
        getAllSearchAuctions(auctionSearch, 'activeHome')
    }, [auctionSearch])
    useEffect(() => {
        if (viewAuction.length) {
            setSubLotSearch({ ...subLotSearch, lotof: viewAuction[0].id })
        }
    }, [viewAuction])

    useEffect(() => {
        if (subLotSearch.lotof) {
            getLotList(subLotSearch, 'homePageAuction')
        }
    }, [subLotSearch])

    useEffect(() => {
        setLoading(false)
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const onHandlePage = (event, value) => {
        setAuctionSearch({ ...auctionSearch, page: value })
    }

    useEffect(() => {
        if (search_allauctionproducts.from === 'homePageAuction') {
            //console.log('search_allauctionproducts', search_allauctionproducts)
            setSubLotData(search_allauctionproducts.results)
        }
    }, [search_allauctionproducts])

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'All Auctions',
        },
        {
            value: 1,
            show: 'Upcoming Auctions',
        },
        {
            value: 2,
            show: 'Live Auctions',
        },
        {
            value: 3,
            show: 'Completed Auctions',
        },
    ]

    return (
        <Layout props={props}>
            <div className="customContainer auctionsSearch">
                <div className="d-flex justify-content-between align-items-center flex-wrap mt-5 mb-3 auctionSearchCnt">
                    <h2 className="auctionListTitle">
                        {auctionSearch.auc_type == 1
                            ? 'Upcoming'
                            : auctionSearch.auc_type == 2
                            ? 'Live'
                            : auctionSearch.auc_type == 3
                            ? 'Completed'
                            : 'All'}{' '}
                        Auctions
                    </h2>
                    <div className="auctionsMiscFilter">
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => setAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => setAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={auctionSearch.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                setAuctionSearch({ ...auctionSearch, sh_limit: event.target.value })
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        <CustomSelect
                            label="Auction Type"
                            value={auctionSearch.auc_type}
                            size="small"
                            selectType="noBorder"
                            name="auc_type"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                setAuctionSearch({
                                    ...auctionSearch,
                                    auc_type: event.target.value,
                                    type: event.target.value == 3 ? 'closed' : '',
                                    is_auction: event.target.value == 3 ? 1 : '',
                                })
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    </div>
                </div>
                <>
                    <>
                        {loading ? (
                            <div className="text-center">
                                <Loaders name="product_grid_view" isLoading={loading} loop={1} />
                            </div>
                        ) : viewAuction.length === 0 ? (
                            <NoRecordsFound />
                        ) : parseInt(auction_type) == 2 ? (
                            <div className={`searchResults ${auctionView}`}>
                                {viewAuction.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <GridAuction
                                            key={index}
                                            data={data}
                                            details={`${
                                                data.con_check
                                                    ? 'liveauctionview'
                                                    : 'search/product-buyer-auction'
                                            }/${data.id}`}
                                            timerTheme="multiseller"
                                            theme="multiseller"
                                            lotCount={data.lotCount ? data.lotCount : 0}
                                        />
                                    ) : (
                                        <MultisellerListCard
                                            key={index}
                                            data={data}
                                            allsearchitems={viewAuction}
                                            lotCount={data.lotCount ? data.lotCount : 0}
                                            details={`${
                                                data.con_check
                                                    ? 'liveauctionview'
                                                    : 'search/product-buyer-auction'
                                            }/${data.id}`}
                                        />
                                    ),
                                )}
                            </div>
                        ) : auctionSearch.auc_type == 3 ? (
                            <div className={`searchResults ${auctionView}`}>
                                {viewAuction.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <GridAuction
                                            key={index}
                                            data={data}
                                            details={`${
                                                data.con_check
                                                    ? 'liveauctionview'
                                                    : 'search/product-buyer-auction'
                                            }/${data.id}`}
                                            timerTheme="multiseller"
                                            theme="multiseller"
                                            lotCount={data.lotCount ? data.lotCount : 0}
                                        />
                                    ) : (
                                        <MultisellerListCard
                                            key={index}
                                            data={data}
                                            allsearchitems={viewAuction}
                                            lotCount={data.lotCount ? data.lotCount : 0}
                                            details={`${
                                                data.con_check
                                                    ? 'liveauctionview'
                                                    : 'search/product-buyer-auction'
                                            }/${data.id}`}
                                        />
                                    ),
                                )}
                            </div>
                        ) : (
                            <div className={`searchResults ${auctionView}`}>
                                {viewAuction
                                    .filter(
                                        (data) =>
                                            data.market_status == 'open' && data.remainingDay >= 0,
                                    )
                                    .map((data, index) =>
                                        auctionView === 'Grid' ? (
                                            <GridAuction
                                                key={index}
                                                data={data}
                                                details={`${
                                                    data.con_check
                                                        ? 'liveauctionview'
                                                        : 'search/product-buyer-auction'
                                                }/${data.id}`}
                                                timerTheme="multiseller"
                                                theme="multiseller"
                                                lotCount={data.lotCount ? data.lotCount : 0}
                                            />
                                        ) : (
                                            <MultisellerListCard
                                                key={index}
                                                data={data}
                                                allsearchitems={viewAuction}
                                                lotCount={data.lotCount ? data.lotCount : 0}
                                                details={`${
                                                    data.con_check
                                                        ? 'liveauctionview'
                                                        : 'search/product-buyer-auction'
                                                }/${data.id}`}
                                            />
                                        ),
                                    )}
                            </div>
                        )}
                    </>
                </>
            </div>
        </Layout>
    )
}

export default Auctions
