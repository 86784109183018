/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Link, useHistory } from 'react-router-dom'
import {
    currencyFormat,
    dateFormatFront,
    handleRedirectInternal,
    mapData,
} from '../../../Product/common/components'
import { Redirect } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
import Timer from '../../../Product/common/timer'
import { Button, Tooltip } from '@material-ui/core'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { getImageurl, imageError } from '../../../utils/commonFunctions'
import Loader from '../../../assets/loader'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useTranslation } from 'react-i18next'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { carrierName, shippingStatus } from '../../../utils/index'
const Invoice = (props) => {
    const productContext = useContext(ProductContext)
    const {
        seller_allproducts,
        getAllSellerProducts,
        get_seller_dashboard,
        seller_dashboard_data,
        update_shipping_details,
        responseStatus,
    } = productContext
    const { t } = useTranslation()
    const authContext = useContext(AuthContext)
    const { isAuthenticated, isAdmin, user } = authContext
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [data, setData] = useState([])
    const [userData, setUserData] = useState({})
    const [sellerData, setSellerData] = useState({})
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const [toInvoice, settoInvoice] = useState(false)
    const [toInvoiceID, settoInvoiceID] = useState({
        inv_id: 0,
        user_id: 0,
    })

    const [search, setSearch] = useState({
        status: 'sold',
        limit: 12,
        page: 1,
        paid: props.page === 'paid' ? 1 : 0,
    })
    const [open, setOpen] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [trigger, setTrigger] = useState(false)
    useEffect(() => {
        setLoading(false)
    }, [seller_allproducts])
    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    useEffect(() => {
        if (isAuthenticated) {
            setLoading(true)
            if (props.page === 'paid') {
                setLoading(true)
                getAllSellerProducts(search)
            } else if (props.page === 'unpaid') {
                setLoading(true)
                getAllSellerProducts(search)
            }
        }
    }, [search, isAuthenticated, trigger])

    const shippingValidation = Yup.object({
        shipping_status: Yup.string().required('Required'),
        tracking_number: Yup.string().required('Required'),
        carrier_name: Yup.string().required('Required'),
    })
    const formik = useFormik({
        initialValues: {
            shipping_status: '',
            tracking_number: '',
            carrier_name: '',
            inv_id: '',
        },
        validationSchema: shippingValidation,
        onSubmit: (value) => {
            setBtnLoading(true)
            update_shipping_details(value)
        },
    })
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'update_shipping_details') {
                setBtnLoading(false)
                setTrigger(!trigger)
                formik.resetForm()
                setOpen(false)
            }
        }
    }, [responseStatus])

    const shippingFields = [
        {
            label: t('shipping_status') + ' *',
            placeholder: `${t('select')} ${t('shipping_status')}`,
            type: 'select',
            class: 'col-6',
            options: shippingStatus,
            defaultOptionValue: `${t('select')} ${t('shipping_status')}`,
            name: 'shipping_status',
            formik: formik,
        },
        {
            label: t('carrier_name') + ' *',
            placeholder: `${t('select')} ${t('carrier_name')}`,
            type: 'select',
            class: 'col-6',
            options: carrierName,
            defaultOptionValue: `${t('select')} ${t('carrier_name')}`,
            name: 'carrier_name',
            formik: formik,
        },

        {
            label: t('tracking_number') + ' *',
            type: 'text',
            placeholder: `${t('enter')} ${t('tracking_number')}`,
            class: 'col-12',
            name: 'tracking_number',
            formik: formik,
        },
    ]
    return (
        <div>
            {toInvoice ? (
                <Redirect to={`invoice/${toInvoiceID.inv_id}/${toInvoiceID.user_id}`} />
            ) : null}
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : seller_allproducts.result.length ? (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="numCell">#</th>
                                    <th className="numCell">{t('invoice_Id')}</th>
                                    <th>{t('Image')}</th>
                                    <th className="invPdtName">{t('product_name')}</th>
                                    <th>{t('shipping_status')}</th>
                                    <th>{t('tracking_number')}</th>
                                    <th>{t('carrier_name')}</th>
                                    {props.page === 'paid' &&
                                    global.storeConfigration?.enable_reseller_shipping_status
                                        ?.value == '1' ? (
                                        <th className="text-center">{t('action')}</th>
                                    ) : null}
                                </tr>
                            </thead>
                            {seller_allproducts.result.map((item, index) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.common_invoice}</td>
                                                <td>
                                                    <img
                                                        className="pro-img"
                                                        src={getImageurl(
                                                            item.store_id.toString(),
                                                            item.avatar,
                                                        )}
                                                        alt={item.title}
                                                        width="65"
                                                        height="65"
                                                        onError={(e) =>
                                                            (e.target.src = imageError())
                                                        }
                                                    />
                                                </td>
                                                <td>{item.title}</td>
                                                <td
                                                    style={
                                                        item.shipping_status == 'Shipped'
                                                            ? { color: 'green' }
                                                            : { color: 'red' }
                                                    }
                                                >
                                                    {item.shipping_status
                                                        ? item.shipping_status
                                                        : '-'}
                                                </td>
                                                <td>
                                                    {item.tracking_number
                                                        ? item.tracking_number
                                                        : '-'}
                                                </td>
                                                <td>
                                                    {item.tracking_service
                                                        ? item.tracking_service
                                                        : '-'}
                                                </td>
                                                {props.page === 'paid' &&
                                                global.storeConfigration
                                                    ?.enable_reseller_shipping_status?.value ==
                                                    '1' ? (
                                                    <td>
                                                        <Button
                                                            className="chngStsBtn"
                                                            variant="outlined"
                                                            onClick={() => {
                                                                setOpen(true)
                                                                formik.setFieldValue(
                                                                    'inv_id',
                                                                    item.common_invoice,
                                                                )
                                                            }}
                                                        >
                                                            Update Shipping
                                                        </Button>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </tbody>
                                    </>
                                )
                            })}
                        </table>
                    </div>
                    <div className="d-flex mt-3">
                        <div className="ml-auto mt-2">
                            {t('showing')} {search.limit * (search.page - 1) + 1} -{' '}
                            {search.limit * search.page > seller_allproducts.length
                                ? seller_allproducts.length
                                : search.limit * search.page}{' '}
                            of {seller_allproducts.length}
                        </div>
                        <Pagination
                            count={Math.ceil(seller_allproducts.length / search.limit)}
                            page={search.page}
                            onChange={onHandlePage}
                        />
                    </div>
                    <MultisellerSlider
                        storeTheme="ArtAuction"
                        selectedLot={selectedProduct}
                        handleClose={handleClose}
                        type={props.type ? props.type : ''}
                        conditions={'Collectibles'}
                    />
                </>
            ) : (
                <>
                    <NoRecordsFound />
                </>
            )}
            {props.page === 'paid' &&
            global.storeConfigration?.enable_reseller_shipping_status?.value == '1' ? (
                <CustomDialog
                    title={'Update Shipping Details'}
                    open={open}
                    function={() => setOpen(false)}
                >
                    <div className="row">{mapData(shippingFields)}</div>
                    <div className="actionWrapper">
                        <Button onClick={() => setOpen(false)} disabled={btnLoading}>
                            {'Cancel'}
                        </Button>
                        <PrimaryButton
                            onClick={formik.handleSubmit}
                            type="button"
                            disabled={btnLoading}
                            label={btnLoading ? 'Loading...' : 'Confirm'}
                        />
                    </div>
                </CustomDialog>
            ) : null}
        </div>
    )
}

export default Invoice
