import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import SubLots from './SubLots'
import ProductAuctionTop from './ProductAuctionTop'
import SlidePanel from '../../../Component/SlidePanel'
import './product.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import Toaster from '../../../Component/Toaster'

import { socket, socketAuction } from '../../../Product/common/socket'

import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'

import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { messageHandler } from '../../../Product/common/socketHandler'

import Layout from '../Layout'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Loaders from '../../../Product/components/molecules/Loaders'
import { handleRedirectInternal } from '../../../Product/common/components'

const ProductAuctionComponent = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)

    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext

    const {
        search_allauctions,
        search_allauctionproducts,
        edit_auction,
        getLotList,
        getEditAuction,
    } = productContext

    let auctionid = props.match.params.id
    const search = useLocation().search
    const params = new URLSearchParams(search)
    const [auctionData, setAuctionData] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    const [private_auction, setPrivateauction] = useState(0)
    const [isRegistered, setIsRegistered] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        sortBy: 7,
        lotof: auctionid,
        limit: 12,
        market_status: 'open',
        auction_market_status: 'open',
        crop_auction_type: params.get('id_new') ? 'buyer' : '',
    })
    const [display_type, setType] = useState('list')
    const [loading, setLoading] = useState(true)
    let history = useHistory()

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })
    useEffect(() => {
        getEditAuction({ auctionid }, 'auctionView')
    }, [isAuthenticated, auctionid])

    useEffect(() => {
        getLotList(subLotSearch, 'auctionView')
    }, [subLotSearch, isAuthenticated])

    useEffect(() => {
        setSubLotSearch({ ...subLotSearch, lotof: auctionid })
    }, [auctionid])

    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionView') {
            setLoading(true)
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
            setLoading(false)
            // setTimeout(() => {
            //     setLoading(false)
            // }, 1500)
        }
    }, [search_allauctionproducts])
    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionView_from_sublot') {
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        setLoading(true)
        if (edit_auction.from === 'auctionView') {
            if (edit_auction.auction_details) {
                setAuctionData(edit_auction.auction_details)
                setIsRegistered(edit_auction.is_registered)
                setLoading(false)
                // setTimeout(() => {
                //     setLoading(false)
                // }, 1500)
            } else {
                setAuctionData({})
            }
        }
    }, [edit_auction])

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        if (parseInt(isRegistered) == 0 && parseInt(private_auction) == 1) {
            window.scrollTo(0, 0)
            setAlert('Please register auction and continue!', 'error')
        } else {
            handleRedirectInternal(history, `productView/${id}`)
        }
        // setSelectedProduct(id)
    }

    const viewProductRef = useRef(subLotData)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = subLotData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }
    const multiauction_handler = (message, type) => {
        var index = viewProductRef.current.findIndex(
            (s) => s.id === parseInt(message.id || message.project_id, 10),
        )
        if (index !== -1) {
            if (parseInt(viewProductRef.current[index].content_head5) == 0) {
                messageHandler(
                    message,
                    viewProductRef.current,
                    userRef.current,
                    setAlert,
                    setSubLotData,
                    type,
                )
            }
        }
    }

    useEffect(() => {
        if (auctionData?.dynamic_fields?.includes('[{')) {
            var data_obj = {}
            JSON.parse(auctionData.dynamic_fields).map((data) => {
                if (data.name == 'private_auction') {
                    data_obj.private_auction = 0
                    if (data.values.length > 0) {
                        if (data.values.filter((v) => v.selected == 'true').length > 0) {
                            data_obj.private_auction = data.values.filter(
                                (v) => v.selected == 'true',
                            )[0].value
                        }
                    }
                }
            })
            setPrivateauction(parseInt(data_obj.private_auction))
        } else {
            setPrivateauction(0)
        }
    }, [auctionData])

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            data.id = data.pid
            //data.bpop_cbidder = data.usr
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (global?.storeDetails?.theme == 9) {
                multiauction_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })
        socketAuction.on('bidAddtime', (data) => {
            if (global?.storeDetails?.theme == 9) {
                multiauction_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                if (global?.storeDetails?.theme == 9) {
                    multiauction_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })

            socketAuction.off('bidAddtime', (data) => {
                if (global?.storeDetails?.theme == 9) {
                    multiauction_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
        }
    }, [])
    const checkreverseauction = () => {
        return subLotData.filter((val) => parseInt(val.id) == parseInt(selectedProduct))[0]
            .content_head5
    }
    return (
        <Layout props={props}>
            <div className="customContainer pt-3 auctionCnt">
                {loading ? (
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                ) : (
                    <>
                        <ProductAuctionTop
                            auctionData={auctionData}
                            product_id={auctionid}
                            executeScroll={executeScroll}
                            sellerName={subLotData[0]?.seller_name}
                            is_registered={isRegistered}
                            setIsRegistered={setIsRegistered}
                            auction_id={props.match.params.id}
                        />
                        <div ref={myRef}></div>
                        <>
                            <SubLots
                                selectedPostion={handleClick}
                                handleClick={handleClick}
                                title={auctionData.title}
                                auctionid={auctionid}
                                sendType={(val) => {
                                    setType(val)
                                    getLotList(subLotSearch, 'auctionView_from_sublot')
                                }}
                                lotData={subLotData}
                                subLotDataCount={subLotDataCount}
                                subLotSearch={subLotSearch}
                                setSubLotSearch={setSubLotSearch}
                                setViewProduct={setSubLotData}
                                viewProduct={subLotData}
                                is_registered={isRegistered}
                                setAlert={setAlert}
                                private_auction={private_auction}
                            />
                            <MultisellerSlider
                                type="right"
                                reverse_auction={selectedProduct ? checkreverseauction() : 0}
                                selectedLot={selectedProduct}
                                handleClose={handleClose}
                                from={display_type == 'list' ? 'card' : ''}
                            />
                        </>
                    </>
                )}
            </div>
        </Layout>
    )
}

export default ProductAuctionComponent
