import React from 'react'
import './nodata.css'
import { useTranslation } from 'react-i18next'
const Nodata = () => {
    const { t } = useTranslation()
    return (
        <div className="np-pro-box">
            {' '}
            <p>{t('No_Records')}</p>
        </div>
    )
}
export default Nodata
