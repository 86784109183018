import React, { useState, useEffect, useContext } from 'react'
import CustomInput from '../../../Product/components/atoms/Inputs/CustomInput'
import { Redirect } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import AuthContext from '../../../Product/context/auth/authContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
    jsonToFormData,
} from '../../../Product/common/components'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import { Button } from '@material-ui/core'

const BidIncrement = (props) => {
    const [bidincarr, setBidincarr] = useState([
        {
            from: '0',
            to: 99,
            price: 1,
        },
    ])
    var key = 0,
        cprice = 0
    const addnewrow = () => {
        var from_in = 0,
            to_in = 0
        cprice = bidincarr[bidincarr.length - 1].to
        //key = key + 1
        from_in = cprice
        to_in = parseInt(cprice) + 100
        bidincarr.push({
            from: from_in,
            to: to_in,
            price: '',
        })
        setBidincarr((pre) => [...bidincarr])
    }

    const removerow = (index) => {
        bidincarr.splice(index, 1)
        setBidincarr((pre) => [...bidincarr])
    }
    const changerow = (index, from, value) => {
        bidincarr[index][from] = value
        setBidincarr((pre) => [...bidincarr])
    }

    useEffect(() => {
        props.formik.values.contenthead4 = bidincarr
    }, [bidincarr])

    useEffect(() => {
        if (props.bidincarr1) {
            //console.log('ppppppppppppp', props.bidincarr1)
            if (props.bidincarr1.length > 0) {
                //console.log('ppppppppppppp', props.bidincarr1)
                var bid_inc = []
                props.bidincarr1.map((data) => {
                    bid_inc.push({
                        from: data.bprice_range_from.toString(),
                        to: data.bprice_range_to.toString(),
                        price: data.per_price.toString(),
                    })
                })
                setBidincarr((pre) => [...bid_inc])
            }
        }
    }, [props.bidincarr1])
    return (
        <>
            <form className="pp-form">
                {bidincarr &&
                    bidincarr.map((val, index) => (
                        <div className="row">
                            <div className="col-md-3 col-sm-6 col-12">
                                <CustomInput
                                    label="From:"
                                    value={val.from}
                                    name={'from' + index}
                                    onChange={(e) => changerow(index, 'from', e.target.value)}
                                />
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <CustomInput
                                    label="To:"
                                    value={val.to}
                                    name={'to' + index}
                                    onChange={(e) => changerow(index, 'to', e.target.value)}
                                />
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <CustomInput
                                    label="Price:"
                                    value={val.price}
                                    name={'price' + index}
                                    onChange={(e) => changerow(index, 'price', e.target.value)}
                                />
                            </div>
                            <div className="col-md-3 col-sm-6 col-12">
                                <PrimaryButton
                                    type="button"
                                    label={
                                        index == 0 ? '(+)Add' : '(-)Remove'
                                        // loading
                                        //     ? 'Loading...'
                                        //     : `${auction_id ? 'Edit & Add Lot' : 'Save & Add Lot'}`
                                    }
                                    //disabled={loading}
                                    onClick={() => {
                                        if (index == 0) {
                                            addnewrow()
                                        } else {
                                            removerow(index)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    ))}
            </form>
        </>
    )
}

export default BidIncrement
