import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { useHistory } from 'react-router-dom'
import SubLots from './SubLots'
import ProductAuctionTop from './ProductAuctionTop'
import SlidePanel from '../../../Component/SlidePanel'
import './product.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import Toaster from '../../../Component/Toaster'

import { socket, socketAuction } from '../../../Product/common/socket'

import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'

import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { messageHandler } from '../../../Product/common/socketHandler'

import Layout from '../Layout'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Loaders from '../../../Product/components/molecules/Loaders'

const ProductAuctionComponent = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)

    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext

    const {
        search_allauctions,
        search_allauctionproducts,
        edit_auction,
        getLotList,
        getEditAuction,
    } = productContext

    let auctionid = props.match.params.id
    const [auctionData, setAuctionData] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    const [isRegistered, setIsRegistered] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        sortBy: 12,
        lotof: auctionid,
        limit: 50,
        market_status: 'open',
        auction_market_status: 'open',
        auction_buynow: 'all',
    })
    const [display_type, setType] = useState('list')
    const [loading, setLoading] = useState(true)
    let history = useHistory()

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })
    useEffect(() => {
        getEditAuction({ auctionid }, 'auctionView')
    }, [isAuthenticated, auctionid])

    useEffect(() => {
        getLotList(subLotSearch, 'auctionView')
    }, [subLotSearch, isAuthenticated])

    useEffect(() => {
        setSubLotSearch({ ...subLotSearch, lotof: auctionid })
    }, [auctionid])

    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionView') {
            setLoading(true)
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
    }, [search_allauctionproducts])
    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionView_from_sublot') {
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        setLoading(true)
        if (edit_auction.from === 'auctionView') {
            if (edit_auction.auction_details) {
                setAuctionData(edit_auction.auction_details)
                setIsRegistered(edit_auction.is_registered)
                setTimeout(() => {
                    setLoading(false)
                }, 1500)
            }
        }
    }, [edit_auction])

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const viewProductRef = useRef(subLotData)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = subLotData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            // getLotList(subLotSearch, 'auctionView')
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])
    return (
        <Layout props={props}>
            <div className="customContainer pt-3 auctionCnt">
                {loading ? (
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                ) : (
                    <>
                        <ProductAuctionTop
                            auctionData={auctionData}
                            product_id={auctionid}
                            executeScroll={executeScroll}
                            sellerName={subLotData[0]?.seller_name}
                            is_registered={isRegistered}
                        />
                        <div ref={myRef}></div>
                        <>
                            <SubLots
                                selectedPostion={handleClick}
                                handleClick={handleClick}
                                title={auctionData.title}
                                auctionid={auctionid}
                                sendType={(val) => {
                                    setType(val)
                                    getLotList(subLotSearch, 'auctionView_from_sublot')
                                }}
                                lotData={subLotData}
                                subLotDataCount={subLotDataCount}
                                subLotSearch={subLotSearch}
                                setSubLotSearch={setSubLotSearch}
                            />
                            <MultisellerSlider
                                type="right"
                                selectedLot={selectedProduct}
                                handleClose={handleClose}
                                from={display_type == 'list' ? 'card' : ''}
                            />
                        </>
                    </>
                )}
            </div>
        </Layout>
    )
}

export default ProductAuctionComponent
