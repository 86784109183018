import {
    RESPONSE_STATUS,
    GET_ALL_BID_HISTORY,
    CLEAR_RESPONSE,
    GET_SAVED_SEARCHES,
    LOT_PRODUCT_DTLS,
    ALL_LOTS,
    GET_ALL_SELLERS,
    DASHBOARD_MYBIDS,
    GET_SINGLE_INVOICE_DETAIL,
    AUCTION_PERCENT,
    GET_ALL_LOT_AUCTIONS,
} from './productTypes'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_BID_HISTORY:
            return {
                ...state,
                search_allbidhistory: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_SAVED_SEARCHES:
            return {
                ...state,
                saved_searches: action.payload,
            }
        case LOT_PRODUCT_DTLS: {
            return {
                ...state,
                lot_details: action.payload,
            }
        }
        case ALL_LOTS:
            return {
                ...state,
                allLots: action.payload,
            }
        case DASHBOARD_MYBIDS:
            return {
                ...state,
                dashboardMyBids: action.payload,
            }
        case GET_SINGLE_INVOICE_DETAIL:
            return {
                ...state,
                search_allinvoiceproducts: action.payload,
            }
        case AUCTION_PERCENT:
            return {
                ...state,
                auctionPercent: action.payload,
            }
        case GET_ALL_LOT_AUCTIONS:
            return {
                ...state,
                search_allauctionproducts: action.payload,
            }
        case GET_ALL_SELLERS:
            return {
                ...state,
                getAllSeller: action.payload,
            }
        default:
            return state
    }
}
