import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { useHistory } from 'react-router-dom'
import AuctionSubLots from './AuctionSubLots'
import AuctionTop from './AuctionTop'

import _ from 'lodash'

import { socket, socketAuction } from '../../../Product/common/socket'

import ProductContext from '../../../Product/context/auctionio/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'

import { messageHandler } from '../../../Product/common/socketHandler'

import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Loaders from '../../../Product/components/molecules/Loaders'
import AuctionContext from '../../../Product/context/auctionio/auction/auctionContext'

const AuctionView = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const { responseStatus } = useContext(AuctionContext)

    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext

    const { search_allauctionproducts, getLotList } = productContext

    let auctionid = props.match.params.id
    const [auctionData, setAuctionData] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    const [isRegistered, setIsRegistered] = useState(0)
    const [showTimer, setShowTimer] = useState(true)
    const [trigger, setTrigger] = useState(true)
    const [registeredUser, setRegisteredUser] = useState({})
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: '1',
        order: 1,
        orserby: 2,
        lotof: auctionid,
        limit: 50,
        // userid: user.id,
    })
    const [loading, setLoading] = useState(true)
    let history = useHistory()

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        if (isAuthenticated) {
            let body = {
                ...subLotSearch,
                userid: user.id,
            }
            getLotList(body, 'auctionViewLive')
        } else {
            getLotList(subLotSearch, 'auctionViewLive')
        }
    }, [subLotSearch, isAuthenticated, trigger])

    useEffect(() => {
        setLoading(true)
        if (search_allauctionproducts.results.length) {
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_results)
            search_allauctionproducts.auctionList.current_projectid =
                search_allauctionproducts.current_projectid
            search_allauctionproducts.auctionList.isRegistered =
                search_allauctionproducts.isRegistered
            if (new Date(search_allauctionproducts.auctionList.date_added) >= new Date()) {
                search_allauctionproducts.auctionList.preview_date_reached = 1
            } else {
                search_allauctionproducts.auctionList.preview_date_reached = 0
            }
            setAuctionData(search_allauctionproducts.auctionList)

            setIsRegistered(search_allauctionproducts.isRegistered)
            search_allauctionproducts.isRegistered_list.map((val) => {
                if (val.user_id === user.id) {
                    setRegisteredUser(val)
                }
            })
            setLoading(false)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        setLoading(true)
        if (responseStatus) {
            if (responseStatus.from === 'regAuction' && responseStatus.status === 'success') {
                const data = {
                    ...subLotSearch,
                    userid: user.id,
                }
                getLotList(data, 'auctionViewLive')
            }
        }
    }, [responseStatus])

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const viewProductRef = useRef(subLotData)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = subLotData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            // getLotList(subLotSearch, 'auctionViewLive')
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])
    useEffect(() => {
        if (Object.keys(registeredUser).length) {
            socket.on('bidderapproval', (data) => {
                //console.log('bidders approval socket data', data, registeredUser.user_id)
                data.user_id.map(
                    (val) =>
                        val == registeredUser.user_id &&
                        setRegisteredUser({ ...registeredUser, status: 'active' }),
                )
                // setbidUpdate(data)
            })
        }
    }, [registeredUser])

    useEffect(() => {
        socket.on('liveauctionclose', (data) => {
            //console.log('liveauctionclose data', data, data.auctionid, auctionid)
            if (parseInt(data.auctionid) === parseInt(auctionid)) {
                //console.log('Inside This Auction Close auction view page')
                setAlert(`This Auction (${auctionid}) Has Been Ended By Seller Now.`, 'success')
                history.push('/search')
            }
        })
    }, [])

    return (
        <>
            <div className="customContainer pt-3 auctionCnt">
                {loading ? (
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                ) : (
                    <>
                        <AuctionTop
                            auctionData={auctionData}
                            product_id={auctionid}
                            executeScroll={executeScroll}
                            // sellerName={subLotData[0]?.seller_name}
                            // is_registered={isRegistered}
                            registeredUser={registeredUser}
                            showTimer={showTimer}
                        />
                        <div ref={myRef}></div>
                        <>
                            <AuctionSubLots
                                selectedPostion={handleClick}
                                handleClick={handleClick}
                                title={auctionData.title}
                                auctionid={auctionid}
                                lotData={subLotData}
                                subLotDataCount={subLotDataCount}
                                subLotSearch={subLotSearch}
                                setSubLotSearch={setSubLotSearch}
                                auctionData={auctionData}
                                showTimer={showTimer}
                                setShowTimer={setShowTimer}
                                trigger={trigger}
                                setTrigger={setTrigger}
                            />
                        </>
                    </>
                )}
            </div>
        </>
    )
}

export default AuctionView
