import React, { useContext, useEffect, useState } from 'react'
import Layout from '../Layout'
import UserContext from '../../../Product/context/user/userContext'
import './Static.css'
import { Button } from '@material-ui/core'

const AboutUs = (props) => {
    return (
        <Layout>
            <div className="abtSttcPg">
                <div className="topContr customContainer">
                    <p>HOW TO BUY FOOD AT GREAT PRICES</p>
                    <h2>Welcome to Blowfish</h2>
                    <h5>
                        A new meium to blowout food products connecting industry leading buyers &
                        sellers
                    </h5>
                </div>
                <div className="hwBlFCntn brdrTpDv customContainer pt-5 pb-5 text-center">
                    <h4>How Blowfish World & What You Need To Know</h4>
                    <p>
                        Blowfish is an exclusive, controlled marketplace providing food producers a
                        means to blow out large quantities of food products to qualified local
                        buyers
                    </p>
                    <p>
                        Retailers list their food products at ready-to-move prices to avoid food
                        waste. Buyers browse, register and bid on great products.
                    </p>
                    <p>It&apos;s a win-Win-Win for the food economy.</p>
                    <p>Buyers get access to quality food products at tremendous values</p>
                    <p>The food economy avoids needless wastage</p>
                </div>
                <div className="stcHdrCntnr brdrTpDv brdrBtmDv customContainer pt-5 pb-5">
                    <h3 className="mb-0">HOW TO BUY ON BLOWFISH</h3>
                </div>
                <div className="brdrBtmDv pdtGrd customContainer">
                    <div className="stpCard">
                        <div className="stpImgWrpr">
                            <img src="/images/" />
                        </div>
                        <div className="stpDtlsCls">
                            <small>Step 1</small>
                            <h4>Register</h4>
                            <p>
                                Sign-up to join Blowfish to gain access to bidding, watch-lists and
                                updates on products.
                            </p>
                        </div>
                    </div>
                    <div className="stpCard">
                        <div className="stpImgWrpr">
                            <img src="/images/" />
                        </div>
                        <div className="stpDtlsCls">
                            <small>Step 2</small>
                            <h4>Browse</h4>
                            <p>
                                View live auctions, single items and Buy Now products on
                                Blowfish&apos;s constantly updating catalog of products.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="brdrBtmDv pdtGrd customContainer">
                    <div className="stpCard">
                        <div className="stpImgWrpr">
                            <img src="/images/" />
                        </div>
                        <div className="stpDtlsCls">
                            <small>Step 3</small>
                            <h4>Watch</h4>
                            <p>
                                Got your eye on a particular product? Add it to your watchlist and
                                get updates as the pricing changes.
                            </p>
                        </div>
                    </div>
                    <div className="stpCard">
                        <div className="stpImgWrpr">
                            <img src="/images/" />
                        </div>
                        <div className="stpDtlsCls">
                            <small>Step 4</small>
                            <h4>Bid</h4>
                            <p>
                                Submit your price on any item you are interested in. Blowfish&apos;s
                                automatic bidding system let&apos;s you enter your maximum price and
                                handles the rest.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="customContainer py-4">
                    <div className="stpCard">
                        <div className="stpImgWrpr">
                            <img src="/images/" />
                        </div>
                        <div className="stpDtlsCls">
                            <small>Step 5</small>
                            <h4>Win</h4>
                            <p>
                                Big winner! Purchasing is securely managed and confirmed. Blowfish
                                will pass the shipping details to you.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="stcHdrCntnr brdrTpDv brdrBtmDv customContainer pt-5 pb-5">
                    <h3 className="mb-0">HOW TO SELL ON BLOWFISH</h3>
                </div>
                <div className="brdrBtmDv pdtGrd customContainer">
                    <div className="stpCard">
                        <div className="stpImgWrpr">
                            <img src="/images/" />
                        </div>
                        <div className="stpDtlsCls">
                            <small>Step 1</small>
                            <h4>Register</h4>
                            <p>
                                Sign-up to join Blowfish to gain access to bidding, watch-lists and
                                updates on products.
                            </p>
                        </div>
                    </div>
                    <div className="stpCard">
                        <div className="stpImgWrpr">
                            <img src="/images/" />
                        </div>
                        <div className="stpDtlsCls">
                            <small>Step 2</small>
                            <h4>Get Approved</h4>
                            <p>
                                A Blowfish representative will learn about your business and help
                                get your seller account setup.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="brdrBtmDv pdtGrd customContainer">
                    <div className="stpCard">
                        <div className="stpImgWrpr">
                            <img src="/images/" />
                        </div>
                        <div className="stpDtlsCls">
                            <small>Step 3</small>
                            <h4>Post Item</h4>
                            <p>
                                Create your listing with product details, descriptions, images,
                                estimated pricing and starting bid level.
                            </p>
                        </div>
                    </div>
                    <div className="stpCard">
                        <div className="stpImgWrpr">
                            <img src="/images/" />
                        </div>
                        <div className="stpDtlsCls">
                            <small>Step 4</small>
                            <h4>Collect Payment</h4>
                            <p>
                                After your auction has cloased and the product has been won by a
                                buyer, your payment will be securely deposited to your account.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gtStrdWrpr brdrTpDv mt-5 brdrBtmDv text-center py-4">
                    <h5 className="mb-4">Let&apos;s Get Started?</h5>
                    <p className="mb-0">
                        <i>Join Blowfish today for free</i>
                    </p>
                </div>
                <div className="text-center btnWrpr py-4">
                    <Button>Take action</Button>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs
