import React from 'react'
import Header from '../Header'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../Product/common/components'
const PickupSummary = () => {
    const history = useHistory()
    return (
        <>
            <Header />
            <div className="pickupSummary">
                <div className="pickupSummaryPop text-center">
                    <h2 className="mb-4">Pick up summary</h2>
                    <div className="date">
                        <div className="calendar">
                            <h4>Pick up scheduled for Tuesday, September 19,2023</h4>
                            <h5>between 11:00 AM - 11:15 AM</h5>
                            <a>Reschedule</a>
                        </div>
                    </div>
                    <div className="times">
                        <div className="picking d-flex">
                            <p style={{ fontWeight: '500', maxWidth: '200px', width: '100%' }}>
                                Picking up at
                            </p>
                            <div className="text-left ms-2">
                                <p style={{ fontWeight: '500', marginBottom: '5px' }}>
                                    Dallas Auction Warehouse
                                </p>
                                <p>1221 W Campbell Rd,Suite 181 Richardson, TX 75074</p>
                                <a>Get directions</a>
                            </div>
                        </div>
                        <div className="d-flex mt-4">
                            <p style={{ fontWeight: '500', maxWidth: '200px', width: '100%' }}>
                                Items (3 items)
                            </p>
                            <div className="text-left ms-2">
                                <a>View</a>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-2 text-center">
                        <button
                            type="submit"
                            className="pink-btn w-100 mt-4 chooseBtn"
                            style={{ maxWidth: '300px' }}
                            onClick={() => handleRedirectInternal(history, 'pickupConfirmed')}
                        >
                            Confirm pick up
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PickupSummary
