import {
    GET_EDIT_PROFILE,
    RESPONSE_STATUS,
    CHANGE_PWD,
    GET_PFR,
    EDIT_PROFILE,
    GET_MSG_USER_LIST,
    GET_BLOG_LIST,
    GET_SINGLE_BLOG,
    CLEAR_RESPONSE,
    GET_ONE_MSG_USER,
    UPDATE_USER_DYN_PROFILE,
    GET_STATIC_PAGE,
    GET_ALL_SHIPPING_ADDRESS,
    GET_SELLER_LIST,
    GET_LOCATION,
} from './userTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_EDIT_PROFILE:
            return {
                ...state,
                edit_profile: action.payload,
            }
        case GET_MSG_USER_LIST:
            return {
                ...state,
                userlist_messages: action.payload,
            }
        case GET_BLOG_LIST:
            return {
                ...state,
                blogposted: action.payload,
            }
        case GET_SINGLE_BLOG:
            return {
                ...state,
                blogcontent: action.payload,
            }
        case GET_ONE_MSG_USER:
            return {
                ...state,
                buyerseller_messages: action.payload,
            }
        case UPDATE_USER_DYN_PROFILE:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case EDIT_PROFILE:
            return {
                ...state,
                responseStatus: action.payload,
            }

        case GET_PFR:
            return {
                ...state,
                preference: action.payload,
            }
        case CHANGE_PWD:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_STATIC_PAGE:
            return {
                ...state,
                static_page: action.payload,
            }
        case GET_ALL_SHIPPING_ADDRESS:
            return {
                ...state,
                all_shipping_address: action.payload,
            }
        case GET_SELLER_LIST:
            return {
                ...state,
                seller_list: action.payload,
            }
        case GET_LOCATION:
            return {
                ...state,
                countryValues: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
