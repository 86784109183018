import React from 'react'

import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import SellerDashboard from '../../../utils/CommonFunctionality/SellerDashboard'
import { useTranslation } from 'react-i18next'

const SellerDashboardOverview = (props) => {
    const { t } = useTranslation()
    return (
        <Layout>
            <DashboardLayout title={t('dashboard')} subtitle="Items" seller>
                <div className="db-bids-wrapper">
                    <SellerDashboard />
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default SellerDashboardOverview
