import axios from 'axios'
import moment from 'moment'
const apiCall = async (
    method,
    url,
    data,
    headertype,
    baseurl,
    cancelToken,
    delay = false,
    setProgress,
) => {
    let site_url = `${global.site_url}`
    if (baseurl) {
        site_url = `${global.site_base_url}${baseurl}/`
    }
    if (axios?.defaults?.headers?.common['authorization'] == undefined) {
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
    }

    return new Promise(async (resolve, reject) => {
        let type = ''
        let responseType = headertype === 'blob' ? headertype : ''
        if (headertype && headertype === 'formdata') {
            type = 'multipart/form-data'
        } else {
            type = 'application/json'
        }
        const config = {
            headers: {
                'content-type': type,
                responseType,
            },
            cancelToken: cancelToken?.token,
        }

        if (setProgress) {
            config.onUploadProgress = (progressEvent) => {
                const { loaded, total } = progressEvent
                let percent = Math.floor((loaded * 100) / total)
                if (percent < 100) {
                    // console.log(`${loaded} bytes of ${total} bytes. ${percent}%`)
                    setProgress(percent)
                }
            }
        }

        if (
            global?.storeConfigration?.guest_user_login?.value &&
            localStorage.getItem('ipv4addresspublic') &&
            !localStorage.token
        ) {
            if (
                url == 'addtocart' ||
                url == 'removefromcart' ||
                url == 'getUserCart' ||
                url == 'login' ||
                url == 'register' ||
                url == 'updateCart'
            ) {
                if (url != 'login' && url != 'register') {
                    config.headers.authorization =
                        'Bearer ' + global.storeConfigration?.guest_user_login?.value
                }

                data.publicipaddress = localStorage.getItem('ipv4addresspublic')
                    ? localStorage.getItem('ipv4addresspublic').toString()
                    : ''
            }
        }
        switch (method) {
            case 'post':
                try {
                    data = data ? data : {}
                    const res = await axios.post(`${site_url}${url}`, data, config)
                    if (responseType && res.data.success != false) {
                        const url = window.URL.createObjectURL(new Blob([res.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute(
                            'download',
                            data.name + ' (' + moment().format('MM-DD-YYYY') + ').csv',
                        )
                        document.body.appendChild(link)
                        link.click()
                        resolve({ data: { success: true } })
                        break
                    } else {
                        if (delay) {
                            setTimeout(() => {
                                resolve(res)
                            }, Math.floor(Math.random() * 1000))
                        } else {
                            resolve(res)
                        }
                        break
                    }
                } catch (err) {
                    // console.log('responsode error from api', err)
                    resolve(err)
                    break
                }
            case 'get':
                try {
                    // console.log('get method', url, config)
                    let addedParam = null
                    if (data) {
                        addedParam = new URLSearchParams(data).toString()
                    }
                    const res = await axios.get(
                        `${site_url}${url}${addedParam ? `?${addedParam}` : ''}`,
                        config,
                    )
                    // console.log('response get ode from api', res)
                    resolve(res)
                    break
                } catch (err) {
                    // console.log('responsode error from api', err)
                    resolve(err)
                    break
                }
            default:
                return null
        }
    })
}

const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['authorization'] = `Bearer ${token}`
    } else {
        delete axios.defaults.headers.common['authorization']
    }
}

const setIPAddress = (ipaddress) => {
    if (ipaddress) {
        axios.defaults.headers.common['ipaddress'] = ipaddress
    } else {
        delete axios.defaults.headers.common['ipaddress']
    }
}
export { apiCall, setAuthToken, setIPAddress }
