import React from 'react'
import './Maintenance.css'
import { Logo } from '../../utils/index'

const Maintenance = () => {
    return (
        <div className="maintainPage">
            <div className="maintenancePageWrpr">
                <div className="infoContainer">
                    <div className="logoContnr">
                        <Logo />
                    </div>
                    <div className="mt-5">
                        <h2>We&apos;ll Be Back Soon</h2>
                        <p>The site is undergoing maintenance and will back soon</p>
                    </div>
                </div>
                <img src="/assets/images/maintenance.png" />
            </div>
        </div>
    )
}

export default Maintenance
