import React from 'react'
import { EmailShareButton, EmailIcon } from 'react-share'
import ReactHtmlParser from 'react-html-parser'
const Email = ({ path, subject, body }) => {
    return (
        <EmailShareButton
            url={
                window.location.href.includes(global?.storeDetails?.id)
                    ? `${window.location.origin}/${global?.storeDetails?.id}${path}`
                    : `${window.location.origin}${path}`
            }
            subject={subject}
            body={`${
                global.storeConfigration?.email_sharing_text?.value
                    ? global.storeConfigration?.email_sharing_text?.value
                    : ''
            } ${body}`}
        >
            <EmailIcon size={32} round />
        </EmailShareButton>
    )
}

export default Email
