import React from 'react'
import Layout from '../Layout'
import DashboardLayout from '../DashboardLayout'
import PhotoUploader from '../../../utils/CommonFunctionality/PhotoUploader'

const ReportsManagement = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="Photo Uploader">
                <PhotoUploader />
            </DashboardLayout>
        </Layout>
    )
}

export default ReportsManagement
