import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { Link, useHistory } from 'react-router-dom'
import SubLots from './SubLots'
import ProductAuctionTop from './ProductAuctionTop'
import './product.css'
import _ from 'lodash'
import { socket, socketAuction } from '../../Product/common/socket'
import ProductContext from '../../Product/context/product/productContext'
import AuthContext from '../../Product/context/auth/authContext'
import AlertContext from '../../Product/context/alert/alertContext'
import { messageHandler } from '../../Product/common/socketHandler'
import MultisellerSlider from '../../Component/SlidePanel/MultisellerSlider'
import Loaders from '../../Product/components/molecules/Loaders'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Timer from '../../Product/common/timer'
import {
    currencyFormat,
    dateFormatFrontDay,
    handleRedirectInternal,
} from '../../Product/common/components'
import DonationFunction from '../DonationModal/DonationFunction'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const ProductAuctionComponent = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)

    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext

    const {
        search_allauctions,
        search_allauctionproducts,
        edit_auction,
        getLotList,
        getEditAuction,
    } = productContext

    let auctionid = props.auctionid
    const [auctionData, setAuctionData] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        sortBy: 7,
        lotof: auctionid,
        limit: 50,
        market_status: 'open',
    })
    const [loading, setLoading] = useState(true)
    let history = useHistory()

    // const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        if (newValue == 2) {
            if (isAuthenticated) {
                setValue(newValue)
            } else {
                handleRedirectInternal(history, 'login')
            }
        } else if (newValue == 3) {
            if (isAuthenticated) {
                handleRedirectInternal(
                    history,
                    `ticketing/${auctionData.stage_id}/${auctionData.id}`,
                )
            } else {
                handleRedirectInternal(history, 'login')
            }
        } else if (newValue == 4) {
            handleRedirectInternal(history, 'ticketingname')
        } else setValue(newValue)
    }

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    // useEffect(() => {
    //     getEditAuction({ auctionid }, 'auctionView')
    // }, [isAuthenticated])

    // useEffect(() => {
    //     getLotList(subLotSearch, 'auctionView')
    // }, [subLotSearch, isAuthenticated])

    useEffect(() => {
        setLoading(true)
        if (
            search_allauctionproducts.from === 'auctionView' ||
            search_allauctionproducts.from === 'auctionSubView'
        ) {
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        setLoading(true)
        if (edit_auction.from === 'auctionView') {
            if (edit_auction.auction_details) {
                setAuctionData(edit_auction.auction_details)
                setTimeout(() => {
                    setLoading(false)
                }, 1500)
            }
        }
    }, [edit_auction])

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const viewProductRef = useRef(subLotData)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = subLotData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            // getLotList(subLotSearch, 'auctionView')
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])
    return (
        <div className="Fundraiser">
            <div className="customContainer pt-3 auctionCnt charityAuctionWrapper">
                {loading ? (
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                ) : (
                    <>
                        <div className="d-flex align-items-center justify-content-between mb-3 mt-4">
                            <div className="co-breadcrumb mb-0">
                                <Link to="/auctions">Search</Link> / {auctionData.title}
                            </div>
                            <div className="timer-wrapper mnPgTimer">
                                <div className="li-timer">
                                    <Timer
                                        date_added={auctionData.date_added}
                                        date_closed={auctionData.date_closed}
                                        withText={1}
                                        icon={true}
                                        endText={'Ends in' + ':'}
                                        startText={'Starts in' + ':'}
                                        // theme="multiseller"
                                    ></Timer>
                                </div>
                            </div>
                        </div>
                        <AppBar position="static" className="eventTabs">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="simple tabs example"
                            >
                                <Tab label="Overview" {...a11yProps(0)} />
                                <Tab label="Browse Items" {...a11yProps(1)} />
                                <Tab label="Donate" {...a11yProps(2)} />
                                {isAuthenticated ? null : (
                                    <Tab label="Register" {...a11yProps(3)} />
                                )}
                                {auctionData.stage_id > 0 ? (
                                    <Tab label="Buy Tickets" {...a11yProps(4)} />
                                ) : (
                                    ''
                                )}
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <ProductAuctionTop
                                auctionData={auctionData}
                                product_id={auctionid}
                                executeScroll={executeScroll}
                                makeDonation={() => setValue(2)}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <>
                                <SubLots
                                    selectedPostion={handleClick}
                                    handleClick={handleClick}
                                    title={auctionData.title}
                                    auctionid={auctionid}
                                    lotData={subLotData}
                                    subLotDataCount={subLotDataCount}
                                    subLotSearch={subLotSearch}
                                    setSubLotSearch={setSubLotSearch}
                                />
                                <MultisellerSlider
                                    type="right"
                                    selectedLot={selectedProduct}
                                    handleClose={handleClose}
                                />
                            </>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <DonationFunction id={auctionData.id} handleClose={() => setValue(0)} />
                        </TabPanel>
                    </>
                )}
            </div>
        </div>
    )
}

export default ProductAuctionComponent
