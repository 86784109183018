import React from 'react'
import Skeletons from '../../Skeletons'

function ArchiveSkeleton(props) {
    return (
        <div className={`archiveSkeleton ${props.theme ? props.theme : ''}`}>
            <Skeletons type="gridImage" />
        </div>
    )
}

export default ArchiveSkeleton
