import React, { useEffect, useState, useContext } from 'react'
import './gallery.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import Loaders from '../../../Product/components/molecules/Loaders'
import Layout from '../Layout'
import Loader from '../../../assets/loader'
import AlertContext from '../../../Product/context/alert/alertContext'
const GalleryComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { setAlert, clearAlert, alerts } = alertContext
    const { search_allproducts, getAllSearchProducts } = productContext
    const { isAuthenticated } = authContext

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const params = new URLSearchParams(window.location.search.substring(1))
    const [productSearch, setProductSearch] = useState({
        sh_limit: 12,
        from: 'buynow',
        page: 1,
        orderby: '1',
        searchbar: params.get('q') ? params.get('q') : '',
        zipcode: params.get('z') ? params.get('z') : '',
        miles: params.get('m') ? params.get('m') : '',
    })
    useEffect(() => {
        if (isLoading) {
            setAlert('Please Wait', 'warnning')
        }
    }, [isLoading])

    useEffect(() => {
        getAllSearchProducts(productSearch, 'galleryPage')
    }, [productSearch, isAuthenticated])

    useEffect(() => {
        if (search_allproducts.from === 'galleryPage') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
        }
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        //if (params.get('z') || params.get('m') || params.get('q')) {
        setProductSearch({
            ...productSearch,
            zipcode: params.get('z') ? params.get('z') : '',
            miles: params.get('m') ? params.get('m') : '',
            searchbar: params.get('q') ? params.get('q') : '',
        })
        //}
    }, [window.location.search])

    const onHandlePage = (event, value) => {
        setProductSearch({ ...productSearch, page: value })
    }

    return (
        <Layout props={props}>
            <div className="customContainer mt-5">
                {isLoading ? (
                    <div className="text-center">
                        <Loader />
                    </div>
                ) : viewProduct.length > 0 ? (
                    <>
                        <h2 className="buyNowTitle">Buy Now Items</h2>
                        <SearchProductList
                            listview={listview}
                            searchResult={viewProduct}
                            className="multiBuyCard"
                            type="buynow"
                            noTimer={true}
                            cardTheme="multiseller"
                            sliderTheme="multiseller"
                        />
                    </>
                ) : (
                    <NoRecordsFound />
                )}
                {/* <SimilarList /> */}
            </div>
        </Layout>
    )
}

export default GalleryComponent
