import React, { useState, useContext, useEffect } from 'react'

import AuthContext from '../../../context/auth/authContext'
import UserContext from '../../../context/user/userContext'
import ProductContext from '../../../context/product/productContext'
import AlertContext from '../../../context/alert/alertContext'
import CartContext from '../../../context/cart/cartContext'
import StripeCardContext from '../../../context/stripe/card/cardContext'

const HeadAlert = () => {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const cartContext = useContext(CartContext)
    const stripeCardContext = useContext(StripeCardContext)

    const { setAlert } = alertContext
    const { responseStatus: responseStatusAuth, clearResponse: clearResponseAuth } = authContext
    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext
    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext
    const { responseStatus: responseStatusProduct, clearResponse: clearResponseProduct } =
        productContext
    const { responseStatus: responseStatusCard, clearResponse: clearResponseCard } =
        stripeCardContext

    useEffect(() => {
        if (responseStatusAuth) {
            // console.log('responseStatus', responseStatusAuth)
            setAlert(responseStatusAuth.message, responseStatusAuth.status)
            clearResponseAuth()
        }
        if (responseStatusUser && !responseStatusUser.noAlert) {
            setAlert(responseStatusUser.message, responseStatusUser.status)
            clearResponseUser()
        }
        if (responseStatusProduct && !responseStatusProduct.noAlert) {
            setAlert(responseStatusProduct.message, responseStatusProduct.status)
            clearResponseProduct()
        }
        if (responseStatusCart) {
            setAlert(responseStatusCart.message, responseStatusCart.status)
            clearResponseCart()
        }
        if (responseStatusCard) {
            setAlert(responseStatusCard.message, responseStatusCard.status)
            clearResponseCard()
        }
    }, [
        responseStatusAuth,
        responseStatusUser,
        responseStatusProduct,
        responseStatusCart,
        responseStatusCard,
    ])

    return <></>
}
export default HeadAlert
