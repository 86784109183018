import React, { useEffect, useState, useContext } from 'react'

import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './Auctions.css'
import { Button } from '@material-ui/core'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import Loader from '../../../assets/loader'
import Loaders from '../../../Product/components/molecules/Loaders'
import { handleRedirectInternal } from '../../../Product/common/components'
import { useHistory } from 'react-router-dom'

const AuctionSearchPage = (props) => {
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allauctionproducts, getLotList } =
        productContext
    const params = new URLSearchParams(window.location.search.substring(1))
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [auctionView, setAuctionView] = useState('Grid')

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: params.get('t') ? params.get('t') : '',
        type: params.get('t') == '3' ? 'closed' : params.get('t') == '' ? 'all' : '',
        is_auction: global.storeConfigration?.need_post_buynow?.value != 0 ? 0 : 1,
    })

    useEffect(() => {
        setLoading(true)
        setAuctionSearch({
            ...auctionSearch,
            auc_type: params.get('t') ? params.get('t') : params.get('t') == '' ? '' : 2,
            type: params.get('t') == '3' ? 'closed' : '',
        })
    }, [window.location.search])

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'activeHome')
    }, [auctionSearch])
    useEffect(() => {
        setLoading(false)
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const onHandlePage = (event, value) => {
        setAuctionSearch({ ...auctionSearch, page: value })
    }

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'All Auctions',
        },
        {
            value: 1,
            show: 'Upcoming Auctions',
        },
        {
            value: 2,
            show: 'Current Auctions',
        },
        {
            value: 3,
            show: 'Completed Auctions',
        },
    ]

    return (
        <div className="customContainer auctionsSearch">
            <div className="d-flex justify-content-between align-items-center flex-wrap mt-5 mb-3 auctionSearchCnt">
                <h2 className="auctionListTitle">
                    {auctionSearch.auc_type == 1
                        ? 'Upcoming'
                        : auctionSearch.auc_type == 2
                        ? 'Current'
                        : auctionSearch.auc_type == 3
                        ? 'Completed'
                        : 'All'}{' '}
                    Auctions
                </h2>
                <div className="auctionsMiscFilter">
                    {props.viewType ? (
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => setAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => setAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                    ) : null}
                    {props.resultsPerPage ? (
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={auctionSearch.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                setAuctionSearch({ ...auctionSearch, sh_limit: event.target.value })
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    ) : null}
                    {props.auctionType ? (
                        <CustomSelect
                            label="Auction Type"
                            value={auctionSearch.auc_type}
                            size="small"
                            selectType="noBorder"
                            name="auc_type"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                handleRedirectInternal(history, `auctions?t=${event.target.value}`)
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    ) : null}
                </div>
            </div>
            <>
                <>
                    {loading ? (
                        <div className="text-center">
                            <Loaders name="product_grid_view" isLoading={loading} loop={1} />
                        </div>
                    ) : viewAuction.length === 0 ? (
                        <NoRecordsFound />
                    ) : (
                        <div className={`searchResults ${auctionView}`}>
                            {viewAuction.map((data, index) =>
                                auctionView === 'Grid' ? (
                                    <GridAuction
                                        key={index}
                                        data={data}
                                        details={`${
                                            data.con_check
                                                ? 'liveauctionview'
                                                : 'search/product-buyer-auction'
                                        }/${data.id}`}
                                        timerTheme="multiseller"
                                        theme="multiseller"
                                        // lotCount={data.lotCount ? data.lotCount : 0}
                                    />
                                ) : (
                                    <MultisellerListCard
                                        key={index}
                                        data={data}
                                        allsearchitems={viewAuction}
                                        // lotCount={data.lotCount ? data.lotCount : 0}
                                        details={`${
                                            data.con_check
                                                ? 'liveauctionview'
                                                : 'search/product-buyer-auction'
                                        }/${data.id}`}
                                    />
                                ),
                            )}
                        </div>
                    )}
                </>
            </>
        </div>
    )
}

export default AuctionSearchPage
