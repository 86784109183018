import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './Auctions.css'
import { Button } from '@material-ui/core'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'

const Auctions = (props) => {
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allauctionproducts, getLotList } =
        productContext
    const [loading, setLoading] = useState(true)
    const [auctionView, setAuctionView] = useState('Grid')
    const [viewAuction, setViewAuction] = useState([])
    const [subLotData, setSubLotData] = useState([])

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
    })

    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        orderby: '2',
        lotof: '',
        limit: 50,
        market_status: 'open',
    })

    useEffect(() => {
        setLoading(true)
        getAllSearchAuctions(auctionSearch, 'activeHome')
    }, [auctionSearch])

    useEffect(() => {
        if (viewAuction.length) {
            setSubLotSearch({ ...subLotSearch, lotof: viewAuction[0].id })
        }
    }, [viewAuction])

    useEffect(() => {
        if (subLotSearch.lotof) {
            getLotList(subLotSearch, 'homePageAuction')
        }
    }, [subLotSearch])

    useEffect(() => {
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
            setLoading(false)
        }
    }, [search_allauctions])

    useEffect(() => {
        setViewAuction([])
        return () => {
            setViewAuction([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        setAuctionSearch({ ...auctionSearch, page: value })
    }

    useEffect(() => {
        if (search_allauctionproducts.from === 'homePageAuction') {
            //console.log('search_allauctionproducts', search_allauctionproducts)
            setSubLotData(search_allauctionproducts.results)
        }
    }, [search_allauctionproducts])

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: 'p.id, desc',
            show: 'None',
        },
        {
            value: `CASE WHEN p.wprice > 0 THEN 0 ELSE 1 END ASC, p.wprice asc`,
            show: 'Current price low to high',
        },
        {
            value: `CASE WHEN p.wprice > 0 THEN 0 ELSE 1 END ASC, p.wprice desc`,
            show: 'Current price high to low',
        },
        {
            value: 'p.date_closed, asc',
            show: 'Time remaining',
        },
        {
            value: 1,
            show: 'Ending today',
        },
        {
            value: `CASE WHEN p.bprice > 0 THEN 0 ELSE 1 END ASC, CASE WHEN p.rprice - p.wprice >= 0 THEN 0 ELSE 1 END ASC, p.bprice * p.qty asc`,
            show: 'Buy now price low to high',
        },
        {
            value: `CASE WHEN p.bprice > 0 THEN 0 ELSE 1 END ASC, CASE WHEN p.rprice - p.wprice >= 0 THEN 0 ELSE 1 END ASC, p.bprice * p.qty desc`,
            show: 'Buy now price high to low',
        },
    ]

    return (
        <Layout props={props}>
            <div className="customContainer auctionsSearch">
                <div className="d-flex justify-content-between align-items-center flex-wrap my-5">
                    <h2 className="auctionListTitle">Live Auctions</h2>
                    <div className="auctionsMiscFilter">
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => setAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => setAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={auctionSearch.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                setAuctionSearch({ ...auctionSearch, sh_limit: event.target.value })
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        {/* <CustomSelect
                            label="Sort by"
                            value={auctionSearch.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                setAuctionSearch({ ...auctionSearch, orderby: event.target.value })
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect> */}
                    </div>
                </div>
                <>
                    {loading ? (
                        <div className="row"></div>
                    ) : viewAuction.length === 0 ? (
                        <NoRecordsFound />
                    ) : (
                        <>
                            {' '}
                            {viewAuction.length > 0 && (
                                <div className={`searchResults ${auctionView}`}>
                                    {viewAuction.map((data, index) =>
                                        auctionView === 'Grid' ? (
                                            <GridAuction
                                                key={index}
                                                data={data}
                                                details={`search/product-buyer-auction/${data.id}?${data.description}`}
                                                timerTheme="HeavyEquipment"
                                                theme="HeavyEquipment"
                                                // lotCount={subLotData.length}
                                            />
                                        ) : (
                                            <MultisellerListCard
                                                key={index}
                                                data={data}
                                                allsearchitems={viewAuction}
                                                lotData={subLotData}
                                                timerTheme=""
                                                details={`search/product-buyer-auction/${data.id}?${data.description}`}
                                            />
                                        ),
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </>
            </div>
        </Layout>
    )
}

export default Auctions
