import React from 'react'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../Product/common/components'
import Header from '../Header'
import './SchedulePickup.css'

const SchedulePickup = () => {
    const history = useHistory()
    return (
        <>
            <Header />
            <div className="schedule">
                <div className="schedulePop text-center">
                    <h2>Thank you, your order has been placed!</h2>
                    <p className="mt-3">
                        You will receive an order confirmation shortly at
                        krithika@auctionsoftware.com
                    </p>
                    <a>Order#101012</a>
                    <div className="form-group mt-2 text-center">
                        <button
                            type="submit"
                            className="pink-btn w-100 mt-4 scheduleBtn"
                            style={{ maxWidth: '300px' }}
                            onClick={() => handleRedirectInternal(history, 'chooseDay')}
                        >
                            Schedule Pickup
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SchedulePickup
