import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DirectStripeContext from '../../../Product/context/directStripe/directStripeContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { mapData, handleRedirectInternal } from '../../../Product/common/components'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'

const sellerOnboard = (props) => {
    const { uploadIdentityProof, createBankAccount, responseStatus, clearResponse } =
        useContext(DirectStripeContext)
    const { setAlert } = useContext(AlertContext)
    const { isAuthenticated, user, loadUser } = useContext(AuthContext)
    const history = useHistory()

    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [loading, setLoading] = useState('')
    useEffect(() => {
        if (isAuthenticated) {
            if (user.role != '1') {
                handleRedirectInternal(history, '')
            }
            if (user.card_profileid) {
                setAlert('Onboarding already completed!', 'success')
                handleRedirectInternal(history, 'dashboard/profile')
            }
        }
    }, [isAuthenticated])

    const validationArray = Yup.object({
        firstname: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        lastname: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email format')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        phone: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'Only numbers are allowed')
            .min(10, 'Minimum 10 characters')
            .required('Required'),
        dob: Yup.string().required('Required!').nullable(),
        company_name: Yup.string().when('business_type', {
            is: 'company',
            then: Yup.string().trim().max(250, 'Maximum 250 characters').required('Required'),
        }),
        tax_id: Yup.string().when('business_type', {
            is: 'company',
            then: Yup.string().trim().max(250, 'Maximum 250 characters').required('Required'),
        }),
        ssn: Yup.number().when('country', {
            is: '38',
            otherwise: Yup.number('Only numbers allowed').required('Required!'),
        }),
        routing_no: Yup.number().when('country', {
            is: '38',
            otherwise: Yup.number('Only numbers allowed').required('Required!'),
        }),
        transit_number: Yup.number().when('country', {
            is: '38',
            then: Yup.number('Only numbers allowed').required('Required!'),
        }),
        institution_number: Yup.number().when('country', {
            is: '38',
            then: Yup.number('Only numbers allowed').required('Required!'),
        }),
        account_no: Yup.number('Only numbers allowed').required('Required!'),
        country: Yup.string().trim().required('Required'),
        state: Yup.string().trim().required('Required'),
        address: Yup.string().trim().max(250, 'Maximum 250 characters').required('Required'),
        city: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z ]*$/g, 'Only characters allowed')
            .required('Required'),
        zipcode: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
            .max(6, 'Maximum 6 characters')
            .required('Required'),
        front_id: Yup.mixed().test(
            'type',
            'Only allowed following formats: jpeg / jpg / png',
            (value) =>
                (value && value[0]?.type === 'image/jpeg') ||
                value[0]?.type === 'image/jpg' ||
                value[0]?.type === 'image/png',
        ),
        back_id: Yup.mixed().test(
            'type',
            'Only allowed following formats: jpeg / jpg / png',
            (value) =>
                (value && value[0]?.type === 'image/jpeg') ||
                value[0]?.type === 'image/jpg' ||
                value[0]?.type === 'image/png',
        ),
        // company_front_id: Yup.mixed().test(
        //     'type',
        //     'Only allowed following formats: jpeg / jpg / png',
        //     (value) =>
        //         (value && value[0]?.type === 'image/jpeg') ||
        //         value[0]?.type === 'image/jpg' ||
        //         value[0]?.type === 'image/png',
        // ),
        // company_back_id: Yup.mixed().test(
        //     'type',
        //     'Only allowed following formats: jpeg / jpg / png',
        //     (value) =>
        //         (value && value[0]?.type === 'image/jpeg') ||
        //         value[0]?.type === 'image/jpg' ||
        //         value[0]?.type === 'image/png',
        // ),
    })

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            dob: '',
            ssn: '',
            routing_no: '',
            transit_number: '',
            institution_number: '',
            account_no: '',
            address: '',
            country: '',
            city: '',
            zipcode: '',
            state: '',
            front_id: [],
            back_id: [],
            company_front_id: [],
            company_back_id: [],
            logo: '',
            front_photo: '',
            user_id: '',
            site_id: '',
            siteurl: `https://${window.location.hostname}`,
            business_type: 'individual',
            company_name: '',
            tax_id: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            setLoading('Uploading Front Image...')
            uploadIdentityProof(
                values.front_id,
                'front_id',
                formik.values.user_id,
                formik.values.site_id,
            )
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'front_id') {
                if (responseStatus.status === 'error') {
                    setLoading('')
                    setAlert(responseStatus.message, 'error')
                }
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, 'success')
                    setLoading('Uploading Back Image...')
                    formik.setFieldValue('front_photo', responseStatus.data.image_id)
                    formik.setFieldValue('logo', responseStatus.data.image_name)
                    clearResponse()
                    uploadIdentityProof(
                        formik.values.back_id,
                        'back_id',
                        formik.values.user_id,
                        formik.values.site_id,
                    )
                }
            }
            if (responseStatus.from === 'back_id') {
                if (responseStatus.status === 'error') {
                    setLoading('')
                    setAlert(responseStatus.message, 'error')
                }
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, 'success')
                    if (formik.values.business_type === 'company') {
                        setLoading('Uploading Company Front Image...')
                        formik.setFieldValue('back_photo', responseStatus.data.image_id)
                        formik.setFieldValue('banner', responseStatus.data.image_name)
                        clearResponse()
                        uploadIdentityProof(
                            formik.values.company_front_id,
                            'company_front_id',
                            formik.values.user_id,
                            formik.values.site_id,
                        )
                    } else {
                        setLoading('Verifying Your Details... Please Wait...')
                        clearResponse()
                        var temp = {
                            ...formik.values,
                            country: formik.values.country == 38 ? 'CA' : 'US',
                            back_photo: responseStatus.data.image_id,
                            banner: responseStatus.data.image_name,
                            rounting_no: formik.values.routing_no,
                            currency: formik.values.country == 38 ? 'cad' : 'usd',
                        }
                        if (formik.values.country == 38) {
                            temp.rounting_no =
                                formik.values.transit_number +
                                '-' +
                                formik.values.institution_number
                        }
                        delete temp.back_id
                        delete temp.front_id
                        delete temp.routing_no
                        delete temp.ssn
                        createBankAccount(temp)
                    }
                }
            }
            if (responseStatus.from === 'company_front_id') {
                if (responseStatus.status === 'error') {
                    setLoading('')
                    setAlert(responseStatus.message, 'error')
                }
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, 'success')
                    setLoading('Uploading Company Back Image...')
                    formik.setFieldValue('company_front_photo', responseStatus.data.image_id)
                    formik.setFieldValue('company_logo', responseStatus.data.image_name)
                    clearResponse()
                    uploadIdentityProof(
                        formik.values.company_back_id,
                        'company_back_id',
                        formik.values.user_id,
                        formik.values.site_id,
                    )
                }
            }
            if (responseStatus.from === 'company_back_id') {
                if (responseStatus.status === 'error') {
                    setLoading('')
                    setAlert(responseStatus.message, 'error')
                }
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, 'success')
                    setLoading('Verifying Your Details... Please Wait...')
                    clearResponse()
                    var temp = {
                        ...formik.values,
                        country: formik.values.country == 38 ? 'CA' : 'US',
                        company_back_photo: responseStatus.data.image_id,
                        company_banner: responseStatus.data.image_name,
                        rounting_no: formik.values.routing_no,
                        currency: formik.values.country == 38 ? 'cad' : 'usd',
                    }
                    if (formik.values.country == 38) {
                        temp.rounting_no =
                            formik.values.transit_number + '-' + formik.values.institution_number
                    }
                    delete temp.back_id
                    delete temp.front_id
                    delete temp.company_back_id
                    delete temp.company_front_id
                    delete temp.routing_no
                    delete temp.ssn
                    createBankAccount(temp)
                }
            }
            if (responseStatus.from === 'createBankAccount') {
                if (responseStatus.status === 'error') {
                    setLoading('')
                    setAlert(responseStatus.message, 'error')
                }
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, 'success')
                    setLoading('')
                    clearResponse()
                    loadUser()
                    handleRedirectInternal(history, `dashboard/profile`)
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (user) {
            formik.setFieldValue('user_id', user?.id)
            formik.setFieldValue('site_id', global.storeDetails.site_id)
            formik.setFieldValue('firstname', user?.first_name)
            formik.setFieldValue('lastname', user?.last_name)
            formik.setFieldValue('email', user?.email)
            formik.setFieldValue('phone', user?.phone)
            formik.setFieldValue('address', user?.address1 + ' ' + user?.address2)
            formik.setFieldValue('country', user?.country)
            formik.setFieldValue('state', user?.state)
            formik.setFieldValue('city', user?.city)
            formik.setFieldValue('zipcode', user?.zip)
        }
    }, [user])
    const productListing = [
        {
            title: 'Type of Business',
            type: 'radio',
            noLabel: true,
            name: 'business_type',
            // int: 1,
            class: 'col-12',
            item: [
                { id: 'individual', description: 'Individual' },
                { id: 'company', description: 'Company' },
            ],
            formik: formik,
        },
        {
            label: 'Company Name *',
            type: 'text',
            placeholder: 'Enter your Company Name',
            class: `col-sm-6 col-12 ${
                formik.values.business_type === 'individual' ? 'd-none' : ''
            }`,
            name: 'company_name',
            formik: formik,
        },
        {
            label: 'GST Number *',
            type: 'text',
            placeholder: 'Enter your GST Number',
            class: `col-sm-6 col-12 ${
                formik.values.business_type === 'individual' ? 'd-none' : ''
            }`,
            name: 'tax_id',
            formik: formik,
        },
        {
            label: 'First Name *',
            type: 'text',
            placeholder: 'Enter your First Name',
            class: `col-sm-6 col-12 `,
            name: 'firstname',
            formik: formik,
        },
        {
            label: 'Last Name *',
            type: 'text',
            placeholder: 'Enter your Last Name',
            class: `col-sm-6 col-12 `,
            name: 'lastname',
            formik: formik,
        },
        {
            label: 'Email *',
            type: 'email',
            placeholder: 'Enter your email',
            class: 'col-sm-6 col-12',
            name: 'email',
            formik: formik,
        },
        {
            label: 'Phone *',
            type: 'text',
            placeholder: 'Enter your phone number',
            class: 'col-sm-6 col-12',
            name: 'phone',
            formik: formik,
        },
        {
            label: 'Date of Birth *',
            placeholder: 'Enter your date of birth',
            type: 'date',
            class: `col-sm-6 col-12 `,
            name: 'dob',
            formik: formik,
        },

        {
            label: 'Address *',
            placeholder: 'Enter Address',
            type: 'textarea',
            class: 'col-sm-6 col-12',
            name: 'address',
            formik: formik,
        },
        {
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: countryList.map((country) => ({
                show: country.name,
                value: country.location_id,
            })),
            name: 'country',
        },
        {
            label: formik.values.country == '38' ? 'Province *' : 'State *',
            placeholder: `Select your ${formik.values.country == '38' ? 'Province' : 'State'}`,
            class: 'col-12 col-sm-6',
            type: 'select',
            options: stateList.map((state) => ({ show: state.name, value: state.name })),
            name: 'state',
        },
        {
            label: 'City *',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-sm-6 col-12',
            name: 'city',
            formik: formik,
        },
        {
            label: formik.values.country == '38' ? 'Postal Code *' : 'Zip Code *',
            type: 'text',
            placeholder: `Enter your ${formik.values.country == '38' ? 'postal' : 'zip'} code`,
            class: 'col-sm-6 col-12',
            name: 'zipcode',
            formik: formik,
        },
        {
            label: 'EIN / SSN Number *',
            type: 'text',
            placeholder: 'Enter your EIN / SSN number',
            class: `col-sm-6 col-12 ${formik.values.country == 38 ? 'd-none' : ''}`,
            name: 'ssn',
            formik: formik,
        },
        {
            label: 'Routing Number *',
            type: 'text',
            placeholder: 'Enter your routing number',
            class: `col-sm-6 col-12 ${formik.values.country == 38 ? 'd-none' : ''}`,
            name: 'routing_no',
            formik: formik,
        },
        {
            label: 'Transit Number *',
            type: 'text',
            placeholder: 'Enter your transit number',
            class: `col-sm-6 col-12 ${formik.values.country != 38 ? 'd-none' : ''}`,
            name: 'transit_number',
            formik: formik,
        },
        {
            label: 'Institution Number *',
            type: 'text',
            placeholder: 'Enter your institution number',
            class: `col-sm-6 col-12 ${formik.values.country != 38 ? 'd-none' : ''}`,
            name: 'institution_number',
            formik: formik,
        },
        {
            label: 'Account Number *',
            type: 'text',
            placeholder: 'Enter your account number',
            class: 'col-sm-6 col-12',
            name: 'account_no',
            formik: formik,
        },
    ]
    const proofFields = [
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'front_id',
            titleText: 'Upload ID Proof: Front Image',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>Upload Any Id Proof Front Image *</span>
                        </b>
                    </p>
                    <span>Recommended dimensions: 500px * 500px</span>
                </div>
            ),
            class: 'col-6',
            folder: 'product',
            accept: 'image/*',
            compressImages: false,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'back_id',
            titleText: 'Upload ID Proof: Back Image',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>Upload Any Id Proof Back Image *</span>
                        </b>
                    </p>
                    <span>Recommended dimensions: 500px * 500px</span>
                </div>
            ),
            class: 'col-6',
            folder: 'product',
            accept: 'image/*',
            compressImages: false,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'company_front_id',
            titleText: 'Upload Company ID Proof: Front Image',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>Upload Company Any Id Proof Front Image *</span>
                        </b>
                    </p>
                    <span>Recommended dimensions: 500px * 500px</span>
                </div>
            ),
            class: `col-6 ${formik.values.business_type === 'individual' ? 'd-none' : ''}`,
            folder: 'product',
            accept: 'image/*',
            compressImages: false,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'company_back_id',
            titleText: 'Upload Company ID Proof: Back Image',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>Upload Company Any Id Proof Back Image *</span>
                        </b>
                    </p>
                    <span>Recommended dimensions: 500px * 500px</span>
                </div>
            ),
            class: `col-6 ${formik.values.business_type === 'individual' ? 'd-none' : ''}`,
            folder: 'product',
            accept: 'image/*',
            compressImages: false,
        },
    ]
    const getLocationData = () => {
        var body = {
            countryCode: formik.values.country,
        }
        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                //console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.country])

    return (
        <form className="pp-form" onSubmit={formik.handleSubmit}>
            <div>
                <div className="row">{mapData(productListing)}</div>
                <div className="row">{mapData(proofFields)}</div>
                <PrimaryButton
                    type="submit"
                    label={loading ? loading : 'Submit'}
                    disabled={loading ? true : false}
                />
            </div>
        </form>
    )
}

export default sellerOnboard
