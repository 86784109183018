import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer, SwipeableDrawer, ListItem } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SlidePanel from '../../../Component/SlidePanel'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import { messageHandler } from '../../../Product/common/socketHandler'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import { socket, socketAuction } from '../../../Product/common/socket'
import Slider from 'react-slick'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { handleRedirectInternal } from '../../../Product/common/components'
import { useHistory, useLocation } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HeaderSearchComponent from '../Header/HeaderSearch'
import { casinoLocations, manufacturer, sustainabilityOptions } from '../../../utils'
import Timer from '../../../Product/common/timer'
import moment from 'moment'
import SlidePopup from '../../../Component/SlidePanel/SlidePopup'
import { setAuthToken } from '../../../Product/common/api'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const history = useHistory()
    const location = useLocation()
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const {
        search_allproducts,
        getAllSearchProducts,
        search_allauctions,
        getAllSearchAuctions,
        getUpcomingList,
        upcoming_list,
    } = productContext
    const [auctionView, setAuctionView] = useState('Grid')

    const { isAuthenticated, user } = authContext
    const { setAlert } = alertContext

    const classes = useStyles()

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalItems, setTotalItem] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [pageClicked, setPageClicked] = useState(false)
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    const [viewAuction, setViewAuction] = useState([])
    const [active_game_theme, setActiveTheme] = useState([])
    const [upcomingProducts, setUpcomingProducts] = useState([])
    const [audio] = useState(
        global.storeConfigration?.bidding_sound?.value == 1 ? new Audio('/audio/ding.mp3') : '',
    )
    const forMobile = useMediaQuery('(max-width:1024px)')

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const [subCatg] = useState(
        new URLSearchParams(window.location.search.substring(1)).get('subCatg'),
    )
    const [viewType] = useState(
        new URLSearchParams(window.location.search.substring(1)).get('type'),
    )
    const params = new URLSearchParams(window.location.search.substring(1))

    const formik = useFormik({
        initialValues: {
            sh_limit: 12,
            page: params.get('page') ? params.get('page') : 1,
            orderby: '2',
            location: [],
            state: [],
            city: [],
            category: catg ? [catg] : [],
            contenthead5: [],
            subcategory: subCatg ? [subCatg] : [],
            condition: [],
            storedate: [],
            enddate: null,
            startdate: params.get('start_date') ? params.get('start_date') : '',
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            miles: params.get('m') ? params.get('m') : '',
            // from: '',
            closed_auction_list: 0,
            customfield6: [],
            customfield4: [],
            price: [],
            view_type: 2,
            auction_io: 1,
            game_theme: [],
            is_array_subcategory: 1,
        },
    })

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: '0',
        type: '',
    })

    const customOrder = ['Reel Slot', 'Video Slot', 'Video Poker', 'Other', 'Add-Ons']
    const filterValues = [
        // {
        //     title: 'Category',
        //     type: 'check',
        //     name: 'category',
        //     options: global.allCategories
        //         .sort((a, b) => a.description.localeCompare(b.description))
        //         .filter((main) => main.level == 0),
        //     formik: formik,
        // },
        {
            title: 'Slot Machine Type',
            type: 'check',
            name: 'subcategory',
            class: 'col-12',
            options: global.allCategories
                .sort((a, b) => {
                    return customOrder.indexOf(a.description) - customOrder.indexOf(b.description)
                })
                .filter((main) => main.level == 1),
            formik: formik,
        },
        {
            title: 'Casino Locations',
            type: 'check',
            name: 'customfield6',
            class: 'col-12',
            options: casinoLocations.sort((a, b) => a.description.localeCompare(b.description)),
            formik: formik,
        },
        {
            title: 'Themes',
            type: 'check',
            name: 'game_theme',
            class: 'col-12',
            options: active_game_theme.sort((a, b) => a.description?.localeCompare(b.description)),
            formik: formik,
        },
        {
            title: 'Manufacturers',
            type: 'check',
            name: 'customfield4',
            class: 'col-12',
            options: manufacturer.sort((a, b) => a.description.localeCompare(b.description)),
            formik: formik,
        },
    ]
    var extra_filter = [
        {
            type: 'text',
            placeholder: 'From Price',
            class: 'col-6',
            name: 'fromprice',
            formik: formik,
        },
        {
            type: 'text',
            placeholder: 'To Price',
            class: 'col-6',
            name: 'toprice',
            formik: formik,
        },
    ]
    useEffect(() => {
        setLoading(false)

        if (search_allproducts.from === 'activeAuctions') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            if (search_allproducts.game_them.length > 0) {
                var theme_search = [],
                    active_the = search_allproducts.game_them,
                    id_them = []
                console.log('llllllllllllllllllllllllllllll', active_the)
                active_the.map((data) => {
                    var after_data = JSON.parse(data.custom_field_9)
                    if (!id_them.includes(after_data.game_theme) && after_data.game_theme != '') {
                        id_them.push(after_data.game_theme)
                        theme_search.push({
                            id: after_data.game_theme,
                            value: after_data.game_theme,
                            description: after_data.game_theme,
                            name: after_data.game_theme,
                            show: after_data.game_theme,
                        })
                    }
                })

                setActiveTheme(theme_search)
            } else {
                setActiveTheme([])
            }

            setTotalItem(search_allproducts.total_pagecnt)
            const element = document.getElementById('searchSection')
            element.scrollIntoView()
        }

        if (search_allproducts.from === 'upcomingProducts') {
            // console.log('upcoming upcomingProducts results', search_allproducts?.results)
            setUpcomingProducts(search_allproducts.results.length ? search_allproducts.results : [])
        }
    }, [search_allproducts, formik.values])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    useEffect(() => {
        if (viewType == 'upcoming' && viewAuction.length != 0) {
            formik.setFieldValue('startdate', viewAuction[0].date_added)
        }
    }, [viewType, viewAuction])

    // useEffect(() => {
    //     if (search_allauctions.from === 'upcomingHeader') {
    //         setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
    //     }
    // }, [search_allauctions])

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'upcomingHeader')
    }, [auctionSearch])
    useEffect(() => {
        getUpcomingList({ date: new Date() })
    }, [])
    useEffect(() => {
        if (upcoming_list.length) {
            setViewAuction(upcoming_list)
        }
    }, [upcoming_list])
    const onHandlePage = (event, value) => {
        setPageClicked(true)
        // formik.setFieldValue('page', value)
        const changeParams = new URLSearchParams({ page: value })
        history.replace({ pathname: location.pathname, search: changeParams.toString() })
    }
    useEffect(() => {
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
        setLoading(true)
        if (formik.values.closed_auction_list) {
            //formik.values.orderby = '7'
        }
        // console.log('formik values console')
        if (formik.values.view_type == 1) {
            formik.values.from = 'auction_only'
            getAllSearchProducts(formik.values, 'activeAuctions')
        } else if (formik.values.view_type == 2) {
            formik.values.from = 'buynow'
            if (!pageClicked) {
                formik.values.page = params.get('page') ? params.get('page') : 1
            } else {
                setPageClicked(false)
            }
            getAllSearchProducts(formik.values, 'activeAuctions')
        } else {
            formik.values.from = ''
            getAllSearchProducts(formik.values, 'activeAuctions')
        }
    }, [isAuthenticated, formik.values])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('miles', params.get('m') ? params.get('m') : '')
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
        formik.setFieldValue('page', params.get('page') ? params.get('page') : formik.values.page)
    }, [window.location.search])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        if (isAuthenticated) {
            setSelectedProduct(id)
        } else {
            handleRedirectInternal(history, 'login')
        }
    }

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: 6,
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        // {
        //     value: 6,
        //     show: 'Time remaining',
        // },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]

    const settings = {
        dots: true,
        fade: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }

    return (
        <Layout props={props}>
            <div className="search-banner-wrap">
                <img src="/slot4home/search-banner.png" alt="" className="sb-img" />
                <img src="/slot4home/trans-logo.png" alt="" className="sb-logo" />
            </div>
            <div className="customContainer mt-5 position-relative" id="searchSection">
                {forMobile ? (
                    <div className="mblStckyFilter d-flex justify-content-between align-items-center py-3">
                        <Button
                            className="filterButton w-100"
                            variant="outlined"
                            onClick={toggleDrawer('bottom', true)}
                        >
                            Filters
                        </Button>

                        <CustomSelect
                            label="Sort by"
                            value={formik.values.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                formik.setFieldValue('orderby', event.target.value)
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>

                        {/* <Button
                            className="filterButton w-100"
                            variant="outlined"
                            onClick={() => handleRedirectInternal(history, 'dashboard/mybids')}
                        >
                            My Bids
                        </Button> */}

                        <Button
                            className="filterButton srchBtn w-100"
                            variant="outlined"
                            onClick={toggleDrawer('top', true)}
                        >
                            <span className="material-icons">search</span>
                        </Button>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between align-items-center flex-wrap my-5">
                        <h2 className="auctionListTitle"></h2>

                        <div className="auctionsMiscFilter">
                            <CustomSelect
                                label="Results per page"
                                name="resultsPerPage"
                                selectType="noBorder"
                                value={formik.values.sh_limit}
                                size="small"
                                onChange={(event, editor) => {
                                    formik.setFieldValue('sh_limit', event.target.value)
                                }}
                            >
                                {pageOptions.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                label="Sort by"
                                value={formik.values.orderby}
                                size="small"
                                selectType="noBorder"
                                name="orderby"
                                // shrink={search.orderby !== '' ? true : false}
                                onChange={(event, editor) => {
                                    formik.setFieldValue('orderby', event.target.value)
                                }}
                            >
                                {sortShow.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                        </div>
                        <Button
                            className="toggleRespDrawer w-100"
                            variant="outlined"
                            onClick={toggleDrawer('bottom', true)}
                        >
                            <span className="material-icons">tune</span>
                            Filters
                        </Button>
                    </div>
                )}

                <div className="searchContainer">
                    <div className="searchLt">
                        <FilterPanel
                            formik={formik}
                            filterValues={filterValues}
                            filterData={Object.keys(extra_filter).length > 0 ? extra_filter : ''}
                            label={'Price Filter'}
                            allOpen={true}
                        />
                    </div>

                    <div className="searchRt">
                        {isLoading ? (
                            <div>
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                            </div>
                        ) : viewProduct?.length !== 0 ? (
                            <SearchProductList
                                listview={listview}
                                searchResult={viewProduct}
                                className="liquidationBuyCard"
                                cardTheme="auctioneer"
                                cardType={auctionView}
                                audio={audio}
                                enableZoomOnHover={true}
                                type="buynow"
                            />
                        ) : (
                            <NoRecordsFound />
                        )}
                        <Pagination
                            count={Math.ceil(totalItems / formik.values.sh_limit)}
                            page={formik.values.page}
                            onChange={onHandlePage}
                        />
                    </div>
                </div>
            </div>

            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel
                        formik={formik}
                        filterValues={filterValues}
                        filterData={Object.keys(extra_filter).length > 0 ? extra_filter : ''}
                        label={'Price Filter'}
                    />
                </div>
            </Drawer>
            <SlidePopup
                storeTheme=""
                selectedLot={selectedProduct}
                handleClose={handleClose}
                type={props.type ? props.type : ''}
            />
            <React.Fragment>
                <SwipeableDrawer
                    className="respHeaderSearch"
                    anchor={'top'}
                    open={state['top']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('top', false)}
                    onOpen={toggleDrawer('top', true)}
                >
                    <HeaderSearchComponent />
                </SwipeableDrawer>
            </React.Fragment>
        </Layout>
    )
}

export default AuctionSearchComponent
