import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import './PostProduct.css'
import SEO from '../../../utils/CommonFunctionality/SEO'

import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    mapData,
    jsonToFormData,
    handleRedirectInternal,
    dateTimeFormatFunction,
} from '../../../Product/common/components'
import { getAllMake, getModelById, getModelsOfMake, yearList } from '../../../utils/CarConstants'
import AlertContext from '../../../Product/context/alert/alertContext'
import { getYupObject, swapCountry } from '../../../utils/commonFunctions'
import axios from 'axios'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'

import ProductContext from '../../../Product/context/product/productContext'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import {
    allCondition,
    bvManufacturer,
    bvModel,
    cabinetType,
    casinoLocations,
    displayType,
    gamePlatforms,
    horseNonProfit,
    machineModel,
    manufacturer,
    slotCondition,
    tpManufacturer,
    tpModel,
} from '../..'
import { getValidationSchema } from './validationSchema'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import authContext from '../../../Product/context/auth/authContext'
import moment from 'moment'
// import { insertProductAlgolia } from '../Algolia'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const PostProductDynamic = (props) => {
    const classes = useStyles()
    const alertContext = useContext(AlertContext)
    const { type } = useParams()
    const [loading, setLoading] = useState(false)
    const [allData, setAllData] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0)
    const [stateList, setStateList] = useState([])
    const [countryList, setCountryList] = useState([])
    const history = useHistory()
    const steps = getSteps()
    const [masterCat, setMasterCat] = useState([])
    const [childCat, setChildCategory] = useState([])
    const productContext = useContext(ProductContext)
    const {
        postProduct,
        editProduct,
        edit_product,
        getEditProduct,
        responseStatus,
        getOverAllCategories,
        categories,
        getSubCategories,
        sub_categories,
        clearEditProduct,
    } = productContext
    const { setAlert } = alertContext
    const { user } = useContext(authContext)
    const { insertProductAlgolia, updateProductAlgolia } = useContext(pluginContext)
    const product_id = new URLSearchParams(window.location.search.substring(1)).get('id')
    const repost = new URLSearchParams(window.location.search.substring(1)).get('repost')
    const back = new URLSearchParams(window.location.search.substring(1)).get('back')
    const [reload, setReload] = useState(false)
    useEffect(() => {
        getOverAllCategories({ parent: 1 }, '1')
    }, [])

    useEffect(() => {
        setMasterCat(categories)
    }, [categories])

    function getSteps() {
        return [
            'Basic Information',
            'Additional Information',
            'Uploads',
            // 'Dimensions',
            'Listing Type',
            // 'Payment Method',
            'Shipping',
            // 'Return Policy',
        ]
    }

    const productFormik = useFormik({
        initialValues: {
            // page 1
            category_id: '',
            subcategory: [],
            customfield1: '',
            customfield2: '',
            radcondition: '',
            customfield3: '',
            customfield4: '',
            customfield6: '',
            protitle: '',
            description: '',

            // page 2
            serial: '',
            display_type: '',
            board_type: '',
            inventory_date: '',
            player_tracking: '',
            validator_model: '',
            validator_manufacturer: '',
            ticket_printer_model: '',
            ticket_printer_manufacturer: '',
            game_theme: '',
            denomination: '',
            cabinet_color: '',
            door_color: '',
            bet_configuration: '',
            theoritical_hold: '',
            sas_version: '',
            software_number: '',
            panel_present: '',
            motherboard_present: '',
            other_prom: '',
            model_number: '',
            customfield9: '',

            // page 3
            product_image: [],
            product_existingimage: [],
            product_deletedimage: [],

            // page 4
            auction: 0,
            buynow: 1,
            bprice: '',
            mprice: '',
            auctiontype: 'buynow',
            qty: 1,
            customfield5: '',
            startdate: new Date(),
            enddate: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
            make_an_offer_io: 0,

            // page 5
            temp_hasshipping: 1,
            localpickup: 0,
            shippingcost: 0,
            location: '',
            city: '',
            state: '',
            address: '',
            zipcode: '',
            customfield8: '',

            // edit
            product_id: '',
            auctionid: '',
            saletype: 'auction_io',
            store_id: 0,
            contenthead1: '0',
            sku: '',
        },
        validationSchema: getValidationSchema(activeStep + 1),
        // enableReinitialize: true,
        onSubmit: (values) => {
            if (activeStep !== getSteps().length - 1) {
                setActiveStep((prev) => prev + 1)
            } else {
                let formValues = values
                formValues.customfield8 = JSON.stringify({
                    address: formValues.address,
                    zipcode: formValues.zipcode,
                })
                formValues.customfield9 = JSON.stringify({
                    serial: formValues.serial,
                    display_type: formValues.display_type,
                    board_type: formValues.board_type,
                    inventory_date: formValues.inventory_date,
                    player_tracking: formValues.player_tracking,
                    validator_model: formValues.validator_model,
                    validator_manufacturer: formValues.validator_manufacturer,
                    ticket_printer_model: formValues.ticket_printer_model,
                    ticket_printer_manufacturer: formValues.ticket_printer_manufacturer,
                    game_theme: formValues.game_theme?.trim(),
                    denomination: formValues.denomination,
                    cabinet_color: formValues.cabinet_color,
                    door_color: formValues.door_color,
                    bet_configuration: formValues.bet_configuration,
                    theoritical_hold: formValues.theoritical_hold,
                    sas_version: formValues.sas_version,
                    software_number: formValues.software_number,
                    panel_present: formValues.panel_present,
                    motherboard_present: formValues.motherboard_present,
                    other_prom: formValues.other_prom,
                    model_number: formValues.model_number,
                })
                if (formValues.make_an_offer_io) {
                    formValues.make_an_offer_io = 1
                } else {
                    formValues.make_an_offer_io = 0
                }
                if (
                    productFormik.values.category_id ==
                    global?.storeConfigration?.similar_category?.value
                ) {
                    formValues.qty = 100000
                }
                formValues.localpickup = formValues.temp_hasshipping == 1 ? 0 : 1
                formValues.hasshipping = formValues.temp_hasshipping == 2 ? 0 : 1
                formValues.auction_io = 1
                if (formValues.auction == 1 || formValues.buynow == 1) {
                    // alert(JSON.stringify(formValues))
                    let temp = { ...formValues }
                    temp.subcategory = temp.subcategory.join(',')
                    temp.enddate = new Date(
                        new Date(temp.startdate).setFullYear(
                            new Date(temp.startdate).getFullYear() + 5,
                        ),
                    )

                    let formDataSend = jsonToFormData(temp)
                    if (product_id && !repost) {
                        editProduct(formDataSend)
                    } else {
                        postProduct(formDataSend)
                    }
                    setLoading(true)
                } else {
                    setAlert('Please select either buynow or auction', 'warning')
                }
            }
        },
    })
    const page1 = [
        {
            label: 'Category',
            placeholder: 'Select category',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: categories.map((val) => ({
                show: val.name,
                value: val.id,
            })),
            defaultOptionValue: 'Select a Category',
            name: 'category_id',
            formik: productFormik,
        },
        {
            label: 'Select Slot Type',
            placeholder: 'Select a Slot Type',
            type: 'multiselect',
            class: `col-sm-6 col-12 `,
            options: childCat.map((data) => {
                return {
                    value: `${data.id}`,
                    show: data.name,
                }
            }),
            defaultOptionValue: 'Select a Slot Type',
            name: 'subcategory',
            formik: productFormik,
        },
        {
            label: 'Machine Model',
            placeholder: 'Select Machine Model',
            type: 'select',
            class: 'col-12 col-md-6',
            options: global?.storeConfigration?.machine_models?.value
                ? global?.storeConfigration?.machine_models?.value
                      ?.split('>')
                      .map((val) => ({ show: val, value: val }))
                : machineModel,
            defaultOptionValue: 'Select Machine Model',
            name: 'customfield1',
            formik: productFormik,
        },
        {
            label: 'Game Platform',
            placeholder: 'Select Game Platform',
            type: 'select',
            class: 'col-12 col-md-6',
            options: global?.storeConfigration?.game_platforms?.value
                ? global?.storeConfigration?.game_platforms?.value
                      ?.split('>')
                      .map((val) => ({ show: val, value: val }))
                : gamePlatforms,
            defaultOptionValue: 'Select Game Platform',
            name: 'customfield2',
            formik: productFormik,
        },
        {
            label: 'Condition',
            placeholder: 'Select condition',
            type: 'select',
            class: 'col-sm-6 col-12',
            options:
                global?.condition_data?.length > 0 &&
                global?.condition_data?.map((data) => {
                    return {
                        value: data.condition,
                        show: data.condition,
                    }
                }),
            defaultOptionValue: 'Select a Condition',
            name: 'radcondition',
            formik: productFormik,
        },
        {
            label: 'Cabinet type',
            placeholder: 'Select Cabinet type',
            type: 'select',
            class: 'col-12 col-md-6',
            options: cabinetType,
            defaultOptionValue: 'Select Cabinet type',
            name: 'customfield3',
            formik: productFormik,
        },
        {
            label: 'Manufacturer',
            placeholder: 'Select Manufacturer',
            type: 'select',
            class: 'col-12 col-md-6',
            options: global?.storeConfigration?.manufacturer?.value
                ? global?.storeConfigration?.manufacturer?.value
                      ?.split('>')
                      .map((val) => ({ show: val, value: val }))
                : manufacturer,
            defaultOptionValue: 'Select Manufacturer',
            name: 'customfield4',
            formik: productFormik,
        },
        {
            label: 'Casino Locations',
            placeholder: 'Select Casino Locations',
            type: 'select',
            class: 'col-12 col-md-6',
            options: global?.storeConfigration?.casino_locations?.value
                ? global?.storeConfigration?.casino_locations?.value
                      ?.split('>')
                      .map((val) => ({ show: val, value: val }))
                : casinoLocations,
            defaultOptionValue: 'Select Manufacturer',
            name: 'customfield6',
            formik: productFormik,
        },
        {
            label: 'Title',
            placeholder: 'Enter Title',
            type: 'text',
            class: 'col-12',
            name: 'protitle',
            formik: productFormik,
        },
        {
            label: 'Please provide a description',
            placeholder: '',
            type: 'ckeditor1',
            class: 'col-12 description',
            name: 'description',
            formik: productFormik,
        },
    ]
    const page2 = [
        {
            label: 'Serial#',
            placeholder: 'Enter Serial',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'serial',
            formik: productFormik,
        },
        {
            label: 'Display Type',
            placeholder: 'Select Display Type',
            type: 'select',
            class: 'col-12 col-md-6',
            options: displayType,
            defaultOptionValue: 'Select Display Type',
            name: 'display_type',
            formik: productFormik,
        },
        {
            label: 'Board Type',
            placeholder: 'Enter Board Type',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'board_type',
            formik: productFormik,
        },
        {
            label: 'Manufacture Date',
            placeholder: 'YYYY-MM-DD',
            type: 'date_text',
            class: `col-sm-6 col-12 dates`,
            name: 'inventory_date',
            formik: productFormik,
            upperLabel: 1,
            date_only: 1,
            date_only_new: 1,
            maxDate: new Date(),
        },
        {
            label: 'Player Tracking',
            placeholder: 'Enter Player Tracking',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'player_tracking',
            formik: productFormik,
        },
        {
            label: 'Bill Validator Model',
            placeholder: 'Select Bill Validator Model',
            type: 'select',
            class: 'col-12 col-md-6',
            options: bvModel,
            defaultOptionValue: 'Select Bill Validator Model',
            name: 'validator_model',
            formik: productFormik,
        },
        // {
        //     label: 'Bill Validator Manufacturer',
        //     placeholder: 'Select Bill Validator Manufacturer',
        //     type: 'select',
        //     class: 'col-12 col-md-6',
        //     options: bvManufacturer,
        //     defaultOptionValue: 'Select Bill Validator Manufacturer',
        //     name: 'validator_manufacturer',
        //     formik: productFormik,
        // },
        {
            label: 'Ticket Printer Model',
            placeholder: 'Select Ticket Printer Model',
            type: 'select',
            class: 'col-12 col-md-6',
            options: tpModel,
            defaultOptionValue: 'Select Ticket Printer Model',
            name: 'ticket_printer_model',
            formik: productFormik,
        },
        // {
        //     label: 'Ticket Printer Manufacturer',
        //     placeholder: 'Select Ticket Printer Manufacturer',
        //     type: 'select',
        //     class: 'col-12 col-md-6',
        //     options: tpManufacturer,
        //     defaultOptionValue: 'Select Ticket Printer Manufacturer',
        //     name: 'ticket_printer_manufacturer',
        //     formik: productFormik,
        // },
        {
            label: 'Game Theme',
            placeholder: 'Enter Game Theme',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'game_theme',
            formik: productFormik,
        },
        {
            label: 'Denomination',
            placeholder: 'Enter Denomination',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'denomination',
            formik: productFormik,
        },
        {
            label: 'Cabinet Color',
            placeholder: 'Enter Cabinet Color',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'cabinet_color',
            formik: productFormik,
        },
        {
            label: 'Door Color',
            placeholder: 'Enter Door Color',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'door_color',
            formik: productFormik,
        },
        {
            label: 'Bet Configuration',
            placeholder: 'Enter Bet Configuration',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'bet_configuration',
            formik: productFormik,
        },
        // {
        //     label: 'Theoritical Hold %',
        //     placeholder: 'Enter Theoritical Hold %',
        //     type: 'text',
        //     class: 'col-12 col-md-6',
        //     name: 'theoritical_hold',
        //     formik: productFormik,
        // },
        // {
        //     label: 'Sas Version',
        //     placeholder: 'Enter Sas Version',
        //     type: 'text',
        //     class: 'col-12 col-md-6',
        //     name: 'sas_version',
        //     formik: productFormik,
        // },
        // {
        //     label: 'Software Number',
        //     placeholder: 'Enter Software Number',
        //     type: 'text',
        //     class: 'col-12 col-md-6',
        //     name: 'software_number',
        //     formik: productFormik,
        // },
        // {
        //     label: 'Panel Present',
        //     placeholder: 'Select Panel Present',
        //     type: 'select',
        //     class: 'col-12 col-md-6',
        //     options: horseNonProfit,
        //     defaultOptionValue: 'Select Panel Present',
        //     name: 'panel_present',
        //     formik: productFormik,
        // },
        // {
        //     label: 'Mother Board present',
        //     placeholder: 'Select Mother Board present',
        //     type: 'select',
        //     class: 'col-12 col-md-6',
        //     options: horseNonProfit,
        //     defaultOptionValue: 'Select Mother Board present',
        //     name: 'motherboard_present',
        //     formik: productFormik,
        // },
        // {
        //     label: 'Other Prom',
        //     placeholder: 'Enter Other Prom',
        //     type: 'text',
        //     class: 'col-12 col-md-6',
        //     name: 'other_prom',
        //     formik: productFormik,
        // },
        // {
        //     label: 'Model Number',
        //     placeholder: 'Enter Model Number',
        //     type: 'text',
        //     class: 'col-12 col-md-6',
        //     name: 'model_number',
        //     formik: productFormik,
        // },
    ]
    const page3 = [
        {
            type: 'uploadDropZone',
            formik: productFormik,
            name: 'product_image',
            titleText: 'Upload Product Images',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <span>Recommended dimensions: 500px * 500px</span>
                </div>
            ),
            class: 'col-12',
            folder: 'product',
            multiple: true,
            reorder: true,
            accept: 'image/gif,image/*',
            deleteArray: 'product_deletedimage',
        },
        {
            type: 'previousImages',
            formik: productFormik,
            reorder: true,
            name: 'product_existingimage',
            class: 'col-12',
            folder: 'product',
            deleteArray: 'product_deletedimage',
        },
    ]
    const temp_page4 = [
        {
            label: 'Length',
            type: 'number',
            placeholder: 'Enter Length',
            class: `col-md-2 col-12`,
            name: 'length',
            formik: productFormik,
            min: 0,
        },
        {
            label: 'Width',
            type: 'number',
            placeholder: 'Enter Width',
            class: `col-md-2 col-12`,
            name: 'width',
            formik: productFormik,
            min: 0,
        },
        {
            label: 'Height',
            type: 'number',
            placeholder: 'Enter Height',
            class: `col-md-2 col-12`,
            name: 'height',
            formik: productFormik,
            min: 0,
        },
        {
            title: 'Units',
            type: 'radio',
            class: 'col-md-6 col-12',
            int: 1,
            item: [
                { id: 1, description: 'Inches' },
                { id: 2, description: 'Centimeters' },
            ],
            name: 'customfield7',
            formik: productFormik,
        },
        {
            label: 'Weight',
            type: 'number',
            placeholder: 'Enter Weight',
            class: `col-md-6 col-12`,
            name: 'weight',
            formik: productFormik,
            min: 0,
        },
        {
            title: 'Units',
            type: 'radio',
            class: 'col-md-6 col-12 ',
            int: 1,
            item: [
                { id: 1, description: 'Pounds' },
                { id: 2, description: 'Ounces' },
                { id: 3, description: 'Grams' },
            ],
            name: 'customfield6',
            formik: productFormik,
        },
    ]
    const page4 = [
        // {
        //     label: 'Retail Price',
        //     placeholder: 'Enter retail price',
        //     type: 'number',
        //     class: 'col-md-4 col-12',
        //     name: 'mprice',
        //     formik: productFormik,
        // },
        {
            label: 'Buy Now Price',
            placeholder: 'Enter buy price',
            type: 'text',
            class: 'col-md-6 col-12',
            name: 'bprice',
            formik: productFormik,
            // disabled: productFormik.values.mprice ? false : true,
            startAdornment: '$',
        },
        {
            label: 'Quantity',
            type: 'text',
            placeholder: 'Enter Quantity',
            class: `col-sm-6 col-12 ${
                productFormik.values.category_id ==
                global?.storeConfigration?.similar_category?.value
                    ? 'd-none'
                    : ''
            }`,
            name: 'qty',
            formik: productFormik,
        },
        {
            label: 'SEO Keywords',
            placeholder: 'SEO Keywords',
            type: 'text',
            class: 'col-12',
            name: 'customfield5',
            formik: productFormik,
        },

        {
            label: 'Start Date',
            placeholder: 'Enter start date',
            type: 'date_text',
            class: 'col-12 col-md-6 dates',
            name: 'startdate',
            formik: productFormik,
            // upperLabel: true,
        },
        // {
        //     label: 'End Date',
        //     placeholder: 'Enter end date',
        //     type: 'datetime',
        //     class: 'col-12 col-md-6',
        //     name: 'enddate',
        //     formik: productFormik,
        //     // upperLabel: true,
        // },
        {
            label: 'Enable Make An Offer',
            type: 'checkbox',
            class: `col-sm-6 col-12 ${
                global.pluginConfiguration?.make_an_offer?.enable ? '' : 'd-none'
            }`,
            name: 'make_an_offer_io',
            formik: productFormik,
        },
    ]
    const temp_page5 = [
        {
            label: 'Paypal',
            type: 'checkbox',
            class: `col-sm-4 col-12 `,
            name: 'paypal',
            formik: productFormik,
        },
        {
            label: 'Card',
            type: 'checkbox',
            class: `col-sm-4 col-12 `,
            name: 'card',
            formik: productFormik,
        },
        {
            label: 'ACH (US Only)',
            type: 'checkbox',
            class: `col-sm-4 col-12 `,
            name: 'ach',
            formik: productFormik,
        },
    ]
    const page5 = [
        {
            title: '',
            type: 'radio',
            class: 'col-12 col-md-6',
            int: 1,
            item: [
                { id: 1, description: 'Shipping' },
                { id: 2, description: 'Local Pickup' },
            ],
            name: 'temp_hasshipping',
            formik: productFormik,
        },
        {
            label: 'Shipping Price',
            type: 'text',
            placeholder: 'Flat Fee',
            class: `${productFormik.values.temp_hasshipping == 1 ? '' : 'd-none'}  col-sm-6 col-12`,
            name: 'shippingcost',
            formik: productFormik,
            min: 0,
            startAdornment: '$',
        },
        {
            label: 'Address',
            placeholder: 'Enter Address',
            type: 'textarea',
            class: productFormik.values.temp_hasshipping == 2 ? 'col-12' : 'd-none',
            name: 'address',
            formik: productFormik,
        },
        {
            label: 'Country',
            placeholder: 'Select your country',
            class: productFormik.values.temp_hasshipping == 2 ? 'col-12 col-sm-6' : 'd-none',
            type: 'select',
            options: swapCountry(countryList).map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.name
                return busCategoryChanged
            }),
            name: 'location',
            onChange: (e) => {
                productFormik.setFieldValue('location', e.target.value)
                productFormik.setFieldValue('state', '')
            },
        },
        {
            label: 'State',
            placeholder: 'Select your state',
            class: productFormik.values.temp_hasshipping == 2 ? 'col-12 col-sm-6' : 'd-none',
            type: 'select',
            options: stateList.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            }),
            name: 'state',
        },
        {
            label: 'City',
            type: 'text',
            placeholder: 'Enter City',
            class: productFormik.values.temp_hasshipping == 2 ? 'col-12 col-sm-6' : 'd-none',
            name: 'city',
            formik: productFormik,
        },
        {
            label: 'Zip Code',
            type: 'text',
            placeholder: 'Enter Zip Code',
            class: productFormik.values.temp_hasshipping == 2 ? 'col-12 col-sm-6' : 'd-none',
            name: 'zipcode',
            formik: productFormik,
        },
    ]

    const allPages = {
        page1,
        page2,
        page3,
        page4,
        page5,
        // page6,
        // page7,
    }
    useEffect(() => {
        if (activeStep != 0) {
            let temp = {}
            Object.entries(productFormik.values).forEach(([key, value]) => {
                temp[key] = false
            })
            productFormik.setTouched(temp)
        }
        window.scrollTo(0, 0)
    }, [activeStep])
    useEffect(() => {
        if (user?.role == 1) {
            if (global?.storeDetails?.is_billing == 1) {
                setAlert('Kindly Clear The Due To Sell An Item!', 'error')
                history.push('/')
            }
        }
    }, [user])
    const getLocationData = () => {
        var body = {
            countryCode: countryList.find((val) => val.name == productFormik.values.location)
                ?.location_id,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [productFormik.values.location])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    const getStepContent = (stepIndex) => {
        return (
            <>
                <div className="infrmInfo">
                    <div className="row mt-5">{mapData(allPages[`page${stepIndex + 1}`])}</div>
                    <div className="actnBtnWrpr">
                        {stepIndex !== 0 ? (
                            <SecondaryButton
                                label="Prev"
                                btnSize="mr-2"
                                onClick={() => setActiveStep(stepIndex - 1)}
                            />
                        ) : null}
                        {Object.keys(allPages).length === stepIndex + 1 ? (
                            <PrimaryButton
                                label="Submit"
                                onClick={productFormik.handleSubmit}
                                disabled={loading}
                            />
                        ) : (
                            <PrimaryButton label="Next" onClick={productFormik.handleSubmit} />
                        )}
                    </div>
                </div>
            </>
        )
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'postProduct' || responseStatus.from === 'editProduct') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    // window.onbeforeunload = undefined
                    if (global?.pluginConfiguration?.algolia_search?.enable == 1) {
                        if (responseStatus.from === 'postProduct') {
                            insertProductAlgolia({
                                site_id: global?.storeDetails?.site_id,
                                user_id: user?.id,
                                objectID: responseStatus.data[0],
                                title: productFormik.values.protitle,
                                description: productFormik.values.description.trim(),
                                category: productFormik.values.category_id,
                            })
                            if (back == 1) {
                                return history.goBack()
                            }
                            handleRedirectInternal(history, 'dashboard/mylots')
                        }
                        if (responseStatus.from === 'editProduct') {
                            updateProductAlgolia({
                                site_id: global?.storeDetails?.site_id,
                                user_id: user?.id,
                                objectID: product_id,
                                title: productFormik.values.protitle,
                                description: productFormik.values.description.trim(),
                                category: productFormik.values.category_id,
                            })
                            if (back == 1) {
                                return history.goBack()
                            }
                            handleRedirectInternal(history, 'dashboard/mylots')
                        }
                    } else {
                        if (back == 1) {
                            return history.goBack()
                        }
                        handleRedirectInternal(history, 'dashboard/mylots')
                    }
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (product_id) {
            getEditProduct({ product_id })
        }
    }, [product_id])
    useEffect(() => {
        if (edit_product.product_details && product_id && masterCat.length) {
            let details = edit_product.product_details
            productFormik.values.product_id = details.id ? details.id : ''
            productFormik.values.auctionid = details.auctionid ? details.auctionid : ''
            productFormik.values.category_id = details.category_id ? details.category_id : ''
            productFormik.values.radcondition = details.conditionunique
                ? details.conditionunique
                : ''

            productFormik.values.protitle = details.title ? details.title : ''
            productFormik.values.location = details.location ? details.location : ''
            productFormik.values.state = details.state ? details.state : ''
            productFormik.values.city = details.city ? details.city : ''
            productFormik.values.bprice = details.bprice && details.buynow ? details.bprice : ''
            productFormik.values.mprice = details.mprice ? details.mprice : ''
            productFormik.values.startdate = details.date_added
                ? moment(details.date_added, moment.defaultFormat).toDate()
                : dateTimeFormatFunction(new Date())
            productFormik.values.enddate = details.date_closed
                ? dateTimeFormatFunction(details.date_closed, true)
                : dateTimeFormatFunction(new Date())

            if (repost) {
                productFormik.values.start_date = dateTimeFormatFunction(new Date())
            } else {
                productFormik.values.start_date = details.date_added
                productFormik.values.end_date = details.date_closed
            }
            productFormik.values.qty = details.qty ? details.qty : 1
            productFormik.values.customfield1 = details.custom_field_1
            productFormik.values.customfield2 = details.custom_field_2
            productFormik.values.customfield3 = details.custom_field_3
            productFormik.values.customfield4 = details.custom_field_4
            productFormik.values.customfield5 = details.custom_field_5
            productFormik.values.customfield6 = details.custom_field_6
            productFormik.values.customfield8 = details.custom_field_8
            var address = details.custom_field_8?.includes('{')
                ? JSON.parse(details.custom_field_8)
                : { address: '', zipcode: '' }
            var additionalFields = details.custom_field_9?.includes('{')
                ? JSON.parse(details.custom_field_9)
                : {}
            productFormik.values.serial = additionalFields.serial
            productFormik.values.display_type = additionalFields.display_type
            productFormik.values.board_type = additionalFields.board_type
            productFormik.values.player_tracking = additionalFields.player_tracking
            productFormik.values.validator_model = additionalFields.validator_model
            productFormik.values.validator_manufacturer = additionalFields.validator_manufacturer
            productFormik.values.ticket_printer_model = additionalFields.ticket_printer_model
            productFormik.values.ticket_printer_manufacturer =
                additionalFields.ticket_printer_manufacturer
            productFormik.values.game_theme = additionalFields.game_theme
            productFormik.values.denomination = additionalFields.denomination
            productFormik.values.cabinet_color = additionalFields.cabinet_color
            productFormik.values.door_color = additionalFields.door_color
            productFormik.values.bet_configuration = additionalFields.bet_configuration
            productFormik.values.theoritical_hold = additionalFields.theoritical_hold
            productFormik.values.sas_version = additionalFields.sas_version
            productFormik.values.software_number = additionalFields.software_number
            productFormik.values.panel_present = additionalFields.panel_present
            productFormik.values.motherboard_present = additionalFields.motherboard_present
            productFormik.values.other_prom = additionalFields.other_prom
            productFormik.values.model_number = additionalFields.model_number
            productFormik.values.inventory_date = additionalFields.inventory_date
                ? moment(additionalFields.inventory_date, moment.defaultFormat).toDate()
                : ''

            productFormik.values.address = address.address
            productFormik.values.zipcode = address.zipcode
            productFormik.values.enddate = details.date_closed
                ? dateTimeFormatFunction(details.date_closed, true)
                : dateTimeFormatFunction(new Date())
            if (!repost) {
                productFormik.values.product_image = edit_product.attachments.map((i) => i.src)
            }
            productFormik.values.market_status =
                details.market_status == 'sold' ? '' : details.market_status
            productFormik.values.contenthead1 = details.content_head1 ? details.content_head1 : 0
            productFormik.values.temp_hasshipping = details.hasshipping ? 1 : 2
            productFormik.values.localpickup = details.hasshipping ? 0 : 1
            // productFormik.values.description = details.description ? details.description : ''
            productFormik.setFieldValue('description', details.description)

            productFormik.values.product_deletedimage = []
            productFormik.values.shippingcost = details.shippingcost
            setLoading(false)
            // setReload(!reload)
        }
    }, [edit_product.product_details, masterCat])

    useEffect(() => {
        if (edit_product.product_details && product_id && sub_categories?.length) {
            productFormik.setFieldValue(
                'subcategory',
                edit_product.product_details?.subcategory
                    ? edit_product.product_details?.subcategory?.split(',')
                    : [],
            )
            setReload(!reload)
            setAllData(true)
            clearEditProduct()
        }
    }, [sub_categories])
    useEffect(() => {
        if (productFormik.values.category_id != '') {
            getSubCategories({ parent_id: productFormik.values.category_id })
        }
    }, [productFormik.values.category_id])

    useEffect(() => {
        setChildCategory(sub_categories)
    }, [sub_categories])
    useEffect(() => {
        if (edit_product.product_details && product_id) {
            setReload(!reload)
        }
    }, [childCat])

    return (
        <>
            <SEO title={`${global?.storeDetails?.name} | Post single lot`} />
            <div className="carPstWrpr">
                <div className="carPostList">
                    <h3 className="pstHdr text-center">Sell An Item</h3>
                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            <Typography className={classes.instructions}>
                                {allPages.page1.length && getStepContent(activeStep)}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PostProductDynamic
