import openSocket from 'socket.io-client'
let url = process.env.REACT_APP_SOCKET_URL
const socket = openSocket(url, { transports: ['websocket'] })

const subscribeToTimer = (cb) => socket.on('sliservertime', (timestamp) => cb(null, timestamp))

const bidUpdate = (cb) => socket.on('bidAddtime', (bidUpdateData) => cb(null, bidUpdateData))

const extendedBid = (cb) =>
    socket.on('bid_extended', (extendedBidValue) => cb(null, extendedBidValue))

const realclosedupdates = (cb) =>
    socket.on('realclosedupdates', (closeProduct) => cb(null, closeProduct))

const passwordChangedByAdmin = (cb) =>
    socket.on('passwordChangedByAdmin', (changeFlag) => cb(null, changeFlag))

export { subscribeToTimer, bidUpdate, extendedBid, realclosedupdates, passwordChangedByAdmin }
