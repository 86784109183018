import React, { useState, useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import AuthContext from '../../../Product/context/auth/authContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
    jsonToFormData,
} from '../../../Product/common/components'
import Layout from '../Layout'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
const PostAuction = (props) => {
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const history = useHistory()

    const {
        postAuction,
        edit_auction,
        auction_lots,
        getNewAuction,
        getActiveUsers,
        all_active_users,
        getEditAuction,
        responseStatus,
        clearResponse,
    } = productContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user, isAdmin } = authContext
    const [toHome, settoHome] = useState(false)
    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(true)
    const [stateList, setStateList] = useState([])
    const [countryList, setCountryList] = useState([])
    useEffect(() => {
        if (isAuthenticated) {
            if (user.role === '1') {
            } else {
                handleRedirectInternal(history, '')
            }
        }
    }, [isAuthenticated])

    const validationArray = Yup.object({
        title: Yup.string().required('Required!'),
        description: Yup.string().required('Required!'),
        start_date: Yup.date().typeError('Invalid Date!').required('Required!').nullable(),
        end_date: Yup.date()
            .min(Yup.ref('start_date'), 'End date must be greater than start date!')
            .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End date must be greater than start date!',
                test: function (value) {
                    const start_date = dateTimeFormatFunction(this.parent.start_date, true)
                    const end_date = dateTimeFormatFunction(value, true)
                    return !moment(start_date).isSame(moment(end_date))
                },
            })
            .typeError('Invalid Date!')
            .required('Required!')
            .nullable(),
        ending_enable: Yup.boolean(),
        ending_items: Yup.number().when('ending_enable', {
            is: (end) => end === true,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        ending_mints: Yup.number().when('ending_enable', {
            is: (end) => end === true,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        lot_id: Yup.array().required('Required!'),
        product_image: Yup.array(),
        address: Yup.string().required('Required!'),
        country: Yup.string().required('Required!'),
        state: Yup.string().required('Required!'),
        zipcode: Yup.string().required('Required!'),
        city: Yup.string().required('Required!'),
        phone: Yup.string().required('Required!'),
        la_seller_id: Yup.number().typeError('Invalid number!').required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            start_date: dateTimeFormatFunction(new Date(), true),
            end_date: dateTimeFormatFunction(new Date(), true),
            lot_id: [],
            status: 'open',
            auctionid: 0,
            private_auction: 0,
            selectedbuyers: [],
            grouping: 1,
            ending_enable: 0,
            ending_items: 0,
            ending_mints: 0,
            la_seller_id: 0,
            product_image: [],
            product_existingimage: [],
            product_imagefile: [],

            //address
            address: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
            phone: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            if (!values.product_existingimage.length) {
                if (!values.product_imagefile.length) {
                    return setAlert('Image is required!', 'warning')
                }
            }
            values.product_image = values.product_imagefile[0]

            let formDataSend = jsonToFormData(values)
            //console.log('formDataSend', formDataSend)
            if (props.match.params.id) {
                postAuction(formDataSend)
            } else {
                postAuction(formDataSend)
            }
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'success') {
                if (responseStatus.from === 'postAuction') {
                    //handleRedirectInternal(history, `sellerdashboard`)
                    handleRedirectInternal(history, `${'sellerdashboard?page=' + 3}`)
                }
            }
        }
    }, [responseStatus])

    // const MenuProps = {
    //   PaperProps: {
    //     style: {
    //       width: 250,
    //     },
    //   },
    // };

    useEffect(() => {
        if (isAuthenticated) {
            if (props.match.params.id) {
                setLoading(true)
                getActiveUsers({})
                getEditAuction({ auctionid: props.match.params.id })
            } else {
                getNewAuction({})
                getActiveUsers({})
                setLoading(false)
            }
        }
    }, [isAuthenticated])

    // for image rendering
    // useEffect(() => {
    //     async function fetchData() {
    //         if (formik.values.product_imagefile.length) {
    //             await Promise.all([toReRenderImages(formik, setAuction)])
    //         }
    //     }
    //     fetchData()
    // }, [formik.values.product_imagefile])

    //console.log('auction_lots', auction_lots)
    useEffect(() => {
        if (edit_auction && props.match.params.id) {
            //console.log('edit_auction', edit_auction)
            let editAuctionDetails = edit_auction.auction_details
            formik.values.title = editAuctionDetails.title
            formik.values.description = editAuctionDetails.description
            formik.values.start_date = editAuctionDetails.date_added
                ? dateTimeFormatFunction(editAuctionDetails.date_added, true)
                : dateTimeFormatFunction(new Date(), true)
            formik.values.end_date = editAuctionDetails.date_closed
                ? dateTimeFormatFunction(editAuctionDetails.date_closed, true)
                : dateTimeFormatFunction(new Date(), true)
            formik.values.lot_id = auction_lots
                .filter((i) => (i.lotof === props.match.params.id ? true : false))
                .map((dat) => dat.id)
            formik.values.private_auction =
                editAuctionDetails.private_auction &&
                parseInt(editAuctionDetails.private_auction) > 0
                    ? 1
                    : 0
            formik.values.selectedbuyers = editAuctionDetails.selectedbuyers
                ? editAuctionDetails.selectedbuyers.split(',').map((i) => parseInt(i))
                : []
            formik.values.status = 'open'
            formik.values.ending_enable = editAuctionDetails.ending_enable
            formik.values.ending_items = editAuctionDetails.ending_items
            formik.values.ending_mints = editAuctionDetails.ending_mints
            formik.values.auctionid = props.match.params.id
            formik.values.la_seller_id = editAuctionDetails.la_seller_id
            formik.values.product_existingimage = [editAuctionDetails.avatar]
            formik.values.product_imagefile = []

            formik.values.address = editAuctionDetails.address
            formik.values.country = editAuctionDetails.country
            formik.values.state = editAuctionDetails.state
            formik.values.city = editAuctionDetails.city
            formik.values.zipcode = editAuctionDetails.zipcode
            formik.values.phone = editAuctionDetails.custom_field_1
            setLoading(false)
            setReload(!reload)
        }
    }, [edit_auction, auction_lots])
    //console.log(formik.values.lot_id, 'formik.values.lot_id')
    const getLocationData = () => {
        var body = {
            countryCode: formik.values.country,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                //console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.country])
    const productListing = [
        {
            label: 'Auction Title *',
            type: 'text',
            placeholder: 'Enter your Auction Title',
            class: 'col-12',
            name: 'title',
            formik: formik,
        },
        {
            label: 'Description*',
            placeholder: 'Enter Description',
            type: 'ckeditor',
            class: 'col-12',
            name: 'description',
            formik: formik,
        },
        {
            label: 'Lot ' + '*',
            placeholder: 'Select your Lot',
            class: 'col-12 col-sm-6',
            type: 'multiselect',
            options: auction_lots.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.title
                busCategoryChanged.value = buscat.id
                return busCategoryChanged
            }),
            name: 'lot_id',
        },
        {
            label: 'Start date *',
            placeholder: 'Enter Start date',
            type: 'datetime',
            disablePast: true,
            class: 'col-sm-6 col-12',
            name: 'start_date',
            formik: formik,
        },
        {
            label: 'End Date *',
            placeholder: 'Enter End Date',

            disablePast: true,
            type: 'datetime',
            class: 'col-sm-6 col-12',
            name: 'end_date',
            // minDate: dateTimeFormatFunction(formik.values.date_added),
            // minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
            //     formik.values.date_added,
            // )}`,

            formik: formik,
        },
        {
            label: 'Address*',
            placeholder: 'Enter Address',
            type: 'textarea',
            class: 'col-12',
            name: 'address',
            formik: formik,
        },
        {
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: countryList.map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.location_id
                return busCategoryChanged
            }),
            name: 'country',
        },
        {
            label: 'State' + '*',
            placeholder: 'Select your state',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: stateList.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            }),
            name: 'state',
        },
        {
            label: 'City *',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-sm-6 col-12',
            name: 'city',
            formik: formik,
        },
        {
            label: 'Zip *',
            type: 'text',
            placeholder: 'Enter your zip',
            class: 'col-sm-6 col-12',
            name: 'zipcode',
            formik: formik,
        },
        {
            label: 'Phone *',
            type: 'text',
            placeholder: 'Enter your phone number',
            class: 'col-sm-6 col-12',
            name: 'phone',
            formik: formik,
        },
        {
            label: "Buyer's Premium (%) *",
            type: 'number',
            placeholder: "Enter buyer's premium (%)",
            class: 'col-sm-6 col-12',
            name: 'la_seller_id',
            formik: formik,
        },
        {
            label: 'Staggered Bidding',
            type: 'checkbox',
            class: 'col-6',
            name: 'ending_enable',
            formik: formik,
        },

        {
            label: 'Items',
            placeholder: 'Enter items',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'ending_items',
            disabled: formik.values.ending_enable ? false : true,
            formik: formik,
        },
        {
            label: 'Minutes',
            placeholder: 'Enter minutes',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'ending_mints',
            disabled: formik.values.ending_enable ? false : true,
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_imagefile',
            titleText: 'Upload product images *',
            innerText: 'Recommended dimensions: 500px * 500px',
            class: 'col-12',
            folder: 'product',
            multiple: false,
            accept: 'image/*',
        },
        {
            type: 'previousImages',
            formik: formik,
            name: 'product_existingimage',
            class: 'col-12',
            folder: 'product',
        },
    ]

    return (
        <>
            <Layout props={props}>
                <div className="postProductContainer">
                    <h2 className="ppTitle">Post an auction</h2>
                    <form className="pp-form" onSubmit={formik.handleSubmit}>
                        <div>
                            <div className="row">{mapData(productListing)}</div>
                            <div className="pp-btns-wrap">
                                {props.match.params.id ? (
                                    <PrimaryButton type="submit" label="Submit" />
                                ) : (
                                    <PrimaryButton type="submit" label="Submit" />
                                )}
                            </div>
                        </div>
                    </form>{' '}
                </div>
            </Layout>
        </>
    )
}

export default PostAuction
