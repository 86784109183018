import { Box, CircularProgress, IconButton, Typography } from '@material-ui/core'
import React, { useEffect, useState, useContext, useRef, useMemo } from 'react'
import moment from 'moment'
import { socket } from '../../../Product/common/socket'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { useTranslation } from 'react-i18next'
import { PhotoSwipeGallery } from 'react-photoswipe'
import { imageTypes, videoTypes } from '../..'
import ReactPlayer from 'react-player'
const MessageBody = ({
    chatHistory,
    chatUser,
    backImage,
    project_id,
    chat_display,
    hide_message_box,
    hide_header,
}) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { user } = authContext
    const { listAllBuyers, clearImages, uploadImages, message_images, progress, deleteMessage } =
        buyerContext
    const { t } = useTranslation()
    const [messageValue, setMessageValue] = useState('')
    const [chatMessages, setChatMessages] = useState([])
    const [loggedUser, setLoggedUser] = useState({})
    const [imageAttach, setImageAttach] = useState({ selectedFile: [] })
    const [commentimages, setCommentImages] = useState([])
    const [individualCommentImg, setIndividualCommentImg] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [localProgress, setLocalProgress] = useState(0)
    const fileimageupload = useRef(null)
    const filevideoupload = useRef(null)
    const chatMessagesRef = useRef(chatMessages)
    useEffect(() => {
        chatMessagesRef.current = chatMessages
    })
    const commentImageContent = (item) => {
        return <img src={item.thumbnail} alt="slide omg" width={120} height={90} />
    }
    const commentImagePopup = () => {
        setIndividualCommentImg(false)
    }
    const handleImageUpload = (event) => {
        setImageAttach({
            selectedFile: [...imageAttach.selectedFile, ...event.target.files],
        })
    }

    const handleVideoUpload = (event) => {
        const MAX_FILE_SIZE = 35840
        let temp = []
        for (const [key, value] of Object.entries(event.target.files)) {
            if (value.size / 1024 <= MAX_FILE_SIZE) {
                temp.push(value)
            } else {
                setAlert(`${value.name} should be lesser than ${MAX_FILE_SIZE / 1024} MB`, 'error')
            }
        }

        setImageAttach({
            selectedFile: [...imageAttach.selectedFile, ...temp],
        })
    }

    const handleRemoveImg = (index, key) => {
        imageAttach.selectedFile.splice(imageAttach.selectedFile.indexOf(index), 1)
        setImageAttach({ selectedFile: [...imageAttach.selectedFile] })
    }
    const sendMessage = () => {
        if (!messageValue.trim() && !imageAttach?.selectedFile?.length) {
            return false
        }
        if (imageAttach?.selectedFile?.length) {
            setUploadLoading(true)
            const formData = new FormData()
            imageAttach.selectedFile.map((value) => formData.append('image', value))
            return uploadImages(formData)
        }
        var msg = {
            from_id: loggedUser.id,
            to_id: chatUser.id,
            message: messageValue.trim(),
            date_sent: moment(new Date()).format('DD/MM/YYYY hh:mm a'),
            project_id,
        }
        socket.emit('insertMessages', msg)
        setAlert('Message sent successfully', 'success')
        setMessageValue('')
    }
    const messageHandler = (data) => {
        // if (data.to_id == loggedUser.id) {
        //     listAllBuyers({
        //         limit_value: 0,
        //         search_key: ' ',
        //         user_role: project_id ? undefined : user?.role == 1 ? '' : 1,
        //         project_id,
        //     })
        // }
        if (data.from_id == loggedUser.id || data.from_id == chatUser.id) {
            if (data.project_id) {
                if (data.project_id == project_id) {
                    setChatMessages((prev) => [...prev, data])
                    let msgBody = document.getElementById('msgInrBody')
                    if (msgBody) {
                        msgBody.scrollTop = msgBody?.scrollHeight
                    }
                }
            } else {
                setChatMessages((prev) => [...prev, data])
                let msgBody = document.getElementById('msgInrBody')
                if (msgBody) {
                    msgBody.scrollTop = msgBody?.scrollHeight
                }
            }
        }
    }
    const messageHandlerDelete = (data) => {
        let temp = chatMessagesRef.current
        let index = temp.findIndex((value) => value.id == data.message_id)
        console.log(temp, index, 'temp,index')
        if (index > -1) {
            temp.splice(index, 1)
            setChatMessages([...temp])
            console.log(temp, 'temp')
            let msgBody = document.getElementById('msgInrBody')
            if (msgBody) {
                msgBody.scrollTop = msgBody?.scrollHeight
            }
        }
    }
    const handleDelete = (message_id) => {
        deleteMessage({
            message_id,
        })
    }
    const getImages = (data, type) => {
        var commentImage = []
        var commentVideo = []
        if (data.subject && data.subject.includes('[')) {
            JSON.parse(data.subject).map((value) =>
                imageTypes
                    .join(',')
                    .replaceAll('image/', '')
                    .split(',')
                    .includes(value.split('.').pop().toLowerCase())
                    ? commentImage.push({
                          original: `${process.env.REACT_APP_BASE_URL}uploads/${value}`,
                          thumbnail: `${process.env.REACT_APP_BASE_URL}uploads/${value}`,
                          originalClass: 'popupSlider',
                          src: `${process.env.REACT_APP_BASE_URL}uploads/${value}`,
                          w: 1200,
                          h: 900,
                      })
                    : videoTypes
                          .join(',')
                          .replaceAll('video/', '')
                          .split(',')
                          .includes(value.split('.').pop())
                    ? commentVideo.push(`${process.env.REACT_APP_BASE_URL}uploads/${value}`)
                    : null,
            )
        }

        return type === 'image' ? commentImage : commentVideo
    }
    useMemo(() => {
        setLocalProgress(progress)
    }, [progress])
    useEffect(() => {
        if (message_images) {
            var msg = {
                from_id: loggedUser.id,
                to_id: chatUser.id,
                message: messageValue.trim(),
                date_sent: moment(new Date()).format('DD/MM/YYYY hh:mm a'),
                project_id,
                subject: JSON.stringify(message_images.split(',')),
            }
            socket.emit('insertMessages', msg)
            setAlert('Message sent successfully', 'success')
            setMessageValue('')
            clearImages()
            setImageAttach({ selectedFile: [] })
            setUploadLoading(false)
        }
    }, [message_images])
    useEffect(() => {
        if (user) {
            setLoggedUser(user)
        }
    }, [user])

    useEffect(() => {
        if (chatHistory.length) {
            setChatMessages(chatHistory)
            let msgBody = document.getElementById('msgInrBody')

            if (msgBody) {
                msgBody.scrollTop = msgBody?.scrollHeight
            }
        } else {
            setChatMessages([])
        }
    }, [chatHistory])
    useEffect(() => {
        if (Object.keys(loggedUser).length) {
            socket.on('insertMessages_result', (data) => {
                messageHandler(data)
            })
            socket.on('delete_message', (data) => {
                messageHandlerDelete(data)
            })
        }

        return () => {
            socket.off('insertMessages_result', (data) => {
                return data
            })
            socket.off('delete_message', (data) => {
                return data
            })
        }
    }, [loggedUser])

    return (
        <>
            {hide_header == 1 ? null : (
                <div className="topCntnr">
                    <div className="prflWrpr">
                        {Object.keys(chatUser).length
                            ? chatUser.first_name?.charAt(0) + chatUser.last_name?.charAt(0)
                            : ''}
                    </div>
                    <p className="m-0">
                        {Object.keys(chatUser).length
                            ? chatUser.first_name + ' ' + chatUser.last_name
                            : 'Kindly select a user to chat!'}
                    </p>
                </div>
            )}
            <div className={`messageBody`}>
                {backImage != '' && backImage != undefined && (
                    <img className="backDrop" src={backImage} />
                )}
                <div className="msgInrBody" id="msgInrBody">
                    {chat_display ? (
                        <>
                            {chatMessages.map((value) => (
                                <div
                                    className={`chatBblWrpr ${
                                        value.to_id == chatUser.id
                                            ? 'justify-content-end sndWrpr'
                                            : 'justify-content-start'
                                    }`}
                                    key={value.id}
                                >
                                    {value.to_id == chatUser.id ? (
                                        <small>
                                            {moment(value.date_sent).isValid()
                                                ? moment(value.date_sent).format(
                                                      'DD/MM/YYYY h:mm a',
                                                  )
                                                : value.date_sent}
                                        </small>
                                    ) : (
                                        ''
                                    )}
                                    {value.message ? (
                                        <div className="chatBubble">
                                            <pre>{value.message}</pre>
                                        </div>
                                    ) : null}

                                    {value.subject && (
                                        <div className="chatBubble">
                                            {getImages(value, 'image')?.length ? (
                                                <PhotoSwipeGallery
                                                    // key={index}
                                                    isOpen={individualCommentImg}
                                                    items={getImages(value, 'image')}
                                                    thumbnailContent={commentImageContent}
                                                    onClose={commentImagePopup}
                                                    id="CommentPhotos"
                                                />
                                            ) : null}
                                            {getImages(value, 'video')?.length ? (
                                                <ReactPlayer
                                                    url={getImages(value, 'video')}
                                                    width="150px"
                                                    height="150px"
                                                    style={{ margin: '10px 25px' }}
                                                    controls={true}
                                                />
                                            ) : null}
                                        </div>
                                    )}

                                    {value.to_id != chatUser.id ? (
                                        <small>
                                            {moment(value.date_sent).isValid()
                                                ? moment(value.date_sent).format(
                                                      'DD/MM/YYYY h:mm a',
                                                  )
                                                : value.date_sent}
                                        </small>
                                    ) : (
                                        ''
                                    )}
                                    {value.to_id == chatUser.id ? (
                                        <IconButton
                                            className="sndButn ml-auto"
                                            onClick={() => handleDelete(value.id)}
                                        >
                                            <span className="material-icons">delete</span>
                                        </IconButton>
                                    ) : null}
                                </div>
                            ))}
                        </>
                    ) : (
                        ''
                    )}
                    <div className="row pt-5">
                        {Boolean(imageAttach.selectedFile.length) &&
                            imageAttach.selectedFile.map((ele, key) => (
                                <div className="col-md-12 ml-auto mt-2 d-flex justify-content-end">
                                    <div className="pp-uimg">
                                        <span
                                            className="img-del "
                                            onClick={() => handleRemoveImg(ele, key)}
                                        >
                                            {' '}
                                            X{' '}
                                        </span>
                                    </div>
                                    {imageTypes.includes(ele.type) && (
                                        <img
                                            src={URL.createObjectURL(ele)}
                                            style={{ width: '150px', height: '150px' }}
                                        />
                                    )}
                                    {videoTypes.includes(ele.type) && (
                                        <ReactPlayer
                                            url={URL.createObjectURL(ele)}
                                            width="150px"
                                            height="150px"
                                            controls={true}
                                        />
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
                <input
                    type="file"
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                    ref={fileimageupload}
                    accept="image/png,image/jpeg,image/jpg"
                    multiple
                    onClick={(event) => {
                        event.target.value = null
                    }}
                />
                <input
                    type="file"
                    onChange={handleVideoUpload}
                    style={{ display: 'none' }}
                    ref={filevideoupload}
                    accept={videoTypes.join(',')}
                    multiple
                    onClick={(event) => {
                        event.target.value = null
                    }}
                />
                {hide_message_box == 1 ? null : (
                    <div className="msgIptWrpr topCntnr">
                        <textarea
                            placeholder={t('type_message_here')}
                            onChange={(e) => setMessageValue(e.target.value)}
                            value={messageValue}
                        />
                        {uploadLoading && (
                            <Box position="relative" display="inline-flex">
                                <CircularProgress variant="determinate" value={localProgress} />
                                <Box
                                    top={0}
                                    left={0}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        color="textSecondary"
                                    >
                                        {localProgress}%
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        <IconButton
                            className="sndButn"
                            onClick={() => fileimageupload.current.click()}
                            disabled={uploadLoading}
                        >
                            <span className="material-icons">image</span>
                        </IconButton>
                        {user?.role == 1 ? (
                            <IconButton
                                className="sndButn"
                                onClick={() => filevideoupload.current.click()}
                                disabled={uploadLoading}
                            >
                                <span className="material-icons">video_call</span>
                            </IconButton>
                        ) : null}
                        <IconButton
                            className="sndButn"
                            onClick={sendMessage}
                            disabled={uploadLoading}
                        >
                            <span className="material-icons">send</span>
                        </IconButton>
                    </div>
                )}
            </div>
        </>
    )
}

export default MessageBody
