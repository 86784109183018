import React, { useEffect, useState, useRef, useContext } from 'react'
import { socket } from '../../../Product/common/socket'

import useUserMedia from './useUserMedia/useUserMedia'

import { mapData, DirectAPICAll } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import './VideoAuction.css'

const BroadCaster = () => {
    const [broadcaster, setBroadcaster] = useState('')
    const [name, setName] = useState('')
    const [peerConnections, setPeerConnections] = useState({})
    const [numberOfViewers, setNumberOfViewers] = useState(0)
    const [broadcastLaunched, setBroadcastLaunched] = useState(false)
    const [constraints, setConstraints] = useState({
        audio: true,
        video: { facingMode: 'user' },
    })

    const config = {
        iceServers: [
            {
                urls: process.env.REACT_APP_TURN_URL,
                credential: process.env.REACT_APP_TURN_CREDENTIAL,
                username: process.env.REACT_APP_TURN_USERNAME,
            },
        ],
    }

    const videoRef = useRef()
    const mediaStream = useUserMedia(constraints)

    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
        videoRef.current.srcObject = mediaStream
    }

    useEffect(() => {
        socket.on('broadcaster', (id) => {
            //console.log('broadcaster', id)
            setBroadcaster(id)
        })
    }, [socket])

    useEffect(() => {
        socket.on('watcher', (id) => {
            //console.log('watcher', id)
            const peerConnection = new RTCPeerConnection(config)
            peerConnections[id] = peerConnection

            setPeerConnections((peerConnections[id] = peerConnection))
            let stream = videoRef.current.srcObject
            stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream))

            peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    socket.emit('candidate', id, event.candidate)
                }
            }

            peerConnection
                .createOffer()
                .then((sdp) => peerConnection.setLocalDescription(sdp))
                .then(() => {
                    socket.emit('offer', id, peerConnection.localDescription)
                })
            setNumberOfViewers(Object.keys(peerConnections).length)
        })
    }, [socket])

    useEffect(() => {
        socket.on('answer', (id, description) => {
            //console.log('answer', id, description)
            peerConnections[id].setRemoteDescription(description)
        })
    }, [socket])

    useEffect(() => {
        socket.on('candidate', (id, candidate) => {
            //console.log('candidate', id, candidate)
            peerConnections[id].addIceCandidate(new RTCIceCandidate(candidate))
        })
    }, [socket])

    useEffect(() => {
        socket.on('disconnectPeer', (id) => {
            //console.log('disconnectPeer', id)
            peerConnections[id].close()
            delete peerConnections[id]
            setNumberOfViewers(Object.keys(peerConnections).length)
        })
    }, [socket])

    useEffect(() => {
        window.onunload = window.onbeforeunload = () => {
            disconnectBroadcaster()
            socket.close()
        }
    }, [window])

    const handleCanPlay = () => {
        videoRef.current.play()
    }

    const handleNewBroadcaster = () => {
        //console.log('id', socket.id)
        socket.emit('broadcaster', socket.id)
    }

    const launchBroadcast = async () => {
        try {
            //console.log('API CALL launchBroadcast')
            const payload = {
                broadcaster: broadcaster,
                auctionid: formik.values.auctionid,
            }
            //console.log('payload', payload)
            const response = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_BASE_URL}api/newVideoBroadCaster`,
                payload,
            )
            //console.log('username: name', response)
            setBroadcastLaunched(true)
            let broadcasterData = response.data.data.responseData
            socket.emit('new-broadcaster', broadcasterData)
            return broadcasterData
        } catch (error) {
            //console.log('err:', error)
        }
    }

    const disconnectBroadcaster = async () => {
        try {
            const payload = {
                broadcaster: broadcaster,
            }

            const offTheAir = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_BASE_URL}api/stopVideoBroadCaster`,
                payload,
            )
            socket.emit('stop-broadcaster')
            return offTheAir
        } catch (error) {
            //console.log('err:', error)
        }
    }

    // oUR Code
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const { seller_allauctions, getAllSellerAuctions } = productContext
    const { isAuthenticated } = authContext

    const validationArray = Yup.object({
        auctionid: Yup.array().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            auctionid: [],
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (broadcastLaunched) {
                disconnectBroadcaster()
            } else {
                launchBroadcast()
            }
        },
    })

    useEffect(() => {
        if (isAuthenticated) {
            getAllSellerAuctions({
                status: 'open',
                page: 1,
                limit: 20,
            })
        }
    }, [isAuthenticated])

    useEffect(() => {
        //console.log('seller_allauctions', seller_allauctions)
    }, [seller_allauctions])

    const personalDetailsNew = [
        {
            label: 'Auction Lots',
            placeholder: 'Select Auctions',
            type: 'multiselect',
            class: 'col-sm-6 col-12',
            options: seller_allauctions.result.map((user) => {
                let locationChanged = {}
                locationChanged.show = user.title
                locationChanged.value = user.id
                return locationChanged
            }),
            defaultOptionValue: 'Select a Auction',
            name: 'auctionid',
            formik: formik,
        },
    ]

    return (
        <div className="broadcastCnt">
            <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                <div className="row">
                    {Object.values(mapData(personalDetailsNew))}
                    <div className="col-sm-3 col-12">
                        <SecondaryButton
                            disabled={broadcastLaunched ? true : false}
                            type="button"
                            label="Connect"
                            onClick={() => handleNewBroadcaster()}
                        />
                    </div>
                    <div className="col-sm-3 col-12">
                        {broadcaster && !broadcastLaunched ? (
                            <PrimaryButton
                                disabled={broadcastLaunched ? true : false}
                                type="submit"
                                label="Start Broadcast"
                            />
                        ) : broadcastLaunched ? (
                            <PrimaryButton
                                disabled={broadcastLaunched ? false : true}
                                type="submit"
                                label="End Broadcast"
                            />
                        ) : null}
                    </div>
                </div>
            </form>
            {/* {videoRef?.current?.srcObject && ( */}
            <video
                className="broadcasterVideo"
                autoPlay={true}
                muted
                ref={videoRef}
                onCanPlay={handleCanPlay}
                playsInline
            />
            {/* )} */}
            <h3 className="broadcastCount">Viewers: {numberOfViewers}</h3>
        </div>
    )
}
export default BroadCaster
