import { GET_LOT_DATA, GET_LOT_DATA_COUNT, GET_LOT_BODY } from '../type'

const SearchLotReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_LOT_DATA: {
            return Object.assign({}, state, {
                lotData: action.payload,
            })
        }
        case GET_LOT_DATA_COUNT: {
            return Object.assign({}, state, {
                lotDataCount: action.payload,
            })
        }
        case GET_LOT_BODY: {
            return Object.assign({}, state, {
                lotDataBody: action.payload,
            })
        }
        default:
            return state
    }
}

export default SearchLotReducer
