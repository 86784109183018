import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import './dashboard.css'
import { ListItem, Collapse, List } from '@material-ui/core'
import StarBorder from '@material-ui/icons/StarBorder'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AuthContext from '../../../Product/context/auth/authContext'

const DashboardPanel = ({ title, subtitle, savedcards, seller, invoices }) => {
    const { user } = useContext(AuthContext)

    const [menuopen, setMenuopen] = React.useState(true)

    const handleClick = () => {
        setMenuopen(!menuopen)
    }
    return (
        <div className="dashboardLt">
            <ul>
                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard/profile">
                        <span className="material-icons">person</span>
                        My Account
                    </NavLink>
                </ListItem>
                {user && user.role == 1 ? (
                    <ListItem button>
                        <NavLink activeClassName="active" to="/sellerdashboard">
                            <span className="material-icons">gavel</span> My Auctions
                        </NavLink>
                    </ListItem>
                ) : (
                    <ListItem button>
                        <NavLink activeClassName="active" to="/dashboard/mybids">
                            <span className="material-icons">gavel</span> My Bids
                        </NavLink>
                    </ListItem>
                )}
                {user && user.role == 0 && (
                    <ListItem button>
                        <NavLink to="/dashboard/favorites" activeClassName="active">
                            <span className="material-icons">favorite</span>
                            My Favorites
                        </NavLink>
                    </ListItem>
                )}
                {invoices && (
                    <ListItem button>
                        <NavLink to="/dashboard/transactions" activeClassName="active">
                            <span className="material-icons">receipt</span>
                            Invoices
                        </NavLink>
                    </ListItem>
                )}
                <ListItem button>
                    <NavLink to="/dashboard/cards" activeClassName="active">
                        <span className="material-icons">payments</span>
                        Saved Cards
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink to="/dashboard/savedSearch" activeClassName="active">
                        <span className="material-icons">bookmark</span>
                        Saved Search
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink to="/dashboard/preference" activeClassName="active">
                        <span className="material-icons">notifications</span>
                        Preference
                    </NavLink>
                </ListItem>
                {user && user.role == 1 && (
                    <ListItem button>
                        <NavLink to="/dashboard/reports" activeClassName="active">
                            <span className="material-icons">description</span>
                            Reports
                        </NavLink>
                    </ListItem>
                )}
            </ul>
        </div>
    )
}

export default withRouter(DashboardPanel)
