import React, { useState, useEffect, useContext } from 'react'

import Layout from '../Layout'
import BroadCaster from '../../../utils/CommonFunctionality/VideoAuction/BroadCaster'

const VideoStream = () => {
    return (
        <Layout>
            <div className="clearfix row vcaLogin">
                <div className="col-12">
                    <div className="loginFormBox">
                        <BroadCaster />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default VideoStream
