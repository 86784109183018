/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Link, useHistory } from 'react-router-dom'
import {
    currencyFormat,
    dateFormatFront,
    dateFormatFunction,
    dateTimeStripe,
    handleRedirectInternal,
    dateTimeFormatFunction,
} from '../../../Product/common/components'
import { Redirect } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
import Timer from '../../../Product/common/timer'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { getImageurl, imageError } from '../../../utils/commonFunctions'
import Loader from '../../../assets/loader'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import StripeCardContext from '../../../Product/context/stripe/card/cardContext'
import { Button } from '@material-ui/core'
const Invoice = (props) => {
    const productContext = useContext(ProductContext)
    const {
        seller_allproducts,
        getAllSellerProducts,
        get_seller_dashboard,
        seller_dashboard_data,
    } = productContext
    const { get_all_seller_payouts, get_all_payout_details, responseStatus } =
        useContext(StripeCardContext)
    const authContext = useContext(AuthContext)
    const { isAuthenticated, isAdmin, user } = authContext
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [payoutdetails, setPayoutdetails] = useState([])
    const [data, setData] = useState([])
    const [userData, setUserData] = useState({})
    const [sellerData, setSellerData] = useState({})
    const [loading, setLoading] = useState(true)
    const [hasmore, setHasmore] = useState(true)
    const history = useHistory()
    const [toInvoice, settoInvoice] = useState(false)
    const [toInvoiceID, settoInvoiceID] = useState({
        inv_id: 0,
        user_id: 0,
    })

    const [search, setSearch] = useState({
        limit: 12,
        pageNo: 0,
        account_id: '',
    })
    useEffect(() => {
        setLoading(false)
        //console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnn', get_all_payout_details.records)
        if (get_all_payout_details) {
            // console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnn', get_all_payout_details)
            if (get_all_payout_details?.records?.length > 0) {
                setPayoutdetails(get_all_payout_details.records)
                setHasmore(get_all_payout_details.has_more)
            }
        }
    }, [get_all_payout_details])
    useEffect(() => {
        // console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnn', responseStatus)
        if (responseStatus) {
            if (responseStatus.from == 'payout_source_list') {
                // if (responseStatus.data.status == 'success') {
                //     if (responseData?.records?.length > 0) {
                //         setPayoutdetails(
                //             responseData.data.records.length ? responseData.data.records.length : [],
                //         )
                //         setHasmore(responseData.data.hasMore)
                //     }
                // }
                setLoading(false)
            }
        }
    }, [responseStatus])
    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }
    const pagination_prev = () => {
        var data = {
            limit: 12,
            pageNo: 0,
            account_id: user.card_profileid,
            ending_before: payoutdetails[0].id,
        }

        get_all_seller_payouts(data)
    }
    const pagination_next = () => {
        var data = {
            limit: 12,
            pageNo: 0,
            account_id: user.card_profileid,
            starting_after: payoutdetails[payoutdetails.length - 1].id,
        }

        get_all_seller_payouts(data)
    }

    useEffect(() => {
        if (user) {
            setLoading(true)
            search.account_id = user.card_profileid
            get_all_seller_payouts(search)
        }
    }, [user])

    return (
        <div>
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : payoutdetails.length ? (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    {/* <th className="numCell">#</th> */}
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Description</th>
                                    <th>Statement desc</th>
                                    <th>Initiated</th>
                                    <th>Est.Arrival</th>
                                </tr>
                            </thead>
                            {payoutdetails.map((item, index) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr>
                                                {/* <td>{index + 1}</td> */}
                                                <td>{currencyFormat(item.amount / 100)}</td>
                                                <td
                                                    style={
                                                        item.status == 'paid'
                                                            ? { color: 'green' }
                                                            : { color: 'blue' }
                                                    }
                                                >
                                                    {item.status}
                                                </td>
                                                <td>{item.id}</td>
                                                <td>{item.description}</td>
                                                <td>{dateTimeStripe(item.created)}</td>
                                                <td>{dateTimeStripe(item.arrival_date)}</td>
                                            </tr>
                                        </tbody>
                                    </>
                                )
                            })}
                        </table>
                    </div>
                    <div style={{ float: 'right' }}>
                        {/* <div className="ml-auto mt-2">
                            {'Showing'} {search.limit * (search.pageNo - 1) + 1} -{' '}
                            {search.limit * search.page > get_all_payout_details.length
                                ? get_all_payout_details.length
                                : search.limit * search.page}{' '}
                            of {get_all_payout_details.length}
                        </div>
                        <Pagination
                            count={Math.ceil(get_all_payout_details.length / search.limit)}
                            page={search.page}
                            onChange={onHandlePage}
                        /> */}
                        <div>
                            <Button
                                //className="dropdown-toggle actionbutton"
                                onClick={() => pagination_prev()}
                            >
                                Previous
                            </Button>
                            <Button
                                //className="dropdown-toggle actionbutton"
                                onClick={() => pagination_next()}
                                disabled={!hasmore}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                    {/* <MultisellerSlider
                        selectedLot={selectedProduct}
                        handleClose={handleClose}
                        type={props.type ? props.type : ''}
                    /> */}
                </>
            ) : (
                <>
                    <NoRecordsFound />
                </>
            )}
        </div>
    )
}

export default Invoice
