import React from 'react'
import MakeAnOffer from '../../../utils/CommonFunctionality/MakeAnOffer'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'

const MakeOffer = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="Make An Offer">
                <MakeAnOffer props={props} />
            </DashboardLayout>
        </Layout>
    )
}

export default MakeOffer
