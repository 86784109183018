import React, { useContext, useEffect } from 'react'
import Layout from '../Layout'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'

const ProductView = () => {
    return (
        <Layout>
            <div className="contact-main-container">
                <MultisellerSlider viewPage={true} />
            </div>
        </Layout>
    )
}

export default ProductView
