import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './Auctions.css'
import { Button } from '@material-ui/core'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import Loader from '../../../assets/loader'
import Loaders from '../../../Product/components/molecules/Loaders'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import { useFormik } from 'formik'
import moment from 'moment'
import { date } from 'yup/lib/locale'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckBox from '../../../Product/components/atoms/CheckBox'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 50,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const Auctions = (props) => {
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allauctionproducts, getLotList } =
        productContext
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [auctionView, setAuctionView] = useState('Grid')
    const [subLotData, setSubLotData] = useState([])
    const [auction_type, setAuction_type] = useState('')
    const [filterValues, setFilterValues] = useState('')
    const params = new URLSearchParams(window.location.search.substring(1))
    const [auction_filterval, setAuctionfilter] = useState('')
    const [expanded, setExpanded] = useState('panel0')

    const handleChange = (panel) => (event, newExpanded) => {
        if (panel === expanded && !newExpanded) {
            setExpanded(newExpanded ? panel : false)
        } else {
            setExpanded(newExpanded ? panel : false)
        }
    }
    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        is_auction: 1,
        searchbar: '',
        auc_type: '',
        type: '',
    })

    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        orderby: '2',
        lotof: '',
        limit: 50,
        market_status: 'open',
        auction_io: 1,
    })

    useEffect(() => {
        setLoading(true)
        var data = auctionSearch

        if (props?.match?.params?.type) {
            var auction_type_loc = props.match.params.type
            setAuction_type(auction_type_loc)
            // console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnn', props.match.params.type)
            if (parseInt(auction_type_loc) == 0) {
                data.auc_type = '2'
            } else if (parseInt(auction_type_loc) == 1) {
                data.auc_type = '1'
            } else {
                data.auc_type = '3'
                data.type = 'closed'
            }
        }
        getAllSearchAuctions(auctionSearch, 'activeHome')
    }, [auctionSearch])

    useEffect(() => {
        if (subLotSearch.lotof) {
            getLotList(subLotSearch, 'homePageAuction')
        }
    }, [subLotSearch])

    useEffect(() => {
        setLoading(false)
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const onHandlePage = (event, value) => {
        setAuctionSearch({ ...auctionSearch, page: value })
    }

    useEffect(() => {
        if (search_allauctionproducts.from === 'homePageAuction') {
            // console.log('search_allauctionproducts', search_allauctionproducts)
            setSubLotData(search_allauctionproducts.results)
        }
    }, [search_allauctionproducts])

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'All Auctions',
        },
        {
            value: 1,
            show: 'Upcoming Auctions',
        },
        {
            value: 2,
            show: 'Live Auctions',
        },
        {
            value: 3,
            show: 'Completed Auctions',
        },
    ]

    const formik = useFormik({
        initialValues: {
            start_date: params.get('end_date')
                ? [moment(params.get('end_date')).format('YYYY-MM-DD')]
                : [],
        },
    })
    //console.log('00000000000000000000000000000', params.get('end_date'))

    useEffect(() => {
        if (viewAuction.length) {
            setSubLotSearch({ ...subLotSearch, lotof: viewAuction[0].id })
            if (auction_filterval.length == 0) {
                var auction_filter = [],
                    auction_start_date = []
                viewAuction.reverse().map((val) => {
                    if (!auction_start_date.includes(moment(val.date_added).format('YYYY-MM-DD'))) {
                        auction_filter.push({
                            id: moment(val.date_added).format('YYYY-MM-DD'),
                            description: `${moment(val.date_added).format('MMM Do')}(
                        ${moment(val.date_added).format('dddd')})`,
                        })
                        auction_start_date.push(moment(val.date_added).format('YYYY-MM-DD'))
                    }
                })

                setAuctionfilter(auction_filter)
                setFilterValues([
                    {
                        title: 'Upcoming Auctions',
                        type: 'check',
                        name: 'start_date',
                        options: auction_filter,
                        formik: formik,
                    },
                ])
                var data = auctionSearch
                data.start_date = formik.values.start_date
                getAllSearchAuctions(data, 'activeHome')
            }
        }
    }, [viewAuction])

    useEffect(() => {
        //if (formik.values.start_date) {
        if (auction_filterval.length != 0) {
            setLoading(true)
            var data = auctionSearch
            data.start_date = formik.values.start_date
            if (props?.match?.params?.type) {
                var auction_type_loc = props.match.params.type
                setAuction_type(auction_type_loc)
                if (parseInt(auction_type_loc) == 0) {
                    data.auc_type = '2'
                } else if (parseInt(auction_type_loc) == 1) {
                    data.auc_type = '1'
                } else {
                    data.auc_type = '3'
                    data.type = 'closed'
                }
            }
            // formik.setFieldvalue('start_date', data.date_added)
            // var data_set = auction_filterval
            // setAuctionfilter(data_set)
            getAllSearchAuctions(data, 'activeHome')
            //}
        }
    }, [formik.values.start_date])

    return (
        <Layout props={props}>
            <div className="customContainer auctionsSearch">
                <div className="d-flex justify-content-between align-items-center flex-wrap mt-5 mb-3 auctionSearchCnt">
                    <h2 className="auctionListTitle">
                        {auctionSearch.auc_type == 1
                            ? 'Upcoming'
                            : auctionSearch.auc_type == 2
                            ? 'Live'
                            : auctionSearch.auc_type == 3
                            ? 'Completed'
                            : 'All'}{' '}
                        Auctions
                    </h2>
                    <div className="auctionsMiscFilter">
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => setAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => setAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={auctionSearch.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                setAuctionSearch({ ...auctionSearch, sh_limit: event.target.value })
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        {/* <CustomSelect
                            label="Auction Type"
                            value={auctionSearch.auc_type}
                            size="small"
                            selectType="noBorder"
                            name="auc_type"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                setAuctionSearch({
                                    ...auctionSearch,
                                    auc_type: event.target.value,
                                    type: event.target.value == 3 ? 'closed' : '',
                                    is_auction: event.target.value == 3 ? 1 : '',
                                })
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect> */}
                    </div>
                </div>
                <>
                    <>
                        {loading ? (
                            <div className="text-center">
                                <Loaders name="product_grid_view" isLoading={loading} loop={1} />
                            </div>
                        ) : viewAuction.length === 0 ? (
                            <NoRecordsFound />
                        ) : parseInt(auction_type) == 2 ? (
                            <div className="d-flex align-items-start wrprWtFltr">
                                {filterValues ? (
                                    <div className="searchLt">
                                        {/* <FilterPanel
                                        formik={formik}
                                        filterValues={filterValues ? filterValues : []}
                                        allOpen={true}
                                    /> */}
                                        <Accordion
                                            square
                                            key={0}
                                            expanded={expanded === `panel${0}`}
                                            onChange={handleChange(`panel${0}`)}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1d-content"
                                                id={`panel${0}d-header`}
                                            >
                                                <h6 className="accTitle">{'Upcoming Auctions'}</h6>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="filterCheck d-flex align-items-center flex-wrap">
                                                    {filterValues[0].options &&
                                                        filterValues[0].options.map(
                                                            (opt, optindex) => (
                                                                <>
                                                                    <CheckBox
                                                                        key={optindex}
                                                                        name={'start_date'}
                                                                        //disabled={opt.disabled}
                                                                        label={opt.description}
                                                                        //dataCount={data.dataCount}
                                                                        checked={
                                                                            formik.values[
                                                                                'start_date'
                                                                            ].indexOf(
                                                                                opt.id.toString(),
                                                                            ) !== -1
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        value={opt.id.toString()}
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                    />
                                                                </>
                                                            ),
                                                        )}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div
                                    className={`searchResults ${auctionView} ${
                                        filterValues ? 'wtfltr' : ''
                                    }`}
                                >
                                    {viewAuction.map((data, index) =>
                                        auctionView === 'Grid' ? (
                                            <GridAuction
                                                key={index}
                                                data={data}
                                                details={`${
                                                    data.con_check
                                                        ? 'liveauctionview'
                                                        : 'search/product-buyer-auction'
                                                }/${data.id}`}
                                                timerTheme="multiseller"
                                                theme="multiseller"
                                                lotCount={data.lotCount ? data.lotCount : 0}
                                            />
                                        ) : (
                                            <MultisellerListCard
                                                key={index}
                                                data={data}
                                                allsearchitems={viewAuction}
                                                lotCount={data.lotCount ? data.lotCount : 0}
                                                details={`${
                                                    data.con_check
                                                        ? 'liveauctionview'
                                                        : 'search/product-buyer-auction'
                                                }/${data.id}`}
                                            />
                                        ),
                                    )}
                                </div>
                            </div>
                        ) : auctionSearch.auc_type == 3 ? (
                            <div className="d-flex align-items-start wrprWtFltr">
                                {filterValues ? (
                                    <div className="searchLt">
                                        {/* <FilterPanel
                                            formik={formik}
                                            filterValues={filterValues ? filterValues : []}
                                            allOpen={true}
                                        /> */}
                                        <Accordion
                                            square
                                            key={0}
                                            expanded={expanded === `panel${0}`}
                                            onChange={handleChange(`panel${0}`)}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1d-content"
                                                id={`panel${0}d-header`}
                                            >
                                                <h6 className="accTitle">{'Upcoming Auctions'}</h6>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="filterCheck d-flex align-items-center flex-wrap">
                                                    {filterValues[0].options &&
                                                        filterValues[0].options.map(
                                                            (opt, optindex) => (
                                                                <>
                                                                    <CheckBox
                                                                        key={optindex}
                                                                        name={'start_date'}
                                                                        //disabled={opt.disabled}
                                                                        label={opt.description}
                                                                        //dataCount={data.dataCount}
                                                                        checked={
                                                                            formik.values[
                                                                                'start_date'
                                                                            ].indexOf(
                                                                                opt.id.toString(),
                                                                            ) !== -1
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        value={opt.id.toString()}
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                    />
                                                                </>
                                                            ),
                                                        )}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div
                                    className={`searchResults ${auctionView} ${
                                        filterValues ? 'wtfltr' : ''
                                    }`}
                                >
                                    {viewAuction.map((data, index) =>
                                        auctionView === 'Grid' ? (
                                            <GridAuction
                                                key={index}
                                                data={data}
                                                details={`${
                                                    data.con_check
                                                        ? 'liveauctionview'
                                                        : 'search/product-buyer-auction'
                                                }/${data.id}`}
                                                timerTheme="multiseller"
                                                theme="multiseller"
                                                lotCount={data.lotCount ? data.lotCount : 0}
                                            />
                                        ) : (
                                            <MultisellerListCard
                                                key={index}
                                                data={data}
                                                allsearchitems={viewAuction}
                                                lotCount={data.lotCount ? data.lotCount : 0}
                                                details={`${
                                                    data.con_check
                                                        ? 'liveauctionview'
                                                        : 'search/product-buyer-auction'
                                                }/${data.id}`}
                                            />
                                        ),
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex align-items-start wrprWtFltr">
                                {filterValues ? (
                                    <div className="searchLt">
                                        {/* <FilterPanel
                                            formik={formik}
                                            filterValues={filterValues ? filterValues : []}
                                            allOpen={true}
                                        /> */}
                                        <Accordion
                                            square
                                            key={0}
                                            expanded={expanded === `panel${0}`}
                                            onChange={handleChange(`panel${0}`)}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1d-content"
                                                id={`panel${0}d-header`}
                                            >
                                                <h6 className="accTitle">{'Upcoming Auctions'}</h6>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="filterCheck d-flex align-items-center flex-wrap">
                                                    {filterValues[0].options &&
                                                        filterValues[0].options.map(
                                                            (opt, optindex) => (
                                                                <>
                                                                    <CheckBox
                                                                        key={optindex}
                                                                        name={'start_date'}
                                                                        //disabled={opt.disabled}
                                                                        label={opt.description}
                                                                        //dataCount={data.dataCount}
                                                                        checked={
                                                                            formik.values[
                                                                                'start_date'
                                                                            ].indexOf(
                                                                                opt.id.toString(),
                                                                            ) !== -1
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        value={opt.id.toString()}
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                    />
                                                                </>
                                                            ),
                                                        )}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div
                                    className={`searchResults ${auctionView} ${
                                        filterValues ? 'wtfltr' : ''
                                    }`}
                                >
                                    {viewAuction
                                        .filter(
                                            (data) =>
                                                data.market_status == 'open' &&
                                                data.remainingDay >= 0,
                                        )
                                        .map((data, index) =>
                                            auctionView === 'Grid' ? (
                                                <GridAuction
                                                    key={index}
                                                    data={data}
                                                    details={`${
                                                        data.con_check
                                                            ? 'liveauctionview'
                                                            : 'search/product-buyer-auction'
                                                    }/${data.id}`}
                                                    timerTheme="multiseller"
                                                    theme="multiseller"
                                                    lotCount={data.lotCount ? data.lotCount : 0}
                                                />
                                            ) : (
                                                <MultisellerListCard
                                                    key={index}
                                                    data={data}
                                                    allsearchitems={viewAuction}
                                                    lotCount={data.lotCount ? data.lotCount : 0}
                                                    details={`${
                                                        data.con_check
                                                            ? 'liveauctionview'
                                                            : 'search/product-buyer-auction'
                                                    }/${data.id}`}
                                                />
                                            ),
                                        )}
                                </div>
                            </div>
                        )}
                    </>
                </>
            </div>
        </Layout>
    )
}

export default Auctions
