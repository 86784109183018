/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory } from 'react-router-dom'
import {
    Button,
    Popover,
    ListItem,
    Divider,
    SwipeableDrawer,
    MenuItem,
    Menu,
} from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import { useMediaQuery } from 'react-responsive'
import { Logo } from '../../../utils/index'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import { Badge } from '@material-ui/core'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'
const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const { user, isAuthenticated, logout, userCount } = useContext(AuthContext)
    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
    })

    const [listingAnchor, setListingAnchor] = useState(null)
    const listingOpen = Boolean(listingAnchor)

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const handleListingClick = (event) => {
        setListingAnchor(event.currentTarget)
    }

    const handleListingClose = () => {
        setListingAnchor(null)
    }

    const logoutUser = () => {
        logout()
        history.push('/login')
        window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const [langanchorEl, setlanganchorEl] = React.useState(null)

    const handleLangClick = (event) => {
        setlanganchorEl(event.currentTarget)
    }

    const handleLangClose = () => {
        setlanganchorEl(null)
    }

    return (
        <>
            {isDesktop ? (
                /* DESKTOP NAVIGATION */
                <header className="mainHeader noPrint">
                    <div className="customContainer d-flex justify-content-between align-items-center h-100">
                        <div className="headLt d-flex justify-content-start align-items-center">
                            <Logo className="headerLogo" />
                        </div>
                        <div className="headCtr">
                            <HeaderSearch />
                            <div className="customContainer">
                                <div className="headBottomNav">
                                    <ul className="d-flex justify-content-start align-items-center">
                                        <li>
                                            <a href="https://asm-360.com" rel="noreferrer">
                                                Home
                                            </a>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" exact to="/">
                                                Auctions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" exact to="/gallery">
                                                Marketplace
                                            </NavLink>
                                        </li>
                                        <li>
                                            <a href="https://asm-360.com/contact-us/">Contact </a>
                                        </li>
                                        <li>
                                            <a href="https://asm-360.com/">About</a>
                                        </li>

                                        <li>
                                            <a href="https://asm-360.com/solutions/">Solutions</a>
                                        </li>

                                        <li>
                                            <a href="https://asm-360.com/semiconductor-services/">
                                                Semiconductor Services
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://asm-360.com/general-terms/">
                                                General Terms
                                            </a>
                                        </li>

                                        <Headernavmenu type={'desk'} />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="headRt d-flex justify-content-start align-items-center">
                            <ul className="d-flex justify-content-start align-items-center">
                                {isAuthenticated ? (
                                    <>
                                        <li>
                                            <CartIcon />
                                        </li>
                                        <li>
                                            <Button
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={handleMenu}
                                            >
                                                My Account
                                                <Badge
                                                    badgeContent={
                                                        userCount?.itemunPaidCount
                                                            ? userCount.itemunPaidCount
                                                            : 0
                                                    }
                                                    color="primary"
                                                >
                                                    <span className="material-icons">
                                                        account_circle
                                                    </span>
                                                </Badge>
                                                {/* <Badge
                                                    badgeContent={
                                                        user.unpaid_invoice_count
                                                            ? user.unpaid_invoice_count
                                                            : 0
                                                    }
                                                    color="primary"
                                                >
                                                    <ShoppingCartOutlinedIcon />
                                                </Badge>*/}
                                            </Button>

                                            <Popover
                                                id={'simple-menu'}
                                                open={Boolean(anchormenu)}
                                                anchorEl={anchormenu}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <ul className="headerDropMenu">
                                                    <ListItem
                                                        button
                                                        onClick={() =>
                                                            handleClose(
                                                                history,
                                                                `${
                                                                    user && user.role == 1
                                                                        ? 'sellerDashboard'
                                                                        : 'dashboard/mybids'
                                                                }`,
                                                            )
                                                        }
                                                    >
                                                        Dashboard
                                                    </ListItem>
                                                    {/* <ListItem
                                                    button
                                                    onClick={() => handleClose(history, 'cart')}
                                                >
                                                    Cart
                                                </ListItem> */}

                                                    <ListItem
                                                        button
                                                        onClick={() =>
                                                            handleClose(
                                                                history,
                                                                'dashboard/profile',
                                                            )
                                                        }
                                                    >
                                                        Profile
                                                    </ListItem>
                                                    <ListItem button onClick={logoutUser}>
                                                        Logout
                                                    </ListItem>
                                                </ul>
                                            </Popover>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className="headerAuthBtn">
                                            <NavLink to="/login" className="ecyp-login">
                                                Login
                                            </NavLink>
                                            {/* <small className="px-1">/</small> */}
                                            <NavLink to="/sign-up" className="ecyp-signup">
                                                Sign up
                                            </NavLink>
                                        </li>
                                        {/* <li className="langAuthSelect">
                                            <Button
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={handleLangClick}
                                            >
                                                En
                                                <img
                                                    src="/assets/images/united-states-flag.png"
                                                    alt="English"
                                                />
                                            </Button>
                                            <Menu
                                                id="simple-menu"
                                                langanchorEl={langanchorEl}
                                                keepMounted
                                                open={Boolean(langanchorEl)}
                                                onClose={handleLangClose}
                                            >
                                                <MenuItem onClick={handleLangClose}>
                                                    Profile
                                                </MenuItem>
                                                <MenuItem onClick={handleLangClose}>
                                                    My account
                                                </MenuItem>
                                                <MenuItem onClick={handleLangClose}>
                                                    Logout
                                                </MenuItem>
                                            </Menu>
                                        </li> */}
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </header>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader mobHeader customContainer noPrint d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Logo className="headerLogo" />
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('top', true)}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>

                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={() => handleRedirectInternal(history, 'cart')}
                                    >
                                        <span className="material-icons">shopping_cart</span>
                                    </Button>
                                </li>

                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <HeaderSearch />
                        </SwipeableDrawer>
                    </React.Fragment>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    <ListItem button>
                                        <a href="https://asm-360.com" rel="noreferrer">
                                            <span class="material-icons">home</span>
                                            Home
                                        </a>
                                    </ListItem>
                                    <ListItem button>
                                        <a href="https://asm-360.com/solutions/">Solutions</a>
                                    </ListItem>
                                    <ListItem button>
                                        <a href="https://asm-360.com/semiconductor-services/">
                                            Semiconductor Services
                                        </a>
                                    </ListItem>

                                    <ListItem button>
                                        <a href="https://asm-360.com/">
                                            {' '}
                                            <span className="material-icons">groups</span>About
                                        </a>
                                    </ListItem>
                                    <ListItem button>
                                        <a href="https://asm-360.com/contact-us/">
                                            <span class="material-icons-outlined">
                                                contact_support
                                            </span>
                                            Contact{' '}
                                        </a>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/">
                                            Auctions
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/gallery">
                                            <span class="material-icons">local_mall</span>{' '}
                                            Marketplace
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <a href="https://asm-360.com/general-terms/">
                                            General Terms
                                        </a>
                                    </ListItem>

                                    {isAuthenticated && (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mybids"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    My Bids
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/profile"
                                                >
                                                    <span className="material-icons">person</span>
                                                    Profile
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/favorites"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    Favorites
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    Saved Cards
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    {/* <ListItem button>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            <span className="material-icons">groups</span>
                                            About Us
                                        </NavLink>
                                    </ListItem> */}
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/faq">
                                            <span className="material-icons">quiz</span>
                                            FAQs
                                        </NavLink>
                                    </ListItem>

                                    <Headernavmenu type={'mobile'} />

                                    {/* <ListItem button>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            <span className="material-icons">description</span>
                                            Terms & Conditions
                                        </NavLink>
                                    </ListItem> */}

                                    {!isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/login">
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/sign-up">
                                                    <span className="material-icons">
                                                        how_to_reg
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <ListItem button onClick={logoutUser}>
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            Logout
                                        </ListItem>
                                    )}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
        </>
    )
}

export default React.memo(Header)
