/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Link, useHistory } from 'react-router-dom'
import {
    currencyFormat,
    dateFormatFront,
    handleRedirectInternal,
} from '../../../Product/common/components'
import { Redirect } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
import Timer from '../../../Product/common/timer'
import { Button } from '@material-ui/core'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { getImageurl, imageError } from '../../../utils/commonFunctions'
import Loader from '../../../assets/loader'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import buyerContext from '../../../Product/context/buyer/buyerContext'
import alertContext from '../../../Product/context/alert/alertContext'
const Invoice = (props) => {
    const productContext = useContext(ProductContext)
    const {
        seller_allproducts,
        getAllSellerProducts,
        get_seller_dashboard,
        seller_dashboard_data,
    } = productContext

    const authContext = useContext(AuthContext)
    const { setAlert } = useContext(alertContext)
    const { isAuthenticated, isAdmin, user } = authContext
    const {
        getAllMakeRequest,
        list_make_request,
        updatemakeofferreq,
        responseStatus: buyerStatus,
        clearResponse: buyerResponse,
    } = useContext(buyerContext)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [data, setData] = useState([])
    const [userData, setUserData] = useState({})
    const [sellerData, setSellerData] = useState({})
    const [loading, setLoading] = useState(true)
    const [totalItems, setTotalItems] = useState(0)
    const history = useHistory()
    const [toInvoice, settoInvoice] = useState(false)
    const [toInvoiceID, settoInvoiceID] = useState({
        inv_id: 0,
        user_id: 0,
    })

    const [search, setSearch] = useState({
        status: 'sold',
        limit: 12,
        page: 1,
        paid: props.page === 'paid' ? 1 : 0,
    })
    useEffect(() => {
        setLoading(false)
        if (list_make_request?.data?.length > 0) {
            setData(list_make_request.data)
            setTotalItems(list_make_request.totalcount)
        } else {
            setData([])
            setTotalItems(0)
        }
    }, [list_make_request])
    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
        var data_send = {
            seller_id: user.id,
            status: props.page,
            limit: 12,
            page: value,
        }
        getAllMakeRequest(data_send)
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    useEffect(() => {
        if (isAuthenticated) {
            setLoading(true)
            var data_send = {
                seller_id: user.id,
                status: props.page,
                limit: 12,
                page: 1,
            }
            getAllMakeRequest(data_send)
        }
    }, [isAuthenticated])

    const accptoffer = (item, status) => {
        updatemakeofferreq({
            status: status,
            id: item.offer_id,
            user_id: item.buy_id,
            project_id: item.id,
            buyer_id: item.buy_id,
            shipping_amount: parseFloat(item.shippingcost ? item.shippingcost : 0),
            qty_sold: item.qty,
            paid: 0,
            local_pickup: item.localpickup,
            amount: parseFloat(item.proposed_amount),
            limit: 10,
            page: 1,
        })
    }

    useEffect(() => {
        if ((buyerStatus != '' || buyerStatus != null) && user) {
            var data_send = {
                seller_id: user?.id,
                status: props.page,
                limit: 12,
                page: 1,
            }
            getAllMakeRequest(data_send)
            setAlert(buyerStatus, 'success')
        }
        buyerResponse()
    }, [buyerStatus, user])

    return (
        <div>
            {toInvoice ? (
                <Redirect to={`invoice/${toInvoiceID.inv_id}/${toInvoiceID.user_id}`} />
            ) : null}
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : data.length ? (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="numCell">#</th>
                                    <th>Image</th>
                                    <th>Product Name</th>
                                    <th>Proposed Amount</th>
                                    <th>Buyer Name</th>
                                    {props.page != 'Rejected' ? <th>Action</th> : ''}
                                </tr>
                            </thead>
                            {data.map((item, index) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <img
                                                        className="pro-img"
                                                        src={getImageurl(
                                                            item.store_id.toString(),
                                                            item.avatar,
                                                        )}
                                                        alt={item.title}
                                                        width="65"
                                                        height="65"
                                                        onError={(e) =>
                                                            (e.target.src = imageError())
                                                        }
                                                    />
                                                </td>
                                                <td>{item.title}</td>
                                                {/*   {props.page !== 'draft' ? (
                                                    <td>
                                                        {item.market_status === 'open' ? (
                                                            <>
                                                                <Timer
                                                                    date_added={item.date_added}
                                                                    date_closed={item.date_closed}
                                                                    withText={1}
                                                                ></Timer>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {'Date Closed'} :
                                                                {dateFormatFront(item.date_closed)}
                                                            </>
                                                        )}
                                                    </td>
                                                ) : null} */}
                                                <td>
                                                    <>
                                                        <div>
                                                            <b>
                                                                {currencyFormat(
                                                                    item.proposed_amount,
                                                                )}
                                                            </b>
                                                            {item.market_status === 'sold'
                                                                ? item.paid === 1
                                                                    ? ' (Paid)'
                                                                    : ' (Unpaid)'
                                                                : null}
                                                        </div>
                                                    </>
                                                </td>
                                                <td>{item.first_name + ' ' + item.last_name}</td>
                                                <td>
                                                    {item.market_status === 'sold' &&
                                                    props.page != 'Rejected' ? (
                                                        <>
                                                            <PrimaryButton
                                                                btnSize="minMaxContent"
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        `invoice/auction?id=${item.common_invoice}&bid=${item.buy_id}`,
                                                                    )
                                                                }
                                                            >
                                                                View Invoice
                                                            </PrimaryButton>
                                                        </>
                                                    ) : props.page != 'Rejected' ? (
                                                        <>
                                                            <PrimaryButton
                                                                btnSize="minMaxContent"
                                                                onClick={(e) =>
                                                                    accptoffer(item, 'Accepted')
                                                                }
                                                            >
                                                                Accept
                                                            </PrimaryButton>
                                                            <br></br>
                                                            <PrimaryButton
                                                                btnSize="minMaxContent"
                                                                onClick={(e) =>
                                                                    accptoffer(item, 'Rejected')
                                                                }
                                                            >
                                                                Reject
                                                            </PrimaryButton>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </>
                                )
                            })}
                        </table>
                    </div>
                    <div className="d-flex mt-3">
                        <div className="ml-auto mt-2">
                            {'Showing'} {search.limit * (search.page - 1) + 1} -{' '}
                            {search.limit * search.page > totalItems
                                ? totalItems
                                : search.limit * search.page}{' '}
                            of {totalItems.length}
                        </div>
                        <Pagination
                            count={Math.ceil(totalItems.length / search.limit)}
                            page={search.page}
                            onChange={onHandlePage}
                        />
                    </div>
                    <MultisellerSlider
                        selectedLot={selectedProduct}
                        handleClose={handleClose}
                        type={props.type ? props.type : ''}
                    />
                </>
            ) : (
                <>
                    <NoRecordsFound />
                </>
            )}
        </div>
    )
}

export default Invoice
