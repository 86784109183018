import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_LOCATIONS,
    GET_LOCATION,
    GET_READER,
    GET_ALL_READERS,
    PAYMENT_STATUS,
} from './posTypes'

export default (state, action) => {
    switch (action.type) {
        case GET_ALL_LOCATIONS:
            return {
                ...state,
                all_locations: action.payload,
            }
        case GET_LOCATION:
            return {
                ...state,
                location: action.payload,
            }
        case GET_ALL_READERS:
            return {
                ...state,
                all_readers: action.payload,
            }
        case GET_READER:
            return {
                ...state,
                reader: action.payload,
            }
        case PAYMENT_STATUS:
            return {
                ...state,
                payment_status: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: null,
            }
        default:
            return state
    }
}
