import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import { useHistory, NavLink, Link } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import { useFormik } from 'formik'
import UserContext from '../../../Product/context/user/userContext'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Slider from 'react-slick'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react/swiper-react'

import 'swiper/swiper-bundle.css'

import { EffectCoverflow, Pagination, Navigation } from 'swiper'

const Home = (props) => {
    const productContext = useContext(ProductContext)
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)

    const {
        search_allauctionproducts,
        search_allproducts,
        search_allauctions,
        getLotList,
        getAllSearchAuctions,
        getAllSearchProducts,
        setSearchValue,
    } = productContext
    const { isAuthenticated, user, loadUser } = authContext

    const history = useHistory()
    const [auctionView, setAuctionView] = useState('Grid')
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [subLotData, setSubLotData] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: 2,
        lot_images: 1,
    })

    const [auctionUpcomingSearch, setAuctionUpcomingSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: 1,
        lot_images: 1,
    })

    const [productSearch, setProductSearch] = useState({
        sh_limit: 12,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })

    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        orderby: '2',
        lotof: '',
        limit: 50,
        market_status: 'open',
    })

    const [closedSearch] = useState({
        page: 1,
        sh_limit: '50',
        orderby: '1',
        searchbar: '',
        type: 'closed',
        lot_images: 1,
    })

    useEffect(() => {
        getAllSearchProducts(productSearch, 'galleryPage')
    }, [productSearch, isAuthenticated])

    useEffect(() => {
        getAllSearchAuctions(auctionUpcomingSearch, 'upcomingHome')
    }, [auctionUpcomingSearch])

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'activeHome')
    }, [auctionSearch])

    useEffect(() => {
        getAllSearchAuctions(closedSearch, 'closedHome')
    }, [closedSearch])

    useEffect(() => {
        setLoading(false)

        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    useEffect(() => {
        setViewAuction([])
        return () => {
            setViewAuction([])
        }
    }, [])

    useEffect(() => {
        if (viewAuction.length) {
            setSubLotSearch({ ...subLotSearch, lotof: viewAuction[0].id })
        }
    }, [viewAuction])

    useEffect(() => {
        if (subLotSearch.lotof) {
            getLotList(subLotSearch, 'homePageAuction')
        }
    }, [subLotSearch, isAuthenticated])

    //console.log('search_allauctionproducts', search_allauctionproducts)

    useEffect(() => {
        if (search_allauctionproducts.from === 'homePageAuction') {
            setSubLotData(search_allauctionproducts.results)
        }
    }, [search_allauctionproducts])

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const formik = useFormik({
        initialValues: {
            sh_limit: 10,
            page: 1,
            orderby: '6',
            category: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            from: 'auction_only',
            closed: 0,
        },
    })

    useEffect(() => {
        loadUser()
    }, [])

    useEffect(() => {
        loadUser()
    }, [])

    // useEffect(() => {
    //     if (user) {
    //         contactFormik.values.name = user?.first_name + ' ' + user?.last_name || ''
    //         contactFormik.values.phone_number = user?.phone_number || ''
    //         contactFormik.values.from_email = user?.email || ''
    //         contactFormik.values.subject = 'General Enquiry'
    //     }
    // }, [user])

    useEffect(() => {
        setLoading(true)
        getAllSearchProducts(formik.values, 'searchPage')
    }, [formik.values, isAuthenticated])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
    }, [window.location.search])

    const [state, setState] = React.useState({
        bottom: false,
    })
    const settings = {
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        draggable: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }
    return (
        <Layout props={props}>
            <div className="home">
                <div className="siteBanner">
                    <img
                        src={global?.storeDetails?.bannerValue}
                        alt={`${global?.storeDetails?.name} banner`}
                    />
                    <div className="bannerContent">
                        <h2>{global?.storeDetails?.description}</h2>
                    </div>
                </div>
                <div className="hotAuctions">
                    <div className="container">
                        <h2 className="auctionTitle">Hot Auctions</h2>
                        <h4 className="auctionSubTitle">Trending this week</h4>
                        <div className="lotResults">
                            {loading ? (
                                <div className="row"></div>
                            ) : viewAuction.length === 0 ? (
                                <NoRecordsFound />
                            ) : (
                                <>
                                    <div>
                                        <Swiper
                                            navigation={true}
                                            spaceBetween={10}
                                            grabCursor={true}
                                            slidesPerView={3}
                                            breakpoints={{
                                                '@0.00': {
                                                    slidesPerView: 1,
                                                },
                                                '@0.75': {
                                                    slidesPerView: 2,
                                                },
                                                '@1.00': {
                                                    slidesPerView: 2,
                                                },
                                                '@1.50': {
                                                    slidesPerView: 3,
                                                },
                                            }}
                                            pagination={false}
                                            modules={[EffectCoverflow, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            {viewAuction.map((data, index) => {
                                                return (
                                                    <SwiperSlide>
                                                        <div>
                                                            <GridAuction
                                                                cardTheme="Automobile"
                                                                timerTheme="HeavyEquipment"
                                                                data={data}
                                                                details={`search/product-buyer-auction/${data.id}?${data.description}`}
                                                                key={index}
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })}
                                        </Swiper>
                                        {/* <Slider {...settings}>
                                            {viewAuction.map((data, index) => {
                                                return (
                                                    <div>
                                                        <GridProduct
                                                            data={data}
                                                            key={index}
                                                            selectedProduct={selectedProduct}
                                                            link={`/gallery/product-view/${data.id}`}
                                                            handleClick={handleClick}
                                                            timerTheme="HeavyEquipment"
                                                            sliderTheme="multiseller"
                                                            cardTheme="Automobile"
                                                            bidBtnLabel="Place Bid"
                                                            cardTimer={true}
                                                            placeBid={true}
                                                            noTimer={true}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </Slider> */}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="activeAuctions">
                    <div className="container">
                        <h2 className="auctionTitle">Active Auctions</h2>
                        <h4 className="auctionSubTitle">Browse active auctions happening around</h4>
                        <div className="lotResults">
                            {loading ? (
                                <div className="row"></div>
                            ) : viewAuction.length === 0 ? (
                                <NoRecordsFound />
                            ) : (
                                <>
                                    {viewAuction.map((data, index) => (
                                        <GridAuction
                                            cardTheme="Automobile"
                                            timerTheme="HeavyEquipment"
                                            data={data}
                                            details={`search/product-buyer-auction/${data.id}?${data.description}`}
                                            key={index}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <MultisellerSlider
                    selectedLot={selectedProduct}
                    handleClose={handleClose}
                    type={props.type ? props.type : ''}
                />
            </div>
        </Layout>
    )
}

export default Home
