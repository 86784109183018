import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import moment from 'moment'
import { socket } from '../../Product/common/socket'

const RetractBid = (props) => {
    const [timeExpiry, setTimeExpiry] = useState(true)

    let expserverTime = new Date()

    useEffect(() => {
        if (socket) {
            socket.on('sliservertime', (data) => {
                if (moment(data.dTime).isValid()) {
                    expserverTime = data.dTime
                    let newTime =
                        moment(new Date(props.item.date_closed).getTime()).diff(
                            new Date().getTime(),
                        ) /
                            (1000 * 60 * 60) >
                        2

                    setTimeExpiry(newTime)
                }
            })
        }
    }, [socket])
    return (
        <div>
            {props.enableRetractBid &&
                props.user?.id === props.item.highest_bidder_user_id &&
                timeExpiry && (
                    <SecondaryButton label="Retract Bid" btnSize="mt-2" onClick={props.click} />
                )}
        </div>
    )
}

export default RetractBid
