import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import DashboardHeader from '../DashboardHeader'
import ProfileForm from '../../../Component/ProfileForm'
import Layout from '../Layout'

const Profile = (props) => {
    return (
        <Layout props={props}>
            <DashboardHeader />
            <Container className="dashboard-wrapper">
                <Grid container spacing={3}>
                    <ProfileForm buyerOnly={1} />
                </Grid>
            </Container>
        </Layout>
    )
}

export default Profile
