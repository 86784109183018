import { CART_DATA } from '../type'

const CartReducer = (state = {}, action) => {
    switch (action.type) {
        case CART_DATA: {
            return Object.assign({}, state, {
                getCartData: action.payload,
            })
        }
        default:
            return state
    }
}

export default CartReducer
