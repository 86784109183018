import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import MyBidsPanel from '../../../utils/CommonFunctionality/MyBidsPanel'

const MyBids = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="MY BIDS">
                <MyBidsPanel
                    storeTheme="Liquidation_two"
                    sliderTheme="multiseller"
                    noTimer={true}
                    checkout_but_pos={'top'}
                />
            </DashboardLayout>
        </Layout>
    )
}

export default MyBids
