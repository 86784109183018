import React from 'react'
import Layout from '../Layout'

export default function privacy_policy(props) {
    return (
        <Layout props={props}>
            <div className="px-5 py-4 stctPgWrpr">
                {/* <h2>FAQs</h2> */}
                <span
                    dangerouslySetInnerHTML={{
                        __html: global.storeDetails.privacy_policy,
                    }}
                ></span>
            </div>
        </Layout>
    )
}
