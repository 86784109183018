import React, { useEffect, useState, useContext, useCallback } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useHistory, NavLink } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import SearchProductList from '../../../Component/ProductCard/product'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import moment from 'moment'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import { useFormik } from 'formik'
import { Box, Button, ListItem, Tab, Tabs, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Loaders from '../../../Product/components/molecules/Loaders'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import { useMediaQuery } from 'react-responsive'
import jsonp from 'jsonp'
import AlertContext from '../../../Product/context/alert/alertContext'
import queryString from 'query-string'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Slider from 'react-slick'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `home-auctions-${index}`,
        'aria-controls': `home-auctions-tabpanel-${index}`,
    }
}

const Home = (props) => {
    const { user } = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions } = productContext
    const { setAlert } = alertContext

    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [value, setValue] = useState(0)
    const [inpData, setinpData] = useState('')

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: 2,
        lot_images: 1,
        type: '',
        // timed_live: 'timed',
        is_auction: global.storeConfigration?.need_post_buynow?.value != 0 ? 0 : 1,
    })

    const isDesktop = useMediaQuery({
        query: '(min-width: 769px)',
    })

    useEffect(() => {
        if (value === 0) {
            setAuctionSearch({ ...auctionSearch, type: '', auc_type: 2 })
        }
        if (value === 1) {
            setAuctionSearch({ ...auctionSearch, type: '', auc_type: 1 })
        }
        if (value === 2) {
            setAuctionSearch({ ...auctionSearch, type: 'closed', auc_type: '' })
        }
    }, [value])

    useEffect(() => {
        setLoading(true)
        getAllSearchAuctions(auctionSearch, 'activeHome')
    }, [auctionSearch])

    useEffect(() => {
        setLoading(false)
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories.filter((main) => {
                return true
            }),
        },
    ]

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClick = (catId) => {
        handleRedirectInternal(history, `search?cat=${catId}`)
    }

    const [state, setState] = React.useState({
        bottom: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//${global.storeConfigration?.subscribe_code.value}${queryString.stringify(data)}`,
                { param: 'c' },
                (err, data) => {
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }

    const list = (anchor) => (
        <div
            className=""
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="filterPanel">
                <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                    <h4 className="fpTitle">Categories</h4>
                    {props.clearSearch && (
                        <Button onClick={() => props.clearSearch()} className="filterClear">
                            <span className="material-icons">cancel</span>
                            Clear all
                        </Button>
                    )}
                </div>
                <div className="filterAcc">
                    {filterValues[0].options.map((data, index) => (
                        <ListItem
                            button
                            className="homeCatgInner"
                            key={index}
                            onClick={() => handleClick(data.id)}
                        >
                            {data.name}
                        </ListItem>
                    ))}
                </div>
            </div>
        </div>
    )

    const settings = {
        dots: true,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }

    return (
        <Layout props={props}>
            <div className="home">
                {global.multipleBanners.length ? (
                    <div className="siteBanner multiple">
                        {
                            <Slider {...settings}>
                                {global.multipleBanners.map((data, index) => (
                                    <>
                                        <img
                                            src={
                                                process.env.REACT_APP_AUCTION_IMAGE_URL + '/' + data
                                            }
                                        />
                                        <div className="bannerContent">
                                            <h2>{global?.storeDetails?.description}</h2>
                                            <PrimaryButton
                                                label="Explore Now"
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `${isAuthenticated ? 'search' : 'sign-up'}`,
                                                    )
                                                }
                                            />
                                        </div>
                                    </>
                                ))}
                            </Slider>
                        }
                    </div>
                ) : (
                    <div className="siteBanner">
                        <LazyLoadImage
                            src={global?.storeDetails?.bannerValue}
                            alt={`${global?.storeDetails?.name} banner`}
                            effect="blur"
                            height="100%"
                            width="100%"
                        />
                        <div className="bannerContent">
                            <h2>{global?.storeDetails?.description}</h2>
                            <PrimaryButton
                                label="Explore Now"
                                onClick={() =>
                                    handleRedirectInternal(
                                        history,
                                        `${isAuthenticated ? 'search' : 'sign-up'}`,
                                    )
                                }
                            />
                        </div>
                    </div>
                )}

                <div className="customContainer auctionListCnt">
                    <div className="homeCnt">
                        <div className="homeLt">
                            <h3>Categories</h3>
                            <ul className="homeCatg">
                                {filterValues[0].options.map((data, index) => (
                                    <ListItem
                                        button
                                        className="homeCatgInner"
                                        key={index}
                                        onClick={() => handleClick(data.id)}
                                    >
                                        {data.name}
                                    </ListItem>
                                ))}
                            </ul>
                        </div>

                        {/* HomePage Categories Drawer */}

                        <React.Fragment>
                            <Button
                                className="toggleRespDrawer"
                                variant="outlined"
                                onClick={toggleDrawer('bottom', true)}
                            >
                                <span className="material-icons">tune</span>
                                Filters
                            </Button>
                            <SwipeableDrawer
                                className="responsiveFilterDrawer"
                                anchor={'bottom'}
                                open={state['bottom']}
                                onClose={toggleDrawer('bottom', false)}
                                onOpen={toggleDrawer('bottom', true)}
                            >
                                {list('bottom')}
                            </SwipeableDrawer>
                        </React.Fragment>

                        {/* HomePage Categories Drawer end */}

                        <div className="homeRt">
                            <h2 className="auctionListTitle">Our Auctions</h2>
                            <Tabs value={value} onChange={handleChange} aria-label="home-auctions">
                                <Tab label="Current Auctions" {...a11yProps(0)} />
                                <Tab label="Upcoming Auctions" {...a11yProps(1)} />
                                <Tab label="Completed Auctions" {...a11yProps(2)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                {loading ? (
                                    <div className="homeLoader">
                                        {isDesktop ? (
                                            <Loaders
                                                name="product_list_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        ) : (
                                            <Loaders
                                                name="product_grid_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        )}
                                    </div>
                                ) : viewAuction.filter(
                                      (data) =>
                                          data.market_status == 'open' && data.remainingDay >= 0,
                                  ).length === 0 ? (
                                    <NoRecordsFound />
                                ) : (
                                    <div className={`homeAuctions ${!isDesktop && 'mobileView'}`}>
                                        {viewAuction
                                            .filter(
                                                (data) =>
                                                    data.market_status == 'open' &&
                                                    data.remainingDay >= 0,
                                            )
                                            .map((data, index) =>
                                                isDesktop ? (
                                                    <MultisellerListCard
                                                        key={index}
                                                        data={data}
                                                        allsearchitems={viewAuction}
                                                        details={`${
                                                            data.con_check
                                                                ? 'liveauctionview'
                                                                : 'search/product-buyer-auction'
                                                        }/${data.id}`}
                                                        // lotCount={data.lotCount ? data.lotCount : 0}
                                                    />
                                                ) : (
                                                    <GridAuction
                                                        key={index}
                                                        data={data}
                                                        details={`${
                                                            data.con_check
                                                                ? 'liveauctionview'
                                                                : 'search/product-buyer-auction'
                                                        }/${data.id}`}
                                                        timerTheme="multiseller"
                                                        // lotCount={data.lotCount ? data.lotCount : 0}
                                                    />
                                                ),
                                            )}
                                    </div>
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {loading ? (
                                    <div className="homeLoader">
                                        {isDesktop ? (
                                            <Loaders
                                                name="product_list_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        ) : (
                                            <Loaders
                                                name="product_grid_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        )}
                                    </div>
                                ) : viewAuction.length === 0 ? (
                                    <NoRecordsFound />
                                ) : (
                                    viewAuction.map((data, index) =>
                                        isDesktop ? (
                                            <MultisellerListCard
                                                key={index}
                                                data={data}
                                                allsearchitems={viewAuction}
                                                details={`${
                                                    data.con_check
                                                        ? 'liveauctionview'
                                                        : 'search/product-buyer-auction'
                                                }/${data.id}`}
                                                // lotCount={data.lotCount ? data.lotCount : 0}
                                            />
                                        ) : (
                                            <GridAuction
                                                key={index}
                                                data={data}
                                                details={`${
                                                    data.con_check
                                                        ? 'liveauctionview'
                                                        : 'search/product-buyer-auction'
                                                }/${data.id}`}
                                                timerTheme="multiseller"
                                                // lotCount={data.lotCount ? data.lotCount : 0}
                                            />
                                        ),
                                    )
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                {loading ? (
                                    <div className="homeLoader">
                                        {isDesktop ? (
                                            <Loaders
                                                name="product_list_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        ) : (
                                            <Loaders
                                                name="product_grid_view"
                                                isLoading={loading}
                                                loop={1}
                                            />
                                        )}
                                    </div>
                                ) : viewAuction.length === 0 ? (
                                    <NoRecordsFound />
                                ) : (
                                    viewAuction.map((data, index) =>
                                        isDesktop ? (
                                            <MultisellerListCard
                                                key={index}
                                                data={data}
                                                allsearchitems={viewAuction}
                                                details={`${
                                                    data.con_check
                                                        ? 'liveauctionview'
                                                        : 'search/product-buyer-auction'
                                                }/${data.id}`}
                                                // lotCount={data.lotCount ? data.lotCount : 0}
                                            />
                                        ) : (
                                            <GridAuction
                                                key={index}
                                                data={data}
                                                details={`${
                                                    data.con_check
                                                        ? 'liveauctionview'
                                                        : 'search/product-buyer-auction'
                                                }/${data.id}`}
                                                timerTheme="multiseller"
                                                // lotCount={data.lotCount ? data.lotCount : 0}
                                            />
                                        ),
                                    )
                                )}
                            </TabPanel>
                            <div
                                className="viewAllBtn"
                                onClick={() =>
                                    handleRedirectInternal(
                                        history,
                                        `auctions?t=${value == 0 ? 2 : value == 1 ? 1 : 3}`,
                                    )
                                }
                            >
                                <hr />
                                <Button>View All</Button>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>

                {/* {global?.storeConfigration?.subscribe?.value && (
                    <div className="homeContact">
                        <h2>Get the latest deals</h2>
                        <p>Subscribe us to get latest updates</p>
                        <div className="subsBox">
                            <input
                                type="text"
                                name="q"
                                value={inpData}
                                placeholder="Enter email address"
                                onChange={(e) => {
                                    setinpData(e.target.value)
                                }}
                            />
                            <Button>
                                <span className="material-icons" onClick={subscribe}>
                                    send
                                </span>
                            </Button>
                        </div>
                    </div>
                )} */}
            </div>
        </Layout>
    )
}

export default Home
