import React from 'react'
import Bio from '../../../utils/CommonFunctionality/Bio/Bio'
import Layout from '../Layout'
const ArtistBio = () => {
    return (
        <Layout>
            <Bio />
        </Layout>
    )
}

export default ArtistBio
