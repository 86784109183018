import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import DashboardLayout from '../DashboardLayout'
import ProfileForm from '../../../Component/ProfileForm'
import Layout from '../Layout'
import { useTranslation } from 'react-i18next'

const Profile = (props) => {
    const { t } = useTranslation()
    return (
        <Layout props={props}>
            <DashboardLayout title={t('my_account')}>
                <Container className="dashboard-wrapper">
                    <Grid container spacing={3}>
                        <ProfileForm phone_country_code_must={1} mobile={0} />
                    </Grid>
                </Container>
            </DashboardLayout>
        </Layout>
    )
}

export default Profile
