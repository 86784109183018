import React, { useState } from 'react'
import { useContext } from 'react'
import Loader from '../../../../assets/loader'
import PosContext from '../../../../Product/context/plugin/pos/posContext'
import AuthContext from '../../../../Product/context/auth/authContext'
import AlertContext from '../../../../Product/context/alert/alertContext'
import { useEffect } from 'react'
import { Fragment } from 'react'
import AddUpdate from './AddUpdate'
import NoRecordsFound from '../../../../Product/components/atoms/NoRecordsFound'
import Delete from './Delete'
const PosReaders = () => {
    const { user } = useContext(AuthContext)
    const { allReaders, all_readers, responseStatus } = useContext(PosContext)
    const { setAlert } = useContext(AlertContext)

    const [loading, setLoading] = useState(true)
    const [readers, setReaders] = useState([])

    useEffect(() => {
        if (user) {
            setLoading(true)
            allReaders({ user_id: user?.id, site_id: global?.storeDetails?.site_id })
        }
    }, [user])

    useEffect(() => {
        setReaders(all_readers)
        setLoading(false)
    }, [all_readers])

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'createReader' ||
                responseStatus.from === 'deleteReader' ||
                responseStatus.from === 'updateReader'
            ) {
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, 'success')
                    setLoading(true)
                    allReaders({ user_id: user?.id, site_id: global?.storeDetails?.site_id })
                }
            }
        }
    }, [responseStatus])
    return (
        <div>
            <div className="float-right mb-3 addBtn">
                <AddUpdate label="Add Reader" />
            </div>
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : readers.length ? (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Label</th>
                                    <th>Id</th>
                                    <th className="actnBtnCol">Action</th>
                                </tr>
                            </thead>
                            {readers.map((item, index) => (
                                <Fragment key={index}>
                                    <tbody>
                                        <tr>
                                            <td>{item.label}</td>
                                            <td>{item.id}</td>
                                            <td className="tableAct">
                                                <Delete
                                                    id={item.id}
                                                    label={
                                                        <span className="material-icons">
                                                            delete
                                                        </span>
                                                    }
                                                />
                                                <AddUpdate
                                                    id={item.id}
                                                    type="update"
                                                    label={
                                                        <span className="material-icons">
                                                            update
                                                        </span>
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Fragment>
                            ))}
                        </table>
                    </div>
                </>
            ) : (
                <>
                    <NoRecordsFound />
                </>
            )}
        </div>
    )
}

export default PosReaders
