import React, { useReducer } from 'react'
import ProductContext from './productContext'
import ProductReducer from './productReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'

import {
    GET_ALL_BID_HISTORY,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_SAVED_SEARCHES,
    GET_SINGLE_INVOICE_DETAIL,
    LOT_PRODUCT_DTLS,
    ALL_LOTS,
    DASHBOARD_MYBIDS,
    GET_ALL_SEARCH,
    GET_ALL_SELLERS,
    AUCTION_PERCENT,
    GET_ALL_LOT_AUCTIONS,
} from './productTypes'

const ProductState = (props) => {
    const initialState = {
        dashboard: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        search_allbidhistory: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        saved_searches: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        search_allinvoiceproducts: {
            record: [],
            shipping_address: 0,
        },
        responseStatus: null,
        lot_details: {},
        allLots: {},
        dashboardMyBids: {},
        getAllSeller: [],
        auctionPercent: {},
        search_allauctionproducts: {
            results: [],
            total_results: 0,
            isRegistered: 0,
            isRegistered_list: [],
            current_projectid: 0,
            auctionList: {},
        },
    }

    const [state, dispatch] = useReducer(ProductReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addSavedSearch = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addSavedSearch', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'saved_search_msg')
        } catch (err) {
            resp.commonErrorResponse('saved_search_msg')
        }
    }

    const getSavedSearch = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSavedSearch', formData, '', 'user'),
            ])
            dispatch({
                type: GET_SAVED_SEARCHES,
                payload: {
                    records: res.data.data.responseData.records.length
                        ? res.data.data.responseData.records
                        : [],
                    totalRecords: res.data.data.responseData.totalRecords,
                    setDisp: res.data.data.responseData.setDisp,
                },
            })
        } catch (err) {
            resp.commonErrorResponse('saved_search')
        }
    }

    const removeSavedSearch = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeSavedSearch', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'saved_search_msg')
        } catch (err) {
            resp.commonErrorResponse('saved_search_msg')
        }
    }

    const getLotList = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'lotSearch', formData, '', 'api')])
            if (res.data.status === 'success') {
                if (res.data.response.results) {
                    dispatch({
                        type: GET_ALL_LOT_AUCTIONS,
                        payload: {
                            results: res.data.response.results,
                            total_results: res.data.response.total_results,
                            isRegistered: res.data.response.isRegistered,
                            isRegistered_list: res.data.response.isRegistered_list,
                            current_projectid: res.data.response.current_projectid,
                            auctionList: res.data.response.auctionList,
                        },
                    })
                } else {
                    dispatch({
                        type: GET_ALL_LOT_AUCTIONS,
                        payload: {
                            results: [],
                            total_results: 0,
                            isRegistered: 0,
                            isRegistered_list: [],
                            current_projectid: 0,
                            auctionList: {},
                        },
                    })
                }
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getAllSearchProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'searchAPI', formData, '', 'api')])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        records: res.data.response.results.length ? res.data.response.results : [],
                        totalRecords: res.data.response.total_results,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getIndividualProductLotDetails = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'lotDetails', formData, '', 'api')])

            if (res.data.status === 'success') {
                dispatch({
                    type: LOT_PRODUCT_DTLS,
                    payload: res.data.response,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getLiveAuctionPercentage = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getliveauctionpercentage', formData, '', 'api'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: AUCTION_PERCENT,
                    payload: res.data.data,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllBidHistory = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'consolebid', formData, '', 'api')])
            const from = 'liveLot'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BID_HISTORY,
                    payload: {
                        records: res.data.response.bids.length ? res.data.response.bids : [],
                        totalRecords: res.data.response.bids.length,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const searchAllLots = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'searchLotAPI', formData, '', 'api')])

            if (res.data && res.data.status === 'success') {
                dispatch({
                    type: ALL_LOTS,
                    payload: res.data.response,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getDashboardMybids = async (formData, bidtype) => {
        try {
            const [res] = await Promise.all([apiCall('post', bidtype, formData, '', 'api')])
            if (res.data && res.data.status === 'success') {
                dispatch({
                    type: DASHBOARD_MYBIDS,
                    payload: res.data.response,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addWatchlist = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'watchlist', formData, '', 'api')])
            if (res.data && res.data.status === 'success') {
                return true
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const removeWatchlist = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removewatchlist', formData, '', 'api'),
            ])
            if (res.data && res.data.status === 'success') {
                return true
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSellerList = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getsellername', formData, '', 'api')])
            if (res.data && res.data.status === 'success') {
                //console.log(res, 'this is the responce from the get seller list')
                dispatch({
                    type: GET_ALL_SELLERS,
                    payload: res.data.response.results,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllInvoiceProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'invoice', formData, '', 'api')])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_INVOICE_DETAIL,
                    payload: {
                        record: res.data.response.results.length ? res.data.response.results : [],
                        shipping_address: res.data.response.shipping_address,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <ProductContext.Provider
            value={{
                search_allbidhistory: state.search_allbidhistory,
                responseStatus: state.responseStatus,
                saved_searches: state.saved_searches,
                lot_details: state.lot_details,
                allLots: state.allLots,
                dashboardMyBids: state.dashboardMyBids,
                getAllSeller: state.getAllSeller,
                search_allinvoiceproducts: state.search_allinvoiceproducts,
                auctionPercent: state.auctionPercent,
                search_allauctionproducts: state.search_allauctionproducts,
                clearResponse,
                getAllBidHistory,
                addSavedSearch,
                getSavedSearch,
                removeSavedSearch,
                getIndividualProductLotDetails,
                searchAllLots,
                getDashboardMybids,
                addWatchlist,
                removeWatchlist,
                getSellerList,
                getAllInvoiceProducts,
                getLiveAuctionPercentage,
                getLotList,
            }}
        >
            {props.children}
        </ProductContext.Provider>
    )
}

export default ProductState
