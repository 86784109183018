import React, { useState, useEffect, useContext } from 'react'
import Popup from '../../../Product/components/organisms/Popup'
import './DonationModal.css'
import { IconButton } from '@material-ui/core'
import DonationFunction from './DonationFunction'
function DonationModal(props) {
    return (
        <Popup {...props} size="md" modalClass="donationModalUpdate" modaltitle="Bid History">
            <div className="row align-items-stretch m-0">
                <IconButton className="closeButton" onClick={props.handleClose}>
                    <span className="material-icons">clear</span>
                </IconButton>
                <DonationFunction id={props.id} handleClose={props.handleClose} />
            </div>
        </Popup>
    )
}

export default DonationModal
