import React, { useContext, useEffect } from 'react'
import Layout from '../Layout'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Standalone from '../../../Component/SlidePanel/Standalone'
import './ProductViewStandalone.css'

const ProductView = () => {
    return (
        <Layout>
            <div className="productViewStandalone customContainer">
                <Standalone storeTheme="LiquidationPallet" />
            </div>
        </Layout>
    )
}

export default ProductView
