import React from 'react'
import VCModal from '../Modal'
import './HowBid.css'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Dialog from '@material-ui/core/Dialog'
import { IconButton } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

const HowBidPopup = (props) => {
    const classes = useStyles()

    const faqs = [
        {
            question: 'Why Am I Immediately Being Beaten When I Enter My Bid?',
            answer: 'This is happening because another bidder has entered a Proxy Bid that is higher than your Hard Bid and/or Proxy Bid. You will be outbid until your Hard or Proxy Bid is higher than theirs.',
        },
        {
            question: 'Can I Increase My Bid?',
            answer: 'Yes! You can increase your Hard or Proxy bid at any time until bidding closes.',
        },
        {
            question: 'Can I Remove My Bid?',
            answer: 'No. Your bid is binding and must be confirmed prior to the bid being entered.',
        },
        {
            question: 'Do I Have To Bid In The Set Increments?',
            answer: `Yes, bids are required to increase at set increments which are determined by ${global?.storeDetails?.name}.`,
        },
        {
            question: 'How Can I Tell If I Am Winning?',
            answer: `You will be shown on the Lot Page that you are winning as well as on your ${global?.storeDetails?.name} dashboard. ${global?.storeDetails?.name} will also notify you should you be outbid.`,
        },
    ]
    return (
        <div>
            <Dialog
                className="hwBdWkrsPopup"
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="mdlTtle d-flex align-items-center justify-content-between">
                    <p className="d-flex align-items-center m-0">
                        <span className="material-icons mr-2">help</span>How Does Bidding Work With
                        a Sample Lot?
                    </p>
                    <IconButton onClick={props.handleClose}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                </div>
                <div className="hwBdWkWrpr">
                    <p className="infoTxt">
                        Let’s imagine a lot of beef is available on {global?.storeDetails?.name}.
                        Shop with an opening price of $1,000 and the bid increment is $100. This is
                        how bidding might happen:
                    </p>
                    <ul className="stpsTxt">
                        <li>
                            <span>01</span>
                            <b>Bidder #1</b> starts by entering their Proxy Bid of $1,500.
                            {' ' + global?.storeDetails?.name} system will automatically bid for
                            them to $1,100 to put them in the lead.
                        </li>

                        <li>
                            <span>02</span>
                            <b>Bidder #2</b> enters their Proxy Bid of $1,300 to try and take the
                            lead. The {global?.storeDetails?.name} system will accept Bidder #2’s
                            bid, but then automatically adjust Bidder #1’s bid by $100 to $1,400.
                        </li>

                        <li>
                            <span>03</span>
                            <b>Bidder #3</b> enters a Proxy Bid of $2,000.{' '}
                            {global?.storeDetails?.name} system will again attempt to add Bidder
                            #1’s maximum of $1,500, however it’s beaten by #3’s bid. Bidder #3 is
                            now in the lead at $1,600.
                        </li>
                    </ul>
                    <h3>FAQs</h3>
                    <hr />
                    <div className={classes.root}>
                        {faqs.map((data, index) => (
                            <Accordion key={index}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                                >
                                    <Typography className={classes.heading}>
                                        {data.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{data.answer}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default HowBidPopup
