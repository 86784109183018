export const GET_AUCTION_DATA = 'GET_AUCTION_DATA'
export const GET_CLOSED_AUCTION_DATA = 'GET_CLOSED_AUCTION_DATA'
export const GET_AUCTION_DATA_BODY = 'GET_AUCTION_DATA_BODY'
export const GET_AUCTION_DATA_COUNT = 'GET_AUCTION_DATA_COUNT'
export const GET_SEARCH_DATA = 'GET_SEARCH_DATA'
export const GET_SEARCH_DATA_COUNT = 'GET_SEARCH_DATA_COUNT'
export const GET_SEARCH_BODY = 'GET_SEARCH_BODY'
export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_LOT_DATA = 'GET_LOT_DATA'
export const GET_LOT_DATA_COUNT = 'GET_LOT_DATA_COUNT'
export const GET_LOT_BODY = 'GET_LOT_BODY'
export const CART_DATA = 'CART_DATA'
