import React, { useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Badge } from '@material-ui/core'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import CartContext from '../../../Product/context/cart/cartContext'
import './cartIcon.css'
import { ShoppingCart } from '@material-ui/icons'
const CartIcon = (props) => {
    const cartContext = useContext(CartContext)
    const { buyer_cartitems, getUserCart, responseStatus, clearResponse } = cartContext
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                    clearResponse()
                }
            }
            if (responseStatus.from === 'removeFromCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                    clearResponse()
                }
            }
        }
    }, [responseStatus])
    return (
        <>
            <NavLink className="cartIst" activeClassName="active" to="/cart">
                <Badge badgeContent={buyer_cartitems.results.length} color="primary">
                    {props.fillIcon ? <ShoppingCart /> : <ShoppingCartOutlinedIcon />}
                </Badge>
            </NavLink>
        </>
    )
}

export default CartIcon
