import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import ImageGallery from 'react-image-gallery'
import { useHistory, useParams, Link } from 'react-router-dom'
import moment from 'moment-timezone'
import Bidhistory from '../../../Component/Bidhistory'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import CartContext from '../../../Product/context/cart/cartContext'
import AlertContext from '../../../Product/context/alert/alertContext'

import FavouriteCheckbox from '../../../Product/components/atoms/FavoriteCheckbox'
import Bidding from '../../../Product/components/molecules/Bidding/BiddingItem'
import Timer from '../../../Product/common/timer'
import { messageHandlerSingle } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import { makeStyles } from '@material-ui/core/styles'

import { Button } from '@material-ui/core'
import { currencyFormat, handleRedirectInternal } from '../../../Product/common/components'
import PropTypes from 'prop-types'
import './style.css'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { allCondition, storageOptions, unitOptions } from '../../../utils/index'
import HowBidPopup from '../../../Component/HowBiddingWorks'
import Layout from '../Layout'

import Facebook from '../../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../../utils/CommonFunctionality/Share/Pinterest'
import ReactPlayer from 'react-player'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import BidStatus from '../../../Product/components/molecules/Bidding/BidStatus'
import FavoriteCheck from '../../../Product/components/atoms/FavoriteCheckbox/Fav'

export function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="tabBody">{children}</div>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

const LiveStockProductView = ({ type, selectedLot, handleClose, viewPage, storeTheme }) => {
    const buyerContext = useContext(BuyerContext)
    const cartContext = useContext(CartContext)
    const alertContext = useContext(AlertContext)

    const authContext = useContext(AuthContext)
    const { setAlert } = alertContext
    const [value, setValue] = useState(0)

    const { user, isAuthenticated } = authContext
    const { buyer_allproducts, getAllBuyerProducts, product_view, getViewProduct } = buyerContext
    const { addToCart, buyer_cartitems, getUserCart, removeFromCart, responseStatus } = cartContext
    const history = useHistory()
    const [historyData, setHistoryData] = useState({})
    const [loading, setLoading] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [productVideos, setProductVideos] = useState([])
    const [workLoc, setProductWorkLoc] = useState([])
    const [documentField1, setDocumentField1] = useState([])
    const [image, setImage] = useState([])
    const [selectedProductImage, setSelectedProductImage] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { id } = useParams()
    const matches = useMediaQuery('(max-width:700px)')
    const [howBidPopup, setHowBidPopup] = useState(false)
    useEffect(() => {
        if (selectedLot) {
            getViewProduct({ product_id: selectedLot })
        }
    }, [selectedLot])
    useEffect(() => {
        if (id) {
            getViewProduct({ product_id: id })
        }
    }, [id])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        if (product_view.results) {
            if (product_view.results.work_loc) {
                product_view.results.documents = product_view.results.work_loc.split(',')
            }
            product_view.results.video_url = product_view.results.custom_field_9.split(',')
            setSelectedProduct({
                ...product_view.biddetails,
                ...product_view.results,
                incrementamt: product_view.incrementamt,
            })
            if (product_view?.attachments.length) {
                product_view?.attachments.forEach(function (data) {
                    data['original'] = `${
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src
                    }`

                    data['thumbnail'] = `${
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL
                    }${global.storeConfigration?.image_compression?.value == 1 ? 'thumb_' : ''}${
                        data.src
                    }`
                })
                setSelectedProductImage(product_view.attachments)
            } else {
                setSelectedProductImage([])
            }
            if (product_view?.results.videos) {
                let temp_vid = []
                product_view?.results.videos.split(',').forEach(function (data) {
                    temp_vid.push(`${process.env.REACT_APP_BASE_URL + 'uploads/' + data}`)
                })
                setProductVideos(temp_vid)
            } else {
                setProductVideos([])
            }

            if (product_view?.results.document_field_1) {
                let temp_doc = []
                product_view?.results.document_field_1.split(',').forEach(function (data) {
                    temp_doc.push(`${process.env.REACT_APP_BASE_URL + 'uploads/' + data}`)
                })
                setDocumentField1(temp_doc)
            }

            if (product_view?.results.work_loc) {
                let temp_loc = []
                product_view?.results.work_loc.split(',').forEach(function (data) {
                    temp_loc.push(`${process.env.REACT_APP_BASE_URL + 'uploads/' + data}`)
                })
                setProductWorkLoc(temp_loc)
            }

            if (product_view?.results.image) {
                let temp_image = []
                product_view?.results.image.split(',').forEach(function (data) {
                    temp_image.push(`${process.env.REACT_APP_BASE_URL + 'uploads/' + data}`)
                })
                setImage(temp_image)
            }
            setLoading(false)
        }
    }, [product_view.results])

    useEffect(() => {
        if (buyer_cartitems.results) {
            if (selectedProduct.id) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: selectedProduct.id,
                })
                position === -1 ? setDisabled(false) : setDisabled(true)
            }
        }
    }, [buyer_cartitems.results])

    const handleClick = async (e) => {
        addToCart({ id: selectedProduct.id, qty: selectedProduct.qty })
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                }
            }
        }
    }, [responseStatus])

    const viewProductRef = useRef(selectedProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = selectedProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandlerSingle(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const lotInformation = [
        {
            title: (
                <>
                    <span className="material-icons">description</span>Auction Details
                </>
            ),
            description: selectedProduct?.description,
        },

        {
            title: (
                <>
                    <span className="material-icons">description</span>Documents
                </>
            ),
            description: selectedProduct?.documents?.map((val) => (
                <>
                    <a
                        href={`${process.env.REACT_APP_BASE_URL}uploads/${val}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {val}
                    </a>
                    <br />
                </>
            )),
            html: 1,
        },
    ]
    if (global.storeConfigration?.disable_contacts_field?.value != 1) {
        lotInformation.push({
            title: (
                <>
                    <span className="material-icons">contacts</span>Contacts
                </>
            ),
            description: selectedProduct?.custom_field_5,
        })
    }
    if (global.storeConfigration?.disable_tax_field?.value != 1) {
        lotInformation.push({
            title: (
                <>
                    <span className="material-icons">percent</span>Tax/Removal
                </>
            ),
            description: selectedProduct?.custom_field_4,
        })
    }
    if (global.storeConfigration?.disable_terms_field?.value != 1) {
        lotInformation.push({
            title: (
                <>
                    <span className="material-icons">privacy_tip</span>Terms & Conditions
                </>
            ),
            description: selectedProduct?.custom_field_7,
        })
    }

    return (
        <Layout>
            <div className="pvStandaloneTwo container">
                {Object.keys(selectedProduct).length !== 0 && (
                    <>
                        <div className="mpTop d-flex justify-content-between flex-wrap-reverse">
                            <div>
                                <div className="mb-3">
                                    <Button onClick={() => history.goBack()}>
                                        <span className="material-icons mr-2">arrow_back</span>
                                        Back To Horse Sale
                                    </Button>
                                </div>
                                <h3 className="plTitle">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: selectedProduct.title,
                                        }}
                                    />
                                    <span>Lot# {selectedProduct.id}</span>
                                </h3>
                            </div>
                        </div>
                        <div className="pvsCnt">
                            <ImageGallery
                                items={selectedProductImage}
                                thumbnailPosition={matches ? 'bottom' : 'left'}
                                showNav={false}
                                showBullets={false}
                                showFullscreenButton={true}
                                showPlayButton={false}
                                onErrorImageURL={`${global?.storeDetails?.logoValue}`}
                                originalClass="slidImg img-fluid"
                            />
                            <div className="pvsRight">
                                {selectedProduct.auction ? (
                                    <>
                                        {selectedProduct.market_status != 'open' ||
                                        selectedProduct.productClosed ? null : selectedProduct.extend ||
                                          selectedProduct.agent_id ? (
                                            <span className="tmm">
                                                <Timer
                                                    date_added={selectedProduct.date_added}
                                                    date_closed={selectedProduct.date_closed}
                                                    withText={1}
                                                    endText={'Ends in:'}
                                                    startText={'Starts in:'}
                                                    theme="multiseller"
                                                ></Timer>
                                            </span>
                                        ) : (
                                            <span>
                                                <Timer
                                                    date_added={selectedProduct.date_added}
                                                    date_closed={selectedProduct.date_closed}
                                                    withText={1}
                                                    endText={'Ends in' + ':'}
                                                    startText={'Starts in' + ':'}
                                                    theme="multiseller"
                                                ></Timer>
                                            </span>
                                        )}

                                        <div className="productInfo">
                                            {selectedProduct.market_status != 'open' ||
                                            selectedProduct.productClosed ? (
                                                <h5>
                                                    <span>Auction Closed</span>
                                                    {moment(selectedProduct.date_added).format(
                                                        'MMM Do YYYY, h:mm a',
                                                    )}
                                                </h5>
                                            ) : (
                                                <>
                                                    <h5>
                                                        <span>Auction Opens</span>
                                                        {moment(selectedProduct.date_added).format(
                                                            'MMM Do YYYY, h:mm a',
                                                        )}
                                                    </h5>
                                                    <h5>
                                                        <span>Auction Closes</span>
                                                        {moment(selectedProduct.date_closed).format(
                                                            'MMM Do YYYY, h:mm a',
                                                        )}
                                                    </h5>
                                                </>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                                <div className="mssBidItem">
                                    {selectedProduct.auction ? (
                                        <div className="productInfo">
                                            {selectedProduct.market_status != 'sold' ? (
                                                <>
                                                    {Number(selectedProduct.sprice) ===
                                                    selectedProduct.wprice ? (
                                                        <h5>
                                                            <span>Start Bid</span>
                                                            {currencyFormat(selectedProduct.wprice)}
                                                        </h5>
                                                    ) : (
                                                        <h5>
                                                            <span>Current Bid</span>
                                                            {currencyFormat(selectedProduct.wprice)}
                                                        </h5>
                                                    )}
                                                    <h5>
                                                        <span>Minimum Bid</span>
                                                        {currencyFormat(selectedProduct.next_bid)}
                                                    </h5>
                                                </>
                                            ) : (
                                                <h5>
                                                    <span>Hammer Price</span>
                                                    {currencyFormat(selectedProduct.next_bid)}
                                                </h5>
                                            )}
                                            {isAuthenticated ? (
                                                selectedProduct.auction ? (
                                                    type === 'buynow' ? null : (
                                                        <BidStatus
                                                            bidTopStatus={
                                                                selectedProduct.bidtopstatus
                                                            }
                                                        />
                                                    )
                                                ) : null
                                            ) : null}
                                        </div>
                                    ) : (
                                        <div className="productInfo">
                                            {selectedProduct.buynow ? (
                                                <h5>
                                                    <span>
                                                        {selectedProduct.market_status != 'sold'
                                                            ? 'Buy Now Price'
                                                            : 'Hammer Price'}
                                                    </span>
                                                    {currencyFormat(selectedProduct.bprice)}
                                                </h5>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    )}
                                </div>
                                {selectedProduct.market_status != 'open' ||
                                selectedProduct.productClosed ? (
                                    ''
                                ) : (
                                    <div className="sl-pbids slidView">
                                        {isAuthenticated ? (
                                            <>
                                                {selectedProduct.auction ? (
                                                    type === 'buynow' ? null : (
                                                        <Fragment>
                                                            {selectedProduct.user_id == user.id ? (
                                                                <p>
                                                                    You cannot bid on your own
                                                                    product!
                                                                </p>
                                                            ) : (
                                                                <>
                                                                    <p className="slidViewBtnLabel">
                                                                        <span className="material-icons">
                                                                            info
                                                                        </span>
                                                                        Hard Bid - Place your bid on
                                                                        the lot
                                                                    </p>
                                                                    <Bidding
                                                                        data={selectedProduct}
                                                                        hard="1"
                                                                    />
                                                                    <p className="slidViewBtnLabel">
                                                                        <span className="material-icons">
                                                                            info
                                                                        </span>
                                                                        Proxy Bid - Place your
                                                                        maximum bid on the lot
                                                                    </p>
                                                                    <Bidding
                                                                        data={selectedProduct}
                                                                        hard="0"
                                                                    />
                                                                </>
                                                            )}
                                                        </Fragment>
                                                    )
                                                ) : null}

                                                {selectedProduct.buynow ? (
                                                    <>
                                                        <div className="sl-pbids slidView">
                                                            <div className="row d-flex flex-nowrap">
                                                                {selectedProduct.user_id ==
                                                                user.id ? (
                                                                    <p
                                                                        style={{
                                                                            whiteSpace: 'nowrap',
                                                                        }}
                                                                    >
                                                                        You cannot buy your own
                                                                        product!
                                                                    </p>
                                                                ) : (
                                                                    <button
                                                                        className="pink-btn pbtn-sm"
                                                                        onClick={handleClick}
                                                                        disabled={disabled}
                                                                    >
                                                                        {disabled
                                                                            ? 'Added to cart'
                                                                            : 'Add to cart'}
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <br />
                                                    </>
                                                ) : null}
                                            </>
                                        ) : null}
                                    </div>
                                )}
                                {selectedProduct.auction && type !== 'buynow' ? (
                                    <>
                                        <Button
                                            className="mssBidHistory"
                                            onClick={() =>
                                                setHistoryData({
                                                    product_id: selectedProduct.id,
                                                    end: selectedProduct.date_closed,
                                                })
                                            }
                                        >
                                            <span className="material-icons">history</span>
                                            View bid history (
                                            {selectedProduct.bids ? selectedProduct.bids : 0})
                                        </Button>
                                        {selectedProduct.bids &&
                                        parseFloat(selectedProduct.rprice) > 0 ? (
                                            <p
                                                className={
                                                    parseFloat(selectedProduct.rprice) >
                                                    parseFloat(selectedProduct.wprice)
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                }
                                            >
                                                Reserve Price{' '}
                                                {parseFloat(selectedProduct.rprice) >
                                                parseFloat(selectedProduct.wprice)
                                                    ? 'Not'
                                                    : ''}{' '}
                                                Met!
                                            </p>
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                        </div>

                        <div className="pvsTwoBottom">
                            <div className="row mb-5">
                                <div className="col-sm-6 col-12">
                                    <div className="row">
                                        <div className="col-12 pvsbInfo">
                                            <h4>Horse Info</h4>

                                            {selectedProduct.category_id ? (
                                                <h5>
                                                    <span>Type:</span>
                                                    {selectedProduct.category_id}
                                                </h5>
                                            ) : null}
                                            {selectedProduct.content_head2 ? (
                                                <h5>
                                                    <span>Age:</span>
                                                    {selectedProduct.content_head2}
                                                </h5>
                                            ) : null}
                                            {selectedProduct.custom_field_1 ? (
                                                <h5>
                                                    <span>Sire:</span>
                                                    {selectedProduct.custom_field_1}
                                                </h5>
                                            ) : null}
                                            {selectedProduct.custom_field_2 ? (
                                                <h5>
                                                    <span>Dam:</span>
                                                    {selectedProduct.custom_field_2}
                                                </h5>
                                            ) : null}
                                            {selectedProduct.custom_field_4 ? (
                                                <h5>
                                                    <span>{"Dam's"} Sire:</span>
                                                    {selectedProduct.custom_field_4}
                                                </h5>
                                            ) : null}
                                            {selectedProduct.custom_field_5 ? (
                                                <h5>
                                                    <span>Color:</span>
                                                    {selectedProduct.custom_field_5}
                                                </h5>
                                            ) : null}
                                            {/* {selectedProduct.content_head2 ? (
                                                <h5>
                                                    <span>Gait:</span>
                                                    {selectedProduct.content_head2}
                                                </h5>
                                            ) : null} */}
                                            {selectedProduct.content_head3 ? (
                                                <h5>
                                                    <span>Gender:</span>
                                                    {selectedProduct.content_head3}
                                                </h5>
                                            ) : null}
                                            {selectedProduct.state ? (
                                                <h5>
                                                    <span>Location</span>
                                                    {selectedProduct.state}
                                                </h5>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12 pvsbInfo">
                                    <h4>Description</h4>
                                    <div>
                                        <p
                                            className="descCntnrValu"
                                            dangerouslySetInnerHTML={{
                                                __html: selectedProduct?.description,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {/* <div className="col-6 pvsbInfo">
                                    <h4>Owner details</h4>
                                    <h5>
                                        <span>Trainer Name</span>
                                        {selectedProduct.auctionnotes}
                                    </h5>
                                    <h5>
                                        <span>Owner Name</span>
                                        {selectedProduct.manufacturer}
                                    </h5>
                                    <h5>
                                        <span>Owner Phone</span>
                                        {selectedProduct.custom_field_7}
                                    </h5>
                                    <h5>
                                        <span>Contact Phone</span>
                                        {selectedProduct.tags}
                                    </h5>

                                    <h5>
                                        <span>Contact Email</span>
                                        <span className="emlWrdBrk">
                                            {selectedProduct.sell_location}
                                        </span>
                                    </h5>
                                </div> */}
                                <div className="col-sm-6 col-12">
                                    <div className="row">
                                        <div className="col-12 pvsbInfo adtnlAccordion">
                                            <h4>ADDITIONAL INFORMATION</h4>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={''}>
                                                        Veterinarian
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="downloadDoc">
                                                        {workLoc.map((val, key) => (
                                                            <div className="" key={key}>
                                                                <div className="docView">
                                                                    <img
                                                                        src={`/assets/images/${
                                                                            val.includes('pdf')
                                                                                ? 'pdf'
                                                                                : val.includes(
                                                                                      'docx',
                                                                                  )
                                                                                ? 'docx'
                                                                                : 'doc'
                                                                        }.png`}
                                                                        alt="pdf"
                                                                    />
                                                                    <h6>
                                                                        <a
                                                                            href={val}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Veterinarian {key + 1}
                                                                        </a>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="pane21a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography className={''}>X-Ray</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="downloadDoc">
                                                        {image.map((val, key) => (
                                                            <div className="" key={key}>
                                                                <div className="docView">
                                                                    <img
                                                                        src={
                                                                            val.includes('.jpeg') ||
                                                                            val.includes('.jpg') ||
                                                                            val.includes('.png') ||
                                                                            val.includes('.webp')
                                                                                ? val
                                                                                : `/assets/images/${
                                                                                      val.includes(
                                                                                          'pdf',
                                                                                      )
                                                                                          ? 'pdf'
                                                                                          : val.includes(
                                                                                                'docx',
                                                                                            )
                                                                                          ? 'docx'
                                                                                          : 'doc'
                                                                                  }.png`
                                                                        }
                                                                    />
                                                                    <h6>
                                                                        <a
                                                                            href={val}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            X-ray {key + 1}
                                                                        </a>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="pane21a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography className={''}>
                                                        Black Type Pedigree
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="downloadDoc">
                                                        {documentField1.map((val, key) => (
                                                            <div className="" key={key}>
                                                                <div className="docView">
                                                                    <img
                                                                        src={`/assets/images/${
                                                                            val.includes('pdf')
                                                                                ? 'pdf'
                                                                                : val.includes(
                                                                                      'docx',
                                                                                  )
                                                                                ? 'docx'
                                                                                : 'doc'
                                                                        }.png`}
                                                                    />
                                                                    <h6>
                                                                        <a
                                                                            href={val}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Black Type Pedigree{' '}
                                                                            {key + 1}
                                                                        </a>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(selectedProduct?.video_url?.length > 0 || productVideos?.length > 0) && (
                            <>
                                <div className="row mt-5">
                                    {selectedProduct?.video_url[0] !== '' && (
                                        <div className="col-12">
                                            <h4 className="vdoHdTx">VIDEOS</h4>
                                        </div>
                                    )}
                                    {productVideos.map((val) => (
                                        <div
                                            className={`hrsVdoWrpr ${
                                                productVideos.length == 1 &&
                                                selectedProduct?.video_url?.length == 0
                                                    ? 'col-12 mxHt'
                                                    : productVideos.length >= 1
                                                    ? 'col-md-6 col-12'
                                                    : 'col-12 mxHt'
                                            }`}
                                        >
                                            <ReactPlayer light={false} controls={true} url={val} />
                                        </div>
                                    ))}
                                    {selectedProduct?.video_url[0] !== '' && (
                                        <>
                                            {selectedProduct?.video_url.map((val) => (
                                                <div
                                                    className={`hrsVdoWrpr ${
                                                        selectedProduct?.video_url?.length == 1 &&
                                                        productVideos.length == 0
                                                            ? 'col-12 mxHt'
                                                            : selectedProduct?.video_url?.length >=
                                                              1
                                                            ? 'col-md-6 col-12'
                                                            : 'col-12 mxHt'
                                                    }`}
                                                >
                                                    <ReactPlayer
                                                        light={true}
                                                        controls={true}
                                                        url={val}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        <div className="row my-3">
                            <div className="col-md-12 d-flex justify-content-start align-items-center pvTitleContainer flex-wrap">
                                <label className="shrLable">
                                    <span className="material-icons">share</span> Share:
                                </label>
                                <div className="pvMiscActions">
                                    <FavoriteCheck
                                        watchlisted={selectedProduct.wlistpop ? true : false}
                                        project_id={selectedProduct.id}
                                    />
                                    <Facebook
                                        path={`/productView/${selectedProduct.id}`}
                                        title={selectedProduct.title}
                                    />
                                    <Whatsapp
                                        path={`/productView/${selectedProduct.id}`}
                                        title={selectedProduct.title}
                                    />
                                    <Twitter
                                        path={`/productView/${selectedProduct.id}`}
                                        title={selectedProduct.title}
                                    />
                                    <Email
                                        path={`/productView/${selectedProduct.id}`}
                                        subject={selectedProduct.title}
                                        body={selectedProduct.description}
                                    />
                                    <Pinterest
                                        path={`/productView/${selectedProduct.id}`}
                                        title={selectedProduct.title}
                                        media={
                                            `${
                                                selectedProduct.content_head1 === '0'
                                                    ? process.env.REACT_APP_BASE_URL +
                                                      'uploads/product/'
                                                    : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                            }` + selectedProduct.avatar
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <Bidhistory
                            modaltitle="Bid history"
                            open={Object.keys(historyData).length !== 0}
                            value={historyData}
                            handleClose={() => setHistoryData({})}
                        />
                        <HowBidPopup open={howBidPopup} handleClose={() => setHowBidPopup(false)} />
                    </>
                )}
            </div>
        </Layout>
    )
}

export default LiveStockProductView
