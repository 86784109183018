import { Pagination, Rating } from '@material-ui/lab'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../assets/loader'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import authContext from '../../../Product/context/auth/authContext'
import pluginContext from '../../../Product/context/plugin/pluginContext'

const FeedbackBuyer = ({ seller_id, lot_id }) => {
    const { t } = useTranslation()
    const { getAllFeedback, all_feedback } = useContext(pluginContext)
    const { user } = useContext(authContext)

    const [loading, setLoading] = useState(true)
    const [feedbackList, setFeedbackList] = useState([])
    const [body, setBody] = useState({
        perlimit: 12,
        page: 1,
        // user_id: user?.id,
        site_id: global?.storeDetails?.site_id,
        // lot_id: "",
        // auction_id: "",
        // seller_id: user?.id,
    })
    const onHandlePage = (event, value) => {
        setBody({ ...body, page: value })
    }
    useEffect(() => {
        if (user) {
            setLoading(true)
            getAllFeedback({ ...body, ...{ user_id: user?.id } })
        }
    }, [body])
    useEffect(() => {
        setLoading(false)
        setFeedbackList(all_feedback)
    }, [all_feedback])

    // useEffect(() => {
    //     if (user) {
    //         setBody({ ...body, user_id: user?.id })
    //     }
    // }, [user])

    return (
        <div className="text-center">
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : feedbackList.length ? (
                <div>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Lot Title</th>
                                    <th>Seller</th>
                                    <th>Date</th>
                                    <th>Subject</th>
                                    <th>Message</th>
                                    <th>Rating</th>
                                </tr>
                            </thead>
                            <tbody>
                                {feedbackList.map((val, key) => (
                                    <tr key={key}>
                                        <td>{val.title}</td>
                                        <td>{val.seller_name}</td>
                                        <td>{moment(val.date_added).format('MM-DD-YYYY')}</td>
                                        <td>{val.subject}</td>
                                        <td className="feedbackmsg">
                                            <div className="feedMessage">{val.message}</div>
                                        </td>

                                        <td>
                                            <Rating
                                                name="read-only"
                                                value={val.rating}
                                                precision={0.5}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex mt-3 pagination-wrapper">
                            <div className="ml-auto page-showing">
                                {t('showing')} {body.perlimit * (body.page - 1) + 1} -{' '}
                                {body.perlimit * body.page > feedbackList.length
                                    ? feedbackList.length
                                    : body.perlimit * body.page}{' '}
                                of {feedbackList.length}
                            </div>
                            <Pagination
                                count={Math.ceil(feedbackList.length / body.perlimit)}
                                page={body.page}
                                onChange={onHandlePage}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <NoRecordsFound />
            )}
        </div>
    )
}

export default FeedbackBuyer
