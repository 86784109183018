import React, { useState, useEffect, useContext } from 'react'
import Inputfield from '../../../Component/Inputfield/Inputfield'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import './shipping_amount_css.css'
import _ from 'lodash'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Popup from '../../../Product/components/organisms/Popup'
import AuthContext from '../../../Product/context/auth/authContext'
import CartContext from '../../../Product/context/cart/cartContext'
import PayContext from '../../../Product/context/payment/payContext'
import StripeCardContext from '../../../Product/context/stripe/card/cardContext'
import StripeCustomerContext from '../../../Product/context/stripe/customer/customerContext'
import { currencyFormat, handleRedirectInternal, mapData } from '../../../Product/common/components'
import UserContext from '../../../Product/context/user/userContext'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Button } from '@material-ui/core'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
const ShippingpaymentComponent = ({ amount, invoice_id, invoice_req, call_after_payment }) => {
    const authContext = useContext(AuthContext)
    // const cartContext = useContext(CartContext)
    const payContext = useContext(PayContext)
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { static_page, getStaticPage } = userContext
    const { user, loadUser } = authContext
    // const { getUserCart } = cartContext
    const {
        successAfterPay,
        successAfterPayAuction,
        successAfterPayupdateforbank,
        successAfterPayAuctionfaporbank,
        successAfterPayAuctionshipping,
        clearResponse,
        responseStatus: responseStatusPayment,
    } = payContext

    const { get_all_card_details, getAllStripeCards, createStripeCard } =
        useContext(StripeCardContext)
    const {
        customer_details,
        createStripeCustomer,
        getStripeCustomer,
        addCustomerProfile,
        CraeteBankCustomer,
        BuyerAddBank,
        getBankDetails,
        verifyBankdetails,
        transferamount,
        responseStatus: responseStatusStripeCustomer,
        clearResponse: clearbankpayment,
    } = useContext(StripeCustomerContext)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [saveCard, setSaveCard] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [bank_verify_open, setBankVarify] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [paymentoption, setPaymentoption] = useState(1)
    let history = useHistory()
    const [cardDetails, setCardDetails] = useState({
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvv: '',
    })
    const [allData, setAllData] = useState([])
    const [allBankData, setAllBankData] = useState([])
    const [cardData, setCardData] = useState('')
    const [error, setError] = useState({})
    const handleClose = () => {
        setOpen(false)
        setLoading(false)
        setCardDetails({})
        clearResponse()
        loadUser()
        call_after_payment(invoice_req)
    }

    const onCheckBoxChange = () => setSaveCard((prevState) => !prevState)
    const onChange = (e) => {
        e.target.checked ? setCardData(e.target.value) : setCardData('')
    }

    useEffect(() => {
        if (user) {
            const getData = async () => {
                getAllStripeCards({
                    account_id: process.env.REACT_APP_AUCTIONPAYID,
                    customer_id: user.card_paymentid,
                    object: 'card',
                })
            }
            getData()
        }
    }, [user, history])

    // useEffect(() => {
    //     if (user) {
    //         getBankDetails({
    //             site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
    //             email: user.email ? user.email : '',
    //             user_id: user.id ? user.id : '',
    //         })
    //     }
    // }, [user])

    // useEffect(() => {
    //     getStaticPage(
    //         {
    //             page_id: type === 'buynow' ? 'buynow_success_msg' : 'auction_success_msg',
    //         },
    //         1,
    //     )
    // }, [])

    // useEffect(() => {
    //     console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn', static_page)
    // }, [])

    useEffect(() => {
        if (get_all_card_details) {
            setAllData(get_all_card_details.records)
        }

        return () => {
            setAllData([])
        }
    }, [get_all_card_details])

    const handleSubmit = async (event) => {
        event.preventDefault()
        let error = {}

        var body = {
            source: cardData,
            customer: user.card_paymentid,
            currency: 'usd',
            description: 'shpping Payment',
            amount: amount,
        }

        successAfterPayAuctionshipping({
            invoice_id,
            payment_details: body,
        })
    }

    const handleSubmitBank = async (event) => {
        event.preventDefault()
        var index_pos
        allBankData.map((val, index) => {
            if (val.id == cardData) {
                index_pos = index
            }
        })
        if (cardData === 'nocard') {
            setAlert('Please Select Bank Details', 'error')
            return false
        } else if (allBankData[index_pos].status != 'verified') {
            setAlert('Please Verifiy Bank Details', 'error')
            setBankVarify(true)
            return false
        } else {
            setLoading(true)
            transferamount({
                site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                user_id: user.id ? user.id : '',
                payAmount: parseInt(parseFloat(total_payable_amount) * 100),
            })
        }
    }

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'successAfterPayAuctionshipping') {
                if (responseStatusPayment.status === 'success') {
                    setOpen(true)
                } else {
                    setAlert(responseStatusPayment.message, 'error')
                    setLoading(false)
                }
            }
        }
    }, [responseStatusPayment])

    const onTextChange = (e) => {
        setCardDetails({ ...cardDetails, [e.target.name]: e.target.value })
        let errorR = error
        delete errorR[e.target.name]
        setError({ ...errorR })
    }

    const bankvalidationarray = Yup.object({
        bank_account_holder_name: Yup.string().required('Required'),
        bank_account_holder_type: Yup.string().required('Required'),
        bank_routing_number: Yup.string().required('Required').min(9, 'Minimum 9 digits'),
        bank_account_number: Yup.string().required('Required'),
    })
    const bank_verfication_popup = Yup.object({
        payAmount1: Yup.string().required('Required'),
        payAmount2: Yup.string().required('Required'),
    })

    const bank_verification_formik = useFormik({
        initialValues: {
            payAmount1: '',
            payAmount2: '',
            site_id: '',
            user_id: '',
        },
        validationSchema: bank_verfication_popup,
        onSubmit: (values) => {
            setBtnLoading(true)
            values.site_id = global.storeDetails.site_id ? global.storeDetails.site_id : ''
            values.user_id = user.id ? user.id : ''
            verifyBankdetails(values)
        },
    })

    const bankformik = useFormik({
        initialValues: {
            account_id: user?.company_name ? user.company_name : '',
            bank_country: 'us',
            bank_currency: 'usd',
            bank_account_holder_name: '',
            bank_account_holder_type: '',
            bank_routing_number: '',
            bank_account_number: '',
            bank_token: true,
        },
        validationSchema: bankvalidationarray,
        onSubmit: (values) => {
            setBtnLoading(true)
            var data = {
                site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                email: user.email ? user.email : '',
                user_id: user.id ? user.id : '',
                holdername: values.bank_account_holder_name,
            }
            CraeteBankCustomer(data)
        },
    })
    const bank_details = {
        formik: bankformik,
        data: [
            {
                label: 'Holder Name',
                name: 'bank_account_holder_name',
                type: 'text',
                placeholder: 'Enter your bank holder name',
                class: 'col-12 col-sm-6',
                autoFocus: true,
            },
            {
                label: 'Account Type',
                placeholder: 'Select your type',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: [
                    {
                        show: 'Individual',
                        value: 'individual',
                    },
                    {
                        show: 'Company',
                        value: 'company',
                    },
                ],
                name: 'bank_account_holder_type',
            },
            {
                label: 'Routing Number',
                name: 'bank_routing_number',
                type: 'text',
                placeholder: 'Enter routing number',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Account Number',
                name: 'bank_account_number',
                type: 'text',
                placeholder: 'Enter account number',
                class: 'col-12 col-sm-6',
            },
        ],
    }
    const bank_verification = {
        formik: bank_verification_formik,
        data: [
            {
                label: 'Amount 1',
                name: 'payAmount1',
                type: 'text',
                placeholder: 'Enter amount1',
                class: 'col-6',
                autoFocus: true,
            },
            {
                label: 'Amount 2',
                name: 'payAmount2',
                type: 'text',
                placeholder: 'Enter your amount1',
                class: 'col-6',
            },
        ],
    }
    const Openpopup = (e) => {
        bankformik.values.bank_account_holder_name = ''
        bankformik.values.bank_account_holder_type = ''
        bankformik.values.bank_routing_number = ''
        bankformik.values.bank_account_number = ''
        setOpen(true)
    }
    const Verifypopup = (e) => {
        setBankVarify(true)
    }

    return (
        <div className="ptp-wrapper">
            {user ? (
                <>
                    {/* <h2 className="od-head">Payment details</h2>
                    <div className="chkcard">
                        <span>
                            <p>Total Amount</p>
                            <p>: {currencyFormat(amount, currency)}</p>
                        </span>
                        {buyers_premium != 0 ? (
                            <span>
                                <p>{"Buyer's Premium"}</p>
                                <p>: {currencyFormat(buyers_premium, currency)}</p>
                            </span>
                        ) : null}
                        {transaction_fee != 0 ? (
                            <span>
                                <p>Transaction Fee</p>
                                <p>: {currencyFormat(transaction_fee, currency)}</p>
                            </span>
                        ) : null}
                        <span>
                            <p>Shipping Fee</p>
                            <p>: {currencyFormat(shipping_fee, currency)}</p>
                        </span>
                        <span>
                            <p>Sales Tax</p>
                            <p>: {currencyFormat(sales_tax, currency)}</p>
                        </span>
                        <span>
                            <p>Grand Total </p>
                            <p>: {currencyFormat(total_payable_amount, currency)}</p>
                        </span>
                    </div> */}
                    <div className="od-box">
                        {/* {global.storeDetails.theme == 4 ? (
                            <CustomSelect
                                label={'Select Payment Option'}
                                // id={data.id}
                                value={paymentoption}
                                //autoFocus={data.autoFocus}
                                //name={data.filter ? `filters.${data.name}.value` : data.name}
                                // size={data.size}
                                onChange={(e) => {
                                    if (allBankData.length == 0 && e.target.value == 2) {
                                        setIsOpen(true)
                                        setPaymentoption(e.target.value)
                                    } else {
                                        setPaymentoption(e.target.value)
                                    }
                                }}
                                // placeholder={data.placeholder}
                                // disabled={data.disabled}
                                // onBlur={formik.handleBlur}
                                // type={data.type}
                                // error={errorCheck(data, formik)}
                                // helperText={helperText(data, formik)}
                                // upperLabel={data.upperLabel}
                            >
                                <option value={1}>Credit Card</option>
                                <option value={2}>Bank</option>
                            </CustomSelect>
                        ) : (
                            ''
                        )} */}
                        {paymentoption == 1 ? (
                            <form onSubmit={handleSubmit}>
                                {allData &&
                                    allData.map((data, index) => (
                                        <div key={index} className="cardSelect">
                                            <label htmlFor="ritemb" className="cardmain">
                                                <div className="flx">
                                                    <p>{'xxxx-xxxx-xxxx-' + data.last4}</p>
                                                    <div className="radio-item">
                                                        <input
                                                            type="checkbox"
                                                            id="ritemb"
                                                            name="ritem"
                                                            value={data.id}
                                                            checked={cardData === data.id}
                                                            onChange={onChange}
                                                        />
                                                        {/* <span className="vcRad"></span> */}
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <p>{data.brand}</p>
                                                    <p>
                                                        {data.exp_month}/{data.exp_year}
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    ))}
                                {cardData === 'nocard' && (
                                    <div>
                                        <div className="form-group">
                                            <Inputfield
                                                title="Card Number"
                                                name="cardNumber"
                                                type="text"
                                                placeholder="Enter your card number"
                                                length="16"
                                                onChange={onTextChange}
                                                value={cardDetails.cardNumber || ''}
                                                autoFocus={true}
                                                msg={error.cardNumber || ''}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="Expiry Month"
                                                    name="expiryMonth"
                                                    type="text"
                                                    length="2"
                                                    onChange={onTextChange}
                                                    placeholder="MM"
                                                    value={cardDetails.expiryMonth || ''}
                                                    msg={error.expiryMonth || ''}
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="Expiry Year"
                                                    name="expiryYear"
                                                    type="text"
                                                    length="4"
                                                    onChange={onTextChange}
                                                    placeholder="YYYY"
                                                    value={cardDetails.expiryYear || ''}
                                                    msg={error.expiryYear || ''}
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="CVV"
                                                    name="cvv"
                                                    onChange={onTextChange}
                                                    type="text"
                                                    length="4"
                                                    placeholder="Enter your CVV"
                                                    value={cardDetails.cvv || ''}
                                                    msg={error.cvv || ''}
                                                />
                                            </div>
                                        </div>
                                        <input
                                            id="saveCard"
                                            type="checkbox"
                                            onChange={onCheckBoxChange}
                                            checked={saveCard}
                                        />
                                        <label style={{ marginLeft: 10 }} htmlFor="saveCard">
                                            Save Card
                                        </label>
                                    </div>
                                )}
                                <div className="text-center form-group mt-2">
                                    <button type="submit" className="pink-btn" disabled={loading}>
                                        {loading ? 'Loading...' : `Make Payment`}
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={handleSubmitBank}>
                                {allBankData &&
                                    allBankData.map((data, index) => (
                                        <div key={index} className="cardSelect">
                                            <label htmlFor="ritemb" className="cardmain">
                                                <div className="flx">
                                                    <p>{data.bank_name}</p>
                                                    <div className="radio-item">
                                                        <input
                                                            type="checkbox"
                                                            id="ritemb"
                                                            name="ritem"
                                                            value={data.id}
                                                            checked={cardData === data.id}
                                                            onChange={onChange}
                                                        />
                                                        {/* <span className="vcRad"></span> */}
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <p>{data.account_holder_name}</p>
                                                    <p>
                                                        {data.status == 'new' ||
                                                        data.status == 'verification_failed' ? (
                                                            <Button
                                                                onClick={(e) => Verifypopup(e)}
                                                                className="edit"
                                                            >
                                                                <span className="material-icons">
                                                                    verify
                                                                </span>
                                                                VERIFY
                                                            </Button>
                                                        ) : data.status == 'verified' ? (
                                                            <Button className="edit">
                                                                <span className="material-icons">
                                                                    verify
                                                                </span>
                                                                VERIFIED
                                                            </Button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    ))}
                                {/* {cardData === 'nocard' && (
                                    <div>
                                        <div className="form-group">
                                            <Inputfield
                                                title="Card Number"
                                                name="cardNumber"
                                                type="text"
                                                placeholder="Enter your card number"
                                                length="16"
                                                onChange={onTextChange}
                                                value={cardDetails.cardNumber || ''}
                                                autoFocus={true}
                                                msg={error.cardNumber || ''}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="Expiry Month"
                                                    name="expiryMonth"
                                                    type="text"
                                                    length="2"
                                                    onChange={onTextChange}
                                                    placeholder="Expiration Month"
                                                    value={cardDetails.expiryMonth || ''}
                                                    msg={error.expiryMonth || ''}
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="Expiry Year"
                                                    name="expiryYear"
                                                    type="text"
                                                    length="4"
                                                    onChange={onTextChange}
                                                    placeholder="Expiration Year"
                                                    value={cardDetails.expiryYear || ''}
                                                    msg={error.expiryYear || ''}
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <Inputfield
                                                    title="CVV"
                                                    name="cvv"
                                                    onChange={onTextChange}
                                                    type="text"
                                                    length="4"
                                                    placeholder="Enter your CVV"
                                                    value={cardDetails.cvv || ''}
                                                    msg={error.cvv || ''}
                                                />
                                            </div>
                                        </div>
                                        <input
                                            id="saveCard"
                                            type="checkbox"
                                            onChange={onCheckBoxChange}
                                            checked={saveCard}
                                        />
                                        <label style={{ marginLeft: 10 }} htmlFor="saveCard">
                                            Save Card
                                        </label>
                                    </div>
                                )} */}
                                <div className="text-center form-group mt-2">
                                    <button type="submit" className="pink-btn" disabled={loading}>
                                        {loading
                                            ? 'Loading...'
                                            : `Pay $ ${total_payable_amount.toUSFormat()}`}
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>

                    {open && (
                        <Popup
                            open={open}
                            size="md"
                            className="save-search-modal"
                            handleClose={handleClose}
                            modaltitle="Order Successful"
                        >
                            <div className="order-success-wrap text-center">
                                <img src="/image/check_circle.svg" alt="" />
                                <p className="os-text">
                                    Congrats, you have successfully completed your order.
                                </p>
                            </div>
                        </Popup>
                    )}
                </>
            ) : null}
            <Popup
                open={isOpen}
                handleClose={(e) => setIsOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Bank Details"
            >
                <>
                    <div className="row">{mapData(bank_details)}</div>

                    <div className="actionWrapper">
                        <div className="row">
                            <div className="col-sm-3 col-12"></div>
                            <div className="col-md-6">
                                <PrimaryButton
                                    onClick={(e) => bankformik.handleSubmit(e)}
                                    type="button"
                                    disabled={btnLoading}
                                    label={btnLoading ? 'Loading...' : 'ADD'}
                                />
                            </div>
                        </div>
                    </div>
                </>
            </Popup>
            <Popup
                open={bank_verify_open}
                handleClose={(e) => setBankVarify(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Verify Bank Details"
            >
                <>
                    <div className="row">{mapData(bank_verification)}</div>

                    <div className="actionWrapper">
                        <div className="row">
                            <div className="col-sm-3 col-12"></div>
                            <div className="col-md-6">
                                <PrimaryButton
                                    onClick={(e) => bank_verification_formik.handleSubmit(e)}
                                    type="button"
                                    disabled={btnLoading}
                                    label={btnLoading ? 'Loading...' : 'VERIFY'}
                                />
                            </div>
                        </div>
                    </div>
                </>
            </Popup>
        </div>
    )
}

export default ShippingpaymentComponent
