import { Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core'
import React from 'react'
import Layout from '../../Layout'
import './HowItWorks.css'
import { Link } from 'react-router-dom'

const HowItWorks = (props) => {
    const handleExploreClick = (id) => {
        const targetElement = document.getElementById(id)
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    return (
        <>
            <Layout props={props}>
                <div className=" py-4 staticPgWrapper">
                    <div className="staticPage howItWorks">
                        <div className="mainHead">
                            <h2>HOW IT WORKS</h2>
                            <h4>BUYING A USED SLOT MACHINE FOR YOUR HOME</h4>
                            <p>
                                Welcome to <a href="/">OwnASlot.com!</a> We &apos! re thrilled to
                                guide you through the simple steps to bring the excitement of a real
                                casino slot machine into your home.
                            </p>
                        </div>
                        <div className="stepCounter">
                            <div className="line">
                                <div className="steps">
                                    <div
                                        className="counts"
                                        onClick={() => handleExploreClick('howItWorks_1')}
                                    >
                                        <span className="first">1</span>
                                        <p>Explore our collection</p>
                                    </div>
                                    <div
                                        className="counts"
                                        onClick={() => handleExploreClick('howItWorks_2')}
                                    >
                                        <span>2</span>
                                        <p>Select your perfect machine(s)</p>
                                    </div>
                                    <div
                                        className="counts"
                                        onClick={() => handleExploreClick('howItWorks_3')}
                                    >
                                        <span>3</span>
                                        <p>Review product details</p>
                                    </div>
                                    <div
                                        className="counts"
                                        onClick={() => handleExploreClick('howItWorks_4')}
                                    >
                                        <span>4</span>
                                        <p>Add to cart & checkout</p>
                                    </div>
                                    <div
                                        className="counts"
                                        onClick={() => handleExploreClick('howItWorks_5')}
                                    >
                                        <span>5</span>
                                        <p>Ensuring quality & functionality</p>
                                    </div>
                                </div>
                            </div>
                            <div className="line">
                                <div className="steps">
                                    <div
                                        className="counts"
                                        onClick={() => handleExploreClick('howItWorks_6')}
                                    >
                                        <span>6</span>
                                        <p>Enjoy hassle-free delivery</p>
                                    </div>
                                    <div
                                        className="counts"
                                        onClick={() => handleExploreClick('howItWorks_7')}
                                    >
                                        <span>7</span>
                                        <p>Setup made easy</p>
                                    </div>
                                    <div
                                        className="counts"
                                        onClick={() => handleExploreClick('howItWorks_8')}
                                    >
                                        <span>8</span>
                                        <p>Start playing & enjoy!</p>
                                    </div>
                                    <div
                                        className="counts"
                                        onClick={() => handleExploreClick('howItWorks_9')}
                                    >
                                        <span>9</span>
                                        <p>Need assistance? We are here!</p>
                                    </div>
                                    <div
                                        className="counts"
                                        onClick={() => handleExploreClick('howItWorks_10')}
                                    >
                                        <span className="last">10</span>
                                        <p>Share your experience</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stepper">
                            <Stepper orientation="vertical">
                                <Step id="howItWorks_1">
                                    <StepLabel>
                                        <h3>
                                            <Link style={{ color: '#000' }} to="/search">
                                                Explore our collection
                                            </Link>
                                        </h3>
                                        <p>
                                            Please browse through our diverse collection of
                                            authentic, refurbished slot machines. From classic
                                            three-reel slots to engaging video slots and themed
                                            machines, we offer a wide range of options to suit your
                                            preferences. 
                                        </p>
                                        <img
                                            src="/slot4home/Group-2997.png"
                                            alt="Group-2997"
                                            style={{
                                                maxWidth: '1074px',
                                                width: '100%',
                                                margin: '20px auto',
                                                display: 'flex',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </StepLabel>
                                </Step>
                                <Step id="howItWorks_2">
                                    <StepLabel>
                                        <h3>Select your perfect machine(s)</h3>
                                        <p>
                                            Once you’ve found the machine(s) that catches your eye,
                                            click on it to view detailed information. Each listing
                                            specifies the machines features, design, and any
                                            available customization options. 
                                        </p>
                                        <img
                                            src="/slot4home/Group-2998.png"
                                            alt="Group-2998"
                                            style={{
                                                maxWidth: '1074px',
                                                width: '100%',
                                                margin: '20px auto',
                                                display: 'flex',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </StepLabel>
                                </Step>
                                <Step id="howItWorks_3">
                                    <StepLabel>
                                        <h3>Review Product Details</h3>
                                        <p>
                                            Get acquainted with the product details, including its
                                            dimensions, power requirements, and any additional
                                            accessories or features. You’ll also find information on
                                            warranties and return policies.  Typically, slot
                                            machines require 110 to 250 Watts of power, and it is
                                            highly suggested that you use a surge protector.
                                        </p>
                                        <img
                                            src="/slot4home/Group-2999.png"
                                            alt="Group-2999"
                                            style={{
                                                maxWidth: '1074px',
                                                width: '100%',
                                                margin: '20px auto',
                                                display: 'flex',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </StepLabel>
                                </Step>
                                <Step id="howItWorks_4">
                                    <StepLabel>
                                        <h3>Add to cart and checkout</h3>
                                        <p>
                                            Ready to make your purchase? Add the slot machine to
                                            your cart and proceed to the secure checkout. You can
                                            review your order, choose your shipping method, and
                                            input your payment details here. Examine if the machine
                                            requires a stand for the slot machine to sit on. You can
                                            examine if you want a customer to look at the cabinet by
                                            picking from one of our 3M wrap solutions.
                                        </p>
                                        <img
                                            src="/slot4home/Group-3000.png"
                                            alt="Group-3000"
                                            style={{
                                                maxWidth: '784px',
                                                width: '100%',
                                                margin: '20px auto',
                                                display: 'flex',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </StepLabel>
                                </Step>
                                <Step id="howItWorks_5">
                                    <StepLabel>
                                        <h3>
                                            Ensuring Quality and Functionality Every Step of the Way
                                        </h3>
                                        <p>
                                            At OwnASlot, your satisfaction is our top priority.
                                            That’s why our team of expert slot technicians
                                            meticulously inspects and refurbishes each slot machine
                                            before it reaches your doorstep.
                                        </p>
                                        <div className="twoPortions">
                                            <div style={{ marginRight: '20px' }}>
                                                <h6> Thorough Testing and Refurbishment </h6>
                                                <p>
                                                    Our skilled technicians conduct comprehensive
                                                    tests on every component of your chosen slot
                                                    machine. From the reels to the circuitry, we
                                                    ensure that everything functions flawlessly. Any
                                                    necessary repairs are made, and the machine
                                                    undergoes thorough cleaning to restore its
                                                    pristine condition.
                                                </p>
                                                <img
                                                    src="/slot4home/first.png"
                                                    alt="first"
                                                    style={{ maxWidth: '580px', width: '100%' }}
                                                />
                                            </div>
                                            <div>
                                                <img
                                                    src="/slot4home/second.png"
                                                    alt="second"
                                                    style={{
                                                        maxWidth: '337px',
                                                        width: '100%',
                                                        marginBottom: '10px',
                                                    }}
                                                />
                                                <h6> Thorough Testing and Refurbishment </h6>
                                                <p>
                                                    Our skilled technicians conduct comprehensive
                                                    tests on every component of your chosen slot
                                                    machine. From the reels to the circuitry, we
                                                    ensure that everything functions flawlessly. Any
                                                    necessary repairs are made, and the machine
                                                    undergoes thorough cleaning to restore its
                                                    pristine condition.
                                                </p>
                                            </div>
                                        </div>
                                    </StepLabel>
                                </Step>
                                <Step id="howItWorks_6">
                                    <StepLabel>
                                        <h3>Enjoy Hassle-Free Delivery</h3>
                                        <p>
                                            Once your order is confirmed, our team prepares your
                                            slot machine for shipping. Sit back and relax as your
                                            new gaming machine approaches your doorstep. We offer
                                            reliable shipping options to ensure your purchase
                                            arrives safely and promptly.
                                        </p>
                                    </StepLabel>
                                </Step>
                                <Step id="howItWorks_7">
                                    <StepLabel>
                                        <h3>Setup Made Easy</h3>
                                        <p>
                                            Upon delivery, follow the provided setup instructions
                                            included with your slot machine. Typically, it’s a
                                            straightforward process requiring a power source and
                                            possibly minor assembly. If you have any questions, our
                                            customer support team is here to assist you.
                                        </p>
                                    </StepLabel>
                                </Step>
                                <Step id="howItWorks_8">
                                    <StepLabel>
                                        <h3>Start Playing and Enjoy!</h3>
                                        <p>
                                            Congratulations, your slot machine is ready to spin!
                                            Load it up with tokens or credits and experience the
                                            thrill of a casino right in your home. Gather friends
                                            and family for endless entertainment and gaming
                                            excitement.
                                        </p>
                                        <img
                                            src="/slot4home/image-4.png"
                                            alt="image-4"
                                            style={{
                                                margin: '20px auto',
                                                display: 'flex',
                                                maxWidth: '380px',
                                                width: '100%',
                                            }}
                                        />
                                    </StepLabel>
                                </Step>
                                <Step id="howItWorks_9">
                                    <StepLabel>
                                        <h3>Need Assistance? We are Here!</h3>
                                        <p>
                                            If you encounter any issues or have questions about your
                                            slot machine, don’t hesitate to reach out to our
                                            dedicated customer support team. We’re committed to
                                            ensuring your experience with our products is nothing
                                            short of fantastic.
                                        </p>
                                    </StepLabel>
                                </Step>
                                <Step id="howItWorks_10">
                                    <StepLabel>
                                        <h3>Share Your Experience</h3>
                                        <p>
                                            We love hearing about your experiences! Share your fun
                                            moments with your slot machine on social media and tag
                                            us [@YourStoreName]. Join our community of slot
                                            enthusiasts and share the excitement!
                                        </p>
                                    </StepLabel>
                                </Step>
                            </Stepper>
                        </div>
                        <div className="confidence customContainer">
                            <h3>OUR CONFIDENCE, OUR COMMITMENT</h3>
                            <p>
                                With OwnASlot, rest assured that you’re not just purchasing a slot
                                machine; you’re investing in a premium, quality-tested product
                                backed by our commitment to excellence.
                            </p>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default HowItWorks
