import React, { useContext, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Link, useHistory } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import { dateFormatFront, handleRedirectInternal } from '../../../Product/common/components'
import { Pagination } from '@material-ui/lab'
import { Button } from '@material-ui/core'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import Loader from '../../../assets/loader'
import SubLots from '../../../utils/CommonFunctionality/SellerDashboard/SubLots'

const AllBids = (props) => {
    const productContext = useContext(ProductContext)
    const { seller_allauctions, getAllSellerAuctions } = productContext

    const authContext = useContext(AuthContext)
    const { isAuthenticated, isAdmin } = authContext
    const [loading, setLoading] = useState(true)
    const [auctionId, setAuctionId] = useState(null)
    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
    })

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }
    useEffect(() => {
        setLoading(false)
    }, [seller_allauctions])

    useEffect(() => {
        if (isAuthenticated) {
            setLoading(true)
            getAllSellerAuctions(search)
        }
    }, [search, isAuthenticated])
    const history = useHistory()
    return (
        <div>
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : seller_allauctions.result.length ? (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="numCell">Auction #</th>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Time left</th>
                                    <th>No of lots</th>
                                    <th>Status</th>
                                    <th colSpan="2">Action</th>
                                </tr>
                            </thead>
                            {seller_allauctions.result.map((item, index) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>
                                                    <img
                                                        className="pro-img"
                                                        src={`${global.images_url}product/${item.avatar}`}
                                                        alt={item.title}
                                                        width="65"
                                                        height="65"
                                                    />
                                                </td>
                                                <td>{item.title}</td>
                                                <td>{dateFormatFront(item.date_closed)}</td>
                                                {/* <td>
                                                    <tr>
                                                        <td>Active: </td>
                                                        <td>{item.activecount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sold: </td>
                                                        <td>{item.soldcount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Unsold: </td>
                                                        <td>{item.unsoldcount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Draft: </td>
                                                        <td>{item.draftcount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total: </td>
                                                        <td>{item.lotscount}</td>
                                                    </tr>
                                                </td> */}
                                                <td>{item.lotscount}</td>
                                                <td>
                                                    {parseInt(item.lotscount) > 0
                                                        ? parseInt(item.activecount) > 0
                                                            ? 'Live'
                                                            : parseInt(item.draftcount) > 0
                                                            ? 'Draft'
                                                            : 'Closed'
                                                        : 'No Items'}
                                                </td>
                                                <td>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                `post-auction?id=${item.id}`,
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() =>
                                                            setAuctionId(
                                                                auctionId === item.id
                                                                    ? null
                                                                    : item.id,
                                                            )
                                                        }
                                                    >
                                                        {auctionId === item.id ? 'Hide' : 'Show'}{' '}
                                                        Sub Lots{' '}
                                                        {auctionId === item.id ? (
                                                            <span className="material-icons-outlined">
                                                                expand_less
                                                            </span>
                                                        ) : (
                                                            <span className="material-icons-outlined">
                                                                expand_more
                                                            </span>
                                                        )}{' '}
                                                    </Button>
                                                </td>
                                            </tr>
                                            {auctionId === item.id && (
                                                <tr>
                                                    <td colSpan="8">
                                                        <SubLots
                                                            lotof={auctionId}
                                                            searchBar={1}
                                                            status={1}
                                                            resultsPerPage={1}
                                                            changeStatus={1}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </>
                                )
                            })}
                        </table>
                    </div>

                    <div className="d-flex mt-3">
                        <div className="ml-auto mt-2">
                            Showing {search.limit * (search.page - 1) + 1} -{' '}
                            {search.limit * search.page > seller_allauctions.length
                                ? seller_allauctions.length
                                : search.limit * search.page}{' '}
                            of {seller_allauctions.length}
                        </div>
                        <Pagination
                            count={Math.ceil(seller_allauctions.length / search.limit)}
                            page={search.page}
                            onChange={onHandlePage}
                        />
                    </div>
                </>
            ) : (
                <>
                    <NoRecordsFound />
                </>
            )}
        </div>
    )
}

export default AllBids
