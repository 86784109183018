import { Button } from '@material-ui/core'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Copyrightinfo, Logo } from '../../../utils'
import Footernav from '../../../utils/FooterNavigation/footernav'
import './Footer.css'

function Footer() {
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram
    // const contactAddress = global?.storeDetails?.address
    return (
        <footer className="footer-main-standard noPrint">
            <div className="customContainer">
                <div className="row" data-gutter="60">
                    <div className="col-12 ftrDtlCntnr">
                        <div className="footLogoCnt">
                            <Logo className="footerLogo" />
                            {/* {contactAddress} */}
                        </div>
                        <div className="w-100">
                            <ul className="footerLinks">
                                <li>
                                    <NavLink activeClassName="active" to="/">
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/search">
                                        Auction
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/aboutus">
                                        About Us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/contact_us">
                                        Contact Us
                                    </NavLink>
                                </li>
                            </ul>
                            <ul className="footerLinks">
                                <li>
                                    <NavLink activeClassName="active" to="/termsOfService">
                                        Terms & Conditions
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/privacyPolicy">
                                        Privacy policy
                                    </NavLink>
                                </li>
                                <Footernav />
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footerMisc pb-4">
                    <ul className="footer-main-standard-social-list">
                        {facebookLink && (
                            <li className="facebook">
                                <a
                                    href={facebookLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Facebook"
                                >
                                    <i className="fab fa-facebook"></i>
                                </a>
                            </li>
                        )}
                        {twitterLink && (
                            <li className="pinterest">
                                <a
                                    href={twitterLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Pinterest"
                                >
                                    <i className="fab fa-twitter"></i>
                                </a>
                            </li>
                        )}
                        {googleLink && (
                            <li className="instagram">
                                <a
                                    href={googleLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Instagram"
                                >
                                    <i className="fab fa-linkedin"></i>
                                </a>
                            </li>
                        )}
                        {instagramLink && (
                            <li className="instagram">
                                <a
                                    href={instagramLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Instagram"
                                >
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                        )}
                    </ul>
                    <Copyrightinfo />
                </div>
            </div>
        </footer>
    )
}

export default Footer
