import {
    AccountBalance,
    AccountBalanceWalletOutlined,
    AirlineSeatLegroomReducedTwoTone,
} from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../assets/loader'
import { currencyFormat } from '../../../Product/common/components'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import authContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import Popup from '../../../Product/components/organisms/Popup'
import { useFormik } from 'formik'
import { mapData } from '../../../Product/common/components'
import RequestRefund from './Requestreturn'

const ReferralEarned = () => {
    const { t } = useTranslation()
    const {
        getRefundlist,
        RefundlistAccepted,
        Refundlistrejected,
        requestRefundaccept_or_not,
        responseStatus,
    } = useContext(pluginContext)
    const alertContext = useContext(AlertContext)
    const { user } = useContext(authContext)
    const { setAlert } = alertContext
    const [producttype, setProducttype] = useState({})
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [referralList, setReferralList] = useState([])
    const [totalrows, setTotalrows] = useState(0)
    const [btnButton, setBtnButton] = useState(false)
    const [refundTrigger, setRefundTrigger] = useState(false)
    const [search, setSearch] = useState({
        perlimit: 12,
        page: 1,
        site_id: global?.storeDetails?.site_id,
    })

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }
    useEffect(() => {
        if (user?.id) {
            setLoading(true)
            getRefundlist({ ...search, seller_id: user.id })
        }
    }, [search, user, refundTrigger])

    const mark_as_recived = (index) => {
        setBtnButton(true)
        requestRefundaccept_or_not({
            status: 'Seller_mark_as_recived',
            invoice_no: referralList[index].common_invoice,
        })
    }

    useEffect(() => {
        console.log(
            'ppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp',
            responseStatus,
        )
        if (responseStatus?.from == 'refundlist') {
            setLoading(false)
            setReferralList(responseStatus?.data?.product ? responseStatus.data.product : [])
            setTotalrows(
                parseInt(responseStatus?.data?.total_rows ? responseStatus.data.total_rows : 0),
            )
        }
        if (responseStatus?.from == 'refundlistaccepted') {
            setOpen(false)
            getRefundlist({ ...search, seller_id: user.id })
            setAlert(responseStatus.message, responseStatus.status)
        }
        if (responseStatus?.from == 'refundlistreject') {
            getRefundlist({ ...search, seller_id: user.id })
            setAlert(responseStatus.message, responseStatus.status)
        }
        if (responseStatus?.from == 'requestRefundaccpt_or_not') {
            getRefundlist({ ...search, seller_id: user.id })
            setAlert(responseStatus.message, responseStatus.status)
        }
        setBtnButton(false)
    }, [responseStatus])

    // useEffect(() => {
    //     if (user?.id) {
    //         setLoading(true)
    //         setSearch({ ...search, user_id: user?.id })
    //     }
    // }, [search])

    const formik = useFormik({
        initialValues: {
            refund_paytype: 'payment',
            partialamount: 0,
            invoice_id: '',
            lot_id: '',
            user_id: '',
        },
        onSubmit: async (values) => {
            formik.values.invoice_id = producttype.common_invoice
            formik.values.lot_id = producttype.project_id
            formik.values.user_id = user.id
            RefundlistAccepted(formik.values)
        },
    })

    const fields = [
        {
            title: 'Refund To',
            type: 'radio',
            //noLabel: true,
            name: 'refund_paytype',
            class: 'col-12',
            item: [
                { id: 'payment', description: 'Original Payment Method' },
                { id: 'credits', description: 'Store Credit' },
            ],
            formik: formik,
        },
        {
            title: 'Refund Type',
            type: 'radio',
            //noLabel: true,
            int: 1,
            name: 'partialamount',
            class: 'col-12',
            item: [
                { id: 0, description: 'Full Refund' },
                { id: 1, description: 'Partial Refund' },
            ],
            formik: formik,
        },
    ]

    return (
        <div className="text-center">
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : referralList.length ? (
                <div>
                    {/* <p className="d-inline float-left">
                        <AccountBalance /> {currencyFormat(total_earned)}
                    </p>
                    <p className="d-inline float-right">
                        <AccountBalanceWalletOutlined /> {currencyFormat(user?.wallet_amount)}
                    </p> */}

                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Invoice Id</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Title</th>
                                    <th>Notes</th>
                                    <th>Date Added</th>
                                    <th>Amount</th>
                                    <th>Mark As Received</th>
                                    <th>Request</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {referralList.map((val, key) => (
                                    <tr key={key}>
                                        <td>{val.common_invoice}</td>
                                        <td>{val.first_name}</td>
                                        <td>{val.last_name}</td>
                                        <td>{val.email}</td>
                                        <td>{val.title}</td>
                                        <td>{val.refund_request_notes}</td>
                                        <td>
                                            {moment(val.refund_req_date).format(
                                                'MM-DD-YYYY HH:MM:ss',
                                            )}
                                        </td>
                                        <td>{currencyFormat(val.amount)}</td>
                                        <td>
                                            {parseInt(val.seller_refund_accept) == 1 ? (
                                                <>
                                                    <PrimaryButton
                                                        btnSize="mt-3"
                                                        onClick={() => mark_as_recived(key)}
                                                        label={'Mark As Received'}
                                                        disabled={btnButton}
                                                    ></PrimaryButton>
                                                </>
                                            ) : (
                                                <p>-</p>
                                            )}
                                        </td>
                                        <td>
                                            {parseInt(val.seller_refund_accept) != 3 &&
                                            parseInt(val.seller_refund_accept) != 4 ? (
                                                <RequestRefund
                                                    invoice_id={val.common_invoice}
                                                    trigger={refundTrigger}
                                                    setTrigger={setRefundTrigger}
                                                    refund_request_notes={val.seller_return_notes}
                                                />
                                            ) : (
                                                <p>-</p>
                                            )}
                                        </td>
                                        <td>
                                            {val.seller_refund_accept == 3 ? (
                                                <p>
                                                    {`Your 'Mark As Received' Request Updated To Admin.`}
                                                    .
                                                </p>
                                            ) : val.seller_refund_accept == 4 ? (
                                                <p style={{ color: 'red' }}>
                                                    Admin Refund Amount To Buyer.
                                                </p>
                                            ) : val.seller_refund_accept == 5 ? (
                                                <p>Your Return Request Updated To Admin.</p>
                                            ) : val.seller_refund_accept == 6 ? (
                                                <p style={{ color: 'green' }}>
                                                    Admin Released Partial Amount To You And Buyer.
                                                </p>
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex mt-3 pagination-wrapper">
                            <div className="ml-auto page-showing">
                                {t('showing')} {search.perlimit * (search.page - 1) + 1} -{' '}
                                {search.perlimit * search.page > referralList.length
                                    ? referralList.length
                                    : search.perlimit * search.page}{' '}
                                of {referralList.length}
                            </div>
                            <Pagination
                                count={Math.ceil(referralList.length / search.perlimit)}
                                page={search.page}
                                onChange={onHandlePage}
                            />
                        </div>
                    </div>
                    <Popup
                        open={open}
                        handleClose={(e) => setOpen(false)}
                        size="md"
                        className="save-search-modal"
                        modaltitle="Refund"
                    >
                        <div className="row justify-content-center">
                            {mapData(fields)}
                            <div className="col-4">
                                <PrimaryButton
                                    label={loading ? 'Loading...' : 'Submit'}
                                    className="mbv-btn"
                                    onClick={formik.handleSubmit}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </Popup>
                </div>
            ) : (
                <NoRecordsFound />
            )}
        </div>
    )
}

export default ReferralEarned
