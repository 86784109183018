/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory } from 'react-router-dom'
import {
    Button,
    Popover,
    ListItem,
    Divider,
    SwipeableDrawer,
    MenuItem,
    Menu,
} from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import { useMediaQuery } from 'react-responsive'
import { Logo } from '../../../utils/index'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'
const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const { user, isAuthenticated, logout } = useContext(AuthContext)
    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
    })

    const [listingAnchor, setListingAnchor] = useState(null)
    const listingOpen = Boolean(listingAnchor)

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const handleListingClick = (event) => {
        setListingAnchor(event.currentTarget)
    }

    const handleListingClose = () => {
        setListingAnchor(null)
    }

    const logoutUser = () => {
        logout()
        history.push('/login')
        window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const [langanchorEl, setlanganchorEl] = React.useState(null)

    const handleLangClick = (event) => {
        setlanganchorEl(event.currentTarget)
    }

    const handleLangClose = () => {
        setlanganchorEl(null)
    }

    return (
        <>
            {isDesktop ? (
                /* DESKTOP NAVIGATION */
                <header className="mainHeader noPrint">
                    <div className="customContainer d-flex justify-content-center align-items-center h-100 row">
                        <div className="col-md-4">
                            <HeaderSearch />
                        </div>
                        <div className="col-md-4 text-center">
                            <Logo className="headerLogo" />
                        </div>
                        <div className="col-md-4">
                            <div className="headRt d-flex justify-content-end align-items-center">
                                <ul className="d-flex justify-content-start align-items-center headerNavLinks">
                                    <li>
                                        <NavLink activeClassName="active" exact to="/auctions">
                                            Auctions
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" exact to="/contact_us">
                                            Contact Us
                                        </NavLink>
                                    </li>

                                    <Headernavmenu type="desk" />

                                    {isAuthenticated ? (
                                        <>
                                            <li>
                                                <CartIcon />
                                            </li>
                                            <li>
                                                <Button
                                                    className="actnBtn"
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleMenu}
                                                >
                                                    My Account
                                                    <span className="material-icons">
                                                        account_circle
                                                    </span>
                                                </Button>

                                                <Popover
                                                    id={'simple-menu'}
                                                    open={Boolean(anchormenu)}
                                                    anchorEl={anchormenu}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul className="headerDropMenu">
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    `${
                                                                        user && user.role == 1
                                                                            ? 'sellerDashboard'
                                                                            : 'dashboard/mybids'
                                                                    }`,
                                                                )
                                                            }
                                                        >
                                                            Dashboard
                                                        </ListItem>
                                                        {/* <ListItem
                                                    button
                                                    onClick={() => handleClose(history, 'cart')}
                                                >
                                                    Cart
                                                </ListItem> */}

                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/profile',
                                                                )
                                                            }
                                                        >
                                                            Profile
                                                        </ListItem>
                                                        <ListItem button onClick={logoutUser}>
                                                            Logout
                                                        </ListItem>
                                                    </ul>
                                                </Popover>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li>
                                                <SecondaryButton
                                                    onClick={() =>
                                                        handleRedirectInternal(history, 'login')
                                                    }
                                                    label="Login"
                                                />
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader mobHeader customContainer noPrint d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Logo className="headerLogo" />
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('top', true)}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>

                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={() => handleRedirectInternal(history, 'cart')}
                                    >
                                        <span className="material-icons">shopping_cart</span>
                                    </Button>
                                </li>

                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <HeaderSearch />
                        </SwipeableDrawer>
                    </React.Fragment>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/" exact>
                                            <span class="material-icons">home</span> Home
                                        </NavLink>
                                    </ListItem>

                                    {isAuthenticated && (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mybids"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    My Bids
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/profile"
                                                >
                                                    <span className="material-icons">person</span>
                                                    Profile
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/favorites"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    Favorites
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    Saved Cards
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            <span className="material-icons">groups</span>
                                            About Us
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/faq">
                                            <span className="material-icons">quiz</span>
                                            FAQs
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            <span className="material-icons">description</span>
                                            Terms & Conditions
                                        </NavLink>
                                    </ListItem>

                                    <Headernavmenu type="mobile" />

                                    {!isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/login">
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/sign-up">
                                                    <span className="material-icons">
                                                        how_to_reg
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <ListItem button onClick={logoutUser}>
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            Logout
                                        </ListItem>
                                    )}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
        </>
    )
}

export default React.memo(Header)
