import React from 'react'
import Skeletons from '../../Skeletons'

function TableSkeleton() {
    return (
        <div className="tableSkeleton">
            <table className="table">
                <thead>
                    <tr>
                        {[...Array(7)].map((data, index) => (
                            <th key={index}>
                                <Skeletons type="tableCell" />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {[...Array(5)].map((data, index) => (
                        <tr key={index}>
                            {[...Array(7)].map((d, i) => (
                                <td key={i}>
                                    <Skeletons type="tableCell" />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TableSkeleton
