import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import AuctionSearchPage from '../../../utils/CommonFunctionality/AuctionSearchPage'

const Auctions = (props) => {
    return (
        <Layout props={props}>
            <AuctionSearchPage resultsPerPage={1} auctions={1} viewType={1} auctionType={1} />
        </Layout>
    )
}

export default Auctions
