import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { useEffect } from 'react'
import DashboardLayout from '../DashboardLayout'
import ProfileForm from '../../../Component/ProfileForm'
import Layout from '../Layout'
import { useTranslation } from 'react-i18next'

const Profile = (props) => {
    const { t } = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout props={props}>
            <DashboardLayout title={t('my_account')}>
                <Container className="dashboard-wrapper">
                    <Grid container spacing={3}>
                        <ProfileForm
                            theme="artauction"
                            paypal_suc={props?.match?.params?.paypal ? 1 : 0}
                            redirect_url={'dashboard/mylots?moveto="live"'}
                        />
                    </Grid>
                </Container>
            </DashboardLayout>
        </Layout>
    )
}

export default Profile
