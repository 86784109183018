import React, { useContext, useEffect, useState } from 'react'
import Layout from '../Layout'
import UserContext from '../../../Product/context/user/userContext'
import './Static.css'
import { useLocation, useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../Product/common/components'
const Static = (props) => {
    const userContext = useContext(UserContext)
    const { emailverification, responseStatus } = userContext
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    const history = useHistory()
    const urlString = location.search.split('=')
    const getFunction = () => {
        setLoading(true)
        emailverification(urlString[1])
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from == 'user_emailverification') {
                if (responseStatus?.status == 'success') {
                    setLoading(false)
                    handleRedirectInternal(history, 'login')
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        getFunction()
    }, [])

    return (
        <Layout>
            <div className="stcPgWrpr">
                {loading ? (
                    <h1 style={{ textAlign: 'center' }}>
                        Your Verification Are Inprogress.Please Wait....
                    </h1>
                ) : (
                    <h1 style={{ textAlign: 'center' }}>
                        Your Email Verification Successfully Completed.
                    </h1>
                )}
            </div>
        </Layout>
    )
}

export default Static
