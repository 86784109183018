import React from 'react'
import './Registration.css'
import Layout from '../Layout'
import SignupFunction from '../../../utils/CommonFunctionality/SignupFunction'
import SEO from '../../../utils/CommonFunctionality/SEO'

const Registration = (props) => {
    return (
        <Layout>
            <SEO title={`${global?.storeDetails?.name} | Signup`} />

            <div className="clearfix row regCnt">
                <div className="col-12">
                    <div className="loginFormBox">
                        <SignupFunction
                            title="Signup"
                            subTitle="Create your free account today"
                            regpro={props}
                            // multipleUserRole={true}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Registration
