import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import * as Yup from 'yup'
import Popup from '../../../Product/components/organisms/Popup'
import { mapData } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useContext } from 'react'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import alertContext from '../../../Product/context/alert/alertContext'
const RequestRefund = ({ invoice_id, trigger, setTrigger, refund_request_notes }) => {
    const { requestRefund, requestRefund_seller, responseStatus, clearResponse } =
        useContext(pluginContext)
    const { setAlert } = useContext(alertContext)
    const [openDispute, setOpenDispute] = useState(false)
    const [loading, setLoading] = useState(false)
    const validationSchema = Yup.object({
        return_notes: Yup.string().required('Reason is required!'),
    })
    const formik = useFormik({
        initialValues: {
            invoice_no: invoice_id,
            return_notes: '',
        },
        validationSchema,
        onSubmit: (values) => {
            requestRefund_seller(values)
            setLoading(true)
        },
    })

    useEffect(() => {
        if (refund_request_notes) {
            formik.setFieldValue('return_notes', refund_request_notes)
        } else {
            formik.setFieldValue('return_notes', '')
        }
    }, [refund_request_notes])

    const fields = [
        {
            label: 'Reason',
            placeholder: 'Enter Your Reason Here...',
            type: 'textarea',
            class: 'col-12 ',
            name: 'return_notes',
            disabled: refund_request_notes ? true : false,
            formik,
        },
    ]
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'requestRefund_seller') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, responseStatus.status)
                    formik.resetForm()
                    setOpenDispute(false)
                    setTrigger(!trigger)
                } else {
                    setAlert(responseStatus.message, responseStatus.status)
                }
            }
        }
        clearResponse()
    }, [responseStatus])
    return (
        <div>
            <SecondaryButton
                label={refund_request_notes ? 'View Request' : 'Request'}
                onClick={() => setOpenDispute(true)}
            />

            <Popup
                open={openDispute}
                handleClose={(e) => setOpenDispute(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Request Admin"
            >
                <div className="row justify-content-center">
                    {mapData(fields)}
                    {!refund_request_notes ? (
                        <div className="col-4">
                            <PrimaryButton
                                label={loading ? 'Loading...' : 'Submit'}
                                className="mbv-btn"
                                onClick={formik.handleSubmit}
                                disabled={loading}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Popup>
        </div>
    )
}

export default RequestRefund
