import React, { useContext } from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import MyBidsPanel from '../../../utils/CommonFunctionality/MyBidsPanel'
import StripeCustomerContext from '../../../Product/context/stripe/customer/customerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useEffect } from 'react'
const MyBids = (props) => {
    const authContext = useContext(AuthContext)

    const { user, loadUser, isAuthenticated } = authContext
    const {
        createStripeCustomer,
        addCustomerProfile,
        responseStatus: responseStatusStripeCustomer,
    } = useContext(StripeCustomerContext)

    useEffect(() => {
        if (user && !user.card_paymentid) {
            createStripeCustomer({
                account_id: process.env.REACT_APP_AUCTIONPAYID,
                description: 'TEST CUSTOMER',
                email: user.email,
                name: user.first_name + ' ' + user.last_name,
            })
        }
    }, [user])

    useEffect(() => {
        if (responseStatusStripeCustomer) {
            if (responseStatusStripeCustomer.status === 'success') {
                if (responseStatusStripeCustomer.from === 'create') {
                    //console.log('responseStatusStripeCustomer', responseStatusStripeCustomer)
                    addCustomerProfile({
                        customer_id: responseStatusStripeCustomer.data.id,
                    })
                }
                loadUser()
                // setIsLoading(false)
            }
        }
    }, [responseStatusStripeCustomer])

    return (
        <Layout props={props}>
            <DashboardLayout title="MY BIDS">
                <MyBidsPanel />
            </DashboardLayout>
        </Layout>
    )
}

export default MyBids
