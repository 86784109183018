import Skyflow from 'skyflow-js'
import axios from 'axios'
import { SkyflowElements, LogLevel, Env } from 'skyflow-react-js'

export const skyflowConfig = {
    vaultID: process.env.REACT_APP_SKYFLOW_VAULT_ID
        ? process.env.REACT_APP_SKYFLOW_VAULT_ID
        : 'b427787ffcfa416faa1a5e15152d8616',

    vaultURL: process.env.REACT_APP_SKYFLOW_VAULT_URL
        ? process.env.REACT_APP_SKYFLOW_VAULT_URL
        : 'https://e3540c874ee5.vault.skyflowapis.com',
    getBearerToken: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}` + 'auctionpay/generateSkyflowToken',
                )

                resolve(res.data.accessToken)
            } catch (err) {
                reject('Cannot get token')
            }
        })
    },
    options: {
        logLevel: LogLevel.DEBUG,
        env: Env.DEV,
    },
}

const skyflowClient = Skyflow.init({
    vaultID: process.env.REACT_APP_SKYFLOW_VAULT_ID,
    vaultURL: process.env.REACT_APP_SKYFLOW_VAULT_URL,
    getBearerToken: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}` + 'auctionpay/generateSkyflowToken',
                )
                // console.log(res)
                resolve(res.data.accessToken)
            } catch (err) {
                // console.log(err)
                reject('Cannot get token')
            }
        })
    },
    options: {
        logLevel: Skyflow.LogLevel.ERROR,
        env: Skyflow.Env.DEV,
    },
})

export default skyflowClient
