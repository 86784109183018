import React from 'react'
import Header from '../Header'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { handleRedirectInternal } from '../../../Product/common/components'
import Scheduler from '../../../Component/Scheduler'
const ChooseDay = ({ pickupData }) => {
    const history = useHistory()
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [exist, setExist] = useState(
        typeof pickupData === 'object' && Object.keys(pickupData).length ? true : false,
    )
    return (
        <>
            <Header />
            <div className="chooseDay">
                <div className="chooseDayPop text-center">
                    <h2 className="mb-4">Choose a day and time</h2>
                    <div className="date">
                        <div className="calendar">
                            <Scheduler
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                selectedTime={selectedTime}
                                setSelectedTime={setSelectedTime}
                                pickupData={pickupData}
                            />
                            {/* <div className="d-flex align-items-center justify-content-between">
                                <h5 className="d-flex align-items-center mb-0">
                                    September 2023
                                    <span className="material-icons">expand_more</span>
                                </h5>
                                <div className="d-flex align-items-center pagin">
                                    <div>
                                        <span className="material-icons">chevron_left</span>
                                    </div>
                                    <div>
                                        <span className="material-icons">chevron_right</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="form-group mt-2 text-center">
                        <button
                            type="submit"
                            className="pink-btn w-100 mt-4 chooseBtn"
                            style={{ maxWidth: '300px' }}
                            onClick={() => handleRedirectInternal(history, 'pickupSummary')}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChooseDay
