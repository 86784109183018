import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import { useHistory, NavLink, Link } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import SearchProductList from '../../../Component/ProductCard/product'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import moment from 'moment'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import { useFormik } from 'formik'
import { Box, Button, ListItem, Tab, Tabs, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import UserContext from '../../../Product/context/user/userContext'
import * as Yup from 'yup'
import Loaders from '../../../Product/components/molecules/Loaders'
import { useMediaQuery } from 'react-responsive'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `home-auctions-${index}`,
        'aria-controls': `home-auctions-tabpanel-${index}`,
    }
}

const Home = (props) => {
    const productContext = useContext(ProductContext)
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)

    const {
        search_allauctionproducts,
        search_allproducts,
        search_allauctions,
        getLotList,
        getAllSearchAuctions,
        getAllSearchProducts,
        setSearchValue,
    } = productContext
    const { send_contactus, responseStatus } = userContext
    const { isAuthenticated, user, loadUser } = authContext

    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })

    const history = useHistory()
    const [auctionView, setAuctionView] = useState('Grid')
    const [isLoading, setLoading] = useState(true)
    const [upLoad, setUpLoad] = useState(true)
    const [initialLoad, setInitialLoad] = useState(false)
    const [viewAuction, setViewAuction] = useState([])
    const [viewProdLoad, setViewProLoad] = useState(true)
    const [viewAuctionUpcoming, setViewAuctionUpcoming] = useState([])
    const [viewProduct, setViewProduct] = useState([])
    const [subLotData, setSubLotData] = useState([])
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    const [closedLoad, setClosedLoad] = useState(true)
    const [viewClosedAuction, setViewClosedAuction] = useState([])
    const [listview, setlistview] = useState(false)

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: 2,
        lot_images: 1,
        auction: 1,
    })

    const [auctionUpcomingSearch, setAuctionUpcomingSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: 1,
        lot_images: 1,
        auction: 1,
        future_auction: 1,
    })

    const [productSearch, setProductSearch] = useState({
        sh_limit: 12,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })

    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        orderby: '2',
        lotof: '',
        limit: 50,
        market_status: 'open',
    })

    const [closedSearch] = useState({
        page: 1,
        sh_limit: '50',
        orderby: '1',
        searchbar: '',
        type: 'closed',
        lot_images: 1,
        auction: 1,
    })

    // useEffect(() => {
    //     if (search_allproducts.from === 'galleryPage') {
    //         console.log('search_allproducts', search_allproducts)
    //         setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
    //         setLoading(false)
    //     }
    // }, [search_allproducts])
    useEffect(() => {
        if (search_allproducts.from === 'searchPage') {
            setInitialLoad(false)
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setViewProLoad(false)
        }
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        setProductSearch({ ...productSearch, page: value })
        setAuctionSearch({ ...search, page: value })
    }
    const imagenamerepace = (data) => {
        data.file_name = data.avatar
        return data
    }

    useEffect(() => {
        getAllSearchProducts(productSearch, 'galleryPage')
    }, [productSearch])

    useEffect(() => {
        getAllSearchAuctions(auctionUpcomingSearch, 'upcomingHome')
    }, [auctionUpcomingSearch])

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'activeHome')
    }, [auctionSearch])

    useEffect(() => {
        getAllSearchAuctions(closedSearch, 'closedHome')
    }, [closedSearch])

    useEffect(() => {
        if (search_allauctions.from === 'closedHome') {
            setViewClosedAuction(
                search_allauctions.results.length ? search_allauctions.results : [],
            )
            setClosedLoad(false)
        }
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        if (search_allauctions.from === 'upcomingHome') {
            setViewAuctionUpcoming(
                search_allauctions.results.length ? search_allauctions.results : [],
            )
            setUpLoad(false)
        }
    }, [search_allauctions])

    useEffect(() => {
        setViewAuction([])
        setViewClosedAuction([])
        setViewAuctionUpcoming([])
        return () => {
            setViewAuction([])
            setViewClosedAuction([])
            setViewAuctionUpcoming([])
        }
    }, [])

    useEffect(() => {
        if (viewAuction.length) {
            setSubLotSearch({ ...subLotSearch, lotof: viewAuction[0].id })
        }
    }, [viewAuction])

    // useEffect(() => {
    //     setLoading(true)
    //     getAllSearchAuctions(closedSearch)
    // }, [closedSearch])

    useEffect(() => {
        if (subLotSearch.lotof) {
            getLotList(subLotSearch, 'homePageAuction')
        }
    }, [subLotSearch, isAuthenticated])

    useEffect(() => {
        if (search_allauctionproducts.from === 'homePageAuction') {
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
            // setLoading(false)
        }
    }, [search_allauctionproducts])

    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const formik = useFormik({
        initialValues: {
            sh_limit: 10,
            page: 1,
            orderby: '6',
            category: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            from: 'auction_only',
            closed: 0,
        },
    })

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global?.searchCategories?.filter((main) => {
                return true
            }),
            formik: formik,
        },
        {
            title: 'Manufacturer',
            type: 'check',
            noLabel: true,
            name: 'closed_auction_list',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'Live Items' },
                { id: 1, description: 'Closed Items' },
            ],
            formik: formik,
        },
        // {
        //     title: 'Price',
        //     type: 'check',
        //     name: 'Price',
        //     options: global?.searchSubCategories?.filter((subcat) => {
        //         if (
        //             formik &&
        //             formik.values &&
        //             formik.values.category &&
        //             formik.values.category.length
        //                 ? formik.values.category.includes(subcat.level_id.toString())
        //                 : false
        //         ) {
        //             return true
        //         }
        //     }),
        //     formik: formik,
        // },
        // {
        //     title: 'Auction Type',
        //     type: 'check',
        //     name: 'Auction_type',
        //     options: global?.searchSubCategories?.filter((subcat) => {
        //         if (
        //             formik &&
        //             formik.values &&
        //             formik.values.category &&
        //             formik.values.category.length
        //                 ? formik.values.category.includes(subcat.level_id.toString())
        //                 : false
        //         ) {
        //             return true
        //         }
        //     }),
        //     formik: formik,
        // },
    ]

    const validationArray = Yup.object({
        name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'Special characters not allowed')
            .max(15, 'Maximum 15 characters')
            .required('Required'),

        from_email: Yup.string().email('Invalid email format').required('Required'),
        phone_number: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .required('Required'),
        subject: Yup.string().required('Required'),
        messages: Yup.string().required('Required'),
    })

    const contactFormik = useFormik({
        initialValues: {
            name: '',
            last_name: '',
            from_email: '',
            phone_number: '',
            subject: '',
            messages: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            send_contactus(values)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'send_contactus') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        loadUser()
    }, [])

    const contactInfo = {
        formik: contactFormik,
        data: [
            {
                label: 'Name',
                name: 'name',
                type: 'text',
                placeholder: 'Enter your full name',
                class: 'col-12',
            },

            {
                label: 'Email address',
                name: 'from_email',
                type: 'text',
                placeholder: 'Enter your email address',
                class: 'col-12',
            },
            {
                label: 'Phone number',
                name: 'phone_number',
                type: 'phone',
                placeholder: 'Enter your phone number',
                class: 'col-12',
            },
            {
                label: 'Subject',
                name: 'subject',
                type: 'text',
                placeholder: 'Enter your subject',
                class: 'col-12',
            },
            {
                label: 'Message',
                name: 'messages',
                type: 'textarea',
                placeholder: 'Enter your message',
                class: 'col-12',
            },
        ],
    }

    useEffect(() => {
        loadUser()
    }, [])

    useEffect(() => {
        if (user) {
            contactFormik.values.name = user?.first_name + ' ' + user?.last_name || ''
            contactFormik.values.phone_number = user?.phone_number || ''
            contactFormik.values.from_email = user?.email || ''
            contactFormik.values.subject = 'General Enquiry'
        }
    }, [user])

    useEffect(() => {
        let catValue = new URLSearchParams(window.location.search.substring(1))

        if (initialLoad) {
            if (formik.values.closed_auction_list) {
                formik.values.orderby = '7'
            } else {
                formik.values.orderby = '6'
            }
            // setLoading(true)
            getAllSearchProducts(formik.values, 'searchPage')

            history.push(
                `/search?cat=${formik.values.category.toString()}&q=${
                    catValue.get('q') ? catValue.get('q') : ''
                }`,
            )

            formik.setFieldValue('searchbar', catValue.get('q') ? catValue.get('q') : '')
        } else {
            setInitialLoad(true)
        }
    }, [formik.values])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
    }, [window.location.search])

    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClick = (catId) => {
        handleRedirectInternal(history, `search?cat=${catId}`)
    }

    const [state, setState] = React.useState({
        bottom: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const list = (anchor) => (
        <div
            className=""
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="filterPanel">
                <IconButton className="expIconBtn" onClick={toggleDrawer(anchor, false)}>
                    <span className="material-icons">expand_more</span>
                </IconButton>
                <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                    <h4 className="fpTitle">Categories</h4>
                    {props.clearSearch && (
                        <Button onClick={() => props.clearSearch()} className="filterClear">
                            <span className="material-icons">cancel</span>
                            Clear all
                        </Button>
                    )}
                </div>
                <div className="filterAcc">
                    <FilterPanel formik={formik} filterValues={filterValues} />
                </div>
            </div>
        </div>
    )

    return (
        <Layout props={props}>
            <div className="home">
                <div className="siteBanner">
                    <img
                        src={global?.storeDetails?.bannerValue}
                        alt={`${global?.storeDetails?.name} banner`}
                    />
                    <div className="bannerContent">
                        <h2>{global?.storeDetails?.description}</h2>
                    </div>
                </div>
                <div className="customContainer auctionListCnt">
                    <h2 className="auctionListTitle">Live Auctions</h2>
                    <div className="homeCnt">
                        <div className="homeLt">
                            <FilterPanel
                                formik={formik}
                                filterValues={filterValues.filter((ele) => {
                                    if (ele.type === 'check') {
                                        if (ele.options && !!ele.options.length) {
                                            return ele
                                        }
                                    } else {
                                        return ele
                                    }
                                })}
                            />
                        </div>

                        {/* HomePage Categories Drawer */}

                        <React.Fragment>
                            <Button
                                className="toggleRespDrawer"
                                variant="outlined"
                                onClick={toggleDrawer('bottom', true)}
                            >
                                <span className="material-icons">tune</span>
                                Filters
                            </Button>
                            <SwipeableDrawer
                                className="responsiveFilterDrawer"
                                anchor={'bottom'}
                                open={state['bottom']}
                                onClose={toggleDrawer('bottom', false)}
                                onOpen={toggleDrawer('bottom', true)}
                            >
                                {list('bottom')}
                            </SwipeableDrawer>
                        </React.Fragment>

                        {/* HomePage Categories Drawer end */}
                        <div className="homeRt">
                            {isLoading ? (
                                <div className="homeLoader">
                                    {isDesktop ? (
                                        <Loaders
                                            name="product_list_view"
                                            isLoading={isLoading}
                                            loop={1}
                                        />
                                    ) : (
                                        <Loaders
                                            name="product_grid_view"
                                            isLoading={isLoading}
                                            loop={1}
                                        />
                                    )}
                                </div>
                            ) : viewAuction.length > 0 ? (
                                <>
                                    <>
                                        <div
                                            className={`homeAuctions ${!isDesktop && 'mobileView'}`}
                                        >
                                            {viewAuction.map((data, index) =>
                                                isDesktop ? (
                                                    <MultisellerListCard
                                                        key={index}
                                                        data={data}
                                                        allsearchitems={viewAuction}
                                                        lotData={subLotData}
                                                        details={`search/product-buyer-auction/${data.id}`}
                                                        theme="HeavyEquipment"
                                                    />
                                                ) : (
                                                    <GridAuction
                                                        key={index}
                                                        data={data}
                                                        details={`search/product-buyer-auction/${data.id}`}
                                                        timerTheme=""
                                                        // lotCount={data.lotCount ? data.lotCount : 0}
                                                    />
                                                ),
                                            )}
                                        </div>
                                        <Link to="/auctions" className="viewAll">
                                            View More
                                            <span className="material-icons">
                                                keyboard_double_arrow_right
                                            </span>
                                        </Link>
                                    </>
                                </>
                            ) : (
                                <NoRecordsFound />
                            )}
                        </div>
                    </div>
                </div>
                <div className="homeContactRequest">
                    <div className="lftReqstCnt">
                        <h3>Recycling & Repurposing Equipment & Assets</h3>
                        <p>
                            We are an asset management, product life cycle and logistics management
                            company that specializes in maximizing asset values and managing product
                            life cycles, EOL and sunset legacy management.
                        </p>
                        <PrimaryButton onClick={() => handleRedirectInternal(history, details)}>
                            Check our Auction Listings
                        </PrimaryButton>
                    </div>
                    <div className="rtReqFrm">
                        <h4>Request for a Call Back</h4>
                        <form onSubmit={contactFormik.handleSubmit}>
                            <div className="row">{mapData(contactInfo)}</div>
                            <PrimaryButton type="submit">Submit Request</PrimaryButton>
                        </form>
                    </div>
                </div>
                <div className="customContainer auctionListCnt">
                    <h2 className="auctionListTitle">Upcoming Auctions</h2>
                    <div className={`lotResults ${auctionView}`}>
                        {upLoad ? (
                            <div className="homeLoader">
                                {isDesktop && (
                                    <Loaders name="product_grid_view" isLoading={upLoad} loop={1} />
                                )}
                            </div>
                        ) : viewAuctionUpcoming.length > 0 ? (
                            <>
                                <div className="aucGridCnt">
                                    {viewAuctionUpcoming.map((data, index) => (
                                        <GridAuction
                                            key={index}
                                            data={imagenamerepace(data)}
                                            details={`search/product-buyer-auction/${data.id}?${data.description}`}
                                            theme="HeavyEquipment"
                                            //noviewbutton={1}
                                        />
                                    ))}
                                </div>
                                {/* <Link to="/auctions/upcomming" className="viewAll">
                                    View More
                                    <span className="material-icons">
                                        keyboard_double_arrow_right
                                    </span>
                                </Link> */}
                            </>
                        ) : (
                            <NoRecordsFound />
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Home
