import React from 'react'
import Subscription from '../../../utils/CommonFunctionality/Subscription'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import { useTranslation } from 'react-i18next'

const ResellerSubscription = (props) => {
    const { t } = useTranslation()
    return (
        <div>
            <Layout props={props}>
                <DashboardLayout title={t('subscription')}>
                    <Subscription />
                </DashboardLayout>
            </Layout>
        </div>
    )
}

export default ResellerSubscription
