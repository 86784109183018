import React, { useEffect, useState, useContext } from 'react'
import './gallery.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import Layout from '../Layout'
import Loaders from '../../../Product/components/molecules/Loaders'
import Facebook from '../../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../../utils/CommonFunctionality/Share/Pinterest'
import { Pagination } from '@material-ui/lab'
const GalleryComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])

    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)

    const { search_allproducts, getAllSearchProducts } = productContext

    const { isAuthenticated } = authContext

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalViewProduct, setTotalViewProduct] = useState(0)
    const [productSearch, setProductSearch] = useState({
        sh_limit: 12,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })

    useEffect(() => {
        getAllSearchProducts(productSearch, 'galleryPage')
    }, [productSearch, isAuthenticated])

    useEffect(() => {
        if (search_allproducts.from === 'galleryPage') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setTotalViewProduct(search_allproducts.total_pagecnt)
        }
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        setProductSearch({ ...productSearch, page: value })
    }

    return (
        <Layout props={props}>
            <div className="container mt-5">
                {isLoading ? (
                    <Loaders name="product_grid_view" isLoading={isLoading} loop={4} />
                ) : viewProduct.length > 0 ? (
                    <>
                        <div className="gallery-share ">
                            <h2 className="buyNowTitle">All Items</h2>
                            <div className="pvMiscActions">
                                <Facebook path={`/gallery`} title={'Buy Now Items'} />
                                <Whatsapp path={`/gallery`} title={'Buy Now Items'} />
                                <Twitter path={`/gallery`} title={'Buy Now Items'} />
                                <Email
                                    path={`/gallery`}
                                    subject={'Buy Now Items'}
                                    body={'Buy Now Items'}
                                />
                                <Pinterest
                                    path={`/gallery`}
                                    title={'Buy Now Items'}
                                    media={`${global?.storeDetails?.bannerValue}`}
                                />
                            </div>
                        </div>
                        <SearchProductList
                            listview={listview}
                            searchResult={viewProduct}
                            className="liquidationBuyCard"
                            //type="buynow"
                            timerTheme="HeavyEquipment"
                            cardTheme="multiseller"
                            sliderTheme="multiseller"
                            noTimer={true}
                            storeTheme={'Heavyequipment'}
                            base_url={'gallery'}
                        />
                        {totalViewProduct > productSearch.sh_limit ? (
                            <Pagination
                                count={Math.ceil(totalViewProduct / productSearch.sh_limit)}
                                page={productSearch.page}
                                onChange={onHandlePage}
                            />
                        ) : null}
                    </>
                ) : (
                    <NoRecordsFound />
                )}
                {/* <SimilarList /> */}
            </div>
        </Layout>
    )
}

export default GalleryComponent
