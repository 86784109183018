import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { currencyFormat, handleRedirectInternal } from '../../Product/common/components'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import alertContext from '../../Product/context/alert/alertContext'
import authContext from '../../Product/context/auth/authContext'
import payContext from '../../Product/context/payment/payContext'
import cardContext from '../../Product/context/stripe/card/cardContext'

const AdditionalPayment = ({ item, closePopup, clearSelectedItem, trigger, setTrigger }) => {
    const { get_all_card_details, get_skyflow_cards } = useContext(cardContext)
    const { user } = useContext(authContext)
    const { successAfterPayAuctionPartial, responseStatus, clearResponse } = useContext(payContext)
    const { setAlert } = useContext(alertContext)
    const [allData, setAllData] = useState([])
    const [cardData, setCardData] = useState('')
    const [loading, setLoading] = useState(false)
    let history = useHistory()
    const onChange = (e) => {
        e.target.checked ? setCardData(e.target.value) : setCardData('')
    }
    useEffect(() => {
        if (global.storeConfigration?.skyflow_integration?.value == 1) {
            if (get_skyflow_cards) {
                setAllData(get_skyflow_cards)
            }
        } else {
            if (get_all_card_details) {
                setAllData(get_all_card_details.records)
            }
        }
    }, [get_all_card_details, get_skyflow_cards])
    useEffect(() => {
        if (allData.length > 0) {
            setCardData(allData[0].id)
        }
    }, [allData])
    const handlePayment = () => {
        if (!cardData) {
            return setAlert('Kindly Select A Card!', 'warning')
        }
        const body = {
            partial_pay: parseFloat(item.ship_offer),
            user_id: user?.id,
            partial_paytype: 'card payment',
            invoice_id: [item.common_invoice],
            payment_details: {
                source: cardData,
                customer: user.card_paymentid,
                currency: user?.country == 38 ? 'cad' : 'usd',
                description: `Additional Amount Invoice #${item.common_invoice}`,
            },
        }
        successAfterPayAuctionPartial(body)
        setLoading(true)
    }
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'successAfterPayAuctionPartial') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    setAlert('Payment Successful!', 'success')
                    if (setTrigger) {
                        setTrigger(!trigger)
                    }
                    if (closePopup) {
                        closePopup()
                    }
                    if (clearSelectedItem) {
                        clearSelectedItem()
                    }
                }
                clearResponse()
            }
        }
    }, [responseStatus])
    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <img
                        className="img-fluid"
                        src={`${
                            item?.content_head1 === '0' || item?.store_id === 0
                                ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                                : process.env.REACT_APP_PRODUCT_IMAGE_URL
                        }${item.avatar}`}
                    />
                </div>
                <div className="col-md-6">
                    <p>
                        <strong>Title:</strong> {item.title}
                    </p>
                    <p>
                        <strong>Invoice Id:</strong> {item.common_invoice}
                    </p>
                    <p>
                        <strong>Additional Amount Need To Pay:</strong>{' '}
                        {currencyFormat(item.ship_offer)}
                    </p>
                    {allData.map((data, index) => (
                        <div key={index} className="cardSelect">
                            <label htmlFor={`ritemb${index}`} className="cardmain">
                                <div className="flx">
                                    <p>{'xxxx-xxxx-xxxx-' + data.last4}</p>
                                    <div className="radio-item">
                                        <input
                                            type="checkbox"
                                            id={`ritemb${index}`}
                                            name="ritem"
                                            value={data.id}
                                            checked={cardData === data.id}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="flx">
                                    <p>{data.brand}</p>
                                    <p>
                                        {data.exp_month}/{data.exp_year}
                                    </p>
                                </div>
                            </label>
                        </div>
                    ))}
                    {allData.length > 0 ? (
                        <SecondaryButton
                            label={`Pay ${currencyFormat(item.ship_offer)}`}
                            className="mbv-btn"
                            onClick={handlePayment}
                            disabled={loading}
                        />
                    ) : (
                        <SecondaryButton
                            label={`Kinldy Add A Card To Continue!`}
                            className="mbv-btn"
                            onClick={() => handleRedirectInternal(history, 'dashboard/cards')}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default AdditionalPayment
