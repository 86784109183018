import React, { useContext, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormHelperText } from '@material-ui/core'

const Captch = (props) => {
    const handleChange = (value, data) => {
        if (value) {
            props.formik.setFieldValue([props.name], true)
        } else {
            props.formik.setFieldValue([props.name], false)
        }
    }
    return (
        <>
            <ReCAPTCHA
                sitekey={'6LeZRT4gAAAAAL3S09zPVGbApKfoPHLK-PmLWZbT'}
                onChange={handleChange}
                onErrored={() => handleChange(false)}
                onExpired={() => handleChange(false)}
            />
            <FormHelperText error>
                {props.formik.touched[props.name] && props.formik.errors[props.name]}
            </FormHelperText>
        </>
    )
}
export default Captch

// const Captch = () => {
//     return (
//         <>
//             <input type="text" value={captcha} />
//             <br></br>
//             <input type="text" />
//             <button onClick={Cap}>Refresh</button>
//         </>
//     )
// }
// export default Captch
