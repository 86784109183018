import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AuthContext from '../../../Product/context/auth/authContext'

import UserContext from '../../../Product/context/user/userContext'
import { mapData, handleRedirectInternal, jsonToFormData } from '../../../Product/common/components'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import CustomDialog from '../../../Product/components/organisms/Dialog'

const BecomeSeller = ({ type, open, setOpen }) => {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)

    const { user, loadUser, isAuthenticated } = authContext

    const { editProfile, responseStatus } = userContext
    const history = useHistory()
    const [loading, setLoading] = useState(false)

    const toggleOpen = () => {
        setOpen(!open)
    }
    const validationArray = Yup.object({
        card_profileid: Yup.string().trim().required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            country: '',
            state: '',
            city: '',
            address1: '',
            address2: '',
            zip: '',
            role: 1,
            notification: '0',
            category_email: '0',
            card_profileid: '',
            buyer_document: '',
            auction_io: 1,
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            values.first_name = user.first_name
            values.last_name = user.last_name
            values.email = user.email
            values.phone = user.phone
            values.country = user.country
            values.state = user.state
            values.city = user.city
            values.address1 = user.address1
            values.address2 = user.address2
            values.zip = user.zip
            values.buyer_document = JSON.parse(user.taxfile_org).buyer
            //console.log(values, 'AAAA')
            editProfile(values)
            setLoading(true)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'success') {
                if (responseStatus.from === 'editProfile') {
                    loadUser()
                    setOpen(false)
                }
            }
        }
    }, [responseStatus])

    const field = [
        {
            label: 'Account Id',
            placeholder: 'Enter your account id',
            class: `col-12 col-sm-12`,
            type: 'text',
            name: 'card_profileid',
            additionalText: (
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                        process.env.REACT_APP_AUCTIONPAY_OTHER_URL.includes('auctionsoftware.com')
                            ? 'https://payment.auctionsoftware.com/login'
                            : 'https://paymantgateway.io/login'
                    }
                >
                    {"Don't"} have an account ID ? Click Here
                </a>
            ),
            formik: formik,
        },
    ]
    //console.log(formik.values.card_profileid)
    return (
        <>
            {type === 'popup' ? (
                <CustomDialog title={'Become A Seller'} open={open} function={toggleOpen}>
                    <form className="pp-form" onSubmit={formik.handleSubmit}>
                        <div>
                            <div className="row">{mapData(field)}</div>
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </CustomDialog>
            ) : (
                <form className="pp-form" onSubmit={formik.handleSubmit}>
                    <div>
                        <div className="row">{mapData(field)}</div>
                        <PrimaryButton type="submit" label="Submit" />
                    </div>
                </form>
            )}
        </>
    )
}

export default BecomeSeller
