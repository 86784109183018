import React, { useEffect, useState, useContext } from 'react'
import './gallery.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import Layout from '../Layout'

const GalleryComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])

    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)

    const { search_allproducts, getAllSearchProducts } = productContext

    const { isAuthenticated } = authContext

    const [loading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)

    const [productSearch, setProductSearch] = useState({
        sh_limit: 12,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })

    useEffect(() => {
        getAllSearchProducts(productSearch, 'galleryPage')
    }, [productSearch, isAuthenticated])

    useEffect(() => {
        setLoading(false)
        if (search_allproducts.from === 'galleryPage') {
            //console.log('search_allproducts', search_allproducts)
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
        }
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        setProductSearch({ ...productSearch, page: value })
    }

    //console.log('viewProduct', viewProduct)
    return (
        <Layout props={props}>
            <div className="container mt-5">
                {loading ? (
                    <div className="row"></div>
                ) : viewProduct.length === 0 ? (
                    <NoRecordsFound />
                ) : (
                    <>
                        <h2 className="buyNowTitle">Buy Now Items</h2>
                        <SearchProductList
                            listview={listview}
                            searchResult={viewProduct}
                            className="liquidationBuyCard"
                            type="buynow"
                            timerTheme="multiseller"
                            cardTheme="multiseller"
                            sliderTheme="multiseller"
                        />
                    </>
                )}
                {/* <SimilarList /> */}
            </div>
        </Layout>
    )
}

export default GalleryComponent
