import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import DashboardLayout from '../DashboardLayout'
import FavouriteAuctions from '../../../Component/FavouriteList/FavouriteAuctions'
import Layout from '../Layout'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const Favourites = (props) => {
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Layout props={props}>
            <DashboardLayout title="Favorites">
                <div className="mt-4">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="buyer-dashboard"
                        className="mt-4 liquidationDashTabs"
                    >
                        <Tab label="Auctions" {...a11yProps(0)} />
                        <Tab label="Buy Now" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <FavouriteAuctions
                            page="auction"
                            timerTheme=""
                            sliderTheme="multiseller"
                            cardTheme="HeavyEquipment"
                            bidBtnLabel="Place Bid"
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <FavouriteAuctions
                            page="buynow"
                            timerTheme=""
                            sliderTheme="multiseller"
                            cardTheme="HeavyEquipment"
                            bidBtnLabel="Place Bid"
                        />
                    </TabPanel>
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default Favourites
