import React from 'react'
import Layout from '../Layout'
import './PostProduct.css'
import PostProductComponent from '../../../utils/CommonFunctionality/PostProduct'
import DashboardLayout from '../DashboardLayout'

import { useTranslation } from 'react-i18next'

const PostProduct = (props) => {
    const { t } = useTranslation()
    let multiSectionNames = [
        t('fornt_page'),
        t('white_background'),
        t('side_image'),
        t('closeup_image'),
        // 'Side Image',
    ]

    const allCondition = [
        {
            id: 1,
            description: 'Mint',
            active: 1,
            ratingWeight: 0,
        },
        {
            id: 2,
            description: 'Near Mint',
            active: 1,
            ratingWeight: 0,
        },
        {
            id: 3,
            description: 'Excellent',
            active: 1,
            ratingWeight: 0,
        },
        {
            id: 4,
            description: 'Very Good',
            active: 1,
            ratingWeight: 0,
        },
        {
            id: 5,
            description: 'Good',
            active: 1,
            ratingWeight: 10,
        },
        {
            id: 6,
            description: 'Poor',
            active: 1,
            ratingWeight: 0,
        },
        {
            id: 7,
            description: 'Mixed',
            active: 1,
            ratingWeight: 0,
        },
    ]
    return (
        <Layout props={props}>
            <DashboardLayout title={t('post_a_product')}>
                <div>
                    <PostProductComponent
                        withoutAuction={1}
                        // itemLocation={1}
                        addressSection={1}
                        buynow={1}
                        go_live_btn_name={'Next'}
                        draft_btn_name={'Save Draft & Pay later'}
                        // defaultCountryCode={38}
                        extraSection={0}
                        foodIndustryFields={0}
                        theme="FoodIndustry"
                        customLabel={1}
                        taxFree={0}
                        videoUploader={1}
                        noLocalPickup={1}
                        pickupDetails={1}
                        externalshipingOnly={1}
                        freeShipping={1}
                        allformatimagenotneed={1}
                        collectablethemefield={1}
                        // multiSectionImage={multiSectionNames}
                        shipLabel={1}
                        artauctionfields={0}
                        categoryNotRequired={1}
                        hideSubCategory={1}
                        customCondition={allCondition}
                        skuNotRequired={1}
                        showOverallError={1}
                        Need_more_details={1}
                        need_to_disable_start_and_endate={1}
                        customBtnLabel={'Post Live'}
                        set_start_and_enddate={1}
                        compress_image={1}
                    />
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default PostProduct
