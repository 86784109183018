import React from 'react'
import { Chip } from '@material-ui/core'
import moment from 'moment'
const UserCard = (props) => {
    return (
        <div
            className={`userCard ${props.id === props.selected_id ? 'active' : ''}`}
            onClick={props.onClick}
        >
            <div className="prflWrpr">{props.f_name.charAt(0) + props.l_name.charAt(0)}</div>
            <div className="msgInfo">
                <div className="d-flex align-items-center justify-content-between mb-1">
                    <p className="m-0 username">{props.f_name + ' ' + props.l_name}</p>
                    <p className="m-0">
                        <small>
                            {moment(props.time).isValid()
                                ? moment(props.time).format('D MMM h:mm a')
                                : props.time}
                        </small>
                    </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    {/* <p className="m-0 rctnMsgTxt">
                        <small>{props.msg}</small>
                    </p> */}
                    {props.msg_count && props.id != props.selected_id ? (
                        <Chip className="msgCnt" label={props.msg_count} size="small" />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default UserCard
