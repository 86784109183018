import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../Product/common/components'
import { commonPaths } from '../../commonFunctions'
// import AllLocations from './Location'
// import AllReaders from './Reader'
import UnpaidInvoices from './UnpaidInvoices'
import PosStock from './PosStock'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'

import PosLocation from './Location'
import PosReaders from './Readers'
import './Pos.css'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const CommonPos = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const [open, setOpen] = useState({
        location: false,
        readers: false,
    })

    const handleOpen = (target) => {
        setOpen({ ...open, [target]: true })
    }

    const handleClose = (target) => {
        setOpen({ ...open, [target]: false })
    }

    return (
        <div className="posContainer">
            <div className="posMiscAct">
                <Button
                    variant="outlined"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => handleOpen('location')}
                >
                    <span className="material-icons">fmd_good</span>
                    Location Settings
                </Button>
                <Button
                    variant="outlined"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => handleOpen('readers')}
                >
                    <span className="material-icons">barcode_reader</span>
                    Reader Settings
                </Button>
            </div>

            <PosStock />

            <Dialog
                fullScreen
                className="posModal"
                open={open.location}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Locations
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleClose('location')}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="customContainer">
                    <PosLocation />
                </div>
            </Dialog>

            <Dialog
                fullScreen
                className="posModal"
                open={open.readers}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Readers
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleClose('readers')}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="customContainer">
                    <PosReaders />
                </div>
            </Dialog>
        </div>
    )
}

export default CommonPos
