import React, { useEffect } from 'react'
import './pagination.css'
import Pagination from 'react-js-pagination'

const PaginationComponent = (props) => {
    return props.totalProductCount <= props.resultToShow ? null : (
        <Pagination
            activePage={props.currentPage}
            itemsCountPerPage={props.resultToShow}
            totalItemsCount={props.totalProductCount}
            pageRangeDisplayed={3}
            itemClass="page-item"
            linkClass="page-link"
            onChange={props.onPageChange}
        />
        // <ul className="pagination">
        //   {
        //     ([
        //       ...Array(
        //         Math.ceil(props.totalProductCount / props.resultToShow)
        //       ).keys(),
        //     ].map = (_, index) => {
        //       let number = index + 1;
        //       let selectedPage = props.currentPage === number;
        //       if (
        //         number == 1 ||
        //         number == Math.ceil(props.totalProductCount / props.resultToShow) ||
        //         (number >= props.currentPage - 2 && number <= props.currentPage + 2)
        //       ) {
        //         return (
        //           <li
        //             key={number}
        //             name={number}
        //             onClick={props.onPageChange}
        //             className={selectedPage ? "page-item active" : "page-item"}
        //             style={{
        //               pointerEvents: selectedPage ? "none" : "auto",
        //               cursor: selectedPage ? "default" : "pointer",
        //             }}
        //           >
        //             <a key={number} className="page-link">
        //               {number}
        //             </a>
        //           </li>
        //         );
        //       }
        //     })
        //   }
        //   {/* <li className="page-item active"><a className="page-link" href="#">1</a></li>
        //      	<li className="page-item"><a className="page-link" href="#">2</a></li>
        //      	<li className="page-item"><a className="page-link" href="#">3</a></li>
        //      	<li className="page-item"><a className="page-link" href="#">4</a></li>
        //      	<li className="page-item"><a className="page-link" href="#">...</a></li>
        //      	<li className="page-item"><a className="page-link" href="#">99</a></li>  */}
        // </ul>
    )
}

export default PaginationComponent
