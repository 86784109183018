import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useHistory, NavLink } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import SearchProductList from '../../../Component/ProductCard/product'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import moment from 'moment'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import { useFormik } from 'formik'
import { Box, Button, ListItem, Tab, Tabs, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Loaders from '../../../Product/components/molecules/Loaders'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import jsonp from 'jsonp'
import AlertContext from '../../../Product/context/alert/alertContext'
import queryString from 'query-string'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import { messageHandler } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Slider from 'react-slick'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import { getAllMake, getModelsOfMake } from '../../../utils/CarConstants'
import HeaderSearchComponent from '../Header/HeaderSearch'
import FullScreenPopup from '../../../Product/components/organisms/FullScreenPopup'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `home-auctions-${index}`,
        'aria-controls': `home-auctions-tabpanel-${index}`,
    }
}

const Home = (props) => {
    const { user } = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allproducts, getAllSearchProducts } =
        productContext
    const { setAlert } = alertContext

    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    const history = useHistory()
    const [loading, setLoading] = useState(true)

    const [viewProduct, setViewProduct] = useState([])
    const [buyNowProduct, setBuyNowProduct] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [value, setValue] = useState(0)
    const [inpData, setinpData] = useState('')
    const [totalItems, setTotalItem] = useState(0)

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        orderby: '2',
        lotof: '',
        limit: 12,
        market_status: 'open',
        auction_io: 1,
        from: 'auction_only',
    })

    const [buynowSearch, setBuynowSearch] = useState({
        sh_limit: 12,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })

    const homeSearchFormik = useFormik({
        initialValues: {
            make: '',
            model: '',
        },
        onSubmit: (values) => {
            if (value.make != '') {
                handleRedirectInternal(history, `search?cat=${values.make}`)
            } else {
                handleRedirectInternal(history, 'search')
            }
        },
    })

    useEffect(() => {
        // if (value === 0) {
        //     setSubLotSearch({ ...subLotSearch, type: '', auc_type: 2 })
        // }
        // if (value === 1) {
        //     setSubLotSearch({ ...subLotSearch, type: '', auc_type: 1 })
        // }
        // if (value === 2) {
        //     setSubLotSearch({ ...subLotSearch, type: 'closed', auc_type: '' })
        // }
        setSubLotSearch({ ...subLotSearch, type: 'active', auc_type: '2' })
        setBuynowSearch({ ...buynowSearch, type: 'active' })
    }, [value])

    useEffect(() => {
        setLoading(true)
        getAllSearchProducts(subLotSearch, 'activeHome')
        getAllSearchProducts(buynowSearch, 'galleryPage')
    }, [subLotSearch, buynowSearch])

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    useEffect(() => {
        setLoading(false)
        if (search_allproducts.from === 'activeHome') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setTotalItem(search_allproducts.total_pagecnt)
        } else if (search_allproducts.from === 'galleryPage') {
            setBuyNowProduct(search_allproducts.results.length ? search_allproducts.results : [])
        }
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        setBuyNowProduct([])
        return () => {
            setViewProduct([])
            setBuyNowProduct([])
        }
    }, [])

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories.filter((main) => {
                return true
            }),
        },
    ]

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const [state, setState] = React.useState({
        bottom: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//${global.storeConfigration?.subscribe_code.value}${queryString.stringify(data)}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }

    const settings = {
        dots: false,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }

    const vehicleMake = [
        {
            value: 'Mercedez',
            show: 'Mercedez benz',
        },
        {
            value: 'BMW',
            show: 'BMW',
        },
        {
            value: 'Audi',
            show: 'Audi',
        },
    ]

    return (
        <Layout props={props}>
            <div className="home">
                <div
                    className={`siteBanner ${
                        global.multipleBannersFields.length ? 'multiple' : ''
                    }`}
                >
                    {global.multipleBannersFields.length ? (
                        <>
                            <Slider {...settings}>
                                {global.multipleBannersFields?.map((data, index) => (
                                    <>
                                        <img
                                            src={
                                                process.env.REACT_APP_AUCTION_IMAGE_URL +
                                                '/' +
                                                data?.image_name
                                            }
                                        />
                                        <div className="bannerContent">
                                            <h2 className="">
                                                {JSON.parse(data.fields ? data.fields : '{}').title}
                                            </h2>
                                            <p className="pb-4">
                                                {
                                                    JSON.parse(data.fields ? data.fields : '{}')
                                                        .description
                                                }
                                            </p>
                                            {JSON.parse(data.fields ? data.fields : '{}').btn_text
                                                ?.length > 0 && (
                                                <PrimaryButton
                                                    label={
                                                        isAuthenticated
                                                            ? JSON.parse(
                                                                  data.fields ? data.fields : '{}',
                                                              ).btn_text
                                                            : 'Sign Up'
                                                    }
                                                    type="button"
                                                    onClick={() =>
                                                        !isAuthenticated
                                                            ? handleRedirectInternal(
                                                                  history,
                                                                  'sign-up',
                                                              )
                                                            : (window.location.href = JSON.parse(
                                                                  global.multipleBannersFields[
                                                                      index
                                                                  ].fields
                                                                      ? global
                                                                            .multipleBannersFields[
                                                                            index
                                                                        ].fields
                                                                      : '{}',
                                                              ).btn_link)
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                ))}
                            </Slider>
                        </>
                    ) : (
                        <>
                            <LazyLoadImage
                                src={global?.storeDetails?.bannerValue}
                                alt={`${global?.storeDetails?.name} banner`}
                                effect="blur"
                                height="100%"
                                width="100%"
                            />
                            <div className="bannerContent">
                                <h2>{global?.storeDetails?.description}</h2>
                                <PrimaryButton
                                    label="Explore Now"
                                    onClick={() =>
                                        handleRedirectInternal(
                                            history,
                                            `${isAuthenticated ? 'search' : 'sign-up'}`,
                                        )
                                    }
                                />
                            </div>
                        </>
                    )}
                    <div className="srchComponent">
                        <label className="mnLabel">Find Your Vehicle</label>
                        <div className="slctWrprVlNw">
                            <HeaderSearchComponent make label />
                        </div>
                        {/* <form onSubmit={homeSearchFormik.handleSubmit}>
                            <div className="slctWrprVl">
                                <HeaderSearchComponent make />
                                <div className="slctGrop">
                                    <label>Make</label>
                                    <CustomSelect
                                        name="make"
                                        selectType="noBorder"
                                        value={homeSearchFormik.values.make}
                                        onChange={homeSearchFormik.handleChange}
                                    >
                                        <option value="">Select a make</option>
                                        {getAllMake().map((opt, optindex) => (
                                            <option value={opt.id}>{opt.value}</option>
                                        ))}
                                    </CustomSelect>
                                </div>
                                <div className="slctGrop">
                                    <label>Model</label>
                                    <CustomSelect
                                        name="model"
                                        selectType="noBorder"
                                        value={homeSearchFormik.values.model}
                                        onChange={homeSearchFormik.handleChange}
                                    >
                                        <option value="">Select a model</option>
                                        {homeSearchFormik.values.make && (
                                            <>
                                                {getModelsOfMake(homeSearchFormik.values.make).map(
                                                    (opt, optindex) => (
                                                        <option value={opt.id}>{opt.value}</option>
                                                    ),
                                                )}
                                            </>
                                        )}
                                    </CustomSelect>
                                </div>
                                <PrimaryButton type="submit" label="Search" />
                            </div>
                        </form> */}
                    </div>
                </div>
                <div className="customContainer auctionListCnt">
                    <div className="homeCnt">
                        <>
                            <h2 className="auctionListTitle">Active Lots</h2>

                            {loading ? (
                                <div className="homeLoader">
                                    <Loaders
                                        name="product_grid_view"
                                        isLoading={loading}
                                        loop={6}
                                    />
                                </div>
                            ) : viewProduct.filter(
                                  (data) => data.market_status == 'open' && data.remainingDay >= 0,
                              ).length === 0 ? (
                                <NoRecordsFound />
                            ) : (
                                <div className="homeAuctions mobileView liquidationBuyCard">
                                    <>
                                        {viewProduct.map((data, index) => (
                                            <GridProduct
                                                data={data}
                                                key={index}
                                                type="auction"
                                                selectedProduct={selectedProduct}
                                                link={`/gallery/product-view/${data.id}`}
                                                handleClick={handleClick}
                                                timerTheme=""
                                                sliderTheme="multiseller"
                                                cardTheme="carAuction"
                                                bidBtnLabel="Place Bid"
                                            />
                                        ))}
                                    </>
                                </div>
                            )}
                            <div
                                className="viewAllBtn"
                                onClick={() => handleRedirectInternal(history, `search`)}
                            >
                                <hr />
                                <Button>View All</Button>
                                <hr />
                            </div>
                        </>

                        <>
                            <h2 className="auctionListTitle">Buy Now Products</h2>

                            {loading ? (
                                <div className="homeLoader">
                                    <Loaders
                                        name="product_grid_view"
                                        isLoading={loading}
                                        loop={6}
                                    />
                                </div>
                            ) : buyNowProduct.length === 0 ? (
                                <NoRecordsFound />
                            ) : (
                                <div className="homeAuctions mobileView liquidationBuyCard">
                                    <>
                                        {/* <SearchProductList
                                            listview={false}
                                            searchResult={buyNowProduct}
                                            className="multiBuyCard"
                                            type="buynow"
                                            noTimer={true}
                                            cardTheme="carAuction"
                                            noBuyNow={false}
                                        /> */}
                                        {buyNowProduct.map((data, index) => (
                                            <GridProduct
                                                data={data}
                                                key={index}
                                                type="buynow"
                                                selectedProduct={selectedProduct}
                                                link={`/gallery/product-view/${data.id}`}
                                                handleClick={handleClick}
                                                timerTheme=""
                                                sliderTheme="multiseller"
                                                noTimer={true}
                                                cardTheme="carAuction"
                                                bidBtnLabel="Place Bid"
                                            />
                                        ))}
                                    </>
                                </div>
                            )}
                            <div
                                className="viewAllBtn"
                                onClick={() => handleRedirectInternal(history, `gallery`)}
                            >
                                <hr />
                                <Button>View All</Button>
                                <hr />
                            </div>
                        </>
                    </div>
                </div>

                {global?.storeConfigration?.subscribe?.value && (
                    <div className="homeContact">
                        <h2>Get the latest deals</h2>
                        <p>Subscribe us to get latest updates</p>
                        <div className="subsBox">
                            <input
                                type="text"
                                name="q"
                                value={inpData}
                                placeholder="Enter email address"
                                onChange={(e) => {
                                    setinpData(e.target.value)
                                }}
                            />
                            <Button>
                                <span className="material-icons" onClick={subscribe}>
                                    send
                                </span>
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <MultisellerSlider
                selectedLot={selectedProduct}
                handleClose={handleClose}
                currency="cad"
                type={props.type ? props.type : ''}
            />
        </Layout>
    )
}

export default Home
