import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './Auctions.css'
import { Button } from '@material-ui/core'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import Loader from '../../../assets/loader'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Pagination } from '@material-ui/lab'

const Auctions = (props) => {
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions } = productContext
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [auctionView, setAuctionView] = useState('Grid')
    const { search } = useLocation()

    const type = new URLSearchParams(search)
    const params = new URLSearchParams(window.location.search.substring(1))
    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: 9,
        order: 1,
        searchbar: params.get('q') ? params.get('q') : '',
        auc_type: type.get('closed') ? '3' : '2',
        is_auction: 1,
        type: type.get('closed') ? 'closed' : '',
        //is_buynow: 1,
    })
    const [totalViewProduct, setTotalViewProduct] = useState(0)
    useEffect(() => {
        setLoading(true)
        getAllSearchAuctions(auctionSearch, 'auctionSearch')
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'auctionSearch') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
            setTotalViewProduct(search_allauctions.total_pagecnt)
            // setTimeout(() => {
            setLoading(false)
            // }, 1500)
        }
    }, [search_allauctions])

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'All Events',
        },
        {
            value: 1,
            show: 'Upcoming Events',
        },
        {
            value: 2,
            show: 'Live Events',
        },
        {
            value: 3,
            show: 'Closed Events',
        },
    ]

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        var search_data = params.get('q') ? params.get('q') : ''
        if (auctionSearch.searchbar != search_data) {
            setAuctionSearch({
                ...auctionSearch,
                searchbar: params.get('q') ? params.get('q') : '',
            })
        }
    }, [window.location.search])

    return (
        <Layout props={props}>
            <div className="customContainer auctionsSearch mb-5">
                <div className="d-flex justify-content-between align-items-center flex-wrap my-5 auctionSearchCnt">
                    <h2 className="auctionListTitle">Active Events</h2>
                    <div className="auctionsMiscFilter">
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => setAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => setAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={auctionSearch.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                setAuctionSearch({ ...auctionSearch, sh_limit: event.target.value })
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        <CustomSelect
                            label="Event Type"
                            value={auctionSearch.auc_type}
                            size="small"
                            selectType="noBorder"
                            name="auc_type"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                if (event.target.value == '3') {
                                    setAuctionSearch({
                                        ...auctionSearch,
                                        auc_type: event.target.value,
                                        type: 'closed',
                                    })
                                } else if (event.target.value == '') {
                                    setAuctionSearch({
                                        ...auctionSearch,
                                        auc_type: event.target.value,
                                        type: 'all',
                                        orderby: 1,
                                    })
                                } else {
                                    setAuctionSearch({
                                        ...auctionSearch,
                                        auc_type: event.target.value,
                                        type: '',
                                    })
                                }
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    </div>
                </div>
                <>
                    {loading ? (
                        <div className="text-center">
                            <Loader />
                        </div>
                    ) : viewAuction.length === 0 ? (
                        <NoRecordsFound />
                    ) : (
                        <div className={`searchResults ${auctionView}`}>
                            {viewAuction.map((data, index) =>
                                auctionView === 'Grid' ? (
                                    <GridAuction
                                        noActionBtn
                                        key={index}
                                        data={data}
                                        details={`search/product-buyer-auction/${data.id}`}
                                        // lotCount={data.lotCount ? data.lotCount : 0}
                                    />
                                ) : (
                                    <MultisellerListCard
                                        key={index}
                                        data={data}
                                        allsearchitems={viewAuction}
                                        // lotData={data.lotCount ? data.lotCount : 0}
                                        details={`search/product-buyer-auction/${data.id}`}
                                    />
                                ),
                            )}
                        </div>
                    )}
                </>
                {totalViewProduct > auctionSearch.sh_limit && (
                    <Pagination
                        count={Math.ceil(totalViewProduct / auctionSearch.sh_limit)}
                        page={auctionSearch.page}
                        onChange={(e, v) => setAuctionSearch({ ...auctionSearch, page: v })}
                    />
                )}
            </div>
        </Layout>
    )
}

export default Auctions
