import React, { useState, useEffect, useContext } from 'react'
import Layout from '../Layout'
import Loaders from '../../../Product/components/molecules/Loaders'
import PaymentContext from '../../../Product/context/payment/payContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import Popup from '../../../Product/components/organisms/Popup'
const Paypalsuccess = (props) => {
    const [loaders, setLoaders] = useState(true)
    const [open, setOpen] = useState(false)
    const token = new URLSearchParams(window.location.search.substring(1)).get('token')
    const PayerID = new URLSearchParams(window.location.search.substring(1)).get('PayerID')
    const [auction_type, setAuctiontype] = useState('auction')
    const {
        successAfterPaypaypalupdate,
        successAfterPaypaypalupdatebuynow,
        clearResponse,
        responseStatus: responseStatusPayment,
    } = useContext(PaymentContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    var payment_det = JSON.parse(localStorage.getItem('paymentdetails'))
    useEffect(() => {
        if (token) {
            payment_det.payment_details = { token: token, PayerID: PayerID }
            if (payment_det?.auction_type == 'auction') {
                successAfterPaypaypalupdate(payment_det)
            }
            if (payment_det?.auction_type == 'buynow') {
                successAfterPaypaypalupdatebuynow(payment_det)
            }
        }
    }, [token])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'successAfterPaypalupdate') {
                localStorage.removeItem('paymentdetails')
                if (responseStatusPayment.status === 'success') {
                    setLoaders(false)
                    setOpen(true)
                    setAuctiontype('auction')
                } else {
                    setAlert(responseStatusPayment.message, 'error')
                }
            }
            if (responseStatusPayment.from === 'successAfterPaypalupdatebuynow') {
                localStorage.removeItem('paymentdetails')
                if (responseStatusPayment.status === 'success') {
                    setLoaders(false)
                    setOpen(true)
                    setAuctiontype('buynow')
                } else {
                    setAlert(responseStatusPayment.message, 'error')
                }
            }
        }
        clearResponse()
    }, [responseStatusPayment])

    const handleClose = () => {
        setOpen(false)
        clearResponse()
        if (auction_type == 'auction') {
            window.location.href = window.location.href.includes(global?.storeDetails?.id)
                ? `/${global?.storeDetails?.id}/dashboard/mybids?s=1`
                : '/dashboard/mybids?s=1'
        }
        if (auction_type == 'buynow') {
            window.location.href = window.location.href.includes(global?.storeDetails?.id)
                ? `/${global?.storeDetails?.id}/dashboard/mybids?s=3`
                : '/dashboard/mybids?s=4'
        }
    }

    return (
        <Layout props={props}>
            {/* <Loaders isLoading={loaders}></Loaders> */}
            {loaders ? (
                <h6 style={{ fontsize: '32px', textAlign: 'center' }}>
                    Paypal Proccesing payment.......
                </h6>
            ) : (
                ''
            )}
            <Popup
                open={open}
                size="md"
                className="save-search-modal"
                handleClose={handleClose}
                modaltitle="Order Successful"
            >
                <div className="order-success-wrap orPlcdSs text-center">
                    <img src="/image/check_circle.svg" alt="" />
                    <p className="os-text">Congratulations - You have purchased&nbsp;</p>
                </div>
            </Popup>
        </Layout>
    )
}

export default Paypalsuccess
