import React from 'react'
import Layout from '../Layout'
import DashboardLayout from '../DashboardLayout'
import Reports from '../../../utils/CommonFunctionality/Reports'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ReportsManagement = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { t } = useTranslation()
    return (
        <Layout props={props}>
            <DashboardLayout title={t('report_magement')}>
                <Reports />
            </DashboardLayout>
        </Layout>
    )
}

export default ReportsManagement
