import {
    AccountBalance,
    AccountBalanceWalletOutlined,
    AirlineSeatLegroomReducedTwoTone,
} from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../assets/loader'
import { currencyFormat } from '../../../Product/common/components'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import authContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import Popup from '../../../Product/components/organisms/Popup'
import { useFormik } from 'formik'
import { mapData } from '../../../Product/common/components'

const ReferralEarned = () => {
    const { t } = useTranslation()
    const { getDistputslist, getDistputslistReject, responseStatus } = useContext(pluginContext)
    const alertContext = useContext(AlertContext)
    const { user } = useContext(authContext)
    const { setAlert } = alertContext
    const [producttype, setProducttype] = useState({})
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [referralList, setReferralList] = useState([])
    const [totalrows, setTotalrows] = useState(0)
    const [search, setSearch] = useState({
        limit: 12,
        page: 1,
        site_id: global?.storeDetails?.site_id,
    })

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }
    useEffect(() => {
        if (user?.id) {
            setLoading(true)
            getDistputslist({ ...search, seller_id: user?.id })
        }
    }, [search, user])
    useEffect(() => {
        console.log(
            'ppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp',
            responseStatus,
        )
        if (responseStatus?.from == 'distputslist') {
            setLoading(false)
            setReferralList(responseStatus?.data?.product ? responseStatus.data.product : [])
            setTotalrows(
                parseInt(responseStatus?.data?.total_rows ? responseStatus.data.total_rows : 0),
            )
        }
        if (responseStatus?.from == 'distputslistcancel') {
            getDistputslist({ ...search, seller_id: user?.id })
            setAlert(responseStatus.message, responseStatus.status)
        }
    }, [responseStatus])

    // useEffect(() => {
    //     if (user && !search.user_id) {
    //         setSearch({ ...search, user_id: user?.id })
    //     }
    // }, [user])

    const formik = useFormik({
        initialValues: {
            refund_paytype: 'payment',
            partialamount: 0,
            invoice_id: '',
            lot_id: '',
            user_id: '',
        },
        onSubmit: async (values) => {
            formik.values.invoice_id = producttype.common_invoice
            formik.values.lot_id = producttype.project_id
            formik.values.user_id = user.id
            RefundlistAccepted(formik.values)
        },
    })

    const fields = [
        {
            title: 'Refund To',
            type: 'radio',
            //noLabel: true,
            name: 'refund_paytype',
            class: 'col-12',
            item: [
                { id: 'payment', description: 'Original Payment Method' },
                { id: 'credits', description: 'Store Credit' },
            ],
            formik: formik,
        },
        {
            title: 'Refund Type',
            type: 'radio',
            //noLabel: true,
            int: 1,
            name: 'partialamount',
            class: 'col-12',
            item: [
                { id: 0, description: 'Full Refund' },
                { id: 1, description: 'Partial Refund' },
            ],
            formik: formik,
        },
    ]

    return (
        <div className="text-center">
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : referralList.length ? (
                <div>
                    {/* <p className="d-inline float-left">
                        <AccountBalance /> {currencyFormat(total_earned)}
                    </p>
                    <p className="d-inline float-right">
                        <AccountBalanceWalletOutlined /> {currencyFormat(user?.wallet_amount)}
                    </p> */}

                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Lot Title</th>
                                    <th>Email</th>
                                    <th>Subject</th>
                                    <th>Message</th>
                                    <th>Date Added</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {referralList.map((val, key) => (
                                    <tr key={key}>
                                        <td>{val.title}</td>
                                        <td>{val.email}</td>
                                        <td>{val.subject}</td>
                                        <td>{val.message}</td>
                                        <td>
                                            {moment(val.date_sent).format('MM-DD-YYYY HH:MM:ss')}
                                        </td>

                                        {/* <td>
                                            <PrimaryButton
                                                btnSize="mt-3"
                                                onClick={() => {
                                                    getDistputslistReject({
                                                        id: val.id,
                                                    })
                                                }}
                                                label={'Delete'}
                                            ></PrimaryButton>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex mt-3 pagination-wrapper">
                            <div className="ml-auto page-showing">
                                {t('showing')} {search.limit * (search.page - 1) + 1} -{' '}
                                {search.limit * search.page > referralList.length
                                    ? referralList.length
                                    : search.limit * search.page}{' '}
                                of {referralList.length}
                            </div>
                            <Pagination
                                count={Math.ceil(referralList.length / search.limit)}
                                page={search.page}
                                onChange={onHandlePage}
                            />
                        </div>
                    </div>
                    <Popup
                        open={open}
                        handleClose={(e) => setOpen(false)}
                        size="md"
                        className="save-search-modal"
                        modaltitle="Refund"
                    >
                        <div className="row justify-content-center">
                            {mapData(fields)}
                            <div className="col-4">
                                <PrimaryButton
                                    label={loading ? 'Loading...' : 'Submit'}
                                    className="mbv-btn"
                                    onClick={formik.handleSubmit}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </Popup>
                </div>
            ) : (
                <NoRecordsFound />
            )}
        </div>
    )
}

export default ReferralEarned
