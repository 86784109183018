import React from 'react'

import Layout from '../Layout'
import LiveLots from '../../../utils/CommonFunctionality/LiveLots'

const LiveAuction = (props) => {
    return (
        <Layout props={props}>
            <div className="customContainer pt-3 auctionCnt">
                <LiveLots {...props} />
            </div>
        </Layout>
    )
}

export default LiveAuction
