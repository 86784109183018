import React from 'react'
import Layout from '../Layout'
import CartComponent from '../../../utils/CommonFunctionality/Cart'
import './Cart.css'
import SEO from '../../../utils/CommonFunctionality/SEO'

const Cart = (props) => {
    return (
        <Layout props={props}>
            <SEO title={`${global?.storeDetails?.name} | Cart`} />

            <div className="cartOuterWrapper">
                <CartComponent props={props} currency="cad" />
            </div>
        </Layout>
    )
}

export default Cart
