import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Redirect, useParams } from 'react-router-dom'
import Container from '@material-ui/core/Container'
// import Grid from '@material-ui/core/Grid';

import DashboardHeader from '../DashboardLayout'
import AllBids from './AllBids'
import Auctions from './Auctions'
import AuthContext from '../../../Product/context/auth/authContext'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
// import ActiveBids from '../../Components/DashboardSeller/ActiveBids';
// import Sold from '../../Components/DashboardSeller/Sold';
// import Draft from '../../Components/DashboardSeller/Draft';

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const SellerDashboard = (props) => {
    const authContext = useContext(AuthContext)
    const { isAuthenticated, isAdmin } = authContext
    const [toHome, settoHome] = useState(false)
    // useEffect(() => {
    //     if (!isAuthenticated || !isAdmin) {
    //         settoHome(true)
    //     }
    // }, [isAuthenticated])

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const { page } = useParams()
    useEffect(() => {
        if (props.location.search) {
            setValue(parseInt(props.location.search.split('?page=')[1]))
        }
    }, [props])

    return (
        <Layout>
            {toHome ? <Redirect to="/" /> : null}
            <DashboardLayout title="My Auctions" subtitle="Items" seller>
                <div className="db-bids-wrapper">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="buyer-dashboard"
                        className="mt-4 liquidationDashTabs"
                    >
                        <Tab label={'Active Lots'} {...a11yProps(0)} />
                        <Tab label={'Sold Lots'} {...a11yProps(1)} />
                        <Tab label={'Unsold Lots'} {...a11yProps(2)} />
                        <Tab label={'Auctions'} {...a11yProps(3)} />
                        <Tab label={'Draft'} {...a11yProps(4)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <AllBids page="open" />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <AllBids page="sold" />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <AllBids page="closed" />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Auctions />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <AllBids page="draft" />
                    </TabPanel>
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default SellerDashboard
