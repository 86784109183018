import React, { useState, useEffect, useContext } from 'react'
import './RenderMessage.css'
import moment from 'moment'
import { PhotoSwipeGallery } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import { dateFormatFront } from '../../../../Product/common/components'
import authContext from '../../../../Product/context/auth/authContext'
import productContext from '../../../../Product/context/product/productContext'
import SecondaryButton from '../../../../Product/components/atoms/SecondaryButton'

function RenderMessage(props) {
    const { data, reply, setReply, likes, setLikes } = props

    const { user } = useContext(authContext)
    const { productMessage, getProductMessages, messageLike } = useContext(productContext)

    const [message, setMessage] = useState('')
    const [user_id] = useState(user ? user?.id : 0)
    const [showReply, setShowReply] = useState(false)
    const [commentimages, setCommentImages] = useState([])
    const [individualCommentImg, setIndividualCommentImg] = useState(false)
    const [commentLoader, setCommentLoader] = useState(false)
    const [totalComments, setTotalComments] = useState([])
    const [isMessageError, setIsMessageError] = useState(false)

    const [comments, setComments] = useState({
        from: 0,
        data: [],
        to: 10,
        limit: 10,
        length: 0,
    })
    const commentImagePopup = () => {
        setIndividualCommentImg(false)
    }

    const commentImageContent = (item) => {
        return <img src={item.thumbnail} alt="slide omg" width={120} height={90} />
    }

    const getImages = (data) => {
        var commentImage = []

        {
            data.subject && data.subject.includes('[')
                ? JSON.parse(data.subject).map((value) =>
                      commentImage.push({
                          original: `${process.env.REACT_APP_BASE_URL}uploads/${value}`,
                          thumbnail: `${process.env.REACT_APP_BASE_URL}uploads/${value}`,
                          originalClass: 'popupSlider',
                          src: `${process.env.REACT_APP_BASE_URL}uploads/${value}`,
                          w: 1200,
                          h: 900,
                      }),
                  )
                : commentImage.push({
                      original: `${process.env.REACT_APP_BASE_URL}uploads/${data.subject}`,
                      thumbnail: `${process.env.REACT_APP_BASE_URL}uploads/${data.subject}`,
                      originalClass: 'popupSlider',
                      src: `${process.env.REACT_APP_BASE_URL}uploads/${data.subject}`,
                      w: 1200,
                      h: 900,
                  })
        }

        setCommentImages(commentImage)
    }

    const sendMessage = async () => {
        try {
            if (message === '') {
                setIsMessageError(true)
                return
            }

            setIsMessageError(false)

            const sendData = {
                from_id: user_id,
                to_id: '0',
                to_email: 'reply',
                message,
                pro_id: data.project_id || data.pro_id,
                type: 'product',
                reply_to: data.id,
            }

            const res = await productMessage(sendData)
            if (res) {
                setMessage('')
                return true
            }
            return false
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getImages(data)
    }, [data])

    const replyFunction = async () => {
        try {
            if (showReply) {
                return setShowReply(!showReply)
            }
            setCommentLoader(true)
            const res = await getProductMessages({
                reply: data.id,
                type: 'product',
            })
            if (res.success) {
                var reverse = res.data

                setComments({
                    ...comments,
                    length: reverse.length,
                    data: reverse,
                })
                setTotalComments(reverse)
            }
            setCommentLoader(false)
            setShowReply(!showReply)
        } catch (error) {
            setCommentLoader(false)
            console.log(error)
        }
    }
    const likeMessage = async (message_id) => {
        try {
            const res = await messageLike({
                id: message_id,
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (Object.keys(reply).length && reply?.messages?.reply_to == data.id) {
            setComments((prev) => ({ ...prev, data: [...prev.data, reply.messages] }))
            setTotalComments((prev) => [...prev, reply.messages])
            setReply({})
        }
    }, [reply])

    useEffect(() => {
        if (Object.keys(likes).length) {
            let temp_arr = comments.data
            temp_arr.map((val) => {
                if (val.id == likes.message_id) {
                    val.likes = likes.likes
                }
            })
            setComments({ ...comments, data: temp_arr })
            setLikes({})
        }
    }, [likes])

    return (
        <div className="bidAcordent">
            <div className="bid-time">
                <span>{data.username}</span>{' '}
                <span className="hisDateTime">{dateFormatFront(data.date_sent)}</span>
            </div>
            {data.to_email === 'bidhistory' ? (
                <div className="accorLabel">
                    <p className="comment-dtls">
                        {data.message.replace(data.from_id, data.username)}
                    </p>
                </div>
            ) : (
                <div className="post-by-seller">
                    <div className="post-seller-heading">
                        {/* {data.to_email === 'seller' && (
                            <p className="seller-heading">this is the seller </p>
                        )} */}
                        {/* <p className="seller-likes">
                            <i className="far fa-thumbs-up"></i>
                            <span className="thumb-count"> {data.thumbsUpCount} </span>
                        </p> */}
                    </div>
                    <div className="seller-content">
                        <p>{data.message}</p>

                        {data.subject && (
                            <>
                                <PhotoSwipeGallery
                                    // key={index}
                                    isOpen={individualCommentImg}
                                    items={commentimages}
                                    thumbnailContent={commentImageContent}
                                    onClose={commentImagePopup}
                                    id="CommentPhotos"
                                />
                            </>
                        )}
                    </div>
                    {showReply ? (
                        <div className="essentialBox">
                            {comments.data.map((val, key) => (
                                <div className="bidAcordent" key={key}>
                                    <div className="bid-time">
                                        <span>{val.username}</span>{' '}
                                        <span className="hisDateTime">
                                            {dateFormatFront(val.date_sent)}
                                        </span>
                                    </div>
                                    <div className="post-by-seller">
                                        <div className="seller-content">
                                            <p>{val.message}</p>
                                        </div>

                                        <div className="thumbs-up-two">
                                            <p
                                                className="second-thumb"
                                                onClick={() => (user ? likeMessage(val.id) : null)}
                                            >
                                                <i
                                                    className={`${
                                                        val.likes.includes(`${user_id}`)
                                                            ? 'fas'
                                                            : 'far'
                                                    } fa-thumbs-up`}
                                                ></i>{' '}
                                                <span>
                                                    {val.likes ? val.likes.split(',').length : 0}{' '}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="comment-area">
                                <textarea
                                    placeholder={'Add a comment here'}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    style={{ width: '100%', minHeight: '150px', padding: '10px' }}
                                    disabled={user ? false : true}
                                ></textarea>
                                {isMessageError ? (
                                    <p style={{ color: 'red', fontSize: '13px' }}>
                                        Please enter valid message
                                    </p>
                                ) : null}
                            </div>
                            <div className="submit-area justify-content-end">
                                <div className="submit-btn" style={{ maxWidth: '200px' }}>
                                    <SecondaryButton
                                        label={'Submit'}
                                        customClassName="productViewSecontdry"
                                        onClick={() => sendMessage(message)}
                                        disabled={user ? false : true}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className="thumbs-up-two">
                        <p
                            className="second-thumb"
                            onClick={() => (user ? likeMessage(data.id) : null)}
                        >
                            <i
                                className={`${
                                    data.likes.includes(`${user_id}`) ? 'fas' : 'far'
                                } fa-thumbs-up`}
                            ></i>{' '}
                            <span>{data.likes ? data.likes.split(',').length : 0} </span>
                        </p>
                        <p className="contrative-link">
                            <a href="#!" onClick={replyFunction}>
                                {showReply ? 'Hide' : commentLoader ? 'Loading...' : 'View Reply'}
                            </a>
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default RenderMessage
