import React from 'react'
import './Registration.css'
import Layout from '../Layout'
import SignupFunction from '../../../utils/CommonFunctionality/SignupFunction'

const Registration = (props) => {
    return (
        <Layout>
            <div className="clearfix row regCnt">
                <div className="col-12">
                    <div className="loginFormBox">
                        <SignupFunction
                            title="Sign up"
                            subTitle="Create your free account today"
                            // multipleUserRole
                            regpro={props}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Registration
