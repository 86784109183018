import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import ScrollToTop from '../../Routes/ScrollToTop'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
    },
}))

const VCModal = ({ open, handleClose, children, modaltitle, maxWidth, modalClass }) => {
    const classes = useStyles()
    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={open}
            className={classes.modal}
        >
            <div className={classes.paper} style={maxWidth}>
                {/* <ScrollToTop /> */}
                <div className={`ep-modal ${modalClass}`}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{modaltitle}</h4>
                                {handleClose && (
                                    <button type="button" className="close" onClick={handleClose}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                )}
                            </div>
                            <div className="modal-body cm-body">{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default VCModal
