import React, { useContext, useState, useEffect } from 'react'
import BioCard from './BioCard'
import UserContext from '../../../Product/context/user/userContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { CircularProgress } from '@material-ui/core'
import { useParams, Link, useHistory } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../../Product/context/auth/authContext'
import Seller from './Seller'
import {
    Button,
    ButtonBase,
    Drawer,
    SwipeableDrawer,
    FormControl,
    IconButton,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Menu,
    MenuList,
    MenuItem,
} from '@material-ui/core'
import Gallery from './Gallery'
import { useMediaQuery } from 'react-responsive'

const List = () => {
    const { t } = useTranslation()
    const { type } = useParams()
    const [searchbar, setSearchBar] = useState('')
    const [searchkeyword, setSearchKeyword] = useState('')
    const history = useHistory()
    const [sortopen, setSortOpen] = useState(false)
    const [orderbyproduct, setOrderProduct] = useState(6)
    const [orderbyartist, setOrderArtist] = useState(1)
    const { user } = useContext(AuthContext)

    const handleChange = (e) => {
        setSearchKeyword(e.target.value)
    }

    const sortHandleClick = (event) => {
        setSortOpen(event.currentTarget)
    }

    const sortHandleClose = (val) => {
        setOrderProduct(val)
        setSortOpen(null)
    }

    const sortHandleCloseartist = (val) => {
        setOrderArtist(val)
        setSortOpen(null)
    }

    const handleClick = (e) => {
        e.preventDefault()
        setSearchBar(searchkeyword)
    }

    const isArtTheme = document.querySelector('body').classList.contains('artTheme')

    useEffect(() => {
        setSearchKeyword('')
        setSearchBar('')
        setOrderArtist(1)
    }, [type])

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)',
    })

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]

    const sortshowartist = [
        {
            value: 1,
            show: 'Most Followed',
        },
        {
            value: 2,
            show: 'Name A-Z',
        },
        {
            value: 3,
            show: 'Name Z-A',
        },
    ]

    return (
        <div className="artstLst">
            <div className="customContainer">
                <div className="mb-3 mt-4 mr-auto ml-auto artTistGrisSys">
                    <div>
                        {isArtTheme && (
                            <div
                                className="goBackBTn"
                                onClick={() => {
                                    history.push('/')
                                    window.scrollTo(0, 0)
                                }}
                            >
                                <svg width="32" height="32" className="mr-2" viewBox="0 0 32 32">
                                    <g transform="translate(-60 -71)">
                                        <g
                                            transform="translate(60 71)"
                                            fill="none"
                                            stroke="#0a0a0a"
                                            strokeWidth="1"
                                        >
                                            <circle cx="16" cy="16" r="16" stroke="none" />
                                            <circle cx="16" cy="16" r="15.5" fill="none" />
                                        </g>
                                        <path
                                            d="M13.178,11.438a.664.664,0,0,1,.005.934L10.1,15.465H21.846a.66.66,0,0,1,0,1.32H10.1l3.087,3.092a.668.668,0,0,1-.005.934.657.657,0,0,1-.929-.005L8.07,16.592h0a.741.741,0,0,1-.137-.208.63.63,0,0,1-.051-.254.662.662,0,0,1,.188-.462l4.184-4.215A.647.647,0,0,1,13.178,11.438Z"
                                            transform="translate(61.048 70.873)"
                                        />
                                    </g>
                                </svg>
                                <u>Back To Home</u>
                            </div>
                        )}
                    </div>
                    <div className="artsTabs">
                        <ButtonBase>
                            <Link
                                button
                                to={'/seller/artist'}
                                style={{ color: 'white' }}
                                className="aTag"
                            >
                                <h4 className={`${type === 'artist' ? 'active' : ''} pgTtle`}>
                                    {t('artist')}
                                </h4>
                            </Link>
                        </ButtonBase>
                        <ButtonBase>
                            <Link
                                button
                                to={'/seller/gallery'}
                                style={{ color: 'white' }}
                                className="aTag"
                            >
                                <h4 className={`${type === 'gallery' ? 'active' : ''} pgTtle`}>
                                    {t('gallery')}
                                </h4>
                            </Link>
                        </ButtonBase>
                        <ButtonBase>
                            <Link
                                button
                                to={'/seller/reseller'}
                                style={{ color: 'white' }}
                                className="aTag"
                            >
                                <h4 className={`${type === 'reseller' ? 'active' : ''} pgTtle`}>
                                    {t('reseller')}
                                </h4>
                            </Link>
                        </ButtonBase>
                    </div>
                    <div></div>
                </div>
                <div className="SearchFilter">
                    <form onSubmit={handleClick} className="nav-standard-top-area-search">
                        <div className="nav-standard-top-area-search-inner">
                            <div className="artist-search artstSrchWrpr">
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    name="searchbar"
                                    value={searchkeyword}
                                    className="nav-standard-top-area-search-input open-sans"
                                    placeholder={'Search ' + type}
                                />
                                <button className="lin lin-magnifier nav-standard-top-area-search-sumbit">
                                    <span className="material-icons">search</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="filterBtns">
                        <div className="sortoption">
                            <Button
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={sortHandleClick}
                            >
                                {isMobile ? (
                                    <>
                                        <span className="material-icons">sort</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="material-icons mr-2">sort</span>
                                        Sort Feed By
                                        <span className="material-icons ml-2">
                                            keyboard_arrow_down
                                        </span>
                                    </>
                                )}
                            </Button>
                            {/* {type == 'gallery' ? (
                                <Menu
                                    id="simple-menu"
                                    anchorEl={sortopen}
                                    keepMounted
                                    open={Boolean(sortopen)}
                                    onClose={sortHandleClose}
                                    className="sortMenu"
                                >
                                    {sortShow.length > 0 &&
                                        sortShow.map((data, index) => (
                                            <MenuItem
                                                onClick={(e) => sortHandleClose(data.value)}
                                                value={data.value}
                                            >
                                                {data.show}
                                            </MenuItem>
                                        ))}
                                </Menu>
                            ) : ( */}
                            <Menu
                                id="simple-menu"
                                anchorEl={sortopen}
                                keepMounted
                                open={Boolean(sortopen)}
                                onClose={sortHandleCloseartist}
                                className="sortMenu"
                            >
                                {sortshowartist.length > 0 &&
                                    sortshowartist.map((data, index) => (
                                        <MenuItem
                                            onClick={(e) => sortHandleCloseartist(data.value)}
                                            value={data.value}
                                        >
                                            {data.show}
                                        </MenuItem>
                                    ))}
                            </Menu>
                            {/* )} */}
                        </div>
                        {/* <div className="filterOption ml-4">
                            <Button variant="contained" color="primary">
                                {isMobile ? (
                                    <>
                                        <span className="material-icons">filter_list</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="material-icons mr-2">filter_list</span>
                                        Filter Search
                                    </>
                                )}
                            </Button>
                        </div> */}
                    </div>
                </div>
                {/* {type == 'gallery' ? (
                    <>
                        {sortShow.length > 0 && typeof orderbyproduct == 'number' ? (
                            <h6>
                                {`Sort By "${
                                    sortShow.filter((val) => val.value == orderbyproduct)[0].show
                                }"`}
                            </h6>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    <> */}
                {sortshowartist.length > 0 && typeof orderbyartist == 'number' ? (
                    <h6>
                        {`Sort By "${
                            sortshowartist.filter((val) => val.value == orderbyartist)[0].show
                        }"`}
                    </h6>
                ) : (
                    ''
                )}
                {/* </>
                )} */}

                <Seller
                    searchbar={searchbar}
                    user_id={user?.id ? user.id : ''}
                    type={type}
                    index={1}
                    orderbyproduct={orderbyproduct}
                    orderbyartist={orderbyartist}
                />
                {/* <Seller
                    type={type === 'artist' || type === 'reseller' ? 'gallery' : 'artist'}
                    index={2}
                />
                <Seller
                    type={type === 'artist' || type === 'gallery' ? 'reseller' : 'artist'}
                    index={3}
                /> */}
            </div>
        </div>
    )
}

export default List
