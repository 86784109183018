import React, { useContext, useEffect, useRef, useState } from 'react'
import './MessagePlugin.css'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import RenderMessage from './RenderMessage'
import authContext from '../../../Product/context/auth/authContext'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
import productContext from '../../../Product/context/product/productContext'
import { socket } from '../../../Product/common/socket'

const Ask = ({ product }) => {
    const { user } = useContext(authContext)
    const { productMessage, getProductMessages } = useContext(productContext)
    const history = useHistory()
    const [totalComments, setTotalComments] = useState([])
    const [message, setMessage] = useState('')
    const [isSeller, setIsSeller] = useState(user?.id == product.user_id)
    const [comments, setComments] = useState({
        from: 0,
        data: [],
        to: 10,
        limit: 10,
        length: 0,
    })
    const [reply, setReply] = useState({})
    const [likes, setLikes] = useState({})
    const [commentLoader, setCommentLoader] = useState(false)
    const [isMessageError, setIsMessageError] = useState(false)
    const [comment_images, setCommentImages] = useState([])
    const [imageAttach, setImageAttach] = useState({ selectedFile: [] })
    const fileimageupload = useRef(null)

    const handleImageUpload = (event) => {
        setImageAttach({
            selectedFile: [...imageAttach.selectedFile, ...event.target.files],
        })
    }

    const handleRemoveImg = (index, key) => {
        imageAttach.selectedFile.splice(imageAttach.selectedFile.indexOf(index), 1)
        setImageAttach({ selectedFile: [...imageAttach.selectedFile] })
    }

    const sendMessage = async (msg, mail = 'buyer', u_id = 0) => {
        try {
            if (msg === '') {
                setIsMessageError(true)
                return
            }

            setIsMessageError(false)
            const data = {
                from_id: u_id ? u_id : user?.id,
                to_id: '0',
                to_email: parseInt(user?.id) === parseInt(product.user_id) ? 'seller' : mail,
                message: msg,
                pro_id: product.id,
                type: 'product',
                // image: imageAttach.selectedFile,
                isImage: Boolean(imageAttach.selectedFile.length) ? true : false,
            }
            const fromData = new FormData()
            for (const [key, value] of Object.entries(data)) {
                if (key === 'isImage') {
                    if (value) {
                        fromData.set(key, value)
                    }
                } else {
                    fromData.set(key, value)
                }
            }
            if (Boolean(imageAttach.selectedFile.length)) {
                imageAttach.selectedFile.map((value) => fromData.append('image', value))
            }
            const res = await productMessage(fromData)
            if (res) {
                setMessage('')
                setImageAttach({ selectedFile: [] })
                return true
            }
            return false
        } catch (error) {
            console.log(error)
        }
    }

    const getAllMessages = async () => {
        try {
            setCommentLoader(true)
            const res = await getProductMessages({
                pid: product.id,
                type: 'product',
            })
            if (res.success) {
                var reverse = res.data

                setComments({
                    ...comments,
                    length: reverse.length,
                    data: reverse,
                })
                setTotalComments(reverse)
            }
            setCommentLoader(false)
        } catch (error) {
            setCommentLoader(false)
            console.log(error)
        }
    }
    useEffect(() => {
        getAllMessages()
    }, [])
    const commentsRef = useRef(comments)
    useEffect(() => {
        commentsRef.current = comments
    })
    useEffect(() => {
        socket.on('chatmessages', (data) => {
            if (
                parseInt(data.messages.pro_id) === parseInt(product.id) &&
                !data.messages.reply_to
            ) {
                setComments((prev) => ({ ...prev, data: [data.messages, ...prev.data] }))
                setTotalComments((prev) => [data.messages, ...prev])
            }
            if (parseInt(data.messages.pro_id) === parseInt(product.id) && data.messages.reply_to) {
                setReply(data)
            }
        })

        socket.on('like_message', (data) => {
            let temp_arr = commentsRef.current.data
            temp_arr.map((val) => {
                if (val.id == data.message_id) {
                    val.likes = data.likes
                }
            })
            setComments({ ...commentsRef.current, data: temp_arr })
            setLikes(data)
        })
        return () => {
            socket.off('chatmessages', (data) => {
                if (
                    parseInt(data.messages.pro_id) === parseInt(product.id) &&
                    !data.messages.reply_to
                ) {
                    setComments((prev) => ({ ...prev, data: [data.messages, ...prev.data] }))
                    setTotalComments((prev) => [data.messages, ...prev])
                }
                if (
                    parseInt(data.messages.pro_id) === parseInt(product.id) &&
                    data.messages.reply_to
                ) {
                    setReply(data)
                }
            })
            socket.off('like_message', (data) => {
                let temp_arr = commentsRef.current.data
                temp_arr.map((val) => {
                    if (val.id == data.message_id) {
                        val.likes = data.likes
                    }
                })
                setComments({ ...commentsRef.current, data: temp_arr })
                setLikes(data)
            })
        }
    }, [])
    return (
        <>
            <div className="essentialBox mt-4">
                <h4>Ask a Question </h4>
                <p className="text-left">{totalComments.length} Comments</p>
                <div className="comment-area">
                    <textarea
                        placeholder={'Add a comment here'}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        style={{ width: '100%', minHeight: '150px', padding: '10px' }}
                        disabled={user || isSeller ? false : true}
                    ></textarea>
                    {isMessageError ? (
                        <p style={{ color: 'red', fontSize: '13px' }}>Please enter valid message</p>
                    ) : null}
                </div>
                <div className="submit-area justify-content-end mt-3">
                    <div className="submit-btn">
                        {user ? (
                            <div className="seller-comment-area" style={{ maxWidth: '200px' }}>
                                {/* {user && isSeller && ( */}
                                <div className="file-comment-img mb-3">
                                    <input
                                        type="file"
                                        onChange={handleImageUpload}
                                        style={{ display: 'none' }}
                                        ref={fileimageupload}
                                        accept="image/*"
                                        multiple
                                    />
                                    <AttachFileIcon
                                        className="img-attach"
                                        onClick={() => fileimageupload.current.click()}
                                    />
                                </div>
                                {/* )} */}
                                <SecondaryButton
                                    label={commentLoader ? 'Sending...' : 'submit'}
                                    customClassName="productViewSecontdry"
                                    onClick={() => sendMessage(message)}
                                    disabled={(user && isSeller) || !commentLoader ? false : true}
                                />
                            </div>
                        ) : (
                            <SecondaryButton
                                label={user ? 'Submit' : 'Login To Comment'}
                                customClassName="productViewSecontdry"
                                onClick={() =>
                                    history.push('/login?return=' + window.location.pathname)
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="row pt-5">
                {Boolean(imageAttach.selectedFile.length) &&
                    imageAttach.selectedFile.map((ele, key) => (
                        <div className="col-md-3 mt-2">
                            <div className="pp-uimg">
                                <span
                                    className="img-del "
                                    onClick={() => handleRemoveImg(ele, key)}
                                >
                                    {' '}
                                    X{' '}
                                </span>
                            </div>
                            <img
                                src={URL.createObjectURL(ele)}
                                style={{ width: '150px', height: '150px' }}
                            />
                        </div>
                    ))}
            </div>
            <div className="bit-hist" style={{ height: '100%', minHeight: '100px' }}>
                {comments.data.map((ele) => (
                    <RenderMessage
                        data={ele}
                        reply={reply}
                        setReply={setReply}
                        likes={likes}
                        setLikes={setLikes}
                    />
                ))}
            </div>
        </>
    )
}

export default Ask
