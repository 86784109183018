import React, { useContext, useEffect, useState } from 'react'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import authContext from '../../../Product/context/auth/authContext'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
const CustomAlgoliaSearch = ({ id, searchbar }) => {
    const [options, setOptions] = useState([])
    const [cancelRequest, setCancelRequest] = useState(undefined)
    const cancelToken = axios.CancelToken.source()
    const { search_products, searchProductAlgolia } = useContext(pluginContext)
    const { user } = useContext(authContext)
    const history = useHistory()
    const setProducts = () => {
        if (search_products?.hits) {
            let temp = []
            search_products?.hits?.map((val) =>
                temp.push(
                    <option
                        value={val.title}
                        onClickCapture={() => history.push(`/search?q=${searchbar}`)}
                        style={{ cursor: 'pointer' }}
                    />,
                ),
            )
            setOptions(temp)
        }
    }
    const searchProducts = () => {
        const body = {
            user_id: user?.id,
            site_id: global?.storeDetails?.site_id,
            searchbar,
        }
        if (cancelRequest) {
            cancelRequest.cancel()
        }
        setCancelRequest(cancelToken)
        searchProductAlgolia(body, cancelToken)
    }
    useEffect(() => {
        searchProducts()
    }, [searchbar])
    useEffect(() => {
        setProducts()
    }, [search_products])
    return <datalist id={id}>{options.map((val) => val)}</datalist>
}

export default CustomAlgoliaSearch
