import React, { useState, useContext } from 'react'
import { Button } from '@material-ui/core'
import { Link, NavLink } from 'react-router-dom'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Copyrightinfo, Logo } from '../../../utils'
import jsonp from 'jsonp'
import queryString from 'query-string'
import './Footer.css'
import Footernav from '../../../utils/FooterNavigation/footernav'
function Footer() {
    const [inpData, setinpData] = useState('')
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext

    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//${global.storeConfigration?.subscribe_code.value}${queryString.stringify(data)}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }

    return (
        <>
            {global?.storeConfigration?.subscribe?.value && (
                <div className="homeContact">
                    <h2>Get the latest deals</h2>
                    <p>Subscribe us to get latest updates</p>
                    <div className="subsBox">
                        <input
                            type="text"
                            name="q"
                            value={inpData}
                            placeholder="Enter email address"
                            onChange={(e) => {
                                setinpData(e.target.value)
                            }}
                        />
                        <Button>
                            <span className="material-icons" onClick={subscribe}>
                                send
                            </span>
                        </Button>
                    </div>
                </div>
            )}
            <footer className="footer-main-standard noPrint">
                <div className="customContainer">
                    <div className="row" data-gutter="60">
                        <div className="col-12 ftrDtlCntnr">
                            <div className="footLogoCnt">
                                <Logo className="footerLogo" />
                                <Copyrightinfo />
                            </div>
                            <div className="footerMisc">
                                <ul className="footerLinks">
                                    <li>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            Terms & Conditions
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/faq">
                                            FAQs
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            About Us
                                        </NavLink>
                                    </li>
                                    <Footernav />
                                </ul>

                                <ul className="footer-main-standard-social-list">
                                    {facebookLink && (
                                        <li className="facebook">
                                            <a
                                                href={facebookLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Facebook"
                                            >
                                                <i className="fab fa-facebook"></i>
                                            </a>
                                        </li>
                                    )}
                                    {facebookLink && (
                                        <li className="pinterest">
                                            <a
                                                href={twitterLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Pinterest"
                                            >
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                    )}
                                    {googleLink && (
                                        <li className="instagram">
                                            <a
                                                href={googleLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Instagram"
                                            >
                                                <i className="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                    )}
                                    {instagramLink && (
                                        <li className="instagram">
                                            <a
                                                href={instagramLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Instagram"
                                            >
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
