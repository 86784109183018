import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import SubLots from '../../../utils/CommonFunctionality/SellerDashboard/SubLots'

const MyLots = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="MY LOTS">
                <SubLots
                    searchBar={1}
                    status={1}
                    resultsPerPage={1}
                    changeStatus={1}
                    withoutAuction={1}
                    viewPage={1}
                    edit_need_after_bid={1}
                />
            </DashboardLayout>
        </Layout>
    )
}

export default MyLots
