import {
    GET_AUCTION_DATA,
    GET_AUCTION_DATA_BODY,
    GET_AUCTION_DATA_COUNT,
    GET_CLOSED_AUCTION_DATA,
} from '../type'

const SearchReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_AUCTION_DATA: {
            return Object.assign({}, state, {
                auctionSearchResult: action.payload,
            })
        }
        case GET_AUCTION_DATA_COUNT: {
            return Object.assign({}, state, {
                auctionSearchResultCount: action.payload,
            })
        }
        case GET_AUCTION_DATA_BODY: {
            return Object.assign({}, state, {
                auctionSearchResultBody: action.payload,
            })
        }
        case GET_CLOSED_AUCTION_DATA: {
            return Object.assign({}, state, {
                closedauctionSearchResultBody: action.payload,
            })
        }
        default:
            return state
    }
}

export default SearchReducer
