import React from 'react'
import './input.css'

const Inputfield = (props) => {
    return (
        <div className="borderlessTextField">
            <span className="btlLabel">{props.title}</span>
            <input
                autoSave="false"
                autoCorrect="false"
                {...props}
                ref={props.ref}
                maxLength={props.length}
                className={`btlInput ${props.errorclassname}`}
                name={props.name}
                autoFocus={props.autoFocus}
                autoComplete={props.autoComplete}
            />
            {props.msg && <p className="eb-input-error">{props.msg}</p>}
        </div>
    )
}

export default Inputfield
