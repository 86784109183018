import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import SEOTAG from '../../../utils/CommonFunctionality/SEO/AllpagesSEO'
const Layout = ({ props, children, ...rest }) => {
    return (
        <div className="app-wrapper">
            {/* <h1>Hi {currentUser.name}!</h1> */}
            {props ? (
                <SEOTAG
                    page={
                        props?.location?.pathname == '/'
                            ? 'Home'
                            : props?.location?.pathname == '/search'
                            ? 'Marketplace'
                            : props?.location?.pathname == '/dashboard/profile'
                            ? 'Profile'
                            : props?.location?.pathname == '/dashboard/mybids'
                            ? 'My Buynow'
                            : props?.location?.pathname == '/dashboard/favorites'
                            ? 'My Favorites'
                            : props?.location?.pathname == '/dashboard/savedSearch'
                            ? 'Saved Search'
                            : props?.location?.pathname == '/dashboard/cards'
                            ? 'Saved Cards'
                            : props?.location?.pathname == '/dashboard/transactions'
                            ? 'Transactions'
                            : props?.location?.pathname == '/dashboard/mylots'
                            ? 'My Lots'
                            : props?.location?.pathname == '/dashboard/invoices'
                            ? 'Invoices'
                            : props?.location?.pathname == '/dashboard/reports'
                            ? 'Reports'
                            : props?.location?.pathname == '/dashboard/feedback'
                            ? 'Feedback'
                            : props?.location?.pathname == '/dashboard/overview'
                            ? 'Overview'
                            : props?.location?.pathname == '/dashboard/message'
                            ? 'Message'
                            : props?.location?.pathname == '/cart'
                            ? 'Cart'
                            : props?.location?.pathname == '/checkout/buynow'
                            ? 'Checkout'
                            : props?.location?.pathname == '/dashboard/orders'
                            ? 'Current Orders'
                            : ''
                    }
                />
            ) : (
                ''
            )}
            <Header />
            <div className="main-container">{children}</div>
            <Footer />
        </div>
    )
}

export default Layout
