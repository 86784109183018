import React from 'react'
import BuyerAssurance from '../../../utils/CommonFunctionality/BuyerAssurance'
import DashboardHeader from '../DashboardHeader'
import Layout from '../Layout'

const Subscription = (props) => {
    return (
        <Layout props={props}>
            <DashboardHeader />
            <BuyerAssurance />
        </Layout>
    )
}

export default Subscription
