import { Tooltip } from '@material-ui/core'
import React, { useState, useEffect, useContext } from 'react'
import Reorder from 'react-reorder'
import move from 'lodash-move'

const Uploader = (props) => {
    const [reload, setReload] = useState(false)
    const formik = props.formik

    const removeFile = (file, from) => {
        if (props.name === from) {
            let allFiles = formik.values[props.name].filter((fileUploaded) => fileUploaded !== file)
            formik.setFieldValue(props.name, allFiles)

            if (props.deleteArray) {
                let deletedImages = formik.values[props.deleteArray]
                deletedImages.push(file)
                formik.setFieldValue(props.deleteArray, deletedImages)
            }

            setReload(!reload)
        }
    }

    const viewFile = (file, from) => {
        if (props.name === from) {
            const newWindow = window.open(
                `${global.images_url}${props.folder}/${file}`,
                '_blank',
                'noopener,noreferrer',
            )
            if (newWindow) newWindow.opener = null
        }
    }
    const onReorder = (e, from, to) => {
        // console.log(formik.values[props.name], from, to, 'formik.values[props.name]')
        let changedArray = move(formik.values[props.name], from, to)
        // console.log(changedArray, 'changedArray')
        formik.setFieldValue(props.name, changedArray)
        setReload(!reload)
    }

    return (
        <>
            <div>
                <section>
                    <p className="validationError text-left">
                        {formik.touched[props.name] &&
                            formik.errors[props.name] &&
                            formik.errors[props.name]}
                    </p>
                    <aside className="thumbsContainer">
                        <>
                            {formik.values[props.name] && formik.values[props.name].length ? (
                                <>
                                    {props.reorder ? (
                                        <Reorder
                                            reorderId={props.titleText ? props.titleText : ''}
                                            reorderGroup={props.titleText ? props.titleText : ''}
                                            component="div"
                                            className="reorderCnt"
                                            draggedClassName="dragged"
                                            touchHoldTime={250}
                                            mouseHoldTime={100}
                                            onReorder={onReorder}
                                            autoScroll={true}
                                            disabled={false}
                                            disableContextMenus={true}
                                        >
                                            {formik.values[props.name].map((file, index) => (
                                                <div className="thumb" key={index}>
                                                    <div className="thumbInner">
                                                        <div className="thumbCnt">
                                                            {file.includes('pdf') ? (
                                                                <div className="defaultThumb">
                                                                    <span className="material-icons">
                                                                        picture_as_pdf
                                                                    </span>
                                                                </div>
                                                            ) : file.includes('doc') ||
                                                              file.includes('docx') ? (
                                                                <div className="defaultThumb">
                                                                    <span className="material-icons">
                                                                        description
                                                                    </span>
                                                                </div>
                                                            ) : file.includes('mp4') ||
                                                              file.includes('mpeg') ? (
                                                                <div className="defaultThumb">
                                                                    <span className="material-icons">
                                                                        movie
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    src={`${global.images_url}${props.folder}/${file}`}
                                                                    className="img"
                                                                    id={'thumb_' + index}
                                                                />
                                                            )}

                                                            <div className="fileActions">
                                                                {props.hide_delete != 1 ? (
                                                                    <span
                                                                        onClick={() =>
                                                                            removeFile(
                                                                                file,
                                                                                props.name,
                                                                            )
                                                                        }
                                                                        className="cancelBtn material-icons"
                                                                    >
                                                                        delete
                                                                    </span>
                                                                ) : null}
                                                                <span
                                                                    onClick={() =>
                                                                        viewFile(file, props.name)
                                                                    }
                                                                    className="viewBtn material-icons"
                                                                >
                                                                    visibility
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Reorder>
                                    ) : (
                                        formik.values[props.name].map((file, index) => (
                                            <div className="thumb" key={index}>
                                                <div className="thumbInner">
                                                    <div className="thumbCnt">
                                                        {file ? (
                                                            <>
                                                                {props.folder ? (
                                                                    <>
                                                                        {file.includes('pdf') ? (
                                                                            <div className="defaultThumb">
                                                                                <span className="material-icons">
                                                                                    picture_as_pdf
                                                                                </span>
                                                                            </div>
                                                                        ) : file.includes('doc') ||
                                                                          file.includes('docx') ? (
                                                                            <div className="defaultThumb">
                                                                                <span className="material-icons">
                                                                                    description
                                                                                </span>
                                                                            </div>
                                                                        ) : file.includes('mp4') ||
                                                                          file.includes('mpeg') ? (
                                                                            <div className="defaultThumb">
                                                                                <span className="material-icons">
                                                                                    movie
                                                                                </span>
                                                                            </div>
                                                                        ) : (
                                                                            <img
                                                                                src={`${global.images_url}${props.folder}/${file}`}
                                                                                className="img"
                                                                            />
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <div className="defaultThumb">
                                                                        {file.includes('jpg') ||
                                                                        file.includes('jpeg') ||
                                                                        file.includes('png') ||
                                                                        file.includes('.webp') ||
                                                                        file.includes('.gif') ? (
                                                                            <img
                                                                                src={`${global.images_url}${props.folder}/${file}`}
                                                                                className="img"
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                {file.includes(
                                                                                    'pdf',
                                                                                )
                                                                                    ? 'picture_as_pdf'
                                                                                    : file.includes(
                                                                                          'doc',
                                                                                      ) ||
                                                                                      file.includes(
                                                                                          'docx',
                                                                                      )
                                                                                    ? 'description'
                                                                                    : file.includes(
                                                                                          'mp4',
                                                                                      ) ||
                                                                                      file.includes(
                                                                                          'mpeg',
                                                                                      )
                                                                                    ? 'movie'
                                                                                    : 'description'}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <div className="fileActions">
                                                            {props.hide_delete != 1 ? (
                                                                <span
                                                                    onClick={() =>
                                                                        removeFile(file, props.name)
                                                                    }
                                                                    className="cancelBtn material-icons"
                                                                >
                                                                    delete
                                                                </span>
                                                            ) : null}
                                                            <span
                                                                onClick={() =>
                                                                    viewFile(file, props.name)
                                                                }
                                                                className="viewBtn material-icons"
                                                            >
                                                                visibility
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </>
                            ) : null}
                        </>
                    </aside>
                </section>
            </div>
        </>
    )
}

export default Uploader
