import React from 'react'
import Container from '@material-ui/core/Container'
import DashboardHeader from '../DashboardHeader'
import Layout from '../Layout'
import MyBidsPanel from '../../../utils/CommonFunctionality/MyBidsPanel'

const Dashboard = (props) => {
    return (
        <Layout props={props}>
            <DashboardHeader />
            <Container className="db-bids-wrapper mt-4">
                <h2 className="mp-head">MY BIDS</h2>
                <MyBidsPanel />
            </Container>
        </Layout>
    )
}

export default Dashboard
