import React from 'react'
import { Helmet } from 'react-helmet'

const MetaTagName = () => {
    return (
        <>
            <Helmet>
                <script src={'/tagname.js'}></script>
                <noscript>
                    No Script
                    {/* <div>
                        <img
                            height="1"
                            width="1"
                            style="display: none"
                            src="https://www.facebook.com/tr?id=809363682582570&ev=PageView&noscript=1"
                        />
                    </div> */}
                </noscript>
            </Helmet>
        </>
    )
}

export default MetaTagName
