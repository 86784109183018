import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AlertContext from '../../../Product/context/alert/alertContext'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { handleRedirectInternal } from '../../../Product/common/components'
import './sellerDashboard.css'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const sellerDashboard = (props) => {
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user } = authContext
    const { get_seller_dashboard, seller_dashboard_data } = productContext
    const history = useHistory()
    const hue = 0
    const { t } = useTranslation()
    useEffect(() => {
        if (isAuthenticated) {
            if (user.role != '1') {
                handleRedirectInternal(history, '')
            }
            get_seller_dashboard()
        }
    }, [isAuthenticated])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            {Object.keys(seller_dashboard_data).length > 0 && (
                <>
                    <div className="dashboardStats">
                        <h4>{t('listings')}</h4>
                        <div className="dashboardStatsInner">
                            {Object.values(seller_dashboard_data.products).map((data, index) => (
                                <div
                                    className="dsCard"
                                    key={index}
                                    style={{ filter: `hue-rotate(${hue + index * 40}deg)` }}
                                >
                                    <h2>{data ? data : 0}</h2>
                                    <h5>
                                        {t(Object.keys(seller_dashboard_data.products)[index])}{' '}
                                        {t('listings')}
                                    </h5>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="dashboardStats">
                        <h4>{t('invoices')}</h4>
                        <div className="dashboardStatsInner">
                            {Object.values(seller_dashboard_data.invoice).map((data, index) => (
                                <div
                                    className="dsCard"
                                    key={index}
                                    style={{ filter: `hue-rotate(${hue + 15 + index * 200}deg)` }}
                                >
                                    <h2>{data ? data : 0}</h2>
                                    <h5>
                                        {t(
                                            `${
                                                Object.keys(seller_dashboard_data.invoice)[index]
                                            }_invoice`.toLowerCase(),
                                        )}
                                    </h5>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default sellerDashboard
