import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Layout from '../Layout'
import Banner from './Banner'
import UpcomingAuctions from './upcomingAuctions'
import LiveAuctions from './LiveAuction'
import News from './news'
import InstaSlider from './insta'
import './home.css'
import InstaFeeds from './InstagramPosts/InstaFeeds'
import { handleRedirectInternal } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
const csinsta = [
    {
        img: '/phill/cs-insta1.png',
    },
    {
        img: '/phill/cs-insta2.png',
    },
    {
        img: '/phill/cs-insta3.png',
    },
    {
        img: '/phill/cs-insta4.png',
    },
    {
        img: '/phill/cs-insta5.png',
    },
    {
        img: '/phill/cs-insta5.png',
    },
]
const insta = [
    {
        img: '/phill/phg-insta1.png',
    },
    {
        img: '/phill/phg-insta2.png',
    },
    {
        img: '/phill/phg-insta3.png',
    },
    {
        img: '/phill/phg-insta4.png',
    },
    {
        img: '/phill/phg-insta5.png',
    },
    {
        img: '/phill/phg-insta5.png',
    },
]

const Home = (props) => {
    const history = useHistory()
    const handleredirect = (e) => {
        handleRedirectInternal(history, 'reverse-auction')
    }

    return (
        <Layout>
            <div className="home-container">
                <Banner />
                <section className="customContainer">
                    <div className="oneMinAuction">
                        <div className="homeTitleText">
                            <h4 className="mainTit">What is One Minute Auction?</h4>
                            <h5 className="subTit">It’s simple and addictive!</h5>
                        </div>
                        <div className="minAuctionGrid mt-5 mb-5">
                            <div className="minAuctionGridCrd">
                                <div className="gridMedia">
                                    <div className="mediaLeft">
                                        <div className="MediaImg">
                                            <object data="/phill/roomof.svg" type="image/svg+xml" />
                                        </div>
                                    </div>
                                    <div className="mediaBody">
                                        <p>
                                            Imagine, an auction room with <span>reverse</span>{' '}
                                            auction
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="minAuctionGridCrd">
                                <div className="gridMedia">
                                    <div className="mediaLeft">
                                        <div className="MediaImg">
                                            <object data="/phill/timer.svg" type="image/svg+xml" />
                                        </div>
                                    </div>
                                    <div className="mediaBody">
                                        <p>
                                            <span>one minute</span> to acquire the lot
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="minAuctionGridCrd">
                                <div className="gridMedia">
                                    <div className="mediaLeft">
                                        <div className="MediaImg">
                                            <object
                                                data="/phill/everysec.svg"
                                                type="image/svg+xml"
                                            />
                                        </div>
                                    </div>
                                    <div className="mediaBody">
                                        <p>
                                            <span>Every second </span>
                                            it’s price goes down
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="minAuctionGridCrd">
                                <div className="gridMedia">
                                    <div className="mediaLeft">
                                        <div className="MediaImg">
                                            <object data="/phill/Picto.svg" type="image/svg+xml" />
                                        </div>
                                    </div>
                                    <div className="mediaBody">
                                        <p>
                                            The <span>First</span> one to click <span>wins</span>{' '}
                                            the auction !
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="oneMinFinalDiv">
                            <p>Don&#39;t Miss your chance !</p>
                            <PrimaryButton
                                onClick={(e) => handleredirect(e)}
                                className="btn btn-white-trans"
                            >
                                Let’s Go!
                            </PrimaryButton>
                        </div>
                    </div>
                </section>
                <div className="fu-head clearfix">Live Auctions</div>
                <LiveAuctions />
                <div className="fu-head clearfix">Next Auctions</div>
                <UpcomingAuctions />
                <section className="customContainer">
                    <div className="whoVR">
                        <div className="whoVRCnt">
                            <h4 className="tit">Who are we?</h4>
                            <p className="des">
                                One Minute Auction is a unique and rare item sales platform,
                                offering items such as jewelry&quot; photos&quot; and antiques
                                through a private descending auction system. Join us to discover
                                rare and valuable items. We believe that fashion and art are
                                expressions of quality and uniqueness. We know that everyone is
                                looking for something different and unique. Join us on One Minute
                                Auction&quot; where every opportunity is a chance to discover
                                something rare and precious&quot; so &quot;Don&quot;t miss your
                                chance.&quot;
                            </p>
                            <PrimaryButton
                                onClick={(e) => handleRedirectInternal(history, 'aboutus')}
                                className="btn btn-white-trans"
                            >
                                LEARN MORE
                            </PrimaryButton>
                        </div>
                        <figure className="m-0 wvrImg">
                            <img src="/phill/ug-img1.png" alt="picture" />
                        </figure>
                    </div>
                </section>
                <div className="fu-head clearfix">Follow Us on Instagram</div>
                <InstaSlider title="@oneminuteauction" data={csinsta} />
                <InstaSlider title="@oneminuteauction" data={insta} />
            </div>
        </Layout>
    )
}

export default Home
