import React from 'react'
import Layout from '../Layout'
import DashboardLayout from '../DashboardLayout'
import UserMessage from '../../../utils/CommonFunctionality/Message'

const Message = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="Messages">
                <div className="mt-4">
                    <UserMessage data={props} />
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default Message
