import React from 'react'
import { WhatsappShareButton, WhatsappIcon } from 'react-share'
const Whatsapp = ({ path, title }) => {
    return (
        <WhatsappShareButton
            url={
                window.location.href.includes(global?.storeDetails?.id)
                    ? `${window.location.origin}/${global?.storeDetails?.id}${path}`
                    : `${window.location.origin}${path}`
            }
            title={title}
            separator=":: "
        >
            <WhatsappIcon size={32} round />
        </WhatsappShareButton>
    )
}

export default Whatsapp
