import React, { useState, useEffect } from 'react'
import GridAuction from '../ProductCard/gridAuction'
import './AuctionGridCard.css'

const SearchList = (props) => {
    return (
        <div className="search-right-wrapper">
            {props.listview ? (
                <div className="table-responsive"></div>
            ) : (
                <div className="gridContainer">
                    {props.allsearchitems.map((item, index) => {
                        return (
                            <div className="auctionGridCard" key={`gl-${index}`}>
                                {item.closed != '' ? (
                                    <GridAuction
                                        data={item}
                                        details={`search/product-buyer-auction/${item.id}?${item.description}`}
                                    />
                                ) : null}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default SearchList
