import React, { useState, useEffect, useContext, useRef } from 'react'
import { mapData } from '../../../Product/common/components'
import './moredetails.css'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
const Moredetails = ({ formik, product_id, repost, loading, condition, setAddMore }) => {
    const moreListing = [
        {
            label: 'Year',
            type: 'number',
            placeholder: 'Enter a year',
            class: 'col-6',
            name: 'm_year',
            formik: formik,
        },
        {
            label: 'Brand',
            type: 'text',
            placeholder: 'Enter a brand',
            class: 'col-6',
            name: 'm_brand',
            formik: formik,
        },
        {
            label: 'Autograph',
            placeholder: 'Select Autograph',
            type: 'select',
            class: 'col-md-6 col-sm-12 col-lg-6',
            options: [
                {
                    show: 'Yes',
                    value: 'yes',
                },
                {
                    show: 'No',
                    value: 'no',
                },
            ],
            defaultOptionValue: 'Select Autograph',
            name: 'm_autographed',
            formik: formik,
        },
        // {
        //     label: 'Grade Rating',
        //     type: 'text',
        //     placeholder: 'Enter a grade rating',
        //     class: 'col-6',
        //     name: 'm_graderating',
        //     formik: formik,
        // },
        {
            label: 'Athlete',
            type: 'text',
            placeholder: 'Enter a name',
            class: 'col-6',
            name: 'm_athelet',
            formik: formik,
        },
        {
            label: 'Team',
            type: 'text',
            placeholder: 'Enter a team',
            class: 'col-6',
            name: 'm_team',
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'verification_doc',
            titleText: 'Upload documents',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">post_add</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>
                                Certification of Authentication: pdf / doc / docx / jpeg / jpg / png
                            </span>
                        </b>
                    </p>
                    <span>Upload a document file</span>
                </div>
            ),
            class: 'col-12',
            folder: '',
            multiple: false,
            accept: 'image/jpeg, image/jpg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            compressImages: false,
        },
    ]

    return (
        <>
            <div className="media pv-media product-media-wrapper flex-wrap mb-3">
                <div className="media-left">
                    <img
                        src={
                            formik.values.product_image.length > 0 && (!product_id || repost)
                                ? URL.createObjectURL(formik.values.product_image[0])
                                : formik.values.product_existingimage.length > 0
                                ? `${global.images_url}product/${formik.values.product_existingimage[0]}`
                                : `${global?.storeDetails?.logoValue}`
                        }
                        className="pro-img img-fluid imageh"
                    />
                </div>
                <div className="media-body">
                    <div className="row">
                        <div className="col-md-8">
                            <h2
                                className="pv-title mb-4"
                                dangerouslySetInnerHTML={{
                                    __html: formik.values.title,
                                }}
                            />
                            <div className="pv-con">
                                <span>{'Condition: '}</span>
                                {condition.filter(
                                    (val) =>
                                        parseInt(val.id) == parseInt(formik.values.radcondition),
                                ).length > 0
                                    ? condition.filter(
                                          (val) =>
                                              parseInt(val.id) ==
                                              parseInt(formik.values.radcondition),
                                      )[0].description
                                    : ''}
                            </div>
                            <div className="pv-con">
                                <span>{'Autograph: '}</span>
                                {formik.values.m_autographed}
                            </div>
                            <div className="pv-con">
                                <span>{'Athlete Name: '}</span>
                                {formik.values.m_athelet}
                            </div>
                            <div className="pv-con">
                                <span>{'Team: '}</span>
                                {formik.values.m_team}
                            </div>
                            <div className="pv-con">
                                <span>{'Brand: '}</span>
                                {formik.values.m_brand}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">{mapData(moreListing)}</div>
            <div className="row">
                <div className="col-6">
                    <PrimaryButton
                        type="button"
                        label={product_id && !repost ? 'Edit Lot' : 'Save and Post Live'}
                        disabled={loading}
                        onClick={() => {
                            formik.handleSubmit()
                        }}
                    />
                </div>
                {!product_id && !repost ? (
                    <div className="col-6">
                        <PrimaryButton
                            type="button"
                            label={'Save and Add More'}
                            disabled={loading}
                            onClick={() => {
                                formik.handleSubmit()
                                setAddMore(4)
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </>
    )
}
export default Moredetails
