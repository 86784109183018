export const GetFileFromBase64 = (blob, fileName) => {
    //console.log('blob --> ' + blob)
    var arr = blob && blob.split(','),
        mime = arr[0] && arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    // let fileName = Math.random().toString(36).substring(7)
    //console.log('mime type', mime)
    if (mime === 'image/jpeg') {
        fileName = `${fileName}.jpg`
    } else if ('image/webp') {
        fileName = `${fileName}.webp`
    }
    //console.log('m --> ' + fileName)
    return new File([u8arr], fileName, { type: mime })
}
