import React from 'react'
import Layout from '../Layout'
import BuyNowOnly from '../../../utils/CommonFunctionality/BuyNowOnly'
import SEO from '../../../utils/CommonFunctionality/SEO'
const PostBuynow = (props) => {
    return (
        <>
            <Layout props={props}>
                <SEO title={`${global?.storeDetails?.name} | Post buy now`} />

                <div className="postProductContainer">
                    <BuyNowOnly theme="FoodIndustry" bottomErrorField={1} />
                </div>
            </Layout>
        </>
    )
}

export default PostBuynow
