import { GET_SEARCH_BODY, GET_SEARCH_DATA, GET_SEARCH_DATA_COUNT } from '../type'

const SearchGalleryReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_SEARCH_DATA: {
            return Object.assign({}, state, {
                searchResult: action.payload,
            })
        }
        case GET_SEARCH_DATA_COUNT: {
            return Object.assign({}, state, {
                searchResultCount: action.payload,
            })
        }
        case GET_SEARCH_BODY: {
            return Object.assign({}, state, {
                searchResultBody: action.payload,
            })
        }
        default:
            return state
    }
}

export default SearchGalleryReducer
