/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../Product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
const PaymentPendingHeader = (props) => {
    const { userCount } = useContext(AuthContext)
    const { t } = useTranslation()

    return (
        <>
            {userCount.itemunPaidCount ? (
                <div className="auctionHasItems noPrint">
                    <img src="/assets/svg/TrophyIcon.svg" />
                    {global?.pluginConfiguration?.language_manager?.enable == 1 ? (
                        <h3>
                            <Link to={`/dashboard/mybids?s=1`}>{t('make_payment_link')}</Link>
                        </h3>
                    ) : (
                        <h3>
                            Congratulations! You have won an auction.{' '}
                            <Link to={`/dashboard/mybids?s=1`}>Click here</Link> to make the
                            payment.
                        </h3>
                    )}
                </div>
            ) : null}
        </>
    )
}

export default PaymentPendingHeader
