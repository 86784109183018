import React, { useContext, useEffect, useState } from 'react'
import Layout from '../Layout'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import { Rating } from '@material-ui/lab'

export default function Testimonials(props) {
    const [testimonial, setTestimonial] = useState([])
    const { getAllFeedback, all_feedback } = useContext(pluginContext)
    useEffect(() => {
        getAllFeedback({
            perlimit: 12,
            page: 1,
            site_id: global?.storeDetails?.site_id,
            include_testimonial: 1,
        })
    }, [])
    useEffect(() => {
        setTestimonial(all_feedback)
    }, [all_feedback])
    return (
        <Layout props={props}>
            <div className="px-5 py-4 staticPgWrapper">
                {/* <h2>About Us</h2> */}
                <span
                    dangerouslySetInnerHTML={{
                        __html: global.storeDetails.fact_to_know,
                    }}
                ></span>
                {testimonial?.length &&
                    testimonial?.map((val) => (
                        <div className="firstTest">
                            <div>
                                <img
                                    className="testingImg"
                                    src={
                                        `${
                                            val.content_head1 === '0'
                                                ? process.env.REACT_APP_BASE_URL +
                                                  'uploads/product/'
                                                : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                        }` + val.avatar
                                    }
                                    onError={(e) =>
                                        (e.target.src = `${global?.storeDetails?.logoValue}`)
                                    }
                                />
                            </div>

                            <div className="firstContent">
                                <h5>&quot;{val.subject}&quot;</h5>

                                <p>{val.message}</p>

                                <p className="name">
                                    <b>{'Customer from ' + val.buyer_state}</b>
                                    <span>|</span>
                                    <Rating
                                        name="read-only"
                                        value={val.rating}
                                        precision={0.5}
                                        readOnly
                                    />
                                </p>
                            </div>
                        </div>
                    ))}
            </div>
        </Layout>
    )
}
