import React from 'react'
import Layout from '../Layout'
import ReactHtmlParser from 'react-html-parser'
export default function howItWorks(props) {
    return (
        <Layout props={props}>
            <div className="px-5 py-4 stctPgWrpr">
                <span>{ReactHtmlParser(global.storeDetails.how_it_work)}</span>
            </div>
        </Layout>
    )
}
