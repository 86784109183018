import React, { useState, useEffect, useContext } from 'react'
import Slider from 'react-slick'
import { Link, useHistory } from 'react-router-dom'
import { PrevArrow, NextArrow } from './Arrows'
import ProductContext from '../../../Product/context/product/productContext'
import { dateFormatFront, handleRedirectInternal } from '../../../Product/common/components'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import Timer from '../../../Product/common/timer'
const settings = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
            },
        },
    ],
}

const auctions = [
    {
        id: 1,
        img: '/phill/ua-img.png',
        title: 'Fine Arts Auction',
        type: 'Online Auction',
        date: 'Feb 26th to March 17th',
        no_lots: 126,
    },
    {
        id: 2,
        img: '/phill/ug-img1.png',
        title: 'Antique Furniture Auction',
        type: 'Online Auction',
        date: 'Feb 26th to March 17th',
        no_lots: 126,
    },
    {
        id: 3,
        img: '/phill/ua-img.png',
        title: 'Fine Arts Auction',
        type: 'Online Auction',
        date: 'Feb 26th to March 17th',
        no_lots: 126,
    },
]

const LiveAuctions = ({ title }) => {
    const history = useHistory()
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions } = productContext
    const [viewAuction, setViewAuction] = useState([])

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '1',
        searchbar: '',
        auc_type: 2,
        lot_images: 1,
        type: '',
        timed_live: 'timed',
        is_auction: 1,
    })

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'liveauction_home')
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'liveauction_home') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    return (
        <div className="customContainer insta-slider-wrapper">
            {viewAuction.length > 0 ? (
                <Slider
                    {...settings}
                    className={`insta-slider upComingSlider ${
                        viewAuction.length == 1 && 'singleSlider'
                    }`}
                >
                    {viewAuction.map((item, index) => {
                        return (
                            <div className="p-2">
                                <div className="card" key={item.id}>
                                    <figure>
                                        <img
                                            src={
                                                process.env.REACT_APP_PRODUCT_IMAGE_URL +
                                                item.file_name
                                            }
                                            alt=""
                                            className="media-left"
                                        />
                                    </figure>
                                    <div className="card-body">
                                        <h3>{item.title}</h3>
                                        {/* <p>Forward Auction</p> */}
                                        <Timer
                                            date_added={item.date_added}
                                            date_closed={item.date_closed}
                                            withText={1}
                                            theme={'multiseller'}
                                            endText={'Time Left: '}
                                            startText={'Start In: '}
                                        />
                                        <div style={{ marginTop: '20px' }}>
                                            {/* <p className="ua-lots">{item.lotCount} lots</p> */}
                                            <PrimaryButton
                                                className="btn btn-white-trans"
                                                onClick={() => {
                                                    handleRedirectInternal(
                                                        history,
                                                        `search/product-buyer-auction/${item.id}`,
                                                    )
                                                }}
                                            >
                                                BROWSE AUCTION LOTS
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            ) : (
                <NoRecordsFound />
            )}
        </div>
    )
}

export default LiveAuctions
