import React from 'react'
import './style.css'
const Loader = () => {
    return (
        <div className="loadingio-spinner-dual-ball-jcnnn8dl11r">
            <div className="ldio-0xmhh735auim">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader
