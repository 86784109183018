import React from 'react'
import Header from '../Header'
import Footer from '../Footer'

const Layout = ({ props, children, ...rest }) => {
    return (
        <div className="app-wrapper">
            {/* <h1>Hi {currentUser.name}!</h1> */}
            {window.location.href.includes('?app=1') ? '' : <Header />}
            <div className="main-container">{children}</div>
            {window.location.href.includes('?app=1') ? '' : <Footer />}
        </div>
    )
}

export default Layout
