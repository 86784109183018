import React from 'react'
import './Registration.css'
import Layout from '../Layout'
import BecomeSellerFunction from '../../../utils/CommonFunctionality/BecomeSellerFunction'

const BecomeSeller = (props) => {
    return (
        <Layout>
            <div className="clearfix row regCnt">
                <div className="col-12">
                    <div className="loginFormBox becomeSeller">
                        <BecomeSellerFunction
                            defaultCountryCode={38}
                            title="Become a Seller"
                            subTitle=""
                            multipleUserRole
                            regpro={props}
                            theme="artauction"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BecomeSeller
