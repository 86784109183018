import React from 'react'
import './Registration.css'
import Layout from '../Layout'
import SignupFunction from '../../../utils/CommonFunctionality/SignupFunction'
import { Logo } from '../../../utils/index'

const Registration = (props) => {
    return (
        <Layout>
            <div className="clearfix row regCnt">
                <div className="col-12">
                    <div className="loginFormBox py-5">
                        <div className="lgnOtrWrpr">
                            <Logo className="headerLogo" />
                            <SignupFunction
                                // defaultCountryCode={38}
                                title="Signup"
                                subTitle="Create your free account today"
                                regpro={props}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Registration
