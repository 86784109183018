import React from 'react'
import Layout from '../Layout'
import './PostProduct.css'
import PostProductComponent from '../../../utils/CommonFunctionality/PostProduct'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import FishingPostProduct from './FishingPostProduct'

const PostProduct = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { t } = useTranslation()
    let multiSectionNames = [
        t('fornt_page'),
        t('white_background'),
        t('side_image'),
        t('closeup_image'),
        'Need_Image_Upload',
        // 'Side Image',
    ]
    return (
        <Layout props={props}>
            {global.storeConfigration?.fishing_theme?.value != 1 ? (
                <div>
                    <h2 className="ppTitle">{t('post_a_product')}</h2>
                    <PostProductComponent
                        withoutAuction={1}
                        // itemLocation={1}
                        addressSection={1}
                        buynow={0}
                        go_live_btn_name={t('go_live')}
                        // defaultCountryCode={38}
                        extraSection={0}
                        foodIndustryFields={0}
                        theme="FoodIndustry"
                        taxFree={0}
                        videoUploader={1}
                        noLocalPickup={1}
                        pickupDetails={1}
                        externalshipingOnly={1}
                        allformatimagenotneed={1}
                        multiSectionImage={multiSectionNames}
                        artauctionfields={1}
                        low_high_est_not_required={1}
                        sku_not_required={1}
                        imageInfoContent={
                            <>
                                <div className="imgInfoCntnr">
                                    <h5>{t('sample_images')}</h5>
                                    <p>{t('upload_photos')}</p>
                                    <div className="smplImgGrid">
                                        <div className="smplImgCrd">
                                            <label>{t('fornt_page')}</label>
                                            <img src="/assets/images/front_swatch.webp" />
                                        </div>
                                        <div className="smplImgCrd">
                                            <label>{t('white_background')}</label>
                                            <img src="/assets/images/white_background.webp" />
                                        </div>
                                        <div className="smplImgCrd">
                                            <label>{t('side_image')}</label>
                                            <img src="/assets/images/side_image.webp" />
                                        </div>
                                        <div className="smplImgCrd">
                                            <label>{t('closeup_image')}</label>
                                            <img src="/assets/images/closeup_image.webp" />
                                        </div>
                                        {/* <div className="smplImgCrd">
                                        <label>Side Image</label>
                                        <img src="/assets/images/side_image_new.webp" />
                                    </div> */}
                                    </div>
                                </div>
                            </>
                        }
                    />
                </div>
            ) : (
                <FishingPostProduct />
            )}
        </Layout>
    )
}

export default PostProduct
