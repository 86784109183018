/* eslint-disable */
import React, { useState } from 'react'

const Spinner = () => {
    const [isLoading, setIsLoading] = useState(true)

    return (
        isLoading && (
            <div className="loader">
                <img width={120} src="/assets/svg/load.svg" />
            </div>
        )
    )
}

export default Spinner
