import React from 'react'
import './invoice.css'
import InvoiceComponent from '../../../utils/CommonFunctionality/Invoice/InvoiceFunction'
import Layout from '../Layout'
import { useEffect } from 'react'

const Invoice = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Layout props={props}>
            <InvoiceComponent props={props} downloadInvoice={true} noBuyerPremium={false} />
        </Layout>
    )
}

export default Invoice
