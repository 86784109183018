import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import { useLocation } from 'react-router-dom'
import { Pagination } from '@material-ui/lab'
import { load } from 'dotenv'
import { messageHandler } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import UserActivity from '../../../utils/CommonFunctionality/UserActivity'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const location = useLocation()
    const buyerContext = useContext(BuyerContext)
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const history = useHistory()
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { buyer_allproducts, getAllBuyerProducts, product_view, getViewProduct } = buyerContext

    const { search_allproducts, getAllSearchProducts } = productContext
    const { setAlert } = alertContext
    const { user, isAuthenticated } = authContext
    const classes = useStyles()
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [locationfillter, setLocation] = useState([])
    // const [page, setPage] = useState(1)
    const [totalViewProduct, setTotalViewProduct] = useState(0)
    const [listview, setlistview] = useState(false)
    const [auctionView, setAuctionView] = useState('grid')
    const [favLoad, setFavLoad] = useState(false)
    const [audio] = useState(
        global.storeConfigration?.bidding_sound?.value == 1 ? new Audio('/audio/ding.mp3') : '',
    )
    const params = new URLSearchParams(location.search)

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const [isFilterVisible, setFilterVisible] = useState(true)

    const manageFilterPanel = () => {
        setFilterVisible(!isFilterVisible)
    }

    const [view] = useState(new URLSearchParams(window.location.search.substring(1)).get('view'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const [subcatg] = useState(
        new URLSearchParams(window.location.search.substring(1)).get('subcat'),
    )
    const [sort] = useState(new URLSearchParams(window.location.search.substring(1)).get('sort'))
    const [slimit] = useState(
        new URLSearchParams(window.location.search.substring(1)).get('slimit'),
    )
    const [page] = useState(new URLSearchParams(window.location.search.substring(1)).get('page'))

    const formik = useFormik({
        initialValues: {
            sh_limit: slimit !== '' && slimit !== null ? slimit : 50,
            page: page !== '' && page !== null ? Number(page) : 1,
            orderby: sort !== null ? sort : 6,
            location: [],
            state: [],
            city: [],
            category: catg ? catg.split(',') : [],
            subcategory: subcatg ? subcatg.split(',') : [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            contenthead5: global.pluginConfiguration?.reverse_auction?.enable ? ['0'] : [],
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            from: 'auction_only',
            from_id: 1,
            closed_auction_list: view !== '' && view !== null ? view : 0,
            auction_io: 1,
            loc_fil: global.storeConfigration?.show_warehouse_location?.value
                ? global.storeConfigration?.show_warehouse_location?.value
                : 0,
            lot_no_fil: global.storeConfigration?.lot_sequence_order?.value
                ? global.storeConfigration?.lot_sequence_order?.value
                : 0,
        },
    })

    const prevParamsRef = useRef({
        category: formik.values.category,
        subcategory: formik.values.subcategory,
        closed_auction_list: formik.values.closed_auction_list,
        sh_limit: formik.values.sh_limit,
    })

    if (global?.storeConfigration?.need_buynow_search?.value == 1) {
        var filterValues = [
            {
                title: 'View',
                type: 'radio',
                noLabel: true,
                name: 'closed_auction_list',
                int: 1,
                class: 'col-12',
                item: [
                    { id: 0, description: 'Live Items' },
                    { id: 1, description: 'Closed Items' },
                ],
                formik: formik,
            },
            {
                title: 'Type',
                type: 'radio',
                noLabel: true,
                name: 'from_id',
                int: 1,
                class: 'col-12',
                item: [
                    { id: 0, description: 'All' },
                    { id: 1, description: 'Auction' },
                    { id: 2, description: 'Buy Now' },
                ],
                formik: formik,
            },
            {
                title: 'Category',
                type: 'check',
                name: 'category',
                options: global.searchCategories.filter((main) => {
                    return true
                }),
                formik: formik,
            },
            {
                title: 'Sub category',
                type: 'check',
                name: 'subcategory',
                options: global.searchSubCategories.filter((subcat) => {
                    // if (
                    //     formik &&
                    //     formik.values &&
                    //     formik.values.category &&
                    //     formik.values.category.length
                    //         ? formik.values.category.includes(subcat.level_id.toString())
                    //         : false
                    // ) {
                    return true
                    //}
                }),
                formik: formik,
            },
        ]
    } else {
        var filterValues = [
            {
                title: 'View',
                type: 'radio',
                noLabel: true,
                name: 'closed_auction_list',
                int: 1,
                class: 'col-12',
                item: [
                    { id: 0, description: 'Live Items' },
                    { id: 1, description: 'Closed Items' },
                ],
                formik: formik,
            },
            {
                title: 'Category',
                type: 'check',
                name: 'category',
                options: global.searchCategories.filter((main) => {
                    return true
                }),
                formik: formik,
            },
            {
                title: 'Sub category',
                type: 'check',
                name: 'subcategory',
                options: global.searchSubCategories.filter((subcat) => {
                    // if (
                    //     formik &&
                    //     formik.values &&
                    //     formik.values.category &&
                    //     formik.values.category.length
                    //         ? formik.values.category.includes(subcat.level_id.toString())
                    //         : false
                    // ) {
                    return true
                    //}
                }),
                formik: formik,
            },
        ]
    }

    if (global?.storeConfigration?.show_warehouse_location?.value == 1) {
        filterValues.push({
            title: 'Location',
            type: 'check',
            class: 'col-12',
            name: 'location',
            options: locationfillter,
            formik: formik,
        })
    }

    useEffect(() => {
        // setLoading(true)
        if (search_allproducts.from === 'searchPage') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setTotalViewProduct(search_allproducts.total_pagecnt)
            if (global?.storeConfigration?.show_warehouse_location?.value == 1) {
                var loc = []
                search_allproducts.locate.map((data) => {
                    loc.push({
                        description: data.name,
                        name: data.name,
                        value: data.name,
                        id: data.name,
                        active: 1,
                    })
                })
                setLocation(loc)
            }
        }
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        window.scrollTo({ top: 0, bottom: 0, behavior: 'smooth' })
    }, [viewProduct])

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socket.on('cancelbidemitted', (data) => {
            handler(data, 'cancelbidemitted')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socket.off('cancelbidemitted', (data) => {
                handler(data, 'cancelbidemitted')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const onHandlePage = (event, value) => {
        // setPage(value)
        formik.setFieldValue('page', Number(value))
        setLoading(true)
        getAllSearchProducts({ ...formik.values, page: value }, 'searchPage')
        setLoading(false)
        window.scrollTo(0, 0)
    }

    const getAllProduct = async (bool) => {
        // setLoading(true)
        // getAllSearchProducts(formik.values, 'searchPage').then((res) => {
        //     if (res.success === 'yes' && params.get('product'))
        //         getViewProduct({ product_id: Number(params.get('product')) })
        // })
        // setTimeout(() => {
        //     setLoading(false)
        // }, 1000)
    }
    useEffect(() => {
        // setLoading(true)
        /*  if (formik.values.closed_auction_list) {
            formik.values.orderby = '7'
        } */
        if (global?.storeConfigration?.need_buynow_search?.value == 1) {
            if (parseInt(formik.values.from_id) == 0) {
                formik.values.from = ''
            } else if (parseInt(formik.values.from_id) == 1) {
                formik.values.from = 'auction_only'
            } else {
                formik.values.from = 'buynow'
            }

            params.set('at', formik.values.from_id)

            history.replace({
                pathname: location.pathname,
                search: params.toString(),
            })
        }

        let currentUrl = new URL(window.location.href)

        if (currentUrl.searchParams.has('sort') || formik.values.orderby) {
            currentUrl.searchParams.set('sort', formik.values.orderby)
            window.history.replaceState({}, '', currentUrl)
        }

        if (currentUrl.searchParams.has('slimit') || formik.values.sh_limit) {
            currentUrl.searchParams.set('slimit', formik.values.sh_limit)
            window.history.replaceState({}, '', currentUrl)
        }

        if (currentUrl.searchParams.has('page') || formik.values.page) {
            currentUrl.searchParams.set('page', formik.values.page)
            window.history.replaceState({}, '', currentUrl)
        }

        if (currentUrl.searchParams.has('cat') || formik.values.category) {
            currentUrl.searchParams.set('cat', formik.values.category)
            window.history.replaceState({}, '', currentUrl)
        }

        if (currentUrl.searchParams.has('subcat') || formik.values.subcategory) {
            currentUrl.searchParams.set('subcat', formik.values.subcategory)
            window.history.replaceState({}, '', currentUrl)
        }

        if (currentUrl.searchParams.has('view') || formik.values.closed_auction_list) {
            currentUrl.searchParams.set('view', formik.values.closed_auction_list)
            window.history.replaceState({}, '', currentUrl)
        }

        getAllSearchProducts({ ...formik.values, page: formik.values.page }, 'searchPage')
        // setLoading(false)
    }, [formik.values, isAuthenticated])

    // useEffect(() => {
    //     if (
    //         formik.values.category ||
    //         formik.values.subcategory ||
    //         formik.values.closed_auction_list ||
    //         formik.values.sh_limit
    //     ) {
    //         // setPage(1)
    //         formik.setFieldValue('page', 1)
    //     }
    // }, [
    //     formik.values.category,
    //     formik.values.subcategory,
    //     formik.values.closed_auction_list,
    //     formik.values.sh_limit,
    // ])

    useEffect(() => {
        const { category, subcategory, closed_auction_list, sh_limit } = formik.values

        if (
            category !== prevParamsRef.current.category ||
            subcategory !== prevParamsRef.current.subcategory ||
            closed_auction_list !== prevParamsRef.current.closed_auction_list ||
            sh_limit !== prevParamsRef.current.sh_limit
        ) {
            formik.setFieldValue('page', 1)
        }

        prevParamsRef.current = {
            category,
            subcategory,
            closed_auction_list,
            sh_limit,
        }
    }, [
        formik.values.category,
        formik.values.subcategory,
        formik.values.closed_auction_list,
        formik.values.sh_limit,
    ])
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
        formik.setFieldValue(
            'from_id',
            params.get('q') && params.get('at')
                ? params.get('at')
                : params.get('q')
                ? 0
                : params.get('at')
                ? params.get('at')
                : 1,
        )
    }, [window.location.search])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    const pageOptions = [
        {
            value: '50',
            show: 'Results 50 per page',
        },
        {
            value: '75',
            show: 'Results 75 per page',
        },
        {
            value: '100',
            show: 'Results 100 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]
    return (
        <Layout props={props}>
            <UserActivity page="lot_search" />
            <div className="container mt-5 position-relative">
                <div className="searchMiscAct d-flex justify-content-end flex-wrap my-3">
                    {search_allproducts &&
                        !!totalViewProduct &&
                        totalViewProduct > formik.values.sh_limit &&
                        !isLoading && (
                            <Pagination
                                count={Math.ceil(totalViewProduct / formik.values.sh_limit)}
                                page={formik.values.page}
                                onChange={onHandlePage}
                            />
                        )}
                    <CustomSelect
                        label="Results per page"
                        name="resultsPerPage"
                        selectType="noBorder"
                        value={formik.values.sh_limit}
                        size="small"
                        onChange={(event, editor) => {
                            formik.setFieldValue('sh_limit', event.target.value)
                        }}
                    >
                        {pageOptions.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        label="Sort by"
                        value={formik.values.orderby}
                        size="small"
                        selectType="noBorder"
                        name="orderby"
                        // shrink={search.orderby !== '' ? true : false}
                        onChange={(event, editor) => {
                            formik.setFieldValue('orderby', event.target.value)
                        }}
                    >
                        {sortShow.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <Button
                        className="toggleRespDrawer"
                        variant="outlined"
                        onClick={toggleDrawer('bottom', true)}
                    >
                        <span className="material-icons">tune</span>
                        Filters
                    </Button>
                </div>
                <div>
                    <div className={`filterClass ${isFilterVisible ? 'visible' : ''}`}>
                        <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                            {/* <h4 className="fpTitle">Filters</h4> */}
                            <Button className="filterToggle" onClick={manageFilterPanel}>
                                <span className="material-icons-outlined">filter_list</span>
                                Filters
                                <span className="material-icons-outlined">
                                    {isFilterVisible ? 'expand_less' : 'expand_more'}
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div className={`searchContainer ${isFilterVisible ? 'showLeft' : ''}`}>
                        {isFilterVisible ? (
                            <div className="searchLt">
                                <FilterPanel formik={formik} filterValues={filterValues} />
                            </div>
                        ) : null}
                        <div className="searchRt">
                            {isLoading ? (
                                <div>
                                    <Loaders
                                        name="product_grid_view"
                                        isLoading={isLoading}
                                        loop={6}
                                    />
                                </div>
                            ) : viewProduct.length > 0 ? (
                                <SearchProductList
                                    listview={listview}
                                    searchResult={viewProduct}
                                    className="LiquidationThreeBuyCard"
                                    getAllProducts={getAllProduct}
                                    cardType={auctionView}
                                    audio={audio}
                                    enableZoomOnHover={true}
                                    at={formik.values.from_id}
                                />
                            ) : (
                                <NoRecordsFound />
                            )}
                            {search_allproducts &&
                                !!totalViewProduct &&
                                totalViewProduct > formik.values.sh_limit &&
                                !isLoading && (
                                    <Pagination
                                        count={Math.ceil(totalViewProduct / formik.values.sh_limit)}
                                        page={formik.values.page}
                                        onChange={onHandlePage}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel formik={formik} filterValues={filterValues} />
                </div>
            </Drawer>
        </Layout>
    )
}

export default AuctionSearchComponent
