import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import Timer from '../../../Product/common/timer'
import { capitalize } from '../../../Product/common/components'
// import ReactHtmlParser from 'react-html-parser'
import { imageError } from '../../../utils/commonFunctions'
import AuctionContext from '../../../Product/context/auctionio/auction/auctionContext'
import AuthContext from '../../../Product/context/auth/authContext'
import moment from 'moment'

const ProductAuctionTopComponent = (props) => {
    const [image, setImage] = useState([])
    const { user, isAuthenticated } = useContext(AuthContext)
    const { registerForAuction } = useContext(AuctionContext)
    const [registered, setRegistered] = useState(props.is_registered)
    useEffect(() => {
        let imageUrl = `${
            props.auctionData.store_id !== 0
                ? process.env.REACT_APP_PRODUCT_IMAGE_URL
                : process.env.REACT_APP_BASE_URL + 'uploads/product/'
        }${props.auctionData.avatar}`
        setImage([{ original: imageUrl, thumbnail: imageUrl }])
    }, [props.auctionData])
    const [time, setTime] = useState(new Date())
    const history = useHistory()
    const auctionRegister = async () => {
        const res = await registerForAuction({
            auction_id: props.auctionData.id,
            user_id: user.id,
        })
        setRegistered(res)
    }
    // useEffect(() => {
    //     setInterval(() => {
    //         setTime(new Date())
    //     }, 1000)
    // }, [])
    return (
        <>
            <div className="co-breadcrumb">
                <Link to="/auctions">Search</Link> / {props.auctionData.title}
            </div>
            <div className="pvRespTimer d-none">
                <Timer
                    date_added={props.auctionData.date_added}
                    date_closed={props.auctionData.date_closed}
                    withText={1}
                    endText={'Ends in' + ':'}
                    startText={'Starts in' + ':'}
                    theme="multiseller"
                ></Timer>
            </div>
            <div className="media pv-media product-media-wrapper flex-wrap">
                <div className="media-left">
                    {props.auctionData.avatar ? (
                        <ImageGallery
                            items={image}
                            thumbnailPosition="bottom"
                            showNav={false}
                            showBullets={false}
                            showFullscreenButton={true}
                            showPlayButton={false}
                            originalClass="pro-img img-fluid"
                            showThumbnails={false}
                            onErrorImageURL={imageError()}
                        />
                    ) : (
                        <img
                            src={`${global?.storeDetails?.logoValue}`}
                            className="pro-img img-fluid"
                        />
                    )}
                </div>
                {props.auctionData.title !== undefined && (
                    <div className="media-body">
                        <div className="row">
                            <div className="col-xl-8 col-12">
                                <h5 className="hostedBy">
                                    Hosted by:{' '}
                                    <span>
                                        {props.sellerName ? props.sellerName : storeDetails.name}
                                    </span>
                                </h5>
                                <h2
                                    className="pv-title mb-4"
                                    dangerouslySetInnerHTML={{
                                        __html: capitalize(props.auctionData.title),
                                    }}
                                />
                                {/* {props.auctionData.lot_count && (
                                    <h6>
                                        Total Lots:{' '}
                                        <span className="lotCount">
                                            {props.auctionData.lot_count}
                                        </span>
                                    </h6>
                                )} */}
                                <div className="pv-con">
                                    <span>Auction Start Date: </span>
                                    {moment(props.auctionData.date_added)
                                        .tz('America/New_York')
                                        .format('MMM Do YYYY, h:mm a')}{' '}
                                    ET
                                </div>
                                <div className="pv-con">
                                    <span>Auction End Date: </span>
                                    {moment(props.auctionData.date_closed)
                                        .tz('America/New_York')
                                        .format('MMM Do YYYY, h:mm a')}{' '}
                                    ET
                                </div>
                                <div className="pv-con aucDesc">
                                    <span>Auction Description:</span>
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: props.auctionData.description,
                                    }}
                                />
                                {/* <ReadMoreReact
                                text={props.auctionData.description}
                                min={240}
                                ideal={300}
                                max={600}
                                readMoreText={'READ MORE'}
                            /> */}
                            </div>
                            <div className="col-lg-4 col-12 pvDeskTimer text-center-md">
                                <div className="timer-wrapper">
                                    <div className="li-timer">
                                        <Timer
                                            date_added={props.auctionData.date_added}
                                            date_closed={props.auctionData.date_closed}
                                            withText={1}
                                            icon={true}
                                            endText={'Ends in' + ':'}
                                            startText={'Starts in' + ':'}
                                            theme="multiseller"
                                        ></Timer>
                                    </div>
                                </div>
                                {props.auctionData.con_check && !registered ? (
                                    <div className="ndCTA">
                                        <PrimaryButton
                                            label="Register Live Auction"
                                            onClick={auctionRegister}
                                        />
                                    </div>
                                ) : null}
                                {props.auctionData.con_check && registered ? (
                                    <div className="ndCTA">
                                        {new Date(props.auctionData.date_added) <= time ? (
                                            <PrimaryButton
                                                label="Enter Live Auction"
                                                onClick={() =>
                                                    history.push(
                                                        '/liveLots?auctionId=' +
                                                            props.auctionData.id,
                                                    )
                                                }
                                            />
                                        ) : null}
                                    </div>
                                ) : null}
                                <div className="ndCTA">
                                    <PrimaryButton
                                        label="Browse lots"
                                        onClick={props.executeScroll}
                                    />
                                    <div className="arrow">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ProductAuctionTopComponent
