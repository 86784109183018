import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './cards.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import Timer from '../../Product/common/timer'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import AddToCalendar from '../../Product/components/molecules/AddToCalendar'
import { handleRedirectInternal, sellerNameFormat } from '../../Product/common/components'
import moment from 'moment-timezone'
import { Button } from '@material-ui/core'
import { getImageurl } from '../../utils/commonFunctions'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import ReadMore from '../ReadMore'

const GridItem = ({
    data,
    details,
    isFrame,
    timerTheme,
    // lotCount,
    auctionData,
    theme,
    noActionBtn,
    noviewbutton,
    cardTheme,
}) => {
    const addDefaultSrc = (e) => {
        e.target.src = `${global?.storeDetails?.logoValue}`
    }
    const history = useHistory()
    const [time, setTime] = useState(new Date())
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setTime(new Date())
    //     }, 1000)
    //     return () => clearInterval(interval)
    // }, [])
    return (
        <>
            {data ? (
                <SkeletonTheme key={data.id} color="#d6d6d6" highlightColor="#e8e8e8">
                    <div className={`card grid-card ${data.auction ? 'auciton' : 'buynow'}`}>
                        {data.file_name ? (
                            <div className="grid-img-wrap">
                                <Link to={details} target={isFrame ? '_blank' : ''}>
                                    <LazyLoadImage
                                        src={getImageurl(data.store_id.toString(), data.file_name)}
                                        effect="blur"
                                        placeholderSrc="/assets/svg/imageLoading.svg"
                                        height="100%"
                                        width="100%"
                                        className="gridImage"
                                        onError={addDefaultSrc}
                                        alt={data.title}
                                    />
                                    <span className={`badge ${data.con_check ? 'live' : 'timed'}`}>
                                        {data.con_check ? 'Live' : 'Timed'}
                                    </span>
                                </Link>
                                {/* <div className="curve"></div> */}
                                {/* {lotCount ? (
                                    <span className="lotCount">
                                        <span className="material-icons">view_carousel</span>
                                        {lotCount} lot{lotCount > 1 && 's'}
                                    </span>
                                ) : (
                                    ''
                                )} */}
                            </div>
                        ) : (
                            <Skeleton height={280} width={220} />
                        )}
                        <div className="card-body">
                            {cardTheme != 'Automobile' &&
                            global.storeConfigration?.disable_hosted_by?.value != 1 ? (
                                <>
                                    {data.seller_name ? (
                                        <h6 className="text-center hostedBy">
                                            Hosted By: {sellerNameFormat(data.seller_name)}
                                        </h6>
                                    ) : null}
                                </>
                            ) : (
                                ''
                            )}

                            <div
                                className="list-title"
                                dangerouslySetInnerHTML={{
                                    __html: data.title,
                                }}
                            />
                            {cardTheme == 'multiseller_two' && (
                                <div className="gaInfo text-center">
                                    <label className="mb-1">Description:</label>
                                    <ReadMore
                                        data={data.description}
                                        className="listDesc"
                                        limit={30}
                                    />
                                    {/* <p
                                        className="listDesc"
                                        dangerouslySetInnerHTML={{ __html: data.description }}
                                    /> */}
                                </div>
                            )}
                            {theme == 'HeavyEquipment' && (
                                <div className="gaInfo">
                                    <h6>Auction Id: #{data.id}</h6>
                                    <p>
                                        <span>Start Date:</span>
                                        {moment(data.date_added)
                                            .tz('America/New_York')
                                            .format('MMM Do YYYY, h:mm a')}
                                    </p>
                                    <p>
                                        <span>End Date:</span>
                                        {moment(data.date_closed)
                                            .tz('America/New_York')
                                            .format('MMM Do YYYY, h:mm a')}
                                    </p>
                                    <p
                                        className="gaDesc"
                                        dangerouslySetInnerHTML={{ __html: data?.description }}
                                    ></p>
                                </div>
                            )}

                            <div className="timer-wrapper li-timer-wrap">
                                {data.con_check ? (
                                    <div className="li-timer">
                                        {time < new Date(data.date_added) ? (
                                            <Timer
                                                date_added={data.date_added}
                                                date_closed={data.date_closed}
                                                withText={1}
                                                endText={'Time Left'}
                                                startText="Live Auction Starts In"
                                                theme={timerTheme}
                                            />
                                        ) : (
                                            <p className="text-center">
                                                Started In&nbsp;
                                                {moment(data.date_added)
                                                    .tz('America/New_York')
                                                    .format('MMM Do YYYY, h:mm a')}
                                            </p>
                                        )}
                                    </div>
                                ) : data.type != 'buynow' ? (
                                    <div className="li-timer">
                                        <Timer
                                            date_added={data.date_added}
                                            date_closed={data.date_closed}
                                            withText={1}
                                            endText={'Time Left'}
                                            startText="Starts in"
                                            theme={timerTheme}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            {!noActionBtn && (
                                <>
                                    {theme == 'HeavyEquipment' ? (
                                        <div className="gaCTA">
                                            {!noviewbutton && (
                                                <PrimaryButton
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            parseInt(
                                                                data?.event_enable
                                                                    ? data.event_enable
                                                                    : 0,
                                                            ) == 1
                                                                ? `eventAuction/${data.id}`
                                                                : details,
                                                        )
                                                    }
                                                >
                                                    View Details
                                                </PrimaryButton>
                                            )}
                                            <AddToCalendar data={data} />
                                        </div>
                                    ) : (
                                        <PrimaryButton
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    parseInt(
                                                        data?.event_enable ? data.event_enable : 0,
                                                    ) == 1
                                                        ? `eventAuction/${data.id}`
                                                        : details,
                                                )
                                            }
                                        >
                                            {theme == 'multiseller' || 'MultiSeller_two'
                                                ? 'Browse Auction Lots'
                                                : 'View Auction'}
                                        </PrimaryButton>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </SkeletonTheme>
            ) : null}
        </>
    )
}

export default GridItem
