import React from 'react'
import Skeletons from '../../../Skeletons'
import '../../ProductCard/ProductCard.css'
import './ProductCardSkeletons.css'

function ListViewSkeleton(props) {
    return (
        <div className={`listViewSkeleton ${props.theme ? props.theme : ''}`}>
            <Skeletons type="gridImage" />
            <div>
                <Skeletons type="title" />
                <Skeletons type="text" />
                <Skeletons type="text" />
            </div>
            <div>
                <Skeletons type="text" />
                <Skeletons type="text" />
                <Skeletons type="actionButton" />
            </div>
        </div>
    )
}

export default ListViewSkeleton
