import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Layout from '../Layout'
import './PostProduct.css'
import SEO from '../../../utils/CommonFunctionality/SEO'

import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    mapData,
    jsonToFormData,
    handleRedirectInternal,
    dateTimeFormatFunction,
} from '../../../Product/common/components'
import { getAllMake, getModelById, getModelsOfMake, yearList } from '../../../utils/CarConstants'
import AlertContext from '../../../Product/context/alert/alertContext'
import { swapCountry } from '../../../utils/commonFunctions'
import axios from 'axios'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'

import ProductContext from '../../../Product/context/product/productContext'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const PostProduct = (props) => {
    const classes = useStyles()
    const alertContext = useContext(AlertContext)
    const { type } = useParams()
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0)
    const [stateList, setStateList] = useState([])
    const [countryList, setCountryList] = useState([])
    const history = useHistory()
    const steps = getSteps()
    const [masterCat, setMasterCat] = useState([])
    const [childCat, setChildCategory] = useState([])
    const productContext = useContext(ProductContext)
    const {
        postProduct,
        editProduct,
        edit_product,
        getEditProduct,
        responseStatus,
        getOverAllCategories,
        categories,
        getSubCategories,
        sub_categories,
    } = productContext
    const { setAlert } = alertContext
    const product_id = new URLSearchParams(window.location.search.substring(1)).get('id')
    const repost = new URLSearchParams(window.location.search.substring(1)).get('repost')
    const [reload, setReload] = useState(false)
    const validationArray = Yup.object({
        protitle: Yup.string().required('Vehicle title is required!'),
        customfield8: Yup.string().required('Vehicle year is required!'),
        category_id: Yup.string().required('Vehicle make is required!'),
        subcategory: Yup.string().required('Vehicle model is required!'),
        customfield6: Yup.string().required('Vehicle sold by is required!'),
        location: Yup.string().required('Vehicle country is required!'),
        state: Yup.string().required('State is required!'),
        city: Yup.string().required('Vehicle city is required!'),
        url_1: Yup.string().matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
        url_2: Yup.string().matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
        url_3: Yup.string().matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
        url_4: Yup.string().matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
        url_5: Yup.string().matches(
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
            'Enter valid video link',
        ),
        // work_loc: Yup.array().when('existing_work_loc', {
        //     is: (existing_work_loc) => existing_work_loc.length < 2,
        //     then: Yup.array()
        //         .min(2, 'Please upload front and back of vehicless title or registration')
        //         .required('Required'),
        // }),
        // product_image: Yup.array()
        //     .min(4, 'Please upload min 4 product images')
        //     .required('Required'),
    })

    useEffect(() => {
        getOverAllCategories({ parent: 1 }, '1')
    }, [])

    useEffect(() => {
        setMasterCat(categories)
    }, [categories])

    const ValidationArrayTwo = Yup.object({
        contenthead2: Yup.string().required('VIN is required!'),
        contenthead1: Yup.string().required('Length of ownership is required!'),
        contenthead5:
            type === 'car'
                ? Yup.string()
                      .matches(/^[0-9,]+$/, 'Miles must be a number!')
                      .required('Current miles/hour is required!')
                : '',
        customfield4: Yup.string().required('Engine Size is required!'),
        customfield1: Yup.string().when('qcedby', {
            is: 'car',
            then: Yup.string().required('Transmission Type is required!'),
            // otherwise: Yup.string().required('Front brake is required!'),
        }),
        // issues: Yup.string().required('Issues is required!'),
        // records: Yup.string().required('Service Record History is required!'),
        rprice: Yup.number().when('reserve', {
            is: true,
            then: Yup.number()
                .typeError('Reserve price must be a number!')
                .required('Reserve price is required!')
                .moreThan(Yup.ref('sprice'), 'Reserve price must be greater than start price!'),
        }),
        sprice: Yup.number()
            .typeError('Start price must be a number!')
            .when('reserve', {
                is: true,
                then: Yup.number()
                    .typeError('Start price must be a number!')
                    .required('Start price is required!')
                    .lessThan(Yup.ref('rprice'), 'Start price must be lesser than reserve price!'),
            }),
        bprice: Yup.number().when('buynow', {
            is: true,
            then: Yup.number().when('reserve', {
                is: true,
                then: Yup.number()
                    .typeError('Buynow price must be a number!')
                    .required('Buynow price is required!')
                    .moreThan(
                        Yup.ref('rprice'),
                        'Buynow price must be greater than reserve price!',
                    ),
                otherwise: Yup.number()
                    .typeError('Buynow price must be a number!')
                    .required('Buynow price is required!'),
            }),
        }),
        startdate: Yup.date().required('Start date is required!'),
        enddate: Yup.date()
            .min(Yup.ref('startdate'), 'End date must be greater than start date!')
            .required('End date is required!'),
        description: Yup.string().required('Description is required!'),
        contenthead3: Yup.string().when('qcedby', {
            is: 'car',
            then: Yup.string().required('Exterior color is required!'),
            otherwise: Yup.string().required('Color is required!'),
        }),
        contenthead4: Yup.string().when('qcedby', {
            is: 'car',
            then: Yup.string().required('Interior color is required!'),
            otherwise: Yup.string().required('Mileage is required!'),
        }),
        customfield2: Yup.string().required('Body Style is required!'),
        customfield3: Yup.string().when('qcedby', {
            is: 'car',
            then: Yup.string().required('Drivetrain is required!'),
            // otherwise: Yup.string().required('Rear brake is required!'),
        }),
        final_bid: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
    })

    const productFormik = useFormik({
        initialValues: {
            protitle: '',
            product_id: '',
            auctionid: '',
            auctiontype: 'auction',
            saletype: 'auction_io',
            store_id: 0,
            auction: 1,
            buynow: 0,
            category_id: '',
            subcategory: '',
            customfield8: '', //year
            location: '',
            city: '',
            state: '',
            url_1: '',
            url_2: '',
            url_3: '',
            url_4: '',
            url_5: '',
            url_6: '',
            customfield9: '',
            customfield6: '', // sold by
            work_loc: [],
            existing_work_loc: [],
            deleted_work_loc: [],
            product_image: [],
            product_existingimage: [],
            product_deletedimage: [],
            product_existingvideos: [],
            //page 2 values
            contenthead1: '', // ownership
            contenthead2: '', // vin
            contenthead3: '', // exterior color  // Color
            contenthead4: '', // interior color  // Mileage
            contenthead5: '', // current miles
            contenthead6: '', // engine original
            customfield1: '', // transmission type  // Displacement
            customfield2: '', // body type
            customfield3: '', //drive train // fuel capacity
            customfield4: '', // engine size
            customfield5: '', // service history
            customfield7: '', // additional info
            current_miles: '',
            yourself_miles: '',
            reserve: 0,
            rprice: '',
            sprice: '',
            bprice: '',
            description: '',
            startdate: new Date(),
            enddate: new Date(),
            manufacturer: '',
            // terms: false,
            final_bid: 1,
            qcedby: type === 'car' ? 'car' : 'bike',
        },
        validationSchema: activeStep === 0 ? validationArray : ValidationArrayTwo,
        // enableReinitialize: true,
        onSubmit: (values) => {
            if (activeStep === 0) {
                setActiveStep(1)
            } else {
                let formValues = values
                formValues.category_id =
                    masterCat[masterCat.findIndex((e) => e.id == values.category_id)]?.name
                formValues.auction = formValues.auction == true ? 1 : 0
                formValues.buynow = formValues.buynow == true ? 1 : 0
                formValues.customfield9 = `${values.url_1 != '' ? values.url_1 : ''}${
                    values.url_2 != '' ? ',' + values.url_2 : ''
                }${values.url_3 != '' ? ',' + values.url_3 : ''}${
                    values.url_4 != '' ? ',' + values.url_4 : ''
                }${values.url_5 != '' ? ',' + values.url_5 : ''}${
                    values.url_6 != '' ? ',' + values.url_6 : ''
                }`
                if (formValues.auction == 1 || formValues.buynow == 1) {
                    // alert(JSON.stringify(formValues))
                    let formDataSend = jsonToFormData(formValues)
                    if (product_id && !repost) {
                        editProduct(formDataSend)
                    } else {
                        postProduct(formDataSend)
                    }
                    setLoading(true)
                } else {
                    setAlert('Please select either buynow or auction', 'warning')
                }
            }
        },
    })
    useEffect(() => {
        if (activeStep == 1) {
            let temp = {}
            Object.entries(productFormik.values).forEach(([key, value]) => {
                temp[key] = false
            })
            productFormik.setTouched(temp)
        }
        window.scrollTo(0, 0)
    }, [activeStep])

    const productValues = [
        {
            label: 'Title',
            placeholder: 'Enter Title',
            type: 'text',
            class: 'col-12',
            name: 'protitle',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Year of Vehicle',
            placeholder: 'Select year',
            type: 'select',
            class: 'col-12 col-md-4',
            options: yearList()
                ?.reverse()
                ?.map((data) => {
                    return {
                        value: data,
                        show: data,
                    }
                }),
            defaultOptionValue: 'Select year',
            name: 'customfield8',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Vehicle Make',
            placeholder: 'Select make',
            type: 'select',
            class: 'col-12 col-md-4',
            options:
                masterCat.length > 0 && type === 'car'
                    ? masterCat
                          ?.filter((e) => e.description.trim() == 'car')
                          .map((data) => {
                              return {
                                  value: data.id,
                                  show: data.name,
                              }
                          })
                    : masterCat.length > 0 &&
                      masterCat
                          ?.filter((e) => e.description.trim() == 'Bike')
                          .map((data) => {
                              return {
                                  value: data.id,
                                  show: data.name,
                              }
                          }),
            name: 'category_id',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Vehicle Model',
            placeholder: 'Select model',
            type: 'select',
            class: 'col-12 col-md-4',
            options:
                childCat.length > 0 &&
                childCat.map((data) => {
                    return {
                        value: data.name,
                        show: data.name,
                    }
                }),
            name: 'subcategory',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'What Country Is Vehicle Located In ?',
            placeholder: 'Select country',
            type: 'select',
            class: 'col-12 col-md-6',
            options: swapCountry(countryList).map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.location_id
                return busCategoryChanged
            }),
            name: 'location',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'What City Is Vehicle Located In ?',
            placeholder: 'Enter city',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'city',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'What State, Provence, or Territory Is Vehicle Located In ?',
            placeholder: 'Select state',
            type: 'select',
            class: 'col-12 col-md-6',
            options: stateList.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            }),
            name: 'state',
            formik: productFormik,
            upperLabel: true,
        },
        // {
        //     title: 'Is the vehicle currently listed for sale on any other website?',
        //     type: 'radio',
        //     class: 'col-12 col-md-6',
        //     int: 1,
        //     item: [
        //         { id: 1, description: 'Yes' },
        //         { id: 2, description: 'No' },
        //     ],
        //     name: 'listed',
        //     formik: productFormik,
        //     upperLabel: true,
        // },
        // {
        //     title: 'Has this vehicle been denied for listing by us before?',
        //     type: 'radio',
        //     class: 'col-12 col-md-6',
        //     int: 1,
        //     item: [
        //         { id: 1, description: 'Yes' },
        //         { id: 2, description: 'No' },
        //     ],
        //     name: 'denied',
        //     formik: productFormik,
        // },
        // {
        //     label: 'What changes have been made to the vehicle to justify us reconsidering?',
        //     placeholder: 'What changes made to vehicle to justify reconsidering?',
        //     type: 'text',
        //     class: 'col-12 col-md-6',
        //     name: 'reconsidering',
        //     formik: productFormik,
        //     upperLabel: true,
        //     disabled: productFormik.values.denied != 1 ? true : false,
        // },
        // {
        //     title: 'Are you a Dealer (cars, motorcycle, boats,airplanes, RVs, tailers)',
        //     type: 'radio',
        //     class: 'col-12 col-md-6',
        //     int: 1,
        //     item: [
        //         { id: 1, description: 'Yes' },
        //         { id: 2, description: 'No' },
        //     ],
        //     name: 'dealer',
        //     formik: productFormik,
        // },
        // {
        //     label: 'What is the name of your dealership ?',
        //     placeholder: 'Enter name of your dealership',
        //     type: 'text',
        //     class: 'col-12 col-md-6',
        //     name: 'dealership',
        //     formik: productFormik,
        //     upperLabel: true,
        //     disabled: productFormik.values.dealer != 1 ? true : false,
        // },
        {
            label: 'Vehicle Sold By',
            placeholder: 'Select sold by',
            type: 'select',
            class: 'col-12 col-md-6',
            name: 'customfield6',
            options: [
                {
                    value: 'Private Party',
                    show: 'Private Party',
                },
                {
                    value: 'Dealer',
                    show: 'Dealer',
                },
            ],
            formik: productFormik,
            upperLabel: true,
        },
        {
            label:
                type === 'car'
                    ? 'Provide link to Exterior video of vehicle (Youtube or Vimeo)'
                    : 'Provide link to video of bike (Youtube or Vimeo)',
            placeholder: type === 'car' ? 'Enter exterior video url' : 'Enter bike video url',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'url_1',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Provide link to Interior video of vehicle (Youtube or Vimeo)',
            placeholder: 'Enter interior video url',
            type: 'text',
            class: type === 'car' ? 'col-12 col-md-6' : 'd-none',
            name: 'url_2',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Provide link to Driving video of vehicle (Youtube or Vimeo)',
            placeholder: 'Enter driving video url',
            type: 'text',
            class: type === 'car' ? 'col-12 col-md-6' : 'd-none',
            name: 'url_3',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Provide link to Engine video of vehicle (Youtube or Vimeo)',
            placeholder: 'Enter engine video url',
            type: 'text',
            class: type === 'car' ? 'col-12 col-md-6' : 'd-none',
            name: 'url_4',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Provide link to Cold Start video of vehicle (Youtube or Vimeo)',
            placeholder: 'Enter cold start video url',
            type: 'text',
            class: type === 'car' ? 'col-12 col-md-6' : 'd-none',
            name: 'url_5',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Provide link to Additional video of vehicle (Youtube or Vimeo)',
            placeholder: 'Enter additional video url',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'url_6',
            formik: productFormik,
            upperLabel: true,
        },
    ]

    // const netWorthyValues = Array.from(Array(10), (_, index) => ({
    //     label: `Noteworthy Equipment ${index + 1}`,
    //     placeholder: `Noteworthy Equipment ${index + 1}`,
    //     type: 'text',
    //     class: 'col-12 col-md-6',
    //     name: `noteworthy_${index + 1}`,
    //     formik: productFormik,
    //     upperLabel: true,
    // }))

    // const modificationValues = Array.from(Array(10), (_, index) => ({
    //     label: `Modification ${index + 1}`,
    //     placeholder: `Modification ${index + 1}`,
    //     type: 'text',
    //     class: 'col-12 col-md-6',
    //     name: `modification_${index + 1}`,
    //     formik: productFormik,
    //     upperLabel: true,
    // }))

    // const issueValues = Array.from(Array(10), (_, index) => ({
    //     label: `Issue/Flaw ${index + 1}`,
    //     placeholder: `Issue/Flaw ${index + 1}`,
    //     type: 'text',
    //     class: 'col-12 col-md-6',
    //     name: `issue_${index + 1}`,
    //     formik: productFormik,
    //     upperLabel: true,
    // }))

    // const serviceValues = Array.from(Array(10), (_, index) => ({
    //     label: `Service History ${index + 1}`,
    //     placeholder: `Service History ${index + 1}`,
    //     type: 'text',
    //     class: 'col-12 col-md-6',
    //     name: `service_${index + 1}`,
    //     formik: productFormik,
    //     upperLabel: true,
    // }))

    // const additionalValues = Array.from(Array(10), (_, index) => ({
    //     label: `Additional Values ${index + 1}`,
    //     placeholder: `Additional Values ${index + 1}`,
    //     type: 'text',
    //     class: 'col-12 col-md-6',
    //     name: `additional_${index + 1}`,
    //     formik: productFormik,
    //     upperLabel: true,
    // }))

    const mediaUploader = [
        {
            type: 'uploadDropZone',
            formik: productFormik,
            name: 'work_loc',
            titleText: 'Upload Images Of Vehicles Title',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>
                                Please upload clear, color images of the front and back of
                                vehicles&apos;s title*
                            </span>
                        </b>
                    </p>
                </div>
            ),
            class: 'col-12',
            folder: '',
            multiple: true,
            reorder: true,
            compressImages: false,
            accept: 'image/gif,image/*',
        },
        {
            type: 'previousImages',
            formik: productFormik,
            reorder: false,
            name: 'existing_work_loc',
            class: 'col-md-6 col-12',
            folder: '',
            deleteArray: 'deleted_work_loc',
        },
        {
            type: 'uploadDropZone',
            formik: productFormik,
            name: 'product_image',
            titleText: 'Upload Product Images',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <span>Recommended dimensions: 500px * 500px</span>
                </div>
            ),
            class: 'col-12',
            folder: 'product',
            multiple: true,
            reorder: true,
            accept: 'image/gif,image/*',
            deleteArray: 'product_deletedimage',
        },
        {
            type: 'previousImages',
            formik: productFormik,
            reorder: true,
            name: 'product_existingimage',
            class: 'col-md-6 col-12',
            folder: 'product',
            deleteArray: 'product_deletedimage',
        },
    ]

    const auctionValues = [
        // {
        //     title: 'Is this vehicle a racecar ?',
        //     type: 'radio',
        //     class: 'col-12 col-md-6',
        //     int: 1,
        //     item: [
        //         { id: 1, description: 'Yes' },
        //         { id: 2, description: 'No' },
        //     ],
        //     name: 'racecar',
        //     formik: productFormik,
        //     upperLabel: true,
        // },
        {
            label: 'Length of Ownership',
            placeholder: 'Select years',
            type: 'select',
            class: 'col-12 col-md-6',
            name: 'contenthead1',
            options: [
                ...[{ value: 'New', show: 'New' }],
                ...new Array(99).fill(null).map((_, index) => ({
                    value: `${index + 1} ${index + 1 === 1 ? 'Year' : 'Years'}`,
                    show: `${index + 1} ${index + 1 === 1 ? 'Year' : 'Years'}`,
                })),
            ],
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'What is the Vehicle Identification Number (VIN#)',
            placeholder: 'Enter VIN',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'contenthead2',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: type === 'car' ? 'Exterior Color' : 'Color',
            placeholder: type === 'car' ? 'Enter exterior color' : 'Enter color',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'contenthead3',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: type === 'car' ? 'Interior Color' : 'Mileage',
            placeholder: type === 'car' ? 'Enter interior color' : 'Enter mileage',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'contenthead4',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: type === 'car' ? 'Fuel Type' : 'Fuel Capacity',
            placeholder: type === 'car' ? 'Enter fuel type' : 'Enter fuel capacity',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'manufacturer',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Current miles on vehicle',
            placeholder: 'Enter current miles',
            type: 'text',
            class: type === 'bike' ? 'd-none' : 'col-12 col-md-6',
            name: 'contenthead5',
            formik: productFormik,
            upperLabel: true,
        },
        // {
        //     label: 'How many miles/hours have you added yourself?',
        //     placeholder: 'Enter miles/hours',
        //     type: 'text',
        //     class: 'col-12 col-md-6',
        //     name: 'yourself_miles',
        //     formik: productFormik,
        //     upperLabel: true,
        // },
        {
            title: 'Is Engine original?',
            type: 'radio',
            class: 'col-12 col-md-6',
            int: 1,
            item: [
                { id: 1, description: 'Yes' },
                { id: 2, description: 'No' },
            ],
            name: 'contenthead6',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Transmission Type',
            placeholder: 'Select transmission type',
            type: 'select',
            class: type === 'car' ? 'col-12 col-md-6' : 'd-none',
            name: 'customfield1',
            options: [
                {
                    value: 'Automatic',
                    show: 'Automatic',
                },
                {
                    value: 'Manual',
                    show: 'Manual',
                },
                {
                    value: 'Other',
                    show: 'Other',
                },
            ],
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Front brake',
            placeholder: 'Enter front brake',
            type: 'text',
            class: type === 'bike' ? 'col-12 col-md-6' : 'd-none',
            name: 'customfield1',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Rear Brake',
            placeholder: 'Enter rear brake',
            type: 'text',
            class: type === 'bike' ? 'col-12 col-md-6' : 'd-none',
            name: 'customfield3',
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Body Type',
            placeholder: 'Select body type',
            type: 'select',
            class: type === 'car' ? 'col-12 col-md-6' : 'd-none',
            name: 'customfield2',
            options: [
                {
                    value: 'Convertible',
                    show: 'Convertible',
                },
                {
                    value: 'Coupe',
                    show: 'Coupe',
                },
                {
                    value: 'Sedan',
                    show: 'Sedan',
                },
                {
                    value: 'SUV',
                    show: 'SUV',
                },
                {
                    value: 'Other',
                    show: 'Other',
                },
            ],
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Body Type',
            placeholder: 'Select body type',
            type: 'select',
            class: type === 'bike' ? 'col-12 col-md-6' : 'd-none',
            name: 'customfield2',
            options: [
                {
                    value: 'Commuter',
                    show: 'Commuter',
                },
                {
                    value: 'Cruiser',
                    show: 'Cruiser',
                },
            ],
            formik: productFormik,
            upperLabel: true,
        },
        // {
        //     label: 'Title Status',
        //     placeholder: 'Select title status',
        //     type: 'select',
        //     class: 'col-12 col-md-6',
        //     name: 'title_status',
        //     options: [
        //         {
        //             value: 'Clean',
        //             show: 'Clean',
        //         },
        //         {
        //             value: 'Salvage',
        //             show: 'Salvage',
        //         },
        //         {
        //             value: 'Rebuild',
        //             show: 'Rebuild',
        //         },
        //     ],
        //     formik: productFormik,
        //     upperLabel: true,
        // },
        {
            label: 'Drivetrain',
            placeholder: 'Select drivetrain',
            type: 'select',
            class: type === 'car' ? 'col-12 col-md-6' : 'd-none',
            name: 'customfield3',
            options: [
                {
                    value: 'All Wheel Drive',
                    show: 'All Wheel Drive',
                },
                {
                    value: 'Four Wheel Drive',
                    show: 'Four Wheel Drive',
                },
                {
                    value: 'Front Wheel Drive',
                    show: 'Front Wheel Drive',
                },
                {
                    value: 'Rear Wheel Drive',
                    show: 'Rear Wheel Drive',
                },
                {
                    value: 'Other',
                    show: 'Other',
                },
            ],
            formik: productFormik,
            upperLabel: true,
        },
        {
            label: 'Engine cc',
            placeholder: 'Enter engine cc',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'customfield4',
            formik: productFormik,
            upperLabel: true,
        },
        // {
        //     title: 'Is the Vehicle titled in your name?',
        //     type: 'radio',
        //     class: 'col-12 col-md-6',
        //     int: 1,
        //     item: [
        //         { id: 1, description: 'Yes' },
        //         { id: 2, description: 'No' },
        //     ],
        //     name: 'titled',
        //     formik: productFormik,
        //     upperLabel: true,
        // },
        // {
        //     label: 'What Is The Name On The Title/Registration?',
        //     placeholder: 'Enter title/registration name',
        //     type: 'text',
        //     class: 'col-12 col-md-6',
        //     name: 'titled_name',
        //     formik: productFormik,
        //     upperLabel: true,
        //     disabled: productFormik.values.titled != 1 ? true : false,
        // },
        // {
        //     label: 'What Issues should we know about the vehicle?',
        //     placeholder: 'Enter issues',
        //     type: 'text',
        //     class: 'col-12 col-md-6',
        //     name: 'issues',
        //     formik: productFormik,
        //     upperLabel: true,
        // },
        {
            title: 'Do you have Service Records History?',
            type: 'radio',
            class: 'col-12 col-md-6',
            int: 1,
            item: [
                { id: 1, description: 'Yes' },
                { id: 2, description: 'No' },
            ],
            name: 'customfield5',
            formik: productFormik,
            upperLabel: true,
        },
    ]

    const auctionField = [
        {
            label: 'Auction',
            name: 'auction',
            type: 'checkbox',
            class: 'col-6',
            formik: productFormik,
        },
        {
            label: 'Enable Reserve',
            name: 'reserve',
            type: 'checkbox',
            class: 'col-6',
            formik: productFormik,
            disabled: productFormik.values.auction != 1 ? true : false,
        },
        {
            label: 'Start Price',
            placeholder: 'Enter start price',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'sprice',
            formik: productFormik,
            upperLabel: true,
            disabled: productFormik.values.auction != 1 ? true : false,
        },
        {
            label: 'Reserve price?',
            placeholder: 'Enter reserve price',
            type: 'text',
            class: 'col-12 col-md-6',
            name: 'rprice',
            formik: productFormik,
            upperLabel: true,
            disabled: productFormik.values.reserve != 1 ? true : false,
        },
    ]

    const buynowField = [
        {
            label: 'Buy Now',
            name: 'buynow',
            type: 'checkbox',
            class: 'col-12',
            formik: productFormik,
        },
        {
            label: 'Buy Now Price',
            placeholder: 'Enter buy price',
            type: 'text',
            class: 'col-12',
            name: 'bprice',
            formik: productFormik,
            upperLabel: true,
            disabled: productFormik.values.buynow != 1 ? true : false,
        },
    ]

    const auctionInfo = [
        {
            label: 'Start Date',
            placeholder: 'Enter start date',
            type: 'datetime',
            class: 'col-12 col-md-6',
            name: 'startdate',
            formik: productFormik,
            // upperLabel: true,
        },
        {
            label: 'End Date',
            placeholder: 'Enter end date',
            type: 'datetime',
            class: 'col-12 col-md-6',
            name: 'enddate',
            formik: productFormik,
            // upperLabel: true,
        },
        {
            label: 'Please provide a description below that tells the story of your car',
            placeholder: 'Enter Tax/Removal',
            type: 'ckeditor',
            class: 'col-12',
            name: 'description',
            formik: productFormik,
        },
        {
            label: 'Terms',
            placeholder: 'Enter additional details',
            type: 'ckeditor',
            class: 'col-12',
            name: 'customfield7',
            formik: productFormik,
            upperLabel: true,
        },
        // {
        //     label: [
        //         'I agree to the ',
        //         <Link target="_blank" rel="noreferrer" to={'/termsOfService'} key="termsOfSerice">
        //             terms and conditions
        //         </Link>,
        //     ],
        //     name: 'terms',
        //     type: 'checkbox',
        //     class: 'col-12',
        //     formik: productFormik,
        // },
        {
            label: 'I understand that if the final bid for my vehicle is below the reserve, the auction may choose (at its sole discretion) to make up the difference. In this case the vehicle will appear as sold at the below-reserve price and the auction will pay me the difference between the high bid and the reserve once the transaction is complete.',
            name: 'final_bid',
            type: 'checkbox',
            class: 'col-12',
            formik: productFormik,
        },
    ]

    const getLocationData = () => {
        var body = {
            countryCode: productFormik.values.location,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [productFormik.values.location])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    function getSteps() {
        return ['Basic Info', 'Detailed Info']
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <div className="infrmInfo">
                            {/* <p className="alrtInfo text-danger text-center">
                                *If you do not see the make or model of your vehicle (i.e.Boats,
                                Motorcycles, Parts), select &quot; Other &quot; in BOTH the Make and
                                Model dropdown boxes. Then type in what you have in the text box
                                that appears.
                            </p> */}
                            <div className="row mt-5">{mapData(productValues)}</div>
                            {/* <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Noteworthy Equipment</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="row">{mapData(netWorthyValues)}</div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>Modifications</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="row">{mapData(modificationValues)}</div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                >
                                    <Typography>Issues & Flaws</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="row">{mapData(issueValues)}</div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4a-content"
                                    id="panel4a-header"
                                >
                                    <Typography>Service History</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="row">{mapData(serviceValues)}</div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5a-content"
                                    id="panel5a-header"
                                >
                                    <Typography>Additional Items Included</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="row">{mapData(additionalValues)}</div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion> */}
                            <div className="row mt-4">{mapData(mediaUploader)}</div>
                            <div className="actnBtnWrpr">
                                <PrimaryButton label="Next" onClick={productFormik.handleSubmit} />
                            </div>
                        </div>
                    </>
                )
            case 1:
                return (
                    <>
                        <div className="infrmInfo">
                            <div className="row mt-5">{mapData(auctionValues)}</div>
                            <div className="row mt-3">
                                <div className="brdrDv col-md-6 col-12 pt-3">
                                    <label className="dvLbl">Auction</label>
                                    <div className="row">{mapData(auctionField)}</div>
                                </div>
                                <div className="brdrDv col-md-6 col-12 pt-3">
                                    <label className="dvLbl">Buynow</label>
                                    <div className="row">{mapData(buynowField)}</div>
                                </div>
                            </div>
                            <div className="row mt-5">{mapData(auctionInfo)}</div>
                            <div className="actnBtnWrpr">
                                <SecondaryButton
                                    label="Prev"
                                    btnSize="mr-2"
                                    onClick={() => setActiveStep(0)}
                                />
                                <PrimaryButton
                                    label="Submit"
                                    onClick={productFormik.handleSubmit}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </>
                )
            default:
                return 'Unknown stepIndex'
        }
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'postProduct' || responseStatus.from === 'editProduct') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    // window.onbeforeunload = undefined
                    handleRedirectInternal(history, 'dashboard/mylots')
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (product_id) {
            getEditProduct({ product_id })
        }
    }, [product_id])
    useEffect(() => {
        if (edit_product.product_details && product_id && masterCat.length) {
            let details = edit_product.product_details
            productFormik.values.product_id = details.id ? details.id : ''
            productFormik.values.auctionid = details.auctionid ? details.auctionid : ''
            productFormik.values.category_id = details.category_id
                ? masterCat.find((val) => val.name === details.category_id)?.id
                : ''
            productFormik.values.radcondition = details.conditionunique
                ? details.conditionunique
                : ''
            productFormik.values.subcategory = details.subcategory ? details.subcategory : ''
            productFormik.values.protitle = details.title ? details.title : ''
            productFormik.values.latestbid = details.latestbid ? details.latestbid : ''
            productFormik.values.location = details.location ? details.location : ''
            productFormik.values.state = details.state ? details.state : ''
            productFormik.values.city = details.city ? details.city : ''
            productFormik.values.tags = details.tags ? details.tags.split(',') : []
            productFormik.values.lotof = details.lotof ? details.lotof : ''
            productFormik.values.auction = details.auction ? true : false
            productFormik.values.buynow = details.buynow ? true : false
            productFormik.values.sprice = details.sprice ? details.sprice : ''
            productFormik.values.rprice = details.rprice ? details.rprice : ''
            productFormik.values.reserve = details.rprice && parseInt(details.rprice) > 0 ? 1 : 0
            productFormik.values.selectedbuyers = details.selectedbuyers
                ? details.selectedbuyers.split(',').map((i) => parseInt(i))
                : []
            productFormik.values.prev_rprice = details.rprice ? details.rprice : ''
            productFormik.values.bprice = details.bprice && details.buynow ? details.bprice : ''
            productFormik.values.startdate = details.date_added
                ? dateTimeFormatFunction(details.date_added, true)
                : dateTimeFormatFunction(new Date())
            if (repost) {
                productFormik.values.start_date = dateTimeFormatFunction(new Date())
            } else {
                productFormik.values.start_date = details.date_added
                productFormik.values.end_date = details.date_closed
            }
            productFormik.values.qty = details.qty ? details.qty : 1
            productFormik.values.customfield1 = details.custom_field_1
            productFormik.values.customfield2 = details.custom_field_2
            productFormik.values.customfield3 = details.custom_field_3
            productFormik.values.customfield4 = details.custom_field_4
            productFormik.values.customfield5 = details.custom_field_5
            productFormik.values.customfield6 = details.custom_field_6
            productFormik.values.customfield7 = details.custom_field_7
            productFormik.values.customfield8 = details.custom_field_8
            productFormik.values.manufacturer = details.manufacturer
            var URL = details.custom_field_9.split(',')
            URL.map((val, index) => (productFormik.values[`url_${index + 1}`] = val?.trim()))
            productFormik.values.buyerpremium_percentage = details.buyerpremium_percentage
                ? details.buyerpremium_percentage
                : 0
            productFormik.values.enddate = details.date_closed
                ? dateTimeFormatFunction(details.date_closed, true)
                : dateTimeFormatFunction(new Date())
            if (!repost) {
                if (details.videos) {
                    productFormik.values.product_existingvideos = details.videos.split(',')
                }

                productFormik.values.product_image = edit_product.attachments.map((i) => i.src)
            }
            productFormik.values.existing_work_loc = details.work_loc.split(',')
            productFormik.values.paymentoptionresult = details.paymentoptionresult
                ? details.paymentoptionresult
                : ''
            productFormik.values.market_status = details.market_status
            productFormik.values.contenthead1 = details.content_head1 ? details.content_head1 : 0
            productFormik.values.contenthead2 = details.content_head2
                ? details.content_head2
                : global.products_detail
            productFormik.values.contenthead3 = details.content_head3
                ? details.content_head3
                : global.products_terms
            productFormik.values.hasshipping = details.hasshipping ? 1 : 0
            productFormik.values.sell_location = details.sell_location ? details.sell_location : 0
            // productFormik.values.qcedby = details.qcdby == 1 || details.qcdby == 'true' ? 1 : 0
            productFormik.values.sku = details.sku ? details.sku : ''
            productFormik.values.description = details.description ? details.description : ''
            productFormik.values.weight = details.weight ? details.weight : 0
            productFormik.values.length = details.length ? details.length : 0
            productFormik.values.width = details.width ? details.width : 0
            productFormik.values.subcategory = details.subcategory ? details.subcategory : ''
            productFormik.values.height = details.height ? details.height : 0
            productFormik.values.contenthead4 = details.content_head4 ? details.content_head4 : ''
            productFormik.values.contenthead5 = details.content_head5
            productFormik.values.contenthead6 = details.content_head6
            productFormik.values.sustainability = details.content_head5.split(',').map(Number)
                ? details.content_head5.split(',').map(Number)
                : []
            productFormik.values.product_deletedimage = []
            productFormik.values.product_deletedvideos = []
            productFormik.values.shippingcost = details.shippingcost
            productFormik.values.is_digital = details.is_digital ? details.is_digital : 0
            productFormik.values.inventory_loc = details.inventory_loc ? details.inventory_loc : ''
            productFormik.values.extbidding = details.extbidding ? details.extbidding : ''
            productFormik.values.lowest = details.lowest ? details.lowest : 0
            productFormik.values.highest = details.lowest ? details.highest : 0
            setLoading(false)
            setReload(!reload)
        }
    }, [edit_product.product_details, masterCat])

    useEffect(() => {
        if (productFormik.values.category_id != '') {
            getSubCategories({ parent_id: productFormik.values.category_id })
        }
    }, [productFormik.values.category_id])

    useEffect(() => {
        setChildCategory(sub_categories)
    }, [sub_categories])
    useEffect(() => {
        if (edit_product.product_details && product_id) {
            setReload(!reload)
        }
    }, [childCat])

    return (
        <Layout props={props}>
            <SEO title={`${global?.storeDetails?.name} | Post single lot`} />
            <div className="carPstWrpr">
                <div className="carPostList">
                    <h3 className="pstHdr text-center">Submit a Vehicle</h3>
                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            <Typography className={classes.instructions}>
                                {getStepContent(activeStep)}
                            </Typography>
                        </div>
                        {/* <div>
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        All steps completed
                                    </Typography>
                                    <Button onClick={handleReset}>Reset</Button>
                                </div>
                            ) : (
                                <div>
                                    <Typography className={classes.instructions}>
                                        {getStepContent(activeStep)}
                                    </Typography>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.backButton}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                        >
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div> */}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PostProduct
