import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import Timer from '../../../Product/common/timer'
import {
    capitalize,
    handleRedirectInternal,
    getClientTimezoneDate,
} from '../../../Product/common/components'
// import ReactHtmlParser from 'react-html-parser'
import { imageError } from '../../../utils/commonFunctions'
import AuctionContext from '../../../Product/context/auctionio/auction/auctionContext'
import AuthContext from '../../../Product/context/auth/authContext'
import moment from 'moment'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import Alert from '@material-ui/lab/Alert'
const AuctionTop = (props) => {
    const [image, setImage] = useState([])
    const { user, isAuthenticated } = useContext(AuthContext)
    const { registerForAuction } = useContext(AuctionContext)
    const [registered, setRegistered] = useState(props.registeredUser)
    useEffect(() => {
        let imageUrl = `${
            props.auctionData.store_id !== 0
                ? process.env.REACT_APP_PRODUCT_IMAGE_URL
                : process.env.REACT_APP_BASE_URL + 'uploads/product/'
        }${props.auctionData.avatar}`
        setImage([{ original: imageUrl, thumbnail: imageUrl }])
    }, [props.auctionData])
    const [time, setTime] = useState(new Date())
    const history = useHistory()
    const [timerView, setTimerView] = useState(true)
    const auctionRegister = async () => {
        var body = {
            auction_id: props.auctionData.id,
            user_id: user.id,
            status: 'moderate',
            // site_id: window.location.href.toString().includes('moblearn') ? 'AS0136' : 'AS0137',
            // ipaddress: ipaddresss,
        }
        registerForAuction(body, 'regAuction')
    }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setTime(new Date())
    //     }, 1000)
    //     return () => clearInterval(interval)
    // }, [])
    useEffect(() => {
        setRegistered(props.registeredUser)
    }, [props.registeredUser])

    const timerShow = () => {
        if (props.auctionData && new Date() >= new Date(props.auctionData.date_added)) {
            // console.log('date if condition check inside if')
            setTimerView(false)
            clearInterval(timerInterval)
        }
    }

    const timerInterval = setInterval(timerShow, 1000)

    const handleLiveAuctionClick = () => {
        //Check is user is approved to view live auction
        //Add is Register approved to the AuctionLotAPI
        history.push({
            pathname: '/liveLots',
            search: `?auctionId=${props.auctionData.id}&uid=${user.id ? user.id : 0}`,
        })
    }
    return (
        <>
            <div className="co-breadcrumb">
                <Link to="/auctions">Search</Link> / {props.auctionData.title}
            </div>
            <div className="pvRespTimer d-none">
                <Timer
                    date_added={props.auctionData.date_added}
                    date_closed={props.auctionData.date_closed}
                    withText={1}
                    endText={'Ends in' + ':'}
                    startText={'Live Auction Starts In' + ':'}
                    theme="multiseller"
                ></Timer>
            </div>
            <div className="media pv-media product-media-wrapper flex-wrap">
                <div className="media-left">
                    {props.auctionData.avatar ? (
                        <ImageGallery
                            items={image}
                            thumbnailPosition="bottom"
                            showNav={false}
                            showBullets={false}
                            showFullscreenButton={true}
                            showPlayButton={false}
                            originalClass="pro-img img-fluid"
                            showThumbnails={false}
                            onErrorImageURL={imageError()}
                        />
                    ) : (
                        <img
                            src={`${global?.storeDetails?.logoValue}`}
                            className="pro-img img-fluid"
                        />
                    )}
                </div>
                {props.auctionData.title !== undefined && (
                    <div className="media-body">
                        <div className="row">
                            <div className="col-xl-8 col-12">
                                {/* <h5 className="hostedBy">
                                    Hosted by:{' '}
                                    <span>
                                        {props.sellerName ? props.sellerName : storeDetails.name}
                                    </span>
                                </h5> */}
                                {Object.keys(registered).length &&
                                registered.status !== 'active' ? (
                                    <div className="lotInfoReg d-flex align-items-center">
                                        <span className="material-icons">error</span>
                                        Registration Pending, We&rsquo;ll send you an email once
                                        you&rsquo;ve been approved to bid
                                    </div>
                                ) : null}
                                <h2
                                    className="pv-title mb-4"
                                    dangerouslySetInnerHTML={{
                                        __html: capitalize(props.auctionData.title),
                                    }}
                                />
                                {/* {props.auctionData.lot_count && (
                                    <h6>
                                        Total Lots:{' '}
                                        <span className="lotCount">
                                            {props.auctionData.lot_count}
                                        </span>
                                    </h6>
                                )} */}
                                <div className="pv-con">
                                    <span>Auction Start Date: </span>
                                    {getClientTimezoneDate(props.auctionData.date_added)}
                                </div>
                                {/* <div className="pv-con">
                                    <span>Auction End Date: </span>
                                    {moment(props.auctionData.date_closed)
                                        .tz('America/New_York')
                                        .format('MMM Do YYYY, h:mm a')}{' '}
                                    ET
                                </div> */}
                                <div className="pv-con aucDesc">
                                    <span>Auction Description:</span>
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: props.auctionData.description,
                                    }}
                                />
                                {/* <ReadMoreReact
                                text={props.auctionData.description}
                                min={240}
                                ideal={300}
                                max={600}
                                readMoreText={'READ MORE'}
                            /> */}
                            </div>
                            <div className="col-lg-4 col-12 pvDeskTimer text-center-md">
                                <div className="timer-wrapper">
                                    {timerView ? (
                                        <div className="li-timer">
                                            <Timer
                                                date_added={props.auctionData.date_added}
                                                date_closed={props.auctionData.date_closed}
                                                endText={'Time Left'}
                                                startText={'Live Auction Starts In'}
                                                withText={1}
                                                theme="multiseller"
                                            />
                                        </div>
                                    ) : (
                                        `Started In ${getClientTimezoneDate(
                                            props.auctionData.date_added,
                                        )}
                                    `
                                    )}
                                </div>
                                {!registered.user_id ? (
                                    <div className="ndCTA mb-2 w-100 mt-2">
                                        <PrimaryButton
                                            label="Register Live Auction"
                                            onClick={
                                                isAuthenticated
                                                    ? auctionRegister
                                                    : () => handleRedirectInternal(history, 'login')
                                            }
                                        />
                                    </div>
                                ) : null}
                                {registered.user_id ? (
                                    <div className="ndCTA mb-2 w-100 mt-2">
                                        {time > new Date(props.auctionData.date_closed) ? null : (
                                            <PrimaryButton
                                                label="Enter Live Auction"
                                                onClick={() => handleLiveAuctionClick()}
                                            />
                                        )}
                                    </div>
                                ) : null}
                                <div className="ndCTA w-100">
                                    <SecondaryButton
                                        label="Browse lots"
                                        onClick={props.executeScroll}
                                    />
                                    <div className="arrow">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default AuctionTop
