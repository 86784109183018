import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SlidePanel from '../../../Component/SlidePanel'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import { messageHandler } from '../../../Product/common/socketHandler'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import { socket, socketAuction } from '../../../Product/common/socket'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])

    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { search_allproducts, getAllSearchProducts } = productContext
    const [auctionView, setAuctionView] = useState('Grid')

    const { isAuthenticated, user } = authContext
    const { setAlert } = alertContext

    const classes = useStyles()

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalItems, setTotalItem] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    const [searchValues, setSearchValues] = useState({
        searchbar: '',
    })

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    const multiauction_handler = (message, type) => {
        var index = viewProductRef.current.findIndex(
            (s) => s.id === parseInt(message.id || message.project_id, 10),
        )
        if (index !== -1) {
            if (parseInt(viewProductRef.current[index].content_head5) == 0) {
                messageHandler(
                    message,
                    viewProductRef.current,
                    userRef.current,
                    setAlert,
                    setViewProduct,
                    type,
                )
            }
        }
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            data.id = data.pid
            //data.bpop_cbidder = data.usr
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (global?.storeDetails?.theme == 9) {
                multiauction_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })
        socketAuction.on('bidAddtime', (data) => {
            if (global?.storeDetails?.theme == 9) {
                multiauction_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                if (global?.storeDetails?.theme == 9) {
                    multiauction_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })

            socketAuction.off('bidAddtime', (data) => {
                if (global?.storeDetails?.theme == 9) {
                    multiauction_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
        }
    }, [])

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const params = new URLSearchParams(window.location.search.substring(1))

    const formik = useFormik({
        initialValues: {
            sh_limit: 12,
            page: 1,
            orderby: '6',
            location: [],
            state: [],
            city: [],
            category: catg ? [catg] : [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            miles: params.get('m') ? params.get('m') : '',
            from: 'auction_only',
            closed_auction_list: 0,
            view_type: 0,
            multiauction: 1,
            contenthead5: ['0'],
        },
    })

    const filterValues = [
        // {
        //     title: 'View',
        //     type: 'radio',
        //     noLabel: true,
        //     name: 'view_type',
        //     int: 1,
        //     class: 'col-12',
        //     item: [
        //         { id: 0, description: 'All Items' },
        //         { id: 1, description: 'Single Lots' },
        //         { id: 2, description: 'Buy Now' },
        //     ],
        //     formik: formik,
        // },
        {
            title: 'Auction Status',
            type: 'radio',
            noLabel: true,
            name: 'closed_auction_list',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'Live Items' },
                { id: 1, description: 'Closed Items' },
            ],
            formik: formik,
        },
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories
                .sort((a, b) => a.description.localeCompare(b.description))
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },
        {
            title: 'Sub category',
            type: 'check',
            name: 'subcategory',
            options: global.searchSubCategories.filter((subcat) => {
                // if (
                //     formik &&
                //     formik.values &&
                //     formik.values.category &&
                //     formik.values.category.length
                //         ? formik.values.category.includes(subcat.level_id.toString())
                //         : false
                // ) {
                return true
                //}
            }),
            formik: formik,
        },
    ]

    useEffect(() => {
        setLoading(false)

        // if (search_allproducts.from === 'searchPage') {
        setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
        setTotalItem(search_allproducts.total_pagecnt)
        // }
    }, [search_allproducts, formik.values])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        formik.setFieldValue('page', value)
    }
    useEffect(() => {
        setLoading(true)
        if (formik.values.closed_auction_list) {
            formik.values.orderby = '7'
        }
        if (global.pluginConfiguration?.reverse_auction?.enable) {
            formik.values.contenthead5 = ['0', '1']
        }

        // if (formik.values.view_type == 1) {
        //     formik.setFieldValue('from', 'auction_only')
        // } else if (formik.values.view_type == 2) {
        //     formik.setFieldValue('from', 'buynow')
        // } else formik.setFieldValue('from', '')

        getAllSearchProducts(formik.values)
    }, [isAuthenticated, formik.values])
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('miles', params.get('m') ? params.get('m') : '')
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
        setSearchValues({ ...searchValues, searchbar: searchbar })
        if (params.get('product')) {
            handleClick(params.get('product'))
        }
    }, [window.location.search])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const checkreverseauction = () => {
        //console.log('oooooooooooooooooooooooooooooooooooooooooooooooooooo', selectedProduct)
        if (selectedProduct) {
            return viewProduct.filter((val) => parseInt(val.id) == parseInt(selectedProduct))[0]
                .content_head5
        }
    }

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]

    const handleChange = (e) => {
        setSearchValues({ ...searchValues, [e.target.name]: e.target.value })
    }

    const handleSearch = (e) => {
        e.preventDefault()
        // setAuctionSearch({ ...auctionSearch, searchbar: searchValues.searchbar })
        formik.setFieldValue('searchbar', searchValues.searchbar)
    }

    return (
        <Layout props={props}>
            <div className="customContainer mt-5 position-relative lotSearch">
                <div className="d-flex justify-content-between align-items-center flex-wrap my-5">
                    <h2 className="auctionListTitle"></h2>
                    <form className="nav-standard-top-area-search">
                        <div className="nav-standard-top-area-search-inner">
                            <div>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    name="searchbar"
                                    value={searchValues.searchbar}
                                    className="nav-standard-top-area-search-input open-sans"
                                    placeholder="Search"
                                />
                            </div>
                            <button
                                onClick={handleSearch}
                                className="lin lin-magnifier nav-standard-top-area-search-sumbit"
                            >
                                <span className="material-icons">search</span>
                            </button>
                        </div>
                    </form>

                    <div className="auctionsMiscFilter">
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => setAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => setAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={formik.values.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                formik.setFieldValue('sh_limit', event.target.value)
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>

                        <CustomSelect
                            label="Sort by"
                            value={formik.values.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                formik.setFieldValue('orderby', event.target.value)
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    </div>
                    <Button
                        className="toggleRespDrawer w-100"
                        variant="outlined"
                        onClick={toggleDrawer('bottom', true)}
                    >
                        <span className="material-icons">tune</span>
                        Filters
                    </Button>
                </div>
                <div className="searchContainer">
                    <div className="searchLt">
                        <FilterPanel formik={formik} filterValues={filterValues} />
                    </div>

                    <div className="searchRt">
                        {isLoading ? (
                            <div>
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                            </div>
                        ) : viewProduct.length === 0 ? (
                            <NoRecordsFound />
                        ) : (
                            <div
                                className={`lotResults ${auctionView} liquidationBuyCard ${
                                    !isAuthenticated && 'unauth'
                                }`}
                            >
                                {viewProduct.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <>
                                            {
                                                <GridProduct
                                                    data={data}
                                                    className={data.auction ? 'auction' : 'buynow'}
                                                    key={index}
                                                    bidStatus={true}
                                                    type={data.auction ? 'auction' : 'buynow'}
                                                    selectedProduct={selectedProduct}
                                                    link={`/gallery/product-view/${data.id}`}
                                                    handleClick={handleClick}
                                                    timerTheme=""
                                                    sliderTheme="multiseller"
                                                    currencyType=""
                                                    cardTheme="multiseller"
                                                    bidBtnLabel="Place Bid"
                                                />
                                            }
                                        </>
                                    ) : (
                                        <MultisellerListCard
                                            lotData={data}
                                            className={data.auction ? 'auction' : 'buynow'}
                                            key={index}
                                            selectedProduct={selectedProduct}
                                            allsearchitems={viewProduct}
                                            type="lot"
                                            handleClick={handleClick}
                                            details={`/gallery/product-view/${data.id}`}
                                            sliderTheme="multiseller"
                                            bidBtnLabel={
                                                <>
                                                    <span className="material-icons-outlined">
                                                        gavel
                                                    </span>
                                                    Bid
                                                </>
                                            }
                                        />
                                    ),
                                )}
                            </div>
                        )}
                        <Pagination
                            count={Math.ceil(totalItems / formik.values.sh_limit)}
                            page={formik.values.page}
                            onChange={onHandlePage}
                        />
                    </div>
                </div>
            </div>

            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel formik={formik} filterValues={filterValues} />
                </div>
            </Drawer>
            <MultisellerSlider
                reverse_auction={
                    selectedProduct && viewProduct.length > 0 ? checkreverseauction() : 0
                }
                selectedLot={selectedProduct}
                handleClose={handleClose}
                currency="cad"
                type={props.type ? props.type : ''}
            />
        </Layout>
    )
}

export default AuctionSearchComponent
