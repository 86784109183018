import React from 'react'
import '../Login/Login.css'

import Layout from '../Layout'
import ForgotPasswordFunction from '../../../utils/CommonFunctionality/ForgotPasswordFunction'
import { Logo } from '../../../utils/index'

const ForgotPassword = () => {
    return (
        <Layout>
            <div className="clearfix row vcaLogin rstPaswd">
                <div className="col-12">
                    <div className="loginFormBox py-5">
                        <div className="lgnOtrWrpr">
                            <Logo className="headerLogo" />
                            <ForgotPasswordFunction />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ForgotPassword
