import React from 'react'

export const NextArrow = (props) => {
    return (
        <button type="button" className="slick-next pull-left" onClick={props.onClick}>
            <i className="fa fa-chevron-right"></i>
        </button>
    )
}

export const PrevArrow = (props) => {
    return (
        <button type="button" className="slick-prev pull-right" onClick={props.onClick}>
            <i className="fa fa-chevron-left"></i>
        </button>
    )
}
