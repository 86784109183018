import React from 'react'
import CartComponent from '../../../utils/CommonFunctionality/Cart'

const PosCheckout = (props) => {
    return (
        <div className="cartOuterWrapper">
            <CartComponent props={props} />
        </div>
    )
}

export default PosCheckout
