import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import Layout from '../Layout'
import './PostProduct.css'
import {
    dateTimeFormatFunction,
    handleRedirectInternal,
    jsonToFormData,
    mapData,
} from '../../../Product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useHistory, Prompt } from 'react-router-dom'
import {
    getLocation,
    horseAges,
    horseColor,
    horseGait,
    horseGender,
    horseNonProfit,
    horseTypes,
} from '../../../utils'
import Loader from '../../../assets/loader'
import AlertContext from '../../../Product/context/alert/alertContext'
const FishingPostProduct = (props) => {
    const [isBlocking, setIsBlocking] = useState(false)
    const [loading, setLoading] = useState(false)
    const [country, setCountry] = useState([])
    const [state, setState] = useState([])
    const [reload, setReload] = useState(false)
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const [bidincarr, setBidIncarr] = useState([])
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { isAuthenticated } = authContext
    const {
        postProduct,
        categories,
        getOverAllCategories,
        editProduct,
        edit_product,
        getEditProduct,
        responseStatus,
    } = productContext
    const history = useHistory()
    const searchParams = new URLSearchParams(window.location.search.substring(1))
    const product_id = searchParams.get('id')
    const repost = searchParams.get('repost')
    const [Loaders, setLoaders] = useState(product_id ? false : true)
    const [subCategory, setSubCategory] = useState([])
    const validationArray = Yup.object({
        protitle: Yup.string().required('Required!'),
        category_id: Yup.string().required('Required!'),
        subcategory: Yup.string().required('Required!'),
        sku: Yup.string(),
        contenthead3: Yup.string().required('Required!'),
        location: Yup.string().required('Required!'),
        state: Yup.string().when('isValid', {
            is: () => (state?.length > 0 ? true : false),
            then: Yup.string().required('Required!'),
            otherwise: Yup.string().notRequired(),
        }),
        city: Yup.string().required('Required!'),
        zipcode: Yup.string().trim().required('Required!'),
        description: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('Description Required!'),
        }),
        sprice: Yup.number().when('auctiontype', {
            is: 'auction',
            then: Yup.number()
                .required('Start Price Required!')
                .positive('Should be greater than 1')
                .min(1, 'Should be greater than 1'),
        }),
        rprice: Yup.number().when('auctiontype', {
            is: 'auction',
            then: Yup.number()
                .positive('Should be greater than 1')
                .min(Yup.ref('sprice'), 'Should be equal or greater than the starting price')
                .required('Reserve Price Required!'),
        }),
        bprice: Yup.number().when('auctiontype', {
            is: 'buynow',
            then: Yup.number()
                .required('Buynow Price Required!')
                .positive('Should be greater than 1'),
        }),
        mprice: Yup.number().when('auctiontype', {
            is: 'buynow',
            then: Yup.number()
                .required('Retail Price Required!')
                .positive('Should be greater than 1'),
        }),
    })
    const formik = useFormik({
        initialValues: {
            auction_io: 1,
            product_id: '',
            saletype: 'auction_io',
            store_id: 0,
            protitle: '',
            category_id: '',
            radcondition: '',
            contenthead1: '0',
            contenthead2: '',
            contenthead3: '',
            contenthead4: '',
            contenthead5: '',
            contenthead6: '',
            customfield1: '',
            customfield2: '',
            customfield3: '',
            customfield4: '',
            customfield5: '',
            customfield6: 0,
            customfield7: '+1',
            //customfield8: '',
            customfield9_video_url: '',
            location: '',
            state: '',
            city: '',
            zipcode: '',
            isValid: true,
            manufacturer: '',
            auctionnotes: '',
            tags: '+1',
            sell_location: '',
            description: '',
            modelnumber: '',
            subcategory: '',
            sprice: '',
            rprice: '',
            bprice: '',
            enable_rprice: 0,
            startprice: '',
            startdate: new Date(),
            enddate: new Date(),
            auctiontype: 'auction',
            facts: 'fish',
            auction: 1,
            buynow: 0,
            biddeposit: '',
            rptype: '',
            transactionid: '',
            paymenttype: '',
            paymentfor: '',
            product_id: '',
            auctionid: '',
            selling_type: 0,
            work_loc: [],
            existing_work_loc: [],
            deleted_work_loc: [],
            product_image: [],
            product_existingimage: [],
            product_deletedimage: [],
            product_videos: [],
            product_existing_videos: [],
            product_deletedvideos: [],
            image: [],
            existing_image: [],
            deleted_image: [],
            document_field_1: [],
            existing_document_field_1: [],
            deleted_document_field_1: [],
            start_price_for_lot: 1,
            cprice_for_lot: 0,
            enable_bid_increment: 0,
            field1: '',
            field2: '',
            field3: '',
            field4: '',
            field5: '',
            field6: '',
            field7: '',
            field8: '',
            field9: '',
            field10: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            let formValues = values
            if (formValues.auctiontype == 'buynow') {
                formValues.no_cron = 1
            }
            if (product_id && !repost && formik.values.cprice_for_lot) {
                formValues.update_project_after_bid_reseller = 1
            }
            let temp = [
                {
                    heading: formValues.facts === 'fish' ? 'Fishing Facts' : 'Coral Facts',
                },
            ]
            extraFields.map((val) => {
                temp.push({ heading: val.label, value: formValues[val.name] })
            })
            formValues.customfield8 = JSON.stringify({ address: '', zipcode: formValues.zipcode })
            formValues.extra_fields = JSON.stringify(temp)
            let formDataSend = jsonToFormData(formValues)
            if (product_id && !repost) {
                editProduct(formDataSend)
            } else {
                formDataSend.set('customfield9', formValues.customfield9_video_url)
                postProduct(formDataSend)
            }
            setLoading(true)
        },
    })
    useEffect(() => {
        if (isAuthenticated) {
            if (product_id) {
                getEditProduct({ product_id })
            }
            getOverAllCategories({}, global?.storeConfigration?.cat_mis_match?.value)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'postProduct' || responseStatus.from === 'editProduct') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    // window.onbeforeunload = undefined
                    handleRedirectInternal(history, 'dashboard/mylots')
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (formik.values.enddate && formik.values.startdate) {
            if (moment(formik.values.enddate).isSameOrBefore(formik.values.startdate)) {
                formik.values.enddate = null
                return setReload(!reload)
            }
        }
    }, [formik.values.startdate, formik.values.enddate])
    useEffect(() => {
        if (formik.values.location) {
            getLocation(formik.values.location, setState)
        } else {
            getLocation('', setCountry)
        }
    }, [formik.values.location])
    // useEffect(() => {
    //     setIsBlocking(formik.dirty)
    // }, [formik.dirty])
    // useEffect(() => {
    //     if (isBlocking) {
    //         window.onbeforeunload = () => true
    //     } else {
    //         window.onbeforeunload = undefined
    //     }
    // }, [isBlocking])
    useEffect(() => {
        if (formik.values.auctiontype === 'auction') {
            formik.setFieldValue('auction', 1)
            formik.setFieldValue('buynow', 0)
        }
        if (formik.values.auctiontype === 'buynow') {
            formik.setFieldValue('auction', 0)
            formik.setFieldValue('buynow', 1)
        }
    }, [formik.values.auctiontype])
    useEffect(() => {
        if (formik.values.category_id) {
            setSubCategory(
                categories.find((val) => val.id == formik.values.category_id)?.filters || [],
            )
        }
    }, [formik.values.category_id])
    useEffect(() => {
        if (edit_product.product_details && product_id) {
            let details = edit_product.product_details
            let address = JSON.parse(details.custom_field_8 || '{}')
            let extra = JSON.parse(details.extra_fields || '{}')
            let extra_first = extra.shift()
            let loopData = {
                product_id: details.id,
                auctionid: details.auctionid,
                category_id: details.category_id,
                radcondition: details.conditionunique,
                subcategory: details.subcategory,
                protitle: details.title,
                latestbid: details.latestbid,
                location: details.location,
                state: details.state,
                city: details.city,
                tags: details.tags,
                auction: details.auction,
                buynow: details.buynow,
                sprice: details.sprice,
                rprice: details.rprice,
                bprice: details.bprice,
                mprice: details.mprice,
                startdate: details.date_added
                    ? dateTimeFormatFunction(details.date_added, true)
                    : dateTimeFormatFunction(new Date()),
                enddate: details.date_closed
                    ? dateTimeFormatFunction(details.date_closed, true)
                    : dateTimeFormatFunction(new Date()),
                customfield1: details.custom_field_1,
                customfield2: details.custom_field_2,
                customfield3: details.custom_field_3,
                customfield4: details.custom_field_4,
                customfield5: details.custom_field_5,
                customfield6: details.custom_field_6,
                customfield7: details.custom_field_7,
                customfield8: address,
                zipcode: address.zipcode,
                customfield9_video_url: details.custom_field_9,
                market_status: details.market_status,
                contenthead1: details.content_head1,
                contenthead2: details.content_head2,
                contenthead3: details.content_head3,
                sell_location: details.sell_location,
                description: details.description,
                subcategory: details.subcategory,
                contenthead1: details.content_head1,
                contenthead2: details.content_head2,
                contenthead3: details.content_head3,
                //contenthead4: details.content_head4,
                facts: extra_first.heading.includes('Coral') ? 'coral' : 'fish',
                contenthead5: details.content_head5,
                contenthead6: details.content_head6,
                modelnumber: details.modelnumber,
                manufacturer: details.manufacturer,
                auctionnotes: details.auctionnotes,
                product_deletedimage: [],
                product_deletedvideos: [],
                deleted_image: [],
                deleted_work_loc: [],
                deleted_document_field_1: [],
                product_existingimage: !repost ? edit_product.attachments.map((i) => i.src) : [],
                product_existing_videos: !repost && details.videos ? details.videos.split(',') : [],
                existing_image: !repost && details.image ? details.image.split(',') : [],
                existing_work_loc: !repost && details.work_loc ? details.work_loc.split(',') : [],
                existing_document_field_1:
                    !repost && details.document_field_1 ? details.document_field_1.split(',') : [],
                auctiontype: details.auction
                    ? 'auction'
                    : details.buynow
                    ? 'buynow'
                    : 'contactseller',
                saletype: 'auction_io',
                store_id: 0,
                work_loc: [],
                document_field_1: [],
                product_image: [],
                product_videos: [],
                image: [],
                cprice_for_lot: details.latestbid ? details.latestbid : '',
                start_price_for_lot: details.sprice ? details.sprice : 1,
                //enable_bid_increment: details.content_head4 ? 1 : 0,
            }
            extra.map((value, index) => {
                loopData[`field${index + 1}`] = value.value
            })
            Object.keys(formik.values).map((val) => {
                formik.setFieldValue(val, loopData[val])
            })
            // console.log('JSON.parse(details.content_head4)', JSON.parse(details.content_head4))
            // setBidIncarr(details.content_head4 ? JSON.parse(details.content_head4) : [])
            setLoaders(true)
        }
    }, [edit_product.product_details])
    const extraFields = [
        {
            label: formik.values.facts === 'fish' ? 'Also known as' : 'Common Name',
            placeholder: '',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'field1',
            formik: formik,
        },
        {
            label: formik.values.facts === 'fish' ? 'Scientific Name' : 'Coral care level',
            placeholder: '',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'field2',
            formik: formik,
        },
        {
            label: formik.values.facts === 'fish' ? 'Recommenced Tank Size' : 'Lighting',
            placeholder: '',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'field3',
            formik: formik,
        },
        {
            label: formik.values.facts === 'fish' ? 'Care Level' : 'Waterflow',
            placeholder: '',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'field4',
            formik: formik,
        },
        {
            label: formik.values.facts === 'fish' ? 'Diet' : 'Placement',
            placeholder: '',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'field5',
            formik: formik,
        },
        {
            label: formik.values.facts === 'fish' ? 'Coral Safe' : 'Water Conditions',
            placeholder: '',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'field6',
            formik: formik,
        },
        {
            label: formik.values.facts === 'fish' ? 'Mature Size' : 'Temperament',
            placeholder: '',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'field7',
            formik: formik,
        },
        {
            label: formik.values.facts === 'fish' ? 'Origin' : 'Supplements',
            placeholder: '',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'field8',
            formik: formik,
        },
        {
            label: formik.values.facts === 'fish' ? 'Temperament' : 'Origin',
            placeholder: '',
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'field9',
            formik: formik,
        },
        {
            label: 'Family',
            placeholder: '',
            type: 'text',
            class: formik.values.facts === 'fish' ? 'd-none' : 'col-12 col-md-4',
            name: 'field10',
            formik: formik,
        },
    ]
    const LiveStockListing = [
        {
            label: 'Title',
            type: 'text',
            placeholder: 'Title',
            class: 'col-12 col-md-6',
            name: 'protitle',
            formik: formik,
        },
        {
            label: 'keywords',
            type: 'text',
            placeholder: 'Keywords',
            class: 'col-12 col-md-3',
            name: 'contenthead3',
            formik: formik,
        },
        {
            label: 'Stock Number',
            type: 'text',
            placeholder: 'Stock Number',
            class: 'col-sm-3 col-12',
            name: 'sku',
            formik: formik,
        },
        {
            label: 'Description *',
            placeholder: 'Enter Description',
            type: 'ckeditor1',
            class: 'col-12',
            name: 'description',
            rows: '4',
            formik: formik,
        },
        {
            label: 'Category *',
            placeholder: 'Select category',
            type: 'select',
            class: 'col-sm-3 col-12',
            options: categories.map((val) => ({
                show: val.name,
                value: val.id,
            })),
            defaultOptionValue: 'Select a Category',
            name: 'category_id',
            formik: formik,
        },
        {
            label: 'Sub Category',
            placeholder: 'Select Sub Category',
            type: 'select',
            class: 'col-12 col-sm-3',
            options: subCategory.map((val) => ({
                show: val.name,
                value: val.id,
            })),
            defaultOptionValue: 'Select a Sub Category',
            name: 'subcategory',
            formik: formik,
        },

        {
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class: 'col-12 col-md-3',
            type: 'select',
            options: country,
            name: 'location',
            formik: formik,
        },
        {
            label: 'State' + '*',
            placeholder: 'Select your state',
            class: 'col-12 col-md-3',
            type: 'select',
            options: state,
            name: 'state',
            formik: formik,
            disabled: state.length > 0 ? false : true,
        },
        {
            label: 'City *',
            type: 'text',
            placeholder: 'Enter city',
            class: 'col-sm-3 col-12',
            name: 'city',
            formik: formik,
        },
        {
            label: 'Zip code *',
            type: 'text',
            placeholder: 'Enter zip code',
            class: 'col-sm-3 col-12',
            name: 'zipcode',
            formik: formik,
        },
        {
            title: 'Selling Type',
            type: 'radio',
            noLabel: false,
            name: 'auctiontype',
            class: 'col-12 mb-3',
            item: [
                { id: 'auction', description: 'Auction' },
                { id: 'buynow', description: 'Buy Now' },
            ],
            formik: formik,
        },
        {
            label: 'Start Bid',
            placeholder: 'Enter Start Bid',
            type: 'text',
            startAdornment: '$',
            class: formik.values.auctiontype == 'auction' ? 'col-12 col-md-3' : 'd-none',
            name: 'sprice',
            formik: formik,
            disabled: formik.values.cprice_for_lot ? true : false,
        },
        {
            label: 'Reserve Price',
            placeholder: 'Enter Reserve Price',
            type: 'text',
            startAdornment: '$',
            class: formik.values.auctiontype == 'auction' ? 'col-12 col-md-3' : 'd-none',
            name: 'rprice',
            formik: formik,
        },
        {
            label: 'Buy Now Price',
            placeholder: 'Enter Buy Now Price',
            type: 'text',
            startAdornment: '$',
            class: formik.values.auctiontype == 'buynow' ? 'col-12 col-md-3' : 'd-none',
            name: 'bprice',
            formik: formik,
        },
        {
            label: 'Retail Price',
            type: 'text',
            startAdornment: '$',
            placeholder: 'Enter Retail Price',
            class: formik.values.auctiontype == 'buynow' ? 'col-12 col-md-3' : 'd-none',
            name: 'mprice',
            formik: formik,
        },
        {
            label: 'Start Date',
            placeholder: 'Enter Start Date',
            type: 'datetime',
            startAdornment: '$',
            class: formik.values.auctiontype == 'buynow' ? 'd-none' : 'col-12 col-md-3',
            name: 'startdate',
            formik: formik,
            disabled: formik.values.cprice_for_lot ? true : false,
        },
        {
            label: 'End Date',
            placeholder: 'Enter End Date',
            type: 'datetime',
            startAdornment: '$',
            class: formik.values.auctiontype == 'buynow' ? 'd-none' : 'col-12 col-md-3',
            name: 'enddate',
            formik: formik,
            disabled: formik.values.cprice_for_lot ? true : false,
        },
        {
            title: 'Fish Facts',
            type: 'radio',
            noLabel: false,
            name: 'facts',
            class: 'col-12 mb-3',
            item: [
                { id: 'fish', description: 'Do You want to add Fish Facts?' },
                { id: 'coral', description: 'Do You want to add Coral Facts?' },
            ],
            formik: formik,
        },
        ...extraFields,
        {
            label: `Enter Video URL`,
            placeholder: 'If you want to enter multiple url, use "," for seperate each url.',
            class: 'col-12 col-md-12',
            type: 'textarea',
            name: 'customfield9_video_url',
            rows: 4,
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_image',
            titleText: 'Upload product images',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>Upload Preview Images *</span>
                        </b>
                    </p>
                    <span>Recommended dimensions: 500px * 500px</span>
                </div>
            ),
            class: props.videoUploader ? 'col-md-6 col-12' : 'col-12',
            folder: 'product',
            multiple: true,
            reorder: true,
            fileName: props.fileName,
            accept: props.allformatimagenotneed ? 'image/jpeg,image/png,image/jpg' : 'image/*',
        },
        {
            type: 'previousImages',
            formik: formik,
            reorder: true,
            name: 'product_existingimage',
            class: props.videoUploader ? 'col-md-6 col-12' : 'col-12',
            folder: 'product',
            deleteArray: 'product_deletedimage',
        },
    ]

    return (
        <>
            <Prompt
                when={isBlocking}
                message="Your work is not saved! Are you sure you want to leave ?"
            />
            <div className="LiveStockPosting customContainer pb-5">
                <h2 className="ppTitle">Post a Product</h2>
                {Loaders ? (
                    <form onSubmit={formik.handleSubmit}>
                        <div className="pstFldsOptn">
                            <div className="row mt-3">{mapData(LiveStockListing)}</div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <PrimaryButton
                                type="button"
                                label={loading ? 'Loading...' : 'Save As Draft'}
                                disabled={loading}
                                onClick={() => {
                                    formik.setFieldValue('market_status', 'draft')
                                    formik.handleSubmit()
                                }}
                            />
                            <PrimaryButton
                                type="button"
                                label={loading ? 'Loading...' : 'Publish Live'}
                                disabled={loading}
                                onClick={() => {
                                    formik.setFieldValue('market_status', 'open')
                                    formik.handleSubmit()
                                }}
                            />
                        </div>
                    </form>
                ) : (
                    <div className="text-center">
                        <Loader />
                    </div>
                )}
            </div>
        </>
    )
}

export default FishingPostProduct
