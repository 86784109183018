import { Button } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import HowBidPopup from '../../../Component/HowBiddingWorks'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Copyrightinfo, Logo } from '../../../utils'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AuthContext from '../../../Product/context/auth/authContext'
import jsonp from 'jsonp'
import queryString from 'query-string'
import './Footer.css'
import Footernav from '../../../utils/FooterNavigation/footernav'
import { useEffect } from 'react'
import { handleRedirectInternal } from '../../../Product/common/components'
function Footer() {
    const [inpData, setinpData] = useState('')
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext
    const history = useHistory()
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const [hbid, setHBid] = React.useState(false)

    const { user, isAuthenticated, logout } = useContext(AuthContext)

    const forMobile = useMediaQuery('(max-width:1024px)')

    const formatPhNum = (phoneNumber) => {
        if (String(phoneNumber)?.length >= 10) {
            phoneNumber = phoneNumber.replace(/\D/g, '')
            return `+1 (${phoneNumber.substring(1, 4)}) ${phoneNumber.substring(
                4,
                7,
            )}-${phoneNumber.substring(7, 11)}`
        }
        return phoneNumber
    }

    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//${global.storeConfigration?.subscribe_code.value}${queryString.stringify(data)}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    return (
        <>
            {global?.storeConfigration?.subscribe?.value && (
                <div className="homeContact noPrint">
                    <h2>Get the latest deals</h2>
                    <p>Subscribe us to get latest updates</p>
                    <div className="subsBox">
                        <input
                            type="text"
                            name="q"
                            value={inpData}
                            placeholder="Enter email address"
                            onChange={(e) => {
                                setinpData(e.target.value)
                            }}
                        />
                        <Button>
                            <span className="material-icons" onClick={subscribe}>
                                send
                            </span>
                        </Button>
                    </div>
                </div>
            )}
            <footer className="footer-main-standard noPrint">
                {/* {
                    customHome.find(
                        (val) => val.name == global.storeConfigration?.custom_home_page_name?.value,
                    )?.value
                } */}
                <div className="footer-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <h6 className="fc-head">Explore</h6>
                                <ul>
                                    <li>
                                        <Link
                                            to={`${
                                                history.location.pathname === '/home'
                                                    ? '/#whyUsPart'
                                                    : '/home#whyUsPart'
                                            } `}
                                            className="fc-link"
                                        >
                                            Why us?
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/search" className="fc-link">
                                            Our Collection
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/game_room" className="fc-link">
                                            Game Room
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/testimonials" className="fc-link">
                                            Testimonials
                                        </Link>
                                    </li> */}

                                    {/* <li></li>
                                        <Link to="#" className="fc-link">
                                            Warranty
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <h6 className="fc-head">Support</h6>
                                <ul>
                                    <li>
                                        <Link to="/faq" className="fc-link">
                                            FAQ
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="#" className="fc-link">
                                            Shipping Policy
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/HowItWorks" className="fc-link">
                                            How it Works
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="#" className="fc-link">
                                            Returns & Exchanges
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <h6 className="fc-head">About</h6>
                                <ul>
                                    <li>
                                        <Link to="/aboutus" className="fc-link">
                                            Our Story
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/termsOfService" className="fc-link">
                                            Terms and Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy_policy" className="fc-link">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/laws" className="fc-link">
                                            Laws
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <h6 className="fc-head">Contact</h6>
                                <ul>
                                    <li className="fc-link">
                                        {global?.storeSeller?.address1}
                                        <br />
                                        {global?.storeSeller?.city}, {global?.storeSeller?.state} -{' '}
                                        {global?.storeSeller?.zip}
                                    </li>
                                    {/* <li>
                                        <a href={`tel:${contactPhone}`} className="fc-link">
                                            {formatPhNum(contactPhone)}
                                        </a>
                                    </li> */}
                                    <li className="fc-link">
                                        <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                                    </li>
                                    <li>
                                        <Link to="/contact_us" className="fc-link">
                                            Chat with us
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Copyrightinfo />
                <HowBidPopup open={hbid} handleClose={() => setHBid(false)} />
            </footer>
        </>
    )
}

export default Footer
