import React, { useState, useEffect, useContext } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { Redirect } from 'react-router'
import Favourites from './Favourites'
import Checkout from './Checkout'
import Cart from './cart'
import ProductAuction from './ProductAuction'
import Invoice from './Invoices/Invoice'
import ChangePassword from './ChangePassword'
import faq from './StaticPages/faq'
import howItWorks from './StaticPages/howItWorks'
import aboutus from './StaticPages/aboutus'
import PrivacyPolicy from './StaticPages/privacyPolicy'
import preference from './Preference'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Registration from './Registration'
import Home from './Home'
import Auctions from './Auctions'
import AuctionSearch from './AuctionSearch'
import Gallery from './Gallery'
import Profile from './Profile'
import MyBids from './MyBids'
import Transactions from './Transactions'
import Cards from './cards'
import Banks from './Bank'
import Static from './Static'
import mytickets from './MYTickets'
import authContext from '../../Product/context/auth/authContext'
import termsOfService from './StaticPages/termsOfService'
import ContactUs from './ContactUs'
import SavedSearch from './SavedSearch'
import Reports from './Reports'
import ProductView from './ProductView'
import Standalone from '../../Component/SlidePanel/Standalone'
import { commonPaths } from '../../utils/commonFunctions'
import MaintenanceMode from './MaintenanceMode'
import Ticketing from '../../utils/CommonFunctionality/Ticketing'
import EventAuction from './EventAuction'
import IdleTimerContainer from '../../utils/IdleTimerContainer'
const PrivateRoute = ({ component: Component, ...rest }) => (
    <>
        <Route
            {...rest}
            render={(props) =>
                localStorage.token ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    </>
)

export default function HeavyEquipmentRoutes() {
    return (
        <>
            <Route exact path="/maintenance" component={MaintenanceMode} />
            <Route exact path="/" component={Home} />
            <Route exact path="/faq" component={faq} />
            <Route exact path="/aboutus" component={aboutus} />
            <Route exact path="/termsOfService" component={termsOfService} />
            <Route exact path="/how_it_works" component={howItWorks} />
            <Route exact path="/search" component={AuctionSearch} />
            <Route exact path="/auctions" component={Auctions} />
            <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/search/autologin/:login_email" component={Login} />
            <Route exact path="/autologin/:login_email" component={Login} />
            <Route exact path="/search/auto-login/:login_email" component={Login} />
            <Route exact path="/auto-login/:login_email" component={Login} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route exact path="/sign-up" component={Registration} />
            <Route exact path="/login/forgot_password" component={ChangePassword} />
            <Route exact path="/search/product-buyer-auction/:id" component={ProductAuction} />
            <Route exact path="/changepasswordusers" component={ChangePassword} />
            <Route exact path="/contact_us" component={ContactUs} />
            <Route exact path="/ticketing/:sid/:aid" component={Ticketing} />
            <Route exact path="/eventAuction/:id" component={EventAuction} />
            {/* <Route exact path="/product/:id/:title" component={ProductView} /> */}
            <Route exact path="/productView/:id" component={ProductView} />
            <Route exact path="/trust" component={Static} />
            <Route exact path="/payment" component={Static} />
            <PrivateRoute exact path="/dashboard/profile" component={Profile} />
            <PrivateRoute exact path={commonPaths.mybids} component={MyBids} />
            <PrivateRoute exact path="/cart" component={Cart} />
            <PrivateRoute exact path="/checkout/:type" component={Checkout} />
            <PrivateRoute exact path="/invoice/:type" component={Invoice} />
            <PrivateRoute exact path="/dashboard/transactions" component={Transactions} />
            <PrivateRoute exact path="/dashboard/cards" component={Cards} />
            <PrivateRoute exact path="/dashboard/bank" component={Banks} />
            <PrivateRoute exact path="/dashboard/favorites" component={Favourites} />
            <PrivateRoute exact path="/dashboard/savedSearch" component={SavedSearch} />
            <PrivateRoute exact path="/dashboard/preference" component={preference} />
            <PrivateRoute exact path="/dashboard/reports" component={Reports} />
            <PrivateRoute exact path="/dashboard/mytickets" component={mytickets} />

            {global?.storeConfigration?.session_timeout ? <IdleTimerContainer /> : ''}
        </>
    )
}
