import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useHistory, NavLink, Link } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import { Box, Button, ListItem, Tab, Tabs, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import Loaders from '../../../Product/components/molecules/Loaders'
import jsonp from 'jsonp'
import AlertContext from '../../../Product/context/alert/alertContext'
import queryString from 'query-string'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import { messageHandler } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.css'
import { EffectCoverflow, Navigation, Autoplay, Pagination } from 'swiper'
import Slider from 'react-slick'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import ReactHtmlParser from 'react-html-parser'
import id from 'date-fns/locale/id'
import Reviews from './review'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import { useFormik } from 'formik'
import { ReactGoogleReviews } from 'react-google-reviews'
import 'react-google-reviews/dist/index.css'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `home-auctions-${index}`,
        'aria-controls': `home-auctions-tabpanel-${index}`,
    }
}

const Home = (props) => {
    const { user } = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)

    const {
        search_allauctions,
        getAllSearchAuctions,
        search_allproducts,
        getAllSearchProducts,
        productLocalState,
        clearResponse_search_details,
    } = productContext
    const { setAlert } = alertContext
    const sessionTimeOutRef = useRef(null)
    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [loading_buynow, setLoadingBuynow] = useState(true)

    const [viewProduct, setViewProduct] = useState([])
    const [buyNowProduct, setBuyNowProduct] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [value, setValue] = useState(0)
    const [inpData, setinpData] = useState('')
    const [filterValues, setFilterValues] = useState([])
    const [totalItems, setTotalItem] = useState(0)

    const [viewAuction, setViewAuction] = useState([])
    const [tabValue, setTabValue] = useState(0)

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const bodyClass = document.body.classList[1] == 'primeBids'

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '9',
        searchbar: '',
        auc_type: '2',
        type: '',
        auction_io: 1,
        lot_images: 1,
    })

    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const params = new URLSearchParams(window.location.search.substring(1))

    const formik = useFormik({
        initialValues: {
            sh_limit: sessionStorage.getItem('sh_limit'),
            page: 1,
            orderby: global?.storeConfigration?.liquidation_filter_update?.value == 1 ? '15' : '',
            location: [],
            sortType:
                global?.storeConfigration?.liquidation_filter_update?.value == 1 ? 2 : undefined,
            state: [],
            city: [],

            category: catg ? [catg] : [],
            contenthead5: [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            miles: params.get('m') ? params.get('m') : '',
            from: '',
            closed_auction_list: 0,
            view_type: params.get('view') ? params.get('view') : 0,
            auction_io: 1,
            conditionunique: [],
            lotsize: ['1'],

            orderby2: global?.storeConfigration?.liquidation_filter_update?.value == 1 ? '' : '6',
            need_bid_increment: 1,
        },
    })

    let homeFilterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options:
                searchCategories.length > 0 && global.storeConfigration?.cat_with_count?.value
                    ? searchCategories
                          .sort((a, b) => a.description.localeCompare(b.description))
                          .filter((main) => {
                              return true
                          })
                    : global.searchCategories
                          .sort((a, b) => a.description.localeCompare(b.description))
                          .filter((main) => {
                              return true
                          }),
            formik: formik,
        },
    ]

    const [subLotSearch, setSubLotSearch] = useState({
        auc_type: '2',
        page: 1,
        orderby: global?.storeConfigration?.home_pag_liq_2_changes?.value ? '16' : '2',
        lotof: '',
        sh_limit: 16,
        market_status: 'open',
        auction_io: 1,
        from: global?.storeConfigration?.home_pag_liq_2_changes?.value
            ? 'auction_only'
            : global.storeConfigration?.liq_home_page_changes?.value == 1
            ? undefined
            : 'auction_only',

        type: 'active',
        need_bid_increment: 1,
    })

    const [buynowSearch, setBuynowSearch] = useState({
        sh_limit: 16,
        from: 'buynow',
        page: 1,
        orderby: '1',
        auction_io: 1,
    })

    // useEffect(() => {
    //     if (value === 0 || value === 1) {
    //         setSubLotSearch({ ...subLotSearch, type: 'active', auc_type: '2' })
    //     }
    //     if (value === 2) {
    //         setBuynowSearch({ ...buynowSearch, type: 'active' })
    //     }
    // }, [value])

    useEffect(() => {
        if (search_allproducts.from === 'activeHome') {
            if (search_allproducts.results.length > 0) {
                search_allproducts.results.map((data) => {
                    if (data?.extra_fields?.includes('}')) {
                        let parse = JSON.parse(data.extra_fields)
                        Object.keys(parse).map((val) => {
                            data[val] = parse[val]
                        })
                    }
                })

                setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            } else {
                setViewProduct([])
            }
            setLoading(false)
            setTotalItem(search_allproducts.total_pagecnt)
            clearTimeout(sessionTimeOutRef.current)
            clearResponse_search_details()
        } else if (search_allproducts.from === 'galleryPage') {
            if (search_allproducts.results.length > 0) {
                search_allproducts.results.map((data) => {
                    if (data?.extra_fields?.includes('}')) {
                        let parse = JSON.parse(data.extra_fields)
                        Object.keys(parse).map((val) => {
                            data[val] = parse[val]
                        })
                    }
                })
                setBuyNowProduct(
                    search_allproducts.results.length ? search_allproducts.results : [],
                )
            } else {
                setBuyNowProduct([])
            }
            setLoadingBuynow(false)
            setTotalItem(search_allproducts.total_pagecnt)
            clearResponse_search_details()
        }
    }, [search_allproducts])

    useEffect(() => {
        setLoadingBuynow(true)
        if (value === 2) {
            getAllSearchProducts(buynowSearch, 'galleryPage')
        }
    }, [buynowSearch])

    useEffect(() => {
        setLoading(true)
        getAllSearchProducts(subLotSearch, 'activeHome')
        sessionTimeOutRef.current = setTimeout(() => {
            if (!window.location.pathname.includes('/search')) {
                getAllSearchProducts(subLotSearch, 'activeHome')
            }
        }, 8000)
    }, [subLotSearch, isAuthenticated])

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socket.on('buyer_assurance', (data) => {
            handler(data, 'buyer_assurance')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('buyer_assurance', (data) => {
                handler(data, 'buyer_assurance')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    useEffect(() => {
        setViewProduct([])
        setBuyNowProduct([])
        return () => {
            setViewProduct([])
            setBuyNowProduct([])
        }
    }, [])

    useEffect(() => {
        setFilterValues(
            global?.allCategories
                ?.filter((val) => val.level === 0)
                ?.sort((a, b) => a.description.localeCompare(b.description)),
        )
    }, [global.allCategories])

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }
    const handleChange = (event, newValue) => {
        setValue(newValue)
        if (newValue === 0 || newValue === 1) {
            if (global?.storeConfigration?.home_pag_liq_2_changes?.value) {
                setSubLotSearch({
                    ...subLotSearch,
                    type: 'active',
                    auc_type: '2',
                    most_viewed: undefined,
                    viewers: undefined,
                    featured: undefined,
                    orderby: newValue == 0 ? '11' : '',
                })
            } else {
                setSubLotSearch({
                    ...subLotSearch,
                    type: 'active',
                    auc_type: '2',
                    most_viewed: undefined,
                    viewers: undefined,
                    featured: undefined,
                })
            }

            //getAllSearchProducts({ ...subLotSearch, type: 'active', auc_type: '2' }, 'activeHome')
        }
        if (newValue === 3) {
            setSubLotSearch({
                ...subLotSearch,
                type: 'active',
                auc_type: '2',
                featured: 1,
                most_viewed: undefined,
                viewers: undefined,
            })
            //getAllSearchProducts({ ...subLotSearch, type: 'active', auc_type: '2' }, 'activeHome')
        }
        if (newValue === 4) {
            setSubLotSearch({
                ...subLotSearch,
                type: 'active',
                auc_type: '2',
                most_viewed: 1,
                viewers: 1,
                featured: undefined,
            })
            //getAllSearchProducts({ ...subLotSearch, type: 'active', auc_type: '2' }, 'activeHome')
        }
        if (newValue === 2) {
            if (global?.storeConfigration?.home_pag_liq_2_changes?.value) {
                handleRedirectInternal(history, 'how_it_works')
            } else {
                setBuynowSearch({ ...buynowSearch, type: 'active' })
                //getAllSearchProducts({ ...buynowSearch, type: 'active' }, 'galleryPage')
            }
        }
    }

    const [state, setState] = React.useState({
        bottom: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'home_page')
    }, [auctionSearch])

    useEffect(() => {
        // if (search_allauctions.from == 'home_page') {
        setViewAuction(search_allauctions?.results?.length ? search_allauctions?.results : [])
        // } else {
        //     setViewAuction([])
        // }
    }, [search_allauctions])

    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//${global.storeConfigration?.subscribe_code.value}${queryString.stringify(data)}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }

    const settings = {
        dots: false,
        fade: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 10000,
        cssEase: 'linear',
    }

    const categoriesSettings = {
        dots: false,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 6000,
        cssEase: 'linear',
    }

    const checkThemeClassName = document.body.className == 'liquidationTwo primeBids'
    const checkBeachThemeClassName = document.body.className == 'liquidationTwo beachLiquidation'
    const checkMediaWithPrimeBidTheme =
        window.matchMedia('(max-width:768px)').matches && checkThemeClassName

    const isBidDepotProject = document.querySelector('body').classList.contains('BidAuction')

    return (
        <Layout props={props}>
            <div className="home">
                <div className="customContainer">
                    <div className="homeAuctionList_Root">
                        <div className="homeFilter_Base">
                            <FilterPanel
                                formik={formik}
                                filterValues={homeFilterValues}
                                allOpen={true}
                            />
                        </div>
                        <div className="splEvntsWrpr pb-4">
                            <div className="sotingBtngrp mb-3 justify-content-start">
                                <PrimaryButton
                                    label="Current Auctons"
                                    className={auctionSearch.auc_type == 2 ? 'active' : ''}
                                    onClick={() =>
                                        setAuctionSearch({
                                            ...auctionSearch,
                                            auc_type: 2,
                                            type: '',
                                        })
                                    }
                                />
                                <PrimaryButton
                                    label="Upcoming Auctons"
                                    className={auctionSearch.auc_type == 1 ? 'active' : ''}
                                    onClick={() =>
                                        setAuctionSearch({
                                            ...auctionSearch,
                                            auc_type: 1,
                                            type: '',
                                        })
                                    }
                                />
                                <PrimaryButton
                                    label="Closed Auctons"
                                    className={auctionSearch.auc_type == 3 ? 'active' : ''}
                                    onClick={() =>
                                        setAuctionSearch({
                                            ...auctionSearch,
                                            auc_type: 3,
                                            type: 'closed',
                                        })
                                    }
                                />
                            </div>
                            <div className="vwActnWrapper">
                                {viewAuction.length ? (
                                    <div className="hmActnLstWrpr">
                                        {viewAuction.map((data, index) => (
                                            <>
                                                {console.log(viewAuction, '***')}
                                                <MultisellerListCard
                                                    data={data}
                                                    key={index}
                                                    details={`search/product-buyer-auction/${data.id}`}
                                                    card_theme="liquidationTwo"
                                                />
                                            </>
                                        ))}
                                    </div>
                                ) : (
                                    <NoRecordsFound />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MultisellerSlider
                selectedLot={selectedProduct}
                handleClose={handleClose}
                currency="cad"
                type={props.type ? props.type : ''}
                storeTheme="Liquidation_two"
                pvTabList={true}
            />
        </Layout>
    )
}

export default Home
