import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import Donation from '../../../Component/Donation'
import Layout from '../Layout'

const EventAuction = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)

    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext

    const {
        search_allauctions,
        search_allauctionproducts,
        edit_auction,
        getLotList,
        getEditAuction,
    } = productContext

    let history = useHistory()
    const [auctionData, setAuctionData] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    let auctionid = props.match.params.id
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        sortBy: 7,
        lotof: auctionid,
        limit: 50,
        market_status: 'open',
        auction_io: 1,
    })

    const [auctionLoading, setAuctionLoading] = useState(true)
    const [productLoading, setProductLoading] = useState(true)

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        getEditAuction({ auctionid: props.match.params.id }, 'auctionView')
        setAuctionLoading(true)
    }, [props.match.params.id, isAuthenticated])

    useEffect(() => {
        getLotList(subLotSearch, 'auctionSubView')
        setProductLoading(true)
    }, [subLotSearch, isAuthenticated])

    return (
        <Layout props={props}>
            <Donation auctionid={auctionid} />
        </Layout>
    )
}

export default EventAuction
