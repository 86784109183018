import React from 'react'
import Layout from '../Layout'
import Container from '@material-ui/core/Container'
import DashboardHeader from '../DashboardHeader'
import Booktickets from '../../../utils/CommonFunctionality/bookingtickets'

const MyTickets = (props) => {
    return (
        <Layout props={props}>
            <DashboardHeader />
            <Container className="db-bids-wrapper mt-4">
                <h2 className="mp-head">My Tickets</h2>
                <Booktickets props={props} />
            </Container>
        </Layout>
    )
}

export default MyTickets
