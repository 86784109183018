import React, { useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'

const Layout = ({ props, children, ...rest }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="app-wrapper">
            {/* <h1>Hi {currentUser.name}!</h1> */}
            <Header />
            <div className="main-container">{children}</div>
            <Footer />
        </div>
    )
}

export default Layout
