import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const HeaderSearchComponent = (props) => {
    const [searchValues, setSearchValues] = useState({
        searchbar: '',
        zipcode: '',
        miles: '',
        category: [],
    })
    const handleChange = (e) =>
        setSearchValues({ ...searchValues, [e.target.name]: e.target.value })
    let history = useHistory()

    const handleClick = (e) => {
        e.preventDefault()
        history.push(
            `/?q=${searchValues.searchbar}&z=${searchValues.zipcode}&m=${searchValues.miles}&cat=${
                searchValues.category.length > 0 ? searchValues.category.join(',') : ''
            }`,
        )
        if (props.onClose != undefined) {
            props.onClose()
        }
    }
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        setSearchValues({
            ...searchValues,
            searchbar: params.get('q') ? params.get('q') : '',
            zipcode: params.get('z') ? params.get('z') : '',
            miles: params.get('m') ? params.get('m') : '',
            category: params.get('cat') ? params.get('cat').split(',') : '',
        })
    }, [])

    return (
        <form className="nav-standard-top-area-search">
            <div className="nav-standard-top-area-search-inner">
                <div>
                    <input
                        type="text"
                        onChange={handleChange}
                        name="searchbar"
                        value={searchValues.searchbar}
                        className="nav-standard-top-area-search-input open-sans nav-search-input"
                        placeholder="Search"
                    />
                    {/* <select
                        className="custom-select"
                        name="miles"
                        onChange={handleChange}
                        value={searchValues.miles}
                    >
                        <option selected>Kms</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                    </select> */}
                </div>
                <button
                    onClick={(e) => {
                        handleClick(e)
                        if (props.onClose) {
                            props.onClose()
                        }
                    }}
                    className="lin lin-magnifier nav-standard-top-area-search-sumbit nav-search-submit"
                >
                    <span className="material-icons">search</span>
                </button>
            </div>
        </form>
    )
}

export default HeaderSearchComponent
