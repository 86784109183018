import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import FeedbackFunction from '../../../utils/CommonFunctionality/Feedback'

const Feedback = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="Feedback">
                <Container className="dashboard-wrapper">
                    <Grid container spacing={3}>
                        <FeedbackFunction />
                    </Grid>
                </Container>
            </DashboardLayout>
        </Layout>
    )
}

export default Feedback
