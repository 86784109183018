import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { useHistory } from 'react-router-dom'
import SubLots from './SubLots'
import ProductAuctionTop from './ProductAuctionTop'
import SlidePanel from '../../../Component/SlidePanel'
import './product.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import Toaster from '../../../Component/Toaster'

import { socket, socketAuction } from '../../../Product/common/socket'

import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'

import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { messageHandler } from '../../../Product/common/socketHandler'

import Layout from '../Layout'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Loaders from '../../../Product/components/molecules/Loaders'
import { sellerNameFormat } from '../../../Product/common/components'
import axios from 'axios'

const ProductAuctionComponent = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)

    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext
    const cancelToken = axios.CancelToken.source()
    const [cancelRequest, setCancelRequest] = useState(undefined)
    const {
        search_allauctions,
        search_allauctionproducts,
        edit_auction,
        getLotList,
        getEditAuction,
    } = productContext

    let auctionid = props.match.params.id
    const [auctionData, setAuctionData] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    const [isRegistered, setIsRegistered] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        sortBy: 7,
        lotof: auctionid,
        limit: 12,
        market_status: 'open',
        auction_market_status: 'open',
    })
    const [display_type, setType] = useState('list')
    const [loading, setLoading] = useState(true)
    const [subLoading, setSubLoading] = useState(false)
    let history = useHistory()

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        getEditAuction({ auctionid }, 'auctionView')
    }, [isAuthenticated])

    useEffect(() => {
        setSubLoading(true)
        getLotList(subLotSearch, 'auctionView')
    }, [subLotSearch, isAuthenticated])

    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionView') {
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
            setLoading(false)
            setSubLoading(false)
            // setTimeout(() => {
            //     setLoading(false)
            // }, 1500)
        }
    }, [search_allauctionproducts])
    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionView_from_sublot') {
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        setLoading(true)
        if (edit_auction.from === 'auctionView') {
            if (edit_auction.auction_details) {
                setAuctionData(edit_auction.auction_details)
                setIsRegistered(edit_auction.is_registered)
                setTimeout(() => {
                    setLoading(false)
                }, 1500)
            }
        }
    }, [edit_auction])

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const viewProductRef = useRef(subLotData)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = subLotData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }
    useEffect(() => {
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])
    useEffect(() => {
        if (subLotData.length) {
            socket.on('realclosedupdates', (data) => {
                if (subLotData.find((val) => val.id == data.pid)) {
                    if (cancelRequest) {
                        cancelRequest.cancel()
                    }
                    setSubLoading(true)
                    setCancelRequest(cancelToken)
                    getLotList(subLotSearch, 'auctionView', cancelToken)
                }
                handler(data, 'realclosedupdates')
            })
        }
        return () => {
            if (subLotData.length) {
                socket.off('realclosedupdates', (data) => {
                    handler(data, 'realclosedupdates')
                })
            }
        }
    }, [subLotData])

    return (
        <Layout props={props}>
            <div className="customContainer pt-3 auctionCnt">
                {loading ? (
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                ) : (
                    <>
                        <ProductAuctionTop
                            auctionData={auctionData}
                            product_id={auctionid}
                            executeScroll={executeScroll}
                            sellerName={
                                subLotData[0]?.seller_name
                                    ? sellerNameFormat(subLotData[0]?.seller_name)
                                    : ''
                            }
                            is_registered={isRegistered}
                            lotData={subLotData}
                        />
                        <div ref={myRef}></div>
                        <>
                            <SubLots
                                selectedPostion={handleClick}
                                handleClick={handleClick}
                                title={auctionData.title}
                                auctionid={auctionid}
                                sendType={(val) => {
                                    setType(val)
                                    getLotList(subLotSearch, 'auctionView_from_sublot')
                                }}
                                lotData={subLotData}
                                subLotDataCount={subLotDataCount}
                                subLotSearch={subLotSearch}
                                setSubLotSearch={setSubLotSearch}
                                loading={subLoading}
                            />
                            <MultisellerSlider
                                type="right"
                                selectedLot={selectedProduct}
                                handleClose={handleClose}
                                from={''}
                                getAllProducts={() => false}
                            />
                        </>
                    </>
                )}
            </div>
        </Layout>
    )
}

export default ProductAuctionComponent
