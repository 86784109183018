import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import DashboardLayout from '../DashboardLayout'
import FavouriteAuctions from '../../../Component/FavouriteList/FavouriteAuctions'
import Layout from '../Layout'
import { useTranslation } from 'react-i18next'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const Favourites = (props) => {
    const [value, setValue] = useState(0)
    const [date_now, setNowdate] = useState('')
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const { t } = useTranslation()

    useEffect(() => {
        let date_now_day = new Date()
        date_now_day.setDate(date_now_day.getDate() + 7)
        setNowdate(date_now)
    }, [])

    return (
        <Layout props={props}>
            <DashboardLayout title={t('favourites')}>
                <div className="mt-4">
                    <FavouriteAuctions
                        // need_end_date_from={1}
                        // next_week_date_from={date_now}
                        // page="buynow"
                        timerTheme="ArtAuction"
                        sliderTheme="multiseller"
                        cardTheme="ArtAuction"
                        bidBtnLabel="Place Bid"
                        storeTheme="ArtAuction"
                    />
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default Favourites
