import React, { useState, useContext, useEffect } from 'react'
import ProductContext from '../../../context/product/productContext'
import AuthContext from '../../../context/auth/authContext'

import './FavoriteCheckbox.css'

const FavoriteCheck = ({ watchlisted, project_id }) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext

    const [checked, setChecked] = useState(0)
    const { addWatchlist, removeWatchlist } = productContext

    const toggleFavourite = () => {
        if (!checked) {
            addWatchlist({ product_id: project_id, watchlistemail: '1' })
        } else {
            removeWatchlist({ product_id: project_id })
        }
        setChecked(!checked)
    }
    useEffect(() => {
        setChecked(watchlisted)
    }, [watchlisted])
    // console.log(checked, checked)
    return (
        <>
            {isAuthenticated && (
                <div
                    className={'favoriteCheck d-flex justify-content-center align-items-center'}
                    onClick={() => toggleFavourite()}
                >
                    <input type="checkbox" checked={checked} />

                    {checked ? (
                        <label>
                            <span className="material-icons">favorite</span>
                        </label>
                    ) : (
                        <label>
                            <span className="material-icons">favorite_border</span>
                        </label>
                    )}
                </div>
            )}
        </>
    )
}
export default FavoriteCheck
