import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './Auctions.css'
import { Button } from '@material-ui/core'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import Loader from '../../../assets/loader'
import Loaders from '../../../Product/components/molecules/Loaders'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../../Product/common/components'
import { Pagination } from '@material-ui/lab'
const Auctions = (props) => {
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allauctionproducts, getLotList } =
        productContext
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [totalViewProduct, setTotalViewProduct] = useState(0)
    const [auctionView, setAuctionView] = useState('Grid')
    const [subLotData, setSubLotData] = useState([])
    const [auction_type, setAuction_type] = useState('')

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '12',
        orderby: '2',
        searchbar: '',
        auc_type: '',
        lot_images: 1,
        type: 'all',
        auction_io_type: 1,
        is_auction: 1,
        multi_seller_closed: 1,
    })

    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        orderby: '2',
        lotof: '',
        limit: 50,
        market_status: 'open',
        auction_io: 1,
    })

    const [searchValues, setSearchValues] = useState({
        searchbar: '',
    })

    useEffect(() => {
        if (formik.values.view_type != '') {
            setLoading(true)
            var data = auctionSearch

            if (props?.match?.params?.type) {
                var auction_type_loc = props.match.params.type
                setAuction_type(auction_type_loc)
                if (parseInt(auction_type_loc) == 0) {
                    data.auc_type = '2'
                } else if (parseInt(auction_type_loc) == 1) {
                    data.auc_type = '1'
                } else {
                    data.auc_type = '3'
                    data.type = 'closed'
                }
            }
            getAllSearchAuctions(auctionSearch, 'activeHome')
        }
    }, [auctionSearch])
    useEffect(() => {
        if (viewAuction.length) {
            setSubLotSearch({ ...subLotSearch, lotof: viewAuction[0].id })
        }
    }, [viewAuction])

    useEffect(() => {
        if (subLotSearch.lotof) {
            getLotList(subLotSearch, 'homePageAuction')
        }
    }, [subLotSearch])

    useEffect(() => {
        setLoading(false)
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
            setTotalViewProduct(search_allauctions.total_pagecnt)
        }
    }, [search_allauctions])

    const onHandlePage = (event, value) => {
        setAuctionSearch({ ...auctionSearch, page: value })
    }

    useEffect(() => {
        if (search_allauctionproducts.from === 'homePageAuction') {
            setSubLotData(search_allauctionproducts.results)
        }
    }, [search_allauctionproducts])

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            id: '0',
            description: 'All',
        },
        {
            id: 1,
            description: 'Upcoming',
        },
        {
            id: 2,
            description: 'Ongoing',
        },
        {
            id: 3,
            description: 'Closed',
        },
    ]

    const formik = useFormik({
        initialValues: {
            view_type: '',
        },
    })

    useEffect(() => {
        if (formik.values.view_type != '') {
            setAuctionSearch({
                ...auctionSearch,
                auc_type: formik.values.view_type == '0' ? '' : formik.values.view_type,
                type:
                    formik.values.view_type == '3'
                        ? 'closed'
                        : formik.values.view_type == '0'
                        ? 'all'
                        : '',
                multi_seller_closed:
                    formik.values.view_type == '3' || formik.values.view_type == '0'
                        ? 'closed'
                        : '',
                is_auction: 1,
            })
            if (formik.values.view_type == '3') {
                setAuction_type('3')
            }
        }
    }, [formik.values])

    useEffect(() => {
        const searchbar_params = new URLSearchParams(window.location.search.substring(1)).get(
            'type',
        )
        formik.setFieldValue('view_type', searchbar_params != undefined ? searchbar_params : '0')
    }, [window.location.search])

    const filterType = [
        {
            title: 'View',
            type: 'radio',
            noLabel: true,
            name: 'view_type',
            int: 1,
            class: 'aucTypeFilter',
            item: sortShow,
            formik: formik,
        },
    ]

    const handleChange = (e) => {
        setSearchValues({ ...searchValues, [e.target.name]: e.target.value })
    }

    const handleClick = (e) => {
        e.preventDefault()
        setAuctionSearch({ ...auctionSearch, searchbar: searchValues.searchbar })
    }

    return (
        <Layout props={props}>
            <div className="customContainer auctionsSearch">
                <div className="auctionSearchCnt">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mt-5">
                        <h2 className="auctionListTitle">
                            {auctionSearch.auc_type == 1
                                ? 'Upcoming'
                                : auctionSearch.auc_type == 2
                                ? 'Ongoing'
                                : 'All'}{' '}
                            Auctions
                        </h2>
                        {mapData(filterType)}
                    </div>

                    <div className="auctionsMiscFilter d-flex justify-content-between align-items-center flex-wrap mt-3 mb-3">
                        <form className="nav-standard-top-area-search">
                            <div className="nav-standard-top-area-search-inner">
                                <div>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        name="searchbar"
                                        value={searchValues.searchbar}
                                        className="nav-standard-top-area-search-input open-sans"
                                        placeholder="Search"
                                    />
                                </div>
                                <button
                                    onClick={handleClick}
                                    className="lin lin-magnifier nav-standard-top-area-search-sumbit"
                                >
                                    <span className="material-icons">search</span>
                                </button>
                            </div>
                        </form>
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => setAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => setAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={auctionSearch.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                setAuctionSearch({ ...auctionSearch, sh_limit: event.target.value })
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        {/* <CustomSelect
                            label="Auction Type"
                            value={auctionSearch.auc_type}
                            size="small"
                            selectType="noBorder"
                            name="auc_type"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                setAuctionSearch({ ...auctionSearch, auc_type: event.target.value })
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect> */}
                    </div>
                </div>
                <>
                    <>
                        {loading ? (
                            <div className="text-center">
                                <Loaders name="product_grid_view" isLoading={loading} loop={4} />
                            </div>
                        ) : viewAuction.length === 0 ? (
                            <NoRecordsFound />
                        ) : viewAuction.length > 0 ? (
                            <div className={`searchResults ${auctionView}`}>
                                {viewAuction.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <GridAuction
                                            key={index}
                                            data={data}
                                            details={`${
                                                data.con_check
                                                    ? 'liveauctionview'
                                                    : 'search/product-buyer-auction'
                                            }/${data.id}?id_new=${data.id}`}
                                            timerTheme="multiseller"
                                            theme="multiseller"
                                            addTheme="multiAuction"
                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                        />
                                    ) : (
                                        <MultisellerListCard
                                            key={index}
                                            data={data}
                                            allsearchitems={viewAuction}
                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                            details={`${
                                                data.con_check
                                                    ? 'liveauctionview'
                                                    : 'search/product-buyer-auction'
                                            }/${data.id}?id_new=${data.id}`}
                                            addTheme="multiAuction"
                                        />
                                    ),
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                    {totalViewProduct > auctionSearch.sh_limit && (
                        <Pagination
                            count={Math.ceil(totalViewProduct / auctionSearch.sh_limit)}
                            page={auctionSearch.page}
                            onChange={(e, v) => setAuctionSearch({ ...auctionSearch, page: v })}
                        />
                    )}
                </>
                <div className="rvrsActnWork text-center">
                    <section className="customContainer">
                        <div className="oneMinAuction">
                            <div className="homeTitleText">
                                <h3 className="cmmSoon">Coming Soon</h3>
                                <h4 className="mainTit">What is One Minute Auction?</h4>
                                <h5 className="subTit">It’s simple and addictive!</h5>
                            </div>
                            <div className="minAuctionGrid mt-5 mb-5">
                                <div className="minAuctionGridCrd">
                                    <div className="gridMedia">
                                        <div className="mediaLeft">
                                            <div className="MediaImg">
                                                <object
                                                    data="/phill/roomof.svg"
                                                    type="image/svg+xml"
                                                />
                                            </div>
                                        </div>
                                        <div className="mediaBody">
                                            <p>
                                                It’s a room of <span>reverse</span> auction
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="minAuctionGridCrd">
                                    <div className="gridMedia">
                                        <div className="mediaLeft">
                                            <div className="MediaImg">
                                                <object
                                                    data="/phill/timer.svg"
                                                    type="image/svg+xml"
                                                />
                                            </div>
                                        </div>
                                        <div className="mediaBody">
                                            <p>
                                                You have <span>one minute</span> to acquire the lot
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="minAuctionGridCrd">
                                    <div className="gridMedia">
                                        <div className="mediaLeft">
                                            <div className="MediaImg">
                                                <object
                                                    data="/phill/everysec.svg"
                                                    type="image/svg+xml"
                                                />
                                            </div>
                                        </div>
                                        <div className="mediaBody">
                                            <p>
                                                <span>Every second </span>
                                                it’s price drops
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="minAuctionGridCrd">
                                    <div className="gridMedia">
                                        <div className="mediaLeft">
                                            <div className="MediaImg">
                                                <object
                                                    data="/phill/Picto.svg"
                                                    type="image/svg+xml"
                                                />
                                            </div>
                                        </div>
                                        <div className="mediaBody">
                                            <p>
                                                The <span>First</span> to click <span>wins</span>{' '}
                                                the prize
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="oneMinFinalDiv">
                                <p>Be ready, be responsive !</p>
                            </div>
                        </div>
                    </section>
                    <section className="customContainer">
                        <div className="whoVR">
                            <div className="whoVRCnt">
                                <h4 className="tit">Who are we?</h4>
                                <p className="des">
                                    One Minute Auction is a unique and rare item sales platform,
                                    offering items such as jewelry&quot; photos&quot; and antiques
                                    through a private descending auction system. Join us to discover
                                    rare and valuable items. We believe that fashion and art are
                                    expressions of quality and uniqueness. We know that everyone is
                                    looking for something different and unique. Join us on One
                                    Minute Auction&quot; where every opportunity is a chance to
                                    discover something rare and precious&quot; so &quot;Don&quot;t
                                    miss your chance.&quot;
                                </p>
                            </div>
                            <figure className="m-0 wvrImg">
                                <img src="/phill/tableau.png" alt="picture" />
                            </figure>
                        </div>
                    </section>
                    {/* <div className="container">
                        <h2>
                            What is <i>One Minute Auction?</i>
                        </h2>
                        <h4>It&apos;s simple and addictive</h4>
                        <div className="grdCntnr">
                            <div className="hrwCard">
                                <img src="/phill/image_one.png" />
                                <p>
                                    It&apos;s a room of <span>reverse</span> auction
                                </p>
                            </div>
                            <div className="hrwCard">
                                <img src="/phill/image_two.png" />
                                <p>
                                    You have <span>one minute</span> to acquire the lot
                                </p>
                            </div>
                            <div className="hrwCard">
                                <img src="/phill/image_three.png" />
                                <p>
                                    <span>Every second</span> the price drops
                                </p>
                            </div>
                            <div className="hrwCard">
                                <img src="/phill/image_four.png" />
                                <p>
                                    The <span>First</span> to click <span>wins</span> the auction
                                </p>
                            </div>
                        </div>
                        <h4>Be ready, be responsive!</h4>
                    </div> */}
                </div>
            </div>
        </Layout>
    )
}

export default Auctions
