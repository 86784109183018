import { Button } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import PrimaryButton from '../../../../Product/components/atoms/PrimaryButton'
import CustomDialog from '../../../../Product/components/organisms/Dialog'
import PosContext from '../../../../Product/context/plugin/pos/posContext'
import AuthContext from '../../../../Product/context/auth/authContext'
import { useContext } from 'react'
import { useFormik } from 'formik'
import { mapData } from '../../../../Product/common/components'
import * as Yup from 'yup'
const AddUpdate = ({ type, label, id }) => {
    const { user } = useContext(AuthContext)
    const {
        updateReader,
        createReader,
        retrieveReader,
        reader,
        allLocations,
        all_locations,
        responseStatus,
    } = useContext(PosContext)
    const [open, setOpen] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [locations, setLocations] = useState([])
    const toggleOpen = () => setOpen(!open)

    const validationArray = Yup.object({
        label: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s]*$/g, 'The special characters and numbers are not allowed!')
            .max(20, 'Maximum 20 characters')
            .required('Required'),
        registration_code: Yup.string().when('type', {
            is: 'update',
            then: Yup.string(),
            otherwise: Yup.string().required('Required'),
        }),
        location_id: Yup.string().when('type', {
            is: 'update',
            then: Yup.string(),
            otherwise: Yup.string().required('Required'),
        }),
    })

    const formik = useFormik({
        initialValues: {
            user_id: user?.id,
            site_id: global?.storeDetails?.site_id,
            reader_id: id,
            label: '',
            registration_code: '',
            location_id: '',
            type: type,
        },
        enableReinitialize: true,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setBtnLoading(true)
            var data = { ...values }
            data.country = 'US'
            if (type === 'update') {
                updateReader(data)
            } else {
                createReader(data)
            }
        },
    })
    useEffect(() => {
        if (id && user && open) {
            retrieveReader({
                reader_id: id,
                user_id: user?.id,
                site_id: global?.storeDetails?.site_id,
            })
            allLocations({ user_id: user?.id, site_id: global?.storeDetails?.site_id })
        }
    }, [user, open])

    useEffect(() => {
        if (Object.keys(reader).length) {
            formik.setValues({
                ...formik.values,
                type: reader.type,
            })
        }
    }, [reader])

    useEffect(() => {
        var temp_array = []
        all_locations.map((val) => temp_array.push({ value: val.id, show: val.display_name }))
        setLocations(temp_array)
    }, [reader])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'createReader' || responseStatus.from === 'updateReader') {
                if (responseStatus.status === 'success') {
                    formik.resetForm()
                    setOpen(false)
                    setBtnLoading(false)
                } else {
                    setBtnLoading(false)
                }
            }
        }
    }, [responseStatus])

    const fields = [
        {
            label: 'Label *',
            placeholder: 'Enter Reader Label',
            type: 'text',
            class: 'col-sm-6 col-12',
            name: 'label',
            formik: formik,
        },
        {
            label: 'Location' + '*',
            placeholder: 'Select your location',
            class: label === 'update' ? 'd-none' : 'col-sm-6 col-12',
            type: 'select',
            options: locations,
            name: 'location_id',
            formik: formik,
        },
        {
            label: 'Registration Code *',
            placeholder: 'Enter Registration Code',
            type: 'text',
            class: label === 'update' ? 'd-none' : 'col-sm-6 col-12',
            name: 'registration_code',
            formik: formik,
        },
    ]
    return (
        <div>
            <Button variant="outlined" className="ml-2" onClick={toggleOpen}>
                {label ? label : `${type === 'update' ? 'Update Reader' : 'Add Reader'}}`}
            </Button>
            <CustomDialog
                title={`${type === 'update' ? 'Update' : 'Add'} Reader`}
                open={open}
                function={toggleOpen}
            >
                <div className="row">{mapData(fields)}</div>
                <div className="actionWrapper">
                    <Button onClick={() => setOpen(false)} disabled={btnLoading}>
                        {'Cancel'}
                    </Button>
                    <PrimaryButton
                        onClick={formik.handleSubmit}
                        type="button"
                        disabled={btnLoading}
                        label={
                            btnLoading
                                ? 'Loading...'
                                : `${type === 'update' ? 'Update' : 'Add'} Reader`
                        }
                    />
                </div>
            </CustomDialog>
        </div>
    )
}

export default AddUpdate
