import React from 'react'
import './invoice.css'
import InvoiceComponent from '../../../utils/CommonFunctionality/Invoice/InvoiceFunction'
import Layout from '../Layout'

const Invoice = (props) => {
    return (
        <Layout props={props}>
            <InvoiceComponent
                props={props}
                fromresellername={1}
                downloadInvoice={true}
                noBuyerPremium={false}
            />
        </Layout>
    )
}

export default Invoice
