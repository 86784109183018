import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Copyrightinfo, Logo, FooterLogo } from '../../../utils'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AuthContext from '../../../Product/context/auth/authContext'
import './Footer.css'
import Footernav from '../../../utils/FooterNavigation/footernav'
import { useTranslation } from 'react-i18next'

function Footer() {
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram
    const { t } = useTranslation()
    const [hbid, setHBid] = React.useState(false)

    const { user, isAuthenticated, logout } = useContext(AuthContext)

    const forMobile = useMediaQuery('(max-width:1024px)')

    return (
        <footer className="footer-main-standard noPrint">
            <div className="customContainer">
                <div className="row" data-gutter="60">
                    <div className="col-12 ftrDtlCntnr">
                        <div className="footLogoCnt">
                            <FooterLogo className="footerLogo" />
                            <ul className="footer-main-standard-social-list">
                                {facebookLink && (
                                    <li className="facebook">
                                        <a
                                            href={facebookLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Facebook"
                                        >
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                )}
                                {instagramLink && (
                                    <li className="instagram">
                                        <a
                                            href={instagramLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Instagram"
                                        >
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                )}
                                {googleLink && (
                                    <li className="instagram">
                                        <a
                                            href={googleLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Instagram"
                                        >
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                )}
                                {twitterLink && (
                                    <li className="pinterest">
                                        <a
                                            href={twitterLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Pinterest"
                                        >
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                )}
                            </ul>
                            <ul className="footerLinks">
                                <li>
                                    <NavLink activeClassName="active" to="/contact_us">
                                        {t('contact_us')}
                                    </NavLink>
                                </li>
                                {global.storeDetails?.privacy && (
                                    <li>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            {t('about_us')}
                                        </NavLink>
                                    </li>
                                )}
                                {global.storeDetails?.terms && (
                                    <li>
                                        <NavLink activeClassName="active" to="/faq">
                                            {t('faq')}
                                        </NavLink>
                                    </li>
                                )}
                                {global.storeDetails?.terms_condition && (
                                    <li>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            {t('terms_conditions')}
                                        </NavLink>
                                    </li>
                                )}
                                {global.storeDetails?.privacy_policy && (
                                    <li>
                                        <NavLink activeClassName="active" to="/privacy_policy">
                                            {t('Privacy_Policy')}
                                        </NavLink>
                                    </li>
                                )}
                                <Footernav />
                            </ul>
                            <Copyrightinfo />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
