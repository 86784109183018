import React, { useState, useEffect, useContext } from 'react'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import DirectStripeContext from '../../../Product/context/directStripe/directStripeContext'
import AddCard from './AddCard'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { Button } from '@material-ui/core'

const AllCards = () => {
    const { getStripeCard, usercards, deleteStripeCard, responseStatus, clearResponse } =
        useContext(DirectStripeContext)
    const { user } = useContext(AuthContext)
    const { setAlert } = useContext(AlertContext)
    const [cards, setCards] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [open, setOpen] = useState(false)
    const [addOpen, setAddOpen] = useState(false)
    const [deleteCardDetails, setDeleteCardDetails] = useState({})
    const handleClose1 = () => {
        setAddOpen(false)
    }
    useEffect(() => {
        if (user) {
            getStripeCard({ user_id: user?.id, site_id: global.storeDetails.site_id })
        }
    }, [user])
    useEffect(() => {
        setCards(usercards)
    }, [usercards])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'deleteStripeCard') {
                if (responseStatus.status === 'error') {
                    setDisabled(false)
                    setOpen(!open)
                    setAlert(responseStatus.message, 'error')
                }
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, 'success')
                    setOpen(!open)
                    setDisabled(false)
                    clearResponse()
                    getStripeCard({ user_id: user?.id, site_id: global.storeDetails.site_id })
                }
            }
        }
    }, [responseStatus])
    return (
        <div>
            <div className="savedCardsCnt">
                <div className="savedCards">
                    <div
                        className="emptyCard d-flex justify-content-between flex-column"
                        onClick={() => setAddOpen(!addOpen)}
                    >
                        <div>
                            <span>
                                <img src="/assets/svg/emptyCard.svg" />
                            </span>
                            <h2>ADD A NEW CARD</h2>
                        </div>
                    </div>
                    {cards &&
                        cards.map((data, index) => (
                            <div
                                className="credCard d-flex justify-content-between flex-column"
                                key={index}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    <img src="/assets/images/hologram.png" />
                                    {data.brand}
                                </div>
                                <div className="credCardNum">
                                    <h2>**** **** **** {data.last4}</h2>
                                </div>
                                <div className="credCardName d-flex justify-content-between align-items-center">
                                    <div className="text-left">
                                        <span>CARD HOLDER</span>
                                        <p>{data.name}</p>
                                    </div>

                                    <div className="text-left">
                                        <span>EXPIRES</span>
                                        <p>{data.exp_month + '/' + data.exp_year}</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center cardActions">
                                    {/* <Button
                                        onClick={() =>
                                            toggleFullScreenCardPopup(true, 'update', data)
                                        }
                                        className="edit"
                                    >
                                        <span className="material-icons">edit</span>
                                        Edit
                                    </Button> */}

                                    <Button
                                        onClick={() => {
                                            setOpen(!open)
                                            setDeleteCardDetails({
                                                token_id: data.customer,
                                                card_id: data.id,
                                            })
                                        }}
                                        className="delete"
                                    >
                                        <span className="material-icons">delete</span>
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <div className="PaymentModal">
                <CustomDialog title={'Confirmation'} open={open} function={() => setOpen(!open)}>
                    <h5>Are you sure you want to delete ?</h5>
                    <div className="actionWrapper">
                        <Button onClick={() => setOpen(!open)} disabled={disabled}>
                            Cancel
                        </Button>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault()
                                setDisabled(true)
                                deleteStripeCard({
                                    ...deleteCardDetails,
                                    user_id: user?.id,
                                    site_id: global.storeDetails.site_id,
                                })
                            }}
                            autoComplete="nofill"
                        >
                            <PrimaryButton
                                type="submit"
                                label={disabled ? 'Loading...' : 'Confirm'}
                                disabled={disabled}
                            />
                        </form>
                    </div>
                </CustomDialog>
                <CustomDialog
                    title={'Add New Card'}
                    open={addOpen}
                    // function={() => setAddOpen(!addOpen)}
                >
                    <button
                        type="button"
                        style={{ position: 'absolute', right: '20px', top: '20px' }}
                        className="close"
                        onClick={handleClose1}
                    >
                        <i className="fa fa-times"></i>
                    </button>
                    <AddCard closePopup={() => setAddOpen(!addOpen)} />
                </CustomDialog>
            </div>
        </div>
    )
}
export default AllCards
