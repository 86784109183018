import React from 'react'
import Layout from '../Layout'
import BuyNowOnly from '../../../utils/CommonFunctionality/BuyNowOnly'
const PostBuynow = (props) => {
    return (
        <>
            <Layout props={props}>
                <div className="postProductContainer">
                    <BuyNowOnly theme="FoodIndustry" />
                </div>
            </Layout>
        </>
    )
}

export default PostBuynow
