import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import ProductContext from '../../../Product/context/product/productContext'

const CurrencyConverter = () => {
    const { currency, setCurrency } = useContext(ProductContext)
    const handleChange = (e) => {
        localStorage.setItem('currency', e.target.value)
        setCurrency(e.target.value)
    }
    return (
        <div>
            <CustomSelect
                label=""
                name="currency"
                selectType="noBorder"
                value={currency}
                size="small"
                onChange={handleChange}
            >
                <option value="USD">USD</option>
                {global?.currencies?.map((opt) => (
                    <option value={opt.currency_code}>{opt.currency_code}</option>
                ))}
            </CustomSelect>
        </div>
    )
}

export default CurrencyConverter
