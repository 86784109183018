import React, { useState, useContext, useEffect } from 'react'
import PaginationComponent from '../../../Component/Pagination'
import { Link, useHistory } from 'react-router-dom'
import Bidhistory from '../../../Component/Bidhistory'
import { getFilePath } from '../../../utils/commonFunctions'
import './product.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Timer from '../../../Product/common/timer'
import Bidding from '../../../Product/components/molecules/Bidding/BiddingItem'
import AuthContext from '../../../Product/context/auth/authContext'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import { Button } from '@material-ui/core'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import TableView from '../../../Component/ProductCard/TableView'
import { socket } from '../../../Product/common/socket'
import moment from 'moment'
import Loaders from '../../../Product/components/molecules/Loaders'

const SubLotsComponent = (props) => {
    const authContext = useContext(AuthContext)
    const [auctionView, setAuctionView] = useState('List')
    const [auctionTimer, setAuctionTimer] = useState('')
    const [selectedHistory, setSelectedHistory] = useState({})

    const { user, isAuthenticated } = authContext

    const openHistory = (property) => {
        setSelectedHistory(property)
    }
    const history = useHistory()
    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]
    const sortBy = [
        { value: '7', show: 'Ending Soon' },
        { value: '2', show: 'Lot Id' },
        { value: '3', show: 'Price lowest - Price highest' },
        { value: '5', show: 'Highest Bids' },
        { value: '6', show: 'Lowest Bids' },
        { value: '12', show: 'ERP Order' },
    ]

    useEffect(() => {
        if (socket) {
            socket.on('sliservertime', (data) => {
                if (moment(data.dTime).isValid()) {
                    setAuctionTimer(data.dTime)
                }
            })
        }
    }, [socket])
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        setAuctionView(params.get('view') ? params.get('view') : 'List')
    }, [window.location.search])
    const handleAuctionView = (type) => {
        let currentUrlParams = new URLSearchParams(window.location.search)
        currentUrlParams.set('view', type)
        props.sendType(type === 'Grid' ? 'grid' : 'list')
        history.push(history.location.pathname + '?' + currentUrlParams.toString())
    }
    return (
        <div className="msSublots" id="sublot">
            <div className="d-flex justify-content-end align-items-center flex-wrap mt-5 mb-3">
                {/* <h2 className="auctionListTitle">Lots</h2> */}
                <div className="auctionsMiscFilter">
                    <div className="gridListToggle">
                        <Button
                            className={auctionView === 'Grid' ? 'active' : ''}
                            onClick={() => handleAuctionView('Grid')}
                        >
                            <span className="material-icons">apps</span>Grid
                        </Button>
                        <Button
                            className={auctionView === 'List' ? 'active' : ''}
                            onClick={() => handleAuctionView('List')}
                        >
                            <span className="material-icons">view_list</span>List
                        </Button>
                    </div>
                    <CustomSelect
                        label="Results per page"
                        name="resultsPerPage"
                        selectType="noBorder"
                        value={props.subLotSearch.limit}
                        size="small"
                        onChange={(event, editor) => {
                            props.setSubLotSearch({
                                ...props.subLotSearch,
                                limit: event.target.value,
                            })
                        }}
                    >
                        {pageOptions.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        label="Sort By"
                        name="sortBy"
                        selectType="noBorder"
                        value={props.subLotSearch.sortBy}
                        size="small"
                        onChange={(event, editor) => {
                            props.setSubLotSearch({
                                ...props.subLotSearch,
                                sortBy: event.target.value,
                            })
                        }}
                    >
                        {sortBy.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                </div>
            </div>

            <div className={`lotResults ${auctionView}`}>
                {auctionView === 'Grid' ? (
                    props.lotData.map((data, index) =>
                        props.loading ? (
                            <Loaders name="product_grid_view" isLoading={props.loading} loop={6} />
                        ) : (
                            <GridProduct
                                data={data}
                                key={index}
                                lotImages={data.lotImages}
                                selectedProduct={props.selectedPostion}
                                link={`/gallery/product-view/${data.id}`}
                                handleClick={props.handleClick}
                                timerTheme="multiseller"
                                sliderTheme="multiseller"
                                cardTheme="multiseller"
                                bidStatus={true}
                                bidBtnLabel={
                                    <>
                                        <span className="material-icons-outlined">gavel</span>
                                        Place Bid
                                    </>
                                }
                                type="auction"
                                auctionTimer={auctionTimer}
                                removePopup={true}
                            />
                        ),
                    )
                ) : (
                    <div
                        className="table-responsive lineItemBidTable mt-3 sublots-table"
                        id="sublot"
                    >
                        {props.loading ? (
                            <Loaders name="product_list_view" isLoading={props.loading} loop={6} />
                        ) : (
                            <TableView
                                lotData={props.lotData}
                                selectedPostion={props.selectedPostion}
                                auctionTimer={auctionTimer}
                            />
                        )}
                    </div>
                )}
            </div>

            <PaginationComponent
                onPageChange={(pageNumber) => {
                    document.getElementById('sublot').scrollIntoView()
                    props.setSubLotSearch({ ...props.subLotSearch, page: pageNumber })
                }}
                totalProductCount={props.subLotDataCount}
                currentPage={props.subLotSearch.page}
                resultToShow={props.subLotSearch.limit}
            />
            <Bidhistory
                modaltitle="Bid history"
                open={Object.keys(selectedHistory).length !== 0}
                value={selectedHistory}
                serverTime={props.serverTimeSocket}
                handleClose={() => openHistory({})}
            />
        </div>
    )
}

export default SubLotsComponent
