import React, { useContext, useEffect } from 'react'
import Layout from '../Layout'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import './ProductViewStandalone.css'
import StandaloneAuctioneer from '../../../Component/SlidePanel/StandaloneAuctioneer'

const ProductView = () => {
    return (
        <Layout>
            <div className="productViewStandalone customContainer">
                <StandaloneAuctioneer />
            </div>
        </Layout>
    )
}

export default ProductView
