import React, { useState, useContext, useEffect } from 'react'
import Layout from '../Layout'
import SubLots from '../ProductAuction/SubLots'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Standalone from '../../../Component/SlidePanel/Standalone'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import './ProductViewStandalone.css'
import { handleRedirectInternal } from '../../../Product/common/components'
import productContext from '../../../Product/context/product/productContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import AuthContext from '../../../Product/context/auth/authContext'
import Loaders from '../../../Product/components/molecules/Loaders'

const ProductView = (props) => {
    // const authContext = useContext(AuthContext)
    // const alertContext = useContext(AlertContext)
    // const {
    //     search_allauctions,
    //     search_allauctionproducts,
    //     edit_auction,
    //     getLotList,
    //     getEditAuction,
    // } = productContext
    // const { isAuthenticated } = authContext
    // const { setAlert } = alertContext
    // let auctionid = props.match.params.id
    // const search = useLocation().search
    // const params = new URLSearchParams(search)
    // const [auctionData, setAuctionData] = useState({})
    // const [subLotData, setSubLotData] = useState([])
    // const [private_auction, setPrivateauction] = useState(0)
    // const [isRegistered, setIsRegistered] = useState(0)
    // const [subLotDataCount, setSubLotDataCount] = useState(0)
    const [loading, setLoading] = useState(false)
    // const [subLotSearch, setSubLotSearch] = useState({
    //     page: 1,
    //     sortBy: 7,
    //     lotof: auctionid,
    //     limit: 12,
    //     market_status: 'open',
    //     auction_market_status: 'open',
    //     crop_auction_type: params.get('id_new') ? 'buyer' : '',
    // })

    // const handleClick = async (id) => {
    //     if (parseInt(isRegistered) == 0 && parseInt(private_auction) == 1) {
    //         window.scrollTo(0, 0)
    //         setAlert('Please register auction and continue!', 'error')
    //     } else {
    //         handleRedirectInternall(history, `productView/${id}`)
    //     }
    //     setSelectedProduct(id)
    // }

    // useEffect(() => {
    //     getLotList(subLotSearch, 'auctionView')
    // }, [subLotSearch, isAuthenticated])

    // useEffect(() => {
    //     if (search_allauctionproducts.from === 'auctionView_from_sublot') {
    //         setSubLotData(search_allauctionproducts.results)
    //         setSubLotDataCount(search_allauctionproducts.total_only)
    //     }
    // }, [search_allauctionproducts])

    // useEffect(() => {
    //     setLoading(true)
    //     if (edit_auction.from === 'auctionView') {
    //         if (edit_auction.auction_details) {
    //             setAuctionData(edit_auction.auction_details)
    //             setIsRegistered(edit_auction.is_registered)
    //             setLoading(false)
    //             // setTimeout(() => {
    //             //     setLoading(false)
    //             // }, 1500)
    //         }
    //     }
    // }, [edit_auction])

    // useEffect(() => {
    //     setSubLotSearch({ ...subLotSearch, lotof: auctionid })
    // }, [auctionid])

    return (
        <Layout>
            <div className="productViewStandalone customContainer">
                {loading ? (
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                ) : (
                    <>
                        <Standalone
                            theme="multiAuction"
                            view_more_need={1}
                            lotDetails={
                                props.location?.state?.lotid ? props.location.state.lotid : 0
                            }
                        />
                        {/* <SubLots
                            // selectedPostion={handleClick}
                            // handleClick={handleClick}
                            // title={auctionData.title}
                            // auctionid={auctionid}
                            // sendType={(val) => {
                            //     getLotList(subLotSearch, 'auctionView_from_sublot')
                            // }}
                            // lotData={subLotData}
                            // subLotDataCount={subLotDataCount}
                            // subLotSearch={subLotSearch}
                            // setSubLotSearch={setSubLotSearch}
                            // setViewProduct={setSubLotData}
                            // viewProduct={subLotData}
                            // is_registered={isRegistered}
                            // setAlert={setAlert}
                            // private_auction={private_auction}
                        /> */}
                    </>
                )}
            </div>
        </Layout>
    )
}

export default ProductView
