import React from 'react'
import Header from '../Header'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'

const PickupConfirmed = () => {
    const style = { maxWidth: '600px', height: '400px' }
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyANJvSnxieaGRhq_Ndt1Fe4MKDkDYE-SOM',
    })
    const options = [
        { value: 'one', label: 'Your order has 3 items' },
        { value: 'two', label: 'Two' },
    ]
    const defaultOption = options[0]
    const center = {
        lat: 12.955655657608002,
        lng: 80.14500955470852,
    }
    const [map, setMap] = React.useState(null)
    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center)
        map.fitBounds(bounds)
        setMap(map)
    }, [])
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    return (
        <>
            <Header />
            <div className="pickupConfirmed">
                <div className="pickupConfirmedPop text-center">
                    <h2 className="mb-4">Your pick up for order #10102 has been confirmed!</h2>
                    <div className="row my-5">
                        <div className="col-md-6">
                            <div className="picking d-flex">
                                <p
                                    className="text-left"
                                    style={{ fontWeight: '500', maxWidth: '200px', width: '100%' }}
                                >
                                    Picking up at
                                </p>
                                <div className="text-left ml-3">
                                    <p style={{ fontWeight: '500', marginBottom: '5px' }}>
                                        Dallas Auction Warehouse
                                    </p>
                                    <p>1221 W Campbell Rd,Suite 181 Richardson, TX 75074</p>
                                    <a>Get directions</a>
                                </div>
                            </div>
                            <div className="picking d-flex mt-4">
                                <p
                                    className="text-left"
                                    style={{ fontWeight: '500', maxWidth: '200px', width: '100%' }}
                                >
                                    Customer contact
                                </p>
                                <div className="text-left ml-3">
                                    <p style={{ fontWeight: '500', marginBottom: '5px' }}>
                                        John Smith
                                    </p>
                                    <p>(654)-231-7652</p>
                                </div>
                            </div>
                            <div className="d-flex mt-4">
                                <p
                                    className="text-left"
                                    style={{ fontWeight: '500', maxWidth: '200px', width: '100%' }}
                                >
                                    Pick up time
                                </p>
                                <div className="text-left ml-3">
                                    <p style={{ fontWeight: '500', marginBottom: '5px' }}>
                                        Tuesday,September 19, 2023
                                    </p>
                                    <p>11:15 - 11:30 AM</p>
                                    <a>Change</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {isLoaded ? (
                                <GoogleMap
                                    mapContainerStyle={style}
                                    center={center}
                                    zoom={10}
                                    onUnmount={onUnmount}
                                    onLoad={onLoad}
                                ></GoogleMap>
                            ) : null}
                        </div>
                    </div>
                    <Dropdown
                        options={options}
                        // onChange={this._onSelect}
                        value={defaultOption}
                        placeholder="Your order has 3 items"
                    />
                    <div className="row mt-5">
                        <div className="paySummary col-md-6">
                            <h5 className="text-left mb-4">Payment summary</h5>
                            <div className="d-flex align-items-center">
                                <p className="text-left estimated">Estimated subtotal</p>
                                <p>$28.34</p>
                            </div>
                            <div
                                className="d-flex align-items-center"
                                style={{ borderBottom: '1px solid #ccc' }}
                            >
                                <p className="text-left estimated">Sales tax</p>
                                <p>$02.04</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="text-left total">Total</p>
                                <p className="totaly">$30.38</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h5 className="text-left mb-4">After you scheduled to pick up</h5>
                            <div className="d-flex">
                                <img src="" />
                                <p className="text-left">
                                    Track your order on the website, select on my way when you leave
                                    for the store.
                                </p>
                            </div>
                            <div className="d-flex">
                                <img src="" />
                                <p className="text-left">
                                    Pull into pickup spot, select I am here, from your order history
                                    on the website or using the link sent to your email.
                                </p>
                            </div>
                            <div className="d-flex">
                                <img src="" />
                                <p className="text-left">
                                    We will bring your order out and load it into your car for you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PickupConfirmed
