import React, { useContext, useEffect, useState } from 'react'
import StripeCardContext from '../../../Product/context/stripe/card/cardContext'
import AuthContext from '../../../Product/context/auth/authContext'
import PayContext from '../../../Product/context/payment/payContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import { useHistory } from 'react-router-dom'
import productContext from '../../../Product/context/product/productContext'
const ListingFee = ({ product_id, setListingFeePopup }) => {
    const { get_all_card_details, getAllStripeCards, createStripeCard } =
        useContext(StripeCardContext)
    const { user } = useContext(AuthContext)
    const {
        listingFee,
        responseStatus: responseStatusPayment,
        clearResponse,
    } = useContext(PayContext)
    const { changeMarketStatus, responseStatus } = useContext(productContext)
    const { setAlert } = useContext(AlertContext)
    const history = useHistory()
    const [allData, setAllData] = useState([])
    const [cardData, setCardData] = useState('nocard')
    const [loading, setLoading] = useState(false)
    const [saveCard, setSaveCard] = useState(false)
    const validationCard = Yup.object({
        cardNumber: Yup.string()
            .min(12, 'Invalid credit card number!')
            .max(18, 'Invalid credit card number!')
            .required('Required!'),
        cardName: Yup.string()
            .trim()
            .matches(/^[a-z A-Z]*$/g, 'The special characters and numbers are not allowed!')
            .required('Required!'),
        cardAddress: Yup.string().trim().required('Required!'),
        cardCity: Yup.string().trim().required('Required!'),
        cardState: Yup.string().trim().required('Required!'),
        cardZip: Yup.string()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .min(5, 'Minimum 5 Numbers')
            .max(6, 'Maximum 6 Numbers')
            .required('Required!'),
        expiryMonth: Yup.string().required('Required!'),
        expiryYear: Yup.string().required('Required!'),
        cvv: Yup.string()
            .required('Required!')
            .min(3, 'Mininum 3 Numbers required')
            .max(4, 'Maximum 4 Numbers required'),
    })
    const formik = useFormik({
        initialValues: {
            cardName: '',
            cardNumber: '',
            expiryMonth: '',
            expiryYear: '',
            cvv: '',
            cardAddress: '',
            cardAddress1: '',
            cardCountry: '',
            cardState: '',
            cardCity: '',
            cardZip: '',
        },
        validationSchema: cardData === 'nocard' ? validationCard : '',
        onSubmit: (values) => {
            setLoading(true)

            let body = {}
            if (cardData === 'nocard') {
                body = {
                    customer: user.card_paymentid,
                    currency: user?.country == 38 ? 'cad' : 'usd',
                    description: `Listing Fee For #${product_id}`,
                    card_name: values.cardName,
                    card_number: values.cardNumber,
                    card_cvc: values.cvv,
                    card_exp_month: values.expiryMonth,
                    card_exp_year: values.expiryYear,
                    card_token: true,
                    message: 'Manual Payment!',
                    card_address_line1: values.cardAddress,
                    card_address_line2: values.cardAddress1,
                    card_address_city: values.cardCity,
                    card_address_state: values.cardState,
                    card_address_country: values?.cardCountry == '38' ? 'CA' : 'US',
                    card_address_zip: values.cardZip,
                    billing_details: {
                        email: user.email,
                        name: user.first_name,
                        address: {
                            line1: user.address1,
                            line2: user.address2,
                            city: user.city,
                            state: user.state,
                            country: user?.country == '38' ? 'CA' : 'US',
                            postal_code: user.zip,
                        },
                    },
                }
            } else {
                body = {
                    source: cardData,
                    customer: user.card_paymentid,
                    currency: user?.country == 38 ? 'cad' : 'usd',
                    description: `Listing Fee For #${product_id}`,
                }
            }
            if (saveCard) {
                let newCard = {
                    account_id: process.env.REACT_APP_AUCTIONPAYID,
                    customer_id: user ? user.card_paymentid : '',
                    cvv: '',
                    card_address_zip: values.cardZip,
                    card_address_country: values.cardCountry == '38' ? 'CA' : 'US',
                    is_primary: '',
                    user_id: '',
                    id: '',
                    card_token: true,
                    card_name: values.cardName,
                    card_number: values.cardNumber,
                    card_cvc: values.cvv,
                    card_exp_month: values.expiryMonth,
                    card_exp_year: values.expiryYear,
                    card_address_line1: values.cardAddress,
                    card_address_line2: values.cardAddress1,
                    card_address_city: values.cardCity,
                    card_address_state: values.cardState,
                }
                createStripeCard(newCard)
            }
            listingFee({
                payment_details: body,
                user_country: user?.country == '38' ? 'CA' : 'US',
                product_id,
            })
        },
    })

    const onChange = (e) => {
        e.target.checked ? setCardData(e.target.value) : setCardData('nocard')
    }
    useEffect(() => {
        if (user) {
            getAllStripeCards({
                account_id: process.env.REACT_APP_AUCTIONPAYID,
                customer_id: user.card_paymentid,
                object: 'card',
            })
        }
    }, [user])

    useEffect(() => {
        if (get_all_card_details) {
            setAllData(get_all_card_details.records)
        }
    }, [get_all_card_details])
    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'listingFee') {
                if (responseStatusPayment.status === 'success') {
                    clearResponse()
                    changeMarketStatus({
                        lot_id: product_id,
                        status: 'open',
                    })
                    if (setListingFeePopup != undefined) {
                        setListingFeePopup(false)
                    }
                } else {
                    clearResponse()
                    handleRedirectInternal(history, 'dashboard/mylots')
                    setAlert(responseStatusPayment.message, 'error')
                    setLoading(false)
                }
            }
        }
        if (responseStatus) {
            if (responseStatus.from === 'changeMarketStatus') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, 'dashboard/mylots')
                }
            }
        }
    }, [responseStatus, responseStatusPayment])
    const creditCardYearOptions = []
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }
    const creditCardMonthOptions = []
    for (let month = parseInt(1); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: monthValue,
            show: monthValue,
        })
    }
    const cardDetails = [
        {
            label: 'Card Holder Name',
            type: 'text',
            placeholder: 'Enter card holder name',
            class: 'col-12',
            name: 'cardName',
            formik,
        },
        {
            label: 'Card Number',
            type: 'number',
            placeholder: 'Enter your card number',
            class: 'col-12',
            name: 'cardNumber',
            formik,
        },
        {
            label: 'Card Address',
            type: 'text',
            placeholder: 'Enter your card address',
            class: 'col-12',
            name: 'cardAddress',
            formik,
        },
        {
            label: 'City',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-12',
            name: 'cardCity',
            formik,
        },
        {
            label: 'State',
            type: 'text',
            placeholder: 'Enter your state',
            class: 'col-12',
            name: 'cardState',
            formik,
        },
        {
            label: 'Zip Code',
            type: 'text',
            placeholder: 'Enter your zip code',
            class: 'col-sm-6 col-12',
            name: 'cardZip',
            formik,
        },
        {
            label: 'Expiry Month',
            placeholder: 'MM',
            class: 'col-sm-6 col-12',
            type: 'select',
            name: 'expiryMonth',
            options: creditCardMonthOptions,
            formik,
        },
        {
            label: 'Expiry Year',
            placeholder: 'YYYY',
            class: 'col-sm-6 col-12',
            type: 'select',
            name: 'expiryYear',
            options: creditCardYearOptions,
            formik,
        },
        {
            label: 'Cvv',
            type: 'number',
            placeholder: 'Enter your Cvv',
            class: 'col-sm-6 col-12',
            name: 'cvv',
            formik,
        },
    ]
    return (
        <div>
            <form>
                {allData &&
                    allData.map((data, index) => (
                        <div key={index} className="cardSelect">
                            <label htmlFor={`ritemb${index}`} className="cardmain">
                                <div className="flx">
                                    <p>{'xxxx-xxxx-xxxx-' + data.last4}</p>
                                    <div className="radio-item">
                                        <input
                                            type="checkbox"
                                            id={`ritemb${index}`}
                                            name="ritem"
                                            value={data.id}
                                            checked={cardData === data.id}
                                            onChange={onChange}
                                        />
                                        {/* <span className="vcRad"></span> */}
                                    </div>
                                </div>
                                <div className="flx">
                                    <p>{data.brand}</p>
                                    <p>
                                        {data.exp_month}/{data.exp_year}
                                    </p>
                                </div>
                            </label>
                        </div>
                    ))}
                {cardData === 'nocard' ? (
                    <div className="row">{Object.values(mapData(cardDetails))}</div>
                ) : null}
                <div className="text-center form-group mt-2">
                    <button
                        type="button"
                        className="pink-btn"
                        disabled={loading}
                        onClick={formik.handleSubmit}
                    >
                        {loading
                            ? 'Loading...'
                            : `Pay $${global.pluginConfiguration?.reseller_listing_fee?.service_key}`}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ListingFee
