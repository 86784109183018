import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const HeaderSearchComponent = (props) => {
    const [inputVal, setInputVal] = useState('')
    const handleChange = (e) => setInputVal(e.target.value)
    let history = useHistory()
    let location = useLocation()
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const handleClick = (e) => {
        e.preventDefault()
        history.push(`/search?q=${inputVal}`)
    }
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        //console.log(params, 'this is i[arms')
        setInputVal(params.get('q') ? params.get('q') : '')
    }, [window.location.search])
    return (
        <form className="nav-standard-top-area-search">
            <div className="nav-standard-top-area-search-inner">
                <input
                    type="text"
                    onChange={handleChange}
                    name="q"
                    value={inputVal}
                    className="nav-standard-top-area-search-input open-sans"
                    placeholder="Search for Equipment by Keyword, Category, Listing #, Model, OEM"
                />
                {!!inputVal.length && (
                    <span
                        onClick={() => {
                            setInputVal('')
                            history.goBack()
                            //console.log(history, 'this isndoiasdioasjo')
                        }}
                        className="material-icons search-close"
                    >
                        close
                    </span>
                )}
                {/* <FormControl>
                    <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel>
                    <NativeSelect
                        id="demo-customized-select-native"
                        value={age}
                        onChange={handleChange}
                        input={<BootstrapInput />}
                    >
                        <option aria-label="None" value="" />
                        <option value={10}>Ten</option>
                        <option value={20}>Twenty</option>
                        <option value={30}>Thirty</option>
                    </NativeSelect>
                </FormControl> */}
                <button
                    onClick={handleClick}
                    className="lin lin-magnifier nav-standard-top-area-search-sumbit"
                >
                    <span className="material-icons">search</span>
                </button>
            </div>
        </form>
    )
}

export default HeaderSearchComponent
