// Location Types
export const GET_ALL_LOCATIONS = 'GET_ALL_LOCATIONS'
export const GET_LOCATION = 'GET_LOCATION'

// Reder Types
export const GET_ALL_READERS = 'GET_ALL_READERS'
export const GET_READER = 'GET_READER'

// Payment Types
export const PAYMENT_STATUS = 'PAYMENT_STATUS'

export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
