import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useScript } from '../../../Product/common/hooks'
import PluginContext from '../../../Product/context/plugin/pluginContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { handleRedirectInternal } from '../../../Product/common/components'

const Paypal = (props) => {
    const { paypalLogin, responseStatus } = useContext(PluginContext)
    const { loadUser, user } = useContext(AuthContext)
    const script = useScript('https://www.paypalobjects.com/js/external/api.js')
    const { search } = useLocation()
    const history = useHistory()
    const query = new URLSearchParams(search)

    const getAccessToken = () => {
        paypalLogin({
            site_id: global?.storeDetails.site_id,
            code: query.get('code'),
            user_id: user?.id,
        })
    }

    useEffect(() => {
        if (query.get('code') && user) {
            getAccessToken()
        }
    }, [query.get('code'), user])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'success') {
                if (responseStatus.from === 'paypalLogin') {
                    window.close()
                }
            }
            if (responseStatus.status === 'error') {
                if (responseStatus.from === 'paypalLogin') {
                    handleRedirectInternal(history, 'dashboard/profile')
                }
            }
        }
    }, [responseStatus])
    const paypalConnect = () =>
        paypal.use(['login'], function (login) {
            login.render({
                appid: global.pluginConfiguration?.paypal_payout?.client_key,
                authend: process.env.REACT_APP_PAYMENTMODE,
                scopes: 'openid email https://uri.paypal.com/services/paypalattributes',
                containerid: 'paypal_btn',
                responseType: 'code',
                locale: 'en-us',
                buttonType: 'CWP',
                buttonShape: 'rectangle',
                buttonSize: 'lg',
                fullPage: 'true',
                returnurl: global.pluginConfiguration?.paypal_payout?.redirect_url,
            })
        })
    useEffect(() => {
        if (script === 'ready') {
            paypalConnect()
        }
    }, [script])
    return (
        <div>
            <span id="paypal_btn"></span>
        </div>
    )
}

export default Paypal
