import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import axios from 'axios'
import AuthContext from '../../../Product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
const CurrencyConverter = () => {
    const authContext = useContext(AuthContext)
    const [language, setLanuage] = useState(
        localStorage.getItem('language') ? localStorage.getItem('language') : 'for_english',
    )
    const { i18n } = useTranslation()
    const { user } = authContext
    const handleChange = (e) => {
        localStorage.setItem('language', e.target.value)
        i18n.changeLanguage(e.target.value)
        setLanuage(e.target.value)
    }

    useEffect(() => {
        if (!localStorage.getItem('language_loaded')) {
            getlanuagespharse()
        }
    }, [])

    const getlanuagespharse = () => {
        const l_f = global?.language?.map((opt) => {
            return opt.language_slug
        })
        const body = {
            site_id: global?.storeDetails?.site_id ? global.storeDetails.site_id : '',
            user_id: user?.id ? user.id : 0,
            language_name: l_f.toString(),
            language_field: l_f.toString(),
        }
        axios
            .post(`${process.env.REACT_APP_AUCTION_URL}languagedata`, body)
            .then(function (response) {
                try {
                    if (response.data.status) {
                        var name = []
                        if (response.data.response.results.length > 0) {
                            var arr = response.data.response.results
                            for (var i = 0; i < arr.length; i++) {
                                l_f.map((val) => {
                                    i18n.addResourceBundle(
                                        val,
                                        'translations',
                                        { [arr[i].phrase]: arr[i][val] },
                                        true,
                                        true,
                                    )
                                })
                            }
                            localStorage.setItem('language_loaded', 1)
                        }
                    }
                } catch (e) {
                    //console.log(e)
                }
            })
    }

    return (
        <div>
            <CustomSelect
                label=""
                name="language"
                selectType="noBorder"
                value={language}
                size="small"
                onChange={handleChange}
            >
                {global?.language?.map((opt) =>
                    opt.enable ? <option value={opt.language_slug}>{opt.language}</option> : '',
                )}
            </CustomSelect>
        </div>
    )
}

export default CurrencyConverter
