import React, { useState, useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import AuthContext from '../../../Product/context/auth/authContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
    jsonToFormData,
} from '../../../Product/common/components'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import { Button } from '@material-ui/core'

const BuyNowOnly = (props) => {
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const history = useHistory()
    const { setActiveStep, auction_id } = props
    const {
        postProduct,
        postAuction,
        edit_auction,
        auction_lots,
        getEditAuction,
        responseStatus,
        clearResponse,
    } = productContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user, isAdmin } = authContext
    const [toHome, settoHome] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(true)
    const [stateList, setStateList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [timezone, setTimezone] = useState({
        local: moment().format('MM-DD-YYYY h:mm:ss A'),
        utc: moment().utc().format('MM-DD-YYYY h:mm:ss A'),
    })
    const [open, setOpen] = useState(false)

    const [sendData, setSendData] = useState(false)
    useEffect(() => {
        if (isAuthenticated) {
            if (user.role === '1') {
            } else {
                handleRedirectInternal(history, '')
            }
        }
    }, [isAuthenticated])
    const toggleOpen = () => {
        setOpen(!open)
    }
    const validationArray = Yup.object({
        title: Yup.string().required('Title Required!'),
        description: Yup.string().required('Description Required!'),
        // start_date: Yup.string().required('Required!').nullable(),
        // end_date: Yup.string().required('Required!').nullable(),
        product_image: Yup.array(),
        address: Yup.string().required('Address Required!'),
        country: Yup.string().required('Country Required!'),
        state: Yup.string().required('State Required!'),
        zipcode: Yup.string().required('Zipcode Required!'),
        city: Yup.string().required('City Required!'),
        phone: Yup.string().required('Phone Required!'),
        la_seller_id: Yup.number()
            .typeError('Invalid number!')
            .required('Buyers Premium Required!'),
        bprice: Yup.number()
            .required('Buy Now Price Required!')
            .positive('Should be greater than 1'),
        sku: Yup.string().required('SKU Required!'),
        hasshipping: Yup.boolean(),

        weight: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!')
                .positive('Must be positive')
                .required('Weight Required!'),
        }),
        length: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!')
                .positive('Must be positive')
                .required('Length Required!'),
        }),
        width: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!')
                .positive('Must be positive')
                .required('Width Required!'),
        }),
        height: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!')
                .positive('Must be positive')
                .required('Height Required!'),
        }),
        is_digital: Yup.number(),
        shippingcost: Yup.number('Numbers only!').when('is_digital', {
            is: 1,
            then: Yup.number().positive('Must be positive').lessThan(101, 'Minimum % is 100'),
        }),
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            lot_id: 0,
            status: 'open',
            auctionid: 0,
            private_auction: 0,
            selectedbuyers: [],
            grouping: 1,
            ending_enable: 0,
            ending_items: 0,
            ending_mints: 0,
            la_seller_id: 0,
            product_image: [],
            product_existingimage: [],
            product_imagefile: [],
            localpickup: 1,
            hasshipping: 0,
            weight: 0,
            length: 0,
            width: 0,
            height: 0,
            shippingcost: 0,
            is_digital: 0,
            buyerpremium_percentage: 0,
            //address
            address: '',
            country: '224',
            state: '',
            city: '',
            zipcode: '',
            phone: '+1',
            bprice: '',
            buynow: 1,
            sku: '',
            custom_type: 'buynow',
            custom_field_6:
                !auction_id && global.storeConfigration?.pickup_details_global?.value
                    ? global.storeConfigration?.pickup_details_global?.value
                    : '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            // setOpen(true)
            // setSendData(values)
            const value = { ...values }
            if (!value.product_existingimage.length) {
                if (!value.product_imagefile.length) {
                    return setAlert('Image is required!', 'warning')
                }
            }
            // value.product_image = value.product_imagefile[0]
            value.protitle = value.title
            value.product_image = value.product_imagefile
            value.contenthead1 = 0
            value.localpickup = value.hasshipping == 1 ? 0 : 1
            value.auctiontype = value.custom_type
            value.startprice = value.bprice
            value.bprice = undefined
            value.no_cron = 1
            value.buyerpremium_percentage = value.la_seller_id
            let formDataSend = jsonToFormData(value)

            if (auction_id) {
                postAuction(formDataSend)
                setLoading(true)
            } else {
                // postAuction(formDataSend)
                postProduct(formDataSend)
                setLoading(true)
            }
        },
    })
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'postProduct' || responseStatus.from === 'editProduct') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    setLoading(true)
                    var sendData = formik.values
                    sendData.start_date = new Date()
                    sendData.end_date = new Date()
                    sendData.lot_id = responseStatus.data
                    sendData.product_image = sendData.product_imagefile[0]
                    let formDataSend = jsonToFormData(sendData)
                    postAuction(formDataSend, 1)
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'postAuction') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, '')
                }
            }
        }
        clearResponse()
    }, [responseStatus])

    useEffect(() => {
        if (isAuthenticated) {
            if (auction_id) {
                getEditAuction({ auctionid: auction_id })
            }
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (edit_auction && auction_id) {
            let editAuctionDetails = edit_auction.auction_details
            let lot_id = auction_lots
                .filter((i) => (i.lotof === auction_id ? true : false))
                .map((dat) => dat.id)
            formik.values.title = editAuctionDetails.title
            formik.values.description = editAuctionDetails.description
            formik.values.start_date = editAuctionDetails.date_added

            formik.values.end_date = editAuctionDetails.date_closed

            formik.values.lot_id = lot_id.length ? lot_id : 0
            formik.values.private_auction =
                editAuctionDetails.private_auction &&
                parseInt(editAuctionDetails.private_auction) > 0
                    ? 1
                    : 0
            formik.values.selectedbuyers = editAuctionDetails.selectedbuyers
                ? editAuctionDetails.selectedbuyers.split(',').map((i) => parseInt(i))
                : []
            formik.values.status = editAuctionDetails.market_status
            formik.values.ending_enable = editAuctionDetails.ending_enable
            formik.values.ending_items = editAuctionDetails.ending_items
            formik.values.ending_mints = editAuctionDetails.ending_mints
            formik.values.auctionid = auction_id
            formik.values.la_seller_id = editAuctionDetails.la_seller_id
            formik.values.product_existingimage = [editAuctionDetails.avatar]
            formik.values.product_imagefile = []

            formik.values.address = editAuctionDetails.address
            formik.values.country = editAuctionDetails.country
            formik.values.state = editAuctionDetails.state
            formik.values.city = editAuctionDetails.city
            formik.values.zipcode = editAuctionDetails.zipcode
            formik.values.phone = editAuctionDetails.custom_field_1
            setReload(!reload)
        }
    }, [edit_auction, auction_lots])
    const getLocationData = () => {
        var body = {
            countryCode: formik.values.country,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList([
                        response.data.country.find((value) => parseInt(value.location_id) === 224),
                        ...response.data.country.filter(
                            (value) => parseInt(value.location_id) !== 224,
                        ),
                    ])
                }
            })
            .catch(function (error) {
                //console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.country])

    var productListing = [
        {
            label: 'Title *',
            type: 'text',
            placeholder: 'Enter your Title',
            class: 'col-12',
            name: 'title',
            formik: formik,
        },
        {
            label: 'Description*',
            placeholder: 'Enter Description',
            type: 'ckeditor',
            class: 'col-12',
            name: 'description',
            formik: formik,
        },
        {
            label: global?.storeDetails.theme === 7 ? 'Pickup details' : 'Shipping details',
            placeholder: 'Enter shipping details',
            type: 'ckeditor1',
            class: `${
                global.storeConfigration?.remove_shipping_fields?.value != 1 ? 'col-12' : 'col-12'
            }`,
            name: 'custom_field_6',
            formik: formik,
        },
        // {
        //     label: 'Start date *',
        //     placeholder: 'Enter Start date',
        //     type: 'datetime',
        //     disablePast: true,
        //     class: 'col-sm-6 col-12',
        //     name: 'start_date',
        //     formik: formik,
        //     // utc: true,
        // },
        // {
        //     label: 'End Date *',
        //     placeholder: 'Enter End Date',
        //     disablePast: true,
        //     type: 'datetime',
        //     class: 'col-sm-6 col-12',
        //     name: 'end_date',
        //     // minDate: dateTimeFormatFunction(formik.values.date_added),
        //     // minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
        //     //     formik.values.date_added,
        //     // )}`,
        //     formik: formik,
        //     // utc: true,
        // },

        {
            label: 'Address*',
            placeholder: 'Enter Address',
            type: 'textarea',
            class: 'col-12',
            name: 'address',
            formik: formik,
        },
        {
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: countryList.map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.location_id
                return busCategoryChanged
            }),
            name: 'country',
        },
        {
            label: 'State' + '*',
            placeholder: 'Select your state',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: stateList.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            }),
            name: 'state',
        },
        {
            label: 'City *',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-sm-6 col-12',
            name: 'city',
            formik: formik,
        },
        {
            label: 'Zip code' + '*',
            type: 'text',
            placeholder: 'Enter your zip',
            class: 'col-sm-6 col-12',
            name: 'zipcode',
            formik: formik,
        },
    ]
    if (
        global.storeConfigration?.disabled_phone_from_posting?.value == 0 ||
        global.storeConfigration?.disabled_phone_from_posting == undefined
    ) {
        productListing.push({
            label: 'Phone *',
            type: 'text',
            placeholder: 'Enter your phone number',
            class: 'col-sm-6 col-12',
            name: 'phone',
            formik: formik,
        })
    }
    productListing.push(
        {
            label: 'Buy Now price',
            placeholder: 'Enter buy now price',
            type: 'number',
            class: 'col-sm-6 col-12',
            name: 'bprice',
            formik: formik,
        },
        {
            label: 'SKU *',
            type: 'text',
            placeholder: 'SKU',
            class: 'col-sm-6 col-12',
            name: 'sku',
            formik: formik,
        },
    )
    if (global.storeDetails?.theme !== 7) {
        productListing.push({
            label: "Buyer's Premium (%) *",
            type: 'number',
            placeholder: "Enter buyer's premium (%)",
            class: 'col-sm-6 col-12',
            name: 'la_seller_id',
            formik: formik,
        })
    }
    if (
        global.storeConfigration?.shipping_need?.value == 0 ||
        global.storeConfigration?.shipping_need == undefined
    ) {
        productListing.push(
            {
                title: '',
                type: 'radio',
                noLabel: true,
                name: 'hasshipping',
                int: 1,
                class: 'col-12 mb-3',
                item: [
                    { id: 0, description: 'Local Pickup' },
                    { id: 1, description: 'Shipping' },
                ],
                formik: formik,
            },
            {
                label: 'Weight *',
                type: 'number',
                placeholder: 'Enter Weight',
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
                name: 'weight',
                formik: formik,
            },
            {
                label: 'Length *',
                type: 'number',
                placeholder: 'Enter Weight',
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
                name: 'length',
                formik: formik,
            },
            {
                label: 'Width *',
                type: 'number',
                placeholder: 'Enter Weight',
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
                name: 'width',
                formik: formik,
            },
            {
                label: 'Height *',
                type: 'number',
                placeholder: 'Enter Height',
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
                name: 'height',
                formik: formik,
            },
            {
                label: 'Domestic Flat Shipping Price *',
                type: 'text',
                placeholder: 'Enter your Shipping Price',
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-md-6 col-12`,
                name: 'shippingcost',
                formik: formik,
            },
            {
                type: 'radio',
                noLabel: true,
                name: 'is_digital',
                int: 1,
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-6`,
                item: [
                    { id: 0, description: 'Cost' },
                    { id: 1, description: 'Percentage' },
                ],
                formik: formik,
            },
        )
    }
    productListing.push(
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_imagefile',
            titleText: 'Upload product images *',
            innerText: 'Recommended dimensions: 500px * 500px',
            class: 'col-12',
            folder: 'product',
            multiple: true,
            reorder: true,
            accept: 'image/*',
        },
        {
            type: 'previousImages',
            formik: formik,
            name: 'product_existingimage',
            class: 'col-12',
            folder: 'product',
        },
    )

    // useEffect(() => {
    //     if (formik.values.end_date && formik.values.start_date) {
    //         if (moment(formik.values.end_date).isSameOrBefore(formik.values.start_date)) {
    //             formik.values.end_date = null
    //             return setReload(!reload)
    //         }
    //     }
    // }, [formik.values.start_date, formik.values.end_date])

    return (
        <>
            <form className="pp-form">
                <div>
                    <div className="row">{mapData(productListing)}</div>
                    <div className="row">
                        <div className="col-md-6">
                            <PrimaryButton
                                type="button"
                                label={loading ? 'Loading...' : `${auction_id ? 'Edit' : 'Post'}`}
                                disabled={loading}
                                onClick={() => {
                                    formik.setFieldValue('status', 'open')
                                    formik.handleSubmit()
                                }}
                            />
                        </div>
                        {props.bottomErrorField && (
                            <div className="col-12">
                                {formik.submitCount > 0 && formik.errors && (
                                    <small className="text-danger errLst">
                                        {Object.entries(formik.errors).map(([key, value]) => (
                                            <span className="errValu">
                                                {value}
                                                <span className="cma">,</span>&nbsp;
                                            </span>
                                        ))}
                                    </small>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </>
    )
}

export default BuyNowOnly
