import React, { useState, useContext, useEffect } from 'react'
import PaginationComponent from '../../../Component/Pagination'
import { Link, useHistory, useParams } from 'react-router-dom'
import Bidhistory from '../../../Component/Bidhistory'
import { getFilePath } from '../../../utils/commonFunctions'

import 'react-confirm-alert/src/react-confirm-alert.css'
import Timer from '../../../Product/common/timer'
import Bidding from '../../../Product/components/molecules/Bidding/BiddingItem'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import { Button } from '@material-ui/core'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import TableView from '../../../Component/ProductCard/TableView'
import CustomInput from '../../../Product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { socket } from '../../../Product/common/socket'
import moment from 'moment'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
const AuctionSubLots = (props) => {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const buyerContext = useContext(BuyerContext)
    const [auctionView, setAuctionView] = useState('Grid')
    const [amount, setAmount] = useState({})
    const [lotData, setLotData] = useState(props.lotData)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedHistory, setSelectedHistory] = useState({})
    const [bidPriceData, setBidPriceData] = useState({})
    const [time, setTime] = useState(new Date())
    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext
    const { preAuctionBid } = buyerContext
    const history = useHistory()
    const [timerView, setTimerView] = useState(true)
    const [cancelBid, setCancelBid] = useState('')
    const [nextLot, setNextLot] = useState('')
    const [reload, setReload] = useState(false)
    const openHistory = (property) => {
        setSelectedHistory(property)
    }
    const [currentProject, setCurrentProject] = useState(props.auctionData.current_projectid)

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]
    const sortBy = [
        { value: '1', show: 'Ending Soon' },
        { value: '2', show: 'Lot Id' },
        { value: '3', show: 'Price lowest - Price highest' },
        { value: '5', show: 'Highest Bids' },
        { value: '6', show: 'Lowest Bids' },
    ]

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setTime(new Date())
    //     }, 1000)
    //     return () => clearInterval(interval)
    // }, [])
    useEffect(() => {
        setLotData(props.lotData)
    }, [props.lotData])
    const handleOnChange = (e) =>
        !isNaN(e.target.value)
            ? setAmount({ ...amount, [e.target.name]: e.target.value })
            : setAmount({ ...amount })

    const handleClick = async (body, nPrice, auction_type, lot_id) => {
        if (isAuthenticated) {
            if (props.auctionData.con_check === 1) {
                if (parseInt(props.auctionData.isRegistered) === 0) {
                    setAlert('Please Register Live Auction And Participate.', 'error')
                    return window.scrollTo(0, 0)
                } else {
                    body.hard_bid = 0
                    body.auction_io = 1
                }
            }
            if (
                moment(new Date()).isBefore(moment(props.auctionData.date_added)) &&
                auction_type !== 1
            ) {
                setAlert('Auction is not started!', 'warning')
                return false
            }

            if (nPrice > body.wsprice) {
                setAlert(`Enter $${nPrice} or more`, 'warning')
            } else {
                if (auction_type === 1) {
                    await preAuctionBid({
                        wsprice: parseInt(body.wsprice),
                        userid: user.id,
                        id: parseInt(lot_id),
                        lotid: parseInt(lot_id),
                        bidplan: 'auto',
                        auctionid: parseInt(props.auctionid),
                    })
                    setAmount({ ...amount, [body.id]: '' })
                    // getAuctions(newBody)
                }
            }
        } else {
            history.push({ pathname: '/login', state: props.location })
        }
    }
    const onKeyPress = (e, id) => {
        if (e.key === 'Enter') {
            let productPosition = findIndex(lotData, { id: id })
            if (productPosition !== -1) {
                let productPosition = findIndex(lotData, { id: id })
                let product = lotData[productPosition]
                handleClick(
                    {
                        id: product.id,
                        wsprice: amount[product.id],
                        bid_increment: product.incrementamt,
                    },
                    parseFloat(product.next_bid),
                )
            }
        }
    }
    useEffect(() => {
        socket.on('cancelbidemitted', (data) => {
            //console.log('cancel bid emitted data comee here in product view page', data)
            setCancelBid(data)
        })
    }, [])

    useEffect(() => {
        if (cancelBid) {
            let data = cancelBid
            if (lotData && lotData.length && data.prodata && Object.keys(data.prodata).length > 0) {
                let newLots = [...lotData]
                //console.log('lotIndex retracted bid newLots data', newLots)
                let lotIndex = newLots.findIndex(
                    (ele) => parseInt(ele.id) === parseInt(data.project_id),
                )
                //console.log('lotIndex retracted bid', lotIndex, data, newLots[lotIndex])
                if (lotIndex != -1) {
                    newLots[lotIndex].wprice = data.prodata.proposed_amount
                    newLots[lotIndex].nobids = data.bpop_bidcount
                    //console.log(newLots, 'newLots')
                    setLotData(newLots)
                }
            }
        }
    }, [cancelBid])

    useEffect(() => {
        socket.on('liveauctionAwarding', (data) => {
            //console.log('closed auction emit', data)
            //console.log('allauctionlots new')
            setNextLot(data)
        })
    }, [])

    useEffect(() => {
        if (nextLot) {
            let data = nextLot
            //console.log('closed auction emit data assigned', data)
            if (lotData && lotData.length && data) {
                let newLots = [...lotData]
                let lotIndex = newLots.findIndex((ele) => parseInt(ele.id) === parseInt(data.lotid))

                if (lotIndex !== -1) {
                    newLots[lotIndex].market_status = 'sold'
                    newLots[lotIndex].date_closed = new Date()
                    let current_project = Number(data.next_lot)

                    setCurrentProject(current_project)
                    setLotData(newLots)
                }
                setReload(!reload)
                // currentActn && setLotData(currentActn)
                // newAuctionData && setAuctionData(newAuctionData)
                // if (lotIndex > -1) {
                //     newLots[lotIndex].date_added = new Date()
                //     newLots[lotIndex].date_closed = new Date()
                //     if (newLots[lotIndex + 1]) {
                //         newLots[lotIndex + 1].date_added = new Date()
                //         newLots[lotIndex + 1].date_closed = new Date()
                //     }
                // }
                // //console.log(newLots, 'newLots')
                // setLotData(newLots)
            }
        }
    }, [nextLot])

    // useEffect(() => {
    //     socket.on('liveauctionclose', (data) => {
    //         //console.log('Inside Auction Close123', props.auctionid)
    //         if (parseInt(data.auctionid) === parseInt(props.auctionid)) {
    //             props.setShowTimer(false)
    //             setAlert('This Auction Has Been Ended By Seller Now.', 'success')
    //             history.push('/search')
    //         }
    //     })
    // }, [])

    useEffect(() => {
        socket.on('bidAddtime', (data) => {
            setBidPriceData(data)
        })
    }, [])

    useEffect(() => {
        //console.log(bidPriceData, 'this is the data from the socket')

        if (lotData && lotData.length && bidPriceData && bidPriceData.status !== 'failed') {
            //console.log('auction Data', lotData)
            let newLots = [...lotData]
            let lotIndex = newLots.findIndex(
                (ele) => parseInt(ele.id) === parseInt(bidPriceData.id),
            )
            //console.log('lotIndex', lotIndex, bidPriceData, newLots[lotIndex])
            if (lotIndex > -1) {
                newLots[lotIndex].wprice = bidPriceData.bpop_wprice
                newLots[lotIndex].nobids = bidPriceData.bpop_bidcount
            }
            //console.log(newLots, 'newLots')
            setLotData(newLots)
        }
        //console.log('bid added socket emitted')
    }, [bidPriceData])
    const handleClickSelect = async (id) => {
        setSelectedProduct(id)
    }
    const handleCloseSelect = () => setSelectedProduct(null)

    const timerShow = () => {
        if (new Date() >= new Date(props.auctionData.date_added)) {
            //console.log('date if condition check inside if')
            setTimerView(false)
            clearInterval(timerInterval)
        }
    }

    const timerInterval = setInterval(timerShow, 1000)

    //console.log('auctiondata', props.auctionData)

    return (
        <div className="msSublots" id="sublot">
            <div className="d-flex justify-content-end align-items-center flex-wrap mt-5 mb-3">
                {/* <h2 className="auctionListTitle">Lots</h2> */}
                <div className="auctionsMiscFilter">
                    {/* <div className="gridListToggle">
                        <Button
                            className={auctionView === 'Grid' ? 'active' : ''}
                            onClick={() => setAuctionView('Grid')}
                        >
                            <span className="material-icons">apps</span>Grid
                        </Button>
                        <Button
                            className={auctionView === 'List' ? 'active' : ''}
                            onClick={() => setAuctionView('List')}
                        >
                            <span className="material-icons">view_list</span>List
                        </Button>
                    </div> */}
                    <CustomSelect
                        label="Results per page"
                        name="resultsPerPage"
                        selectType="noBorder"
                        value={props.subLotSearch.limit}
                        size="small"
                        onChange={(event, editor) => {
                            props.setSubLotSearch({
                                ...props.subLotSearch,
                                limit: event.target.value,
                            })
                        }}
                    >
                        {pageOptions.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        label="Sort By"
                        name="sortBy"
                        selectType="noBorder"
                        value={props.subLotSearch.sortBy}
                        size="small"
                        onChange={(event, editor) => {
                            props.setSubLotSearch({
                                ...props.subLotSearch,
                                sortBy: event.target.value,
                            })
                        }}
                    >
                        {sortBy.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                </div>
            </div>
            <div className={`lotResults pb-4 ${auctionView}`}>
                {auctionView === 'Grid' ? (
                    lotData.map((data, index) => (
                        <GridProduct
                            data={data}
                            key={index}
                            lotImages={data.lotImages}
                            selectedProduct={handleClickSelect}
                            link={`/gallery/product-view/${data.id}`}
                            handleClick={handleClickSelect}
                            timerTheme="multiseller"
                            sliderTheme="multiseller"
                            cardTheme="multiseller"
                            noTimer={!timerView}
                            bidBtnLabel={
                                <>
                                    <span className="material-icons-outlined">gavel</span>
                                    Place Bid
                                </>
                            }
                            type="auction"
                            lotBidding={
                                new Date() < new Date(data.date_added) ? (
                                    <div className="placeBid gridViewbtn">
                                        <CustomInput
                                            placeholder={`Enter bid`}
                                            label={`Enter bid`}
                                            variant="outlined"
                                            onChange={handleOnChange}
                                            pattern="^\d*(.\d{0,2})?$"
                                            name={String(data.id)}
                                            value={amount[data.id] || ''}
                                            onKeyPress={(e) => onKeyPress(e, data.id)}
                                        />
                                        <PrimaryButton
                                            label={'Place Max Bid'}
                                            disabled={
                                                isAuthenticated === undefined ||
                                                amount[data.id] === undefined ||
                                                amount[data.id] === ''
                                            }
                                            onClick={(e) =>
                                                handleClick(
                                                    {
                                                        id: data.id,
                                                        wsprice: amount[data.id],
                                                        bid_increment: data.incrementamt,
                                                    },
                                                    parseFloat(data.next_bid),
                                                    props.auctionData.con_check,
                                                    data.id,
                                                    data.incrementamt,
                                                    data.wprice,
                                                    data.current_max_offer_bid,
                                                )
                                            }
                                        />
                                    </div>
                                ) : currentProject !== data.id &&
                                  data.market_status === 'open' &&
                                  new Date() < new Date(data.date_closed) ? (
                                    <div className="placeBid gridViewbtn">
                                        <CustomInput
                                            placeholder={`Enter bid`}
                                            label={`Enter bid`}
                                            variant="outlined"
                                            onChange={handleOnChange}
                                            pattern="^\d*(.\d{0,2})?$"
                                            name={String(data.id)}
                                            value={amount[data.id] || ''}
                                            onKeyPress={(e) => onKeyPress(e, data.id)}
                                        />
                                        <PrimaryButton
                                            label={'Place Max Bid'}
                                            disabled={
                                                isAuthenticated === undefined ||
                                                amount[data.id] === undefined ||
                                                amount[data.id] === ''
                                            }
                                            onClick={(e) =>
                                                handleClick(
                                                    {
                                                        id: data.id,
                                                        wsprice: amount[data.id],
                                                        bid_increment: data.incrementamt,
                                                    },
                                                    parseFloat(data.next_bid),
                                                    props.auctionData.con_check,
                                                    data.id,
                                                    data.incrementamt,
                                                    data.wprice,
                                                    data.current_max_offer_bid,
                                                )
                                            }
                                        />
                                    </div>
                                ) : data.market_status !== 'open' ? (
                                    <p className="mb-0 mt-5 actnClosedIndi text-danger">
                                        Auction Closed
                                    </p>
                                ) : (
                                    ' '
                                )
                            }
                        />
                    ))
                ) : (
                    <div
                        className="table-responsive lineItemBidTable mt-3 sublots-table"
                        id="sublot"
                    >
                        <TableView lotData={lotData} selectedPostion={handleClickSelect} />
                    </div>
                )}
            </div>
            {/* <MultisellerSlider
                type="right"
                selectedLot={selectedProduct}
                handleClose={handleCloseSelect}
                lotBidding={
                    new Date() < new Date(data.date_added) ? (
                        <div className="placeBid gridViewbtn">
                            <CustomInput
                                placeholder={`Enter bid`}
                                label={`Enter bid`}
                                variant="outlined"
                                onChange={handleOnChange}
                                pattern="^\d*(.\d{0,2})?$"
                                name={String(data.id)}
                                value={amount[data.id] || ''}
                                onKeyPress={(e) => onKeyPress(e, data.id)}
                            />
                            <PrimaryButton
                                label={'Place Max Bid'}
                                disabled={
                                    isAuthenticated === undefined ||
                                    amount[data.id] === undefined ||
                                    amount[data.id] === ''
                                }
                                onClick={(e) =>
                                    handleClick(
                                        {
                                            id: data.id,
                                            wsprice: amount[data.id],
                                            bid_increment: data.incrementamt,
                                        },
                                        parseFloat(data.next_bid),
                                        props.auctionData.con_check,
                                        data.id,
                                        data.incrementamt,
                                        data.wprice,
                                        data.current_max_offer_bid,
                                    )
                                }
                            />
                        </div>
                    ) : props.auctionData.current_projectid !== data.id &&
                      data.market_status === 'open' &&
                      new Date() < new Date(data.date_closed) ? (
                        <div className="placeBid gridViewbtn">
                            <CustomInput
                                placeholder={`Enter bid`}
                                label={`Enter bid`}
                                variant="outlined"
                                onChange={handleOnChange}
                                pattern="^\d*(.\d{0,2})?$"
                                name={String(data.id)}
                                value={amount[data.id] || ''}
                                onKeyPress={(e) => onKeyPress(e, data.id)}
                            />
                            <PrimaryButton
                                label={'Place Max Bid'}
                                disabled={
                                    isAuthenticated === undefined ||
                                    amount[data.id] === undefined ||
                                    amount[data.id] === ''
                                }
                                onClick={(e) =>
                                    handleClick(
                                        {
                                            id: data.id,
                                            wsprice: amount[data.id],
                                            bid_increment: data.incrementamt,
                                        },
                                        parseFloat(data.next_bid),
                                        props.auctionData.con_check,
                                        data.id,
                                        data.incrementamt,
                                        data.wprice,
                                        data.current_max_offer_bid,
                                    )
                                }
                            />
                        </div>
                    ) : (
                        ' '
                    )
                }
            /> */}
            <PaginationComponent
                onPageChange={(pageNumber) => {
                    document.getElementById('sublot').scrollIntoView()
                    props.setSubLotSearch({ ...props.subLotSearch, page: pageNumber })
                }}
                totalProductCount={props.subLotDataCount}
                currentPage={props.subLotSearch.page}
                resultToShow={props.subLotSearch.limit}
            />
            <Bidhistory
                modaltitle="Bid history"
                open={Object.keys(selectedHistory).length !== 0}
                value={selectedHistory}
                serverTime={props.serverTimeSocket}
                handleClose={() => openHistory({})}
            />
        </div>
    )
}

export default AuctionSubLots
