import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { PrevArrow, NextArrow } from './Arrows'
const settings = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
}

const InstaSlider = ({ title, data }) => {
    return (
        <div className="customContainer insta-slider-wrapper">
            <h6>
                <i className="fab fa-instagram"></i> {title}
            </h6>
            <Slider {...settings} className="insta-slider">
                {data.map((item, index) => {
                    return (
                        <div className="item instagallery" key={index}>
                            <img src={item.img} alt="" className="insta-img" />
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default InstaSlider
