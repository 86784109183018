import React from 'react'
import SEO from '../../../utils/CommonFunctionality/SEO'
import Layout from '../Layout'

export default function PrivacyPolicy(props) {
    return (
        <Layout props={props}>
            <SEO title={`${global?.storeDetails?.name} | Privacy Policy`} />

            <div className="px-5 py-4  staticPgWrapper customContainer">
                {/* <h2>About Us</h2> */}
                <span
                    dangerouslySetInnerHTML={{
                        __html: global.storeDetails.privacy_policy,
                    }}
                ></span>
            </div>
        </Layout>
    )
}
