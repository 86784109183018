import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const HeaderSearchComponent = ({ handleParamsChange, searchValues }) => {
    return (
        <form className="nav-standard-top-area-search">
            <div className="nav-standard-top-area-search-inner">
                <div>
                    <input
                        type="text"
                        onChange={(e) => handleParamsChange(e)}
                        name="search_title_only_auction_io"
                        value={searchValues.search_title_only_auction_io}
                        className="nav-standard-top-area-search-input open-sans"
                        placeholder="Search"
                    />
                </div>
                {/* <button
                    onClick={handleClick}
                    className="lin lin-magnifier nav-standard-top-area-search-sumbit"
                >
                    <span className="material-icons">search</span>
                </button> */}
            </div>
        </form>
    )
}

export default HeaderSearchComponent
