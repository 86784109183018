import { combineReducers } from 'redux'
import SearchReducer from './SearchReducer'
import SearchGalleryReducer from './SearchGalleryReducer'
import SearchLotReducer from './SearchLotReducer'
import UserDataReducer from './UserdataReducer'
import CartReducer from './CartReducer'

const rootReducer = combineReducers({
    SearchReducer,
    SearchGalleryReducer,
    UserDataReducer,
    SearchLotReducer,
    CartReducer,
})
export default rootReducer
