import React from 'react'
import Layout from '../Layout'
import './PostProduct.css'
import PostProductDynamic from '../../../utils/CommonFunctionality/PostProductDynamic'

const PostProduct = (props) => {
    return (
        <Layout props={props}>
            <div>
                <PostProductDynamic />
            </div>
        </Layout>
    )
}

export default PostProduct
