import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Favourite from '../Favourite_copy'
import { Image } from 'react-shimmer'
import './cards.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import moment from 'moment'
import { getTimeInterval } from '../../utils/commonFunctions'
import { useHistory } from 'react-router-dom'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import AuthContext from '../../Product/context/auth/authContext'
import Timer from '../../Product/common/timer'
import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Facebook from '../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../utils/CommonFunctionality/Share/Pinterest'
import Copy from '../../utils/CommonFunctionality/Share/Copy'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import {
    currencyFormat,
    dateFormatFront,
    handleRedirectInternal,
    sellerNameFormat,
} from '../../Product/common/components'

import { Button, Tooltip, Popover } from '@material-ui/core'
import AddToCalendar from '../../Product/components/molecules/AddToCalendar'
import CartContext from '../../Product/context/cart/cartContext'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { allCondition } from '../../utils'
import axios from 'axios'
import userContext from '../../Product/context/user/userContext'
import CurrencyConverterFormat from '../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'
import { useTranslation } from 'react-i18next'

const GridItem = ({
    noTimer,
    noBuyNow,
    noBidButton,
    data,
    handleClick,
    type,
    link,
    timerTheme,
    cardTheme,
    bidBtnLabel,
    getAllProducts,
    lotImages,
    auction_and_buynow,
    lotBidding,
    currencyType,
    fromSearch,
    className,
    bidStatus,
    showCondition,
    showLocation,
    timerIcon,
    auctionTimer,
    viewProduct,
    setViewProduct,
    lotData,
    lotDetails,
    productViewLink,
    showEstimate,
    audio,
    removePopup,
    need_end_date,
    next_week_date,
}) => {
    const history = useHistory()
    const addDefaultSrc = (e) => {
        e.target.src = `${global?.storeDetails?.logoValue}`
        e.target.className = `gridImage default`
    }
    const authContext = useContext(AuthContext)
    const cartContext = useContext(CartContext)
    const [selectedProduct, setSelectedProduct] = useState({})
    const { t } = useTranslation()
    const {
        addToCart,
        getUserCart,
        removeFromCart,
        responseStatus,
        buyer_cartitems,
        clearResponse,
    } = cartContext

    const { countryValues } = useContext(userContext)

    const { isAuthenticated, user } = authContext
    const [active, setActive] = useState(false)

    const [reload, setReload] = useState(false)

    const [bidText, setBidText] = useState('Bid Now')

    useEffect(() => {
        if (buyer_cartitems.results) {
            if (data.id) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: data.id,
                })
                position === -1 ? setActive(false) : setActive(true)
            }
        }
    }, [buyer_cartitems.results, data.id])

    const handleCart = async (id) => {
        if (active) {
            removeFromCart({ id })
        } else addToCart({ id: data.id, qty: data.qty })
    }

    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'addToCart') {
    //             if (responseStatus.status === 'success') {
    //                 console.log('++++++++++++')
    //                 getUserCart()
    //                 clearResponse()
    //             }
    //         } else if (responseStatus.from === 'removeFromCart') {
    //             if (responseStatus.status === 'success') {
    //                 getUserCart()
    //                 clearResponse()
    //             }
    //         }
    //     }
    // }, [responseStatus])

    useEffect(() => {
        if (data?.extra_fields?.includes('}')) {
            let parse = JSON.parse(data.extra_fields)
            Object.keys(parse).map((val) => {
                data[val] = parse[val]
            })
        }
    }, [])
    const getBidText = (data) => {
        if (
            isAuthenticated &&
            (data.latestbid_user != null || data.highest_bidder_user_id != null) &&
            user?.id != undefined
        ) {
            if (data.auction) {
                if (
                    (data.latestbid_user == user?.id || data.highest_bidder_user_id == user?.id) &&
                    data.wprice >= data.rprice
                ) {
                    if (data.market_status == 'open') {
                        setBidText('Winning')
                    } else if (data.market_status == 'sold') {
                        setBidText('Won')
                    }
                } else if (
                    data.latestbid_user != user?.id &&
                    (data.currentUserBidAmount || data.bid_or_not || data.cbidnot)
                ) {
                    if (data.market_status == 'open') {
                        setBidText('Outbid')
                    } else if (data.market_status == 'sold') {
                        setBidText('Lost')
                    }
                } else {
                    setBidText('Bid Now')
                }
            }
        } else {
            setBidText('Bid Now')
        }
    }

    useEffect(() => {
        getBidText(data)
    }, [data.wprice, user?.id, isAuthenticated])

    const [countryList, setCountryList] = useState([])

    const isArtTheme = document.querySelector('body').classList.contains('artTheme')
    const isHyperMicroTheme = document.querySelector('body').classList.contains('hyperMicro')

    const getLocationData = () => {
        var body = {
            countryCode: 38,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setCountryList([
                        response.data.country.find((value) => parseInt(value.location_id) === 224),
                        ...response.data.country.filter(
                            (value) => parseInt(value.location_id) !== 224,
                        ),
                    ])
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        // getLocationData()
        getBidText(data)
    }, [data])

    // share icons funa

    const [shareAnchorEl, setShareAnchorEl] = useState(null)

    const handleShareClick = (event) => {
        setShareAnchorEl(event.currentTarget)
    }

    const handleShareClose = () => {
        setShareAnchorEl(null)
    }

    const openshare = Boolean(shareAnchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <div
            className={`card grid-card gallery ${className} 
            ${bidText === 'Winning' && 'winningcard'} 
            ${bidText === 'Outbid' && 'losingcard'} `}
        >
            {cardTheme == 'liquidationTwo' && (
                <>
                    <div className="lctnFavWrapper row d-flex align-items-center justify-content-between">
                        <div className="col-md-4 col-4">
                            <div className="d-flex align-items-center justify-content-between">
                                {data.market_status == 'open' && (
                                    <FavouriteCheckbox
                                        watchlisted={
                                            data.watchlistid ||
                                            data.watchlisted ||
                                            data.isFav ||
                                            data.watch
                                        }
                                        project_id={data.id}
                                        getAllProducts={getAllProducts}
                                        data={data}
                                        from={'card'}
                                        viewProduct={viewProduct}
                                        setViewProduct={setViewProduct}
                                    />
                                )}
                                {cardTheme === 'liquidationTwo' && (
                                    <>
                                        {isHyperMicroTheme && (
                                            <>
                                                <span
                                                    aria-describedby={id}
                                                    className="material-icons ml-2 share-Icon"
                                                    onClick={handleShareClick}
                                                >
                                                    share
                                                </span>
                                                <Popover
                                                    id={id}
                                                    open={openshare}
                                                    anchorEl={shareAnchorEl}
                                                    onClose={handleShareClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <div className="pvMiscActions mb-0 p-2">
                                                        <Facebook
                                                            path={`/search?product=${data.id}`}
                                                            title={data.title}
                                                        />
                                                        <Whatsapp
                                                            path={`/search?product=${data.id}`}
                                                            title={data.title}
                                                        />
                                                        {global.storeConfigration
                                                            ?.disable_twitter_sharing?.value !=
                                                        1 ? (
                                                            <Twitter
                                                                path={`/search?product=${data.id}`}
                                                                title={data.title}
                                                            />
                                                        ) : null}
                                                        <Email
                                                            path={`/search?product=${data.id}`}
                                                            subject={data.title}
                                                            body={data.description}
                                                        />
                                                        {global.storeConfigration
                                                            ?.disable_pinterest_sharing?.value !=
                                                        1 ? (
                                                            <Pinterest
                                                                path={`/search?product=${data.id}`}
                                                                title={data.title}
                                                                media={
                                                                    `${
                                                                        data.content_head1 === '0'
                                                                            ? process.env
                                                                                  .REACT_APP_BASE_URL +
                                                                              'uploads/product/'
                                                                            : process.env
                                                                                  .REACT_APP_PRODUCT_IMAGE_URL
                                                                    }` + data.avatar
                                                                }
                                                            />
                                                        ) : null}
                                                        <div
                                                            className="cpyToClipDiv"
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <Copy
                                                                text={`/search?product=${data.id}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </Popover>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-md-8 col-8">
                            <div className="lctnTxtWrpr d-flex align-items-center justify-content-end">
                                <span className="material-icons">location_on</span>
                                <span className="location-class">{data?.auction_city}</span>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="grid-img-wrap">
                {cardTheme === 'carAuction' ? (
                    <>
                        <FavouriteCheckbox
                            watchlisted={
                                data.watchlistid || data.watchlisted || data.isFav || data.watch
                            }
                            project_id={data.id}
                            getAllProducts={getAllProducts}
                            data={data}
                            from={'card'}
                            viewProduct={viewProduct}
                            setViewProduct={setViewProduct}
                        />
                        {type != 'buynow' && type == 'Auction'
                            ? ''
                            : isAuthenticated && (
                                  <Button
                                      className={`cartBtn crActnCart ${active ? 'active' : ''} ${
                                          cardTheme === 'carAuction' && data.buynow == 0
                                              ? 'd-none'
                                              : ''
                                      }`}
                                      onClick={() => handleCart(data.id)}
                                      disabled={data.buynow == 0}
                                      //   disabled={
                                      //       data.buynow == 0 ||
                                      //       data.market_status == 'sold' ||
                                      //       parseInt(data.bprice) <
                                      //           parseInt(data.wprice)
                                      //   }
                                  >
                                      <span className="material-icons-outlined">
                                          {active ? 'shopping_cart_checkout' : 'add_shopping_cart'}
                                      </span>
                                  </Button>
                              )}
                    </>
                ) : (
                    ''
                )}
                {global?.storeConfigration?.sold_status?.value == '1' ? (
                    <></>
                ) : (
                    <>
                        {data.market_status === 'sold' && (
                            <div className="soldWrapper">
                                <p>SOLD</p>
                            </div>
                        )}
                        {data.market_status === 'closed' && (
                            <div className="soldWrapper">
                                <p>UNSOLD</p>
                            </div>
                        )}
                    </>
                )}
                <LazyLoadImage
                    src={`${
                        data.content_head1 === '0' || data.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL
                    }${global.storeConfigration?.image_compression?.value == 1 ? 'thumb_' : ''}${
                        (lotImages?.length && lotImages[0]?.file_name) ||
                        (data?.lotImages?.length && data?.lotImages[0]?.file_name) ||
                        data.file_name ||
                        data.avatar_vc
                    }`}
                    effect="blur"
                    placeholderSrc="/assets/svg/imageLoading.svg"
                    height="100%"
                    width="100%"
                    className="gridImage"
                    //onClick={() => handleClick(data.id)}
                    onError={addDefaultSrc}
                    alt={data.title}
                    onClick={() =>
                        productViewLink
                            ? history.push({
                                  pathname: productViewLink,
                              })
                            : global.storeDetails?.theme === 9
                            ? history.push({
                                  pathname: `/productView/${data.id}`,
                                  state: { lotid: lotDetails },
                              })
                            : cardTheme === 'carAuction'
                            ? history.push({
                                  pathname: `/productView/${data.id}`,
                                  state: { lotid: lotDetails },
                              })
                            : handleClick(data.id)
                    }
                />
                {/* {(cardTheme == 'multiseller' ||
                    cardTheme == 'HeavyEquipment' ||
                    cardTheme == 'fundRaiser' ||
                    cardTheme == 'auctioneer') &&
                    data.market_status == 'open' && (
                        <FavouriteCheckbox
                            watchlisted={data.watchlistid || data.watchlisted || data.isFav}
                            project_id={data.id}
                            from="card"
                            getAllProducts={getAllProducts}
                            viewProduct={viewProduct}
                            setViewProduct={setViewProduct}
                        />
                    )} */}

                {global.pluginConfiguration?.most_viewed?.enable == 1 && data.viewers ? (
                    <p className="viewsCountValue">Viewers: {data.viewers}</p>
                ) : null}

                {(cardTheme === 'multiseller' || cardTheme === 'liquidationTwo') &&
                bidStatus === true &&
                data.auction != 0 ? (
                    <>
                        {bidText == 'Winning' ? (
                            <p className="wngSts grnTxt">You Are Winning!</p>
                        ) : bidText == 'Won' ? (
                            <p className="wngSts grnTxt">You Won!</p>
                        ) : bidText == 'Outbid' ? (
                            <p className="wngSts LsgTxt">You Are Losing</p>
                        ) : bidText == 'Lost' ? (
                            <p className="wngSts LsgTxt">You Lost</p>
                        ) : (
                            <p className="wngSts"></p>
                        )}
                    </>
                ) : (
                    ''
                )}
            </div>
            <div className="card-body">
                {cardTheme === 'ArtAuction' ? (
                    <>
                        <div className="ttlInfo">
                            {data.title !== undefined && (
                                <h5
                                    className="name-txt cursorDecoy"
                                    onClick={() =>
                                        productViewLink
                                            ? history.push({
                                                  pathname: productViewLink,
                                              })
                                            : handleClick(data.id)
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: data.title,
                                    }}
                                />
                            )}
                            <h6 className="galleryPrice">
                                {data.auction && data.wprice ? (
                                    <>
                                        {data.market_status == 'closed' ? (
                                            <span>UnSold</span>
                                        ) : (
                                            <div className="d-flex align-item-center">
                                                {/* <span>
                                                {data.market_status == 'sold'
                                                    ? 'Sold'
                                                    : data.nobids || data.bid_count
                                                    ? 'Current'
                                                    : 'Start'}{' '}
                                                Price:{' '}
                                            </span> */}
                                                <p>
                                                    {currencyFormat(
                                                        parseFloat(data.wprice).toFixed(2),
                                                        currencyType,
                                                    )}
                                                </p>
                                                <p>
                                                    <CurrencyConverterFormat amount={data.wprice} />
                                                </p>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        {data.market_status == 'closed' ? (
                                            <span>UnSold</span>
                                        ) : (
                                            <>
                                                {/* <span>
                                                {data.market_status == 'sold'
                                                    ? 'Sold'
                                                    : parseInt(data.wprice) ===
                                                      parseInt(data.sprice)
                                                    ? 'Start'
                                                    : 'Current'}
                                                Price:
                                            </span> */}
                                                <span className="gridNotAvailable">
                                                    Not Available
                                                </span>
                                            </>
                                        )}
                                    </>
                                )}
                            </h6>
                        </div>
                        <div
                            className={`${
                                isAuthenticated ? 'loggedIn' : 'notLogged'
                            } mnCardCntnr d-flex align-items-center justify-content-between`}
                        >
                            {isArtTheme && isAuthenticated && (
                                <>
                                    {data.market_status == 'open' && (
                                        <div className="d-flex align-items-center justify-content-between">
                                            <FavouriteCheckbox
                                                watchlisted={
                                                    data.watchlistid ||
                                                    data.watchlisted ||
                                                    data.isFav
                                                }
                                                project_id={data.id}
                                                from="card"
                                                getAllProducts={getAllProducts}
                                                viewProduct={viewProduct}
                                                setViewProduct={setViewProduct}
                                            />
                                            <span className="LikeTxt">Like</span>
                                        </div>
                                    )}
                                </>
                            )}
                            {!noTimer && data.date_closed && (
                                <div className={`gcTimer ${data.buynow === 1 ? 'noBackDrop' : ''}`}>
                                    {(data.market_status === 'open' && data.auction) ||
                                    (data.market_status === 'open' && auction_and_buynow) ? (
                                        <Timer
                                            date_added={data.date_added}
                                            date_closed={data.date_closed}
                                            withText={1}
                                            endText={
                                                isArtTheme ? t('time_left') : t('time_left') + ': '
                                            }
                                            startText={t('starts_in') + ': '}
                                            theme={timerTheme}
                                            audio={audio}
                                        />
                                    ) : (
                                        <>
                                            {data.buynow === 0 ? (
                                                <span className="closedText">Auction Closed</span>
                                            ) : (
                                                <div className="closedText"></div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="rtCrdCnr d-flex align-items-center justify-content-end">
                                {!isArtTheme && (
                                    <>
                                        {data.market_status == 'open' && (
                                            <FavouriteCheckbox
                                                watchlisted={
                                                    data.watchlistid ||
                                                    data.watchlisted ||
                                                    data.isFav
                                                }
                                                project_id={data.id}
                                                from="card"
                                                getAllProducts={getAllProducts}
                                                viewProduct={viewProduct}
                                                setViewProduct={setViewProduct}
                                            />
                                        )}
                                    </>
                                )}
                                <PrimaryButton
                                    btnSize="ml-3"
                                    onClick={() =>
                                        isAuthenticated
                                            ? productViewLink
                                                ? history.push({
                                                      pathname: productViewLink,
                                                  })
                                                : handleClick(data.id)
                                            : handleRedirectInternal(history, 'login')
                                    }
                                >
                                    {isArtTheme ? (
                                        <>{isAuthenticated ? t('Bid') : t('login_to_bid')}</>
                                    ) : (
                                        <>{isAuthenticated ? t('bid_now') : t('login_to_bid')}</>
                                    )}
                                </PrimaryButton>
                            </div>
                        </div>
                        {need_end_date && (
                            <>
                                {data.auction == 1 ? (
                                    <div className="text-center endDteTxt grid-last-paragraph">
                                        {data.market_status === 'open' ? (
                                            <>
                                                {new Date(next_week_date) <
                                                new Date(data.date_closed) ? (
                                                    <p className="mb-0">
                                                        Ends{' '}
                                                        {`${moment(data.date_closed)
                                                            .tz(
                                                                global?.storeConfigration
                                                                    ?.auction_view_time_zone?.value
                                                                    ? global?.storeConfigration
                                                                          ?.auction_view_time_zone
                                                                          ?.value
                                                                    : moment.tz.guess(),
                                                            )
                                                            .format('MMM Do')}, ${moment(
                                                            data.date_closed,
                                                        )
                                                            .tz(
                                                                global?.storeConfigration
                                                                    ?.auction_view_time_zone?.value
                                                                    ? global?.storeConfigration
                                                                          ?.auction_view_time_zone
                                                                          ?.value
                                                                    : moment.tz.guess(),
                                                            )
                                                            .format('h:mm a z')}`}
                                                    </p>
                                                ) : (
                                                    <p className="mb-0">
                                                        Ends{' '}
                                                        {`${moment(data.date_closed)
                                                            .tz(
                                                                global?.storeConfigration
                                                                    ?.auction_view_time_zone?.value
                                                                    ? global?.storeConfigration
                                                                          ?.auction_view_time_zone
                                                                          ?.value
                                                                    : moment.tz.guess(),
                                                            )
                                                            .format('dddd')} at ${moment(
                                                            data.date_closed,
                                                        )
                                                            .tz(
                                                                global?.storeConfigration
                                                                    ?.auction_view_time_zone?.value
                                                                    ? global?.storeConfigration
                                                                          ?.auction_view_time_zone
                                                                          ?.value
                                                                    : moment.tz.guess(),
                                                            )
                                                            .format('h:mm a z')}`}
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <p className="mb-0">Auction Closed</p>
                                        )}
                                    </div>
                                ) : null}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {data.title !== undefined && (
                            <>
                                {cardTheme !== 'liquidationTwo' && cardTheme !== 'carAuction' && (
                                    <>
                                        {global.storeConfigration?.disable_hosted_by?.value != 1 &&
                                        data.seller_name ? (
                                            <h5 className="hostedBy condition executed">
                                                Hosted By: {sellerNameFormat(data.seller_name)}
                                            </h5>
                                        ) : null}
                                    </>
                                )}
                                <h5
                                    className="name-txt cursorDecoy"
                                    onClick={() =>
                                        cardTheme === 'carAuction'
                                            ? history.push({
                                                  pathname: `/productView/${data.id}`,
                                                  state: { lotid: lotDetails },
                                              })
                                            : productViewLink
                                            ? history.push({
                                                  pathname: productViewLink,
                                              })
                                            : handleClick(data.id)
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: data.title,
                                    }}
                                />
                                {showEstimate === true &&
                                    (parseInt(data.lowest) > 0 || parseInt(data.highest) > 0) && (
                                        <p>
                                            Est:{' '}
                                            <span>
                                                {currencyFormat(data.lowest)} -{' '}
                                                {currencyFormat(data.highest)}
                                            </span>
                                        </p>
                                    )}
                                {cardTheme !== 'liquidationTwo' && (
                                    <>
                                        {global.storeConfigration?.enable_city?.value == 1 &&
                                        data.product_city ? (
                                            <h5 className="hostedBy">{data.product_city}</h5>
                                        ) : (
                                            <h5 className="hostedBy"></h5>
                                        )}
                                    </>
                                )}

                                {cardTheme === 'liquidationTwo' && (
                                    <div className="pdtStsInfo">
                                        <ul>
                                            <li>
                                                <label>Condition:</label>
                                                <Tooltip
                                                    title={data.condition_field || '-'}
                                                    arrow
                                                    placement="top"
                                                >
                                                    <p
                                                        className={`m-0 cursorDecoy ${
                                                            data.condition_field?.includes(
                                                                'Used - Like New',
                                                            )
                                                                ? 'text-Yellow'
                                                                : data.condition_field?.includes(
                                                                      'New',
                                                                  )
                                                                ? 'text-success'
                                                                : 'text-danger'
                                                        }`}
                                                    >
                                                        {data.condition_field || '-'}
                                                    </p>
                                                </Tooltip>
                                            </li>

                                            <li>
                                                <label>Functional:</label>
                                                <p
                                                    className={`m-0 ${
                                                        data.functional_field?.includes('Yes')
                                                            ? 'text-success'
                                                            : 'text-danger'
                                                    }`}
                                                >
                                                    {data.functional_field || '-'}
                                                </p>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <label>Damaged:</label>
                                                <p
                                                    className={`m-0 ${
                                                        data.damaged_field?.includes('No')
                                                            ? 'text-success'
                                                            : 'text-danger'
                                                    }`}
                                                >
                                                    {data.damaged_field || '-'}
                                                </p>
                                            </li>

                                            <li>
                                                <label>Missing Parts:</label>
                                                <p
                                                    className={`m-0 ${
                                                        data.missing_parts_field?.includes('Yes')
                                                            ? 'text-danger'
                                                            : 'text-success'
                                                    }`}
                                                >
                                                    {data.missing_parts_field || '-'}
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                )}

                                {showCondition ? (
                                    <p className="gpAddnInfo">
                                        Condition:{' '}
                                        {cardTheme == 'auctioneer' ? (
                                            <span>{data?.auctionnotes}</span>
                                        ) : (
                                            <span>
                                                {data?.conditionunique
                                                    ? allCondition
                                                          .filter(
                                                              (condition) =>
                                                                  condition.id ==
                                                                  data.conditionunique,
                                                          )
                                                          .map((d) => d.description)
                                                    : '--'}
                                            </span>
                                        )}
                                    </p>
                                ) : (
                                    ''
                                )}
                                {/* Write Optimized code for this and uncomment */}
                                {/* {showLocation ? (
                                    <p className="gpAddnInfo">
                                        Location:{' '}
                                        <span>
                                            {data?.location
                                                ? countryList
                                                      .filter(
                                                          (country) =>
                                                              country.location_id == data.location,
                                                      )
                                                      .map((d) => d.name)
                                                : '--'}
                                        </span>
                                    </p>
                                ) : (
                                    ''
                                )} */}

                                {cardTheme !== 'carAuction' && (
                                    <>
                                        {!noTimer && data.date_closed && (
                                            <div
                                                className={`gcTimer ${
                                                    data.buynow === 1 && data.auction === 0
                                                        ? 'noBackDrop'
                                                        : ''
                                                }`}
                                            >
                                                {/*eslint-disable*/}
                                                {(data.market_status === 'open' && data.auction) ||
                                                (data.market_status === 'open' &&
                                                    auction_and_buynow) ? (
                                                    <Timer
                                                        date_added={data.date_added}
                                                        date_closed={data.date_closed}
                                                        withText={1}
                                                        endText={'Time left' + ': '}
                                                        startText={'Starts in' + ': '}
                                                        theme={timerTheme}
                                                        icon={timerIcon}
                                                        audio={audio}
                                                    />
                                                ) : (
                                                    <>
                                                        {data.buynow === 0 ? (
                                                            <span className="closedText">
                                                                Auction Closed
                                                            </span>
                                                        ) : (
                                                            <div className="closedText"></div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {cardTheme == 'HeavyEquipment' ? (
                            <div className="gaInfo">
                                {fromSearch ? (
                                    <h6>Lot Id: {data.category_id}</h6>
                                ) : (
                                    <h6>Auction Id: {data.id}</h6>
                                )}
                                <p>
                                    <span>Start Date:</span>
                                    {moment(data.date_added)
                                        .tz('America/New_York')
                                        .format('MMM Do YYYY, h:mm a')}
                                </p>
                                <p>
                                    <span>End Date:</span>
                                    {moment(data.date_closed)
                                        .tz('America/New_York')
                                        .format('MMM Do YYYY, h:mm a')}
                                </p>
                                <p
                                    className="gaDesc"
                                    dangerouslySetInnerHTML={{
                                        __html: data.description,
                                    }}
                                ></p>
                            </div>
                        ) : cardTheme == 'carAuction' ? (
                            <div className="autoGridInfo mb-3">
                                {type !== 'buynow' && (
                                    <h6 className="crncyValue">
                                        <span className="material-icons-outlined">
                                            monetization_on
                                        </span>
                                        {data.auction &&
                                            data.wprice &&
                                            currencyFormat(
                                                parseFloat(data.wprice).toFixed(2),
                                                currencyType,
                                            )}
                                        <CurrencyConverterFormat amount={data.wprice} />
                                    </h6>
                                )}
                                {data.buynow == 1 && type == 'buynow' && (
                                    <h6 className="crncyValue">
                                        <span className="material-icons-outlined">
                                            monetization_on
                                        </span>
                                        {data.buynow &&
                                            data.bprice &&
                                            currencyFormat(
                                                parseFloat(data.bprice).toFixed(2),
                                                currencyType,
                                            )}
                                        <CurrencyConverterFormat amount={data.bprice} />
                                    </h6>
                                )}

                                {!noTimer && data.market_status === 'open' && data.auction ? (
                                    <div className="timrVlue">
                                        <span className="material-icons-outlined">watch_later</span>
                                        <Timer
                                            date_added={data.date_added}
                                            date_closed={data.date_closed}
                                            withText={1}
                                            endText={'Time left' + ': '}
                                            startText={'Starts in' + ': '}
                                            theme={timerTheme}
                                            audio={audio}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {data.buynow === 0 ? (
                                            <span className="closedText">Auction Closed</span>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <>
                                {cardTheme === 'liquidationTwo' ? (
                                    <>
                                        {data.retailer_link && data.mprice ? (
                                            <h6 className="galleryPrice rtlrPrice">
                                                <span>
                                                    Retail Price:{' '}
                                                    <a href={data.retailer_link} target="_blank">
                                                        (Retailer)
                                                    </a>
                                                </span>
                                                &nbsp;
                                                <span className="prcTxtVal">
                                                    {currencyFormat(
                                                        parseFloat(data.mprice || 0).toFixed(2),
                                                        currencyType,
                                                    )}
                                                    <CurrencyConverterFormat amount={data.mprice} />
                                                </span>
                                                {/* <span className="prcTxtVal">
                                                </span> */}
                                            </h6>
                                        ) : (
                                            <h6 className="galleryPrice rtlrPrice"></h6>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}

                                {type !== 'buynow' && (
                                    // parseInt(global.storeConfigration.length) > 0 ?
                                    // (!!Number(global.storeConfigration[2].value) ||
                                    //     !!Number(global.storeConfigration[3].value)) && (
                                    <h6 className="galleryPrice actnPriceVlue nowrap">
                                        {data.auction && data.wprice ? (
                                            <>
                                                {data.market_status == 'closed' ? (
                                                    <span>UnSold</span>
                                                ) : data.market_status == 'sold' ? (
                                                    <span>Sold</span>
                                                ) : (
                                                    <>
                                                        <span>
                                                            {data.market_status == 'sold'
                                                                ? 'Sold'
                                                                : data.nobids || data.bid_count
                                                                ? 'Current'
                                                                : 'Start'}{' '}
                                                            Price:{' '}
                                                        </span>
                                                        <span className="d-flex align-items-center">
                                                            <span className="prcTxtVal">
                                                                {currencyFormat(
                                                                    parseFloat(data.wprice).toFixed(
                                                                        2,
                                                                    ),
                                                                    currencyType,
                                                                )}
                                                                <CurrencyConverterFormat
                                                                    amount={data.wprice}
                                                                />
                                                            </span>
                                                            {/* <span className="prcTxtVal">
                                                            </span> */}
                                                            {cardTheme === 'liquidationTwo' && (
                                                                <>
                                                                    &nbsp; |{' '}
                                                                    {data.nobids ||
                                                                        data.bid_count ||
                                                                        0}{' '}
                                                                    Bids
                                                                </>
                                                            )}
                                                        </span>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {' '}
                                                {data.market_status == 'closed' ? (
                                                    <span>UnSold</span>
                                                ) : (
                                                    <span className="crncyNtAvlblVlue">
                                                        <span>
                                                            {data.market_status == 'sold'
                                                                ? 'Sold'
                                                                : parseInt(data.wprice) ===
                                                                  parseInt(data.sprice)
                                                                ? 'Start'
                                                                : 'Current'}
                                                            Price:
                                                        </span>
                                                        <span className="gridNotAvailable">
                                                            Not Available
                                                        </span>
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </h6>
                                )}
                                {global.storeConfigration?.show_condition?.value == 1 ? (
                                    <h6 className="galleryPrice condition">
                                        <span>Condition: </span>
                                        <span
                                            className="prcTxtVal"
                                            title={data.auctionnotes ? data.auctionnotes : '-'}
                                        >
                                            {data.auctionnotes ? data.auctionnotes : '-'}
                                        </span>
                                    </h6>
                                ) : null}
                                {!noBuyNow && type !== 'auction' && (
                                    <h6 className={`${!data.buynow ? 'noPrice' : 'galleryPrice'}`}>
                                        {data.buynow && data.bprice ? (
                                            <>
                                                <span>
                                                    {data.market_status === 'sold'
                                                        ? 'Sold'
                                                        : 'Buy Now'}{' '}
                                                    Price:{' '}
                                                </span>
                                                &nbsp;
                                                <span className="prcTxtVal">
                                                    {currencyFormat(
                                                        parseFloat(data.bprice).toFixed(2),
                                                        currencyType,
                                                    )}
                                                    <CurrencyConverterFormat amount={data.bprice} />
                                                </span>
                                                {/* <span className="prcTxtVal">
                                                </span> */}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </h6>
                                )}
                            </>
                        )}
                        {lotBidding ? (
                            lotBidding
                        ) : data.date_closed &&
                          moment(data.date_closed).isValid() &&
                          auctionTimer &&
                          moment(auctionTimer).isValid() &&
                          new Date(data.date_closed).getTime() - new Date(auctionTimer).getTime() <
                              0 &&
                          !data.buynow ? (
                            <p> </p>
                        ) : data?.user_id == user?.id && data.market_status == 'open' ? (
                            <p className="cntBidOwn" style={{ whiteSpace: 'nowrap' }}>
                                {data.buynow
                                    ? 'You cannot buy your own product!'
                                    : 'You cannot bid on your own product!'}
                            </p>
                        ) : (
                            <>
                                {cardTheme == 'auctioneer' ||
                                cardTheme == 'liquidationTwo' ||
                                cardTheme == 'carAuction' ? (
                                    data.auction &&
                                    !window.location.pathname.includes('gallery') &&
                                    type !== 'buynow' ? (
                                        <>
                                            <div
                                                className={`gpBidCart ${
                                                    cardTheme === 'carAuction' ? 'carAucBid' : ''
                                                }`}
                                            >
                                                <Bidding
                                                    theme="multiseller"
                                                    data={data}
                                                    hard={
                                                        global.storeConfigration?.hard_bid?.value ||
                                                        '1'
                                                    }
                                                    shortText={bidBtnLabel}
                                                    removePopup={removePopup}
                                                />
                                                {cardTheme !== 'carAuction' ? (
                                                    <>
                                                        {type == 'buynow' && type != 'Auction'
                                                            ? ''
                                                            : isAuthenticated &&
                                                              cardTheme != 'liquidationTwo' &&
                                                              ((parseInt(data.buynow) == 1 &&
                                                                  parseInt(data.auction) == 0) ||
                                                                  (parseInt(data.wprice) <=
                                                                      parseInt(data.bprice) &&
                                                                      parseInt(data.buynow) == 1 &&
                                                                      parseInt(data.auction) ==
                                                                          1)) &&
                                                              data.market_status == 'open' && (
                                                                  <Button
                                                                      className={`cartBtn  ${
                                                                          active ? 'active' : ''
                                                                      }`}
                                                                      onClick={() =>
                                                                          handleCart(data.id)
                                                                      }
                                                                      disabled={data.buynow == 0}
                                                                  >
                                                                      <span className="material-icons-outlined">
                                                                          {active
                                                                              ? 'shopping_cart_checkout'
                                                                              : 'add_shopping_cart'}
                                                                      </span>
                                                                  </Button>
                                                              )}
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            {cardTheme == 'liquidationTwo' ? (
                                                <>
                                                    {type == 'buynow' && type != 'Auction' ? (
                                                        <div className={`gpBidCart spByNw`}></div>
                                                    ) : ((parseInt(data.buynow) == 1 &&
                                                          parseInt(data.auction) == 0) ||
                                                          (parseInt(data.wprice) <=
                                                              parseInt(data.bprice) &&
                                                              parseInt(data.buynow) == 1 &&
                                                              parseInt(data.auction) == 1)) &&
                                                      data.market_status == 'open' ? (
                                                        <div className="gpBidCart spByNw">
                                                            {isAuthenticated ? (
                                                                <PrimaryButton
                                                                    btnSize={'small'}
                                                                    onClick={() =>
                                                                        handleCart(data.id)
                                                                    }
                                                                    disabled={data.buynow == 0}
                                                                    label={
                                                                        active === true
                                                                            ? 'Added to Cart'
                                                                            : `Buy it Now: ${currencyFormat(
                                                                                  data.bprice,
                                                                              )}`
                                                                    }
                                                                />
                                                            ) : (
                                                                <PrimaryButton
                                                                    btnSize={'small'}
                                                                    onClick={() =>
                                                                        handleRedirectInternal(
                                                                            history,
                                                                            'login',
                                                                        )
                                                                    }
                                                                    disabled={data.buynow == 0}
                                                                    label={`Login To Buy: ${currencyFormat(
                                                                        data.bprice,
                                                                    )}`}
                                                                />
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="gpBidCart spByNw hide"></div>
                                                    )}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    ) : data.market_status !== 'sold' ? (
                                        <>
                                            {cardTheme === 'liquidationTwo' &&
                                                type == 'buynow' &&
                                                type != 'Auction' && (
                                                    <div className="noBidAboveDiv"></div>
                                                )}
                                            {isAuthenticated ? (
                                                <PrimaryButton
                                                    btnSize={
                                                        cardTheme === 'liquidationTwo'
                                                            ? 'small'
                                                            : ''
                                                    }
                                                    onClick={() => handleCart(data.id)}
                                                >
                                                    {active === true
                                                        ? 'Added to Cart'
                                                        : `Buy it Now: ${currencyFormat(
                                                              data.bprice,
                                                          )}`}
                                                </PrimaryButton>
                                            ) : (
                                                <PrimaryButton
                                                    btnSize={
                                                        cardTheme === 'liquidationTwo'
                                                            ? 'small'
                                                            : ''
                                                    }
                                                    onClick={() =>
                                                        handleRedirectInternal(history, 'login')
                                                    }
                                                >
                                                    {`Login To Buy: ${currencyFormat(data.bprice)}`}
                                                </PrimaryButton>
                                            )}
                                            {cardTheme === 'liquidationTwo' &&
                                                type == 'buynow' &&
                                                type != 'Auction' && (
                                                    <div className="text-center endDteTxt noBidDownDiv"></div>
                                                )}

                                            {/* {!isAuthenticated && (
                                                <>
                                                    {cardTheme === 'liquidationTwo' &&
                                                        type == 'buynow' &&
                                                        type != 'Auction' && (
                                                            <div className="noBidDiv"></div>
                                                        )}
                                                </>
                                            )} */}
                                        </>
                                    ) : null
                                ) : cardTheme == 'multiseller' || cardTheme == 'fundRaiser' ? (
                                    data.auction &&
                                    !window.location.pathname.includes('gallery') ? (
                                        ''
                                    ) : data.market_status !== 'sold' ? (
                                        <PrimaryButton
                                            onClick={() =>
                                                isAuthenticated
                                                    ? productViewLink
                                                        ? history.push({
                                                              pathname: productViewLink,
                                                          })
                                                        : handleClick(data.id)
                                                    : handleRedirectInternal(history, 'login')
                                            }
                                        >
                                            {isAuthenticated ? 'Buy Now' : 'Login To Buy'}
                                        </PrimaryButton>
                                    ) : null
                                ) : cardTheme == 'HeavyEquipment' ? (
                                    <div className="gaCTA">
                                        <PrimaryButton
                                            onClick={() =>
                                                productViewLink
                                                    ? history.push({
                                                          pathname: productViewLink,
                                                      })
                                                    : handleClick(data.id)
                                            }
                                        >
                                            View Details
                                        </PrimaryButton>
                                        <AddToCalendar data={data} />
                                    </div>
                                ) : (
                                    <div className="timer-text mt-2">
                                        {!noBidButton && link && (
                                            <>
                                                {data.id !== undefined ? (
                                                    <div
                                                        className={`${
                                                            isAuthenticated && 'gridItemAct'
                                                        } ${
                                                            isAuthenticated &&
                                                            data.auction != 0 &&
                                                            (bidText == 'Winning' ||
                                                                bidText == 'Won') &&
                                                            'winBtnWrpr'
                                                        } ${
                                                            isAuthenticated &&
                                                            data.auction != 0 &&
                                                            (bidText == 'Outbid' ||
                                                                bidText == 'Lost') &&
                                                            'outbidBtnWrpr'
                                                        }`}
                                                    >
                                                        <PrimaryButton
                                                            disabled={data.market_status == 'sold'}
                                                            name={data.id}
                                                            onClick={() =>
                                                                isAuthenticated
                                                                    ? productViewLink
                                                                        ? history.push({
                                                                              pathname:
                                                                                  productViewLink,
                                                                          })
                                                                        : handleClick(data.id)
                                                                    : handleRedirectInternal(
                                                                          history,
                                                                          'login',
                                                                      )
                                                            }
                                                            label={
                                                                window.location.pathname.includes(
                                                                    'search',
                                                                ) ||
                                                                type === 'Auction' ||
                                                                data.auction
                                                                    ? type === 'buynow'
                                                                        ? isAuthenticated
                                                                            ? 'Buy Now'
                                                                            : 'Login To Buy Now'
                                                                        : isAuthenticated
                                                                        ? bidText
                                                                        : 'Login To Bid'
                                                                    : isAuthenticated
                                                                    ? 'Buy Now'
                                                                    : 'Login To Buy Now'
                                                            }
                                                            btnColor={
                                                                data.latestbid_user == user?.id &&
                                                                'success'
                                                            }
                                                        />
                                                        {cardTheme !== 'carAuction' ? (
                                                            <>
                                                                {type != 'buynow' &&
                                                                type == 'Auction'
                                                                    ? ''
                                                                    : isAuthenticated && (
                                                                          <Button
                                                                              className={`cartBtn ${
                                                                                  active
                                                                                      ? 'active'
                                                                                      : ''
                                                                              } `}
                                                                              onClick={() =>
                                                                                  handleCart(
                                                                                      data.id,
                                                                                  )
                                                                              }
                                                                              disabled={
                                                                                  data.buynow == 0
                                                                              }
                                                                              //   disabled={
                                                                              //       data.buynow == 0 ||
                                                                              //       data.market_status == 'sold' ||
                                                                              //       parseInt(data.bprice) <
                                                                              //           parseInt(data.wprice)
                                                                              //   }
                                                                          >
                                                                              <span className="material-icons-outlined">
                                                                                  {active
                                                                                      ? 'shopping_cart_checkout'
                                                                                      : 'add_shopping_cart'}
                                                                              </span>
                                                                          </Button>
                                                                      )}
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {cardTheme !== 'multiseller' &&
                                                            cardTheme !== 'liquidationTwo' &&
                                                            cardTheme !== 'carAuction' &&
                                                            data.market_status === 'open' && (
                                                                <FavouriteCheckbox
                                                                    watchlisted={
                                                                        data.watchlistid ||
                                                                        data.watchlisted ||
                                                                        data.isFav ||
                                                                        data.watch
                                                                    }
                                                                    project_id={data.id}
                                                                    getAllProducts={getAllProducts}
                                                                    data={data}
                                                                    from={'card'}
                                                                    viewProduct={viewProduct}
                                                                    setViewProduct={setViewProduct}
                                                                />
                                                            )}
                                                    </div>
                                                ) : (
                                                    <Link
                                                        className={`${'pink-btn pbtn-sm'} ${
                                                            isAuthenticated &&
                                                            data.auction != 0 &&
                                                            bidText == 'Winning' &&
                                                            'winBtnWrpr'
                                                        } ${
                                                            isAuthenticated &&
                                                            data.auction != 0 &&
                                                            bidText == 'Losing' &&
                                                            'outbidBtnWrpr'
                                                        }`}
                                                        to={link}
                                                        params={{ product_id: data.id }}
                                                    >
                                                        {window.location.pathname.includes(
                                                            'search',
                                                        ) ||
                                                        type === 'Auction' ||
                                                        data.auction
                                                            ? type === 'buynow'
                                                                ? isAuthenticated
                                                                    ? 'Buy Now'
                                                                    : 'Login To Buy Now'
                                                                : isAuthenticated
                                                                ? bidText
                                                                : 'Login To Bid'
                                                            : isAuthenticated
                                                            ? 'Buy Now'
                                                            : 'Login To Buy Now'}{' '}
                                                        <i className="fa fa-long-arrow-alt-right"></i>
                                                    </Link>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </>
                        )}

                        <Biddingforwardandreverse
                            theme="multiseller"
                            data={data}
                            hard="1"
                            shortText={bidBtnLabel}
                            is_registered={1}
                        />

                        {cardTheme === 'liquidationTwo' && (
                            <>
                                {/* <Tooltip
                                    title={
                                        <>
                                            {!noTimer && data.date_closed && (
                                                <div
                                                    className={`gcTimer ${
                                                        data.buynow === 1 ? 'noBackDrop' : ''
                                                    }`}
                                                >
                                                    {(data.market_status === 'open' &&
                                                        data.auction) ||
                                                    (data.market_status === 'open' &&
                                                        auction_and_buynow) ? (
                                                        <Timer
                                                            date_added={data.date_added}
                                                            date_closed={data.date_closed}
                                                            withText={0}
                                                            endText={'Time left' + ': '}
                                                            startText={'Starts in' + ': '}
                                                            theme={timerTheme}
                                                            icon={timerIcon}
                                                        />
                                                    ) : (
                                                        <>
                                                            {data.buynow === 0 ? (
                                                                <span className="closedText">
                                                                    Auction Closed
                                                                </span>
                                                            ) : (
                                                                <div className="closedText"></div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    }
                                > */}
                                {data.auction == 1 ? (
                                    <div className="text-center endDteTxt">
                                        {data.market_status === 'open' ? (
                                            <>
                                                {cardTheme == 'liquidationTwo' ? (
                                                    <p className="mb-0">
                                                        Ends{' '}
                                                        {`${moment(data.date_closed)
                                                            .tz(
                                                                global?.storeConfigration
                                                                    ?.auction_view_time_zone?.value
                                                                    ? global?.storeConfigration
                                                                          ?.auction_view_time_zone
                                                                          ?.value
                                                                    : moment.tz.guess(),
                                                            )
                                                            .format('MMM Do')}, ${moment(
                                                            data.date_closed,
                                                        )
                                                            .tz(
                                                                global?.storeConfigration
                                                                    ?.auction_view_time_zone?.value
                                                                    ? global?.storeConfigration
                                                                          ?.auction_view_time_zone
                                                                          ?.value
                                                                    : moment.tz.guess(),
                                                            )
                                                            .format('h:mm a')}`}
                                                    </p>
                                                ) : (
                                                    <p className="mb-0">
                                                        Ends {dateFormatFront(data.date_closed)}
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <p className="mb-0">Auction Closed</p>
                                        )}
                                    </div>
                                ) : null}
                                {/* </Tooltip> */}
                            </>
                        )}
                    </>
                )}
            </div>
            {isArtTheme && (
                <div className="artCardHover">
                    <h4 className="ProTit" onClick={() => handleClick(data.id)}>
                        {data.ptitle}
                    </h4>
                    <div className="priceCrd">
                        {Boolean(data.auction) ? (
                            <>
                                <div className="cPrice">
                                    <h4>Current Bid</h4>
                                    <span>{currencyFormat(data.wprice)}</span>
                                </div>
                                <span className="crncyValue makemycurrency">
                                    <CurrencyConverterFormat amount={data.wprice} />
                                </span>
                            </>
                        ) : (
                            ''
                        )}
                        {Boolean(data.buynow) ? (
                            <div className="bPrice">
                                <h4>Buy Now Price</h4>
                                <span>{currencyFormat(data.bprice)}</span>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default GridItem
