import React, { useState } from 'react'
import Layout from '../Layout'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(
    (theme) => (
        console.log(theme, 'theme'),
        {
            root: {
                width: '100%',
            },
            accordionLayout: {
                border: 'none',
                borderRadius: theme.shape.borderRadius,
                boxShadow: '0px 0px 6px 0px #0000001f',
                marginBottom: '15px',
                '&:before': {
                    height: '0px',
                },
            },
            heading: {
                fontSize: theme.typography.pxToRem(24),
                flexBasis: '100%',
                flexShrink: 0,
                color: '#000',
                [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.pxToRem(16),
                },
            },
            secondaryHeading: {
                fontSize: theme.typography.pxToRem(16),
                color: '#000',
                [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.pxToRem(14),
                },
            },
        }
    ),
)

export default function faq(props) {
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    return (
        <Layout props={props}>
            <div className="px-5 py-5 staticPsgs">
                <h2 className="text-center mb-5">FAQs</h2>
                {/* <span
                    dangerouslySetInnerHTML={{
                        __html: global.storeDetails.terms,
                    }}
                ></span> */}
                <div className={classes.root}>
                    <Accordion
                        expanded={expanded === 'panel1'}
                        onChange={handleChange('panel1')}
                        className={classes.accordionLayout}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={classes.heading}>
                                01. How does One Minute Auction&apos;s auction work?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className={classes.secondaryHeading}>
                                One Minute Auction&apos;s auctions are descending auctions, meaning
                                that the starting price is high and it automatically decreases with
                                each bid until a buyer wins the auction. Auctions typically last for
                                one minute, but can be extended if a bid is made in the last few
                                seconds of the auction.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel2'}
                        onChange={handleChange('panel2')}
                        className={classes.accordionLayout}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography className={classes.heading}>
                                02. Who can participate in One Minute Auction&apos;s auctions?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.secondaryHeading}>
                            <Typography>
                                Donec placerat, lectus sed mattis semper, neque lectus feugiat
                                lectus, varius pulvinar diam eros in elit. Pellentesque convallis
                                laoreet laoreet.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel3'}
                        onChange={handleChange('panel3')}
                        className={classes.accordionLayout}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography className={classes.heading}>
                                03. What types of products are sold on One Minute Auction?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
                                sit amet egestas eros, vitae egestas augue. Duis vel est augue.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel4'}
                        onChange={handleChange('panel4')}
                        className={classes.accordionLayout}
                        s
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography className={classes.heading}>
                                04. How can I list a product for auction on One Minute Auction?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
                                sit amet egestas eros, vitae egestas augue. Duis vel est augue.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel5'}
                        onChange={handleChange('panel5')}
                        className={classes.accordionLayout}
                        s
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography className={classes.heading}>
                                05. What happens if nobody wins my auction?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
                                sit amet egestas eros, vitae egestas augue. Duis vel est augue.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel6'}
                        onChange={handleChange('panel6')}
                        className={classes.accordionLayout}
                        s
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography className={classes.heading}>
                                06. What happens if I win an auction?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
                                sit amet egestas eros, vitae egestas augue. Duis vel est augue.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel7'}
                        onChange={handleChange('panel7')}
                        className={classes.accordionLayout}
                        s
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography className={classes.heading}>
                                07. What are the fees associated with using One Minute Auction?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
                                sit amet egestas eros, vitae egestas augue. Duis vel est augue.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </Layout>
    )
}
