import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import GridProduct from './GridProduct'
import { search } from '../../redux/action/SearchGallery'
import { connect } from 'react-redux'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import AuthContext from '../../Product/context/auth/authContext'
import AlertContext from '../../Product/context/alert/alertContext'
import { messageHandler } from '../../Product/common/socketHandler'
import { socket } from '../../Product/common/socket'
import { Button } from '@material-ui/core'
import CustomSelect from '../../Product/components/atoms/Inputs/CustomSelect'
import PaginationComponent from '../../Component/Pagination'
import ProductContext from '../../Product/context/product/productContext'
import MultisellerListCard from '../../Component/ProductCard/MultisellerListCard'
const SimilarListComponent = (props) => {
    const [searchresult, setSearchresult] = useState([])
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { user } = authContext
    const [auctionView, setAuctionView] = useState('Grid')
    const productContext = useContext(ProductContext)
    const { getLotList } = productContext
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        sortBy: '2',
        lotof: props.selected_lotof,
        limit: 12,
        market_status: 'open',
        auction_io: 1,
        searchquery: props.selected_cat,
    })

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]
    const sortBy = [
        { value: '7', show: 'Ending Soon' },
        { value: '2', show: 'Lot Id' },
        { value: '5', show: 'Highest Bids' },
        { value: '6', show: 'Lowest Bids' },
    ]

    useEffect(() => {
        if (props.searchResult.length > 0) {
            setSearchresult(props.searchResult)
        } else {
            setSearchresult([])
        }
    }, [props.searchResult])

    const viewProductRef = useRef(searchresult)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = searchresult
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSearchresult,
            type,
        )
    }
    const multiauction_handler = (message, type) => {
        var index = viewProductRef.current.findIndex(
            (s) => s.id === parseInt(message.id || message.project_id, 10),
        )
        if (index !== -1) {
            if (parseInt(viewProductRef.current[index].content_head5) == 0) {
                messageHandler(
                    message,
                    viewProductRef.current,
                    userRef.current,
                    setAlert,
                    setSearchresult,
                    type,
                )
            }
        }
    }

    useEffect(() => {
        socket.on('bidAddtime', (data) => {
            if (global?.storeDetails?.theme == 9) {
                multiauction_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })
        socket.on('realclosedupdates', (data) => {
            data.id = data.pid
            //data.bpop_cbidder = data.usr
            handler(data, 'realclosedupdates')
        })

        return () => {
            socket.off('bidAddtime', (data) => {
                if (global?.storeDetails?.theme == 9) {
                    multiauction_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
        }
    }, [])

    const page_nav = (type, val) => {
        var sub_lot_payload = subLotSearch
        sub_lot_payload.lotof = props.selected_lotof
        sub_lot_payload.searchquery = props.selected_cat
        if (type == 'pagination') {
            sub_lot_payload.page = val
            getLotList(sub_lot_payload, 'productviewAuction')
        } else if (type == 'sortby') {
            sub_lot_payload.sortBy = val
            sub_lot_payload.page = 1
            getLotList(sub_lot_payload, 'productviewAuction')
        } else {
            sub_lot_payload.limit = val
            sub_lot_payload.page = 1
            getLotList(sub_lot_payload, 'productviewAuction')
        }
    }

    return (
        <SkeletonTheme color="#d6d6d6" highlightColor="#e8e8e8">
            <div className="search-wrapper">
                {props.loading ? (
                    <Skeleton width={80} height={30} />
                ) : props.searchResult.length > 0 ? (
                    <>
                        <h2 className="text-center font-weight-bold mt-3">You May Also Like</h2>
                        <h4 className="si-head">{props.tittle || 'Similar Items'}</h4>
                    </>
                ) : (
                    ''
                )}
                <div className="auctionsMiscFilter mb-4">
                    <div className="gridListToggle">
                        <Button
                            className={auctionView === 'Grid' ? 'active' : ''}
                            onClick={() => {
                                setAuctionView('Grid')
                            }}
                        >
                            <span className="material-icons">apps</span>Grid
                        </Button>
                        <Button
                            className={auctionView === 'List' ? 'active' : ''}
                            onClick={() => {
                                setAuctionView('List')
                            }}
                        >
                            <span className="material-icons">view_list</span>List
                        </Button>
                    </div>
                    <CustomSelect
                        label="Results per page"
                        name="resultsPerPage"
                        selectType="noBorder"
                        value={subLotSearch.limit}
                        size="small"
                        onChange={(event, editor) => {
                            page_nav('resultperpage', event.target.value)
                            setSubLotSearch({
                                ...subLotSearch,
                                limit: event.target.value,
                                page: 1,
                            })
                        }}
                    >
                        {pageOptions.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        label="Sort By"
                        name="sortBy"
                        selectType="noBorder"
                        value={subLotSearch.sortBy}
                        size="small"
                        onChange={(event, editor) => {
                            page_nav('sortby', event.target.value)
                            setSubLotSearch({
                                ...subLotSearch,
                                sortBy: event.target.value,
                            })
                        }}
                    >
                        {sortBy.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                </div>
                {auctionView == 'Grid' ? (
                    <div className="row">
                        {searchresult.map((data, index) => {
                            return (
                                <div className="col-6 col-md-4 col-lg-3" key={`gl-${index}`}>
                                    <GridProduct
                                        data={data}
                                        key={index}
                                        bidStatus={true}
                                        lotImages={data.lotImages}
                                        selectedProduct={props.selectedPostion}
                                        link={`/gallery/product-view/${data.id}`}
                                        handleClick={props.handleClick}
                                        timerTheme=""
                                        sliderTheme="multiseller"
                                        cardTheme="multiseller"
                                        bidBtnLabel={
                                            <>
                                                <span className="material-icons-outlined">
                                                    gavel
                                                </span>
                                                Place Bid
                                            </>
                                        }
                                        type="auction"
                                        currencyType=""
                                        addTheme="multiAuction"
                                        showEstimate={true}
                                        lotDetails={props.lotData}
                                        setViewProduct={props.setViewProduct}
                                        viewProduct={props.viewProduct}
                                        productViewLink={'/productView/' + data.id}
                                    />
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <>
                        {searchresult.map((data, index) => {
                            return (
                                <MultisellerListCard
                                    lotData={data}
                                    key={index}
                                    selectedProduct={props.selectedPostion}
                                    type="lot"
                                    allsearchitems={props.lotData}
                                    handleClick={props.handleClick}
                                    details={`/gallery/product-view/${data.id}`}
                                    sliderTheme="multiseller"
                                    bidBtnLabel={
                                        <>
                                            <span className="material-icons-outlined">gavel</span>
                                            Bid
                                        </>
                                    }
                                    addTheme="multiAuction"
                                    lotDetails={props.lotData}
                                    setViewProduct={props.setViewProduct}
                                    viewProduct={props.viewProduct}
                                    is_registered={props.is_registered}
                                    setAlert={props.setAlert}
                                    private_auction={props.private_auction}
                                />
                            )
                        })}
                    </>
                )}
            </div>
            <PaginationComponent
                onPageChange={(pageNumber) => {
                    page_nav('pagination', pageNumber)
                    setSubLotSearch({ ...subLotSearch, page: pageNumber })
                }}
                totalProductCount={props.totalProductCount}
                currentPage={subLotSearch.page}
                resultToShow={subLotSearch.limit}
            />
        </SkeletonTheme>
    )
}

export default SimilarListComponent
