import React, { useState, useEffect, useContext } from 'react'
import Popup from '../../Product/components/organisms/Popup'
import {
    handleRedirectInternal,
    mapData,
    capitalize,
    currencyFormat,
} from '../../Product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import './DonationModal.css'
import axios from 'axios'
import { IconButton } from '@material-ui/core'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import CustomSelect from '../../Product/components/atoms/Inputs/CustomSelect'
import AuthContext from '../../Product/context/auth/authContext'
import pluginContext from '../../Product/context/plugin/pluginContext'
import AlertContext from '../../Product/context/alert/alertContext'
function DonationFunction(props) {
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext
    const {
        donar_registration,
        getdonarexistingcard,
        responseStatus: donar_response,
        clearResponse: donar_clearresponse,
    } = useContext(pluginContext)
    const [addressInitialValues, setAddressInitialValues] = useState({
        user_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        address: '',
        id: '',
        payment: 'Card',
        web_address: '',
        notes: '',
        amount: '',
        business_name: '',
        auctionid: '',
        extracardnumber: '',
        extradate: '',
        extrayear: '',
        extracvv: '',
        sourceID: '',
    })
    const [stateList, setStateList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [selected_card, setSelectedCard] = useState('')
    const [carddetails, setCardDetails] = useState([])
    const [donar_loading, setDonarLoading] = useState(false)
    const { setAlert } = useContext(AlertContext)
    const [currentDon, setCurrentDon] = useState('donAmount')

    const validationArray = Yup.object({
        first_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z]*$/g, 'Special characters and numbers are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
        last_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z]*$/g, 'Special characters and numbers are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .min(12, 'Minimum 10 digits phone number')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email format')
            .max(250, '250 characters max')
            .required('Required'),
        country: Yup.string().trim().required('Required!'),
        city: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z ]*$/g, 'No special charaters allowed.')
            .required('Required!'),
        state: Yup.string().trim().required('Required!'),
        zip: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'Only positive numbers allowed')
            .min(5, 'Minimum 5 Numbers')
            .max(6, 'Maximum 6 Numbers')
            .required('Required!'),
        address: Yup.string().trim().required('Required!'),
        //web_address: Yup.string().trim().required('Required!'),
        business_name: Yup.string().trim().required('Required!').max(25, 'Maximum 25 characters'),
        amount: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'Only positive numbers allowed')
            .required('Required!'),
        //payment: Yup.string().trim().required("Required!"),
        extradate:
            currentDon != 'donInfo' && !selected_card ? Yup.string().required('Required!') : '',
        extrayear:
            currentDon != 'donInfo' && !selected_card ? Yup.string().required('Required!') : '',
        extracvv:
            currentDon != 'donInfo' && !selected_card
                ? Yup.string()
                      .required('Required!')
                      .min(3, 'Mininum 3 Numbers required')
                      .max(4, 'Maximum 3 Numbers required')
                : '',
        cardhname:
            currentDon != 'donInfo' && !selected_card
                ? Yup.string()
                      .trim()
                      .matches(
                          /^[a-z A-Z]*$/g,
                          'The special characters and numbers are not allowed!',
                      )
                      .required('Required!')
                : '',
        extracardnumber:
            currentDon != 'donInfo' && !selected_card
                ? Yup.string()
                      .min(12, 'Invalid credit card number!')
                      .max(18, 'Invalid credit card number!')
                      .required('Required!')
                : '',
    })

    useEffect(() => {
        if (donar_response) {
            if (donar_response.from == 'donar_register') {
                //console.log("llllllllllllllllllllllll", donar_response);
                if (donar_response.status == 'success') {
                    formik.resetForm()
                    props.handleClose()
                    setSelectedCard('')
                    setAlert(donar_response.message, 'success')
                } else {
                    setAlert(donar_response.message, 'error')
                }
                setDonarLoading(false)
            }
            if (donar_response.from == 'donorcarddetails_bidderdetails_page') {
                // console.log("ppppppppppppppppppppppppp",responseStatus.message.cardlist)
                setCardDetails(
                    donar_response.message?.cardlist ? donar_response.message.cardlist : [],
                )
            }
            // if (donar_response.from == 'donorlist_bidderdetails_page') {
            //     //console.log("llllllllllllllllllllllllllllllll", donar_response);
            //     if (donar_response.message) {
            //         donar_response.message.totpercentage = parseInt(
            //             parseFloat(
            //                 parseInt(donar_response.message.donationamt) /
            //                     parseInt(auctiondetails.expected_amount),
            //             ) * 100,
            //         )
            //         var given = moment(auctiondetails.date_closed, 'YYYY-MM-DD')
            //         var current = moment().startOf('day')
            //         donar_response.message.total_days = moment
            //             .duration(given.diff(current))
            //             .asDays()
            //     }
            //     setDonarList(donar_response.message)
            // }
            donar_clearresponse()
        }
    }, [donar_response])
    // console.log('llllllllllllllllllllllllllllllllllllllllll', props.handleClose())
    const formik = useFormik({
        initialValues: addressInitialValues,
        validationSchema: validationArray,
        onSubmit: async (values) => {
            if (currentDon == 'donInfo') {
                setCurrentDon('donPay')
                return false
            }
            setDonarLoading(true)
            let countryName = countryList.filter(
                (val) => parseInt(val.location_id) == parseInt(values.country),
            )[0].name
            var original_country = formik.values.country
            // let { name: stateName } = csc.getStateById(values['state'])
            var data_send = formik.values
            data_send.country = countryName
            formik.values.country = original_country
            // values.state = stateName
            data_send.cardcity = data_send.city
            data_send.cardstate = data_send.state
            data_send.zipcode = data_send.zip
            if (selected_card) {
                data_send.sourceID = selected_card
            }
            donar_registration(data_send)
            // const response = await addShippingAddress({
            //   ...values,
            //   user_id: user.id,
            //   country: countryName,
            //   state: stateName,
            // });
            // if (response) {
            //   setAlert(response, "success");
            //   getCheckout({ user_id: user.id });
            //   setIsPopupOpen(false);
            //   formik.resetForm();
            // }
        },
        enableReinitialize: true,
    })

    const getLocationData = () => {
        var body = {
            countryCode: formik.values.country,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.country])

    const manualEntry = {
        formik: formik,
        data: [
            {
                label: 'Enter Amount',
                name: 'enterAmount',
                type: 'text',
                placeholder: 'Enter amount you wish to donate',
                class: formik.values.donAmount === 'other' ? 'col-12' : 'd-none',
            },
        ],
    }
    const creditCardYearOptions = []
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }

    const creditCardMonthOptions = []
    for (let month = parseInt(1); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: monthValue,
            show: monthValue,
        })
    }

    useEffect(() => {
        if (user) {
            if (user.email) {
                // console.log('llllllllllllllllllllllllllllllllllllllll', props.id)
                // let countryId = "";
                // if (lookup.byCountry(user.country) != null) {
                //   let { id } = csc.getCountryByCode(
                //     lookup.byCountry(user.country).iso2
                //   );
                //   countryId = id;
                // }
                // let stateId = "";
                // if (countryId) {
                //   let statesOfCountry = csc.getStatesOfCountry(countryId);
                //   let index = statesOfCountry.findIndex(
                //     (element) => element.name === user.state
                //   );
                //   if (index) {
                //     stateId = statesOfCountry[index].id;
                //   }
                // }
                //let auctionId = new URLSearchParams(search).get("auctionId");
                getdonarexistingcard({ user_id: user?.id ? user.id : '' })
                setAddressInitialValues({
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    phone: user.phone,
                    city: user.city,
                    state: user.state,
                    country: user.country,
                    zip: user.zip,
                    address: user.address1,
                    eventid: 0,
                    payment: '',
                    web_address: '',
                    notes: '',
                    amount: '',
                    business_name: '',
                    auctionid: props?.id ? props.id : '',
                    user_id: user.id,
                    extracardnumber: '',
                    extradate: '',
                    extrayear: '',
                    extracvv: '',
                    sourceID: '',
                    cardhname: '',
                })
            }
        }
    }, [props.open, user])

    const paymentdetails = [
        {
            label: 'Card Holder Name',
            type: 'text',
            placeholder: 'Enter card holder name',
            class: 'col-6',
            name: 'cardhname',
            formik: formik,
        },
        {
            label: 'Card No',
            type: 'number',
            placeholder: 'Enter your card no',
            class: 'col-6',
            name: 'extracardnumber',
            formik: formik,
        },
        {
            label: 'Expiry Month',
            placeholder: 'MM',
            class: 'col-sm-6 col-12',
            type: 'select',
            name: 'extradate',
            options: creditCardMonthOptions,
            formik: formik,
        },
        {
            label: 'Expiry Year',
            placeholder: 'YYYY',
            class: 'col-sm-6 col-12',
            type: 'select',
            name: 'extrayear',
            options: creditCardYearOptions,
            formik: formik,
        },
        {
            label: 'Cvv',
            type: 'number',
            placeholder: 'Enter your Cvv',
            class: 'col-sm-6 col-12',
            name: 'extracvv',
            formik: formik,
        },
    ]

    // const donationInfo = {
    //     formik: formik,
    //     data: [
    //         {
    //             label: 'First Name',
    //             name: 'firstName',
    //             type: 'text',
    //             placeholder: 'Enter your first name',
    //             class: 'col-12',
    //         },
    //         {
    //             label: 'Last Name',
    //             name: 'lastName',
    //             type: 'text',
    //             placeholder: 'Enter your last name',
    //             class: 'col-12',
    //         },
    //         {
    //             label: 'Email',
    //             name: 'email',
    //             type: 'email',
    //             placeholder: 'Enter your email address',
    //             class: 'col-12',
    //         },
    //     ],
    // }
    const swapCountry = (countries = []) => {
        const move = (from, to, ...arr) =>
            from === to ? arr : (arr.splice(to, 0, ...arr.splice(from, 1)), arr)
        var US_INDEX = countries.findIndex((val) => val.name === 'United States')
        return move(US_INDEX, 0, ...countries)
    }

    const amountDetails = [
        {
            title: 'Price',
            type: 'radio',
            name: 'amount',
            int: 1,
            class: 'col-md-12 donAmountRadio',
            customLabel: true,
            item: [
                { id: 25, description: '$25' },
                { id: 50, description: '$50' },
                { id: 100, description: '$100' },
                { id: 250, description: '$250' },
                { id: 500, description: '$500' },
                { id: 1000, description: '$1000' },
            ],
            formik: formik,
        },
        {
            label: 'Custom Amount',
            type: 'number',
            placeholder: 'USD',
            class: 'col-sm-6 col-12',
            name: 'amount',
            formik: formik,
        },
    ]

    const donationInfo = [
        {
            label: 'First Name',
            type: 'text',
            placeholder: 'Enter First Name',
            class: 'col-sm-6 col-12',
            name: 'first_name',
            formik: formik,
        },
        {
            label: 'Last Name',
            type: 'text',
            placeholder: 'Enter Last Name',
            class: 'col-sm-6 col-12',
            name: 'last_name',
            formik: formik,
        },
        {
            label: 'Email',
            type: 'email',
            placeholder: 'Enter Email',
            class: 'col-sm-6 col-12',
            name: 'email',
            formik: formik,
        },
        {
            label: 'Phone Number',
            type: 'phone',
            placeholder: 'Enter Phone Number',
            class: 'col-sm-6 col-12',
            name: 'phone',
            formik: formik,
        },
        {
            label: 'Country',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: swapCountry(countryList).map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.location_id
                return busCategoryChanged
            }),
            name: 'country',
            formik: formik,
        },
        {
            label: 'Address',
            placeholder: 'Enter your Address',
            class: 'col-12 col-sm-6',
            type: 'text',
            name: 'address',
            formik: formik,
        },
        {
            label: 'State',
            placeholder: 'Select your state',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: stateList.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            }),
            name: 'state',
            formik: formik,
        },
        {
            label: 'City',
            type: 'text',
            placeholder: 'Enter City',
            class: 'col-sm-6 col-12',
            name: 'city',
            formik: formik,
        },
        {
            label: 'Zip',
            type: 'number',
            placeholder: 'Enter Zip',
            class: 'col-sm-6 col-12',
            name: 'zip',
            formik: formik,
        },
        {
            label: 'Business Name',
            type: 'text',
            placeholder: 'Enter Business Name',
            class: 'col-sm-6 col-12',
            name: 'business_name',
            formik: formik,
        },
        {
            label: 'Web Address',
            type: 'text',
            placeholder: 'Enter Web Address',
            class: 'col-sm-6 col-12',
            name: 'web_address',
            formik: formik,
        },
        // {
        //   label: "Payment Method",
        //   placeholder: "Select your payment method",
        //   class: "col-12 col-sm-6",
        //   type: "select",
        //   options: [
        //     {
        //       show: "Visa",
        //       value: "Visa",
        //     },
        //     {
        //       show: "Master Card",
        //       value: "MasterCard",
        //     },
        //     {
        //       show: "Paypal",
        //       value: "Paypal",
        //     },
        //     {
        //       show: "Cash",
        //       value: "Cash",
        //     },
        //     {
        //       show: "Check",
        //       value: "Check",
        //     },
        //   ],
        //   name: "payment",
        //   formik: formik,
        // },
        {
            label: 'Notes',
            type: 'text',
            placeholder: 'Enter Notes',
            class: 'col-sm-6 col-12',
            name: 'notes',
            formik: formik,
        },
    ]

    const amountValues = [
        {
            label: '$1,000',
            id: '1000',
        },
        {
            label: '$500',
            id: '500',
        },
        {
            label: '$100',
            id: '100',
        },
        {
            label: 'Other',
            id: 'other',
        },
    ]

    useEffect(() => {
        // console.log('formik', formik)
        if (formik.isSubmitting) {
            if (formik.errors.business_name || formik.errors.web_address) {
                setCurrentDon('donInfo')
            }
        }
    }, [formik.isSubmitting])

    return (
        <div className="row align-items-stretch mx-auto donationPageWrapper">
            <div className="lftDnrCntnr">
                <form onSubmit={formik.handleSubmit} className="donationFormWrapper">
                    <h5 className="mb-4 pyhdrTtle">1. Select A Donation Amount</h5>
                    {currentDon === 'donAmount' && (
                        <div className="row">
                            {Object.values(mapData(amountDetails))}
                            <div className="col-md-3">
                                <PrimaryButton
                                    disabled={formik.values.amount != '' ? false : true}
                                    label="Continue"
                                    onClick={() => setCurrentDon('donInfo')}
                                />
                            </div>
                        </div>
                    )}
                    {currentDon != 'donAmount' && formik.values.amount != '' && (
                        <>
                            <div className="fnlDntnInfo">
                                <div className="d-flex align-items-center justify-content-between">
                                    {/* {capitalize(auctionDetails.title)} */}
                                    <p className="donAmountVle">
                                        {currencyFormat(formik.values.amount)}
                                    </p>
                                </div>
                                <div className="ttlAmountWrpr d-flex align-items-center justify-content-between mt-4">
                                    <SecondaryButton
                                        onClick={() => setCurrentDon('donAmount')}
                                        label="Edit Donation"
                                        btnSize="small"
                                    />
                                    <div className="d-flex align-items-center">
                                        <label>Total:</label>
                                        <p className="donAmountVle">
                                            {currencyFormat(formik.values.amount)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <h5 className="mb-4 pyhdrTtle">2. Your Information</h5>
                    {currentDon === 'donInfo' && (
                        <>
                            <div className="row">
                                {Object.values(mapData(donationInfo))}
                                <div className="col-md-9"></div>
                                <div className="col-md-3 mb-4">
                                    <PrimaryButton
                                        label="Continue"
                                        onClick={() => formik.handleSubmit()}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {currentDon != 'donInfo' &&
                        currentDon != 'donAmount' &&
                        formik.values.first_name != '' &&
                        formik.values.last_name != '' &&
                        formik.values.email != '' &&
                        formik.values.phone != '' &&
                        formik.values.amount != '' && (
                            <>
                                <div className="fnlDntnInfo">
                                    <h4 className="usrNameVale">
                                        {formik.values.first_name + ' ' + formik.values.last_name}
                                    </h4>
                                    <div className="usrCntctInfo d-flex align-items-center justify-content-start mt-4">
                                        <p className="mr-4">
                                            <span className="material-icons">mail</span>
                                            {formik.values.email}
                                        </p>

                                        <p>
                                            <span className="material-icons">phone</span>
                                            {formik.values.phone}
                                        </p>
                                    </div>
                                </div>
                                <div className="ttlAmountWrpr d-flex align-items-center justify-content-between mt-4">
                                    <SecondaryButton
                                        onClick={() => setCurrentDon('donInfo')}
                                        label="Edit Information"
                                        btnSize="small"
                                    />
                                </div>
                            </>
                        )}
                    <h5 className="mb-4 pyhdrTtle">3. Payment</h5>
                    {currentDon === 'donPay' && (
                        <>
                            {carddetails.length > 0 ? (
                                <div className="row">
                                    <div className="col-4 pt-3">Use Existing Card details</div>
                                    <div className="col-8">
                                        <CustomSelect
                                            label={'Card Details'}
                                            value={selected_card}
                                            onChange={(e) => setSelectedCard(e.target.value)}
                                        >
                                            <option value="">{'Select Card'}</option>
                                            {carddetails.map((val) => (
                                                <option value={val.id}>
                                                    {'XXXX XXXX XXXX XXXX ' + val.last4}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                    {selected_card == '' ? (
                                        <p className="text-center w-100">OR</p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                            {selected_card == '' ? (
                                <div className="row">{Object.values(mapData(paymentdetails))}</div>
                            ) : (
                                ''
                            )}
                            <PrimaryButton
                                disabled={donar_loading ? true : false}
                                label="Donate"
                                type="submit"
                            />
                        </>
                    )}
                </form>
            </div>
            {/* <div className="rtBack">
                <div className="txtContainer">
                    Your one donation is changing the world for the better.
                </div>
            </div> */}
        </div>
    )
}

export default DonationFunction
