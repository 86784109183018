import React, { useContext, useEffect, useState } from 'react'
import Layout from '../Layout'
import UserContext from '../../../Product/context/user/userContext'
import './Static.css'

const Static = (props) => {
    const userContext = useContext(UserContext)
    const { static_page, getStaticPage } = userContext
    const [loading, setLoading] = useState(true)
    const getFunction = (page_id) => {
        if (page_id !== 0) {
            setLoading(true)
            getStaticPage({ page_id: page_id })
        }
    }

    useEffect(() => {
        setLoading(false)
    }, [static_page])

    useEffect(() => {
        let page_id = 0
        if (props?.match?.params?.action) {
            if (props?.match?.params?.action === '/press') {
                page_id = 'press'
            } else if (props?.match?.params?.action === '/trust') {
                page_id = 'trust'
            } else if (props?.match?.params?.action === '/terms') {
                page_id = 'terms'
            } else if (props?.match?.params?.action === '/privacy') {
                page_id = 'privacy'
            } else if (props?.match?.params?.action === '/cookie-policy') {
                page_id = 'cookie-policy'
            } else if (props?.match?.params?.action === '/about') {
                page_id = 'about'
            } else if (props?.match?.params?.action === '/blog') {
                page_id = 'blog'
            } else if (props?.match?.params?.action === '/laad') {
                page_id = 'laad'
            } else if (props?.match?.params?.action === '/forgotpassword') {
                page_id = 'forgotpassword'
            } else if (props?.match?.params?.action === '/services') {
                page_id = 'services'
            } else if (props?.match?.params?.action === '/our_process') {
                page_id = 'our_process'
            } else if (props?.match?.params?.action === '/considerations') {
                page_id = 'considerations'
            } else if (props?.match?.params?.action === '/news') {
                page_id = 'News'
            } else {
                page_id = props?.match?.params?.action
            }

            getFunction(page_id)
        }
    }, [props?.match?.params?.action])

    return (
        <Layout>
            <div
                className="stcPgWrpr"
                dangerouslySetInnerHTML={{
                    __html: static_page.content,
                }}
            ></div>
        </Layout>
    )
}

export default Static
