import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer, SwipeableDrawer } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SlidePanel from '../../../Component/SlidePanel'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import { messageHandler } from '../../../Product/common/socketHandler'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import { socket, socketAuction } from '../../../Product/common/socket'
import Slider from 'react-slick'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { handleRedirectInternal } from '../../../Product/common/components'
import { Link, useHistory } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HeaderSearchComponent from '../Header/HeaderSearch'
import { sustainabilityOptions, brandsliqutatios, allCondition } from '../../../utils'
import SEO from '../../../utils/CommonFunctionality/SEO'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const history = useHistory()

    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { search_allproducts, getAllSearchProducts } = productContext
    const [auctionView, setAuctionView] = useState('Grid')

    const { isAuthenticated, user } = authContext
    const { setAlert } = alertContext

    const classes = useStyles()

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [states, setStates] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalItems, setTotalItem] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    const forMobile = useMediaQuery('(max-width:1024px)')

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const params = new URLSearchParams(window.location.search.substring(1))

    const formik = useFormik({
        initialValues: {
            sh_limit: 36,
            page: 1,
            orderby: '6',
            location: [],
            state: [],
            city: [],
            category: catg ? [catg] : [],
            contenthead5: [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            miles: params.get('m') ? params.get('m') : '',
            from: '',
            closed_auction_list: 0,
            view_type: params.get('view') ? params.get('view') : 0,
            auction_io: 1,
            conditionunique: [],
            lotsize: ['1'],
        },
    })

    const filterValues = [
        {
            title: 'View',
            type: 'radio',
            noLabel: true,
            name: 'view_type',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'All' },
                { id: 1, description: 'Auction Lots' },
                { id: 2, description: 'Buy Now Lots' },
            ],
            formik: formik,
        },

        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories
                .sort((a, b) => a.description.localeCompare(b.description))
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },
        {
            title: 'Conditions',
            type: 'check',
            name: 'conditionunique',
            options: allCondition
                .filter(
                    (maincat) =>
                        maincat.active === 1 ||
                        (formik.values.conditionTypeId &&
                            formik.values.conditionTypeId === maincat.id),
                )
                .map((location) => {
                    let conditionChanged = {}
                    conditionChanged.description = location.description
                    conditionChanged.id = location.id
                    conditionChanged.name = location.description
                    conditionChanged.value = location.id
                    conditionChanged.active = 1
                    return conditionChanged
                })
                .sort((a, b) => a.description.localeCompare(b.description))
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },
        {
            title: 'Location',
            type: 'check',
            name: 'state',
            options: states
                .map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.description = buscat.state
                    busCategoryChanged.name = buscat.state
                    busCategoryChanged.value = buscat.state
                    busCategoryChanged.id = buscat.state
                    busCategoryChanged.active = 1
                    return busCategoryChanged
                })
                .sort()
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },
        {
            title: "Seller's",
            type: 'check',
            name: 'contenthead5',
            options: brandsliqutatios
                .map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.description = buscat.description
                    busCategoryChanged.name = buscat.description
                    busCategoryChanged.value = buscat.id
                    busCategoryChanged.id = buscat.id
                    busCategoryChanged.active = 1
                    return busCategoryChanged
                })
                .sort((a, b) => a.description.localeCompare(b.description))
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },
        {
            title: 'Lot Size',
            type: 'check',
            name: 'lotsize',
            options: [
                {
                    description: 'Pallet',
                    name: 'Pallet',
                    value: 1,
                    id: 1,
                },
            ],
            formik: formik,
        },
        {
            title: 'Auction Status',
            type: 'radio',
            noLabel: true,
            name: 'closed_auction_list',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'Live Items' },
                { id: 1, description: 'Closed Items' },
            ],
            formik: formik,
        },

        // {
        //     title: 'Sub category',
        //     type: 'check',
        //     name: 'subcategory',
        //     options: global.searchSubCategories.filter((subcat) => {
        //         // if (
        //         //     formik &&
        //         //     formik.values &&
        //         //     formik.values.category &&
        //         //     formik.values.category.length
        //         //         ? formik.values.category.includes(subcat.level_id.toString())
        //         //         : false
        //         // ) {
        //         return true
        //         //}
        //     }),
        //     formik: formik,
        // },
    ]

    useEffect(() => {
        setLoading(false)

        // if (search_allproducts.from === 'searchPage') {
        setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
        setTotalItem(search_allproducts.total_pagecnt)
        setStates(search_allproducts.state)
        // }
    }, [search_allproducts, formik.values])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        formik.setFieldValue('page', value)
    }
    useEffect(() => {
        setLoading(true)
        if (formik.values.closed_auction_list) {
            formik.values.orderby = '7'
        }

        if (formik.values.view_type == 0) {
            formik.setFieldValue('from', '')
            getAllSearchProducts(formik.values)
        } else if (formik.values.view_type == 1) {
            formik.setFieldValue('from', 'auction_only')
            if (formik.values.from === 'auction_only') {
                getAllSearchProducts(formik.values)
            }
        } else if (formik.values.view_type == 2) {
            formik.setFieldValue('from', 'buynow')
            if (formik.values.from === 'buynow') {
                getAllSearchProducts(formik.values)
            }
        }
    }, [isAuthenticated, formik.values])
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('miles', params.get('m') ? params.get('m') : '')
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
        formik.setFieldValue('view_type', params.get('view') ? params.get('view') : 0)
    }, [window.location.search])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]

    const settings = {
        dots: true,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }
    const handleRedirect = (data) => {
        //console.log(data)
        //console.log(global.multipleBannersFields[data])
        if (!isAuthenticated) {
            handleRedirectInternal(history, 'sign-up')
        } else {
            // window.location.href = JSON.parse(
            //     global.multipleBannersFields[data].fields
            //         ? global.multipleBannersFields[data].fields
            //         : '{}',
            // ).btn_link
        }
    }
    return (
        <Layout props={props}>
            <SEO title={`${global?.storeDetails?.name} | Home`} />
            {global.multipleBannersFields.length ? (
                <div className="siteBanner multiple">
                    {
                        <Slider {...settings}>
                            {global.multipleBannersFields?.map((data, index) => (
                                <>
                                    <img
                                        src={
                                            process.env.REACT_APP_AUCTION_IMAGE_URL +
                                            '/' +
                                            data?.image_name
                                        }
                                    />
                                    <div className="bannerContent">
                                        <h2 className="pb-4">
                                            {JSON.parse(data.fields ? data.fields : '{}').title}
                                        </h2>
                                        {/* <Link
                                            to={
                                                !isAuthenticated
                                                    ? '/sign-up'
                                                    : JSON.parse(data.fields ? data.fields : '{}')
                                                          .btn_link
                                            }
                                        >
                                            {'Sign Up'}
                                        </Link> */}
                                        <PrimaryButton
                                            label={
                                                isAuthenticated
                                                    ? JSON.parse(data.fields ? data.fields : '{}')
                                                          .btn_text
                                                    : 'Sign Up'
                                            }
                                            type="button"
                                            onClick={() =>
                                                !isAuthenticated
                                                    ? handleRedirectInternal(history, 'sign-up')
                                                    : (window.location.href = JSON.parse(
                                                          global.multipleBannersFields[index].fields
                                                              ? global.multipleBannersFields[index]
                                                                    .fields
                                                              : '{}',
                                                      ).btn_link)
                                            }
                                        />
                                    </div>
                                </>
                            ))}
                        </Slider>
                    }
                </div>
            ) : (
                <div className="siteBanner">
                    <LazyLoadImage
                        src={global?.storeDetails?.bannerValue}
                        alt={`${global?.storeDetails?.name} banner`}
                        effect="blur"
                        height="100%"
                        width="100%"
                    />
                    <div className="bannerContent">
                        <h2>{global?.storeDetails?.description}</h2>
                        <PrimaryButton
                            label="Explore Now"
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    `${isAuthenticated ? 'search' : 'sign-up'}`,
                                )
                            }
                        />
                    </div>
                </div>
            )}

            <div className="customContainer mt-5 position-relative">
                {forMobile ? (
                    <div className="mblStckyFilter d-flex justify-content-between align-items-center py-3">
                        {/* <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={formik.values.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                formik.setFieldValue('sh_limit', event.target.value)
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect> */}
                        <Button
                            className="filterButton w-100"
                            variant="outlined"
                            onClick={toggleDrawer('bottom', true)}
                        >
                            Filters
                        </Button>

                        <CustomSelect
                            label="Sort by"
                            value={formik.values.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                formik.setFieldValue('orderby', event.target.value)
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>

                        <Button
                            className="filterButton w-100"
                            variant="outlined"
                            onClick={() => handleRedirectInternal(history, 'dashboard/mybids')}
                        >
                            My Bids
                        </Button>

                        <Button
                            className="filterButton srchBtn w-100"
                            variant="outlined"
                            onClick={toggleDrawer('top', true)}
                        >
                            <span className="material-icons">search</span>
                        </Button>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between align-items-center flex-wrap my-5">
                        <h2 className="auctionListTitle"></h2>

                        <div className="auctionsMiscFilter">
                            <div className="gridListToggle">
                                <Button
                                    className={auctionView === 'Grid' ? 'active' : ''}
                                    onClick={() => setAuctionView('Grid')}
                                >
                                    <span className="material-icons">apps</span>
                                </Button>
                                <Button
                                    className={auctionView === 'List' ? 'active' : ''}
                                    onClick={() => setAuctionView('List')}
                                >
                                    <span className="material-icons">view_list</span>
                                </Button>
                            </div>
                            <CustomSelect
                                label="Results per page"
                                name="resultsPerPage"
                                selectType="noBorder"
                                value={formik.values.sh_limit}
                                size="small"
                                onChange={(event, editor) => {
                                    formik.setFieldValue('sh_limit', event.target.value)
                                }}
                            >
                                {pageOptions.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                label="Sort by"
                                value={formik.values.orderby}
                                size="small"
                                selectType="noBorder"
                                name="orderby"
                                // shrink={search.orderby !== '' ? true : false}
                                onChange={(event, editor) => {
                                    formik.setFieldValue('orderby', event.target.value)
                                }}
                            >
                                {sortShow.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                        </div>
                        <Button
                            className="toggleRespDrawer w-100"
                            variant="outlined"
                            onClick={toggleDrawer('bottom', true)}
                        >
                            <span className="material-icons">tune</span>
                            Filters
                        </Button>
                    </div>
                )}

                <div className="searchContainer">
                    <div className="searchLt">
                        <FilterPanel formik={formik} filterValues={filterValues} allOpen={true} />
                    </div>

                    <div className="searchRt">
                        {isLoading ? (
                            <div>
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                            </div>
                        ) : viewProduct?.length !== 0 ? (
                            <div
                                className={`lotResults ${auctionView} liquidationBuyCard ${
                                    !isAuthenticated ? 'unauth' : 'auth'
                                }`}
                            >
                                {viewProduct.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <>
                                            {
                                                <GridProduct
                                                    data={data}
                                                    viewProduct={viewProduct}
                                                    setViewProduct={setViewProduct}
                                                    className={data.auction ? 'auction' : 'buynow'}
                                                    key={index}
                                                    type={data.auction ? 'auction' : 'buynow'}
                                                    selectedProduct={selectedProduct}
                                                    link={`/gallery/product-view/${data.id}`}
                                                    handleClick={handleClick}
                                                    timerTheme=""
                                                    sliderTheme="multiseller"
                                                    currencyType="cad"
                                                    cardTheme=""
                                                    bidBtnLabel="Place Bid"
                                                />
                                            }
                                        </>
                                    ) : (
                                        <MultisellerListCard
                                            lotData={data}
                                            viewProduct={viewProduct}
                                            setViewProduct={setViewProduct}
                                            className={data.auction ? 'auction' : 'buynow'}
                                            key={index}
                                            selectedProduct={selectedProduct}
                                            allsearchitems={viewProduct}
                                            type="lot"
                                            handleClick={handleClick}
                                            details={`/gallery/product-view/${data.id}`}
                                            sliderTheme="multiseller"
                                            card_theme="LiquidationPallet"
                                            bidBtnLabel={
                                                <>
                                                    <span className="material-icons-outlined">
                                                        gavel
                                                    </span>
                                                    Bid
                                                </>
                                            }
                                        />
                                    ),
                                )}
                            </div>
                        ) : (
                            <NoRecordsFound />
                        )}
                        <Pagination
                            count={Math.ceil(totalItems / formik.values.sh_limit)}
                            page={formik.values.page}
                            onChange={onHandlePage}
                        />
                    </div>
                </div>
            </div>

            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel formik={formik} filterValues={filterValues} />
                </div>
            </Drawer>
            <MultisellerSlider
                storeTheme="LiquidationPallet"
                selectedLot={selectedProduct}
                handleClose={handleClose}
                currency="cad"
                type={props.type ? props.type : ''}
            />
            <React.Fragment>
                <SwipeableDrawer
                    className="respHeaderSearch"
                    anchor={'top'}
                    open={state['top']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('top', false)}
                    onOpen={toggleDrawer('top', true)}
                >
                    <HeaderSearchComponent />
                </SwipeableDrawer>
            </React.Fragment>
        </Layout>
    )
}

export default AuctionSearchComponent
