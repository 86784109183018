import React, { useState } from 'react'
import { useContext } from 'react'
import Loader from '../../../../assets/loader'
import PosContext from '../../../../Product/context/plugin/pos/posContext'
import AuthContext from '../../../../Product/context/auth/authContext'
import AlertContext from '../../../../Product/context/alert/alertContext'
import { useEffect } from 'react'
import { Fragment } from 'react'
import AddUpdate from './AddUpdate'
import NoRecordsFound from '../../../../Product/components/atoms/NoRecordsFound'
import Delete from './Delete'

const PosLocation = () => {
    const { user } = useContext(AuthContext)
    const { allLocations, all_locations, responseStatus } = useContext(PosContext)
    const { setAlert } = useContext(AlertContext)

    const [loading, setLoading] = useState(true)
    const [locations, setLocations] = useState([])

    useEffect(() => {
        if (user) {
            setLoading(true)
            allLocations({ user_id: user?.id, site_id: global?.storeDetails?.site_id })
        }
    }, [user])

    useEffect(() => {
        setLocations(all_locations)
        setLoading(false)
    }, [all_locations])

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'createLocation' ||
                responseStatus.from === 'deleteLocation' ||
                responseStatus.from === 'updateLocation'
            ) {
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, 'success')
                    setLoading(true)
                    allLocations({ user_id: user?.id, site_id: global?.storeDetails?.site_id })
                }
            }
        }
    }, [responseStatus])
    return (
        <div>
            <div className="float-right mb-3 addBtn">
                <AddUpdate label="Add a new location" />
            </div>
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : locations.length ? (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Display Name</th>
                                    <th>Id</th>
                                    <th>Country</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Address</th>
                                    <th>Zip Code</th>
                                    <th className="actnBtnCol">Action</th>
                                </tr>
                            </thead>
                            {locations.map((item, index) => (
                                <Fragment key={index}>
                                    <tbody>
                                        <tr>
                                            <td>{item.display_name}</td>
                                            <td>{item.id}</td>
                                            <td>{item.address?.country}</td>
                                            <td>{item.address?.state}</td>
                                            <td>{item.address?.city}</td>
                                            <td>{item.address?.line1}</td>
                                            <td>{item.address?.postal_code}</td>
                                            <td className="tableAct">
                                                <Delete
                                                    id={item.id}
                                                    label={
                                                        <span className="material-icons">
                                                            delete
                                                        </span>
                                                    }
                                                />
                                                <AddUpdate
                                                    id={item.id}
                                                    type="update"
                                                    label={
                                                        <span className="material-icons">
                                                            update
                                                        </span>
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Fragment>
                            ))}
                        </table>
                    </div>
                </>
            ) : (
                <>
                    <NoRecordsFound />
                </>
            )}
        </div>
    )
}

export default PosLocation
