import React, { useState, useEffect, useContext } from 'react'
import Layout from '../Layout'
import CheckoutComponent from '../../../utils/CommonFunctionality/Checkout'

const Checkout = (props) => {
    return (
        <Layout props={props}>
            <CheckoutComponent props={props} />
        </Layout>
    )
}

export default Checkout
