import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import MyBidsPanel from '../../../utils/CommonFunctionality/MyBidsPanel'

const MyBids = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="MY BIDS">
                <MyBidsPanel
                    currency="cad"
                    //sliderTheme="multiseller"
                    noTimer={true}
                    noView={true}
                    redirect_product_view={1}
                    theme="auctioneer"
                />
            </DashboardLayout>
        </Layout>
    )
}

export default MyBids
