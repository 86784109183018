import axios from 'axios'
import { config } from './common.header'

const getBiddingHistory = async (product_id) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_URL}getbiddetails`,
            { product_id },
            config(),
        )
        return response
    } catch (error) {
        //console.log(error)
    }
}

const buyerDashboard = async (data) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_URL}buyerDashboard`,
            { ...data },
            config(),
        )
        return response
    } catch (error) {
        //console.log(error)
    }
}

const buyerPurchaseDashboard = async (data) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_URL}buyerPurchaseHistory`,
            { ...data },
            config(),
        )
        return response
    } catch (error) {
        //console.log(error)
    }
}

const submitBid = async (productBody) => {
    let body = {
        ...productBody,
        email: config('email'),
        first_name: config('first_name'),
        last_name: config('last_name'),
        userid: config('id'),
        checkuserenable: 1,
    }
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_URL}mobileconfirmForward`,
            body,
            config(),
        )
        return response
    } catch (error) {
        //console.log(error)
    }
}

export { getBiddingHistory, submitBid, buyerDashboard, buyerPurchaseDashboard }
