import React from 'react'
import BuyerAssuranceBilling from '../../../utils/CommonFunctionality/BuyerAssurance/Billing'
import DashboardHeader from '../DashboardHeader'
import Layout from '../Layout'

const Billing = (props) => {
    return (
        <Layout props={props}>
            <DashboardHeader />
            <BuyerAssuranceBilling />
        </Layout>
    )
}

export default Billing
