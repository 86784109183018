import React, { useState, useEffect, useCallback, useContext } from 'react'
import Container from '@material-ui/core/Container'
import DashboardLayout from '../DashboardLayout'
import { Button } from '@material-ui/core'
import Layout from '../Layout'
import './Cards.css'
import CardDetails from '../../../Product/components/organisms/CardDetails'
import Popup from '../../../Product/components/organisms/Popup'
import StripeCustomerContext from '../../../Product/context/stripe/customer/customerContext'
import StripeCardContext from '../../../Product/context/stripe/card/cardContext'
import AuthContext from '../../../Product/context/auth/authContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { handleRedirectInternal, mapData, jsonToFormData } from '../../../Product/common/components'
import AlertContext from '../../../Product/context/alert/alertContext'
const Banks = (props) => {
    const [cards, setCards] = useState([])

    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const [isOpen, setOpen] = useState(false)
    const [bank_verify_open, setBankVarify] = useState(false)
    const { user, loadUser, isAuthenticated } = authContext
    const [btnLoading, setBtnLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }

    const {
        customer_details,
        createStripeCustomer,
        getStripeCustomer,
        addCustomerProfile,
        CraeteBankCustomer,
        BuyerAddBank,
        getBankDetails,
        verifyBankdetails,
        responseStatus: responseStatusStripeCustomer,
    } = useContext(StripeCustomerContext)

    const {
        get_all_card_details,
        getAllStripeCards,
        responseStatus: responseStatusCard,
        clearResponse: clearResponseCard,
    } = useContext(StripeCardContext)

    const getStripeCards = () => {
        getAllStripeCards({
            account_id: process.env.REACT_APP_AUCTIONPAYID,
            customer_id: user.card_paymentid,
            object: 'card',
        })
        toggleFullScreenCardPopup(false, 'new', null)
    }
    const getBankDetailsCards = () => {
        getBankDetails({
            site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
            email: user.email ? user.email : '',
            user_id: user.id ? user.id : '',
        })
    }

    useEffect(() => {
        if (user && user.id) {
            getBankDetailsCards()
        }
        //getStripeCards()
        // } else if (user && !user.card_paymentid) {
        //     console.log('user', user)
        //     createStripeCustomer({
        //         account_id: process.env.REACT_APP_AUCTIONPAYID,
        //         description: 'TEST CUSTOMER',
        //         email: user.email,
        //         name: user.first_name + ' ' + user.last_name,
        //     })
        // }
    }, [user])

    // useEffect(() => {
    //     if (responseStatusStripeCustomer) {
    //         if (responseStatusStripeCustomer.status === 'success') {
    //             if (responseStatusStripeCustomer.from === 'create') {
    //                 console.log('responseStatusStripeCustomer', responseStatusStripeCustomer)
    //                 addCustomerProfile({
    //                     customer_id: responseStatusStripeCustomer.data.id,
    //                 })
    //             }
    //             loadUser()
    //             // setIsLoading(false)
    //         }
    //     }
    // }, [responseStatusStripeCustomer])

    useEffect(() => {
        if (responseStatusStripeCustomer) {
            if (
                responseStatusStripeCustomer.from === 'add_bank_customer' ||
                responseStatusStripeCustomer.from === 'add_bank_buyer' ||
                responseStatusStripeCustomer.from === 'get_bank_buyer' ||
                responseStatusStripeCustomer.from === 'verify_bank_buyer'
            ) {
                if (responseStatusStripeCustomer.status === 'success') {
                    //getStripeCards()
                    if (responseStatusStripeCustomer.from === 'add_bank_customer') {
                        var bank_data = {
                            site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                            user_id: user.id ? user.id : '',
                            account_no: bankformik.values.bank_account_number,
                            rounting_no: bankformik.values.bank_routing_number,
                            holdername: bankformik.values.bank_account_holder_name,
                        }
                        BuyerAddBank(bank_data)
                    } else if (responseStatusStripeCustomer.from === 'add_bank_buyer') {
                        setOpen(false)
                        setAlert('Bank Details Added Sucessfully', 'success')
                        getBankDetailsCards()
                    } else if (responseStatusStripeCustomer.from === 'get_bank_buyer') {
                        // console.log(
                        //     'innnnnnnnnnnnnnnnnnnnnnnnnn',
                        //     responseStatusStripeCustomer.data,
                        // )
                        setCards(
                            responseStatusStripeCustomer.data.data
                                ? responseStatusStripeCustomer.data.data
                                : [],
                        )
                    } else if (responseStatusStripeCustomer.from === 'verify_bank_buyer') {
                        setBankVarify(false)
                        setAlert(responseStatusStripeCustomer.message, 'success')
                        getBankDetailsCards()
                    }
                } else {
                    if (
                        responseStatusStripeCustomer.message == 'Customer account already created!'
                    ) {
                        var bank_data = {
                            site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                            user_id: user.id ? user.id : '',
                            account_no: bankformik.values.bank_account_number,
                            rounting_no: bankformik.values.bank_routing_number,
                            holdername: bankformik.values.bank_account_holder_name,
                        }
                        BuyerAddBank(bank_data)
                    } else {
                        setAlert(responseStatusStripeCustomer.message, 'error')
                    }
                }
            }
            setIsLoading(false)
        }
        return () => {
            clearResponseCard()
        }
    }, [responseStatusStripeCustomer])

    // useEffect(() => {
    //     if (get_all_card_details) {
    //         setCards(get_all_card_details.records)
    //     }

    //     return () => {
    //         setCards([])
    //     }
    // }, [get_all_card_details])

    const bankvalidationarray = Yup.object({
        bank_account_holder_name: Yup.string().required('Required'),
        bank_account_holder_type: Yup.string().required('Required'),
        bank_routing_number: Yup.string().required('Required').min(9, 'Minimum 9 digits'),
        bank_account_number: Yup.string().required('Required'),
    })
    const bank_verfication_popup = Yup.object({
        payAmount1: Yup.string().required('Required'),
        payAmount2: Yup.string().required('Required'),
    })

    const bank_verification_formik = useFormik({
        initialValues: {
            payAmount1: '',
            payAmount2: '',
            site_id: '',
            user_id: '',
        },
        validationSchema: bank_verfication_popup,
        onSubmit: (values) => {
            values.site_id = global.storeDetails.site_id ? global.storeDetails.site_id : ''
            values.user_id = user.id ? user.id : ''
            verifyBankdetails(values)
        },
    })

    const bankformik = useFormik({
        initialValues: {
            account_id: user?.company_name ? user.company_name : '',
            bank_country: 'us',
            bank_currency: 'usd',
            bank_account_holder_name: '',
            bank_account_holder_type: '',
            bank_routing_number: '',
            bank_account_number: '',
            bank_token: true,
        },
        validationSchema: bankvalidationarray,
        onSubmit: (values) => {
            var data = {
                site_id: global.storeDetails.site_id ? global.storeDetails.site_id : '',
                email: user.email ? user.email : '',
                user_id: user.id ? user.id : '',
                holdername: values.bank_account_holder_name,
            }
            CraeteBankCustomer(data)
        },
    })
    const bank_details = {
        formik: bankformik,
        data: [
            {
                label: 'Holder Name',
                name: 'bank_account_holder_name',
                type: 'text',
                placeholder: 'Enter your bank holder name',
                class: 'col-12 col-sm-6',
                autoFocus: true,
            },
            {
                label: 'Account Type',
                placeholder: 'Select your type',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: [
                    {
                        show: 'Individual',
                        value: 'individual',
                    },
                    {
                        show: 'Company',
                        value: 'company',
                    },
                ],
                name: 'bank_account_holder_type',
            },
            {
                label: 'Routing Number',
                name: 'bank_routing_number',
                type: 'text',
                placeholder: 'Enter routing number',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Account Number',
                name: 'bank_account_number',
                type: 'text',
                placeholder: 'Enter account number',
                class: 'col-12 col-sm-6',
            },
        ],
    }
    const bank_verification = {
        formik: bank_verification_formik,
        data: [
            {
                label: 'Amount 1',
                name: 'payAmount1',
                type: 'text',
                placeholder: 'Enter amount1',
                class: 'col-6',
                autoFocus: true,
            },
            {
                label: 'Amount 2',
                name: 'payAmount2',
                type: 'text',
                placeholder: 'Enter your amount1',
                class: 'col-6',
            },
        ],
    }
    const Openpopup = (e) => {
        bankformik.values.bank_account_holder_name = ''
        bankformik.values.bank_account_holder_type = ''
        bankformik.values.bank_routing_number = ''
        bankformik.values.bank_account_number = ''
        setOpen(true)
    }
    const Verifypopup = (e) => {
        setBankVarify(true)
    }

    return (
        <Layout props={props}>
            <DashboardLayout title="Bank Details">
                <div>
                    <div className="savedCardsCnt">
                        <div className="savedCards">
                            <div
                                className="emptyCard d-flex justify-content-between flex-column"
                                onClick={(e) => Openpopup(e)}
                            >
                                <div>
                                    <span>
                                        <img src="/assets/svg/emptyCard.svg" />
                                    </span>
                                    <h2>ADD A NEW Bank Detail</h2>
                                </div>
                            </div>
                            {cards &&
                                cards.map((data, index) => (
                                    <div
                                        className="emptyCard d-flex justify-content-between flex-column"
                                        key={index}
                                    >
                                        {/* <div className="d-flex justify-content-between align-items-center">
                                           
                                        </div> */}
                                        <div className="credCardNum">
                                            <h2>Bank Name: {data.bank_name}</h2>
                                            {/* <h2></h2> */}
                                            <h2>Account Holder Name: {data.account_holder_name}</h2>
                                            <h2></h2>
                                        </div>
                                        <div className="credCardName d-flex justify-content-between align-items-center">
                                            {data.status == 'new' ? (
                                                <Button
                                                    onClick={(e) => Verifypopup(e)}
                                                    className="edit"
                                                >
                                                    <span className="material-icons">verify</span>
                                                    VERIFY
                                                </Button>
                                            ) : data.status == 'verified' ? (
                                                <Button className="edit">
                                                    <span className="material-icons">verify</span>
                                                    VERIFIED
                                                </Button>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-end align-items-center cardActions">
                                            {customer_details &&
                                                data.id === customer_details.default_source && (
                                                    <h6 className="defaultSelection">Default</h6>
                                                )}
                                            <Button
                                                onClick={() =>
                                                    toggleFullScreenCardPopup(true, 'update', data)
                                                }
                                                className="edit"
                                            >
                                                <span className="material-icons">edit</span>
                                                Edit
                                            </Button>

                                            {customer_details &&
                                                data.id !== customer_details.default_source && (
                                                    <>
                                                        <Button
                                                            onClick={() =>
                                                                toggleFullScreenCardPopup(
                                                                    true,
                                                                    'default',
                                                                    data,
                                                                )
                                                            }
                                                            className="default"
                                                        >
                                                            <span className="material-icons">
                                                                published_with_changes
                                                            </span>
                                                            Make Default
                                                        </Button>
                                                    </>
                                                )}
                                            <Button
                                                onClick={() =>
                                                    toggleFullScreenCardPopup(false, 'delete', data)
                                                }
                                                className="delete"
                                            >
                                                <span className="material-icons">delete</span>
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="PaymentModal">
                        {/* <Popup
                            open={addBank}
                            size="md"
                            handleClose={() => setAddBank(false)}
                            modaltitle="Bank Account"
                        ></Popup>*/}
                    </div>
                    <Popup
                        open={isOpen}
                        handleClose={(e) => setOpen(false)}
                        size="md"
                        className="save-search-modal"
                        modaltitle="Bank Details"
                    >
                        <>
                            <div className="row">{mapData(bank_details)}</div>

                            <div className="actionWrapper">
                                <div className="row">
                                    <div className="col-sm-3 col-12"></div>
                                    <div className="col-md-6">
                                        <PrimaryButton
                                            onClick={(e) => bankformik.handleSubmit(e)}
                                            type="button"
                                            disabled={btnLoading}
                                            label={btnLoading ? 'Loading...' : 'ADD'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    </Popup>
                    <Popup
                        open={bank_verify_open}
                        handleClose={(e) => setBankVarify(false)}
                        size="md"
                        className="save-search-modal"
                        modaltitle="Verify Bank Details"
                    >
                        <>
                            <div className="row">{mapData(bank_verification)}</div>

                            <div className="actionWrapper">
                                <div className="row">
                                    <div className="col-sm-3 col-12"></div>
                                    <div className="col-md-6">
                                        <PrimaryButton
                                            onClick={(e) =>
                                                bank_verification_formik.handleSubmit(e)
                                            }
                                            type="button"
                                            disabled={btnLoading}
                                            label={btnLoading ? 'Loading...' : 'VERIFY'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    </Popup>
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default Banks
