import React from 'react'
import Layout from '../Layout'
import DashboardLayout from '../DashboardLayout'
import Booktickets from '../../../utils/CommonFunctionality/bookingtickets'

const MyTickets = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="My Tickets">
                <Booktickets props={props} />
            </DashboardLayout>
        </Layout>
    )
}

export default MyTickets
