import axios from 'axios'
import { config } from './common.header'

const updateProfile = async (body) => {
    if (!config('id')) {
        return false
    }
    body['role'] = 'buyer'
    body['notification'] = 0
    body['category_email'] = 0
    let responce = await axios.post(`${process.env.REACT_APP_URL}updateUserProfile`, body, config())
    return responce
}

const changePassword = async (body) => {
    let responce = await axios.post(
        `${process.env.REACT_APP_URL}updatepassword_in_profile`,
        body,
        config(),
    )
    return responce
}

const forgetPassword = async (body) => {
    let { data } = await axios.post(`${process.env.REACT_APP_URL}change-password`, body)
    return data
}

const getProfileData = async () => {
    if (!config('id')) {
        return false
    }
    let userData = await axios.post(
        `${process.env.REACT_APP_URL}getUserProfileDetails`,
        {},
        config(),
    )
    if (userData && userData.data.success === 'yes') {
        let data = userData.data.data
        return {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone,
            alternate_phone: data.alternate_phone,
            country: data.country,
            state: data.state,
            city: data.city,
            zip: data.zip,
            address1: data.address1,
            address2: data.address2,
            tax_excempt: data.tax_excempt,
            nmfs_number: data.nmfs_number,
        }
    }
}

export const profile = {
    getProfileData,
    updateProfile,
    changePassword,
    forgetPassword,
}
