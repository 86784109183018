import React, { useContext, useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { Pagination } from '@material-ui/lab'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import { currencyFormat, dateFormatFront } from '../../../Product/common/components'

const Transactions = (props) => {
    const buyerContext = useContext(BuyerContext)
    const { buyer_alltransaction, getAllTransaction } = buyerContext

    const [search, setSearch] = useState({
        sh_limit: 10,
        page: 1,
    })

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        getAllTransaction(search)
    }, [search])

    return (
        <Layout props={props}>
            <DashboardLayout title="Transactions">
                <div className="dashboard-wrapper">
                    {buyer_alltransaction.results.length ? (
                        <>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Title</th>
                                        <th>Type</th>
                                        <th>Amount</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {buyer_alltransaction.results.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.transid}</td>
                                                <td>{item.title}</td>
                                                <td>Card Payment</td>
                                                <td>{currencyFormat(item.amount)}</td>
                                                <td>{dateFormatFront(item.pay_date)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <div className="np-pro-box"> {'No Transactions'} </div>
                    )}
                    {buyer_alltransaction.results.length ? (
                        <>
                            <div className="d-flex mt-3">
                                <div className="ml-auto mt-2">
                                    {'Showing'} {search.sh_limit * (search.page - 1) + 1} -{' '}
                                    {search.sh_limit * search.page >
                                    buyer_alltransaction.total_pagecnt
                                        ? buyer_alltransaction.total_pagecnt
                                        : search.sh_limit * search.page}{' '}
                                    of {buyer_alltransaction.total_pagecnt}
                                </div>
                                <Pagination
                                    count={Math.ceil(
                                        buyer_alltransaction.total_pagecnt / search.sh_limit,
                                    )}
                                    page={search.page}
                                    onChange={onHandlePage}
                                />
                            </div>
                        </>
                    ) : null}
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default Transactions
