import React from 'react'
import Layout from '../Layout'
import CartComponent from '../../../utils/CommonFunctionality/Cart'

const Cart = (props) => {
    return (
        <Layout props={props}>
            <CartComponent props={props} currency="cad" />
        </Layout>
    )
}

export default Cart
