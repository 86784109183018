import React from 'react'
import Layout from '../Layout'
import DashboardLayout from '../DashboardLayout'
import PreferenceForm from '../../../utils/CommonFunctionality/PreferenceFunction'
import { useTranslation } from 'react-i18next'

const Preference = (props) => {
    const { t } = useTranslation()
    return (
        <Layout props={props}>
            <DashboardLayout title={t('notifi_preference')}>
                <PreferenceForm props={props} notifictionpara={true} />
            </DashboardLayout>
        </Layout>
    )
}

export default Preference
