import React from 'react'
import Layout from '../Layout'
import './PostProduct.css'
import PostProductComponent from '../../../utils/CommonFunctionality/PostProduct'

const PostProduct = (props) => {
    return (
        <Layout props={props}>
            <div>
                <h2 className="ppTitle">Post a Product</h2>
                <PostProductComponent
                    withoutAuction={1}
                    itemLocation={1}
                    addressSection={1}
                    buynow={1}
                    defaultCountryCode={38}
                    extraSection={1}
                />
            </div>
        </Layout>
    )
}

export default PostProduct
