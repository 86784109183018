import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { Copyrightinfo, Logo } from '../../../utils'
import jsonp from 'jsonp'
import queryString from 'query-string'
import './Footer.css'
import Footernav from '../../../utils/FooterNavigation/footernav'
function Footer() {
    const [inpData, setinpData] = useState('')
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//${global.storeConfigration?.subscribe_code?.value}${queryString.stringify(
                    data,
                )}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }

    return (
        <footer className="footer-main-standard noPrint">
            <div className="subscribeContainer">
                <div>
                    <p className="mb-5">Join our mailing list Never Miss An Update</p>
                    <div className="row">
                        <div className="col-md-4">
                            <input
                                type="text"
                                name="name"
                                // value={inpData}
                                placeholder="Name"
                            />
                        </div>
                        <div className="col-md-4">
                            <input
                                type="text"
                                name="q"
                                value={inpData}
                                placeholder="Email address"
                                onChange={(e) => {
                                    setinpData(e.target.value)
                                }}
                            />
                        </div>
                        <div className="col-md-4">
                            <PrimaryButton label="Subscribe Now" onClick={subscribe} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="customContainer mt-5">
                <div className="ftrLgWrpr d-flex align-items-center justify-content-between">
                    <div className="ftrLgWrpr d-flex align-items-center">
                        <Logo className="footerLogo" />
                        <div className="adrsInfoDtls">
                            {/* <p>Owned by Michelle Cannon</p> */}
                            <p>
                                4520 FM 877, Waxahachie, TEXAS 75165{' '}
                                <span className="material-icons">circle</span>
                                {contactPhone} <span className="material-icons">circle</span>
                                <a href={`mailto:${contactEmail}`}>
                                    <u>Email Us</u>
                                </a>
                            </p>
                            <p>
                                <Copyrightinfo />
                            </p>
                        </div>
                    </div>
                    <div className="footerMisc">
                        <ul className="footer-main-standard-social-list">
                            {facebookLink && (
                                <li className="facebook">
                                    <a
                                        href={facebookLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Facebook"
                                    >
                                        <i className="fab fa-facebook"></i>
                                    </a>
                                </li>
                            )}
                            {twitterLink && (
                                <li className="pinterest">
                                    <a
                                        href={twitterLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Pinterest"
                                    >
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                            )}
                            {googleLink && (
                                <li className="instagram">
                                    <a
                                        href={googleLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Instagram"
                                    >
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                </li>
                            )}
                            {instagramLink && (
                                <li className="instagram">
                                    <a
                                        href={instagramLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Instagram"
                                    >
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="customContainer d-none">
                <div className="row" data-gutter="60">
                    <div className="col-12 ftrDtlCntnr">
                        {/* <div className="footLogoCnt">
                            <Logo className="footerLogo" />
                        </div> */}
                        <div className="footerMisc">
                            <ul className="footerLinks">
                                {/* <li>
                                    <NavLink activeClassName="active" to="/termsOfService">
                                        Terms & Conditions
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/faq">
                                        FAQs
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/aboutus">
                                        About Us
                                    </NavLink>
                                </li> */}
                                <Footernav />
                            </ul>

                            {/* <ul className="footer-main-standard-social-list">
                                {facebookLink && (
                                    <li className="facebook">
                                        <a
                                            href={facebookLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Facebook"
                                        >
                                            <i className="fab fa-facebook"></i>
                                        </a>
                                    </li>
                                )}
                                {facebookLink && (
                                    <li className="pinterest">
                                        <a
                                            href={twitterLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Pinterest"
                                        >
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                )}
                                {googleLink && (
                                    <li className="instagram">
                                        <a
                                            href={googleLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Instagram"
                                        >
                                            <i className="fab fa-linkedin"></i>
                                        </a>
                                    </li>
                                )}
                                {instagramLink && (
                                    <li className="instagram">
                                        <a
                                            href={instagramLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Instagram"
                                        >
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                )}
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
