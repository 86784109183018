import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import DashboardPanel from './DashboardPanel'
import { NavLink, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function DashboardLayout(props) {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div className="artAuctionDashboardLayout customContainer">
            <DashboardPanel title={props.title} subtitle={props.subtitle} seller={props.seller} />

            <div className="dashboardRt">
                <div className="maTitle d-flex justify-content-between align-items-center w-100">
                    <h2 className="dashTitle">{props.title}</h2>
                    <Button
                        onClick={() => {
                            history.goBack()
                            window.scrollTo(0, 0)
                        }}
                        className="moveBack"
                    >
                        <span className="material-icons">arrow_back</span>
                        {t('back')}
                    </Button>
                </div>
                {props.children}
            </div>
        </div>
    )
}

export default DashboardLayout
