import React, { useState, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Copyrightinfo, Logo } from '../../../utils'
import './Footer.css'
import jsonp from 'jsonp'
import queryString from 'query-string'
import AlertContext from '../../../Product/context/alert/alertContext'
import Footernav from '../../../utils/FooterNavigation/footernav'
function Footer() {
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const [inpData, setinpData] = useState('')
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext
    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }
            jsonp(
                `//${global.storeConfigration?.subscribe_code.value}${queryString.stringify(data)}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }

    let email = global?.storeDetails?.address?.split(',')

    return (
        <footer className="footer-main-standard noPrint">
            <div className="customContainer">
                <div className="row" data-gutter="60">
                    <div className="col-12 ftrDtlCntnr">
                        <div className="footLogoCnt">
                            <Link className="nav-standard-logo" to="/">
                                <img
                                    src="../phill/philpgate.svg"
                                    className="footerLogo"
                                    alt={`logo`}
                                />
                            </Link>
                        </div>
                        <div className="footerMisc">
                            <div className="footerLinks">
                                {/* <ul>
                                    <li>
                                        {email?.length > 0 &&
                                            email?.map((data, index) => (
                                                <>
                                                    <span>{data}</span>
                                                    {index + 1 < email?.length && ','}
                                                    <br />
                                                </>
                                            ))}
                                        {/* Kemp House <br />
                                        152-160, City Road, <br />
                                        London, EC1V 2NX <br />
                                        United Kingdom 
                                    </li>
                                    <li>
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            href={
                                                global?.storeDetails?.contact_email
                                                    ? 'mailto:' + global.storeDetails.contact_email
                                                    : ''
                                            }
                                            // target="_blank"
                                        >
                                            {global?.storeDetails?.contact_email}
                                            {/* contact_email: "phil@multiauction.com"
                                        </a>
                                    </li>
                                </ul> */}
                                <div className="text-center footerSocial">
                                    <a
                                        href={facebookLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Facebook"
                                    >
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a
                                        href={instagramLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Instagram"
                                    >
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a
                                        href={twitterLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Twitter"
                                    >
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a
                                        href={googleLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Youtube"
                                    >
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </div>
                                <ul>
                                    <li>
                                        <Link to="/aboutus">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/how_it_works">How it Work</Link>
                                    </li>
                                    <li>
                                        <Link to="/auctions?type=2">Buy</Link>
                                    </li>
                                    <li>
                                        <Link to="/static/sell">Sell</Link>
                                    </li>
                                    <li>
                                        <Link to="/static/estimate">Estimates</Link>
                                    </li>
                                    <li>
                                        <Link to="/static/shipments">Shipments</Link>
                                    </li>
                                    <li>
                                        <Link to="/faq">Faq</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact_us">Contact</Link>
                                    </li>
                                    <li>
                                        <Link to="/static/contact_seller">Contact Seller</Link>
                                    </li>
                                    <li>
                                        <Link to="/static/legal_disclaimer">Legal Disclaimer</Link>
                                    </li>
                                    <li>
                                        <Link to="/termsOfService">Terms & Conditions</Link>
                                    </li>
                                </ul>
                                {/* <ul>
                                    <Footernav />
                                </ul> */}
                                {/* <div>
                                    <ul className="footer-main-standard-social-list">
                                        {facebookLink && (
                                            <li className="facebook">
                                                <a
                                                    href={facebookLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Facebook"
                                                >
                                                    <i className="fab fa-facebook"></i>
                                                </a>
                                            </li>
                                        )}
                                        {googleLink && (
                                            <li className="instagram">
                                                <a
                                                    href={googleLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Instagram"
                                                >
                                                    <i className="fab fa-linkedin"></i>
                                                </a>
                                            </li>
                                        )}
                                        {instagramLink && (
                                            <li className="instagram">
                                                <a
                                                    href={instagramLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Instagram"
                                                >
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </li>
                                        )}
                                        {twitterLink && (
                                            <li className="pinterest">
                                                <a
                                                    href={twitterLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Twitter"
                                                >
                                                    <i
                                                        className="fab fa-twitter"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                    <div className="subscribe-wrapper">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="q"
                                            value={inpData}
                                            placeholder="Subscribe to our mailing list"
                                            onChange={(e) => {
                                                setinpData(e.target.value)
                                            }}
                                        />
                                        <span onClick={subscribe} className="send-button">
                                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <p>Stay in touch with us for new updates!</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copy-right">
                <Copyrightinfo />
            </div>
        </footer>
    )
}

export default Footer
