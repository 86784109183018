import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import AuthContext from '../../../Product/context/auth/authContext'
import PropTypes from 'prop-types'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
    jsonToFormData,
} from '../../../Product/common/components'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import './PostProduct.css'
import { Box, Tab, Tabs, Typography } from '@material-ui/core'
import readXlsxFile from 'read-excel-file'
import { allCondition } from '../../../utils'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const PostProduct = (props) => {
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    var item_count = 0,
        total_items_csv = 0
    const { setAlert } = alertContext
    const {
        postProduct,
        postAuction,
        editProduct,
        edit_product,
        auction_lots,
        edit_auction,
        categories,
        bulkUploadInventory,
        getActiveUsers,
        all_active_users,
        getEditProduct,
        getEditAuction,
        getOverAllCategories,
        responseStatus,
        clearResponse,
    } = productContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated, isAdmin, user } = authContext
    const [reload, setReload] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [addMore, setAddMore] = useState(0)
    const [value, setValue] = useState(0)
    const product_id = new URLSearchParams(window.location.search.substring(1)).get('id')
    const lotRef = useRef(null)
    let paragraph = '<p></p>'
    const [subCategory, setSubCategory] = useState([])
    const [auctionDetails, setAuctionDetails] = useState({
        title: '',
        description: '',
        start_date: null,
        end_date: null,
        lot_id: [],
        status: 'open',
        auctionid: 0,
        private_auction: 0,
        selectedbuyers: [],
        grouping: 1,
        ending_enable: 0,
        ending_items: 0,
        ending_mints: 0,
        la_seller_id: 0,
        product_image: [],
        product_existingimage: [],
        product_imagefile: [],
        localpickup: 1,
        shipping: 0,
        //address
        address: '',
        country: '',
        state: '',
        city: '',
        zipcode: '',
        phone: '',
    })
    useEffect(() => {
        if (edit_auction && auction_id) {
            let editAuctionDetails = edit_auction.auction_details
            var items_limit = global.storeConfigration?.global_lot_items?.value
                ? global.storeConfigration?.global_lot_items?.value
                : 0
            if (parseInt(editAuctionDetails.lot_count) > 0 && parseInt(items_limit) > 0) {
                var total_items = editAuctionDetails.lot_count
                item_count = editAuctionDetails.lot_count
                total_items_csv = editAuctionDetails.lot_count
                if (parseInt(items_limit) <= parseInt(total_items)) {
                    setAlert('Your Lot Limit Is Reached!', 'error')
                    handleRedirectInternal(history, `dashboard/overview`)
                }
            }
            //console.log(editAuctionDetails, 'editAuctionDetails')
            setAuctionDetails({
                title: editAuctionDetails.title,
                description: editAuctionDetails.description,
                start_date: editAuctionDetails.date_added,
                end_date: editAuctionDetails.date_closed,
                private_auction:
                    editAuctionDetails.private_auction &&
                    parseInt(editAuctionDetails.private_auction) > 0
                        ? 1
                        : 0,
                status: editAuctionDetails.market_status,
                ending_enable: editAuctionDetails.ending_enable,
                ending_items: editAuctionDetails.ending_items,
                ending_mints: editAuctionDetails.ending_mints,
                auctionid: auction_id,
                la_seller_id: editAuctionDetails.la_seller_id,
                product_existingimage: [editAuctionDetails.avatar],
                address: editAuctionDetails.address,
                country: editAuctionDetails.country,
                state: editAuctionDetails.state,
                city: editAuctionDetails.city,
                zipcode: editAuctionDetails.zipcode,
                phone: editAuctionDetails.custom_field_1,
                lot_id: auction_lots.length
                    ? auction_lots
                          .filter((i) => (i.lotof === auction_id ? true : false))
                          .map((dat) => dat.id)
                    : [],
            })
        }
    }, [edit_auction, auction_lots])
    const { auction_id, setActiveStep } = props
    //console.log('auction_id', auction_id)
    useEffect(() => {
        if (isAuthenticated) {
            if (user.role === '1') {
            } else {
                handleRedirectInternal(history, '')
            }
        }
    }, [isAuthenticated])
    useEffect(() => {
        getEditAuction({ auctionid: auction_id })
    }, [auction_id])
    const validationArray = Yup.object({
        product_id: Yup.number(),
        auctionid: Yup.number(),
        /*   category_id: Yup.string().required('Required!'),
        radcondition: Yup.string().required('Required!'),
        subcategory: Yup.string(), */
        protitle: Yup.string().required('Required!'),
        auction: Yup.boolean().when('buynow', {
            is: false,
            then: Yup.boolean().oneOf([true], 'Enable Auction or Buy Now'),
        }),
        /*  sprice: Yup.number().when('auction', {
            is: true,
            then: Yup.number().required('Required!').positive('Should be greater than 1'),
        }),
        rprice: Yup.number().when('auction', {
            is: true,
            then: Yup.number()
                .positive('Should be greater than 1')
                .min(Yup.ref('sprice'), 'Should be equal or greater than the starting price')
                .required('Required!'),
        }),
        enable_rprice: Yup.boolean(), */
        selectedbuyers: Yup.array(),
        prev_rprice: Yup.number(),
        buynow: Yup.boolean(),
        bprice: Yup.number().when('buynow', {
            is: true,
            then: Yup.number()
                .required('Required!')
                .positive('Should be greater than 1')
                .when('auction', {
                    is: true,
                    then: Yup.number().moreThan(
                        Yup.ref('rprice'),
                        'Should be greater than the reserve price',
                    ),
                }),
        }),
        // startdate: Yup.string().required('Required!').nullable(),
        description: Yup.string().required('Required!'),
        sku: Yup.string().required('Required!'),
        qty: Yup.number().required('Required!').positive('Should be greater than 1'),
        /*  lowest: Yup.number().required('Required!').positive('Should be greater than 1'),
        highest: Yup.number().required('Required!').positive('Should be greater than 1'), */
        tags: Yup.array(),
        // enddate: Yup.string().required('Required!').nullable(),
        lotof: Yup.string(),
        paymentoptionresult: Yup.string(),
        contenthead1: Yup.string(),
        contenthead2: Yup.string(),
        // contenthead3: Yup.string(),
        // contenthead4: Yup.string(),
        // contenthead5: Yup.string().required('Required!'),
        hasshipping: Yup.boolean(),
        // sell_location: Yup.string().required('Required!'),
        weight: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').positive('Must be positive').required('Required!'),
        }),
        length: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').positive('Must be positive').required('Required!'),
        }),
        width: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').positive('Must be positive').required('Required!'),
        }),
        height: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').positive('Must be positive').required('Required!'),
        }),
        is_digital: Yup.number(),
        shippingcost: Yup.number('Numbers only!').when('is_digital', {
            is: 1,
            then: Yup.number().positive('Must be positive').lessThan(101, 'Minimum % is 100'),
        }),
        uploadimages: Yup.array(),
        product_deletedimage: Yup.array(),
        product_image: Yup.array(),
        product_existingimage: Yup.array(),
        product_imagefile: Yup.array(),
    })
    //console.log(global.products_terms, global.products_detail, 'global.products_detail')
    const formik = useFormik({
        initialValues: {
            product_id: '',
            auctionid: '',
            category_id: '',
            radcondition: '',
            subcategory: '',
            protitle: '',
            auction: 1,
            buynow: 0,
            sprice: 0,
            rprice: 0,
            enable_rprice: 1,
            selectedbuyers: [],
            prev_rprice: 0,
            bprice: 0,
            startdate: '',
            description: '',
            qty: global.storeConfigration?.global_quantity?.value
                ? global.storeConfigration?.global_quantity?.value
                : 1,
            tags: [],
            enddate: '',
            state: '',
            location: '',
            city: '',
            lotof: auction_id,
            paymentoptionresult: '',
            contenthead1: 0,
            contenthead2: global.products_detail,
            contenthead3: global.products_terms,
            contenthead4: '',
            contenthead5: '',
            latestbid: 0,
            hasshipping: 0,
            sell_location: '',
            weight: 0,
            length: 0,
            width: 0,
            height: 0,
            uploadimages: [],
            product_deletedimage: [],
            product_image: [],
            product_existingimage: [],
            product_imagefile: [],
            shippingcost: 0,
            is_digital: 0,
            market_status: '',
            sku: '',
            lowest: '',
            highest: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            let formValues = values
            formValues.buynow = formValues.buynow ? 1 : 0
            formValues.auction = formValues.auction ? 1 : 0
            formValues.hasshipping = formValues.hasshipping == 1 ? 1 : 0
            formValues.localpickup = formValues.hasshipping == 1 ? 0 : 1
            formValues.is_digital = parseInt(formValues.is_digital)
            formValues.startdate = edit_auction.auction_details.date_added
            formValues.enddate = edit_auction.auction_details.date_closed
            let formDataSend = jsonToFormData(formValues)
            if (product_id) {
                editProduct(formDataSend)
            } else {
                postProduct(formDataSend)
            }
            setLoading(true)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'postProduct' ||
                responseStatus.from === 'editProduct' ||
                responseStatus.from === 'bulkUploadInventory'
            ) {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    //console.log(responseStatus, ' responseStatus.data')
                    setLoading(true)
                    var sendData = auctionDetails
                    sendData.lot_id = [...sendData.lot_id, responseStatus.data, product_id]
                    let formDataSend = jsonToFormData(sendData)
                    postAuction(formDataSend)
                    var items_limit = global.storeConfigration?.global_lot_items?.value
                        ? global.storeConfigration?.global_lot_items?.value
                        : 0
                    if (parseInt(items_limit) > 0) {
                        item_count = item_count + 1
                        if (parseInt(items_limit) <= parseInt(item_count)) {
                            setAlert('Your Lot Limit Is Reached!', 'error')
                            handleRedirectInternal(history, `dashboard/overview`)
                        }
                    }
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'postAuction') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    if (addMore) {
                        formik.resetForm()
                        bulkFormik.resetForm()
                        window.scrollTo(0, 0)
                    } else {
                        handleRedirectInternal(history, `${'sellerdashboard?page=' + 0}`)
                    }
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (isAuthenticated) {
            if (product_id) {
                // setLoading(true)
                getEditProduct({ product_id })
            }
            getOverAllCategories()
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (edit_product.product_details && product_id) {
            let details = edit_product.product_details
            //console.log(details, 'details')
            formik.values.product_id = details.id ? details.id : ''
            formik.values.auctionid = details.auctionid ? details.auctionid : ''
            formik.values.category_id = details.category_id ? details.category_id : ''
            formik.values.radcondition = details.conditionunique ? details.conditionunique : ''
            formik.values.subcategory = details.subcategory ? details.subcategory : ''
            formik.values.protitle = details.title ? details.title : ''
            formik.values.latestbid = details.latestbid ? details.latestbid : ''
            formik.values.location = details.location ? details.location : ''
            formik.values.state = details.state ? details.state : ''
            formik.values.city = details.city ? details.city : ''
            formik.values.tags = details.tags ? details.tags.split(',') : []
            formik.values.lotof = details.lotof ? details.lotof : ''
            formik.values.auction = details.auction ? true : false
            formik.values.buynow = details.buynow ? true : false
            formik.values.sprice = details.sprice ? details.sprice : ''
            formik.values.rprice = details.rprice ? details.rprice : ''
            formik.values.enable_rprice = details.rprice && parseInt(details.rprice) > 0 ? 1 : 0
            formik.values.selectedbuyers = details.selectedbuyers
                ? details.selectedbuyers.split(',').map((i) => parseInt(i))
                : []
            formik.values.prev_rprice = details.rprice ? details.rprice : ''
            formik.values.bprice = details.bprice && details.buynow ? details.bprice : ''
            formik.values.startdate = details.date_added
                ? dateTimeFormatFunction(details.date_added, true)
                : dateTimeFormatFunction(new Date())
            /*eslint-disable*/
            formik.values.description = details.description
                .split('')
                .filter((item) => !paragraph.includes(item))
                .join('')
            formik.values.qty = details.qty ? details.qty : 1
            formik.values.enddate = details.date_closed
                ? dateTimeFormatFunction(details.date_closed, true)
                : dateTimeFormatFunction(new Date())
            formik.values.product_existingimage = edit_product.attachments.map((i) => i.src)
            formik.values.paymentoptionresult = details.paymentoptionresult
                ? details.paymentoptionresult
                : ''
            formik.values.market_status = details.market_status
            formik.values.contenthead1 = details.content_head1 ? details.content_head1 : 0
            formik.values.contenthead2 = details.content_head2
                ? details.content_head2
                : global.products_detail
            formik.values.contenthead3 = details.content_head3
                ? details.content_head3
                : global.products_terms
            formik.values.hasshipping = details.hasshipping ? 1 : 0
            formik.values.sell_location = details.sell_location ? details.sell_location : 0
            formik.values.sku = details.sku ? details.sku : ''
            formik.values.weight = details.weight ? details.weight : 0
            formik.values.length = details.length ? details.length : 0
            formik.values.width = details.width ? details.width : 0
            formik.values.height = details.height ? details.height : 0
            formik.values.contenthead4 = details.content_head4 ? details.content_head4 : ''
            formik.values.contenthead5 = details.content_head5 ? details.content_head5 : ''
            formik.values.product_deletedimage = []
            formik.values.shippingcost = details.shippingcost
            formik.values.is_digital = details.is_digital ? details.is_digital : 0
            setLoading(false)
            setReload(!reload)
        }
    }, [edit_product.product_details])
    useEffect(() => {
        if (formik.values.category_id) {
            setSubCategory(categories.find((val) => val.id == formik.values.category_id)?.filters)
        }
    }, [formik.values.category_id])
    //console.log(formik.values, 'this is formik')
    var productListing = [
        {
            label: 'Lisiting Title *',
            type: 'text',
            placeholder: 'Enter your Listing Title',
            class: 'col-12',
            name: 'protitle',
            formik: formik,
        },
        /*  {
            label: 'Category *',
            placeholder: 'Select category',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: categories.map((val) => ({
                show: val.name,
                value: val.id,
            })),
            defaultOptionValue: 'Select a Category',
            name: 'category_id',
            formik: formik,
        },
        {
            label: 'Sub-Category',
            placeholder: 'Select sub-category',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: subCategory?.map((val) => ({
                show: val.name,
                value: val.id,
            })),
            defaultOptionValue: 'Select a Sub Category',
            name: 'subcategory',
            formik: formik,
        },*/
        {
            label: 'Description *',
            placeholder: 'Enter Description',
            type: 'textarea',
            class: 'col-12',
            name: 'description',
            formik: formik,
        },
        // {
        //     label: 'Condition *',
        //     placeholder: 'Select condition',
        //     type: 'select',
        //     class: 'col-sm-6 col-12',
        //     options: allCondition
        //         .filter(
        //             (maincat) =>
        //                 maincat.active === 1 ||
        //                 (formik.values.conditionTypeId &&
        //                     formik.values.conditionTypeId === maincat.id),
        //         )
        //         .map((location) => {
        //             let conditionChanged = {}
        //             conditionChanged.show = location.description
        //             conditionChanged.value = location.id
        //             return conditionChanged
        //         }),
        //     defaultOptionValue: 'Select a Condition',
        //     name: 'radcondition',
        //     formik: formik,
        // },
    ]
    if (
        global.storeConfigration?.global_quantity?.value == 0 ||
        global.storeConfigration?.global_quantity == undefined
    ) {
        productListing.push({
            label: 'Quantity *',
            type: 'number',
            placeholder: 'Enter Quantity',
            class: 'col-sm-6 col-12',
            name: 'qty',
            formik: formik,
        })
    }

    productListing.push(
        /*  {
            label: 'Low EST *',
            type: 'number',
            placeholder: 'Enter low est',
            class: 'col-sm-6 col-12',
            name: 'lowest',
            formik: formik,
        },
        {
            label: 'High EST *',
            type: 'number',
            placeholder: 'Enter high est',
            class: 'col-sm-6 col-12',
            name: 'highest',
            formik: formik,
        }, */
        // {
        //     label: 'Enable Auction',
        //     type: 'checkbox',
        //     class: 'col-6',
        //     name: 'auction',
        //     formik: formik,
        // },
        {
            label: 'SKU *',
            type: 'text',
            placeholder: 'SKU',
            class: 'col-sm-6 col-12',
            name: 'sku',
            formik: formik,
        },
        {
            label: 'Buy Now price',
            placeholder: 'Enter buy now price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'bprice',
            disabled: formik.values.buynow ? false : true,
            formik: formik,
        },
        /* {
            label: 'Auction start price',
            placeholder: 'Enter starting price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'sprice',
            disabled: formik.values.auction ? false : true,
            formik: formik,
        },
        {
            label: 'Auction reserve price',
            placeholder: 'Enter reserve price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'rprice',
            disabled: formik.values.auction ? false : true,
            formik: formik,
            // Make the following dynamic later on
            tooltiptitle:
                global.storeDetails?.theme == 7
                    ? 'If your item does not meet your reserve. 5% of the highest bid obtained will be charged to your account'
                    : '',
        }, */
    )
    // {
    //     label: 'Enable Buy Now',
    //     type: 'checkbox',
    //     class: 'col-6',
    //     name: 'buynow',
    //     formik: formik,
    // },
    // {
    //     label: 'Terms & conditions',
    //     placeholder: 'Enter terms & conditions',
    //     type: 'ckeditor',
    //     class: 'col-12',
    //     name: 'contenthead3',
    //     formik: formik,
    // },
    // {
    //     label: 'Shipping details',
    //     placeholder: 'Enter shipping details',
    //     type: 'ckeditor',
    //     class: 'col-12',
    //     name: 'contenthead4',
    //     formik: formik,
    // },
    // {
    //     label: 'Shipping',
    //     type: 'checkbox',
    //     class: `col-6`,
    //     name: 'hasshipping',
    //     formik: formik,
    // },
    if (
        global.storeConfigration?.shipping_need?.value == 0 ||
        global.storeConfigration?.shipping_need == undefined
    ) {
        productListing.push(
            {
                title: '',
                type: 'radio',
                noLabel: true,
                name: 'hasshipping',
                int: 1,
                class: 'col-12 mb-3',
                item: [
                    { id: 0, description: 'Local Pickup' },
                    { id: 1, description: 'Shipping' },
                ],
                formik: formik,
            },
            {
                label: 'Weight *',
                type: 'number',
                placeholder: 'Enter Weight',
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
                name: 'weight',
                formik: formik,
            },
            {
                label: 'Length *',
                type: 'number',
                placeholder: 'Enter Weight',
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
                name: 'length',
                formik: formik,
            },
            {
                label: 'Width *',
                type: 'number',
                placeholder: 'Enter Weight',
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
                name: 'width',
                formik: formik,
            },
            {
                label: 'Height *',
                type: 'number',
                placeholder: 'Enter Height',
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
                name: 'height',
                formik: formik,
            },
            {
                label: 'Domestic Flat Shipping Price *',
                type: 'text',
                placeholder: 'Enter your Shipping Price',
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-6`,
                name: 'shippingcost',
                formik: formik,
            },
            {
                type: 'radio',
                noLabel: true,
                name: 'is_digital',
                int: 1,
                class: `${formik.values.hasshipping == 1 ? '' : 'd-none'} col-6`,
                item: [
                    { id: 0, description: 'Cost' },
                    { id: 1, description: 'Percentage' },
                ],
                formik: formik,
            },
        )
    }
    productListing.push(
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_image',
            titleText: 'Upload product images *',
            innerText: 'Recommended dimensions: 500px * 500px',
            class: 'col-12',
            folder: 'product',
            multiple: true,
            reorder: true,
            accept: 'image/*',
        },
        {
            titleText: 'Upload product images',
            type: 'previousImages',
            formik: formik,
            reorder: true,
            name: 'product_existingimage',
            class: 'col-12',
            folder: 'product',
            deleteArray: 'product_deletedimage',
        },
    )

    useEffect(() => {
        setReload(!reload)
        //console.log(formik.values.product_existingimage, 'formik.values.product_existingimage')
    }, [formik.values.product_existingimage])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const validationBulkArray = Yup.object({
        csvdata: Yup.array().required('File required'),
    })

    const bulkFormik = useFormik({
        initialValues: {
            csvdata: [],
            market_status: '',
        },
        validationSchema: validationBulkArray,
        onSubmit: async (values) => {
            if (values.csvdata.length == 0) {
                setAlert('File required', 'error')
                return false
            }
            var items_limit = global.storeConfigration?.global_lot_items?.value
                ? global.storeConfigration?.global_lot_items?.value
                : 0

            if (parseInt(items_limit) > 0) {
                readXlsxFile(values.csvdata[0]).then((rows) => {
                    total_items_csv = total_items_csv + (rows.length - 1)
                    if (parseInt(total_items_csv) <= parseInt(item_count)) {
                        setAlert('Your Lot Limit Is Reached!', 'error')
                        return handleRedirectInternal(history, `dashboard/overview`)
                    } else {
                        let formValues = values
                        formValues.startdate = edit_auction.auction_details.date_added
                        formValues.enddate = edit_auction.auction_details.date_closed
                        let formDataSend = jsonToFormData(formValues)
                        bulkUploadInventory(formDataSend)
                        setLoading(true)
                    }
                })
            } else {
                let formValues = values
                formValues.startdate = edit_auction.auction_details.date_added
                formValues.enddate = edit_auction.auction_details.date_closed
                let formDataSend = jsonToFormData(formValues)
                bulkUploadInventory(formDataSend)
                setLoading(true)
            }
        },
    })

    const bulkListing = [
        {
            type: 'uploadDropZone',
            formik: bulkFormik,
            name: 'csvdata',
            titleText: 'Upload csv import file *',
            innerText: 'Recommended dimensions: 500px * 500px',
            class: 'col-12',
            folder: 'product',
            multiple: false,
            accept: [
                'text/csv',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
            ],
        },
    ]

    const downloadSample = () => window.open(`/sample/drop_shipper_listing_sample.xlsx`)

    return (
        <div className="postProductContainer">
            <Tabs value={value} onChange={handleChange} aria-label="buyer-dashboard">
                <Tab label={product_id ? 'Edit Product' : 'Upload Single Item'} {...a11yProps(0)} />
                {!product_id && <Tab label="Upload Bulk Items" {...a11yProps(1)} />}
            </Tabs>
            <TabPanel value={value} index={0}>
                <form className="pp-form" onSubmit={formik.handleSubmit}>
                    <div ref={lotRef}>
                        <div className="row">{mapData(productListing)}</div>

                        <div className="pp-btns-wrap">
                            {product_id ? (
                                <PrimaryButton
                                    type="button"
                                    label={loading ? 'Loading...' : 'Edit'}
                                    disabled={loading}
                                    onClick={() => {
                                        formik.handleSubmit()
                                    }}
                                />
                            ) : (
                                <div className="row">
                                    <div className="col-md-3">
                                        <PrimaryButton
                                            type="button"
                                            label={loading ? 'Loading...' : 'Save Draft & Add More'}
                                            disabled={loading}
                                            onClick={() => {
                                                formik.setFieldValue('market_status', 'draft')
                                                formik.handleSubmit()
                                                setAddMore(1)
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <PrimaryButton
                                            type="button"
                                            label={
                                                loading ? 'Loading...' : 'Publish Live & Add More'
                                            }
                                            disabled={loading}
                                            onClick={() => {
                                                formik.setFieldValue('market_status', 'open')
                                                formik.handleSubmit()
                                                setAddMore(1)
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <PrimaryButton
                                            type="button"
                                            label={loading ? 'Loading...' : 'Save Draft'}
                                            disabled={loading}
                                            onClick={() => {
                                                formik.setFieldValue('market_status', 'draft')
                                                formik.handleSubmit()
                                                setAddMore(0)
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <PrimaryButton
                                            type="button"
                                            label={loading ? 'Loading...' : 'Publish Live'}
                                            disabled={loading}
                                            onClick={() => {
                                                formik.setFieldValue('market_status', 'open')
                                                formik.handleSubmit()
                                                setAddMore(0)
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </form>{' '}
            </TabPanel>
            <TabPanel value={value} index={1}>
                <form className="pp-form">
                    <div>
                        <div className="row">{mapData(bulkListing)}</div>
                        <div className="pp-btns-wrap">
                            <div className="row">
                                <div className="col-md-3">
                                    <PrimaryButton
                                        type="button"
                                        label={loading ? 'Loading...' : 'Save Draft & Add More'}
                                        disabled={loading}
                                        onClick={() => {
                                            bulkFormik.setFieldValue('market_status', 'draft')
                                            bulkFormik.handleSubmit()
                                            setAddMore(1)
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <PrimaryButton
                                        type="button"
                                        label={loading ? 'Loading...' : 'Publish Live & Add More'}
                                        disabled={loading}
                                        onClick={() => {
                                            bulkFormik.setFieldValue('market_status', 'open')
                                            bulkFormik.handleSubmit()
                                            setAddMore(1)
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <PrimaryButton
                                        type="button"
                                        label={loading ? 'Loading...' : 'Save Draft'}
                                        disabled={loading}
                                        onClick={() => {
                                            bulkFormik.setFieldValue('market_status', 'draft')
                                            bulkFormik.handleSubmit()
                                            setAddMore(0)
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <PrimaryButton
                                        type="button"
                                        label={loading ? 'Loading...' : 'Publish Live'}
                                        disabled={loading}
                                        onClick={() => {
                                            bulkFormik.setFieldValue('market_status', 'open')
                                            bulkFormik.handleSubmit()
                                            setAddMore(0)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>{' '}
                <br />
                <PrimaryButton onClick={downloadSample} label="Download Sample" />
                To use (CSV format). Other supported file formats: XLSX, and Text (tab delimited).
            </TabPanel>
        </div>
    )
}

export default PostProduct
