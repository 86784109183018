import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './cards.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import Timer from '../../Product/common/timer'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import AddToCalendar from '../../Product/components/molecules/AddToCalendar'
import {
    currencyFormat,
    handleRedirectInternal,
    sellerNameFormat,
} from '../../Product/common/components'
import moment from 'moment-timezone'
import { Button, ListItem, Popover, Tooltip } from '@material-ui/core'
import { getImageurl } from '../../utils/commonFunctions'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import ReadMore from '../ReadMore'
import AuthContext from '../../Product/context/auth/authContext'
import SlidePanel from '../SlidePanel'
const GridItem = ({
    data,
    details,
    isFrame,
    timerTheme,
    // lotCount,
    auctionData,
    theme,
    noActionBtn,
    noviewbutton,
    cardTheme,
    remove_description,
}) => {
    const addDefaultSrc = (e) => {
        e.target.src = `${global?.storeDetails?.logoValue}`
    }
    const history = useHistory()
    const [time, setTime] = useState(new Date())
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setTime(new Date())
    //     }, 1000)
    //     return () => clearInterval(interval)
    // }, [])
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user } = authContext
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    const handleClose = () => {
        setSelectedProduct(null)
    }
    const handleClick = () => {
        handleRedirectInternal(history, 'gallery')
    }

    const getAllProducts = () => {
        return false
    }

    const [anchorDesc, setAnchorDesc] = useState(null)

    const handleDescriptionClose = () => {
        setAnchorDesc(null)
    }

    const handleDescriptionPopover = (event) => {
        setAnchorDesc(event.currentTarget)
    }

    return (
        <>
            {data ? (
                <SkeletonTheme key={data.id} color="#d6d6d6" highlightColor="#e8e8e8">
                    <div className={`card grid-card ${data.auction ? 'auciton' : 'buynow'}`}>
                        {data.file_name ? (
                            <div className="grid-img-wrap">
                                <div
                                    onClick={(e) => handleClick()}
                                    //target={isFrame ? '_blank' : ''}
                                >
                                    <LazyLoadImage
                                        src={getImageurl(data.store_id.toString(), data.file_name)}
                                        effect="blur"
                                        placeholderSrc="/assets/svg/imageLoading.svg"
                                        height="100%"
                                        width="100%"
                                        className="gridImage"
                                        onError={addDefaultSrc}
                                        alt={data.title}
                                    />
                                </div>
                                {/* {lotCount ? (
                                    <span className="lotCount">
                                        <span className="material-icons">view_carousel</span>
                                        {lotCount} lot{lotCount > 1 && 's'}
                                    </span>
                                ) : (
                                    ''
                                )} */}
                            </div>
                        ) : (
                            <Skeleton height={280} width={220} />
                        )}
                        <div className="card-body">
                            <div
                                className="list-title"
                                dangerouslySetInnerHTML={{
                                    __html: data.title,
                                }}
                            />
                            {cardTheme == 'multiseller_two' && (
                                <div className="gaInfo text-center">
                                    <label className="mb-1">Description:</label>
                                    <ReadMore
                                        data={data.description}
                                        className="listDesc"
                                        limit={50}
                                    />
                                    {/* <p
                                        className="listDesc"
                                        dangerouslySetInnerHTML={{ __html: data.description }}
                                    /> */}
                                </div>
                            )}
                            {theme == 'HeavyEquipment' && (
                                <div className="gaInfo">
                                    <h6>Auction Id: #{data.id}</h6>
                                    <p>
                                        <span>Start Date:</span>
                                        {moment(data.date_added)
                                            .tz('America/New_York')
                                            .format('MMM Do YYYY, h:mm a')}
                                    </p>
                                    <p>
                                        <span>End Date:</span>
                                        {moment(data.date_closed)
                                            .tz('America/New_York')
                                            .format('MMM Do YYYY, h:mm a')}
                                    </p>
                                    <p
                                        className="gaDesc"
                                        dangerouslySetInnerHTML={{ __html: data?.description }}
                                    ></p>
                                </div>
                            )}
                            {/* <div className="gaInfo">
                                {' '}
                                <h6>
                                    Buy Now Price:{'    '}
                                    {currencyFormat(parseFloat(data.bprice).toFixed(2))}
                                </h6>
                            </div>
                            <div className="gaInfo">
                                {' '}
                                <h6>
                                    Qty:{'    '}
                                    {data.qty}
                                </h6>
                            </div> */}
                            {remove_description == 1 ? null : (
                                <div className="gaInfo text-center">
                                    <label className="mb-1">Description:</label>
                                    <p
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onMouseEnter={handleDescriptionPopover}
                                        // onMouseLeave={handleDescriptionClose}
                                        className="listDesc"
                                        dangerouslySetInnerHTML={{ __html: data.description }}
                                    />

                                    <Popover
                                        id={'simple-menu'}
                                        open={Boolean(anchorDesc)}
                                        anchorEl={anchorDesc}
                                        onClose={handleDescriptionClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <div
                                            className="descPopup"
                                            onMouseLeave={handleDescriptionClose}
                                            dangerouslySetInnerHTML={{ __html: data.description }}
                                        />
                                    </Popover>

                                    {/* <ReadMore data={data.description} className="listDesc" limit={40} /> */}
                                    {/* <Tooltip
                                    open
                                    title={
                                        <div
                                            className="descToolTip"
                                            dangerouslySetInnerHTML={{ __html: data.description }}
                                        />
                                    }
                                    arrow
                                    placement="top"
                                >
                                    <p
                                        className="listDesc"
                                        dangerouslySetInnerHTML={{ __html: data.description }}
                                    />
                                </Tooltip> */}
                                </div>
                            )}

                            {!noActionBtn && (
                                <>
                                    {theme == 'HeavyEquipment' ? (
                                        <div className="gaCTA">
                                            {!noviewbutton && (
                                                <PrimaryButton
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            parseInt(
                                                                data?.event_enable
                                                                    ? data.event_enable
                                                                    : 0,
                                                            ) == 1
                                                                ? `eventAuction/${data.id}`
                                                                : details,
                                                        )
                                                    }
                                                >
                                                    View Details
                                                </PrimaryButton>
                                            )}
                                            <AddToCalendar data={data} />
                                        </div>
                                    ) : (
                                        <PrimaryButton
                                            onClick={() => {
                                                //if (isAuthenticated) {
                                                handleClick()
                                                //} else {
                                                //handleRedirectInternal(history, 'login')
                                                //}
                                            }}
                                        >
                                            Browse Buy Now Lots
                                        </PrimaryButton>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </SkeletonTheme>
            ) : null}

            <SlidePanel
                selectedLot={selectedProduct}
                handleClose={handleClose}
                type="buynow"
                from={'card'}
                getAllProducts={getAllProducts}
            />
        </>
    )
}

export default GridItem
