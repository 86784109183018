import React from 'react'
import './addBank.css'
import Layout from '../Layout'
import SellerOnboard from '../../../utils/CommonFunctionality/SellerOnboard'

const AddBank = () => {
    return (
        <Layout>
            <div className="postProductContainer">
                <h2 className="ppTitle">OnBoarding</h2>
                <SellerOnboard />
            </div>
        </Layout>
    )
}

export default AddBank
