import React from 'react'
import Skeletons from '../../Skeletons'

function ProductViewSkeleton() {
    return (
        <div className="productViewSkeleton">
            {/* <Skeletons type="banner" /> */}
            <div className="d-flex justify-content-between flex-wrap">
                <div className="pvsImage">
                    <div className="">
                        <Skeletons type="thumbnail" />
                        <Skeletons type="thumbnail" />
                        <Skeletons type="thumbnail" />
                    </div>
                    <Skeletons type="gridImage" />
                </div>
                <div className="pvsDetails">
                    <Skeletons type="title" />

                    <div className="pvsAct">
                        <Skeletons type="input" />
                        <Skeletons type="input" />
                    </div>
                    <div className="pvsInfo">
                        <Skeletons type="text" />
                        <Skeletons type="text" />
                    </div>
                    <Skeletons type="input" />
                    <Skeletons type="actionButton" />
                </div>
            </div>
        </div>
    )
}

export default ProductViewSkeleton
