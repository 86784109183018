import React from 'react'
import DashboardHeader from '../DashboardHeader'
import Layout from '../Layout'
import CommonPos from '../../../utils/CommonFunctionality/Pos'
import { Container } from '@material-ui/core'

const Pos = (props) => {
    return (
        // <Layout props={props}>
        //     <DashboardHeader />
        <Container className="db-bids-wrapper mt-4">
            <h2 className="mp-head">POS</h2>
            <CommonPos />
        </Container>
        // </Layout>
    )
}

export default Pos
