export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const GET_ALL_BID_HISTORY = 'GET_ALL_BID_HISTORY'
export const GET_SAVED_SEARCHES = 'GET_SAVED_SEARCHES'
export const LOT_PRODUCT_DTLS = 'LOT_PRODUCT_DTLS'
export const ALL_LOTS = 'ALL_LOTS'
export const DASHBOARD_MYBIDS = 'DASHBOARD_MYBIDS'
export const GET_ALL_SEARCH = 'GET_ALL_SEARCH'
export const GET_ALL_SELLERS = 'GET_ALL_SELLERS'
export const GET_SINGLE_INVOICE_DETAIL = 'GET_SINGLE_INVOICE_DETAIL'
export const AUCTION_PERCENT = 'AUCTION_PERCENT'
export const GET_ALL_LOT_AUCTIONS = 'GET_ALL_LOT_AUCTIONS'
