import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import AuthContext from '../../../Product/context/auth/authContext'
import PropTypes from 'prop-types'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
    jsonToFormData,
} from '../../../Product/common/components'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Layout from '../Layout'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import './PostProduct.css'
import { Box, Tab, Tabs, Typography } from '@material-ui/core'
import { allCondition } from '../../../utils'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const PostProduct = (props) => {
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const {
        postProduct,
        editProduct,
        edit_product,
        categories,
        bulkUploadInventory,
        getActiveUsers,
        all_active_users,
        getEditProduct,
        getOverAllCategories,
        responseStatus,
        clearResponse,
    } = productContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated, isAdmin, user } = authContext
    const [toHome, settoHome] = useState(false)
    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(false)
    const history = useHistory()

    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [subCategory, setSubCategory] = useState([])

    useEffect(() => {
        if (isAuthenticated) {
            if (user.role === '1') {
            } else {
                handleRedirectInternal(history, '')
            }
        }
    }, [isAuthenticated])
    const [toSellerDashboard, settoSellerDashboard] = useState(false)
    const [stepTwo, setstepTwo] = useState(false)
    const [stepThree, setstepThree] = useState(false)

    const validationArray = Yup.object({
        product_id: Yup.number(),
        auctionid: Yup.number(),
        category_id: Yup.string().required('Required!'),
        radcondition: Yup.string().required('Required!'),
        subcategory: Yup.string(),
        protitle: Yup.string().required('Required!'),
        auction: Yup.boolean().when('buynow', {
            is: false,
            then: Yup.boolean().oneOf([true], 'Enable Auction or Buy Now'),
        }),
        sprice: Yup.number().when('auction', {
            is: true,
            then: Yup.number().required('Required!').positive('Should be greater than 1'),
        }),
        rprice: Yup.number().when('auction', {
            is: true,
            then: Yup.number()
                .positive('Should be greater than 1')
                .min(Yup.ref('sprice'), 'Should be equal or greater than the starting price')
                .required('Required!'),
        }),
        enable_rprice: Yup.boolean(),
        selectedbuyers: Yup.array(),
        prev_rprice: Yup.number(),
        buynow: Yup.boolean(),
        bprice: Yup.number().when('buynow', {
            is: true,
            then: Yup.number()
                .required('Required!')
                .positive('Should be greater than 1')
                .when('auction', {
                    is: true,
                    then: Yup.number().moreThan(
                        Yup.ref('rprice'),
                        'Should be greater than the reserve price',
                    ),
                }),
        }),
        // startdate: Yup.string().required('Required!').nullable(),
        description: Yup.string().required('Required!'),
        qty: Yup.number().required('Required!').positive('Should be greater than 1'),
        tags: Yup.array(),
        // enddate: Yup.string().required('Required!').nullable(),
        state: Yup.string().required('Required!'),
        location: Yup.string().required('Required!'),
        city: Yup.string().required('Required!'),
        lotof: Yup.string(),
        paymentoptionresult: Yup.string(),
        contenthead1: Yup.string(),
        contenthead2: Yup.string(),
        contenthead3: Yup.string(),
        contenthead4: Yup.string(),
        contenthead5: Yup.string().required('Required!'),
        hasshipping: Yup.boolean(),
        sell_location: Yup.string().required('Required!'),
        localpickup: Yup.boolean(),
        weight: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').positive('Must be positive').required('Required!'),
        }),
        length: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').positive('Must be positive').required('Required!'),
        }),
        width: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').positive('Must be positive').required('Required!'),
        }),
        height: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').positive('Must be positive').required('Required!'),
        }),
        is_digital: Yup.number(),
        shippingcost: Yup.number('Numbers only!')
            .required('Required!')
            .when('is_digital', {
                is: 1,
                then: Yup.number().lessThan(101, 'Minimum % is 100'),
            }),
        uploadimages: Yup.array(),
        product_deletedimage: Yup.array(),
        product_image: Yup.array(),
        product_existingimage: Yup.array(),
        product_imagefile: Yup.array(),
    })

    const formik = useFormik({
        initialValues: {
            product_id: '',
            auctionid: '',
            category_id: '',
            radcondition: '',
            subcategory: '',
            protitle: '',
            auction: 1,
            buynow: 0,
            sprice: 0,
            rprice: 0,
            enable_rprice: 1,
            selectedbuyers: [],
            prev_rprice: 0,
            bprice: 0,
            // startdate: dateTimeFormatFunction(new Date()),
            description: '',
            qty: 1,
            tags: [],
            // enddate: dateTimeFormatFunction(),
            state: '',
            location: '',
            city: '',
            lotof: '',
            paymentoptionresult: '',
            contenthead1: 0,
            contenthead2: global.products_detail,
            contenthead3: global.products_terms,
            contenthead4: '',
            contenthead5: '',
            latestbid: 0,
            hasshipping: 0,
            sell_location: '',
            localpickup: 1,
            weight: 0,
            length: 0,
            width: 0,
            height: 0,
            uploadimages: [],
            product_deletedimage: [],
            product_image: [],
            product_existingimage: [],
            product_imagefile: [],
            shippingcost: 0,
            is_digital: 0,
            market_status: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            let formValues = values
            formValues.buynow = formValues.buynow ? 1 : 0
            formValues.auction = formValues.auction ? 1 : 0
            formValues.localpickup = formValues.localpickup ? 1 : 0
            formValues.hasshipping = formValues.hasshipping ? 1 : 0
            formValues.market_status = formik.values.market_status
                ? formik.values.market_status
                : 'draft'
            formValues.is_digital = parseInt(formValues.is_digital)
            let formDataSend = jsonToFormData(formValues)
            //console.log('formDataSend', formDataSend)
            if (props.match.params.id) {
                editProduct(formDataSend)
            } else {
                postProduct(formDataSend)
            }
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'success') {
                if (
                    responseStatus.from === 'postProduct' ||
                    responseStatus.from === 'editProduct' ||
                    responseStatus.from === 'bulkUploadInventory'
                ) {
                    if (formik.values.market_status === 'open') {
                        handleRedirectInternal(history, `${'sellerdashboard?page=' + 0}`)
                    } else {
                        handleRedirectInternal(history, `${'sellerdashboard?page=' + 4}`)
                    }
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (isAuthenticated) {
            if (props.match.params.id) {
                setLoading(true)
                getEditProduct({ product_id: props.match.params.id })
            } else {
                setLoading(false)
            }
            getOverAllCategories()
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (edit_product.product_details && props.match.params.id) {
            let details = edit_product.product_details
            formik.values.product_id = details.id ? details.id : ''
            formik.values.auctionid = details.auctionid ? details.auctionid : ''
            formik.values.category_id = details.category_id ? details.category_id : ''
            formik.values.radcondition = details.conditionunique ? details.conditionunique : ''
            formik.values.subcategory = details.subcategory ? details.subcategory : ''
            formik.values.protitle = details.title ? details.title : ''
            formik.values.latestbid = details.latestbid ? details.latestbid : ''
            formik.values.location = details.location ? details.location : ''
            formik.values.state = details.state ? details.state : ''
            formik.values.city = details.city ? details.city : ''
            formik.values.tags = details.tags ? details.tags.split(',') : []
            formik.values.lotof = details.lotof ? details.lotof : ''
            formik.values.auction = details.auction ? true : false
            formik.values.buynow = details.buynow ? true : false
            formik.values.sprice = details.sprice ? details.sprice : ''
            formik.values.rprice = details.rprice ? details.rprice : ''
            formik.values.enable_rprice = details.rprice && parseInt(details.rprice) > 0 ? 1 : 0
            formik.values.selectedbuyers = details.selectedbuyers
                ? details.selectedbuyers.split(',').map((i) => parseInt(i))
                : []
            formik.values.prev_rprice = details.rprice ? details.rprice : ''
            formik.values.bprice = details.bprice && details.buynow ? details.bprice : ''
            formik.values.startdate = details.date_added
                ? dateTimeFormatFunction(details.date_added, true)
                : dateTimeFormatFunction(new Date())
            formik.values.description = details.description ? details.description : ''
            formik.values.qty = details.qty ? details.qty : 1
            formik.values.enddate = details.date_closed
                ? dateTimeFormatFunction(details.date_closed, true)
                : dateTimeFormatFunction(new Date())
            formik.values.product_existingimage = edit_product.attachments.map((i) => i.src)
            formik.values.paymentoptionresult = details.paymentoptionresult
                ? details.paymentoptionresult
                : ''
            formik.values.market_status = details.market_status
            formik.values.contenthead1 = details.content_head1 ? details.content_head1 : 0
            formik.values.contenthead2 = details.content_head2
                ? details.content_head2
                : global.products_detail
            formik.values.contenthead3 = details.content_head3
                ? details.content_head3
                : global.products_terms
            formik.values.hasshipping = details.hasshipping ? true : false
            formik.values.sell_location = details.sell_location ? details.sell_location : 0
            formik.values.localpickup = details.localpickup ? true : false
            formik.values.weight = details.weight ? details.weight : 0
            formik.values.length = details.length ? details.length : 0
            formik.values.width = details.width ? details.width : 0
            formik.values.height = details.height ? details.height : 0
            formik.values.contenthead4 = details.content_head4 ? details.content_head4 : ''
            formik.values.contenthead5 = details.content_head5 ? details.content_head5 : ''
            formik.values.product_deletedimage = []
            formik.values.shippingcost = details.shippingcost
            formik.values.is_digital = details.is_digital ? details.is_digital : 0
            setLoading(false)
            setReload(!reload)
        }
    }, [edit_product.product_details])
    useEffect(() => {
        if (formik.values.category_id) {
            setSubCategory(categories.find((val) => val.id == formik.values.category_id)?.filters)
        }
    }, [formik.values.category_id])

    const productListing = [
        {
            label: 'Lisiting Title *',
            type: 'text',
            placeholder: 'Enter your Listing Title',
            class: 'col-12',
            name: 'protitle',
            formik: formik,
        },
        {
            label: 'Category *',
            placeholder: 'Select category',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: categories.map((val) => ({
                show: val.name,
                value: val.id,
            })),
            defaultOptionValue: 'Select a Category',
            name: 'category_id',
            formik: formik,
            // onChange: () => formik.setFieldValue('cat')
        },
        {
            label: 'Sub-Category',
            placeholder: 'Select sub-category',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: subCategory?.map((val) => ({
                show: val.name,
                value: val.id,
            })),
            defaultOptionValue: 'Select a Sub Category',
            name: 'subcategory',
            formik: formik,
        },
        {
            label: 'Description *',
            placeholder: 'Enter Description',
            type: 'textarea',
            class: 'col-12',
            name: 'description',
            formik: formik,
        },
        {
            label: 'Condition *',
            placeholder: 'Select condition',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allCondition
                .filter(
                    (maincat) =>
                        maincat.active === 1 ||
                        (formik.values.conditionTypeId &&
                            formik.values.conditionTypeId === maincat.id),
                )
                .map((location) => {
                    let conditionChanged = {}
                    conditionChanged.show = location.description
                    conditionChanged.value = location.id
                    return conditionChanged
                }),
            defaultOptionValue: 'Select a Condition',
            name: 'radcondition',
            formik: formik,
        },
        {
            label: 'Quantity *',
            type: 'number',
            placeholder: 'Enter Quantity',
            class: 'col-sm-6 col-12',
            name: 'qty',
            formik: formik,
        },
        // {
        //     label: 'Start date *',
        //     placeholder: 'Enter Start date',
        //     type: 'datetime',
        //     disablePast: true,
        //     class: 'col-sm-6 col-12',
        //     name: 'startdate',
        //     formik: formik,
        // },
        // {
        //     label: 'End Date *',
        //     placeholder: 'Enter End Date',
        //     disablePast: true,
        //     type: 'datetime',
        //     class: 'col-sm-6 col-12',
        //     name: 'enddate',
        //     minDate: dateTimeFormatFunction(formik.values.date_added),
        //     minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
        //         formik.values.date_added,
        //     )}`,

        //     formik: formik,
        // },

        {
            label: 'Enable Auction',
            type: 'checkbox',
            class: 'col-6',
            name: 'auction',
            formik: formik,
        },
        {
            label: 'Auction start price',
            placeholder: 'Enter starting price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'sprice',
            disabled: formik.values.auction ? false : true,
            formik: formik,
        },
        {
            label: 'Auction reserve price',
            placeholder: 'Enter reserve price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'rprice',
            disabled: formik.values.auction ? false : true,
            formik: formik,
        },
        {
            label: 'Enable Buy Now',
            type: 'checkbox',
            class: 'col-6',
            name: 'buynow',
            formik: formik,
        },
        {
            label: 'Buy Now price',
            placeholder: 'Enter buy now price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'bprice',
            disabled: formik.values.buynow ? false : true,
            formik: formik,
        },
        {
            label: 'Terms & conditions',
            placeholder: 'Enter terms & conditions',
            type: 'ckeditor',
            class: 'col-12',
            name: 'contenthead3',
            formik: formik,
        },
        {
            label: 'Shipping details',
            placeholder: 'Enter shipping details',
            type: 'ckeditor',
            class: 'col-12',
            name: 'contenthead4',
            formik: formik,
        },
        // {
        //     type: 'radio',
        //     noLabel: true,
        //     name: 'ship_type',
        //     int: 1,
        //     class: `col-12`,
        //     item: [
        //         { id: 0, description: 'Local Pickup' },
        //         { id: 1, description: 'Shipping' },
        //     ],
        //     formik: formik,
        // },
        {
            label: 'Local Pickup',
            type: 'checkbox',
            class: `col-6`,
            name: 'localpickup',
            formik: formik,
            disabled: formik.values.hasshipping ? true : false,
        },
        {
            label: 'Shipping',
            type: 'checkbox',
            class: `col-6`,
            name: 'hasshipping',
            formik: formik,
            disabled: formik.values.localpickup ? true : false,
        },
        {
            label: 'Weight *',
            type: 'number',
            placeholder: 'Enter Weight',
            class: `${formik.values.hasshipping ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
            name: 'weight',
            formik: formik,
        },
        {
            label: 'Length *',
            type: 'number',
            placeholder: 'Enter Weight',
            class: `${formik.values.hasshipping ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
            name: 'length',
            formik: formik,
        },
        {
            label: 'Width *',
            type: 'number',
            placeholder: 'Enter Weight',
            class: `${formik.values.hasshipping ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
            name: 'width',
            formik: formik,
        },
        {
            label: 'Height *',
            type: 'number',
            placeholder: 'Enter Height',
            class: `${formik.values.hasshipping ? '' : 'd-none'} col-md-3 col-sm-6 col-12`,
            name: 'height',
            formik: formik,
        },
        {
            label: 'Domestic Flat Shipping Price *',
            type: 'text',
            placeholder: 'Enter your Shipping Price',
            class: `${formik.values.hasshipping ? '' : 'd-none'} col-6`,
            name: 'shippingcost',
            formik: formik,
        },
        {
            type: 'radio',
            noLabel: true,
            name: 'is_digital',
            int: 1,
            class: `${formik.values.hasshipping ? '' : 'd-none'} col-6`,
            item: [
                { id: 0, description: 'Cost' },
                { id: 1, description: 'Percentage' },
            ],
            formik: formik,
        },
        {
            label: 'Address *',
            type: 'text',
            placeholder: 'Enter your address',
            class: 'col-sm-6 col-12',
            name: 'sell_location',
            formik: formik,
        },
        {
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: countryList.map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.location_id
                return busCategoryChanged
            }),
            name: 'location',
        },
        {
            label: 'State' + '*',
            placeholder: 'Select your state',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: stateList.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            }),
            name: 'state',
        },
        {
            label: 'City *',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-sm-6 col-12',
            name: 'city',
            formik: formik,
        },
        {
            label: 'Zip *',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-sm-6 col-12',
            name: 'contenthead5',
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_image',
            titleText: 'Upload product images *',
            innerText: 'Recommended dimensions: 500px * 500px',
            class: 'col-12',
            folder: 'product',
            multiple: true,
            accept: 'image/*',
        },
        {
            type: 'previousImages',
            formik: formik,
            name: 'product_existingimage',
            class: 'col-12',
            folder: 'product',
            deleteArray: 'product_deletedimage',
        },
    ]

    const getLocationData = () => {
        var body = {
            countryCode: formik.values.location,
        }
        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                //console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.location])

    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const validationBulkArray = Yup.object({
        csvdata: Yup.array().required('File required'),
    })

    const bulkFormik = useFormik({
        initialValues: {
            csvdata: [],
        },
        validationSchema: validationBulkArray,
        onSubmit: async (values) => {
            let formValues = values
            let formDataSend = jsonToFormData(formValues)
            //console.log('formDataSend', formDataSend)
            bulkUploadInventory(formDataSend)
        },
    })

    const bulkListing = [
        {
            type: 'uploadDropZone',
            formik: bulkFormik,
            name: 'csvdata',
            titleText: 'Upload csv import file *',
            innerText: 'Recommended dimensions: 500px * 500px',
            class: 'col-12',
            folder: 'product',
            multiple: false,
            accept: [
                'text/csv',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
            ],
        },
    ]

    const downloadSample = () => window.open(`/sample/drop_shipper_listing_sample.xlsx`)

    return (
        <Layout props={props}>
            <div className="postProductContainer">
                <h2 className="ppTitle">Post a product</h2>
                <Tabs value={value} onChange={handleChange} aria-label="buyer-dashboard">
                    <Tab label="Upload Single Item" {...a11yProps(0)} />
                    <Tab label="Upload Bulk Listing" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <form className="pp-form" onSubmit={formik.handleSubmit}>
                        <div>
                            <div className="row">{mapData(productListing)}</div>
                            <div className="pp-btns-wrap">
                                {(props.match.params.id &&
                                    parseInt(formik.values.auction) === 1 &&
                                    parseInt(formik.values.latestbid) > 0) ||
                                (props.match.params.id &&
                                    formik.values.market_status !== 'open') ? null : (
                                    <PrimaryButton type="submit" label="Submit" />
                                )}
                            </div>
                        </div>
                    </form>{' '}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <form className="pp-form" onSubmit={bulkFormik.handleSubmit}>
                        <div>
                            <div className="row">{mapData(bulkListing)}</div>
                            <div className="pp-btns-wrap">
                                <PrimaryButton type="submit" label="Submit" />
                            </div>
                        </div>
                    </form>{' '}
                    <br />
                    <PrimaryButton onClick={downloadSample} label="Download Sample" />
                    To use (CSV format). Other supported file formats: XLSX, and Text (tab
                    delimited).
                </TabPanel>
            </div>
        </Layout>
    )
}

export default PostProduct
