import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import MyBidsPanel from '../../../utils/CommonFunctionality/MyBidsPanel'

const MyBids = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="MY BIDS">
                <MyBidsPanel noTimer={true} redirect_product_view={1} />
            </DashboardLayout>
        </Layout>
    )
}

export default MyBids
