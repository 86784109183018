import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'
import ReadMoreReact from 'read-more-react'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import Timer from '../../../Product/common/timer'
import { capitalize, removeHTMLTags } from '../../../Product/common/components'
import { Button, Dialog } from '@material-ui/core'
// import ReactHtmlParser from 'react-html-parser'

const ProductAuctionTopComponent = (props) => {
    const [characterLimit, setCharacterLimit] = useState(100)
    const [activeDesc, setActiveDesc] = useState({
        name: null,
        value: null,
    })
    const [readMore, setReadMore] = useState({
        description: false,
        paymentDetails: false,
        shippingDetails: false,
        pickupDetails: false,
        termsDetails: false,
        returnsDetails: false,
    })

    const [showdescription, setShowDescription] = useState({
        description: false,
        payment: false,
        shipping: false,
        pickup: false,
        tc: false,
        returns: false,
    })
    const [image, setImage] = useState([])

    useEffect(() => {
        let imageUrl = `${
            props.auctionData.store_id
                ? process.env.REACT_APP_PRODUCT_IMAGE_URL
                : process.env.REACT_APP_BASE_URL + 'uploads/product/'
        }${props.auctionData.avatar}`
        setImage([{ original: imageUrl, thumbnail: imageUrl }])
    }, [props.auctionData])

    const handleReadMore = (name, value) => {
        setReadMore({
            ...readMore.description,
            [name]: !readMore.name,
        })
        setActiveDesc({ name: name, value: value })
    }

    const getDescription = (name, value) => {
        return (
            <>
                {' '}
                <p className="m-0">
                    {removeHTMLTags(value).substring(0, characterLimit)}
                    {!readMore[name] && removeHTMLTags(value).length > characterLimit
                        ? '...'
                        : null}
                </p>
                {removeHTMLTags(value).length > characterLimit && (
                    <a
                        className="readMore cursorDecoy mb-2"
                        onClick={() => handleReadMore(name, value)}
                    >
                        Read {readMore[name] ? 'less' : 'more'}
                    </a>
                )}
            </>
        )
    }

    return (
        <div className="media pv-media product-media-wrapper flex-wrap">
            <div className="media-left">
                {props.auctionData.avatar ? (
                    <ImageGallery
                        items={image}
                        thumbnailPosition="bottom"
                        showNav={false}
                        showBullets={false}
                        showFullscreenButton={true}
                        showPlayButton={false}
                        originalClass="pro-img img-fluid"
                        showThumbnails={false}
                    />
                ) : (
                    // <Skeleton  height={345} width={475} />

                    //  src = `${global?.storeDetails?.logoValue}`
                    <img src={`${global?.storeDetails?.logoValue}`} className="pro-img img-fluid" />
                )}
                {/* <img src={ `https://forwardapi.auctionsoftware.com/uploads/product/${props.auctionData.avatar}`} className="pro-img img-fluid"/> */}
            </div>
            {props.auctionData.title !== undefined ? (
                <div className="media-body">
                    <div className="row">
                        <div className="col-md-12 col-lg-8 col-xl-8">
                            <h2
                                className="pv-title mb-4"
                                dangerouslySetInnerHTML={{
                                    __html: capitalize(props.auctionData.title),
                                }}
                            />
                            {/* {props.auctionData.date_added ? (
                                <div className="pv-con">
                                    <span>Auction Start Date: </span>
                                    {moment(props.auctionData.date_added)
                                        .tz('America/New_York')
                                        .format('MMM Do YYYY, h:mm a')}{' '}
                                    ET
                                </div>
                            ) : (
                                <Skeleton width={80} />
                            )}
                            <div className="pv-con">
                                <span>Auction End Date: </span>
                                {moment(props.auctionData.date_closed)
                                    .tz('America/New_York')
                                    .format('MMM Do YYYY, h:mm a')}{' '}
                                ET
                            </div> */}
                            <div className="pv-con aucDesc mt-2">
                                <span>Auction Description:</span>
                            </div>
                            {getDescription('description', props.auctionData.description)}
                            {props.auctionData.store_comment_one ? (
                                <>
                                    <div className="pv-con aucDesc mt-2">
                                        <span>Payment Details:</span>
                                    </div>
                                    <div>
                                        {getDescription(
                                            'paymentDetails',
                                            props.auctionData.store_comment_one,
                                        )}
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                            {props.auctionData.store_comment_two ? (
                                <>
                                    <div className="pv-con aucDesc mt-2">
                                        <span>Shipping Details:</span>
                                    </div>
                                    <div>
                                        {getDescription(
                                            'shippingDetails',
                                            props.auctionData.store_comment_two,
                                        )}
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                            {props.auctionData.store_comment_three ? (
                                <>
                                    <div className="pv-con aucDesc mt-2">
                                        <span>Pick-Up Details:</span>
                                    </div>
                                    <div>
                                        {getDescription(
                                            'pickupDetails',
                                            props.auctionData.store_comment_three,
                                        )}
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                            {props.auctionData.store_comment_four ? (
                                <>
                                    <div className="pv-con aucDesc mt-2">
                                        <span>Terms & Condition:</span>
                                    </div>
                                    <div>
                                        {getDescription(
                                            'termsDetails',
                                            props.auctionData.store_comment_four,
                                        )}
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                            {props.auctionData.store_comment_five ? (
                                <>
                                    <div className="pv-con aucDesc mt-2">
                                        <span>Returns:</span>
                                    </div>
                                    <div>
                                        {getDescription(
                                            'returnDetails',
                                            props.auctionData.store_comment_five,
                                        )}
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-12 col-lg-4 col-xl-4 text-center-md">
                            <div className="timer-wrapper">
                                <div>
                                    <img src="/image/timer.svg" alt="" className="lv-timer-new" />
                                </div>
                                <div className="li-timer">
                                    <Timer
                                        date_added={props.auctionData.date_added}
                                        date_closed={props.auctionData.date_closed}
                                        withText={1}
                                        endText={'Ends in' + ':'}
                                        startText={'Starts in' + ':'}
                                        theme="multiseller"
                                    ></Timer>
                                </div>
                            </div>
                            {parseInt(props.auctionData.stage_id) > 0 ? (
                                <div className="ndCTA">
                                    <PrimaryButton
                                        label="Buy Tickets"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `ticketing/${props.auctionData.stage_id}/${props.auctionData.id}`,
                                            )
                                        }
                                    />
                                    <br></br>
                                </div>
                            ) : null}

                            <div className="ndCTA">
                                <PrimaryButton label="Browse lots" onClick={props.executeScroll} />
                                <div className="arrow">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="media-body">
                    <div className="row">
                        <div className="col-md-8">
                            <Skeleton count={10} />
                        </div>
                    </div>
                </div>
            )}

            <Dialog
                className="addInfoModal"
                open={readMore[activeDesc.name]}
                onClose={() =>
                    setReadMore({
                        ...readMore,
                        [activeDesc.name]: false,
                    })
                }
            >
                <div className="descriptionModal">
                    <Button
                        className="close"
                        onClick={() =>
                            setReadMore({
                                ...readMore,
                                [activeDesc.name]: false,
                            })
                        }
                    >
                        <span className="material-icons">close</span>
                    </Button>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: activeDesc.value,
                        }}
                    ></div>
                </div>
            </Dialog>
        </div>
    )
}

export default ProductAuctionTopComponent
