import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import CommonPos from '../../../utils/CommonFunctionality/Pos'

const Pos = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="POS">
                <CommonPos />
            </DashboardLayout>
        </Layout>
    )
}

export default Pos
