import React, { useContext, useEffect, useState } from 'react'
import ProductContext from '../../../Product/context/product/productContext'

const CurrencyConverterFormat = ({ amount }) => {
    const [convertedAmount, setConverterAmount] = useState('')
    const { currency } = useContext(ProductContext)

    useEffect(() => {
        if (
            global?.pluginConfiguration?.currency_converter?.enable == 1 &&
            currency !== '' &&
            currency !== 'USD'
        ) {
            let findCurrency = global?.currencies?.find((val) => currency === val.currency_code)
            setConverterAmount(
                ` (${findCurrency?.symbol} ${(
                    parseFloat(amount) * parseFloat(findCurrency?.usd_rate || 1)
                ).toUSFormat(2)})`,
            )
        } else {
            setConverterAmount('')
        }
    }, [currency, amount])
    return <div>{convertedAmount}</div>
}

export default CurrencyConverterFormat
