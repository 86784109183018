import { Box, Tab, Tabs } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import UserActivity from '../UserActivity'
import './feedback.css'
import FeedbackSeller from './Seller'
import FeedbackBuyer from './Buyer'
import { handleRedirectInternal } from '../../../Product/common/components'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            className="customTabsInner dashboard-tabs feedTabs"
            // component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}
function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const FeedbackFunction = ({ seller_id, lot_id, seller_only }) => {
    const { t } = useTranslation()

    const history = useHistory()
    const search = useLocation().search
    const params = new URLSearchParams(search)

    const [value, setValue] = useState(params.get('s') ? parseInt(params.get('s')) : 0)

    useEffect(() => {
        setValue(params.get('s') ? parseInt(params.get('s')) : 0)
    }, [window.location.search])
    const handleChange = (event, newValue) => {
        handleRedirectInternal(history, `dashboard/feedback?s=${newValue}`)
    }
    return (
        <>
            {seller_only ? (
                <FeedbackSeller />
            ) : (
                <>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="Feedback"
                        className="mt-4 liquidationDashTabs"
                    >
                        <Tab label={t('Buyer')} {...a11yProps(0)} />
                        <Tab label={t('Seller')} {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <UserActivity page="Feedback_Buyer" />
                        <FeedbackBuyer />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <UserActivity page="Feedback_seller" />
                        <FeedbackSeller />
                    </TabPanel>
                </>
            )}
        </>
    )
}

export default FeedbackFunction
