import React from 'react'
import './Registration.css'
import Layout from '../Layout'
import SignupFunction from '../../../utils/CommonFunctionality/SignupFunction'

const Registration = () => {
    return (
        <Layout>
            <div className="clearfix row regCnt">
                <div className="col-12">
                    <div className="loginFormBox">
                        <SignupFunction
                            title="Signup"
                            termsCondition={'https://asm-360.com/general-terms/'}
                            subTitle="Create your free account today"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Registration
