import React from 'react'

import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import SellerDashboard from '../../../utils/CommonFunctionality/SellerDashboard'

const SellerDashboardOverview = (props) => {
    return (
        <Layout>
            <DashboardLayout title="Dashboard" subtitle="Items" seller>
                <div className="db-bids-wrapper">
                    <SellerDashboard />
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default SellerDashboardOverview
