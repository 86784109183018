import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { handleRedirectInternal } from '../../../Product/common/components'
import UserActivity from '../UserActivity'
import ReferralEarned from './Earned'
import ReferralSpent from './Spent'
import Copy from '../Share/Copy'
import { useContext } from 'react'
import authContext from '../../../Product/context/auth/authContext'
const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            className="customTabsInner dashboard-tabs"
            // component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}
function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}
const ReferralFunction = () => {
    const { user } = useContext(authContext)
    const history = useHistory()
    const search = useLocation().search
    const params = new URLSearchParams(search)
    const referralLink = `${global?.storeDetails?.dmap_url?.replace(
        'search',
        '',
    )}sign-up?ref=${btoa(user?.email)}`
    const [value, setValue] = useState(params.get('s') ? parseInt(params.get('s')) : 0)
    const { t } = useTranslation()
    const handleChange = (event, newValue) => {
        handleRedirectInternal(history, `referral?s=${newValue}`)
    }
    useEffect(() => {
        setValue(params.get('s') ? parseInt(params.get('s')) : 0)
    }, [window.location.search])
    return (
        <div>
            {/* <p>
                {referralLink}
                <Copy text={referralLink} textOnly={true} />
            </p> */}
            {/* <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Refunde"
                className="mt-4 liquidationDashTabs"
            >
                <Tab label={t('Earned')} {...a11yProps(0)} />
                <Tab label={t('Spent')} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <UserActivity page="Referral_Earned" /> */}
            <ReferralEarned />
            {/* </TabPanel>
            <TabPanel value={value} index={1}>
                <UserActivity page="Referral_Spent" />
                <ReferralSpent />
            </TabPanel> */}
        </div>
    )
}

export default ReferralFunction
