import React, { useState, useContext, useEffect } from 'react'
import { mapData, handleRedirectInternal } from '../../../Product/common/components'
import { useFormik } from 'formik'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import './photoUpload.css'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import ProductContext from '../../../Product/context/product/productContext'

const PhotoUploader = (props) => {
    const productContext = useContext(ProductContext)
    const { imageBulkUpload } = productContext
    const history = useHistory()
    const imageValidation = Yup.object({
        product_image: Yup.array().test('file', 'Upload Valid file', function (value) {
            //console.log(value, 'this is input file')
            let fileName = value.map((ele) => {
                if (ele.name.split('_').length === 3) {
                    return true
                }
                return false
            })
            //console.log(fileName, 'this is file name')
            return fileName.every((v) => v === true)
        }),
    })
    const formik = useFormik({
        initialValues: {
            product_image: [],
        },
        validationSchema: imageValidation,
        onSubmit: async (values) => {
            const formData = new FormData()
            values.product_image.map((ele) => formData.append('product_image', ele))
            //console.log(values, formData, 'uploaded file')
            let res = await imageBulkUpload(formData)
            //console.log(res, 'this is ithe responc frrom the file')
            if (res.data.success) {
                handleRedirectInternal(history, 'sellerdashboard')
                formik.resetForm()
            }
        },
    })
    const productListing = [
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_image',
            titleText: '',
            innerText: 'Upload files or drag and drop image files here',
            class: '',
            folder: 'product',
            compressImages: false,
            multiple: true,
            accept: 'image/*',
        },
    ]
    return (
        <div className="bulk-upload my-2 px-2">
            <div className="imgeupload-inst">
                <p>
                    <Link to="/dashboard/requirements-for-uploading-photo">
                        See Requirements for Uploading Photos
                    </Link>
                    <br />
                    You can add images one-by-one, or use multiple images at once.
                </p>
                <p>
                    {`NOTE: Images should not contain any URL's, email addresses, contact information
                    or watermarks of any kind.`}
                </p>
                {/* <p> Naming your Photos </p> */}
                <p>
                    {`Rename your Photos - Rename your photo so it matches up with the Auction ID "_"
                    lot number (you can get this in My Auctions Tab) it represents. If you have
                    three images for Lot 1, Auction ID 200 name them like this:`}
                    <br />
                    200_1_1.jpg <br />
                    200_1_2.jpg <br />
                    200_1_3.jpg <br />
                    {`Please remember to always include the "_" as well as the ".jpg"`}
                </p>
            </div>

            <div className="">{mapData(productListing)}</div>
            {!!formik.values.product_image.length && (
                <>
                    {/* <div className="img-uploads">
                        <PrimaryButton
                            type="button"
                            label={'Upload Photos & Add More'}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        />
                    </div> */}
                    <div className="img-uploads">
                        <PrimaryButton
                            type="button"
                            label={'Upload Photos'}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
export default PhotoUploader
