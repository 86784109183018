import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import Layout from '../Layout'
import './PostProduct.css'
import {
    dateTimeFormatFunction,
    handleRedirectInternal,
    jsonToFormData,
    mapData,
} from '../../../Product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useHistory, Prompt } from 'react-router-dom'
import {
    getLocation,
    horseAges,
    horseColor,
    horseGait,
    horseGender,
    horseNonProfit,
    horseTypes,
} from '../../../utils'
import Loader from '../../../assets/loader'
import BidIncrement from './bidincrment'
import AlertContext from '../../../Product/context/alert/alertContext'
const PostProduct = (props) => {
    const [isBlocking, setIsBlocking] = useState(false)
    const [loading, setLoading] = useState(false)
    const [country, setCountry] = useState([])
    const [state, setState] = useState([])
    const [reload, setReload] = useState(false)
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const [bidincarr, setBidIncarr] = useState([])
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { isAuthenticated } = authContext
    const { postProduct, editProduct, edit_product, getEditProduct, responseStatus } =
        productContext
    const history = useHistory()
    const searchParams = new URLSearchParams(window.location.search.substring(1))
    const product_id = searchParams.get('id')
    const repost = searchParams.get('repost')
    const [Loaders, setLoaders] = useState(product_id ? false : true)
    const validationArray = Yup.object({
        protitle: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('Horse Name Required!'),
        }),
        // category_id: Yup.string().when('currentStep', {
        //     is: 1,
        //     then: Yup.string().required('Horse Type Required!'),
        // }),
        subcategory: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('Non Profit Required!'),
        }),
        customfield3: Yup.number().when('currentStep', {
            is: 1,
            then: Yup.number()
                .typeError('Only numbers are allowed!')
                .required('Registration Number Required!'),
        }),
        contenthead2: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('Age Required!'),
        }),
        // contenthead2: Yup.string().when('currentStep', {
        //     is: 1,
        //     then: Yup.string().required('Gait Required!'),
        // }),
        contenthead3: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('Gender Required!'),
        }),
        customfield1: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('Sire Required!'),
        }),
        customfield2: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('Dam Required!'),
        }),
        customfield4: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required("Dam's Sire Required!"),
        }),
        customfield5: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('Color Required!'),
        }),
        location: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('Country Required!'),
        }),
        /*  state: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('State Required!'),
        }), */
        state: Yup.string().when('isValid', {
            is: () => (state?.length > 0 ? true : false),
            then: Yup.string().required('State Required!'),
            otherwise: Yup.string().notRequired(),
        }),
        modelnumber: Yup.number().when('currentStep', {
            is: 1,
            then: Yup.number()
                .typeError('Only numbers are allowed!')
                .required('Earnings Required!'),
        }),
        description: Yup.string().when('currentStep', {
            is: 1,
            then: Yup.string().required('Description Required!'),
        }),
        // manufacturer: Yup.string().when('currentStep', {
        //     is: 1,
        //     then: Yup.string().required('Owner Name Required!'),
        // }),
        // auctionnotes: Yup.string().when('currentStep', {
        //     is: 1,
        //     then: Yup.string().required('Trainer Name Required!'),
        // }),
        // customfield7: Yup.string().when('currentStep', {
        //     is: 1,
        //     then: Yup.string()
        //         .trim()
        //         .matches(/^(\+|00)[0-9]{1,3}[0-9]{4,14}(?:x.+)?$/, 'Only numbers are allowed')
        //         .min(10, 'Invalid!')
        //         .max(14, 'Maximum 14 characters')
        //         .required('Owner Phone Required!'),
        // }),
        // tags: Yup.string().when('currentStep', {
        //     is: 1,
        //     then: Yup.string()
        //         .trim()
        //         .matches(/^(\+|00)[0-9]{1,3}[0-9]{4,14}(?:x.+)?$/, 'Only numbers are allowed')
        //         .min(10, 'Invalid!')
        //         .max(14, 'Maximum 14 characters')
        //         .required('Contact Phone Required!'),
        // }),
        // sell_location: Yup.string().when('currentStep', {
        //     is: 1,
        //     then: Yup.string()
        //         .email('Invalid email format')
        //         .max(250, 'Maximum 250 characters')
        //         .required('Contact Email Required!'),
        // }),
        // contenthead6: Yup.string().when('currentStep', {
        //     is: 1,
        //     then: Yup.string().required('Owner Statement Required!'),
        // }),
        sprice: Yup.number().when('currentStep', {
            is: 2,
            then: Yup.number().when('auctiontype', {
                is: 'auction',
                then: Yup.number()
                    .required('Start Price Required!')
                    .positive('Should be greater than 1')
                    .min(1, 'Should be greater than 1'),
            }),
        }),
        rprice: Yup.number().when('currentStep', {
            is: 2,
            then: Yup.number().when('auctiontype', {
                is: 'auction',
                then: Yup.number()
                    .positive('Should be greater than 1')
                    .min(Yup.ref('sprice'), 'Should be equal or greater than the starting price')
                    .required('Reserve Price Required!'),
            }),
        }),
        bprice: Yup.number().when('currentStep', {
            is: 2,
            then: Yup.number().when('auctiontype', {
                is: 'buynow',
                then: Yup.number()
                    .required('Buynow Price Required!')
                    .positive('Should be greater than 1'),
            }),
        }),
        // startdate: Yup.string().when('currentStep', {
        //     is: 2,
        //     then: Yup.string().required('Start Data Required!').nullable(),
        // }),
        // enddate: Yup.string().when('currentStep', {
        //     is: 2,
        //     then: Yup.string().required('End Data Required!').nullable(),
        // }),
        // work_loc: Yup.array().when('currentStep', {
        //     is: 3,
        //     then: Yup.array().min(1, 'Veterinarian Documents Required!'),
        // }),
        // image: Yup.array().when('currentStep', {
        //     is: 3,
        //     then: Yup.array().min(1, 'X-ray Documents Required!'),
        // }),
        // product_image: Yup.array().when('currentStep', {
        //     is: 3,
        //     then: Yup.array().min(1, 'Product Images Required!'),
        // }),
        // document_field_1: Yup.array().when('currentStep', {
        //     is: 3,
        //     then: Yup.array().min(1, 'Black Type Pedigree Required!'),
        // }),
    })
    const formik = useFormik({
        initialValues: {
            auction_io: 1,
            product_id: '',
            saletype: 'auction_io',
            store_id: 0,
            currentStep: 1,
            protitle: '',
            category_id: '',
            radcondition: '',
            contenthead1: '0',
            contenthead2: '',
            contenthead3: '',
            contenthead4: '',
            contenthead5: '',
            contenthead6: '',
            customfield1: '',
            customfield2: '',
            customfield3: '',
            customfield4: '',
            customfield5: '',
            customfield6: 0,
            customfield7: '+1',
            //customfield8: '',
            customfield9_video_url: '',
            location: '',
            state: '',
            isValid: true,
            manufacturer: '',
            auctionnotes: '',
            tags: '+1',
            sell_location: '',
            description: '',
            modelnumber: '',
            subcategory: '',
            sprice: '',
            rprice: '',
            bprice: '',
            enable_rprice: 0,
            startprice: '',
            startdate: new Date(),
            enddate: new Date(),
            auctiontype: 'auction',
            auction: 1,
            buynow: 0,
            biddeposit: '',
            rptype: '',
            transactionid: '',
            paymenttype: '',
            paymentfor: '',
            product_id: '',
            auctionid: '',
            selling_type: 0,
            work_loc: [],
            existing_work_loc: [],
            deleted_work_loc: [],
            product_image: [],
            product_existingimage: [],
            product_deletedimage: [],
            product_videos: [],
            product_existing_videos: [],
            product_deletedvideos: [],
            image: [],
            existing_image: [],
            deleted_image: [],
            document_field_1: [],
            existing_document_field_1: [],
            deleted_document_field_1: [],
            start_price_for_lot: 1,
            cprice_for_lot: 0,
            enable_bid_increment: 0,
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            handleNext()
        },
    })
    useEffect(() => {
        if (isAuthenticated) {
            if (product_id) {
                getEditProduct({ product_id })
            }
        }
    }, [isAuthenticated])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'postProduct' || responseStatus.from === 'editProduct') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    // window.onbeforeunload = undefined
                    handleRedirectInternal(history, 'dashboard/mylots')
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (formik.values.enddate && formik.values.startdate) {
            if (moment(formik.values.enddate).isSameOrBefore(formik.values.startdate)) {
                formik.values.enddate = null
                return setReload(!reload)
            }
        }
    }, [formik.values.startdate, formik.values.enddate])
    useEffect(() => {
        if (formik.values.location) {
            getLocation(formik.values.location, setState)
        } else {
            getLocation('', setCountry)
        }
    }, [formik.values.location])
    // useEffect(() => {
    //     setIsBlocking(formik.dirty)
    // }, [formik.dirty])
    // useEffect(() => {
    //     if (isBlocking) {
    //         window.onbeforeunload = () => true
    //     } else {
    //         window.onbeforeunload = undefined
    //     }
    // }, [isBlocking])
    useEffect(() => {
        if (formik.values.auctiontype === 'auction') {
            formik.setFieldValue('auction', 1)
            formik.setFieldValue('buynow', 0)
        }
        if (formik.values.auctiontype === 'buynow') {
            formik.setFieldValue('auction', 0)
            formik.setFieldValue('buynow', 1)
        }
    }, [formik.values.auctiontype])
    useEffect(() => {
        if (edit_product.product_details && product_id) {
            let details = edit_product.product_details
            let loopData = {
                product_id: details.id,
                auctionid: details.auctionid,
                category_id: details.category_id,
                radcondition: details.conditionunique,
                subcategory: details.subcategory,
                protitle: details.title,
                latestbid: details.latestbid,
                location: details.location,
                state: details.state,
                city: details.city,
                tags: details.tags,
                auction: details.auction,
                buynow: details.buynow,
                sprice: details.sprice,
                rprice: details.rprice,
                bprice: details.bprice,
                startdate: details.date_added
                    ? dateTimeFormatFunction(details.date_added, true)
                    : dateTimeFormatFunction(new Date()),
                enddate: details.date_closed
                    ? dateTimeFormatFunction(details.date_closed, true)
                    : dateTimeFormatFunction(new Date()),
                customfield1: details.custom_field_1,
                customfield2: details.custom_field_2,
                customfield3: details.custom_field_3,
                customfield4: details.custom_field_4,
                customfield5: details.custom_field_5,
                customfield6: details.custom_field_6,
                customfield7: details.custom_field_7,
                //customfield8: details.custom_field_8,
                customfield9_video_url: details.custom_field_9,
                market_status: details.market_status,
                contenthead1: details.content_head1,
                contenthead2: details.content_head2,
                contenthead3: details.content_head3,
                sell_location: details.sell_location,
                description: details.description,
                subcategory: details.subcategory,
                contenthead1: details.content_head1,
                contenthead2: details.content_head2,
                contenthead3: details.content_head3,
                //contenthead4: details.content_head4,
                contenthead5: details.content_head5,
                contenthead6: details.content_head6,
                modelnumber: details.modelnumber,
                manufacturer: details.manufacturer,
                auctionnotes: details.auctionnotes,
                product_deletedimage: [],
                product_deletedvideos: [],
                deleted_image: [],
                deleted_work_loc: [],
                deleted_document_field_1: [],
                product_existingimage: !repost ? edit_product.attachments.map((i) => i.src) : [],
                product_existing_videos: !repost && details.videos ? details.videos.split(',') : [],
                existing_image: !repost && details.image ? details.image.split(',') : [],
                existing_work_loc: !repost && details.work_loc ? details.work_loc.split(',') : [],
                existing_document_field_1:
                    !repost && details.document_field_1 ? details.document_field_1.split(',') : [],
                currentStep: 1,
                auctiontype: details.auction
                    ? 'auction'
                    : details.buynow
                    ? 'buynow'
                    : 'contactseller',
                saletype: 'auction_io',
                store_id: 0,
                work_loc: [],
                document_field_1: [],
                product_image: [],
                product_videos: [],
                image: [],
                cprice_for_lot: details.latestbid ? details.latestbid : '',
                start_price_for_lot: details.sprice ? details.sprice : 1,
                //enable_bid_increment: details.content_head4 ? 1 : 0,
            }
            Object.keys(formik.values).map((val) => {
                formik.setFieldValue(val, loopData[val])
            })
            // console.log('JSON.parse(details.content_head4)', JSON.parse(details.content_head4))
            // setBidIncarr(details.content_head4 ? JSON.parse(details.content_head4) : [])
            setLoaders(true)
        }
    }, [edit_product.product_details])
    const LiveStockListing = [
        {
            label: 'Name of the Horse',
            type: 'text',
            placeholder: 'Name of the Horse',
            class: 'col-12 col-md-6',
            name: 'protitle',
            formik: formik,
        },
        {
            label: 'Registration Number',
            type: 'text',
            placeholder: 'Enter registration number',
            class: 'col-12 col-md-4',
            name: 'customfield3',
            formik: formik,
        },
        {
            label: 'Non Profit',
            placeholder: 'Select non profit',
            type: 'select',
            class: 'col-12 col-md-2',
            options: horseNonProfit,
            defaultOptionValue: 'Select a non profit',
            name: 'subcategory',
            formik: formik,
        },
        {
            label: 'Age',
            placeholder: 'Select Age',
            type: 'select',
            class: 'col-12 col-md-4',
            options: horseAges,
            defaultOptionValue: 'Select Age',
            name: 'contenthead2',
            formik: formik,
        },
        // {
        //     label: 'Gait',
        //     placeholder: 'Select Gait',
        //     type: 'select',
        //     class: 'col-12 col-md-4',
        //     options: horseGait,
        //     defaultOptionValue: 'Select a Category',
        //     name: 'contenthead2',
        //     formik: formik,
        // },
        {
            label: 'Gender',
            placeholder: 'Select Gender',
            type: 'select',
            class: 'col-12 col-md-4',
            options: horseGender,
            defaultOptionValue: 'Select a Category',
            name: 'contenthead3',
            formik: formik,
        },
        {
            label: 'Sire',
            placeholder: "Enter Sire's Name - Father",
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'customfield1',
            formik: formik,
        },
        {
            label: 'Dam',
            placeholder: "Enter Dam's Name - Mother",
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'customfield2',
            formik: formik,
        },
        {
            label: "Dam's Sire",
            placeholder: "Enter Dam's Sire's Name - Grandfather",
            type: 'text',
            class: 'col-12 col-md-4',
            name: 'customfield4',
            formik: formik,
        },
        {
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class: 'col-12 col-md-4',
            type: 'select',
            options: country,
            name: 'location',
            formik: formik,
        },
        {
            label: 'State' + '*',
            placeholder: 'Select your state',
            class: 'col-12 col-md-4',
            type: 'select',
            options: state,
            name: 'state',
            formik: formik,
            disabled: state.length > 0 ? false : true,
        },
        {
            label: 'Color' + '*',
            placeholder: 'Select horse color',
            class: 'col-12 col-md-4',
            type: 'select',
            options: horseColor,
            name: 'customfield5',
            formik: formik,
        },
        {
            label: 'Earnings',
            placeholder: 'Enter Earnings',
            type: 'text',
            startAdornment: '$',
            class: 'col-12 col-md-3',
            name: 'modelnumber',
            formik: formik,
        },
        // {
        //     label: 'Type of horse',
        //     placeholder: 'Select Horse Type',
        //     class: 'col-12 col-md-3',
        //     type: 'select',
        //     options: horseTypes,
        //     name: 'category_id',
        //     formik: formik,
        // },
        {
            label: 'Description *',
            placeholder: 'Enter Description',
            type: 'ckeditor1',
            class: 'col-12',
            name: 'description',
            formik: formik,
        },
    ]

    const OwnerDetails = [
        {
            label: 'Owner Name',
            placeholder: 'Enter your owner name',
            class: 'col-12 col-md-6',
            type: 'text',
            name: 'manufacturer',
            formik: formik,
        },
        {
            label: 'Trainer Name',
            placeholder: 'Enter your trainer name',
            class: 'col-12 col-md-6',
            type: 'text',
            name: 'auctionnotes',
            formik: formik,
        },
        {
            label: 'Owners Phone',
            placeholder: '1234567890',
            class: 'col-12 col-md-4',
            type: 'text',
            name: 'customfield7',
            formik: formik,
        },
        {
            label: 'Contact Phone',
            placeholder: '1234567890',
            class: 'col-12 col-md-4',
            type: 'text',
            name: 'tags',
            formik: formik,
        },
        {
            label: 'Contact Email',
            placeholder: 'Enter email',
            class: 'col-12 col-md-4',
            type: 'text',
            name: 'sell_location',
            formik: formik,
        },
        {
            title: 'Preferred method of communication',
            type: 'radio',
            noLabel: false,
            name: 'customfield6',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'Text' },
                { id: 1, description: 'Phone' },
                { id: 2, description: 'Email' },
            ],
            formik: formik,
        },
        {
            label: `Owner's Statement`,
            placeholder: 'Enter description',
            class: 'col-12 col-md-12',
            type: 'textarea',
            name: 'contenthead6',
            rows: 4,
            formik: formik,
        },
    ]

    const BidDetails = [
        {
            title: 'Selling Type',
            type: 'radio',
            noLabel: false,
            name: 'auctiontype',
            class: 'col-12 mb-3',
            item: [
                { id: 'auction', description: 'Auction' },
                { id: 'buynow', description: 'Buy Now' },
                // { id: 'contactseller', description: 'Contact Seller' },
            ],
            formik: formik,
        },
        {
            label: 'Start Bid',
            placeholder: 'Enter Start Bid',
            type: 'text',
            startAdornment: '$',
            class: formik.values.auctiontype == 'auction' ? 'col-12 col-md-4' : 'd-none',
            name: 'sprice',
            formik: formik,
            disabled: formik.values.cprice_for_lot ? true : false,
        },
        {
            label: 'Reserve Price',
            placeholder: 'Enter Reserve Price',
            type: 'text',
            startAdornment: '$',
            class: formik.values.auctiontype == 'auction' ? 'col-12 col-md-4' : 'd-none',
            name: 'rprice',
            formik: formik,
        },
        {
            label: 'Buy Now Price',
            placeholder: 'Enter Buy Now Price',
            type: 'text',
            startAdornment: '$',
            class: formik.values.auctiontype == 'buynow' ? 'col-12 col-md-4' : 'd-none',
            name: 'bprice',
            formik: formik,
        },
        {
            label: 'Start Date',
            placeholder: 'Enter Start Date',
            type: 'datetime',
            startAdornment: '$',
            class: formik.values.auctiontype == 'buynow' ? 'd-none' : 'col-12 col-md-4',
            name: 'startdate',
            formik: formik,
            disabled: formik.values.cprice_for_lot ? true : false,
        },
        {
            label: 'End Date',
            placeholder: 'Enter End Date',
            type: 'datetime',
            startAdornment: '$',
            class: formik.values.auctiontype == 'buynow' ? 'd-none' : 'col-12 col-md-4',
            name: 'enddate',
            formik: formik,
            disabled: formik.values.cprice_for_lot ? true : false,
        },
        {
            label: 'Enable Bid Increment',
            class: formik.values.auctiontype == 'buynow' ? 'd-none' : 'col-12',
            type: 'checkbox',
            name: 'enable_bid_increment',
            formik: formik,
        },
    ]

    const Uploads = [
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'work_loc',
            titleText: 'Veterinarian Documents Upload',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">note_add</span>
                    <br />
                    <p className="mb-2 mt-2">
                        <b>
                            <span>Upload Veterinarian Document</span>
                        </b>
                    </p>
                    <span>Recommended format: .docx, .doc, .pdf</span>
                </div>
            ),
            class: 'col-md-6 col-12',
            folder: '',
            multiple: true,
            accept: [
                'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                'application/msword',
                'application/pdf',
            ],
            compressImages: false,
        },
    ]

    const Uploadsxray = [
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'image',
            titleText: 'X-Ray Documents Upload',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">post_add</span>
                    <br />
                    <p className="mb-2 mt-2">
                        <b>
                            <span>Upload X-Ray Document</span>
                        </b>
                    </p>
                    <span>Recommended format: .jpeg, .jpg, .png, .docx, .doc, .pdf</span>
                </div>
            ),
            class: 'col-md-6 col-12',
            folder: '',
            multiple: true,
            accept: [
                'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                'application/msword',
                'application/pdf',
                'image/gif,image/*',
            ],
            compressImages: false,
        },
        {
            type: 'previousImages',
            formik: formik,
            name: 'existing_work_loc',
            class: 'col-md-6 col-12',
            folder: '',
            deleteArray: 'deleted_work_loc',
        },
        {
            type: 'previousImages',
            formik: formik,
            name: 'existing_image',
            class: 'col-md-6 col-12',
            folder: '',
            deleteArray: 'deleted_image',
        },
    ]

    const Uploadsproductimages = [
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_image',
            titleText: 'Upload Horse Images',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>Upload Horse Images *</span>
                        </b>
                    </p>
                    <span>Recommended dimensions: 500px * 500px</span>
                </div>
            ),
            class: 'col-md-6 col-12',
            folder: 'product',
            multiple: true,
            reorder: true,
            accept: 'image/gif,image/*',
        },
    ]

    const UploadsproductVideos = [
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_videos',
            titleText: 'Upload Horse Videos',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">video_call</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>Upload Horse Video*</span>
                        </b>
                    </p>
                    <span>Recommended format: .mp4</span>
                </div>
            ),
            class: 'col-md-6 col-12',
            folder: 'product',
            multiple: true,
            reorder: true,
            accept: 'video/*',
            compressImages: false,
        },
        {
            type: 'previousImages',
            formik: formik,
            reorder: true,
            name: 'product_existingimage',
            class: 'col-md-6 col-12',
            folder: 'product',
            deleteArray: 'product_deletedimage',
        },
        {
            type: 'previousImages',
            formik: formik,
            name: 'product_existing_videos',
            class: 'col-md-6 col-12',
            folder: '',
            deleteArray: 'product_deletedvideos',
        },
    ]

    const blackType = [
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'document_field_1',
            titleText: 'Black Type Pedigree Upload',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">note_add</span>
                    <br />
                    <p className="mb-2 mt-2">
                        <b>
                            <span>Upload Black Type Pedigree Document</span>
                        </b>
                    </p>
                    <span>Recommended format: .docx, .doc, .pdf</span>
                </div>
            ),
            class: 'col-12',
            folder: '',
            multiple: true,
            accept: [
                'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                'application/msword',
                'application/pdf',
            ],
            compressImages: false,
        },
        {
            type: 'previousImages',
            formik: formik,
            name: 'existing_document_field_1',
            class: 'col-12',
            folder: '',
            deleteArray: 'deleted_document_field_1',
        },
        {
            label: `Enter Video URL`,
            placeholder: 'If you want to enter multiple url, use "," for seperate each url.',
            class: 'col-12 col-md-12',
            type: 'textarea',
            name: 'customfield9_video_url',
            rows: 4,
            formik: formik,
        },
    ]

    const handlePrev = () => {
        if (formik.values.currentStep !== 1) {
            formik.setFieldValue('currentStep', formik.values.currentStep - 1)
            window.scrollTo(0, 0)
        }
    }

    const handleNext = () => {
        if (formik.values.currentStep !== 3) {
            if (formik.values.enable_bid_increment && formik.values.currentStep == 2) {
                var bid_inc_arr = [],
                    big_error = 0,
                    pos = 0
                // console.log('innnnnnnnnnnnnnnnnnnnnnnn', formik.values.contenthead4)
                var set_bid_arr = formik.values.contenthead4
                set_bid_arr.map((val, index) => {
                    pos = parseInt(index) + 1
                    if (val.from == '') {
                        setAlert('Invalid Input for from field value at row' + pos, 'error')
                        big_error = 1
                        return false
                    } else if (val.to == '') {
                        setAlert('Invalid Input for to field value at row' + pos, 'error')
                        big_error = 1
                        return false
                    } else if (val.price == '') {
                        setAlert('Invalid Input for price field value at row' + pos, 'error')
                        big_error = 1
                        return false
                    } else {
                        bid_inc_arr.push({
                            bprice_range_from: parseInt(val.from),
                            bprice_range_to: parseInt(val.to),
                            per_price: parseInt(val.price),
                        })
                    }
                })
                if (big_error) {
                    return false
                } else {
                    if (product_id) {
                        formik.values.contenthead4 = JSON.stringify(bid_inc_arr)
                    } else {
                        formik.values.contenthead4 = JSON.stringify(bid_inc_arr)
                    }
                }
            } else {
                formik.values.contenthead4 = ''
            }
            formik.setTouched({})
            formik.setFieldValue('currentStep', formik.values.currentStep + 1)
            window.scrollTo(0, 0)
        } else {
            // console.log('formik submit post product', formik.values)
            let formValues = formik.values
            if (formValues.auctiontype == 'buynow') {
                formValues.no_cron = 1
            }
            if (product_id && !repost && formik.values.cprice_for_lot) {
                formValues.update_project_after_bid_reseller = 1
            }
            let formDataSend = jsonToFormData(formValues)
            if (product_id && !repost) {
                editProduct(formDataSend)
            } else {
                formDataSend.set('customfield9', formValues.customfield9_video_url)
                postProduct(formDataSend)
            }
            setLoading(true)
        }
    }

    return (
        <Layout props={props}>
            <Prompt
                when={isBlocking}
                message="Your work is not saved! Are you sure you want to leave ?"
            />
            <div className="LiveStockPosting customContainer pb-5">
                <h2 className="ppTitle">Post a Product</h2>
                <div className="steps-wrapper mb-5">
                    <div className={`step-wrap ${formik.values.currentStep === 1 ? 'active' : ''}`}>
                        <div className="step-circle">01</div>
                        <div>Horse Details</div>
                    </div>
                    <div>
                        <img src="/assets/images/fast-forward.png" alt="" className="ff-img" />
                    </div>
                    <div className={`step-wrap ${formik.values.currentStep === 2 ? 'active' : ''}`}>
                        <div className="step-circle">02</div>
                        <div>Auction Details</div>
                    </div>
                    <div>
                        <img src="/assets/images/fast-forward.png" alt="" className="ff-img" />
                    </div>
                    <div className={`step-wrap ${formik.values.currentStep === 3 ? 'active' : ''}`}>
                        <div className="step-circle">03</div>
                        <div>Uploads</div>
                    </div>
                </div>
                {Loaders ? (
                    <form onSubmit={formik.handleSubmit}>
                        {formik.values.currentStep === 1 ? (
                            <>
                                <div className="pstFldsOptn">
                                    <h3 className="mb-4">Horse Details</h3>
                                    <div className="row mt-3">{mapData(LiveStockListing)}</div>
                                </div>
                                <hr />
                                {/* <div className="pstFldsOptn">
                                <h3 className="mb-4">Owner Details</h3>
                                <div className="row mt-3">{mapData(OwnerDetails)}</div>
                            </div> */}
                            </>
                        ) : formik.values.currentStep === 2 ? (
                            <>
                                <div className="pstFldsOptn">
                                    <h3 className="mb-4">Bid Details</h3>
                                    <div className="row mt-3">{mapData(BidDetails)}</div>
                                    {formik.values.enable_bid_increment == 1 ? (
                                        <BidIncrement
                                            formik={formik}
                                            bidincarr1={bidincarr}
                                        ></BidIncrement>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </>
                        ) : formik.values.currentStep === 3 ? (
                            <>
                                <div className="pstFldsOptn">
                                    <h3 className="row">
                                        {mapData(Uploads)}
                                        {mapData(Uploadsxray)}
                                    </h3>
                                    <div className="row">
                                        {mapData(Uploadsproductimages)}
                                        {mapData(UploadsproductVideos)}
                                    </div>
                                    <div className="row">{mapData(blackType)}</div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}

                        <div className="d-flex align-items-center justify-content-between">
                            <SecondaryButton
                                disabled={formik.values.currentStep === 1 ? true : false}
                                label="Prev"
                                onClick={() => handlePrev()}
                            />
                            <PrimaryButton
                                label={formik.values.currentStep === 3 ? 'Submit' : 'Next'}
                                disabled={loading}
                                type="submit"
                            />
                        </div>
                    </form>
                ) : (
                    <div className="text-center">
                        <Loader />
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default PostProduct
