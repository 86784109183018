import React, { useEffect, useState, useContext } from 'react'
import MessageBody from './MessageBody'
import './Messages.css'
import UserCard from './UserCard'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'

const InvoiceMessage = ({
    project_id,
    backImage,
    chatUser = {},
    hide_message_box,
    hide_header,
}) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const { chat_history, getChatHistory } = buyerContext
    const { user } = authContext
    const [chated_history, setChatHistory] = useState([])
    const [chat_display, setChatDisplay] = useState(true)
    const [viewUsers, setViewUsers] = useState(false)
    const isMobile = useMediaQuery({
        query: '(max-width: 700px)',
    })

    const { t } = useTranslation()

    useEffect(() => {
        if (user) {
            if (Object.keys(chatUser).length) {
                getChatHistory({ chat_id: chatUser.id, project_id: project_id })
            }
        }
    }, [])

    useEffect(() => {
        if (chat_history?.length > 0) {
            setChatHistory(chat_history)
        } else {
            setChatHistory([])
        }
    }, [chat_history])

    return (
        <>
            {isMobile && (
                <Button className="mb-2" onClick={() => setViewUsers(!viewUsers)}>
                    <span className="material-icons mr-1">contacts</span> User List
                </Button>
            )}
            <div className="msgPgWrpr d-block">
                <div className="rtMsgWrpr">
                    {chat_display ? (
                        <MessageBody
                            chatHistory={chated_history}
                            chatUser={chatUser}
                            backImage={backImage ? backImage : ''}
                            project_id={project_id}
                            chat_display={chat_display}
                            hide_message_box={hide_message_box}
                            hide_header={hide_header}
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </>
    )
}

export default InvoiceMessage
