import React from 'react'
import './invoice.css'
import InvoiceComponent from '../../../utils/CommonFunctionality/Invoice/InvoiceFunction'
import Layout from '../Layout'

const Invoice = (props) => {
    return (
        <Layout props={props}>
            <InvoiceComponent props={props} downloadInvoice={true} noRefundMessage={1} />
        </Layout>
    )
}

export default Invoice
