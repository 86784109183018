import { Pagination, Rating } from '@material-ui/lab'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../assets/loader'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import authContext from '../../../Product/context/auth/authContext'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'

const FeedbackSeller = ({ seller_id, lot_id }) => {
    const { t } = useTranslation()
    const { getAllFeedback, all_feedback, updateTestimonial, responseStatus } =
        useContext(pluginContext)
    const { user } = useContext(authContext)

    const [loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)
    const [product, setProduct] = useState({})
    const [feedbackList, setFeedbackList] = useState([])
    const [body, setBody] = useState({
        perlimit: 12,
        page: 1,
        // user_id: user?.id,
        site_id: global?.storeDetails?.site_id,
        // lot_id: "",
        // auction_id: "",
        // seller_id: user?.id,
    })
    const onHandlePage = (event, value) => {
        setBody({ ...body, page: value })
    }
    const handleTestimonial = (item) => {
        setBtnLoading(true)
        updateTestimonial({
            include_testimonial: item.include_testimonial == 1 ? 0 : 1,
            id: item.id,
            site_id: global?.storeDetails?.site_id,
        })
    }
    useEffect(() => {
        if (user) {
            setLoading(true)
            getAllFeedback({ ...body, ...{ seller_id: user?.id } })
        }
    }, [body, user])
    useEffect(() => {
        setLoading(false)
        setFeedbackList(all_feedback)
    }, [all_feedback])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'updateTestimonial') {
                setBtnLoading(false)
                if (responseStatus.status === 'success') {
                    let temp = feedbackList
                    temp.map((val) => {
                        if (val.id == product?.id) {
                            val.include_testimonial = product.include_testimonial == 1 ? 0 : 1
                        }
                    })
                    setFeedbackList([...temp])
                }
            }
        }
    }, [responseStatus])
    // useEffect(() => {
    //     if (user) {
    //         setBody({ ...body, seller_id: user?.id })
    //     }
    // }, [user])

    return (
        <div className="text-center">
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : feedbackList.length ? (
                <div>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Lot Title</th>
                                    <th>Buyer Name</th>
                                    <th>Date</th>
                                    <th>Subject</th>
                                    <th>Message</th>
                                    <th>Rating</th>
                                    {global?.storeConfigration?.add_testimonial?.value == 1 ? (
                                        <th>Action</th>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {feedbackList.map((val, key) => (
                                    <tr key={key}>
                                        <td>{val.title}</td>
                                        <td>{val.buyer_name}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                            {moment(val.date_added).format('MM-DD-YYYY')}
                                        </td>
                                        <td>{val.subject}</td>
                                        <td>{val.message}</td>

                                        <td>
                                            <Rating
                                                name="read-only"
                                                value={val.rating}
                                                precision={0.5}
                                                readOnly
                                            />
                                        </td>
                                        {global?.storeConfigration?.add_testimonial?.value == 1 ? (
                                            <td>
                                                <PrimaryButton
                                                    type="button"
                                                    label={
                                                        btnLoading
                                                            ? 'Loading...'
                                                            : val.include_testimonial == 1
                                                            ? 'UNDO'
                                                            : 'ADD'
                                                    }
                                                    onClick={() => {
                                                        setProduct(val)
                                                        handleTestimonial(val)
                                                    }}
                                                />
                                            </td>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex mt-3 pagination-wrapper">
                            <div className="ml-auto page-showing">
                                {t('showing')} {body.perlimit * (body.page - 1) + 1} -{' '}
                                {body.perlimit * body.page > feedbackList.length
                                    ? feedbackList.length
                                    : body.perlimit * body.page}{' '}
                                of {feedbackList.length}
                            </div>
                            <Pagination
                                count={Math.ceil(feedbackList.length / body.perlimit)}
                                page={body.page}
                                onChange={onHandlePage}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <NoRecordsFound />
            )}
        </div>
    )
}

export default FeedbackSeller
