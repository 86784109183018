import { useFormik } from 'formik'
import React, { useState, useContext, useEffect, useRef } from 'react'
import { handleRedirectInternal, mapData, removeHTMLTags } from '../../../Product/common/components'
import { useHistory, useParams, useLocation, Link } from 'react-router-dom'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import AuthContext from '../../../Product/context/auth/authContext'
import UserContext from '../../../Product/context/user/userContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import VCModal from '../../../Component/Modal'

import './Bio.css'
import moment from 'moment'
import PropTypes from 'prop-types'
import BioCard from './BioCard'
import { socket } from '../../../Product/common/socket'
import { followHandler, messageHandler } from '../../../Product/common/socketHandler'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import {
    CircularProgress,
    Button,
    ButtonBase,
    FormControl,
    Box,
    ListItem,
    Tab,
    Tabs,
    Typography,
    Menu,
    MenuList,
    MenuItem,
} from '@material-ui/core'
import * as Yup from 'yup'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import ProductContext from '../../../Product/context/product/productContext'
import { useTranslation } from 'react-i18next'
import Loaders from '../../../Product/components/molecules/Loaders'
import ReadMore from '../../../Component/ReadMore'
import HeaderSearchComponent from '../../../Themes/ArtAuction/Header/HeaderSearch'
import { useMediaQuery } from 'react-responsive'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `home-auctions-${index}`,
        'aria-controls': `home-auctions-tabpanel-${index}`,
    }
}

const Bio = () => {
    const [showDescription, setShowDescription] = useState(false)
    const [reportPopup, setReportPopup] = useState(false)
    const [loading, setLoading] = useState(true)
    const [followed, setFollowed] = useState(true)
    const [userData, setUserData] = useState({})
    const [orderbysearch, setOrderbysearch] = useState(6)
    const [value, setValue] = useState(0)
    const [total, setTotal] = useState(0)
    const [viewProduct, setViewProduct] = useState([])
    const [userProductsParams, setUserProductsParams] = useState({ page: 1, limit: 10 })
    const [userProductsData, setUserProductsData] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [sortopen, setSortOpen] = React.useState(false)
    const productContext = useContext(ProductContext)
    const {
        search_allauctions,
        getAllSearchAuctions,
        search_allproducts,
        getAllSearchProducts,
        categories,
    } = productContext
    const { userDetails, loadUserDetails, userProducts, isAuthenticated, user } =
        useContext(AuthContext)
    const { follow, reportSeller, responseStatus, seller_list, getSellerList } =
        useContext(UserContext)
    const [sellers, setSellers] = useState([])
    const { setAlert } = useContext(AlertContext)
    const { t } = useTranslation()
    const { id } = useParams()
    const history = useHistory()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const user_type = params.get('type') ? params.get('type') : ''
    const isArtTheme = document.querySelector('body').classList.contains('artTheme')

    const isDesktop = useMediaQuery({
        query: '(min-width: 769px)',
    })
    const isMobile = useMediaQuery({
        query: '(max-width: 700px)',
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        //setLoading(true)
        const params = new URLSearchParams(location.search)
        const user_type = params.get('type') ? params.get('type') : ''
        setUserProductsParams({ page: 1, limit: 10 })
        loadUserDetails(
            user_type == 'Artist'
                ? {
                      user_id: id,
                      status: 'open,sold',
                      contenthead5: user_type == 'Artist' ? params.get('search') : '',
                      user: user?.id,
                      page: 1,
                      limit: 10,
                  }
                : {
                      user_id: id,
                      status: 'open,sold',
                      user: user?.id,
                      page: 1,
                      limit: 10,
                  },
        )
    }, [user, id])

    useEffect(() => {
        if (Object.keys(userDetails).length) {
            setFollowed(userDetails.followed)
            setUserData(userDetails)
            setLoading(false)
        }
    }, [userDetails])

    useEffect(() => {
        getSellerList({ limit: 100, page: 1, type: 1 })
    }, [id])

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        var search = params.get('q') ? params.get('q') : ''
        getAllSearchProducts(
            {
                sh_limit: 24,
                page: 1,
                orderby: '6',
                location: [],
                state: [],
                city: [],
                category: [],
                subcategory: [],
                condition: [],
                storedate: [],
                enddate: null,
                fromprice: 0,
                toprice: 0,
                totalpage: 5,
                lotof: '',
                searchbar: search,
                from: 'auction_only',
                closed_auction_list: 0,
                seller_id_follow: id,
            },
            'searchPage',
        )
    }, [window.location.search])

    useEffect(() => {
        if (seller_list?.data?.length > 0) {
            setSellers(seller_list.data.filter((val) => parseInt(val.id) != parseInt(id)))
        }
    }, [seller_list])

    useEffect(() => {
        setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
    }, [search_allproducts])

    useEffect(() => {
        setTotal(userProducts.total)
        if (userProductsParams.page === 1) {
            return setUserProductsData(userProducts.data)
        }
        setUserProductsData((prev) => [...prev, ...userProducts.data])
    }, [userProducts])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const validation = Yup.object({
        reportDescription: Yup.string().when('reportValue', {
            is: '6',
            then: Yup.string().required('Description is required!'),
        }),
    })

    const searchHandleClick = (e) => {
        e.preventDefault()
        // setSearchBar(searchkeyword)
    }

    const sortHandleClick = (event) => {
        setSortOpen(event.currentTarget)
    }

    const sortHandleClose = (val) => {
        console.log(
            'ppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp',
            val,
        )
        setOrderbysearch(val)
        getAllSearchProducts(
            {
                sh_limit: 24,
                page: 1,
                orderby: val,
                location: [],
                state: [],
                city: [],
                category: [],
                subcategory: [],
                condition: [],
                storedate: [],
                enddate: null,
                fromprice: 0,
                toprice: 0,
                totalpage: 5,
                lotof: '',
                searchbar: '',
                from: 'auction_only',
                closed_auction_list: 0,
                seller_id_follow: id,
            },
            'searchPage',
        )
        setSortOpen(null)
    }

    const formik = useFormik({
        initialValues: {
            reportValue: 1,
            reportDescription: '',
        },
        validationSchema: validation,
        onSubmit: (value) => {
            const body = {
                seller_id: id,
                message:
                    value.reportValue == 6 && value.reportDescription !== ''
                        ? value.reportDescription
                        : reportOption.find((val) => val.id == value.reportValue).description,
            }
            reportSeller(body)
        },
    })

    const userFollow = () => {
        if (!isAuthenticated) {
            return handleRedirectInternal(history, 'login')
        }
        follow({ follow: followed === 1 ? 0 : 1, user_id: id })
    }
    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }
    useEffect(() => {
        if (Object.keys(userDetails).length) {
            socket.on('follow_count', (data) => {
                followHandler(data, setUserData, user, id, setFollowed)
            })
        }
    }, [userDetails])
    useEffect(() => {
        //setLoading(false)
    }, [userProductsData])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'reportSeller') {
                if (responseStatus.status === 'success') {
                    formik.resetForm()
                    setReportPopup(false)
                }
            }
        }
    }, [responseStatus])
    const handleLoadMoreClick = () => {
        loadUserDetails({
            user_id: id,
            status: 'open,sold',
            user: user?.id,
            limit: 10,
            page: userProductsParams.page + 1,
        })
        setUserProductsParams((prev) => ({ ...prev, page: prev.page + 1 }))
    }

    const reportOption = [
        { id: 0, description: 'Local Pickup' },
        { id: 1, description: 'Adult Content' },
        { id: 2, description: 'Fraud/Scam' },
        { id: 3, description: 'Offensive or illegal' },
        { id: 4, description: 'Imminent physical harm' },
        { id: 5, description: 'Infringes my rights' },
        { id: 6, description: 'Other' },
    ]
    const reportData = [
        {
            title: '',
            type: 'radio',
            noLabel: true,
            name: 'reportValue',
            int: 1,
            class: 'col-12 mb-3',
            item: reportOption,
            formik: formik,
        },
        {
            label: 'Description',
            type: 'textarea',
            rows: 4,
            placeholder: 'Describe here',
            class: `${formik.values.reportValue == 6 ? 'col-12' : 'd-none'}`,
            name: 'reportDescription',
            formik: formik,
        },
    ]
    const viewProductRef = useRef(userProductsData)
    const userRef = useRef(user)

    const sortShow = [
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = userProductsData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setUserProductsData,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])
    return (
        <>
            {loading ? (
                <div className="homeLoader">
                    <Loaders name="product_grid_view" isLoading={loading} loop={9} />
                </div>
            ) : (
                <>
                    <div className={`sellerBio ${user_type == 'Artist' ? 'artistBio' : ''}`}>
                        <div className="customContainer">
                            {isArtTheme && (
                                <div className="mb-3 mt-4 mr-auto ml-auto artTistGrisSys">
                                    <div>
                                        <div
                                            className="goBackBTn"
                                            onClick={() => {
                                                handleRedirectInternal(history, 'seller/artist')
                                                // history.goBack('/seller/artist')
                                                window.scrollTo(0, 0)
                                            }}
                                        >
                                            <svg
                                                width="32"
                                                height="32"
                                                className="mr-2"
                                                viewBox="0 0 32 32"
                                            >
                                                <g transform="translate(-60 -71)">
                                                    <g
                                                        transform="translate(60 71)"
                                                        fill="none"
                                                        stroke="#0a0a0a"
                                                        strokeWidth="1"
                                                    >
                                                        <circle
                                                            cx="16"
                                                            cy="16"
                                                            r="16"
                                                            stroke="none"
                                                        />
                                                        <circle
                                                            cx="16"
                                                            cy="16"
                                                            r="15.5"
                                                            fill="none"
                                                        />
                                                    </g>
                                                    <path
                                                        d="M13.178,11.438a.664.664,0,0,1,.005.934L10.1,15.465H21.846a.66.66,0,0,1,0,1.32H10.1l3.087,3.092a.668.668,0,0,1-.005.934.657.657,0,0,1-.929-.005L8.07,16.592h0a.741.741,0,0,1-.137-.208.63.63,0,0,1-.051-.254.662.662,0,0,1,.188-.462l4.184-4.215A.647.647,0,0,1,13.178,11.438Z"
                                                        transform="translate(61.048 70.873)"
                                                    />
                                                </g>
                                            </svg>
                                            <u>Back To Artist</u>
                                        </div>
                                    </div>
                                    <div className="bioTabs">
                                        <Tabs
                                            value={value}
                                            className="mb-0"
                                            onChange={handleChange}
                                            aria-label="home-auctions"
                                        >
                                            <Tab label={t('Profile')} {...a11yProps(0)} />
                                            <Tab label={t('About')} {...a11yProps(1)} />
                                        </Tabs>
                                        {/* <ButtonBase>
                                    <Link
                                        button
                                        to={`/bio/${id}`}
                                        style={{ color: 'white' }}
                                        className="aTag"
                                    >
                                        <h4 className="pgTtle">{t('Profile')}</h4>
                                    </Link>
                                </ButtonBase>
                                <ButtonBase>
                                    <Link
                                        button
                                        to={`/bio/${id}`}
                                        style={{ color: 'white' }}
                                        className="aTag"
                                    >
                                        <h4 className="pgTtle">{t('About')}</h4>
                                    </Link>
                                </ButtonBase> */}
                                    </div>
                                    <div></div>
                                </div>
                            )}

                            {isArtTheme ? null : (
                                <>
                                    {user_type != 'Artist' && (
                                        <>
                                            {userData?.taxfile_org ? (
                                                <div
                                                    className="topBanner"
                                                    style={{
                                                        background: userData?.taxfile_org?.includes(
                                                            'seller_1',
                                                        )
                                                            ? `url(${global.images_url}${
                                                                  JSON.parse(userData.taxfile_org)
                                                                      .seller_1
                                                              })`
                                                            : 'url(https://images.unsplash.com/photo-1551043088-22eb8ac4cc48?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80)',
                                                    }}
                                                >
                                                    {/* <div className="customContainer">
                                            <Button
                                                className="artsBckBtn bioBackBtn"
                                                onClick={() => history.goBack()}
                                            >
                                                {' '}
                                                <span className="material-icons">
                                                    keyboard_backspace
                                                </span>{' '}
                                                {t('back')}
                                            </Button>
                                        </div> */}
                                                </div>
                                            ) : (
                                                <div
                                                    className="topBanner"
                                                    style={{
                                                        background:
                                                            'url(https://images.unsplash.com/photo-1551043088-22eb8ac4cc48?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80)',
                                                    }}
                                                >
                                                    <div className="customContainer">
                                                        {/* <Button
                                                className="artsBckBtn bioBackBtn"
                                                onClick={() => history.goBack()}
                                            >
                                                {' '}
                                                <span className="material-icons">
                                                    keyboard_backspace
                                                </span>{' '}
                                                {t('back')}
                                            </Button> */}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            <div className="btmDivCntnr">
                                <TabPanel value={value} index={0} className="w-100">
                                    {user_type != 'Artist' && (
                                        <div className="lftSlrInfo">
                                            <div className="slrPrflImg">
                                                <img
                                                    src={
                                                        userData.avatar
                                                            ? `${process.env.REACT_APP_BASE_URL}uploads/${userData.avatar}`
                                                            : `https://ui-avatars.com/api/?name=${
                                                                  userData.first_name?.split(' ')[0]
                                                              }+${
                                                                  userData.last_name?.split(' ')[0]
                                                              }`
                                                    }
                                                />
                                            </div>
                                            <div className="ArtistDetailsSec">
                                                <div className="d-flex align-items-start justify-content-start w-100 flex-wrap">
                                                    <div className="objectDeatais">
                                                        <h3 className="slrNm">
                                                            {userData.first_name +
                                                                ' ' +
                                                                userData.last_name}
                                                        </h3>
                                                        <h5 className="">{userData.user_type}</h5>
                                                    </div>
                                                    <div className="followCount">
                                                        <div className="flwDtlCntnr">
                                                            <div className="flwrsCount">
                                                                <p>
                                                                    {userData.follow_count}{' '}
                                                                    <span>{t('followers')}</span>
                                                                </p>
                                                            </div>
                                                            {user?.id == id ? null : (
                                                                <>
                                                                    {followed ? (
                                                                        <SecondaryButton
                                                                            label={<>unfollow</>}
                                                                            onClick={userFollow}
                                                                        />
                                                                    ) : (
                                                                        <PrimaryButton
                                                                            label={
                                                                                <>
                                                                                    <span className="material-icons">
                                                                                        add
                                                                                    </span>
                                                                                    follow
                                                                                </>
                                                                            }
                                                                            onClick={userFollow}
                                                                        />
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                        <p className="loctnInfo">
                                                            {userData.state ? (
                                                                <>
                                                                    <span className="material-icons">
                                                                        location_on
                                                                    </span>
                                                                    <span className="">
                                                                        {userData.state
                                                                            ? userData.state
                                                                            : '-' +
                                                                              ', ' +
                                                                              userData.city
                                                                            ? userData.city
                                                                            : '-'}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="abtMeCntnr mb-4">
                                                    <ReadMore
                                                        data={userData?.aboutme}
                                                        className=""
                                                        limit={400}
                                                    />
                                                </div>

                                                {/* <div className="wrkExprnce mb-4">
                                        <h5 className="sbHdr">WORK EXPERIENCE</h5>
                                        <div className="wrkExpDtl">
                                            <h5>Founder / Creative Director</h5>
                                            <p>Lobster Phone — San Francisco, CA, USA</p>
                                        </div>
                                        <div className="wrkExpDtl">
                                            <h5>Founder / Creative Director</h5>
                                            <p>Lobster Phone — San Francisco, CA, USA</p>
                                        </div>
                                    </div>
                                        <div className="tlsUsd mb-4">
                                            <h5 className="sbHdr">TOP TOOLS USED</h5>
                                            <div className="tlDtls">Tool 1</div>
                                        </div> */}
                                                {/* <div className="mbrSnce">
                                            {t('member')}:{' '}
                                            {moment(userData.created_at).format('LL')}
                                        </div> */}
                                                {/* {user?.id == id ? null : (
                                            <p
                                                className="rprtBtn cursorDecoy"
                                                onClick={() =>
                                                    !isAuthenticated
                                                        ? handleRedirectInternal(history, 'login')
                                                        : setReportPopup(true)
                                                }
                                            >
                                                {t('report')}
                                            </p>
                                        )} */}
                                            </div>
                                        </div>
                                    )}
                                </TabPanel>

                                <TabPanel value={value} index={1} className="w-100">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: userData?.aboutme
                                                ? userData.aboutme
                                                : 'No Result',
                                        }}
                                    ></p>
                                </TabPanel>

                                <div className="rtWrksLst mb-4">
                                    <h4 className="ArtTitle">Artworks</h4>

                                    <div className="SearchFilter">
                                        <div className="SearchProduct">
                                            <FormControl
                                                fullWidth
                                                className=""
                                                variant="outlined"
                                                onSubmit={searchHandleClick}
                                            >
                                                <HeaderSearchComponent
                                                    header_page={1}
                                                    seller_id={id}
                                                    placeholder=" Search Lots"
                                                />
                                            </FormControl>
                                            <div className="searchTags">
                                                <div className="searchTagsBadge">
                                                    data
                                                    <span className="material-icons-outlined">
                                                        close
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="filterBtns">
                                                <div className="sortoption">
                                                    <Button
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={sortHandleClick}
                                                    >
                                                        {isMobile ? (
                                                            <>
                                                                <span className="material-icons">
                                                                    sort
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="material-icons mr-2">
                                                                    sort
                                                                </span>
                                                                Sort Feed By
                                                                <span className="material-icons ml-2">
                                                                    keyboard_arrow_down
                                                                </span>
                                                            </>
                                                        )}
                                                    </Button>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={sortopen}
                                                        keepMounted
                                                        open={Boolean(sortopen)}
                                                        onClose={sortHandleClose}
                                                        className="sortMenu"
                                                    >
                                                        {sortShow.length > 0 &&
                                                            sortShow.map((data, index) => (
                                                                <MenuItem
                                                                    onClick={(e) =>
                                                                        sortHandleClose(data.value)
                                                                    }
                                                                    value={data.value}
                                                                    key={index}
                                                                >
                                                                    {data.show}
                                                                </MenuItem>
                                                            ))}
                                                    </Menu>
                                                </div>
                                                {/* <div className="filterOption ml-4">
                                            <Button variant="contained" color="primary">
                                                {isMobile ? (
                                                    <>
                                                        <span className="material-icons">
                                                            filter_list
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-icons mr-2">
                                                            filter_list
                                                        </span>
                                                        Filter Search
                                                    </>
                                                )}
                                            </Button>
                                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {sortShow.length > 0 && typeof orderbysearch == 'number' ? (
                                        <h6>
                                            {`Sort By "${
                                                sortShow.filter(
                                                    (val) => val.value == orderbysearch,
                                                )[0].show
                                            }"`}
                                        </h6>
                                    ) : (
                                        ''
                                    )}

                                    {user_type == 'Artist' && (
                                        <>
                                            <div className="mt-5">
                                                <div className="tpNmBtnLnk d-flex align-items-center justify-content-between">
                                                    <h4 className="mb-3 mt-4 pgTtle">
                                                        {user_type == 'Artist'
                                                            ? params.get('search')
                                                            : ''}
                                                    </h4>

                                                    {/* <Button
                                                className="bioBackBtn"
                                                onClick={() => history.goBack()}
                                            >
                                                {' '}
                                                <span className="material-icons">
                                                    keyboard_backspace
                                                </span>{' '}
                                                {t('back')}
                                            </Button> */}
                                                </div>
                                                <hr />
                                            </div>
                                        </>
                                    )}

                                    <div className="wrksGrid">
                                        {loading ? (
                                            <div className="homeLoader">
                                                <Loaders
                                                    name="product_grid_view"
                                                    isLoading={loading}
                                                    loop={9}
                                                />
                                            </div>
                                        ) : viewProduct.filter(
                                              (data) =>
                                                  data.market_status == 'open' &&
                                                  data.remainingDay >= 0,
                                          ).length !== 0 ? (
                                            <div
                                                className={`homeAuctions ${
                                                    !isDesktop && 'mobileView'
                                                }`}
                                            >
                                                {viewProduct.map((data, index) => (
                                                    <>
                                                        {
                                                            <GridProduct
                                                                data={data}
                                                                className={
                                                                    data.auction
                                                                        ? 'auction'
                                                                        : 'buynow'
                                                                }
                                                                key={index}
                                                                type={
                                                                    data.auction
                                                                        ? 'auction'
                                                                        : 'buynow'
                                                                }
                                                                selectedProduct={selectedProduct}
                                                                link={`/gallery/product-view/${data.id}`}
                                                                handleClick={handleClick}
                                                                timerTheme="ArtAuction"
                                                                sliderTheme="multiseller"
                                                                cardTheme="ArtAuction"
                                                                bidBtnLabel="Place Bid"
                                                                viewProduct={viewProduct}
                                                                setViewProduct={setViewProduct}
                                                            />
                                                        }
                                                    </>
                                                ))}
                                            </div>
                                        ) : (
                                            <NoRecordsFound />
                                        )}
                                        {/* {userProductsData.map((data, index) => (
                                    <BioCard
                                        title={data.title}
                                        avatar={`${process.env.REACT_APP_BASE_URL}uploads/product/${data.avatar}`}
                                        index={index}
                                        redirectUrl={`bio/${data.id}`}
                                        handleClick={() => handleClick(data.id)}
                                    />
                                ))} */}
                                    </div>

                                    {/* {!userProductsData.length ? <NoRecordsFound /> : null} */}
                                    {/* {userProductsData.length &&
                            total > userProductsParams.page * userProductsParams.limit ? (
                                <div className="row">
                                    <div className="col-md-3 col-12 mx-auto d-block artLoad">
                                        <PrimaryButton
                                            disabled={loading}
                                            label={
                                                loading ? (
                                                    <>
                                                        <CircularProgress color="inherit" />
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="material-icons mr-2">
                                                            arrow_circle_down
                                                        </span>{' '}
                                                        {t('load_more')}
                                                    </>
                                                )
                                            }
                                            onClick={handleLoadMoreClick}
                                            className="text-center"
                                        />
                                    </div>
                                </div>
                            ) : null} */}
                                </div>

                                <VCModal
                                    open={reportPopup}
                                    handleClose={() => setReportPopup(false)}
                                    modaltitle={t('report_profile')}
                                    maxWidth={{ maxWidth: '400px' }}
                                    modalClass={'reportModal'}
                                >
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            {mapData(reportData)}
                                            <div className="d-flex align-items-center justify-content-start col-12">
                                                <PrimaryButton
                                                    label={t('send')}
                                                    btnSize="mr-2"
                                                    type="submit"
                                                />
                                                <SecondaryButton
                                                    label={t('cancel')}
                                                    onClick={() => setReportPopup(false)}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </VCModal>
                            </div>

                            <MultisellerSlider
                                storeTheme="ArtAuction"
                                type="right"
                                selectedLot={selectedProduct}
                                handleClose={handleClose}
                                from={''}
                            />
                        </div>
                    </div>
                    <div className="customContainer">
                        {sellers.length > 0 ? (
                            <div className="similarArtist">
                                <h4 className="pgTtle">{t('Other Artists You May Like')}</h4>
                                <Link to="/seller/artist" className="seeAllSimilarArtist">
                                    See All Artists
                                </Link>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="artstCardGrid bioPage">
                            {sellers.map((data, index) => (
                                <>
                                    {index < 4 ? (
                                        <BioCard
                                            title={data.first_name + ' ' + data.last_name}
                                            avatar={
                                                data.avatar
                                                    ? `${process.env.REACT_APP_BASE_URL}uploads/${data.avatar}`
                                                    : `https://ui-avatars.com/api/?name=${
                                                          data.first_name?.split(' ')[0]
                                                      }+${data.last_name?.split(' ')[0]}`
                                            }
                                            index={index}
                                            redirectUrl={`bio/${data.id}`}
                                            sellerFollow={data.sellerFollow}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </>
                            ))}
                        </div>
                        {/* {viewProduct.length > 0 ? (
                    <h4 className="mb-3 mt-4 pgTtle">{t('similar_gallery')}</h4>
                ) : (
                    ''
                )} */}
                        {/* <div className="artstCardGrid">
                    {viewProduct.map((data, index) => (
                        <>
                            {index < 5 && parseInt(data.user_id) != parseInt(id) ? (
                                <BioCard
                                    title={data.title}
                                    avatar={`${process.env.REACT_APP_BASE_URL}uploads/product/${data.file_name}`}
                                    index={index}
                                    redirectUrl={`bio/${data.id}`}
                                    handleClick={() => handleClick(data.id)}
                                />
                            ) : (
                                ''
                            )}
                        </>
                    ))}
                </div> */}
                    </div>
                </>
            )}
        </>
    )
}

export default Bio
