import React from 'react'
import './invoice.css'
import InvoiceComponent from '../../../utils/CommonFunctionality/Invoice/InvoiceFunction'
import Layout from '../Layout'
import DashboardHeader from '../DashboardHeader'

const Invoice = (props) => {
    return (
        <Layout props={props}>
            <DashboardHeader />
            <InvoiceComponent props={props} show_product_id={1} />
        </Layout>
    )
}

export default Invoice
