import axios from 'axios'
import { config } from './common.header'
import { authenticationService } from '../Services/authentication.service'

function getAll() {
    // const requestOptions = { method: 'GET', headers: authHeader() };
    // return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function saveCard(body) {
    var payUrl = `${process.env.REACT_APP_AUCTIONPAY_URL}`
    if (window.location.href.indexOf('songvest.ecommerce.auction') > -1) {
        body['account_id'] = `${process.env.REACT_APP_AUCTIONPAYID}`
        var payUrl = `${process.env.REACT_APP_AUCTIONPAY_URL}`
    } else if (window.location.href.indexOf('192.168.20.104') > -1) {
        body['account_id'] = 'acct_1IivGXPGtjQDIgYO'
        payUrl = `${process.env.REACT_APP_AUCTIONPAY_URL_TEST}`
    }
    //console.log('payUrl ===', payUrl)
    body['amount'] = 50
    body['currency'] = 'usd'
    body['card_token'] = true
    body['capture'] = false
    // body['isSave'] = true;
    // body['onlySaveCard'] = true;
    // body['mode'] = process.env.REACT_APP_PAYMENTMODE;
    return axios
        .post(payUrl, body)
        .then(function (response) {
            //console.log(response.data)
            return response
        })
        .catch(function (error) {
            //console.log(error)
        })
}

function createCustomer(body) {
    var payUrl = `${process.env.REACT_APP_AUCTIONPAY_OTHER_URL}`
    if (window.location.href.indexOf('songvest.ecommerce.auction') > -1) {
        body['account_id'] = `${process.env.REACT_APP_AUCTIONPAYID}`
        payUrl = `${process.env.REACT_APP_AUCTIONPAY_OTHER_URL}/api/customer/create`
    } else if (window.location.href.indexOf('192.168.20.104') > -1) {
        body['account_id'] = 'acct_1IivGXPGtjQDIgYO'
        payUrl = `${process.env.REACT_APP_AUCTIONPAY_OTHER_URL}/api/customer/create`
    } else {
        body['account_id'] = `${process.env.REACT_APP_AUCTIONPAYID}`
        payUrl = `${process.env.REACT_APP_AUCTIONPAY_OTHER_URL}/api/customer/create`
    }
    //console.log('payUrl ===', payUrl)

    return axios
        .post(payUrl, body)
        .then(function (response) {
            //console.log(response.data)
            return response
        })
        .catch(function (error) {
            //console.log(error)
        })
}

function savecardToCust(body) {
    var payUrl = `${process.env.REACT_APP_AUCTIONPAY_OTHER_URL}`
    if (window.location.href.indexOf('songvest.ecommerce.auction') > -1) {
        body['account_id'] = `${process.env.REACT_APP_AUCTIONPAYID}`
        payUrl = `${process.env.REACT_APP_AUCTIONPAY_OTHER_URL}/api/customer/source/create`
    } else if (window.location.href.indexOf('192.168.20.104') > -1) {
        body['account_id'] = 'acct_1IivGXPGtjQDIgYO'
        payUrl = `${process.env.REACT_APP_AUCTIONPAY_OTHER_URL}/api/customer/source/create`
    } else {
        body['account_id'] = `${process.env.REACT_APP_AUCTIONPAYID}`
        payUrl = `${process.env.REACT_APP_AUCTIONPAY_OTHER_URL}/api/customer/source/create`
    }
    //console.log('payUrl ===', payUrl)

    return axios
        .post(payUrl, body)
        .then(function (response) {
            //console.log(response.data)
            return response
        })
        .catch(function (error) {
            //console.log(error)
        })
}

function saveCustomerid(body) {
    //console.log('body ===============', body)
    return axios
        .post(`${process.env.REACT_APP_SOCKET_URL}auctionpay/save_customer_id`, body, config())
        .then(function (response) {
            //console.log(response.data)
            return response
        })
        .catch(function (error) {
            //console.log(error)
        })
}

function getAllProducts(email) {
    return axios
        .get(`${process.env.REACT_APP_URL}search`)
        .then(function (response) {
            //console.log(response.data)
            return response
        })
        .catch(function (error) {
            //console.log(error)
        })
}

function getProductDetails(product_id) {
    let data = { product_id: product_id }
    return axios
        .post(`${process.env.REACT_APP_URL}getProductDetails`, data, config())
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            //console.log(error)
        })
}

const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

const getProDetails = async (data) => {
    //console.log('data===>', data)
    const getResponce = async (product_id) =>
        await axios.post(
            `${process.env.REACT_APP_URL}getprodetails`,
            { product_id, ftpImage: true },
            config(),
        )
    let location = window.location.pathname
    if (
        location.includes('product-view') ||
        location.includes('favorites') ||
        location.includes('mybids')
    ) {
        return await getResponce(data)
    } else {
        let list = []
        const appendBidData = async (item) => {
            let response = await getResponce(item.id)
            if (response && response.data.success === 'yes') {
                item['incrementamt'] = response.data.incrementamt
                item['next_bid'] = response.data.biddetails.next_bid
            }
            return { ...item }
        }
        Array.isArray(data) &&
            (await asyncForEach(data, async (item) => {
                let itemResponce = await appendBidData(item)
                list.push(itemResponce)
            }))
        return list
    }
}

function searchAuctionData(body) {
    return axios
        .post(`${process.env.REACT_APP_URL}auctionsearch`, body, config())
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            //console.log(error)
        })
}

function searchClosedAuctionData(body) {
    return axios
        .post(`${process.env.REACT_APP_URL}auctionsearch`, body, config())
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            //console.log(error)
        })
}

function searchResult(body) {
    return axios
        .post(`${process.env.REACT_APP_URL}mobilesearch`, body, config())
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            //console.log(error)
        })
}

const getAuctionDetails = async (body) => {
    let response = await axios.post(
        `${process.env.REACT_APP_URL}getauctionlotdetails`,
        body,
        config(),
    )
    return response
}

function saveSearch(formData) {
    let user_token = authenticationService.currentUserValue.user_token
    let user_id = authenticationService.currentUserValue.id
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user_token
    return axios
        .post(`${process.env.REACT_APP_URL}saveSearch/` + user_id, formData)
        .then(function (response) {
            //console.log(response.data)
            return response.data
        })
        .catch(function (error) {
            //console.log(error)
            return error
        })
}

const getLotList = async (body) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_URL}lotSearch`, body, config())
        return response
    } catch (error) {
        //console.log(error)
    }
}

export const productService = {
    getAll,
    getAllProducts,
    getProductDetails,
    searchResult,
    saveSearch,
    searchAuctionData,
    getProDetails,
    getAuctionDetails,
    getLotList,
    saveCard,
    searchClosedAuctionData,
    saveCustomerid,
    createCustomer,
    savecardToCust,
}
