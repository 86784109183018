import { GET_USER_DATA } from '../type'
import { config } from '../../Services/common.header'

const UserDataReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USER_DATA: {
            return Object.assign({}, config(), {
                updateUserData: action.payload,
            })
        }
        default:
            return state
    }
}

export default UserDataReducer
