import React, { useState, useEffect, useContext } from 'react'
import './invoice.css'
import PropTypes from 'prop-types'
import Invoice from './Invoice'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { Redirect, useParams } from 'react-router-dom'
import AuthContext from '../../../Product/context/auth/authContext'
import Box from '@material-ui/core/Box'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const MakeAnOffer = (props) => {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const { page } = useParams()
    useEffect(() => {
        if (window.location.search) {
            setValue(parseInt(window.location.search.split('?page=')[1]))
        }
    }, [window.location.search])
    return (
        <div className="db-bids-wrapper">
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="buyer-dashboard"
                className="mt-4 liquidationDashTabs"
            >
                <Tab label={'Pending'} {...a11yProps(0)} />
                <Tab label={'Accepted'} {...a11yProps(1)} />
                <Tab label={'Rejected'} {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Invoice page="Pending" />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Invoice page="Accepted" />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Invoice page="Rejected" />
            </TabPanel>
        </div>
    )
}

export default MakeAnOffer
