/*eslint-disable*/
import React, { useEffect, useState, useContext } from 'react'
import PluginContext from '../../../Product/context/plugin/pluginContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import StripeCardContext from '../../../Product/context/stripe/card/cardContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { currencyFormat, handleRedirectInternal } from '../../../Product/common/components'
import { useHistory } from 'react-router-dom'
import './Subscription.css'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const Subscription = (props) => {
    const [loading, setLoading] = useState(false)
    const [currentplan, setCurrentPlan] = useState('')
    const { isAuthenticated, user, active_plugins, loadUser } = useContext(AuthContext)
    const { setAlert } = useContext(AlertContext)
    const { get_all_card_details, getAllStripeCards, createStripeCard, get_skyflow_cards } =
        useContext(StripeCardContext)
    const { responseStatus, clearResponse, subscribeReseller, requestdiscount, getPlanDetails } =
        useContext(PluginContext)
    const { t } = useTranslation()
    const [allData, setAllData] = useState([])
    const [plan, setPlan] = useState([])
    const [listing_fee, setListingFee] = useState({})
    const [cardData, setCardData] = useState('nocard')
    const [subscribed, setSubscription] = useState(false)
    const [requestLoading, setRequestLoading] = useState(false)
    const [Validcode, setValidCode] = useState(false)
    const [subscripe_plan, setSubscripe] = useState([])
    const history = useHistory()
    const handleSubmit = (data) => {
        subscribeReseller({
            user_id: user?.id,
            site_id: global?.storeDetails?.site_id,
            plan_name: data.name,
            plan_commision: parseInt(data.commission),
            payment_details: {
                source: cardData,
                customer: user.card_paymentid,
                currency: user?.country == 38 ? 'cad' : 'usd',
                description: 'Reseller Subscription',
                amount:
                    parseFloat(parseFloat(data.price) - parseFloat(data.discount)).toFixed(2) * 100,
            },
        })
        setCurrentPlan(data.name)
        setLoading(true)
    }

    //console.log("fnhebhfwebhfwhe", global?.storeDetails?.theme)

    const onChange = (e) => {
        e.target.checked ? setCardData(e.target.value) : setCardData('nocard')
    }

    useEffect(() => {
        if (user) {
            if (user?.subscribed) {
                setSubscription(true)
            }
        }
    }, [user])

    useEffect(() => {
        var lis_fee = ''
        if (active_plugins) {
            for (var i = 0; i < active_plugins.length; i++) {
                if (active_plugins[i].plugin_name == 'listing_fee') {
                    lis_fee = active_plugins[i].plugin_name
                    break
                }
            }
        }
        setListingFee(lis_fee)
    }, [active_plugins])
    useEffect(() => {
        getPlanDetails({ site_id: global?.storeDetails?.site_id })
    }, [])

    useEffect(() => {
        if (global.storeConfigration?.skyflow_integration?.value == 1) {
            if (get_skyflow_cards) {
                setAllData(get_skyflow_cards)
            }
        } else {
            if (get_all_card_details) {
                setAllData(get_all_card_details.records)
            }
        }
    }, [get_all_card_details, get_skyflow_cards])

    useEffect(() => {
        if (allData.length > 0) {
            setCardData(allData[0].id)
        }
    }, [allData])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'subscribeReseller') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    setAlert('Subscribed Successfully!', 'success')
                    loadUser()
                    clearResponse()
                    // if (!user?.card_profileid) {
                    //     setAlert('Kindly add account id for move to product in live!', 'warning')
                    //     handleRedirectInternal(history, 'dashboard/profile?moveto="profilepage"')
                    // } else {
                    setAlert('You can able to change product status to live now', 'success')
                    handleRedirectInternal(history, 'dashboard/mylots')
                    //}

                    // handleRedirectInternal(history, '')
                }
            }
            if (responseStatus.from === 'requestDiscount') {
                setRequestLoading(false)
                if (responseStatus.status === 'success') {
                    setAlert('Code Valid!', 'success')

                    var req_data = responseStatus.message
                    var plan = subscripe_plan
                    plan.map((data) => {
                        if (req_data.discountType == 'percentage') {
                            var discount_amount = parseFloat(
                                parseFloat(
                                    parseFloat(data.price) * parseFloat(req_data.amount / 100),
                                ),
                            ).toFixed(2)
                        } else if (req_data.discountType == 'fixedamount') {
                            var discount_amount = req_data.amount
                        }
                        data.discount = discount_amount
                    })
                    setSubscripe([...plan])

                    document.getElementById(`code_cpcn`).disabled = true
                    document.getElementById(`code_cpcn`).style.backgroundColor =
                        'rgba(65, 206, 65, 1)'
                    setValidCode(true)
                }
            }
            if (responseStatus.from === 'get_plan_details') {
                if (responseStatus.status === 'success') {
                    var data_from = responseStatus.message
                    data_from.map((val) => {
                        val.config = val.config.split(',')
                        val.discount = 0
                    })
                    setSubscripe(responseStatus.message.length > 0 ? responseStatus.message : [])
                }
            }
            if (responseStatus.status === 'error' && responseStatus.from === 'requestDiscount') {
                setAlert('Invalid Code!', 'error')
            }
        }
        clearResponse()
    }, [responseStatus])

    // const plan = [
    //     // {
    //     //     name: 'gold',
    //     //     price: '30',
    //     //     duration: 'month',
    //     //     commission: '15',
    //     //     config: [
    //     //         'first month free',
    //     //         'Up to 30 uploads a month',
    //     //         'Multi image uploader',
    //     //         'Artist portfolio',
    //     //         'Organize inventory',
    //     //         'artist bio(about me page)',
    //     //         'Artist banner',
    //     //         'Newsletter',
    //     //         'Control timing of auction',
    //     //         'Shipstation',
    //     //         'buyers responsible for shipping cost',
    //     //         'Custom timedout labeling',
    //     //     ],
    //     // },
    //     {
    //         name: 'vip',
    //         price: 300,
    //         duration: 'month',
    //         commission: '10',
    //         discount: 0,
    //         config: [
    //             'Post_Up_To_10_Lot',
    //             'Timed Auction',
    //             'Control_Timing_Cl',
    //             'Artist_Bio_Page',
    //             'Newsletter_To_Cli',
    //             'inventory_managem',
    //             'ship_details',
    //             'shipping_label',
    //             'Buyer_Pays_Shippi',
    //             'Stripe_Payment_Se',
    //             'Global_Exposure',
    //         ],
    //     },
    //     {
    //         name: 'diamount_premium',
    //         price: 100,
    //         duration: 'month',
    //         discount: 0,
    //         commission: '0',
    //         config: [
    //             'post_UNLIMITED_Am',
    //             'Service_And_Help_',
    //             'Bio_Page_Link',
    //             'Timed Auction',
    //             'Control_Timing_Cl',
    //             'Artist_Bio_Page',
    //             'Newsletter_To_Cli',
    //             'inventory_managem',
    //             'ship_details',
    //             'shipping_label',
    //             'Buyer_Pays_Shippi',
    //             'Stripe_Payment_Se',
    //             'Global_Exposure',
    //         ],
    //     },
    // ]

    const aplaycouponcode = async (e) => {
        e.preventDefault()
        const body = {
            inputcpn: document.getElementById(`code_cpcn`).value,
            storeId: global?.storeDetails?.id,
            pid: 0,
        }
        setRequestLoading(true)
        requestdiscount(body)
    }

    return (
        <>
            {global.pluginConfiguration?.coupon_code?.enable ? (
                <>
                    <br></br>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <form onSubmit={(e) => aplaycouponcode(e)}>
                                <div className="input-group chkShpngMsg">
                                    <input
                                        id={`code_cpcn`}
                                        type="text"
                                        min="1"
                                        placeholder={t('enter_coupon_code')}
                                        className="form-control mr-2"
                                    />
                                    <div className="input-group-btn">
                                        <button
                                            id={`code_cpcn_btn`}
                                            type="submit"
                                            className="btn pink-btn"
                                            disabled={requestLoading || Validcode ? true : false}
                                        >
                                            {requestLoading ? 'Loading...' : t('apply_code')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
            {global.storeDetails?.theme != 14 || listing_fee == '' ? (
                <>
                    <div className="sbspntWrpr">
                        {subscripe_plan.map((data, index) => (
                            <div className="sbsCard" key={index}>
                                <h2>{t(data.name)}</h2>
                                <div className="prcngVlw">
                                    <h4>{currencyFormat(data.price)}</h4>
                                    <p>{t(data.duration)}</p>
                                    {parseInt(data.discount) > 0 ? (
                                        <p>Discount Amount: - {currencyFormat(data.discount)}</p>
                                    ) : (
                                        ''
                                    )}
                                    <p className="mt-3">
                                        {data.commission ? data.commission + '%' : 'No'}{' '}
                                        {t('commission_upon_s')}
                                    </p>
                                </div>
                                <ul>
                                    {data.config.map((data, index) => (
                                        <li key={index}>{t(data)}</li>
                                    ))}
                                </ul>
                                {subscribed && user?.subscribe_plan == data.name ? (
                                    <Button
                                        style={{ color: '#ffffff', backgroundColor: '#34cc63' }}
                                        className="sbsCrbBtn"
                                    >
                                        {' '}
                                        {loading && data.name == currentplan
                                            ? 'Loading...'
                                            : t(`subscribed`)}
                                    </Button>
                                ) : allData.length === 0 ? (
                                    <Button
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                'dashboard/cards?moveto="subscribe"',
                                            )
                                        }
                                        className="sbsCrbBtn"
                                    >
                                        {t('card_subscribe')}
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={(e) => handleSubmit(data)}
                                        className="sbsCrbBtn"
                                    >
                                        {loading && data.name == currentplan
                                            ? 'Loading...'
                                            : t(`subscribe`)}
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                ''
            )}
        </>
    )
}

export default Subscription
