import React from 'react'
import Layout from '../../Layout'
import './GameRoom.css'
const GameRoom = (props) => {
    return (
        <>
            <Layout props={props}>
                <div className=" py-4 staticPgWrapper">
                    <div className="static-pages-wrapper">
                        <div className="inner-main-wrapper">
                            <div className="customContainer rss-pgr gameRoom">
                                <h2>Game Room</h2>
                                <h4>creating your game room experience</h4>
                                <p style={{ textAlign: 'center' }}>
                                    Owning a casino slot machine for home use opens doors to endless
                                    entertainment and enjoyment. It is a unique investment that
                                    brings the allure of the casino right to your doorstep. Find the
                                    perfect machine that suits your preferences on OwnASlot.com.
                                </p>
                                <div className="journey" style={{ textAlign: 'left' }}>
                                    <h5>Adams Journey</h5>
                                    <p>
                                        Meet Adam, a passionate gaming enthusiast who turned his
                                        game room into a thrilling casino paradise! He is not just a
                                        collector; he is a connoisseur of the slot gaming world.
                                    </p>
                                    <p>
                                        Adams journey began with a vision he created shopping at
                                        OwnASlot.com - to create a space that exuded the
                                        exhilarating atmosphere of a casino right in the comfort of
                                        his home. His game room transformation started with one
                                        pivotal addition: a real casino slot machine from
                                        OwnASlot.com.
                                    </p>
                                    <div
                                        style={{
                                            position: 'relative',
                                            minHeight: '590px',
                                            maxWidth: '700px',
                                            margin: '20px auto',
                                        }}
                                    >
                                        <div className="down">
                                            <img
                                                src="/slot4home/image-5.png"
                                                alt="image5"
                                                style={{ maxWidth: '411px', width: '100%' }}
                                            />
                                        </div>
                                        <div
                                            className="up"
                                            style={{
                                                position: 'absolute',
                                                right: '26px',
                                                top: '250px',
                                            }}
                                        >
                                            <img
                                                src="/slot4home/image-4.png"
                                                alt="image4"
                                                style={{ maxWidth: '380px', width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                    <p>
                                        Adams game room is not just a space; it is an experience.
                                        The vibrant lights, the familiar jingles, and the rush of
                                        spinning reels greet anyone entering his gaming haven. It is
                                        a place where friends gather, laughter echoes, and
                                        unforgettable memories are made.
                                    </p>
                                    <p>
                                        But Adams story is not unique. You, too, can transform your
                                        entertainment space into a captivating hub with a real
                                        casino slot machine from OwnASlot.com. Whether you are a
                                        gaming aficionado, a collector seeking authenticity, or
                                        someone who craves the thrill of the casino, owning a
                                        genuine slot machine can elevate your home entertainment to
                                        unparalleled heights.
                                    </p>
                                    <p>
                                        Let Adams picture-perfect game room inspire you to create
                                        your at-home casino oasis. It is more than just owning a
                                        slot machine; it is about having an experience, making
                                        memories, and having endless fun right in your home.
                                    </p>
                                </div>
                                <h5
                                    style={{
                                        textAlign: 'center',
                                        color: '#1d52dd',
                                        marginTop: '80px',
                                    }}
                                >
                                    Ready to transform your space into a casino paradise? We at
                                    OwnASlot.com will help you find the perfect slot machine to
                                    kickstart your gaming adventure and let the thrill begin!
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default GameRoom
