import React from 'react'

const RequirementImageUpload = () => {
    return (
        <div className="req-img-upload">
            <section id="contentArea" className="container-fluid">
                <div className="row-fluid">
                    <section className="span9">
                        <div className="contentWrapper">
                            <article id="fullArticle">
                                <h1 className="title">Requirements for Uploading Photos</h1>

                                <a
                                    href="javascript:window.print()"
                                    className="printArticle"
                                    title="Print this article"
                                    aria-label="Print this Article"
                                >
                                    <i className="icon-print"></i>
                                </a>
                                <p data-children-count="0">
                                    {' '}
                                    <span style={{ fontSize: '14px' }}>
                                        Great photos are just as important as product descriptions.
                                        We allow up to 20&nbsp;photos per lot.
                                        <br /> <br /> <strong>Photo Requirements</strong>
                                        <br /> <br />{' '}
                                        <u>Single Image &amp; Batch/Bulk Image processor</u> -&nbsp;
                                    </span>
                                    <span style={{ backgroundColor: 'initial' }}>
                                        We recommend using images that do not exceed an&nbsp;
                                    </span>
                                    image resolution size of 3840x3840 (4K). Using the original
                                    image size enables bidders to use the Zoom feature.&nbsp;The
                                    file size must <u>not</u> be less than
                                    15kb&nbsp;(kilobyte).&nbsp;We require images to be in a .jpg
                                    format.
                                </p>

                                <p data-children-count="0">
                                    <span style={{ fontSize: '14px' }}>
                                        {' '}
                                        <strong>
                                            <em>
                                                NOTE: Images cannot contain any URLs, email
                                                addresses, contact information or&nbsp;watermarks of
                                                any kind
                                                <br />{' '}
                                            </em>
                                        </strong>{' '}
                                        <br /> <strong>Naming your Photos</strong>
                                        <br /> <br /> Name your photo so it matches up with the lot
                                        number it represents. If you have three&nbsp;images for Lot
                                        1, name them like this: <br /> 1_1.jpg <br /> 1_2.jpg <br />{' '}
                                        1_3.jpg <br /> Please remember to always include the “_” as
                                        well as the “.jpg” <br /> <br /> Similarly, if you have an A
                                        lot in your sale (i.e., lot 53A), name the images like this:{' '}
                                        <br /> 53A_1.jpg&nbsp; <br /> 53A_2.jpg&nbsp; <br />{' '}
                                        53A_3.jpg&nbsp; <br /> <br />
                                        Image file columns are only required if the photographs are
                                        not&nbsp;named according to&nbsp;lot numbers. You can have
                                        up to 20 image file columns.
                                        <br /> <br /> If using these columns:&nbsp;{' '}
                                    </span>
                                </p>
                                <ol data-children-count="0">
                                    <li>
                                        <span style={{ fontSize: '14px' }}>
                                            You must list the full image file name including the
                                            .jpg extension.&nbsp;
                                        </span>
                                    </li>
                                    <li>
                                        <span style={{ fontSize: '14px' }}>
                                            The file names are <u>case sensitive</u>.&nbsp;
                                        </span>
                                    </li>
                                </ol>
                                <p data-children-count="0">
                                    {' '}
                                    Example: If your image is “warhol19front” for lot&nbsp;number
                                    24, then the ImageFile.1 for lot 24 would read
                                    “warhol19front.jpg”.&nbsp;
                                </p>
                                <p data-children-count="0">
                                    {' '}
                                    <span style={{ fontSize: '14px' }}>
                                        {' '}
                                        <br /> Additionally, if your images are hosted on a website,
                                        you may enter the URL location&nbsp;of your images in the
                                        Image File Columns for our image processor to retrieve.{' '}
                                    </span>
                                </p>
                            </article>
                        </div>
                    </section>
                </div>
            </section>
        </div>
    )
}
export default RequirementImageUpload
