import React, { useState, useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    snackWrapper: {
        maxWidth: 380,
        fontSize: 14,
        margin: theme.spacing(6, 0, 0),
        background: 'var(--primColor)',
        borderColor: 'var(--primColor)',
        borderWidth: 2,
        borderStyle: 'solid',
        color: '#fff',
    },
}))

const Toaster = ({ status, open, onClose, direction }) => {
    function MySnackbarContentWrapper(props) {
        const classes1 = useStyles()
        const { className, message, variant, ...other } = props

        return (
            <SnackbarContent
                aria-describedby="client-snackbar"
                className={classes1.snackWrapper}
                message={
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <p id="client-snackbar" className="m-0">
                            {message}
                        </p>
                        <Button
                            className="material-icons ml-auto toaster_button"
                            onClick={onClose}
                            style={{ color: 'white' }}
                        >
                            X
                        </Button>
                    </div>
                }
                {...other}
            />
        )
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: direction || 'bottom',
                horizontal: 'right',
            }}
            open={open}
        >
            <MySnackbarContentWrapper variant={status} message={status && status.message} />
        </Snackbar>
    )
}

export default Toaster
