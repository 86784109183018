import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import SubLots from '../../../utils/CommonFunctionality/SellerDashboard/SubLots'
import { useTranslation } from 'react-i18next'

const MyLots = (props) => {
    const { t } = useTranslation()
    return (
        <Layout props={props}>
            <DashboardLayout title={t('my_lots')}>
                <SubLots
                    multipleChangeStatus={true}
                    searchBar={1}
                    status={1}
                    resultsPerPage={1}
                    changeStatus={1}
                    withoutAuction={1}
                    copyItem={1}
                    slidertheme={'ArtAuction'}
                    set_start_and_end_date={1}
                    multiple_relist={1}
                    need_auction_type={1}
                />
            </DashboardLayout>
        </Layout>
    )
}

export default MyLots
