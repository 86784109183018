import React, { useState, useEffect, useContext } from 'react'
import UserContext from '../../../Product/context/user/userContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { mapData } from '../../../Product/common/components'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'

const AddressBook = (props) => {
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { add_shipping_address, getAllShippingAddress, responseStatus, clearResponse } =
        userContext

    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])

    const validationArray = Yup.object({
        first_name: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        last_name: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email format')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        phone: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'Only numbers are allowed')
            .min(10, 'Minimum 10 characters')
            .required('Required'),
        country: Yup.string().trim().required('Required'),
        state: Yup.string().trim().required('Required'),
        address: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        city: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z ]*$/g, 'Only characters allowed')
            .required('Required'),
        zipcode: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            address: '',
            address1: '',
            country: '',
            city: '',
            zipcode: '',
            state: '',
            tempAddress: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            add_shipping_address(values)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'error') {
                if (responseStatus.from === 'add_shipping_address') {
                    setAlert(responseStatus.message, 'error')
                }
            }
            if (responseStatus.status === 'success') {
                if (responseStatus.from === 'add_shipping_address') {
                    setAlert(responseStatus.message, 'success')
                    clearResponse()
                    if (props.closePopup) {
                        props.closePopupFunction()
                    }
                    if (props.refreshAddress) {
                        getAllShippingAddress()
                    }
                }
            }
        }
    }, [responseStatus])

    const productListing = [
        {
            label: 'First Name *',
            type: 'text',
            placeholder: 'Enter your First Name',
            class: 'col-sm-6 col-12',
            name: 'first_name',
            formik: formik,
        },
        {
            label: 'Last Name *',
            type: 'text',
            placeholder: 'Enter your Last Name',
            class: 'col-sm-6 col-12',
            name: 'last_name',
            formik: formik,
        },
        {
            label: 'Email *',
            type: 'email',
            placeholder: 'Enter your email',
            class: 'col-sm-6 col-12',
            name: 'email',
            formik: formik,
        },
        {
            label: 'Phone *',
            type: 'text',
            placeholder: 'Enter your phone number',
            class: 'col-sm-6 col-12',
            name: 'phone',
            formik: formik,
        },
        {
            label: 'Address Line 1*',
            placeholder: 'Enter Address',
            type: global.storeConfigration?.map_input?.value == '1' ? 'mapInput' : 'textarea',
            class: 'col-sm-6 col-12',
            name: 'address',
            formik: formik,
        },
        {
            label: 'Address Line 2',
            placeholder: 'Enter Address',
            type: 'textarea',
            class: 'col-sm-6 col-12',
            name: 'address1',
            formik: formik,
        },
        {
            label: 'Country' + '*',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: countryList.map((country) => ({
                show: country.name,
                value: country.location_id,
            })),
            name: 'country',
        },
        {
            label: formik.values.country == 38 ? 'Province' : 'State' + '*',
            placeholder: `Select your ${formik.values.country == 38 ? 'province' : 'state'}`,
            class: 'col-12 col-sm-6',
            type: 'select',
            options: stateList.map((state) => ({ show: state.name, value: state.name })),
            name: 'state',
        },
        {
            label: 'City *',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-sm-6 col-12',
            name: 'city',
            formik: formik,
        },
        {
            label: `${formik.values.country == 38 ? 'Postal' : 'Zip'} Code *`,
            type: 'text',
            placeholder: `Enter your ${formik.values.country == 38 ? 'postal' : 'zip'} code`,
            class: 'col-sm-6 col-12',
            name: 'zipcode',
            formik: formik,
        },
    ]

    const getLocationData = () => {
        var body = {
            countryCode: formik.values.country,
        }
        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                //console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.country])

    const setstatefrommap = () => {
        if (formik.values.tempAddress.split(',').length > 3) {
            let newAdd = formik.values.tempAddress.split(',')
            let addLength = newAdd.length
            //console.log('newAdd state', newAdd, addLength)
            let findValue = newAdd[addLength - 2].trim()
            let newState = stateList.findIndex(
                (e) => e.map_code_io == findValue || e.name == findValue,
            )
            if (newState != -1) {
                formik.setFieldValue('state', stateList[newState].name)
            } else {
                formik.setFieldValue('state', '')
            }

            let findcity = newAdd[addLength - 3].trim()
            formik.setFieldValue('city', findcity)
        } else {
            formik.setFieldValue('state', '')
            formik.setFieldValue('city', '')
        }
    }

    useEffect(() => {
        if (formik.values.tempAddress != '' && global.storeConfigration?.map_input?.value == '1') {
            if (formik.values.tempAddress.split(',').length > 3) {
                formik.values.address = formik.values.tempAddress
                let newAdd = formik.values.tempAddress.split(',')
                let addLength = newAdd.length
                //console.log('newAdd', newAdd, addLength)
                let findValue =
                    newAdd[addLength - 1].trim() == 'USA'
                        ? 'United States'
                        : newAdd[addLength - 1].trim() == 'UK'
                        ? 'United Kingdom'
                        : newAdd[addLength - 1].trim()
                let newCountry = countryList.findIndex((e) => e.name == findValue)
                if (newCountry > 0) {
                    if (formik.values.country != countryList[newCountry].location_id) {
                        formik.setFieldValue('country', countryList[newCountry].location_id)
                    } else {
                        setstatefrommap()
                    }
                } else {
                    formik.setFieldValue('country', '')
                }
            }
        }
    }, [formik.values.tempAddress])

    useEffect(() => {
        if (formik.values.tempAddress != '' && global.storeConfigration?.map_input?.value == '1') {
            setstatefrommap()
        }
    }, [stateList])

    return (
        <form className="pp-form" onSubmit={formik.handleSubmit}>
            <div>
                <div className="row">{mapData(productListing)}</div>
                <PrimaryButton type="submit" label="Submit" />
            </div>
        </form>
    )
}

export default AddressBook
