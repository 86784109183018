import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { getAllMake, getModelsOfMake } from '../../../utils/CarConstants'
import ProductContext from '../../../Product/context/product/productContext'

const HeaderSearchComponent = (props) => {
    const productContext = useContext(ProductContext)
    const { getOverAllCategories, categories } = productContext
    const [masterCat, setMasterCat] = useState('')
    const [searchValues, setSearchValues] = useState({
        searchbar: '',
        zipcode: '',
        miles: '',
        category: [],
        subCategory: [],
    })
    const handleChange = (e) =>
        setSearchValues({ ...searchValues, [e.target.name]: e.target.value })
    let history = useHistory()

    const handleClick = (e) => {
        e.preventDefault()
        history.push(
            `/search?q=${searchValues.searchbar}&z=${searchValues.zipcode}&m=${searchValues.miles}&cat=${searchValues.category}&view=0`,
        )
        props.handleDrawerClose && props.handleDrawerClose()
    }
    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        setSearchValues({
            ...searchValues,
            searchbar: params.get('q') ? params.get('q') : '',
            zipcode: params.get('z') ? params.get('z') : '',
            miles: params.get('m') ? params.get('m') : '',
            category: params.get('cat') ? params.get('cat').split(',') : '',
        })
    }, [])

    useEffect(() => {
        getOverAllCategories({ parent: 1 }, '1')
    }, [])

    useEffect(() => {
        setMasterCat(categories)
    }, [categories])

    return (
        <form className="nav-standard-top-area-search">
            <div className="nav-standard-top-area-search-inner">
                <>
                    <div className="hdInptWrpr">
                        {props.label && <label>Search</label>}
                        <input
                            type="text"
                            onChange={handleChange}
                            name="searchbar"
                            value={searchValues.searchbar}
                            className="nav-standard-top-area-search-input open-sans"
                            placeholder="Search"
                        />
                    </div>
                    {props.make && (
                        <div className="hdSlctWrpr">
                            {props.label && <label>Make</label>}
                            <select
                                className="custom-select"
                                name="category"
                                onChange={handleChange}
                                value={searchValues.category}
                            >
                                <option value="">Select a make</option>
                                {masterCat.length > 0 &&
                                    masterCat.map((opt, optindex) => (
                                        <option value={opt.name}>{opt.name}</option>
                                    ))}
                            </select>
                        </div>
                    )}
                    {props.model && (
                        <select
                            className="custom-select"
                            name="subCategory"
                            onChange={handleChange}
                            value={searchValues.subCategory}
                        >
                            <option selected>Kms</option>
                            {getModelsOfMake(searchValues.category).map((opt, optindex) => (
                                <option value={opt.id}>{opt.value}</option>
                            ))}
                        </select>
                    )}
                </>
                <button
                    onClick={handleClick}
                    className="lin lin-magnifier nav-standard-top-area-search-sumbit"
                >
                    <span className="material-icons">search</span>
                </button>
            </div>
        </form>
    )
}

export default HeaderSearchComponent
