import React, { useState, useEffect, useContext } from 'react'
import Layout from '../Layout'
import Loaders from '../../../Product/components/molecules/Loaders'
import PaymentContext from '../../../Product/context/payment/payContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import Popup from '../../../Product/components/organisms/Popup'
import authContext from '../../../Product/context/auth/authContext'
import { handleRedirectInternal } from '../../../Product/common/components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { LocalLaundryService } from '@material-ui/icons'
const Paypalsuccess = (props) => {
    const [loaders, setLoaders] = useState(true)
    // const [open, setOpen] = useState(false)
    const token = new URLSearchParams(window.location.search.substring(1)).get('merchantIdInPayPal')
    // const PayerID = new URLSearchParams(window.location.search.substring(1)).get('PayerID')
    const {
        paypalsuccessUpdate,
        verifyPaypalstate,
        clearResponse,
        responseStatus: responseStatusPayment,
    } = useContext(PaymentContext)
    const { user, loadUser } = useContext(authContext)
    const history = useHistory()
    const [paypalerror, setPaypalerror] = useState(false)
    const [paypalflag, setPaypalflag] = useState(0)
    // const alertContext = useContext(AlertContext)
    // const { setAlert } = alertContext

    useEffect(() => {
        if (token && user?.id) {
            var payment_det = JSON.parse(localStorage.getItem('paymentdetails'))
            payment_det = { merchantIdInPayPal: token, user_id: user?.id ? user.id : '' }
            verifyPaypalstate(payment_det)
        }
    }, [token, user])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'sucesssellerverifypaypal') {
                if (responseStatusPayment.message) {
                    if (!responseStatusPayment?.message?.primary_email_confirmed) {
                        setPaypalflag(0)
                        setPaypalerror(true)
                        return false
                    } else if (!responseStatusPayment?.message?.payments_receivable) {
                        setPaypalflag(1)
                        setPaypalerror(true)
                        return false
                    } else {
                        var payment_det_send = JSON.parse(localStorage.getItem('paymentdetails'))
                        payment_det_send = {
                            merchantIdInPayPal: token,
                            user_id: user?.id ? user.id : '',
                        }
                        paypalsuccessUpdate(payment_det_send)
                    }
                }
            }
            if (responseStatusPayment.from === 'sucessselleronboardpaypal') {
                if (responseStatusPayment.status === 'success') {
                    loadUser()
                    handleRedirectInternal(history, 'dashboard/profile')
                } else {
                    setAlert(responseStatusPayment.message, 'error')
                }
            }
        }
        clearResponse()
    }, [responseStatusPayment])

    const handleClose = () => {
        setPaypalerror(false)
        handleRedirectInternal(history, 'dashboard/profile')
    }

    return (
        <Layout props={props}>
            {/* <Loaders isLoading={loaders}></Loaders> */}
            {loaders ? (
                <h6 style={{ fontsize: '32px', textAlign: 'center' }}>
                    Paypal Proccesing account.......
                </h6>
            ) : (
                ''
            )}
            <Popup
                open={paypalerror}
                handleClose={(e) => handleClose()}
                size="md"
                className="save-search-modal"
                modaltitle="PayPal Error"
            >
                {paypalflag == 0 ? (
                    <div>
                        Attention: Please confirm your email address on
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.paypal.com/businessprofile/settings"
                        >
                            https://www.paypal.com/businessprofile/settings
                        </a>{' '}
                        in order to receive payments! You currently cannot receive payments.
                    </div>
                ) : (
                    <div>
                        Attention: You currently cannot receive payments due to restriction on your
                        PayPal account. Please reach out to PayPal Customer Support or connect to{' '}
                        <a target="_blank" rel="noreferrer" href="https://www.paypal.com">
                            https://www.paypal.com
                        </a>{' '}
                        for more information.
                    </div>
                )}
            </Popup>
        </Layout>
    )
}

export default Paypalsuccess
