import React from 'react'
import CommonPosCheckout from '../../../utils/CommonFunctionality/Pos/Checkout'

const PosCheckout = () => {
    return (
        <div>
            <CommonPosCheckout />
        </div>
    )
}

export default PosCheckout
