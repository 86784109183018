import { BehaviorSubject } from 'rxjs'
import axios from 'axios'

// import config from 'config';
// import { handleResponse } from '@/_helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))

const login = async (body) => {
    let response = await axios.post(`${process.env.REACT_APP_URL}login`, body, {
        domain: 'auctionsoftwaremarketplace.com:2074',
    })
    if (response && response.data && response.data.status == 'yes') {
        let user =
            response && response.data && response.data.data
                ? JSON.parse(atob(response.data.data.split('.')[1]))
                : ''
        user.user_token = response.data.data
        localStorage.setItem('currentUser', JSON.stringify(user))
        currentUserSubject.next(user)
        return response.data
    } else {
        localStorage.removeItem('currentUser')
        return response.data
    }
}

const forgotPassword = async (email) => {
    let { data } = await axios.post(`${process.env.REACT_APP_URL}forgot_password`, {
        email,
    })
    return data
}

const signup = async (formData) => {
    const body = {
        verify_paypal: false,
        customerProfileId: '',
        city: formData.city,
        address2: formData.address2,
        first_name: formData.firstname,
        last_name: formData.lastname,
        userName: formData.email,
        country: formData.country,
        state: formData.state,
        email: formData.email,
        emailrepeat: formData.email,
        confirmpassword: formData.confirmPassword,
        companyName: '',
        address1: formData.address1,
        password: formData.password,
        phone: formData.phonenumber,
        timezone: '',
        customerPaymentProfileId: '',
        zip: formData.zipcode,
    }
    let responce = await axios.post(`${process.env.REACT_APP_URL}register`, body)
    return responce
}

const checkPasswordChanged = async (body) => {
    let response = await axios.post(`${process.env.REACT_APP_URL}checkPasswordChanged`, body)
    if (response && response.data && response.data.status) {
        //console.log('user data ====', response)
        return response
    } else {
        //console.log('user data not found')
        return response
    }
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser')
    currentUserSubject.next(null)
}

export const authenticationService = {
    forgotPassword,
    login,
    logout,
    signup,
    checkPasswordChanged,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
}
