import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import * as Yup from 'yup'
import Popup from '../../Product/components/organisms/Popup'
import { mapData } from '../../Product/common/components'
import { disputeSubject } from '../../utils'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { useContext } from 'react'
import pluginContext from '../../Product/context/plugin/pluginContext'
import alertContext from '../../Product/context/alert/alertContext'
const Dispute = ({ from_id, to_id, lotid, auctionid }) => {
    const { disputeMessage, responseStatus, clearResponse } = useContext(pluginContext)
    const { setAlert } = useContext(alertContext)
    const [openDispute, setOpenDispute] = useState(false)
    const [loading, setLoading] = useState(false)
    const validationSchema = Yup.object({
        subject: Yup.string().required('Subject is required!'),
        message: Yup.string().required('Message is required!'),
    })
    const formik = useFormik({
        initialValues: {
            site_id: global?.storeDetails?.site_id,
            from_id,
            to_id,
            user_id: to_id,
            lotid,
            auctionid,
            subject: '',
            message: '',
        },
        validationSchema,
        onSubmit: (values) => {
            disputeMessage(values)
            setLoading(true)
        },
    })

    const fields = [
        {
            label: 'Subject',
            placeholder: 'Select a subject',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: disputeSubject,
            name: 'subject',
            formik,
        },
        {
            label: 'Message',
            placeholder: 'Enter Your Message Here...',
            type: 'textarea',
            class: 'col-12 col-sm-6',
            name: 'message',
            formik,
        },
    ]
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'disputeMessage') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message.message, responseStatus.status)
                    formik.resetForm()
                    setOpenDispute(false)
                } else {
                    setAlert(responseStatus.message, responseStatus.status)
                }
            }
        }
        clearResponse()
    }, [responseStatus])
    return (
        <div>
            <SecondaryButton
                label="Raise A Dispute"
                // btnSize="mt-2"
                onClick={() => setOpenDispute(true)}
            />

            <Popup
                open={openDispute}
                handleClose={(e) => setOpenDispute(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Raise A Dispute"
            >
                <div className="row justify-content-center">
                    {mapData(fields)}
                    <div className="col-4">
                        <PrimaryButton
                            label={loading ? 'Loading...' : 'Submit'}
                            className="mbv-btn"
                            onClick={formik.handleSubmit}
                            disabled={loading}
                        />
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default Dispute
